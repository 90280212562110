import React, { useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/joy';
import { Link } from 'react-router-dom';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles((theme: any) => ({
    primaryContainer: {
        backgroundColor: '#F8F8F8',
        marginBottom: "5px",
    },
    sectionTitle: {
        color: theme.palette.text.neutral.neutral900,
    },
    footerContent: {
        position: 'relative',
        color: theme.palette.text.neutral.neutral900,
        fontSize: '14px',
        backgroundColor: '#F8F8F8',
        marginBottom: "5px",
        fontWeight: '400',
        padding: "4px 10px 4px 20px",
        transition: 'all 0.5s ease',
    },
    SubfooterContent: {
        transition: 'all 0.5s ease',
        "& ul": {
            "& li": {
                position: 'relative',
                color: theme.palette.text.neutral.neutral700,
                backgroundColor: "white",
                fontSize: '14px',
                marginBottom: "0px",
                fontWeight: '400',
                padding: "8px 10px 8px 22px",
                textDecoration: 'none',
            },
        },
    },
}));

interface AccordionItem {
    name: string;
    route: string;
    subSection?: AccordionSection;
    secondarySubSection?: AccordionSection;
}

interface AccordionSection {
    title: string;
    items: AccordionItem[];
}

interface AccordionV2Props {
    sections: AccordionSection[];
}

const AccordionV2: React.FC<AccordionV2Props> = ({ sections }) => {
    const classes = useStyles();
    
    const [openIndex, setOpenIndex] = useState<number | null>(null);
    const [openSubIndex, setOpenSubIndex] = useState<number | null>(null);
    const [openSecondaryIndex, setOpenSecondaryIndex] = useState<number | null>(null);

    const handleToggle = (index: number) => {
        setOpenIndex(openIndex === index ? null : index);
        setOpenSubIndex(null); 
        setOpenSecondaryIndex(null);
    };

    const handleSubToggle = (index: number) => {
        setOpenSubIndex(openSubIndex === index ? null : index);
        setOpenSecondaryIndex(null);
    };

    const handleSecondaryToggle = (index: number) => {
        setOpenSecondaryIndex(openSecondaryIndex === index ? null : index);
    };

    const renderSecondarySubSection = (secondarySubSection: AccordionSection, secIndex: number) => (
        <Accordion expanded={openSecondaryIndex === secIndex} onChange={() => handleSecondaryToggle(secIndex)}>
            <AccordionSummary className={classes.primaryContainer}>
                <div className={`${classes.sectionTitle} font-normal text-sm ml-5 py-1`}>
                    {secondarySubSection.title}
                </div>
            </AccordionSummary>
            <AccordionDetails className={`${classes.SubfooterContent} grid gap-y-2`}>
                <ul>
                    {secondarySubSection.items.map((item, idx) => (
                        <li key={idx}>
                            <Link to={item.route}>{item.name}</Link>
                        </li>
                    ))}
                </ul>
            </AccordionDetails>
        </Accordion>
    );

    const renderSubSection = (subSection: AccordionSection, subIndex: number) => (
        <Accordion expanded={openSubIndex === subIndex} onChange={() => handleSubToggle(subIndex)}>
            <AccordionSummary className={classes.primaryContainer}>
                <div className={`${classes.sectionTitle} font-normal text-sm ml-5 py-1`}>
                    {subSection.title}
                </div>
            </AccordionSummary>
            <AccordionDetails className={`${classes.SubfooterContent} grid gap-y-2`}>
                <ul>
                    {subSection.items.map((item, idx) => (
                        <li key={idx}>
                            <Link to={item.route}>{item.name}</Link>
                            {item.secondarySubSection && renderSecondarySubSection(item.secondarySubSection, idx)}
                        </li>
                    ))}
                </ul>
            </AccordionDetails>
        </Accordion>
    );

    return (
        <div>
            {sections.map((section, index) => (
                <Accordion key={index} expanded={openIndex === index} onChange={() => handleToggle(index)}>
                    <AccordionSummary className={classes.primaryContainer}>
                        <div className={`${classes.sectionTitle} font-normal text-sm px-2 py-2`}>
                            {section.title}
                        </div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <ul>
                            {section.items.map((item, idx) => (
                                <React.Fragment key={idx}>
                                    {!item.subSection && (
                                        <div className={`${classes.footerContent} grid gap-y-2`}>
                                            <li>
                                                <Link to={item.route}>{item.name}</Link>
                                            </li>
                                        </div>
                                    )}
                                    {item.subSection && renderSubSection(item.subSection, idx)}
                                </React.Fragment>
                            ))}
                        </ul>
                    </AccordionDetails>
                </Accordion>
            ))}
        </div>
    );
};

export default AccordionV2;
