interface PricingAndPaymentsProps {
    contentStyle?: string;
    boxStyle?: string;
    subHeading?: string;
    header?: string;
}

const PricingAndPayments: React.FC<PricingAndPaymentsProps> = ({ contentStyle, header, boxStyle, subHeading }) => {
    return (
        <>
            <div className={boxStyle}>
                <span className={subHeading}>{header}</span>
            </div>
            <div className={contentStyle}>
            
            <h4 className="mt-3 mb-1 text-xl font-semibold">Pricing</h4>
                <p>We strive for accuracy in listed prices; however, typographical or system errors may occasionally occur. We reserve the right to rectify and quote accurate prices without any liability.</p>
                <h4 className="mt-3 mb-1 text-xl font-semibold">Invoicing and Taxation</h4>
                <p>Invoices will be issued upon delivery, clearly detailing quantities, prices, taxes (including GST), payment terms, and interest charges (where applicable). You agree to fulfil invoice payment obligations on time and fully comply with applicable GST and Income Tax regulations. You will indemnify us against losses resulting from your non-compliance.</p>
                <h4 className="mt-3 mb-1 text-xl font-semibold">Payment Terms</h4>
                <p>Payments may be made via RTGS, NEFT, NACH, IMPS, Account Payee Cheques, Demand Drafts, or debit/credit cards through designated banks. Responsibility for secured payment transactions and the accuracy of details lies entirely with You. Assistance for obtaining third-party financing options is available upon request; however, obligations towards third-party financing institutions remain exclusively Yours</p>
                <h4 className="mt-3 mb-1 text-xl font-semibold">Return and Replacement</h4>
                <p>Returns for incorrect or transit-damaged Products must be notified within one day of delivery. After verification, a replacement will be arranged. Products must be returned in original condition and packaging, including the original documents. Manufacturing defects discovered post-delivery will be eligible for free replacements following inspection.</p>
                <h4 className="mt-3 mb-1 text-xl font-semibold">Refunds</h4>
                <p>Refunds are permitted upon approval and will be credited via original payment channels. Refunds related to third-party financing will be credited directly to the financier.</p>
                <h4 className="mt-3 mb-1 text-xl font-semibold">Delivery Terms</h4>
                <p>Acceptance of delivery is mandatory for all ordered Products. Refusal or non-acceptance will result in additional charges borne by You. All deliveries must be acknowledged with appropriate documentation upon receipt.</p>
            </div>
        </>
    )
}

export default PricingAndPayments;