import React, { useEffect } from 'react'
import { createUseStyles } from 'react-jss';
import { useNavigate } from 'react-router-dom';
import Button from '../../atoms/Button/Button';
import { useAuthenticatedUser } from '../../../hooks/useAuthenticatedUser';
import { AUTH_STATES } from '../../../utils/types';
import { CUSTOMER_ROUTES } from '../../../utils/constant';

export interface AuthDialogFooterProps {
    setAuthStateTo: (state: AUTH_STATES) => () => void;
    section: AUTH_STATES;
    loginType?: AUTH_STATES;
    isLogin: boolean;
    skipForNow?: boolean
    showFooter?: boolean;
}

const useStyles = createUseStyles((theme: any) => ({
    content: {
        color: theme.palette.text.neutral.neutral600,
        lineHeight: "16px"
    },
    abovefooter: {
        paddingBottom: "24px",
        marginTop: "12px"
    },
    borderTop: {
        borderTop: `1px solid ${theme.palette.border.neutral.neutral100}`,
    },
    footer: {
        paddingTop: "24px"
    },
    "@media (max-width: 767px)": {
        abovefooter: {
            paddingBottom: "4px",
            marginTop: "24px"
        },
        borderTop: {
            borderTop: `none`,
        },
        footer: {
            paddingTop: "0px"
        },
    }
}));

const AuthDialogFooter: React.FC<AuthDialogFooterProps> = ({ setAuthStateTo, section, loginType = AUTH_STATES.LOGIN_WITH_EMAIL, isLogin, skipForNow = false, showFooter }) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const { user, syncAuthDialogActive } = useAuthenticatedUser();
    const handleSkip = () => {
        syncAuthDialogActive(false);
        setAuthStateTo(AUTH_STATES.LOGIN_WITH_EMAIL)
        user && !user.businessId && navigate(CUSTOMER_ROUTES.ONBOARDING)
    };
    return (
        <>
            <div className={`flex justify-center  ${classes.abovefooter}`}>
                {isLogin && <Button label={`Login via ${loginType === AUTH_STATES.LOGIN_WITH_EMAIL ? "email" : "mobile number"}`} variant="secondaryText" size="small" onClick={setAuthStateTo(loginType)} />}
                {skipForNow && <Button label="Skip for now" variant="secondaryText" size="small" onClick={handleSkip} />}
            </div>
             <div className={`flex justify-center ${classes.borderTop} ${classes.footer}`}>
                <div className={`${classes.content} text-sm my-auto`}>{isLogin ? "Don't have an account yet?" : "Already have an account?"}</div>
                <Button className='!-ml-3' label={isLogin ? "Register Now" : "Log In"} variant="secondaryText" size="small" onClick={setAuthStateTo(section)} />
            </div>
        </>
    )
}

export default AuthDialogFooter