import React, { useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import roudTickIcon from '../../../assets/icons/roudTickIcon.jpg';
import buyerProtection from '../../../assets/images/buyerProtection.png';
import truckTime from '../../../assets/images/truckTime.png';
import issueRporting from '../../../assets/images/issueRporting.png';
import digiServices from '../../../assets/images/digiServices.png';
import trustAssurance from '../../../assets/images/trustAssurance.png';
import roudTickIcon1 from '../../../assets/icons/roudTickIcon1.svg';
import roudTickIcon2 from '../../../assets/icons/roudTickIcon2.svg';
import BannerComp from '../../../assets/images/BannerComp.png';
import { useLocation } from 'react-router-dom';
//bykamal
import TrustAssuranceMobileBanner from '../../../assets/images/TrustAssuranceMobileBanner.jpg'; 
import BulletImg from "../../../assets/images/bulletIcon.svg"; 
const useStyles = createUseStyles((theme: any) => ({
    container: {
       
    },
    bannerContainer: { 
        backgroundImage: `url(${BannerComp})`,
        backgroundSize: 'cover',
    },
    bannerSellContainer: {
        "& h1": {
            fontSize: '56px',
            lineHeight: '66px',
            color: theme.palette.text.warning.warning400,
        },
        "& p": {
            color: '#fff',
            lineHeight: '66px',
            fontSize: '56px',
        },
        "& h3": {
            color: '#fff',
        },
    },
    intoContainer: {
        backgroundColor: theme.palette.background.neutral.neutral50,
    },
    text: {
        fontSize: '40px',
        color: "#000",
        lineHeight: '48px',
    },
    decContainer: {
        width: '780px',
        "& p": {
            color: "#000",
            lineHeight: '26px',
        },
    },
    headingText: {
        fontSize: '48px !important',
        "& span": {
            color: `${theme.palette.text.primary.primary500} !important`,
        },
    },
    featuresContainer: {
        "& > h5": {
            color: '#000',
        },
        "& span": {
            fontSize: '40px',
            lineHeight: '48px',
            color: theme.palette.text.primary.primary700,
        },
        "& h4": {
            color: theme.palette.text.neutral.neutral800,
            "& span": {
                color: theme.palette.text.primary.primary500,
                fontWeight: '600',
            },
        },
        "& h6": {
            color: theme.palette.text.neutral.neutral900,
            lineHeight: '28px'
        },
    },
    featureInfo: {
        "& h4": {
            color: theme.palette.text.neutral.neutral900,
        },
        "& p": {
            color: theme.palette.text.neutral.neutral800,
            lineHeight: '20px',
        },
    },
    featuresText: {
        width: '651px',
        "& p": {
            color: '#000',
            lineHeight: '26px',
            fontSize: '22px',
        },
    },
    sectionHeading: {
        color: '#000',
        fontSize: '54px',
        lineHeight: '66px',
        "& span": {
            fontSize: '54px',
            color: theme.palette.text.primary.primary500,
            lineHeight: '66px',
        },
    },
    sellerContainer: {
        background: theme.palette.background.primary.primary50,
        position: "relative",
    },
    buyerContainer: {
        background: theme.palette.background.primary.primary500,
    },
    sellerHeading: {
        fontSize: '40px',
        lineHeight: '48px',
        color: theme.palette.text.neutral.neutral800,
        borderBottom: `1px solid ${theme.palette.border.primary.primary500}`,
        "& span": {
            fontSize: '40px',
            lineHeight: '48px',
            color: theme.palette.text.primary.primary500,
        },
    },
    sellerSubHeading: {
        color: '#000',
        lineHeight: '34px',
    },
    buyerSubHeading: {
        color: '#fff',
        lineHeight: '34px',
    },
    benifitContent: {
        "& h5": {
            fontSize: '28px',
            lineHeight: '34px',
            color: '#000',
        },
        "& p": {
            color: theme.palette.text.neutral.neutral800,
            fontSize: '22px',
            lineHeight: '26px',
        },
    },
    buyerHeading: {
        color: '#fff',
        fontSize: '40px',
        lineHeight: '48px',
        borderBottom: '1px solid #fff',
        "& span": {
            fontSize: '40px',
            lineHeight: '48px',
            color: theme.palette.text.warning.warning500,
        },
    },
    benifitContentBuyer: {
        "& h5": {
            fontSize: '28px',
            lineHeight: '34px',
            color: '#fff',
        },
        "& p": {
            color: theme.palette.text.neutral.neutral50,
            fontSize: '22px',
            lineHeight: '26px',
        },
    },
    benifitExtra: {
        position: 'absolute',
        top: '121px',
        right: '-25px',
    },
    extra2: {
        background: theme.palette.background.primary.primary200,
        width: '24px',
        height: '24px',
    },
    extra1: {
        background: theme.palette.background.primary.primary500,
        width: '24px',
        height: '24px',
    },
    subHeading: {
        color: theme.palette.text.neutral.neutral600,
        textAlign: 'center',
    },
    "@media screen and (max-width: 480px)": {
    bannerContainer: { 
        backgroundImage: `url(${TrustAssuranceMobileBanner})`,
        backgroundSize: 'cover',
    },
},
}));

const TrustAssurance: React.FC = () => {

    const classes = useStyles();
    const location = useLocation();

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const component = params.get('component');

        if (component) {
            const element = document.getElementById(component);
            if (element) {
                element.scrollIntoView();
            }
        }
    }, [location.search]);

    
    return (
        <>
         <div className={`${classes.bannerContainer}`}>
        <div className="container mx-auto px-4">
          <div className="insideBannerBx">
            <div className="insideBannerInfo">
               <b>Steelbazaar Trust<br />Assurance</b>
              <h1>Secure, Reliable, and <br /> Transparent Trading </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="neutral20Bg lg:py-14 py-8 w-full">
        <div className="container lg:px-4 px-2 mx-auto flex lg:columns-2 columns-1 lg:flex-row flex-col lg:gap-10 gap-2 PageInfoBoxGry">
          <div className="lg:w-2/5 w-full flex items-center">
            <h2>Secure, Reliable, and Transparent Trading</h2>
          </div>
          <div className={`lg:w-3/5 w-full  flex items-center`}> 
            <p className='!mb-0'>At Steelbazaar, trust is the foundation of every transaction. Our Trust Assurance Program ensures safe payments, quality products, on-time deliveries, and seamless dispute resolution for buyers and sellers alike.</p>
          </div>
        </div>
      </div>
      <div className={`${classes.container} container mx-auto lg:py-24 py-8 lg:px-4 px-2 `}>
            <div className={`headingContainer`}>
            <div className="heading"><span>Trust Assurance</span> for Buyers</div>
            <div className="Subheading">Buy with Confidence on Steelbazaar</div>
            </div>

            {
          // Section One Start Here
        }
        <div
          className="flex lg:columns-2 columns-1 lg:flex-row flex-col items-center ZigZagSection">
          <div className="2xl:w-4/12 lg:w-5/12 w-full flex content-center order-1 lg:order-2 justify-center">
            <div className="ZigZagImgBox ">
              <img src={buyerProtection} className="w-full !shadow-none" alt="" />
            </div>
          </div>
          <div className="2xl:w-8/12 lg:w-7/12 w-full ZigZagText  order-2 lg:order-1"> 
            <div className="heading48">
            Payment Security with <span className='lg:block inline'>
            Buyer Protection</span>
            </div>
            <div className="Subhead">
              <b>Secure Payments Every Step of the Way</b>
             
            </div>

            <div className="PointBox">
              <ul>
                <li>
                <img src={BulletImg} className="bulletBrd" alt="" />
                  <span className='txt22'> 
                  Funds are held in escrow until the buyer confirms satisfaction
                  with the product.
                  </span>
                </li>
                <li>
                <img src={BulletImg} className="bulletBrd" alt="" />
                  <span className='txt22'>
                  Steelbazaar safeguards every transaction from unauthorized 
                  access or fraud.
                  </span>
                </li>
                <li>
                <img src={BulletImg} className="bulletBrd" alt="" />
                  <span className='txt22'>
                  Refunds are initiated for unfulfilled or disputed orders based on predefined policies.
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {
          // Section Two Start Here
        }
        <div className="flex lg:columns-2 columns-1 lg:flex-row flex-col items-center ZigZagSection" id='quality-assurance'>
          <div className="2xl:w-4/12 lg:w-5/12 w-full flex justify-center">
            <div className="ZigZagImgBox"> 
              <img src={trustAssurance} className="w-full !shadow-none" alt="" />
            </div>
          </div>
          <div className="2xl:w-8/12 lg:w-7/12 w-full ZigZagText "> 
            <div className="heading48">
            Quality <span>Assurance</span>
            </div>
            <div className="Subhead">
              <b>Guaranteed Quality Products</b>
            
            </div>

            <div className="PointBox">
              <ul>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span className='txt22'>
                  All products adhere to agreed specifications, including material grades and certifications.
                  </span>
                </li>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span className='txt22'>Regular seller audits maintain strict quality benchmarks. </span>
                </li>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span className='txt22'>Buyers receive mill test certificates and documentation for verification.</span>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {
          // Section Three Start Here
        }
        <div
          className="flex lg:columns-2 columns-1 lg:flex-row flex-col items-center ZigZagSection" id='ontime-delivery'>
          <div className="2xl:w-4/12 lg:w-5/12 w-full flex content-center order-1 lg:order-2 justify-center">
            <div className="ZigZagImgBox ">
              <img src={truckTime} className="w-full !shadow-none" alt="" />
            </div>
          </div>
          <div className="2xl:w-8/12 lg:w-7/12 w-full ZigZagText  order-2 lg:order-1"> 
            <div className="heading48">
            On-Time <span>Deliveries</span>
            </div>
            <div className="Subhead">
              <b>Timely Deliveries You Can Trust</b>
            </div>

            <div className="PointBox">
              <ul>
                <li>
                <img src={BulletImg} className="bulletBrd" alt="" />
                  <span className='txt22'> Sellers commit to guaranteed delivery timelines for every order.</span>
                </li>
                <li>
                <img src={BulletImg} className="bulletBrd" alt="" />
                  <span className='txt22'>Real-time tracking enables buyers to monitor shipment progress.</span>
                </li>
                <li>
                <img src={BulletImg} className="bulletBrd" alt="" />
                  <span className='txt22'>Proactive resolution for delayed shipments ensures minimal disruptions.</span>
                </li>
              </ul>
            </div>
          </div>
        </div>

        </div>
        <div className="neutral20Bg w-full"> 
        <div className={`${classes.container} container mx-auto lg:pt-24 lg:pb-0 py-8 lg:px-4 px-2 `} id='verified-buyers'>
            <div className={`headingContainer`}>
            <div className="heading"><span>Trust Assurance</span> for Seller</div>
            <div className="Subheading">Grow Your Business with Steelbazaar</div>
            </div>
            </div>
            </div>
            <div className='w-full flex blueOddEvenBox  lg:py-10 py-4'>
                <div className='container mx-auto flex lg:flex-row flex-col lg:px-4 px-0'>
                    <div className='lg:w-6/12 w-full leftBx'>
                    <div className='OddTextBox'>
                        <div className='OddHeading'><span>Verified </span>Buyers</div>
                        <b className='lg:pt-4 pt-2'>Connect with Reliable Buyers</b>
                        <div className='OddContent'>
                        <ul>
                            <li>
                            <img src={BulletImg} className="bulletBrd" alt="" />
                            <span>Buyers undergo a detailed verification process to ensure credibility.</span>
                            </li>
                            <li>
                            <img src={BulletImg} className="bulletBrd" alt="" />
                            <span>Verified buyer status boosts seller confidence and transaction security.</span>
                            </li>
                            <li>
                            <img src={BulletImg} className="bulletBrd" alt="" />
                            <span>Access to pre-vetted leads from genuine buyers.</span>
                            </li>
                        </ul>
                        </div>
                    </div>
                    </div>
                    <div className='lg:w-6/12 w-full RightBx'>
                    <div className='EvenTextBox'>
                        <div className='EvenHeading'>Timely <span>Payments</span></div>
                        <b className='lg:pt-4 pt-2'>Payments When You Need Them</b>
                        <div className='EvenContent'>
                        <ul>
                            <li>
                            <img src={BulletImg} className="bulletBrd" alt="" />
                            <span>Escrow services release payments promptly upon order fulfillment.</span>
                            </li>
                            <li>
                            <img src={BulletImg} className="bulletBrd" alt="" />
                            <span>Transparent payment schedules improve cash flow management.</span>
                            </li>
                            <li>
                            <img src={BulletImg} className="bulletBrd" alt="" />
                            <span>Dispute-free transactions ensure hassle-free payment settlements.</span>
                            </li>
                        </ul>
                        </div>
                    </div>
                    </div>
                </div>
            </div>

            <div className={`${classes.container} container mx-auto lg:py-24 py-8 lg:px-4 px-2 `} id='mediation-services'>
            <div className={`headingContainer`}>
            <div className="heading">Dispute <span>Resolution</span></div>
            <div className="Subheading">Fair and Efficient Resolution Processes</div>
            </div>

            {
          // Section One Start Here
        }
        <div
          className="flex lg:columns-2 columns-1 lg:flex-row flex-col items-center ZigZagSection lg:py-11 pt-0" id='issue-reporting'>
          <div className="2xl:w-4/12 lg:w-5/12 w-full flex content-center order-1 lg:order-2 justify-center">
            <div className="ZigZagImgBox ">
              <img src={issueRporting} className="w-full !shadow-none" alt="" />
            </div>
          </div>
          <div className="2xl:w-8/12 lg:w-7/12 w-full ZigZagText  order-2 lg:order-1"> 
            <div className="heading48">Issue <span>Reporting</span>
            </div>
            <div className="Subhead">
              <b>Report Concerns Easily</b>
            </div>

            <div className="PointBox">
              <ul>
                <li>
                <img src={BulletImg} className="bulletBrd" alt="" />
                  <span className='txt22'> Buyers and sellers can raise disputes online for product, payment, or delivery issues.</span>
                </li>
                <li>
                <img src={BulletImg} className="bulletBrd" alt="" />
                  <span className='txt22'>Automated ticketing tracks the resolution process.
                  </span>
                </li>
                <li>
                <img src={BulletImg} className="bulletBrd" alt="" />
                  <span className='txt22'>Dedicated support teams respond to disputes within 24-48 hours.</span>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {
          // Section Two Start Here
        }
        <div className="flex lg:columns-2 columns-1 lg:flex-row flex-col items-center ZigZagSection lg:py-11">
          <div className="2xl:w-4/12 lg:w-5/12 w-full flex justify-center">
            <div className="ZigZagImgBox"> 
              <img src={digiServices} className="w-full !shadow-none" alt="" />
            </div>
          </div>
          <div className="2xl:w-8/12 lg:w-7/12 w-full ZigZagText "> 
            <div className="heading48">
            Mediation <span>Services</span>
            </div>
            <div className="Subhead">
              <b>Resolving Disputes Fairly</b>
            
            </div>

            <div className="PointBox">
              <ul>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span className='txt22'>Expert mediators provide impartial resolution for buyer-seller conflicts.</span>
                </li>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span className='txt22'>Dispute outcomes are based on documented terms and agreements.</span>
                </li>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span className='txt22'>Focused on preserving trust and long-term partnerships.</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        </div>
             
            
        </>
    );
};
export default TrustAssurance;
