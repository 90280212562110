import React, { useEffect, useState } from 'react';
import { CATEGORY_TYPE, CLASS_STATES, HTTP_STATUS, IPagination } from '../../../utils/types';
import { CATALOGUES_TYPE, PRODUCT_SECTION, catalogueFilterStatusOptions, catalogueStatusOptions, initialPaginationValues, statusOptions } from '../../../utils/constant';
import { createUseStyles } from 'react-jss';
import TextFieldV2 from '../../atoms/Input/TextFieldV2';
import Button from '../../atoms/Button/Button';
import { useAuthenticatedUser } from '../../../hooks/useAuthenticatedUser';
import { InputAdornment } from "@mui/material";
import Search from "@mui/icons-material/Search";
import VendorCataloguesListingTemplate from './VendorCatalogueListing.template';
import { IGetCatalogueParams, useVendorCatalogueService } from '../../../services/useVendorCatalogueService';
import EmptyStateCard from '../../molecules/EmptyStateCard/EmptyStateCard';
import Shimmer from '../../molecules/ShimmerComponent/Shimmer';
import { IMultiSelectOption } from '../../atoms/FilterChip/FilterChip';
import FilterChipV2 from '../../atoms/FilterChipV2/FilterChipV2';
import { ICategory } from '../Rfq/ProductRequirementForm.template';

const useStyles = createUseStyles((theme: any) => ({
    heading: {
        color: theme.palette.text.primary.primary900,
    },
    textField: {
        padding: "14px 12px"
    },
    searchIcon: {
        color: theme.palette.text.neutral.neutral400
    },
}));

interface ISkuCodes {
    id: number;
    catalogFilter: string;
    productCode: string;
    productId: number;
}
interface IVendorCatalogueTableViewTemplatePropsProps {
    catalogueType: string;
    stats: number;
    handleAddNewProduct: () => void;
}

export interface ICataloguesList {
    id: number;
    catalogId: string;
    catalogueName: string;
    catalogueType: string;
    productType: string;
    productCount: number;
    reserveSalesPrice: string;
    createdAt: string;
    updatedAt: string;
    createdBy: string;
    status: string;
    productStatus: string;
    fullName: string;
}

export interface ICataloguesListingFilter {
    status: string;
    search: string;
    productCategory: string;
    catalogId: string;
    classType: string;
}

const initialCataloguesListingFiltersValues: ICataloguesListingFilter = {
    status: "",
    search: "",
    productCategory: "",
    catalogId: "",
    classType: CLASS_STATES.STANDARD,
}


const IVendorCatalogueTableViewTemplate: React.FC<IVendorCatalogueTableViewTemplatePropsProps> = ({ catalogueType, stats, handleAddNewProduct }) => {
    const [cataloguesFilters, setcataloguesFiltersTo] = useState<ICataloguesListingFilter>(initialCataloguesListingFiltersValues);
    const [pagination, setPaginationTo] = useState<IPagination>(initialPaginationValues);
    const [cataloguesList, setcataloguesListTo] = useState<ICataloguesList[]>([]);
    const [productCategoryData, setProductCategoryData] = useState<ICategory[] | null>([]);
    const [skuCodesData, setSkuCodesData] = useState<ISkuCodes[] | null>([]);
    const [status, setStatus] = useState<IMultiSelectOption[]>([]);
    const [isLoading, setIsLoading]= useState<boolean>(true);
    const [catalogFilter, setcatalogFilter] = useState<IMultiSelectOption[]>([]);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [cataloguesId, setCataloguesIdTo] = useState<ICataloguesList[]>([]);
    const vendorCatalogueService = useVendorCatalogueService();
    const { user } = useAuthenticatedUser();
    const classes = useStyles();

    const fetchCatalogueId = async () => {
        if (user?.businessId) {
            try {
                const params: IGetCatalogueParams = {
                    page: 0,
                    size: 10,
                    sort:"createdAt",
                    search: searchTerm ?? "",
                    classType: catalogueType ?? initialCataloguesListingFiltersValues.classType
                };
                const productResponse = await vendorCatalogueService.getAllVendorCatalogue(user.businessId, params);
                if (productResponse?.data?.data?.content?.length) {
                    const { content, totalElements } =
                        productResponse.data.data;
                    setCataloguesIdTo(content);
                } else {
                    throw new Error("Catalogues fetch failed");
                }
            } catch (error) {
                setCataloguesIdTo([]);
            }
        }
    };

    const refreshCatalogues = async () => {
        if (user?.businessId) {
            try {
                const params: IGetCatalogueParams = {
                    page: pagination.page,
                    size: pagination.size,
                    status: cataloguesFilters.status,
                    search: cataloguesFilters.search,
                    sort:"createdAt,desc",
                    catalogueId: cataloguesFilters.catalogId,
                    classType: catalogueType ?? initialCataloguesListingFiltersValues.classType

                };
                const productResponse = await vendorCatalogueService.getAllVendorCatalogue(user.businessId, params)
                if (productResponse?.data?.data?.content?.length) {
                    const { content, totalElements } =
                        productResponse.data.data;
                    setcataloguesListTo(content);
                    setPaginationTo((prevPagination: IPagination) => ({
                        ...prevPagination,
                        totalRecords: totalElements,
                    }));
                } else {
                    throw new Error("Catalogues fetch failed");
                }
            } catch (error) {
                setcataloguesListTo([]);
            }
            finally {
                setIsLoading(false);
              }
        }
    };

    const handleFiltersChange = (name: string, value: any) => {
        setPaginationTo({ ...pagination });
        setcataloguesFiltersTo({ ...cataloguesFilters, [name]: value ?? "" });
    };

    const handleSaveStatus = (status: IMultiSelectOption[]) => {
        const valueString = status.length > 0 ? status[0].value : '';
        handleFiltersChange("status", valueString);
    };

    const handleSaveSku = (skuCodes: IMultiSelectOption[]) => {
        const valueString = skuCodes.map(item => item.value).join(',');
        handleFiltersChange("catalogId", valueString);
    };

    const handleSelectionChangeStatus = (selected: IMultiSelectOption[], clearWithCrossIcon: boolean | undefined) => {
        setStatus(selected);
        clearWithCrossIcon && handleSaveStatus(selected);
    };


    const handleSelectionChangeCode = (selected: IMultiSelectOption[], clearWithCrossIcon: boolean | undefined) => {
        setcatalogFilter(selected);
        clearWithCrossIcon && handleSaveSku(selected);
    };

    const handlePaginationChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPageNumber: number) => {
        setPaginationTo(prevPagination => ({
            ...prevPagination,
            page: newPageNumber
        }));
    };

    const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPaginationTo((prevPagination: IPagination) => ({
            ...prevPagination,
            page: 0,
            size: parseInt(event.target.value, 10)
        }));
    };

    const handleClearClick = (filterType: string) => {
        switch (filterType) {
            case 'status':
                setStatus([]);
                handleFiltersChange("status", '');
                break;
            case 'catalogId':
                setcatalogFilter([]);
                handleFiltersChange("catalogId", '');
                break;
        }
    };

    useEffect(() => {
        refreshCatalogues();
    }, [catalogueType, pagination.size, pagination.page, cataloguesFilters]);


    useEffect(() => {
        fetchCatalogueId();
    }, [searchTerm]);

    return (
        <div className='mt-8'>
            <div className={`${classes.heading} text-lg font-medium mb-4`}>
                {CATALOGUES_TYPE[catalogueType as keyof typeof CATALOGUES_TYPE]} ({stats})
            </div>
            <div className='flex justify-between items-center gap-2 mb-4'>
                <FilterChipV2
                    options={catalogueFilterStatusOptions}
                    label=" "
                    value={status}
                    onchange={handleSelectionChangeStatus}
                    placeholder="Status"
                    ClearButtonComponent={Button}
                    buttonLabel={"Clear all"}
                    buttonOnClick={() => handleClearClick('status')}
                    SaveButton={Button}
                    saveButtonLabel='Apply'
                    saveButtonOnClick={() => handleSaveStatus(status)}
                    isSingleSelect={true}
                    minWidth='180px'
                />
                <FilterChipV2
                    options={cataloguesId.map(catalogue => ({
                        label: `${catalogue.id} - ${catalogue.catalogueName}`,
                        value: catalogue.id
                    }))}
                    label=" "
                    value={catalogFilter}
                    onchange={handleSelectionChangeCode}
                    placeholder="Catalog ID"
                    ClearButtonComponent={Button}
                    buttonLabel={"Clear all"}
                    buttonOnClick={() => handleClearClick('catalogId')}
                    SaveButton={Button}
                    saveButtonLabel='Apply'
                    saveButtonOnClick={() => handleSaveSku(catalogFilter)}
                    textFieldPlaceholder='Search...'
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                    isSingleSelect={true}
                    minWidth='200px'
                />
                <div className='flex justify-end ml-auto'>
                    <TextFieldV2
                        label=''
                        variant="outlined"
                        placeholder="Search..."
                        name='search'
                        className={classes.textField}
                        onChange={(event) => {
                            handleFiltersChange("search", event.target.value)
                        }}
                        InputProps={{
                            className: "px-2 h-11 text-base ",
                            startAdornment: (
                                <InputAdornment position="start" style={{ marginLeft: '16px' }}>
                                    <Search className={classes.searchIcon} />
                                </InputAdornment>
                            )
                        }}
                    />
                </div>
            </div>
            {isLoading ? (<Shimmer />) :
                (
                    <div>
                        {cataloguesList.length === 0 ? (
                            <div>
                                <EmptyStateCard
                                    buttonText="Add New Catalog"
                                    onButtonClick={handleAddNewProduct}
                                    message="No Catalog Available"
                                />
                            </div>
                        ) : (
                            <VendorCataloguesListingTemplate
                                cataloguesList={cataloguesList}
                                cataloguesFilters={cataloguesFilters}
                                handlePaginationChange={handlePaginationChange}
                                handleRowsPerPageChange={handleRowsPerPageChange}
                                pagination={pagination}
                                vendorId={user?.businessId ?? 0}
                            />
                        )}

                    </div>
                )}
        </div>
    );
};

export default IVendorCatalogueTableViewTemplate;
