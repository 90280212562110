import React, { useEffect, useMemo, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { IBrand, ICategory, useAdminMasterService } from '../../../../services/useAdminMasterService';
import { CATEGORY_TYPE, HTTP_STATUS } from '../../../../utils/types';
import { useFormik } from 'formik';
import { BorderLeft, Search } from '@mui/icons-material';
import { IMultiSelectOption } from '../../../atoms/MultiSelectInput/MultiSelectInput2';
import Button from '../../../atoms/Button/Button';
import FilterDropdown from './FilterDropDown.template';
import { makeRoute } from '../../../../utils/helper';
import { CUSTOMER_ROUTES } from '../../../../utils/constant';
import { useNavigate } from 'react-router-dom';

const useStyles = createUseStyles((theme: any) => ({
    selectedValue: {
        color: "#FFF !important",
        lineHeight: "22px",
        background: theme.palette.background.primary.primary500,
    },
    unselectedValue: {
        color: "#313335",
        lineHeight: "22px",
        background: 'white',
        border: `1px solid ${theme.palette.border.neutral.neutral300}`,
    },
    searchBar: {
        border: `1px solid ${theme.palette.border.neutral.neutral300}`,
        background: 'rgba(255, 255, 255, 0.70)',
        backdropFilter: 'blur(8px)',
    },
    searchButton: {
        backgroundColor: theme.palette.background.primary.primary500,
        color: "#fff",
        lineHeight: "20px"
    },
    searchIcon: {
        color: "#FFF"
    },
    borderLeft: {
        borderLeft: `1px solid #CACCCE`,
    },
    primaryText: {
        color: '#000',
        fontSize: '24px',
        lineHeight: '28px',
    },
    secondaryText: {
        color: theme.palette.text.primary.primary500,
        fontSize: '36px',
        lineHeight: '38px',
    },
    input: {
        border: '1px solid #CACCCE',
        borderRadius: '51px',
        fontSize: '16px',
    },
    border: {
        width: '0',
        height: '0',
        marginLeft: '20px',
        borderLeft: '10px solid transparent',
        borderRight: '10px solid transparent',
        borderTop: `8px solid ${theme.palette.border.primary.primary500}`,
    },
    popUpBtn: {},
    "@media (max-width: 767px)": {
        searchBar: {},
        primaryText: {
            color: '#000',
            fontSize: '18px',
            lineHeight: '18px',
        },
        secondaryText: {
            color: theme.palette.text.primary.primary500,
            fontSize: '24px',
            lineHeight: '28px',
        },
        input: {
            border: '1px solid #CACCCE',
            borderRadius: '51px',
            fontSize: '16px',
        },
    },
}));

const BannerSearchTemplate: React.FC = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const adminMasterService = useAdminMasterService();
    const [superCategoryData, setSuperCategoryData] = useState<ICategory[] | null>([]);
    const [mainCategoryData, setMainCategoryData] = useState<ICategory[] | null>([]);
    const [productCategoryData, setProductCategoryData] = useState<ICategory[] | null>([]);
    const [brandData, setBrandData] = useState<IBrand[] | null>([]);

    const createOptions = (data: any[] | null, keyName: string = "name") => {
        return data?.map((item: any) => ({ label: item[keyName], value: item.id })) ?? [];
    };

    const superCategoryOptions = useMemo(() => createOptions(superCategoryData), [superCategoryData]);
    const mainCategoryOptions = useMemo(() => createOptions(mainCategoryData), [mainCategoryData]);
    const productCategoryOptions = useMemo(() => createOptions(productCategoryData), [productCategoryData]);
    const brandOptions = useMemo(() => createOptions(brandData), [brandData]);

    const initialFormikValue = {
        superCategory: {
            label: "",
            value: ""
        },
        mainCategoryArray: [],
        productCategoryArray: [],
        brandArray: [],
    }

    const formik = useFormik<any>({
        initialValues: initialFormikValue,
        onSubmit: async (values, { setSubmitting }) => {
            setSubmitting(true);
            const query = {
                superCategory: JSON.stringify(formik.values.superCategory),
                mainCategory: JSON.stringify(formik.values.mainCategoryArray),
                productCategory: JSON.stringify(formik.values.productCategoryArray),
                brand: JSON.stringify(formik.values.brandArray)
            };
            navigate(makeRoute(CUSTOMER_ROUTES.BRAND_COMPARISON, { query }));
        }
    });

    const loadSuperCategory = (inputValue?: string) => {
        adminMasterService.getAllCategories({ level: CATEGORY_TYPE.SUPER_CATEGORY, page: 0, size: 10, sort: 'created_at,desc', parentId: '', name: inputValue ?? '', status: 'ACTIVE' })
            .then(res => {
                if (res.status === HTTP_STATUS.OK)
                    setSuperCategoryData(res?.data?.data?.content)
            }).catch((error) => {
                console.error("Error Fetching Super Category: ", error);
            })
    }

    const loadMainCategory = (inputValue?: string) => {
        if (formik.values.superCategory?.value) {
            adminMasterService.getAllCategories({ level: CATEGORY_TYPE.MAIN_CATEGORY, page: 0, size: 10, sort: 'created_at,desc', parentId: formik.values.superCategory?.value, name: inputValue ?? '', status: 'ACTIVE' })
                .then(res => {
                    if (res.status === HTTP_STATUS.OK)
                        setMainCategoryData(res?.data?.data?.content)
                }).catch((error) => {
                    console.error("Error Fetching Main Category: ", error);
                })
        }
    }

    const loadProductCategory = (inputValue?: string) => {
        if (formik.values.mainCategoryArray[0]?.value) {
            adminMasterService.getAllCategories({ level: CATEGORY_TYPE.PRODUCT_CATEGORY, page: 0, size: 10, sort: 'created_at,desc', parentId: formik.values.mainCategoryArray[0]?.value, name: inputValue ?? '', status: 'ACTIVE' })
                .then(res => {
                    if (res.status === HTTP_STATUS.OK)
                        setProductCategoryData(res?.data?.data?.content)
                }).catch((error) => {
                    console.error("Error Fetching Product Category: ", error);
                })
        }
    }

    const loadBrands = (inputValue?: string) => {
        if (formik.values.productCategoryArray[0]?.value) {
            adminMasterService.getAllBrandsByName({ page: 0, size: 10, sort: 'createdAt,desc', name: inputValue ?? '', productCategoryIds: formik.values.productCategoryArray.map((category: any) => category.value).join(",") })
                .then(res => {
                    if (res.status === HTTP_STATUS.OK)
                        setBrandData(res?.data?.data?.content)
                }).catch((error) => {
                    console.error("Error Fetching Brands: ", error);
                })
        }
    }

    useEffect(() => {
        loadSuperCategory();
    }, []);

    useEffect(() => {
        loadMainCategory();
    }, [formik.values.superCategory?.value]);

    useEffect(() => {
        loadProductCategory();
    }, [formik.values.mainCategoryArray[0]?.value]);

    useEffect(() => {
        loadBrands();
    }, [formik.values.productCategoryArray]);

    const handleToggleClick = (item: any) => {
        formik.setFieldValue("superCategory", item)
    };

    useEffect(() => {
        if (superCategoryOptions.length > 0) {
            formik.setValues({
                ...formik.values,
                superCategory: superCategoryOptions[0],
                mainCategoryArray: [],
                productCategoryArray: [],
                brandArray: []
            });
        }
    }, [superCategoryOptions])

    useEffect(() => {
        formik.setValues({
            ...formik.values,
            mainCategoryArray: [],
            productCategoryArray: [],
            brandArray: []
        });
    }, [formik.values.superCategory])

    useEffect(() => {
        formik.setValues({
            ...formik.values,
            productCategoryArray: [],
            brandArray: []
        });
    }, [formik.values.mainCategoryArray])

    const handleSubmit = (event?: React.FormEvent) => {
        event?.preventDefault();
        formik.handleSubmit();
    };

    const updateSelected = (key: string) => (option: IMultiSelectOption[] | null) => {
        if (option) {
            formik.setFieldValue(`${key}Array`, option);
        }
    };

    return (
        <div className='lg:px-0 lg:py-0 px-6 py-6 lg:w-max w-full'>
            <div className={`${classes.searchBar} flex flex-col items-center justify-between  rounded-[20px] px-4 py-6 lg:px-5 lg:py-9 lg:w-max w-full`}>
                <h3 className={`${classes.primaryText} font-semibold lg:mb-2 mb-1`}>Search, Compare & Find</h3>
                <h2 className={`${classes.secondaryText} font-bold`}>The Best Steel & Sellers</h2>
                <div className={`flex lg:gap-x-3 lg:mt-5 lg:mb-3 mt-3 mb-3 gap-x-1 w-full`}>
                    {superCategoryOptions
                        .map((item: any, index: any) => (
                            <div className='w-1/2'>
                                <div key={item.name} className={`grid justify-center items-center lg:px-2 lg:py-4 p-2 rounded-[9px] ${formik.values.superCategory === (formik.values.superCategory || superCategoryOptions[0]) && formik.values.superCategory === item ? `${classes.selectedValue} font-semibold text-lg cursor-pointer` : `${classes.unselectedValue} font-medium text-lg cursor-pointer`}`} onClick={() => handleToggleClick(item)}>
                                    <div className={`${classes.popUpBtn} lg:text-base text-sm font-semibold leading-[24px] whitespace-normal `}>{item.label}</div>
                                </div>
                                {formik.values.superCategory === item && (
                                    <div className={`${classes.border}`}></div>
                                )}
                            </div>
                        ))}
                </div>

                <div className='flex-1 flex flex-col justify-between lg:gap-y-4 gap-y-2 w-full lg:px-3 px-0 lg:pt-3 pt-0' >
                    <div className={`${classes.input} w-full lg:px-[13px] lg:py-2 !font-medium text-base !bg-white text-black`}>
                        <FilterDropdown
                            options={mainCategoryOptions}
                            label="Main Category"
                            value={formik.values.mainCategoryArray}
                            onchange={updateSelected('mainCategory')}
                            border={false}
                            singleSelect={true}
                        />
                    </div>
                    <div className={`${classes.input} w-full lg:px-[13px] lg:py-2 !font-medium text-base !bg-white text-black`}>
                        <FilterDropdown
                            options={productCategoryOptions}
                            label="Product Category"
                            value={formik.values.productCategoryArray}
                            onchange={updateSelected('productCategory')}
                            border={false}
                        />
                    </div>
                    <div className={`${classes.input} w-full lg:px-[13px] lg:py-2 font-medium text-base !bg-white text-black`}>
                        <FilterDropdown
                            options={brandOptions}
                            label="Brand"
                            value={formik.values.brandArray}
                            onchange={updateSelected('brand')}
                            border={false}
                        />
                    </div>
                </div>
                <div className='w-full flex justify-end mt-4 lg:px-3'>
                    <Button className='!rounded-[100px]' startIcon={<Search className={`${classes.searchIcon} my-auto`} />
                    } variant='primaryContained' label={"Search"} size='medium' onClick={handleSubmit} />
                </div>
            </div>
        </div>
    );
};

export default BannerSearchTemplate;