import { createUseStyles } from "react-jss";
import { toInr } from "../../../utils/helper";
import Button from "../../atoms/Button/Button";
import { UNIT } from "../../../utils/constant";
import AddToCartIcon from '../../../assets/images/addToCart.svg';
import Whatsapp from "../../atoms/Whatsapp/Whatsapp";
import SubjectToTermsConditionTemplate from "./SubjectToTermsCondition.template";
import { useAuthenticatedUser } from "../../../hooks/useAuthenticatedUser";
import { useMemo, useState } from "react";
import emailIcon from "../../../assets/images/emailIcon.svg";
import MailerModalTemplate from "./MailerModal.template";
import emailIconWhiteOutlined from "../../../assets/images/emailIconWhiteOutlined.svg"
import { PriceDisplayProps } from "./PriceDetails.template";

export interface SecondaryPriceDetailsProps {
    minimumPrice: number;
    maximumPrice: number;
    quantity: string;
    warehousename: string;
    gst: number;
    uom: string;
    moq: number;
    handleAddToCart: () => void;
    catalogueBundle?: any | null;
    paymentType?: string | null;
}

const useStyles = createUseStyles((theme: any) => ({
    priceDetailsHeading: {
        background: "white",
        boxShadow: `0px 0px 16px 0px rgba(0, 0, 0, 0.09)`,
        padding: "20px",
        borderRadius: "16px"
    },
    priceText: {
        color: theme.palette.text.primary.primary700,
    },
    priceValue: {
        color: theme.palette.text.neutral.neutral700,
        fontSize: "24px"
    },
    specialPrice: {
        color: theme.palette.text.neutral.neutral700,
    },
    netWeightText: {
        color: theme.palette.text.neutral.neutral900,
    },
    lotQuantity: {
        color: theme.palette.text.neutral.neutral900,
    },
    price: {
        color: theme.palette.text.success.success600,
    },
    uom: {
        color: theme.palette.text.neutral.neutral600,
        fontSize: "14px",
    },
    gst: {
        color: theme.palette.text.neutral.neutral600,
        fontSize: "14px",
    },
    finalPrice: {
        color: theme.palette.text.secondaryDark
    },
    gstText: {
        color: theme.palette.text.neutral.neutral600,
        fontSize: "14px",
    },
    finalPriceText: {
        color: theme.palette.text.primary.primary400
    },
    email: {
        borderRadius: "12px",
        border: `1px solid ${theme.palette.border.primary.primary800}`,
        width: "48px",
        height: "48px",
    },
    emailIcon: {
        width: "48px",
        height: "48px",
        border: `1px solid ${theme.palette.border.primary.primary800}`,
    },
    webContainer: {
        display: "grid"
    },
    termAndCondition: {
        display: "flex"
    },
    webButton: {
        display: "flex"
    },
}));

const BundlePriceDetailsTemplate: React.FC<SecondaryPriceDetailsProps> = ({ handleAddToCart, paymentType, minimumPrice, maximumPrice, warehousename, quantity, gst, uom, moq, catalogueBundle }) => {

    const classes = useStyles();
    const { user } = useAuthenticatedUser();

    const [dialogOpen, setDialogOpen] = useState<boolean>(false);

    const handleRfqForm = () => {
        setDialogOpen(true);
    }

    // TODO: Will modify after getting data from backend
    const PriceDisplay: React.FC<PriceDisplayProps> = ({ price, uom, gstText, classes }) => (
        <div className="flex text-2xl font-semibold tracking-tighter mb-2 whitespace-nowrap">
            <div className="flex">
                <span className={`${classes.priceValue} font-semibold`}>
                    {price}
                </span>
            </div>
            <span className={`${classes.uom} font-bold mt-0 mx-0.5 text-sm`}>*</span>
            <span className={`${classes.uom} font-normal my-auto mr-2 text-sm`}>{`/${uom || "MT"}`}</span>
            <div className={`${classes.gst} font-medium my-auto text-sm`}>
                <span className="mr-1">Exclu. </span>
                <span>GST@{gst}%</span>
            </div>
        </div>
    );

    const productPriceDisplay = useMemo(() => {
        const gstText = "Exclu. ";

        const outOfStock = !warehousename || parseFloat(quantity) <= 0;

        if (outOfStock) {
            return (
                <PriceDisplay
                    price="₹ - "
                    uom={uom}
                    gstText={gstText}
                    classes={classes}
                />
            );
        }

        const priceRange = minimumPrice === maximumPrice
            ? toInr(minimumPrice || 0)
            : `${toInr(minimumPrice || 0)} - ${toInr(maximumPrice || 0)}`;

        return (
            <PriceDisplay
                price={priceRange}
                uom={uom}
                gstText={gstText}
                classes={classes}
            />
        );
    }, [warehousename, minimumPrice, maximumPrice, toInr, uom, classes]);


    const FinalPriceText = useMemo(() => {
        let message = "For Final Price/Quotation, Click Here.";
        let rfqFlag = false;

        if (!warehousename || parseFloat(quantity) <= 0) {
            message = "Out of Stock";
        }

        return (
            <>
                <div className={`${classes.finalPriceText} text-sm font-medium mb-1`}>{message}</div>
                <div className={`${classes.finalPriceText} text-sm font-medium mb-1`}>{rfqFlag ? `Please refer to 'Request for Quotation'` : null}</div>
            </>
        );
    }, [warehousename, quantity, moq, classes.finalPriceText]);


    return (
        <div className={`grid  ${classes.priceDetailsHeading} gap-y-3`}>
            {/* TODO: Will uncomment after getting data from backend */}
            <div className={`${classes.webContainer}  gap-y-4 pb-3 border-b`}>
                <div className="flex flex-col border-b pb-4">
                    <span className={`${classes.priceText} text-xl font-medium mb-3`}>Steel Bazaar Price</span>
                    {productPriceDisplay}
                </div>
                <div className="flex flex-col">
                    <span className={`text-xl font-medium mb-1 ${classes.netWeightText}`}>Net Weight</span>
                    <span className={`flex gap-2 text-2xl font-semibold mb-1 ${classes.lotQuantity}`}>{quantity} {UNIT.UOM}</span>
                </div>
            </div>

            <div>

                <div className={`text-sm font-medium mt-1 ${classes.specialPrice}`}>Your Final Price (After Discounts)</div>

                {productPriceDisplay}

                {FinalPriceText}

                <div className={`${classes.finalPriceText} text-sm font-medium mb-2`}>{(!paymentType) ? "Please select payment method to add to cart." : null}</div>

                <div className="flex justify-between gap-x-2">
                    <div className={`${classes.webButton} flex-1`}>
                        <Button variant="primaryContained" onClick={handleAddToCart} fullWidth label="Get Best Quote" startIcon={<img src={AddToCartIcon} alt="icon" />} />
                    </div>

                    <Whatsapp isActive />

                    {/* {user?.email && (
                        <div className={`${dialogOpen ? classes.emailIcon : classes.email} cursor-pointer grid justify-center content-center`} onClick={handleRfqForm}>
                            <img className="" src={dialogOpen ? emailIconWhiteOutlined : emailIcon} alt="" />
                        </div>
                    )} */}

                    {/* <MailerModalTemplate dialogOpen={dialogOpen} setDialogOpen={setDialogOpen} warehouse={warehousename} catalogueBundle={catalogueBundle} /> */}
                </div>
            </div>
            <div className={`${classes.termAndCondition}`}>
                <SubjectToTermsConditionTemplate />
            </div>
        </div>
    );
}

export default BundlePriceDetailsTemplate;