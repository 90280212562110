import React from 'react';
import { createUseStyles } from 'react-jss';
import standardixedIcon from '../../../assets/icons/standardixedIcon.svg';
import scalableIcon from '../../../assets/icons/scalableIcon.svg';
import sustainibilityIcon from '../../../assets/icons/sustainibilityIcon.svg';
import secureIcon from '../../../assets/icons/secureIcon.svg';
import strategicIcon from '../../../assets/icons/strategicIcon.svg';
import streamlinedIcon from '../../../assets/icons/streamlinedIcon.svg';
import seamlessIcon from '../../../assets/icons/seamlessIcon.svg';
import smartIcon from '../../../assets/icons/smartIcon.svg';

const useStyles = createUseStyles((theme: any) => ({
    container: {
        width: '100%',
        padding: '32px 12px',
        background: '#F8F8F8',
    },
    sectionHeading: {
        color: "#000",
        fontSize: '28px',
        lineHeight: '34px',
        "& span": {
            color: theme.palette.text.primary.primary500,
        },
        "& p": {
            color: theme.palette.text.success.success500,
        },
    },
    imgContainer1: { background: theme.palette.background.secondary.secondary400, border: `1px solid ${theme.palette.border.secondary.secondary400}` },
    imgContainer2: { background: theme.palette.background.success.success500, border: `1px solid ${theme.palette.border.success.success500}` },
    imgContainer3: { background: theme.palette.background.complementary.complementary300, border: `1px solid ${theme.palette.border.complementary.complementary300}` },
    imgContainer4: { background: '#FD8235', border: '1px solid #FD8235' },
    imgContainer5: { background: theme.palette.background.success.success400, border: `1px solid ${theme.palette.border.success.success400}` },
    imgContainer6: { background: theme.palette.background.secondary.secondary700, border: `1px solid ${theme.palette.border.secondary.secondary700}` },
    imgContainer7: { background: theme.palette.background.warning.warning400, border: `1px solid ${theme.palette.border.warning.warning400}` },
    imgContainer8: { background: theme.palette.background.primary.primary400, border: `1px solid ${theme.palette.border.primary.primary400}` },
    text1: { color: theme.palette.text.secondary.secondary400 },
    text2: { color: theme.palette.text.success.success500 },
    text3: { color: theme.palette.text.complementary.complementary300 },
    text4: { color: '#FD8235' },
    text5: { color: theme.palette.text.success.success500 },
    text6: { color: theme.palette.text.secondary.secondary700 },
    text7: { color: theme.palette.text.warning.warning400 },
    text8: { color: theme.palette.text.primary.primary400 },
    marketPlaceCard: { border: `0.5px solid ${theme.palette.border.neutral.neutral200}`, background: '#fff', "& p": { color: theme.palette.text.neutral.neutral800 } },
    primarytext: {},
}));

const Card = ({ imgContainerClass, icon, textClass, number, title, description }: any) => {
    const classes = useStyles();
    return (
        <div className={`${classes.marketPlaceCard} p-2 rounded-lg`}>
            <div className="flex gap-2 mb-2">
                <div className={`${imgContainerClass} h-[54px] w-[54px] flex items-center justify-center rounded-lg`}>
                    <img src={icon} alt={title} />
                </div>
                <div className={`${classes.primarytext} flex flex-col`}>
                    <h4 className={`${textClass} font-semibold text[22px] leading-[26px]`}>{number}</h4>
                    <h5 className="leading-[26px] font-semibold text-[22px]">{title}</h5>
                </div>
            </div>
            <p className="text-[14px] leading-4">{description}</p>
        </div>
    );
};

const MarketPlaceTemplate: React.FC = () => {
    const classes = useStyles();

    const cards = [
        {
            imgContainerClass: classes.imgContainer1,
            icon: standardixedIcon,
            textClass: classes.text1,
            number: "01",
            title: "Smart",
            description: "Trade with AI-driven tools and insights for optimized trading and decision-making",
        },
        {
            imgContainerClass: classes.imgContainer2,
            icon: scalableIcon,
            textClass: classes.text2,
            number: "02",
            title: "Seamless",
            description: "Sourcing from Multiple Global & Domestic Manufacturers with Quality Assurance",
        },
        {
            imgContainerClass: classes.imgContainer3,
            icon: sustainibilityIcon,
            textClass: classes.text3,
            number: "03",
            title: "Streamlined",
            description: "Digital, Automated & Transparent Workflows for inventory & order management",
        },
        {
            imgContainerClass: classes.imgContainer4,
            icon: secureIcon,
            textClass: classes.text4,
            number: "04",
            title: "Strategic",
            description: "Savings through Bulk pricing, cost-effective sourcing, and financing options",
        },
        {
            imgContainerClass: classes.imgContainer5,
            icon: strategicIcon,
            textClass: classes.text5,
            number: "05",
            title: "Secure",
            description: "Fast and Easy Transactions: Trade with Confidence",
        },
        {
            imgContainerClass: classes.imgContainer6,
            icon: streamlinedIcon,
            textClass: classes.text6,
            number: "06",
            title: "Standardized",
            description: "And Industry Compliant processes and materials for assured, regulated quality",
        },
        {
            imgContainerClass: classes.imgContainer7,
            icon: seamlessIcon,
            textClass: classes.text7,
            number: "07",
            title: "Scalable",
            description: "Growth through discovering new global markets and connections",
        },
        {
            imgContainerClass: classes.imgContainer8,
            icon: smartIcon,
            textClass: classes.text8,
            number: "08",
            title: "Sustainability",
            description: "Commitment to eco-friendly practices, including recycling, promotes environmental responsibility.",
        },
    ];

    return (
        <div className={`${classes.container} flex flex-col gap-4`}>
            <h2 className={`${classes.sectionHeading} font-semibold text-center`}>Unlocking the 8S Power of Marketplace Smart <span>Sourcing </span>to <p className='inline-block'>Sustainability</p></h2>
            {cards.map((card, index) => (
                <Card key={index} {...card} />
            ))}
        </div>
    );
};

export default MarketPlaceTemplate;
