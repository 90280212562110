import React from 'react';
import { createUseStyles } from 'react-jss';
import productServicesBanner from '../../../../assets/images/productServicesBanner.jpg';

const useStyles = createUseStyles((theme: any) => ({
    statsMainContainer: {
        background: `url(${productServicesBanner}) #08102d`,
        backgroundSize: "cover",
    },
    statsContainer: {
        borderRadius: "12px"
    },
    statsHeading: {
        fontWeight: 600,
        color: '#fff',
        lineHeight: '58px',
        "& span": {
            color: theme.palette.text.secondary.secondary600,
        },
    },
    stats: {},
    sliderContent: {
        rowGap: "8px",
        "& p": {
            fontSize: '40px',
            color: theme.palette.text.warning.warning400,
            lineHeight: '48px'
        },
        "& span": {
            fontSize: '18px',
            color: '#fff',
            lineHeight: '22px',
            fontWeight: '500',
        },
    },
    "@media (max-width: 767px)": { 
        statsMainContainer: {
         display: 'none',
        },
    }
}));

const ProductServices: React.FC = () => {
    const classes = useStyles();

    return (
        <>
            <div className={`${classes.statsMainContainer} lg:px-4 lg:py-10`}>
                <div className={`${classes.statsContainer} mx-auto grid lg:grid-cols-1 md:gap-10 md:grid-cols-1`}>
                    <div className={`${classes.statsHeading} text-center text-5xl`}>Products & Services Tailored to Your Needs.</div>
                    <div className='flex justify-center'>
                        <div className={`${classes.stats}  grid grid-cols-5 w-full lg:mx-[180px]`}>
                        <div className={`${classes.sliderContent} grid text-center`}>
                                <p className='font-bold mb-2'>200+</p>
                                <span>Product Categories</span>
                            </div>
                            <div className={`${classes.sliderContent} grid text-center`}>
                                <p className='font-bold mb-2'>10K+</p>
                                <span>Products</span>
                            </div>
                            <div className={`${classes.sliderContent} grid text-center`}>
                                <p className='font-bold mb-2'>5K+</p>
                                <span>Sellers</span>
                            </div>
                            <div className={`${classes.sliderContent} grid text-center`}>
                                <p className='font-bold mb-2'>25+</p>
                                <span>Industries</span>
                            </div>
                            <div className={`${classes.sliderContent} grid text-center`}>
                                <p className='font-bold mb-2'>6K+</p>
                                <span>Buyers</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ProductServices;