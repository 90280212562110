import React, { useEffect, useState } from 'react'
import yellowVerifiedIcon from "../../../assets/icons/yellowVerifiedUser.svg"
import blueVerifiedIcon from "../../../assets/icons/blueVerifiedUser.svg"
import WhatsappLabel from "../../atoms/Whatsapp/WhatsappLabel";
import CallButton from '../../atoms/Button/CallButton';
import { createUseStyles } from 'react-jss';
import userAvatar from "../../../assets/icons/userAvatar.svg"
import { useSnackbar } from '../../../hooks/useSnackBar';
import { IBusinessProfile, useBusinessProfileService } from '../../../services/useBusinessProfileService';
import { HTTP_STATUS } from '../../../utils/types';
import { ILocationGstinForm } from '../../pages/DashBoard/BussinessLocation.page';
import { VENDOR_TYPE } from '../../../utils/constant';
import Button from '../../atoms/Button/Button';
import { useNavigate, useParams } from 'react-router-dom';

interface StoreFrontGstDetailsTemplateProps {
    businessProfile: IBusinessProfile | null;
}

const useStyles = createUseStyles((theme: any) => ({
    vendorDetails: {
        color: theme.palette.text.neutral.neutral600,
    },
    heading: {
        color: theme.palette.text.primary.primary900,
        textTransform: "capitalise"
    },
    borderBottom: {
        borderBottom: `1px solid ${theme.palette.border.neutral.neutral100}`
    },
}));

const initialValues = {
    locations: [],
    noOfRows: null,
    newLocations: []
}

export const StoreFrontGstDetailsTemplate: React.FC<StoreFrontGstDetailsTemplateProps> = ({ businessProfile }) => {

    const classes = useStyles();
    const navigate = useNavigate()
    const { showSnackbar, SnackBarComponent } = useSnackbar();
    // const bussinessProfileService = useBusinessProfileService();

    // const [businessProfile, setBusinessProfileTo] = useState<IBusinessProfile | null>(null);

    // const getBusinessProfile = async () => {
    //     try {
    //         const businessProfileResponse = await bussinessProfileService.getBusinessProfile();
    //         if (businessProfileResponse.status === HTTP_STATUS.OK) {
    //             const profileData = businessProfileResponse?.data?.data;
    //             setBusinessProfileTo(profileData);
    //         }
    //     } catch (error) {
    //         showSnackbar('error', 'Business Profile fetch failed');
    //     }
    // };

    // useEffect(() => {
    //     getBusinessProfile();
    // }, []);

    const params = useParams();
    return (
        <div className={`${classes.borderBottom} flex justify-between py-6`}>
            <div className="flex gap-4">
                <div>
                    <img className="rounded-xl" width={"54px"} height={"54px"} src={userAvatar} alt="user avatar" />
                </div>
                <div className="flex flex-col justify-center">
                    <div className="flex text-2xl font-semibold items-center">
                        <div className={`${classes.heading} capitalize`}>{businessProfile?.name?.toLowerCase() || businessProfile?.user?.fullName.toLowerCase()}</div>
                        <img width={"24px"} height={"24px"} src={blueVerifiedIcon} alt="" />
                    </div>
                    <div className={`${classes.vendorDetails} flex gap-1 capitalize`}>
                        {businessProfile?.address?.city && <div>{`${businessProfile?.address?.city?.toLowerCase()}, ${businessProfile?.address?.country?.toLowerCase()} |`}</div>}
                        {businessProfile?.vendorType && <div>{`${VENDOR_TYPE.filter(({ value }) => value === businessProfile?.vendorType)[0]?.label} |`}</div>}
                        {businessProfile?.gstin ? <div>{`GST No. ${businessProfile?.gstin} |`}</div> : <div>{`GST No. ${businessProfile?.user?.gstin} |`}</div>}
                        <div className="flex gap-1">
                            <img width={"24px"} height={"24px"} src={yellowVerifiedIcon} alt="verified icon" />
                            <div>{`Trusted Supplier`}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex gap-2">
                {businessProfile?.user?.whatsappNumber && <WhatsappLabel label={`${businessProfile?.user?.whatsappNumber}`} />}
                {businessProfile?.user?.mobileNumber && <CallButton label={`${businessProfile?.user?.mobileNumber}`} />}
                {isNaN(Number(params?.id)) &&
                    <Button
                        variant="primaryContained"
                        label="Back To Dashboard"
                        size='medium'
                        onClick={() => { navigate('/dashboard/seller-dashboard'); }}
                    />
                }
            </div>
        </div>
    )
}
