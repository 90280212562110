import { createUseStyles } from "react-jss";
import Dialog from '@mui/material/Dialog';
import locationIcon from "../../../assets/images/locationIcon.svg"
import Button from "../../atoms/Button/Button";
import { ICatalogueUpc } from "../../pages/CatalogueDetail/CatalogueDetails.page";
import { useAuthenticatedUser } from "../../../hooks/useAuthenticatedUser";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import deleteIcon from "../../../assets/images/deleteIcon.svg"
import * as Yup from "yup";
import { useFormik } from "formik";
import { IMailSenderRequestBody, useMailSenderService } from "../../../services/useMailSenderService";
import { HTTP_STATUS } from "../../../utils/types";
import { REGEX } from "../../../utils/constant";
import { ICatalogue } from "../../pages/CatalogueDetail/MultipleUpcCatalogueDetails.page";
// import { ISecondaryCatalogue } from "../../pages/Catalogue/SecondaryCatalogueDetails.page";
import useMetaDataService from "../../../hooks/useMetaDataService";
import { sortSpecificationAttributes } from "../../../utils/helper";
import TextFieldV2 from "../../atoms/Input/TextFieldV2";
import NoImage from '../../../assets/images/noImage.jpg'


interface MailerModalTemplateProps {
    dialogOpen: boolean;
    setDialogOpen: (open: boolean) => void;
    selectedUpc?: ICatalogueUpc | null;
    warehouse: string | null;
    catalogue?: ICatalogue;
    // secondaryCatalogue?: ISecondaryCatalogue | null;
    secondaryCatalogue?: any | null;
}

interface IMailSenderForm {
    to: string[];
    message: string;
    data: any;
}

const useStyles = createUseStyles((theme: any) => ({
    container: {
        "& .MuiDialog-paper": {
            width: "615px !important",
            maxWidth: "615px !important",
            borderRadius: "21px"
        }
    },
    mainContainer: {
        padding: "32px",
        rowGap: "24px"
    },
    form: {
        rowGap: "32px"
    },
    productWebContainer: {
        display: "flex",
        borderRadius: "20px",
        border: `1px solid  ${theme.palette.border.neutral.neutral50}`,
        padding: "18px 26px"
    },
    productMobileContainer: {
        display: "none"
    },
    heading: {
        color: theme.palette.text.neutral.neutral900,
        fontWeight: 600,
    },
    closeIcon: {
        color: theme.palette.text.secondary.secondary500
    },
    image: {
        width: "133px",
        height: "84px",
        borderRadius: "12px",
        border: `1px solid  ${theme.palette.border.neutral.neutral50}`,
    },
    productTitle: {
        height: "50px",
        color: theme.palette.text.secondary.secondary900,
        lineHeight: "25px",
        fontSize: "20px"
    },
    location: {
        height: "22px",
        borderRadius: "100px",
        backgroundColor: theme.palette.background.primary.primary50,
        color: theme.palette.text.secondary.secondary900,
    },
    to: {
        color: theme.palette.text.neutral.neutral800
    },
    deleteIcon: {
        width: "52px",
        height: "52px",
        padding: "14px",
        backgroundColor: theme.palette.background.primary.primary100,
    },
    errorMessage: {
        color: theme.palette.text.secondary.secondary500,
    },
    addMoreButton: {
        color: theme.palette.text.primary.primary500,
        marginLeft:"-20px",
        transition: 'color 0.3s ease, padding-left 0.3s ease',
        "&:hover":{
            // marginLeft:"0px"
        }
    },
    buttonWebContainer: {
        display: "flex"
    },
    buttonMobileContainer: {
        display: "none"
    },
    "@media (max-width: 767px)": {
        container: {
            "& .MuiDialog-paper": {
                width: "448px !important",
                maxWidth: "448px !important",
                margin: "16px",
                maxHeight: "calc(100% - 16px)"
            }
        },
        mainContainer: {
            marginBottom: "0px",
            padding: "16px",
            rowGap: "16px"
        },
        heading: {
            fontWeight: 500,
        },
        productWebContainer: {
            display: "none"
        },
        productMobileContainer: {
            display: "grid",
            borderBottom: `1px solid  ${theme.palette.border.neutral.neutral50}`,
        },
        productTitle: {
            height: "auto",
            lineHeight: "25px",
            fontSize: "18px"
        },
        image: {
            width: "100%",
            height: "234px",
        },
        addMoreButton: {
            padding: "0px !important",
        },
        buttonWebContainer: {
            display: "none"
        },
        buttonMobileContainer: {
            display: "flex"
        },
        form: {
            rowGap: "24px"
        },
    },
}));

const MailerModalTemplate: React.FC<MailerModalTemplateProps> = ({ dialogOpen, setDialogOpen, selectedUpc, warehouse, catalogue, secondaryCatalogue }) => {

    const classes = useStyles();
    const { user } = useAuthenticatedUser();
    const mailSenderService = useMailSenderService();
    const [productAttributesRankWise, setProductAttributesRankWiseTo] = useState<any>([]);
    const [sortedCatalogueSpecificationAttributes, setSortedCatalogueSpecificationAttributes] = useState<any>([]);
    const { metaData: filterData } = useMetaDataService();
    const [recipientEmails, setRecipientEmails] = useState(['']);

    useEffect(() => {
        setProductAttributesRankWiseTo(filterData.attributes);
        setSortedCatalogueSpecificationAttributes(catalogue ? sortSpecificationAttributes(selectedUpc?.attributes?.SPECIFICATION ?? [], productAttributesRankWise) : sortSpecificationAttributes(secondaryCatalogue?.attributes.productSpecification, productAttributesRankWise))
    }, [dialogOpen, catalogue, filterData.attributes])

    useEffect(() => {
        formik.setValues(prevValues => ({
            ...prevValues,
            data: {
                ...prevValues.data,
                attributes: (sortedCatalogueSpecificationAttributes)?.map((attribute: any) => ({
                    name: attribute.name,
                    value: catalogue ? attribute.attributeOptions : `${attribute.minValue === attribute.maxValue ? attribute.minValue : `${attribute.minValue} - ${attribute.maxValue}`}`,
                    uom: attribute.uom !== '' ? attribute.uom : null
                })) || [],
            }
        }));
    }, [sortedCatalogueSpecificationAttributes]);

    const handleDialogClose = () => {
        setDialogOpen(false);
        formik.resetForm();
        setRecipientEmails([''])
    };

    const validationSchema = Yup.object().shape({
        to: Yup.array().of(Yup.string().email('Invalid email address').matches(REGEX.EMAIL, 'Enter valid Email')).min(1, 'At least one recipient email is required').required('At least one recipient email is required')
            .test('unique', 'Duplicate emails are not allowed', function (value) {
                const emails = value || [];
                const uniqueEmails = new Set(emails);
                return uniqueEmails.size === emails.length;
            }),
        message: Yup.string().required('Message is required'),
    });

    const initialFormValues: IMailSenderForm = {
        to: [],
        message: "",
        data: {
            name: catalogue ? catalogue?.name : secondaryCatalogue?.name || '',
            location: warehouse || '',
            imageUrl: (catalogue && catalogue.catalogueImages && catalogue.catalogueImages[0]?.path) 
                  || (secondaryCatalogue && secondaryCatalogue.catalogueImages && secondaryCatalogue.catalogueImages[0]?.path) 
                  || 'https://public-avrp.s3.ap-south-1.amazonaws.com/misc_images/noImage.jpg',
            url: window.location.href || '',
            attributes: (sortedCatalogueSpecificationAttributes)?.map((attribute: any) => ({
                name: attribute.name,
                value: catalogue ? attribute.attributeOptions : `${attribute.minValue == attribute.maxValue ? attribute.minValue : `${attribute.minValue} - ${attribute.maxValue}`}`,
                uom: attribute.uom !== '' ? attribute.uom : null
            })) || [],
        },
    };

    const formik = useFormik<IMailSenderForm>({
        enableReinitialize: true,
        initialValues: initialFormValues,
        validationSchema: validationSchema,
        validateOnMount: true,
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            setSubmitting(true);
            const mailSenderRequestBody: IMailSenderRequestBody = {
                to: values.to,
                message: values.message,
                data: values.data
            }
            try {
                const mailSenderResponse = await mailSenderService.sendMail(mailSenderRequestBody);
                if (mailSenderResponse.status === HTTP_STATUS.OK) {
                    setDialogOpen(false);
                    resetForm();
                    setRecipientEmails([''])
                }
            } catch (error) {
                return false;
            }
        },
    });

    const addRecipientField = () => {
        setRecipientEmails([...recipientEmails, '']);
    };

    const removeRecipientField = (index: any) => {
        const updatedEmails = [...recipientEmails];
        updatedEmails.splice(index, 1);
        setRecipientEmails(updatedEmails);
        formik.setFieldValue('to', updatedEmails);
    };

    const handleRecipientEmailChange = (email: any, index: any) => {
        const updatedEmails = [...recipientEmails];
        updatedEmails[index] = email;
        setRecipientEmails(updatedEmails);
        formik.setFieldValue('to', updatedEmails);
    };

    return (
        <Dialog fullWidth className={classes.container}
            open={dialogOpen} onClose={handleDialogClose}>
            <div className={`grid ${classes.mainContainer}`} >
                <div className="flex justify-between">
                    <div className={`${classes.heading} text-2xl`}>Email this Product</div>
                    <CloseIcon className={`${classes.closeIcon} my-auto`} onClick={handleDialogClose} />
                </div>

                <div className={`${classes.productWebContainer} flex gap-x-4`}>
                    {
                        catalogue &&
                        <img className={`${classes.image}`} src={catalogue?.catalogueImages?.[0]?.path ?? NoImage} alt="productImage" />
                    }
                    {
                        secondaryCatalogue &&
                        <img className={`${classes.image}`} src={secondaryCatalogue?.catalogueImages[0]?.path ? secondaryCatalogue?.catalogueImages[0]?.path : NoImage} alt="productImage" />
                    }
                    <div className="grid gap-y-3">
                        <div className={`${classes.productTitle} text-xl font-semibold`}>{catalogue ? catalogue?.name : secondaryCatalogue?.name}</div>
                        <div className={`${classes.location} flex gap-x-1 py-1 px-2 w-fit`}>
                            <img src={locationIcon} alt="" />
                            <div className="text-xs">{warehouse}</div>
                        </div>
                    </div>
                </div>

                <div className={`${classes.productMobileContainer} grid gap-y-3 pb-4`}>
                    <div className="grid gap-y-3">
                        <div className={`${classes.productTitle} text-xl font-semibold`}>{catalogue ? catalogue?.name : secondaryCatalogue?.name}</div>
                        <div className={`${classes.location} flex gap-x-1 py-1 px-2 w-fit`}>
                            <img src={locationIcon} alt="" />
                            <div className="text-xs">{warehouse}</div>
                        </div>
                    </div>
                    <img className={`${classes.image}`} src={ catalogue?.catalogueImages?.[0]?.path ?? secondaryCatalogue?.catalogueImages?.[0]?.path ?? NoImage } alt="productImage" />
                </div>

                <form onSubmit={formik.handleSubmit}>
                    <div className={`${classes.form} grid`}>
                        <div className="grid gap-y-3">
                            <div className={`${classes.to} text-base font-semibold`}>To</div>
                            <div className="grid gap-y-3">
                                {recipientEmails.map((email: any, index: any) => (
                                    <div className="flex flex-col">
                                        <div className="flex gap-2">
                                            <div className="w-full">
                                                <TextFieldV2
                                                    label={`Recipient Email ${index + 1}`}
                                                    type="text"
                                                    fullWidth
                                                    placeholder="Enter here"
                                                    value={formik.values.to[index]}
                                                    onChange={(e) => handleRecipientEmailChange(e.target.value, index)}
                                                    />
                                            </div>
                                            {recipientEmails.length > 1 && (
                                                <div className={`${classes.deleteIcon} rounded-lg self-end`} onClick={() => removeRecipientField(index)}>
                                                    <img src={deleteIcon} alt="delete" />
                                                </div>
                                            )}
                                        </div>
                                        {formik.touched && formik.touched?.to && formik.errors?.to && (
                                            <div className={`${classes.errorMessage}`}>
                                                {Array.isArray(formik.errors.to) && formik.errors.to.length > 0 ? (
                                                    <small>{formik.errors?.to[index]}</small>
                                                ) : (
                                                    <small>{formik.errors.to}</small>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
                            {recipientEmails.length < 4 && (
                                <div className={`${classes.buttonWebContainer}`}>
                                    <Button label="Add More" variant="primaryText" iconPosition="15px" className={`${classes.addMoreButton}`} onClick={addRecipientField} />
                                </div>
                            )}
                            {recipientEmails.length < 4 && (
                                <div className={`${classes.buttonMobileContainer} justify-between`}>
                                    <Button size="small" label="Add More" variant="primaryText"  className={`${classes.addMoreButton}`} onClick={addRecipientField} />
                                </div>
                            )}
                        </div>

                        <div className="grid gap-y-3">
                            <div className={`${classes.to} text-base font-semibold`}>From</div>
                            <div className="grid">
                                <TextFieldV2
                                    label="Your Email"
                                    type="text"
                                    placeholder="Enter here"
                                    value={user?.email || user?.fullName}
                                    disabled={true}
                                />
                            </div>
                            <div className="grid">
                                <TextFieldV2
                                    label="Message"
                                    fullWidth
                                    type="text"
                                    placeholder="Enter here"
                                    multiline
                                    maxRows={4}
                                    {...formik.getFieldProps("message")}
                                />
                                {formik.touched && formik.touched?.message && formik.errors?.message && (
                                    <div className={classes.errorMessage}>
                                        <small>{formik.errors?.message}</small>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className={`${classes.buttonWebContainer} justify-between`}>
                            <Button label="Cancel" variant="tertiaryContained" onClick={handleDialogClose} />
                            <Button label="Send" variant="primaryContained" type="submit" disabled={formik.isSubmitting} isLoading={formik.isSubmitting} />
                        </div>
                        <div className={`${classes.buttonMobileContainer} justify-between`}>
                            <Button size="small" label="Cancel" variant="tertiaryContained" onClick={handleDialogClose} />
                            <Button size="small" label="Send" variant="primaryContained" type="submit" disabled={formik.isSubmitting} isLoading={formik.isSubmitting} />
                        </div>
                    </div>
                </form>
            </div>
        </Dialog>
    );
};

export default MailerModalTemplate;