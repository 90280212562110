interface PlatformAndIntellectualPropertyProps {
    contentStyle?: string;
    boxStyle?: string;
    subHeading?: string;
    header?: string;
}

const PlatformAndIntellectualProperty: React.FC<PlatformAndIntellectualPropertyProps> = ({ contentStyle, header, boxStyle, subHeading }) => {
    return (
        <>
            <div className={boxStyle}>
                <span className={subHeading}>{header}</span>
            </div>
            <div className={contentStyle}>
                <p>All intellectual property rights related to content on the Platform, including text, graphics, logos, design, photographs, software, data compilations, and digital downloads, remain exclusively with SteelBazaar or respective third-party licensors and suppliers. You must not copy, extract, reverse-engineer, republish, or create derivative works from such content without explicit prior written approval from Us.</p>
                <h4 className="mt-3 mb-1 text-xl font-semibold">Trademarks</h4>
                <p>SteelBazaar domain names, trademarks, logos, and service marks displayed are the exclusive property of SteelBazaar and must not be used in ways causing confusion or discredit without explicit written consent.</p>
                <h4 className="mt-3 mb-1 text-xl font-semibold">Reporting Intellectual Property Infringement</h4>
                <p>If You believe Your intellectual property rights have been infringed, promptly contact Our legal team by email at the address listed on Our Platform, providing detailed evidence and identification of the allegedly infringing content. Appropriate actions will be undertaken upon verification.</p>
                <h4 className="mt-3 mb-1 text-xl font-semibold">Software Updates</h4>
                <p>Occasionally, we may conduct software updates on Our Platform to enhance its functionality. These updates may occur without prior notice and could involve automatic or manual updates.</p>
            </div>
        </>
    )
}

export default PlatformAndIntellectualProperty;