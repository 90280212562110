import React, { useEffect, useMemo, useState } from 'react'
import { createUseStyles } from 'react-jss';
import { useAuthenticatedUser } from '../../../hooks/useAuthenticatedUser';
import Button from '../../atoms/Button/Button';
import { Badge } from "@mui/material";
import bell from '../../../assets/icons/bellIcon.svg'
import Drawer from '@mui/material/Drawer';
import CloseIcon from '@mui/icons-material/Close';
import { HTTP_STATUS, USER_PROFILE_TYPE } from '../../../utils/types';
import { useSnackbar } from '../../../hooks/useSnackBar';
import { useBuyerService } from '../../../services/useBuyerService';
import { useOutletContext } from "react-router-dom";
import comingSoonLogo from '../../../assets/images/comingSoonLogo.svg';
import DisplayImageV2 from '../../molecules/ImageUpload/DisplayImageV2';

const useStyles = createUseStyles((theme: any) => ({
    userName: {
        color: theme.palette.text.neutral.neutral1000,
        fontSize: "18px"
    },
    customBadge: {
        "& .MuiBadge-badge": {
            color: "white",
            backgroundColor: theme.palette.background.complementary.complementary200,
            borderRadius: "4px"
        }
    },
    container: {
        border: `1px solid #EBEFFA`
    },
    filterHeading: {
        color: theme.palette.text.primary.primary950,
    },
    body: {
        border: `1px solid ${theme.palette.border.neutral.neutral100}`,
    },
}));

interface Notifications {
    id: number;
    notificationId: number;
    notificationType: string;
    notificationMessage: string;
    read: boolean;
    createdAt: string;
    updatedAt: string;
    createdBy: string;
    updatedBy: string;
    notificationElapsedTime: string;
}

const DashboardHeaderTemplate: React.FC = () => {

    const classes = useStyles();
    const { user, setAuthenticatedUser } = useAuthenticatedUser();
    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const buyerService = useBuyerService();

    const [open, setOpen] = React.useState(false);
    const [unreadCount, setUnreadCount] = useState(0);
    const [notifications, setNotificationsTo] = useState<Notifications[]>([])
    const [comingSoon, setComingSoonTo] = useState<boolean>(false);
    const { sellerToggleCallback } = useOutletContext<any>();

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
   
    const getBuyerNotifications = () => {
        buyerService.getBuyerNotifications()
            .then((notificationsResponse) => {
                if (notificationsResponse.status === HTTP_STATUS.OK) {
                    setNotificationsTo(notificationsResponse?.data?.data)
                }
                else {
                    setNotificationsTo([]);
                }
            })
            .catch((error) => {
                setNotificationsTo([]);
                showSnackbar('error', 'Something went wrong while fetching notification details')
            });
    }

    useEffect(() => {
        getBuyerNotifications()
    }, [])

    const activeContent = useMemo(() => {
        return (
            <div className={`${classes.container} p-4 gap-6 flex flex-col h-full relative`}>
                <div className="flex  justify-between">
                    <div className={`${classes.filterHeading} text-lg font-semibold my-auto `}>Notification</div>
                    <div className={`cursor-pointer`} onClick={handleClose}>
                        <CloseIcon />
                    </div>
                </div>
                <div className={` flex flex-col gap-3 `}>
                    {notifications?.map((item: Notifications, index: number) => (
                        <div className={`${classes.body} rounded-lg flex gap-2 p-4 flex-col`}>
                            <span>ID : {item?.notificationId}</span>
                            <span>{item?.notificationMessage}</span>
                            <div className='flex gap-2'>
                                <span>{item?.notificationType}</span>
                                <span>.{item?.notificationElapsedTime}</span>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        )
    }, [notifications]);

    useEffect(() => {
        if (open) {
            const unreadIds = notifications.filter((notif: Notifications) => !notif?.read).map((notif) => notif?.id);
            if (unreadIds.length > 0) {
                const body = {
                    notificationIds: unreadIds
                }
                buyerService.updateNotificationDetails(body)
                    .then((notificationsResponse) => {
                        if (notificationsResponse.status === HTTP_STATUS.OK) {
                            const updatedNotifications = notifications.map((notif) => ({
                                ...notif,
                                read: unreadIds.includes(notif.id) ? true : notif.read,
                            }));
                            setNotificationsTo(updatedNotifications);
                        }
                    })
                    .catch((error) => {
                        showSnackbar('error', 'Something went wrong while updating the notification details')
                    });
            }
        }
    }, [open])

    useEffect(() => {
        const getUnreadCount = () => {
            return notifications.filter((notif: Notifications) => !notif?.read).length;
        };
        setUnreadCount(getUnreadCount())
    }, [notifications])

    return (
        <div className='flex justify-between'>
            <div>
                <span className={`font-medium ${classes.userName}`}> Welcome Back, {user?.fullName}</span>
            </div>
            <div className='flex gap-2'>
                {!user?.isVendor && <Button size='small' variant="secondaryContained" label={"Become Seller"} onClick={() => sellerToggleCallback(USER_PROFILE_TYPE.SELLER)} />}
                <Button size='small' variant="secondaryContained" onClick={() => setComingSoonTo(true)} label={"Member Plans"} />
                <DisplayImageV2
                    path={comingSoonLogo}
                    setImageDialogOpenTo={setComingSoonTo}
                    imageDialogOpen={comingSoon}
                    showCloseIcon={true} />
                <Badge className={classes.customBadge} onClick={handleOpen} badgeContent={unreadCount}
                >
                    <img src={bell} />
                </Badge>
                <Drawer
                    anchor="right"
                    open={open}
                    onClose={handleClose}
                    sx={{
                        width: '43%',
                        flexShrink: 0,
                        '& .MuiDrawer-paper': {
                            width: '40%',
                            boxSizing: 'border-box',
                        },
                    }}
                >
                    {activeContent}
                </Drawer>
            </div>
        </div>
    )
}
export default DashboardHeaderTemplate;