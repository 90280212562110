import React from 'react';
import { createUseStyles } from 'react-jss';
import Button from '../../../atoms/Button/Button';
import salesInvoice from '../../../../assets/images/salesInvoice.svg';
import salesInvoice2 from '../../../../assets/images/salesInvoice2.svg';
import salesInvoice3 from '../../../../assets/images/salesInvoice3.svg';

const useStyles = createUseStyles((theme: any) => ({
    container: {
       
        paddingBottom: "70px"
    },
    headingContainer:{
        rowGap: "8px",
    },
    heading: {
        fontSize: "48px",
        color: theme.palette.text.neutral.neutral800,
        lineHeight: "58px",
        "& span": {
            color: theme.palette.text.primary.primary500,
        },
    },
    subheading: {
        fontSize: '32px',
        color: theme.palette.text.neutral.neutral700,
        lineHeight: "38px"
    },
    cardContainer:{
        
    },
    card: {
        padding: "24px",
        
        '&:nth-child(1)': {
            background: theme.palette.background.warning.warning200,
            color: theme.palette.text.warning.warning900,
        },
        '&:nth-child(2)': {
            background: theme.palette.background.primary.primary100,
            color: theme.palette.text.primary.primary900,
        },
        '&:nth-child(3)': {
            background: theme.palette.background.secondary.secondary100,
            text: theme.palette.text.secondary.secondary900,
        },
    },
    cardTitle: {
        fontSize: '32px',
        fontWeight: '500',
        lineHeight: '38px',
        marginBottom: '10px',
    },
    cardDescription: {
        fontSize: '18px',
        lineHeight: '22px',
        height: "66px"
    },
    imageContainer: {
        marginTop: "16px"
    },
    "@media (max-width: 767px)": {
        container: {
            width: "100%",
            rowGap: "40px",
            padding: "0px 12px 0px 12px"
        },
        headingContainer:{
            rowGap: "0px",
        },
        heading: {
            fontSize: "28px",
            lineHeight: "34px",
        },
        subheading: {
            fontSize: '16px',
            lineHeight: "20px"
        },
        cardContainer:{
            display: "grid",
            // rowGap: "32px",
            rowGap: "12px"
        },
        card: {
            padding: "15px 10px 0px 10px",
            width: '100%',
            '&:nth-child(1)': {
                background: theme.palette.background.warning.warning200,
                color: theme.palette.text.warning.warning900,
            },
            '&:nth-child(2)': {
                background: theme.palette.background.primary.primary100,
                color: theme.palette.text.primary.primary900,
            },
            '&:nth-child(3)': {
                background: theme.palette.background.secondary.secondary100,
                text: theme.palette.text.secondary.secondary900,
            },
        },
        cardTitle: {
            fontSize: '18px',
            fontWeight: '500',
            lineHeight: '22px',
            marginBottom: '5px',
        },
        cardDescription: {
            fontSize: '16px',
            lineHeight: '20px',
            height: "auto"
        },
        imageContainer: {
            marginTop: "10px"
        },
    }
}));

const cardDetails = [
    {
        title: 'Sales Invoice Discounting',
        description: 'Instant Payments, No Hassles',
        imgSrc: salesInvoice,
    },
    {
        title: 'Purchase Invoice Finance',
        description: 'Maximise your Revenues, Liquidate in shortest time',
        imgSrc: salesInvoice2,
    },
    {
        title: 'Dynamic Discounting',
        description: 'Accelerate Liquidity while Maximizing Savings',
        imgSrc: salesInvoice3,
    },
];

const FinanceCardTemplate: React.FC = () => {
    const classes = useStyles();

    return (
        <div className={`${classes.container} container mx-auto lg:px-4`}>
            <div className={`headingContainer`}> 
                <div className='heading'>Supply Chain <span>Finance Solutions</span></div>
                <div className='Subheading italic'>Funds Never Short</div>
                </div> 
            <div className={`w-full ${classes.cardContainer} grid xl:grid-cols-3 lg:grid-cols-3 md:gap-4`}>
                {cardDetails.map((card, index) => (
                    <div className={`${classes.card} flex flex-col justify-between rounded-2xl`} key={index}>
                        <div className={classes.cardTitle}>{card.title}</div>
                        <div className={classes.cardDescription}>{card.description}</div>
                        <div className={`flex justify-between ${classes.imageContainer}`}>
                            <a href={``} target="_blank" rel="noopener noreferrer" className='w-fit cursor-pointer'>
                                <Button variant="tertiaryContained" label="View All" size='medium' />
                            </a>
                            <img src={card.imgSrc} alt="" />
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default FinanceCardTemplate;