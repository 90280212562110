import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import TextFieldV2 from '../../atoms/Input/TextFieldV2';
import CustomRadioGroup from '../../molecules/CustomRadioGroup/CustomRadioGroup';
import { BADGE_TYPES, PACKAGING_TYPES } from '../../../utils/constant';
import { FormikErrors, FormikProps } from 'formik';
import { VENDOR_CATALOGUE_FORM_STATES } from '../../../utils/types';
import { IcatalogueProductDetails, IVendorCatalogueAddForm } from '../../pages/VendorCatalogue/VendorCatalogueAdd.page';
import { FormHelperText } from '@mui/material';

interface ITrustBadgeTemplateProps {
  setCurrentSectionTo: (section: VENDOR_CATALOGUE_FORM_STATES) => void;
  formik: FormikProps<IVendorCatalogueAddForm>;
}

const useStyles = createUseStyles((theme: any) => ({
  radioColor: {
    color: theme.palette.background.primary.primary400,
    width: '23%',
  },
  helperText: {
    position: 'absolute',
    bottom: '-20px',
  },
}));

const TrustBadgeTemplate: React.FC<ITrustBadgeTemplateProps> = ({ formik }) => {
  const classes = useStyles();

  const handleChangePackaging = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value === "true";
    formik.setFieldValue(`isVerifiedTrusted`, value);
  };

  return (
    <div>
      <CustomRadioGroup
        {...formik.getFieldProps(`isVerifiedTrusted`)}
        options={BADGE_TYPES}
        onChange={handleChangePackaging}
        className={`${classes.radioColor} `}
      />
    </div>
  )
}
export default TrustBadgeTemplate;