import React, { useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss';
import Button from '../../../atoms/Button/Button';
import { ADD_VENDOR_INVENTORY_STATES, HTTP_STATUS } from '../../../../utils/types';
import { useNavigate } from 'react-router-dom';
import ProductViewTemplate from './ProductView.template';
import AddInventorysectionTemplate from './AddInventorysection.template';
import { AddVendorInventorySectionProp } from './AddNewVendorInventory.template';
import { IProductData } from './SelectProduct.template';
import { useVendorInventoryService } from '../../../../services/useVendorInventoryService';
import { useSnackbar } from '../../../../hooks/useSnackBar';
import { useAuthenticatedUser } from '../../../../hooks/useAuthenticatedUser';
import { MODE } from '../../../../utils/constant';

const useStyles = createUseStyles((theme: any) => ({
    webContainer: {
        display: "grid"
    },
    activatedProgressBar: {
        backgroundColor: theme.palette.background.primary.primary500
    },
    activeProgressBar: {
        backgroundColor: theme.palette.background.primary.primary400
    },
    UnTouchProgressBar: {
        backgroundColor: theme.palette.border.neutral.neutral100
    },
    barHeading: {
        color: theme.palette.text.primary.primary500
    },
    textHeading:{
        color: theme.palette.text.primary.primary900
    },
    selectBox:{
        border: `1px solid ${theme.palette.background.neutral.neutral100}`,
    },
    previousActiveHeading: {
        color: theme.palette.background.primary.primary400
    },
    subHeading: {
        color: theme.palette.text.neutral.neutral600
    }, 
    search: {
        margin: "0",
        background: theme.palette.background.neutral.neutral400,
    },
}));

const VendorInventoryReviewTemplate: React.FC<AddVendorInventorySectionProp> = ({ formik, setCurrentSectionTo, modalFormikEdit, setWareHouseId, mode, existingWarehouseDetails}) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const [productsData, setProductsData] = useState<IProductData | null>(null);
    const vendorInventoryService = useVendorInventoryService();
    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const { user } = useAuthenticatedUser();
    const handleCancel = () => {
        navigate('/dashboard/inventory-management');
    };
    const handleBack = () => {
        setCurrentSectionTo(ADD_VENDOR_INVENTORY_STATES.ADD_USN);
    }
    
    const handleSubmit = () => {
        if(modalFormikEdit){
            modalFormikEdit.values.wareHouses = modalFormikEdit.values.wareHouses.filter(
                warehouse => warehouse.warehouseId !== 0
            );
            const updatedWarehouseDetails = [...formik.values.warehouseDetails, ...modalFormikEdit.values.wareHouses];
            formik.setFieldValue('warehouseDetails', updatedWarehouseDetails)
        }
        formik.handleSubmit();
    }

    const getVedorProductbyId = async () => {
        if(user?.businessId){
            try {
                const productId = formik?.values?.productId ?? 0;
                const ProductsResponse = await vendorInventoryService.getDetailedProduct(user?.businessId, productId);
                if (ProductsResponse.status === HTTP_STATUS.OK) {
                    const productData = ProductsResponse?.data?.data;
                    setProductsData({
                        id: productData?.id,
                        productId: productData?.productId,
                        skuCode: productData?.standardProductDetails.upcCode,
                        productName: productData?.productUpcTitle,
                        standardProductDetails: productData?.standardProductDetails,
                        vendorProductStockDetails: productData?.vendorProductStockDetails
                    });
                }
            } catch (error) {
                showSnackbar('error', 'Business Profile fetch failed');
            }
        }
        
    };
    useEffect(() => {
        getVedorProductbyId();
    }, [user?.businessId]);

    useEffect(() => {
        document.getElementById("main")?.scroll(0, 0); 
    }, []);
    return (
        <div className={`${classes.webContainer} grid gap-y-8 `}>
            <div className={`w-full m-auto flex justify-between gap-x-2 text-var(--black, #4D4D4D) text-center text-sm `}>
                <div className="grid gap-y-3 w-1/2">
                    <div className='flex gap-x-2 mx-auto'>
                        <div className={`${classes.activeProgressBar} w-2 h-2 my-auto rounded-full`}></div>
                        <div className={`${classes.previousActiveHeading} my-auto`}>Select Product Type </div>
                    </div>
                    <div className={`${classes.activatedProgressBar} h-2.5 rounded `}></div>
                </div>
                <div className="grid gap-y-3 w-1/2">
                    <div className='flex gap-x-2 mx-auto'>
                        <div className={`${classes.activeProgressBar} w-2 h-2 my-auto rounded-full`}></div>
                        <div className={`${classes.previousActiveHeading} my-auto`}>Select Product</div>
                    </div>
                    <div className={`${classes.activatedProgressBar} h-2.5 rounded `}></div>
                </div>
                <div className="grid gap-y-3 w-1/2">
                    <div className='flex gap-x-2 mx-auto'>
                        <div className={`${classes.activeProgressBar} w-2 h-2 my-auto rounded-full`}></div>
                        <div className={`${classes.previousActiveHeading} my-auto`}>Add USN Details </div>
                    </div>
                    <div className={`${classes.activatedProgressBar} h-2.5 rounded `}></div>
                </div>
                <div className="grid gap-y-3 w-1/2">
                    <div className='flex gap-x-2 mx-auto'>
                        <div className={`${classes.activatedProgressBar} w-2 h-2 my-auto rounded-full`}></div>
                        <div className={`${classes.barHeading} my-auto`}>Review</div>
                    </div>
                    <div className={`${classes.activatedProgressBar} h-2.5 rounded `}></div>
                </div>
            </div>

            <div className=' grid gap-4 '>
                <div className='flex  justify-between'>
                    <div className=' grid gap-1/2'>
                        <text className={`${classes.textHeading} text-base font-medium`}> Review</text>
                        <text className={`${classes.subHeading} text-xs font-normal`}> Please review the data you have entered </text>
                    </div>
                </div>
                <ProductViewTemplate
                    product={productsData}
                    showButton={false}
                />
               <AddInventorysectionTemplate formik={formik} buttonShow={false} modalFormikEdit={modalFormikEdit} setWareHouseId={setWareHouseId} existingWarehouseDetails = {existingWarehouseDetails} />

            </div>
            <div className=' flex justify-between'>
                <Button
                    variant="tertiaryContained"
                    label="Cancel"
                    onClick={handleCancel}
                />
            <div className='flex gap-6 '>
                <Button
                    variant="tertiaryContained"
                    label="Back"
                    onClick={handleBack}
                />
                {/* <Button
                    variant="secondaryContained"
                    label="Save As Draft"
                    onClick={handleBack}
                /> */}
                <Button
                    variant="primaryContained"
                    label="Add"
                    onClick={handleSubmit}
                    disabled={formik.isSubmitting}
                />
            </div>
            </div>
        </div>
    )
}

export default VendorInventoryReviewTemplate