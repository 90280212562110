import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import auctionAndCustomisedBanner from '../../../../assets/images/auctionAndCustomisedBanner.jpg';
import Button from '../../../atoms/Button/Button';
import RequestForQuotationModalTemplate from '../../Rfq/RequestForQuotationModal.template';

interface DemoForm {
    name: string;
    email: string;
    mobileNumber: string;
}

const useStyles = createUseStyles((theme: any) => ({
    mainContainer: {
        backgroundImage: `url(${auctionAndCustomisedBanner})`,
        backgroundSize: 'cover',
        padding: "75px 0px"
    },
    container: {
      
    },
    heading: {
        width: "60%",
        fontSize: '48px',
        color: "white",
        lineHeight: "58px",
    },
    cardContainer: { 
        columnGap: "55px"
    },
    firstContainer: {
        padding: "50px",
        backgroundColor: theme.palette.background.primary.primary500,
        borderRadius: "10px",
        "& div": {
            color: theme.palette.text.primary.primary50,
            fontSize: "32px",
            fontWeight: 600,
            lineHeight: "38px"
        },
    },
    secondContainer: {
        padding: "50px",
        backgroundColor: "white",
        borderRadius: "10px",
        "& div": {
            color: theme.palette.text.primary.primary600,
            fontSize: "32px",
            fontWeight: 600,
            lineHeight: "38px"
        },
    },
    subHeading: {
        fontSize: '20px',
        color: theme.palette.text.neutral.neutral50,
        lineHeight: '23.6px',
        letterSpacing: "-0.4px"
    },
    subText: {
        color: theme.palette.text.neutral.neutral50,
        letterSpacing: "0.16px"
    },
    errorMessage: {
        color: theme.palette.text.secondary.secondary500,
    },
    "@media (max-width: 767px)": {
        mainContainer: {
            padding: "20px 10px"
        },
        container: {
            width: '100%',
        },
        heading: {
            width: "80%",
            fontSize: '22px',
            color: "white",
            lineHeight: "26px",
        },
        cardContainer: {
            display: "grid",
            gridTemplateColumns: "repeat(1, 1fr)",
            rowGap: "30px"
        },
        firstContainer: {
            padding: "30px 8px",
            backgroundColor: theme.palette.background.primary.primary500,
            borderRadius: "10px",
            "& div": {
                fontSize: "18px",
                fontWeight: 700,
                lineHeight: "26px"
            },
        },
        secondContainer: {
            padding: "30px 8px",
            backgroundColor: "white",
            borderRadius: "10px",
            "& div": {
                fontSize: "18px",
                fontWeight: 700,
                lineHeight: "26px"
            },
        },
    }
}));

const AuctionAndCustomisedTemplate: React.FC = () => {
    const classes = useStyles();
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const handleRfqForm = () => {
        setDialogOpen(true);
    }

    const scrollToLabel = () => {
        const label = document.getElementById('scrollToLabel');
        if (label) {
            label.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };
    return (
        <div className={`${classes.mainContainer} w-full `}>
            <div className={`${classes.container}  container mx-auto lg:px-4`}>
                <div className={`${classes.heading} font-bold mb-10 mx-auto text-center`}>
                    Cost Efficiency through Dynamic
                    Auctions & Customised RFQs
                </div>
                <div className={`${classes.cardContainer} grid xl:grid-cols-2 lg:grid-cols-2 sm:grid-cols-1 md:gap-14`}>
                    <div className={`${classes.firstContainer} text-center grid md:gap-4 gap-y-4`}>
                        <div className='w-full mx-auto'>Real-Time Competitive Auctions for Transparent Market Deals</div>
                        <div className='flex justify-center'>
                            <Button variant='tertiaryContained' label={'Contact Us'} size='large' onClick={scrollToLabel} />
                        </div>
                    </div>
                    <div className={`${classes.secondContainer} text-center grid gap-y-4`}>
                        <div className='w-full mx-auto'>Get Custom Quotes from Trusted Vendors at Competitive Prices</div>
                        <div className='flex justify-center'>
                            <Button variant='primaryContained' label={'Get Quote'} size='large' onClick={handleRfqForm} />
                        </div>
                    </div>
                </div>

                <RequestForQuotationModalTemplate dialogOpen={dialogOpen} setDialogOpen={setDialogOpen} />
            </div>
        </div>
    );
};

export default AuctionAndCustomisedTemplate;