import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import SocialMediaLinks from '../../molecules/socialMediaLinks/SocialMediaLinks';
import Twitter from '../../../assets/icons/xMedium.svg';
import Facebook from "../../../assets/icons/facebookMedium.svg";
import Instagram from "../../../assets/icons/instagramMedium.svg";
import LinkedIn from "../../../assets/icons/linkedinMedium.svg";
import Whatsapp from "../../../assets/icons/whatsappMedium.svg"
import emailIcon from "../../../assets/icons/emailicon.png"
import phoneIcon from "../../../assets/icons/phoneIcon.png"
import WhatsappIcon from "../../../assets/images/whatsapp.svg"
import { INDUSTRY_STATES } from '../../../utils/constant';
import { convertDate, getProfileDisplayData } from '../../../utils/helper';
import BusinessAddressTemplate from '../OnBoarding/BusinessAddress.template';
import { StoreFrontGstDetailsTemplate } from './StoreFrontGstDetails.template';
import CatalogueImageTemplate from './CatalogueImage.template';
import { IBusinessProfile } from '../../../services/useBusinessProfileService';
import moment from 'moment';

interface ProfileOverviewTemplateProps {
    businessProfile: IBusinessProfile;
}

export interface IRegisteredAddress {
    addressLine1: string;
    addressLine2: string;
    pincode: string;
    city: string;
    state: string;
}

const useStyles = createUseStyles((theme: any) => ({
    section: {
        color: theme.palette.background.primary.primary900,
    },
    container: {
        border: `1px solid ${theme.palette.border.neutral.neutral100}`,
        width: "100%"
    },
    tabContainer: {
        display: "grid",
    },
    title: {
        color: theme.palette.text.neutral.neutral800,
    },
    value: {
        color: theme.palette.text.neutral.neutral700,
    },
    property: {
        color: theme.palette.text.neutral.neutral700,
    },
    aboutUsBorder: {
        borderTop: `1px solid ${theme.palette.border.neutral.neutral100}`,
    },
    icon: {
        color: theme.palette.button.primaryContained,
        width: "33px",
    },
    websiteColor: {
        color: `${theme.palette.text.primary.primary400} !important`,
    }
}));

const ProfileOverviewTemplate: React.FC<ProfileOverviewTemplateProps> = ({ businessProfile }) => {

    const classes = useStyles();
    const [gstRegisteredAddress, setGstRegisteredAddressTo] = useState<IRegisteredAddress | null>(null);
    const [cinRegisteredAddress, setCinRegisteredAddressTo] = useState<IRegisteredAddress | null>(null);

    const USER_DETAILS_SCHEMA = [{ label: "Full Name", value: "fullName" }, { label: "Department", value: "department" }, { label: "Designation", value: "designation" }, { label: "Email Address", value: "email" }, { label: "Phone", value: "mobileNumber" }, { label: "Whatsapp No.", value: "whatsappNumber" }, { label: "Social Media Link", value: "socialLinks" }];
    const BUSINESS_INFORMATION_SCHEMA = [{ label: "Company Name", value: "name" }, { label: "Year of Establishment", value: "dateOfEstablishment" }, { label: "Number of Employees", value: "totalEmployees" }, { label: "Business Classification", value: "businessType" }, { label: "Entity Type", value: "entityType" }, { label: "Business Type", value: "businessType" }, { label: "Industry Type", value: "industry" }, { label: "Website", value: "websiteUrl" }];

    const getIndustryType = (value: string): string => {
        const industry = INDUSTRY_STATES[value as keyof typeof INDUSTRY_STATES];
        return industry !== undefined ? industry : value;
    }

    const formatSocialLinks = (socialLinks: any) => {
        if (!socialLinks) return null;
        const logoMapping: { [key: string]: string } = {
            Twitter,
            Facebook,
            Instagram,
            LinkedIn,
            Whatsapp
        };

        const socialMediaSchema = Object.keys(socialLinks).map((key) => ({
            key: key,
            link: socialLinks[key] || "",
            logo: logoMapping[key] || `path/to/${key}Logo.png`
        }));
        return <SocialMediaLinks schema={socialMediaSchema} />;
    };

    useEffect(() => {
        if (businessProfile) {
            setGstRegisteredAddressTo({
                addressLine1: businessProfile?.address?.line1 || "",
                addressLine2: businessProfile?.address?.line2 || "",
                pincode: businessProfile?.address?.pincode || "",
                city: businessProfile?.address?.city || "",
                state: businessProfile?.address?.state || "",
            });
            setCinRegisteredAddressTo({
                addressLine1: businessProfile?.cinRegisteredAddress?.line1 || "",
                addressLine2: businessProfile?.cinRegisteredAddress?.line2 || "",
                pincode: businessProfile?.cinRegisteredAddress?.pincode || "",
                city: businessProfile?.cinRegisteredAddress?.city || "",
                state: businessProfile?.cinRegisteredAddress?.state || "",
            });
        }
    }, [businessProfile]);

    const iconMap: { [key: string]: string } = {
        "Email Address": emailIcon,
        "Phone": phoneIcon,
        "Whatsapp No.": WhatsappIcon,
    };    

    return (
        <div className={` ${classes.tabContainer} gap-y-8 max-container m-auto mb-[84px]`}>
            <StoreFrontGstDetailsTemplate businessProfile={businessProfile}/>
            <div className={`${classes.section} text-2xl font-semibold`}>About Us</div>
            <div className='grid gap-y-6'>
                <div className='flex gap-x-6 -mb-4'>
                    <CatalogueImageTemplate isSharable={false} height={"374px"} width={"622.5px"} />
                    <div className={`p-6 flex flex-col h-[374px] rounded-xl ${classes.container}`}>
                        <span className={`${classes.title} text-2xl font-medium pb-6`}>About Us</span>
                        <div className={` ${classes.property} ${classes.aboutUsBorder} font-medium text-base pt-6 `}>{getProfileDisplayData(businessProfile?.description)}</div>
                    </div>
                </div>
                <div className={`p-6 grid gap-y-6 rounded-lg w-full ${classes.container}`}>
                    <div className={`${classes.title} text-2xl w-full font-medium`}>Business Information</div>
                    <div className='pt-6 border-t border-gray-300 w-full'>
                        <div className='w-full grid gap-y-8'>
                            <div className='flex w-full justify-between gap-x-9'>
                                {BUSINESS_INFORMATION_SCHEMA.slice(0, 4).map((key: any, index: number) => (
                                    <div key={key.label} className={` w-1/4`}>
                                        <div className="my-auto w-5"><img src={key.icon} className="m-auto" alt="" /></div>
                                        <div className="grid gap-y-1">
                                            <div className={`${classes.value} font-normal text-lg`}>{key.label}</div>
                                            <div className={`${classes.property} font-medium text-lg`}>
                                                {key.label === 'Business Classification'
                                                    ? getProfileDisplayData(businessProfile?.udhyam?.businessType)
                                                    : key.label === 'Business Type'
                                                        ? getProfileDisplayData(businessProfile?.businessType)
                                                        : key.value === 'entityType'
                                                            ? businessProfile?.cin?.entityType || businessProfile?.entityType
                                                            : key.value === 'dateOfEstablishment'
                                                            ? convertDate(businessProfile?.dateOfEstablishment)
                                                                : typeof (businessProfile as any)?.[key.value] === 'string'
                                                                    ? (businessProfile as any)?.[key.value]
                                                                    : (businessProfile as any)?.[key.value] || "No Data"
                                                }
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className='flex w-full justify-between gap-x-9'>
                                {BUSINESS_INFORMATION_SCHEMA.slice(4).map((key: any, index: number) => (
                                    <div key={key.label} className={` w-1/4`}>
                                        <div className="my-auto w-5"><img src={key.icon} className="m-auto" alt="" /></div>
                                        <div className="grid gap-y-1">
                                            <div className={`${classes.value} font-normal text-lg`}>{key.label}</div>
                                            <div className={`${classes.property} font-medium text-lg break-all`}>
                                                {
                                                    key.label === 'Business Classification'
                                                        ? getProfileDisplayData(businessProfile?.udhyam?.businessType)
                                                        : key.label === 'Business Type'
                                                            ? getProfileDisplayData(businessProfile?.businessType)
                                                            : key.value === 'entityType'
                                                                ? businessProfile?.cin?.entityType || businessProfile?.entityType
                                                                : key.label === 'Website'
                                                                    ? (
                                                                        businessProfile?.websiteUrl
                                                                            ? <a href={businessProfile.websiteUrl} target="_blank" rel="noopener noreferrer" className={`${classes.websiteColor} !fomt-medium !text-lg`}>
                                                                                {businessProfile.websiteUrl}
                                                                            </a>
                                                                            : "No Data"
                                                                    )
                                                                    : key.label === 'Industry Type'
                                                                        ? getIndustryType(businessProfile?.industry) || "No Data"
                                                                        : typeof (businessProfile as any)?.[key.value] === 'string'
                                                                            ? (businessProfile as any)?.[key.value]
                                                                            : "No Data"
                                                }
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`p-6 grid gap-y-6 rounded-lg ${classes.container}`}>
                    <div className={`${classes.title} text-2xl font-medium`}>Key Person Information</div>
                    <div className='pt-6 border-t border-gray-300 flex '>
                        <div className='w-full grid gap-y-8'>
                            <div className='flex w-full justify-between gap-x-9'>
                                {USER_DETAILS_SCHEMA.slice(0, 3).map((key: any, index: number) => (
                                    <div key={key.label} className={` w-1/4`}>
                                        <div className="w-5">
                                            <img src={key.icon} className="m-auto" alt="" />
                                        </div>
                                        <div className="grid gap-y-1">
                                            <div className={`${classes.value} font-normal text-lg`}>{key.label}</div>
                                        
                                            <div className={`${classes.property} font-medium text-lg`}>
                                                {key.value === 'socialLinks' ? (
                                                    <div className="flex gap-x-2">
                                                        {getProfileDisplayData(businessProfile?.user?.socialLinks) === "No Data" ? getProfileDisplayData(businessProfile?.user?.socialLinks) :
                                                            formatSocialLinks(businessProfile?.user?.socialLinks)
                                                        }
                                                    </div>
                                                ) : (
                                                    (businessProfile?.user as any)?.[key.value] || getProfileDisplayData(null)
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                <div className={`w-1/4`}></div>
                            </div>
                            <div className='flex w-full justify-between gap-x-9'>
                                {USER_DETAILS_SCHEMA.slice(3).map((key: any, index: number) => (
                                    <div key={key.label} className={` w-1/4`}>
                                        <div className="w-5">
                                            <img src={key.icon} className="m-auto" alt="" />
                                        </div>
                                        <div className="grid gap-y-1">
                                            <div className="flex items-center gap-x-2">
                                                <span className={`${classes.value} font-normal text-lg`}>
                                                    {key.label}
                                                </span>
                                                {iconMap[key.label] && (
                                                    <img src={iconMap[key.label]} className="w-4 h-4" alt={key.label} />
                                                )}
                                            </div>
                                            <div className={`${classes.property} font-medium text-lg`}>
                                                {key.value === 'socialLinks' ? (
                                                    <div className="flex gap-x-2">
                                                        {getProfileDisplayData(businessProfile?.user?.socialLinks) === "No Data" ? getProfileDisplayData(businessProfile?.user?.socialLinks) :
                                                            formatSocialLinks(businessProfile?.user?.socialLinks)
                                                        }
                                                    </div>
                                                ) : (
                                                    (businessProfile?.user as any)?.[key.value] || getProfileDisplayData(null)
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`p-6 grid gap-y-6 rounded-lg w-full ${classes.container}`}>
                    <div className='w-full grid gap-y-6'>
                        <div className={`${classes.title} text-2xl font-medium`}>Business Places</div>
                        <div className='flex gap-x-6 w-full pt-6 border-t border-gray-300'>
                            <div className='w-1/2'>
                                {cinRegisteredAddress && <BusinessAddressTemplate title="Registered Office Address" address={cinRegisteredAddress} />}
                            </div>
                            <div className='w-1/2'>
                                {gstRegisteredAddress && <BusinessAddressTemplate title="Principle Office Address (as per GST)" address={gstRegisteredAddress} />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProfileOverviewTemplate;
