import React from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles((theme: any) => ({
    container: {
        border: `1px solid ${theme.palette.border.neutral.neutral100}`,
        transition: "box-shadow 0.3s ease-in-out",
        "&:hover": {
            boxShadow: `0px 5px 16px 0px rgba(8, 15, 52, 0.08)`,
        },
    },
    background: {
        background: "#d3d3d3",
        animation: "$pulse 0.9s infinite", 
    },
    "@keyframes pulse": {
        "0%": { opacity: 1 },
        "50%": { opacity: 0.5 },
        "100%": { opacity: 1 }
    }
}));

const ImageShimmer: React.FC = () => {
    const classes = useStyles();

    return (
        <div className={`${classes.container} rounded-lg overflow-hidden p-2 w-full h-full flex gap-x-3`}>
            <div className={`w-[344px] h-[299px] rounded-lg ${classes.background}`}></div>
        </div>
    );
};

export default ImageShimmer;
