import React from 'react';
import { createUseStyles } from 'react-jss';
import homeBanner from '../../../../assets/images/homeBanner.jpg';
import outlinedIcon1 from '../../../../assets/icons/outlinedIcon1.svg';
import outlinedIcon2 from '../../../../assets/icons/outlinedIcon2.svg';
import outlinedIcon3 from '../../../../assets/icons/outlinedIcon3.svg';
import outlinedIcon4 from '../../../../assets/icons/outlinedIcon4.svg';
import outlinedIcon5 from '../../../../assets/icons/outlinedIcon5.svg';
import Animation from './BannerAnimation';
import BannerSearchTemplate from './BannerSearch.template';
import bgpatterndot from '../../../../assets/images/bgpatterndot.png';

const useStyles = createUseStyles((theme: any) => ({
    container: {
        height: 'auto',
        backgroundImage: `url(${homeBanner})`,
        backgroundSize: 'cover',
        padding: "40px 0px 90px 0px"
    },
    secondaryContainer: {
        display: "flex",
        justifyContent: "space-between",
        boxShadow: '0px 4px 15px 0px rgba(0, 0, 0, 0.15)',
        borderRadius: '40px',
        marginTop: '-52px',
        background: `url(${bgpatterndot})`,

    },
    statsMainContainer: {},
    statsContainer: {
        border: `1px dashed #AEB0B6`,
        borderRadius: "12px"
    },
    statsHeading: {
        fontSize: '32px',
        fontWeight: 700,
        color: theme.palette.text.neutral.neutral800,
        lineHeight: '38px',
        "& span": {
            color: theme.palette.text.secondary.secondary600,
        },
    },
    stats: {},
    sliderContent: {
        rowGap: "8px",
        "& p": {
            fontSize: '32px',
            color: theme.palette.text.primary.primary900,
            lineHeight: '38px'
        },
        "& span": {
            fontSize: '18px',
            color: theme.palette.text.neutral.neutral600,
            lineHeight: '22px'
        },
    },
    headingConatiner: {
        display: 'flex',
        flexDirection: 'column',
        height: '192px',
        padding: '12px',
        width: '43%',
    },
    heading: {
        fontSize: '62px',
        color: theme.palette.text.primary.primary50,
        lineHeight: '58px',
        width: '100%',
        maxWidth: '650px',
    },
    homeTextAnimation: {
        position: 'relative',
        display: 'block'
    },

    inputSearch: {
        border: `0.8px solid ${theme.palette.border.primary.primary500}`,
        borderRadius: '45px',
        overflow: 'hidden',
        background: 'white',
        width: '670px',
        "& .MuiInputBase-root": {
            border: 'none',
            background: 'white',
        }
    },
    btn: {
        background: theme.palette.background.primary.primary500,
        color: "white",
        borderRadius: '50px',
        height: '48px',
        width: '152px',
    },
    chip: {
        "& span": {
            border: `1px solid ${theme.palette.border.neutral.neutral50}`,
            color: theme.palette.text.neutral.neutral50,
            borderRadius: '28px',
            lineHeight: "16.32px"
        }
    },
    smallContainer: {
        borderRight: `1px solid ${theme.palette.border.neutral.neutral200}`,
        "&:last-child": {
            borderRight: 'none'
        },
        "& span": {
            color: theme.palette.text.primary.primary800,
            lineHeight: "22px",
            fontSize: "18px",
            fontWeight: 500
        },
        display: "flex",
        columnGap: "12px"
    },
    banneSearchIcon: {
        color: theme.palette.text.neutral.neutral400,
    },
    serachContainer: {
        display: "grid",
    },
    "@media (max-width: 767px)": {
        container: {
            width: '100%',
            height: 'auto',
            backgroundImage: `url(${homeBanner})`,
            backgroundSize: 'cover',
            padding: "0px"
        },
        primaryContainer: {
            width: '100%',
        },
        heading: {
            width: "90%",
            fontSize: '28px',
            color: theme.palette.text.primary.primary50,
            lineHeight: '34px',
        },
        secondaryContainer: {
            width: '100%',
            display: "none"
        },
        smallContainer: {
            "& span": {
                color: theme.palette.text.primary.primary800,
                lineHeight: "22px",
                fontSize: "14px",
                fontWeight: 500
            },
            display: "grid",
            rowGap: "12px",
            textAlign: "center",
            justifyItems: "center"
        },
        statsMainContainer: {
            width: '100%',
            padding: "40px 12px 0px 12px",
            display: "none"
        },
        statsContainer: {
            padding: "20px 10px",
            borderRadius: "12px",
            display: "grid",
            rowGap: "20px"
        },
        statsHeading: {
            fontSize: '22px',
            fontWeight: 500,
            color: theme.palette.text.neutral.neutral800,
            lineHeight: '26px',
            "& span": {
                color: theme.palette.text.secondary.secondary600,
            },
            width: "100%",
            textAlign: "center",
        },
        stats: {
            width: "100%",
        },
        sliderContent: {
            width: "33.33%",
            rowGap: "4px",
            textAlign: "center",
            "& p": {
                fontSize: '22px',
                color: theme.palette.text.primary.primary900,
                lineHeight: '26px'
            },
            "& span": {
                fontSize: '12px',
                color: theme.palette.text.neutral.neutral600,
                lineHeight: '14px'
            },
        },
        headingConatiner: {
            height: "auto",
            padding: "70px 12px",
            display: 'none',
        },
        serachContainer: {
            display: "block",
        },
    }
}));

const features = [
    { icon: outlinedIcon1, text: 'Unifying Entire Steel', secondaryText: 'Eco-Systems' },
    { icon: outlinedIcon2, text: 'End-to-End Digital Trade ', secondaryText: 'with Real-Time Prices' },
    { icon: outlinedIcon3, text: 'Manufacturing and', secondaryText: 'Customisation Services' },
    { icon: outlinedIcon4, text: 'Value Maximisation via ', secondaryText: 'Reprocess & Recycling' },
    { icon: outlinedIcon5, text: 'Flexible and Tailored ', secondaryText: 'Finance Solutions' }

];

const HomePage: React.FC = () => {
    const classes = useStyles();

    return (
        <>
            <div className={`${classes.container}`}>
                <div className={`container mx-auto flex justify-between lg:items-center items-center lg:flex-row flex-col lg:px-4 px-0 md:px-0 gap-y-8`}>
                    <div className={`${classes.headingConatiner}`}>
                        <h1 className={`${classes.heading} font-bold`}>NextGen Digital <br /> Steel Commerce</h1>
                        <div className={`${classes.homeTextAnimation}`}><Animation /></div>
                    </div>
                    <div className={`${classes.serachContainer} lg:w-max w-full`}>
                        <BannerSearchTemplate />
                    </div>
                </div>
            </div>
            <div className='lg:mb-10 lg:px-4'>
                <div className={`${classes.secondaryContainer} container !bg-white mx-auto`}>
                    <div className=' grid lg:grid-cols-5 md:grid-cols-3 w-full'>
                        {features.map((feature, index) => (
                            <div
                                key={index}
                                className={`${classes.smallContainer} lg:py-10 py-5  lg:px-4 flex flex-col gap-5 justify-center items-center w-full`}>
                                <img src={feature.icon} alt="" />
                                <span className='text-center'>{feature.text} <br /> {feature.secondaryText}</span>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

        </>
    );
};

export default HomePage;