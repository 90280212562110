import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import Button from '../../../atoms/Button/Button';
import { News, useNewsService } from '../../../../services/useNewsService';
import IconButton from '../../../atoms/Button/IconButton';

const useStyles = createUseStyles((theme: any) => ({
    container: {

        paddingTop: "80px",
        paddingBottom: "80px"
    },
    headingContainer: {
        rowGap: "8px"
    },

    webContainer: {
        display: "flex",
    },
    mainContainer: {
        border: `1px solid ${theme.palette.border.neutral.neutral100}`,
    },
    largeImage: {
        borderRadius: "10px"
    },
    newsHeading: {
        fontSize: "24px",
        color: theme.palette.text.primary.primary900,
        lineHeight: "33.6px"
    },
    newsDescription: {
        color: theme.palette.text.neutral.neutral700,
        lineHeight: "20.7px"
    },
    smallImage: {
        borderRadius: "10px",
        width: "160px",
        height: "148px",
    },
    samllNewsDescription: {
        fontSize: "14px",
        color: theme.palette.text.neutral.neutral700,
        lineHeight: "16.1px"
    },
    chip: {
        padding: "4px 8px",
        fontSize: "12px",
        color: theme.palette.text.complementary.complementary600,
        background: theme.palette.background.complementary.complementary50,
        borderRadius: '4px',
        border: `1px solid ${theme.palette.border.complementary.complementary100}`,
    },
    background: {
        background: "#d3d3d3",
    },
    mobileContainer: {
        display: "none"
    },
    "@media (max-width: 767px)": {
        container: {
            width: "100%",
            padding: "0px 12px"
        },
        headingContainer: {
            rowGap: "0px"
        },
        heading: {
            fontSize: "24px",
            lineHeight: "24px",
        },
        subheading: {
            fontSize: '16px',
        },
        webContainer: {
            display: "none",
        },
        mobileContainer: {
            display: "flex",
        },
        smallImage: {
            borderRadius: "4px",
            width: "90px",
            height: "83.25px",
        },
        newsHeading: {
            fontSize: "14px",
            lineHeight: "16px"
        },
        chip: {
            padding: "4px 6px",
            fontSize: "10px",
        },
        samllNewsDescription: {
            fontSize: "12px",
            color: theme.palette.text.neutral.neutral700,
            lineHeight: "14px",
            width: '200px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
        },
    }
}));

const NewsTemplate: React.FC = () => {

    const classes = useStyles();
    const [loading, setLoading] = useState<boolean>(true);
    const newsService = useNewsService();
    const [news, setNewsTo] = useState<News[]>([]);

    const loadNews = async () => {
        setLoading(true);
        try {
            const params = {
                limit: 10,
                page: 1,
            };
            const newsResponse = await newsService.getNews(params);
            if (newsResponse?.data?.data?.length) {
                setNewsTo(newsResponse.data.data);
            } else {
                throw new Error("News fetch failed");
            }
        } catch (error) {
            setNewsTo([]);
        }
        setLoading(false);

    };

    useEffect(() => {
        loadNews();
    }, []);

    return (
        <div className={`${classes.container} container mx-auto lg:px-4 grid`}>
            <div className={`headingContainer`}>
                <div className='heading'><span>Market </span>Insights and Updates</div>
                <div className='Subheading italic'>Predict the Trends Stay Ahead</div>
            </div>
            <div className={`${classes.webContainer}`}>
                {loading ? (
                    <div className={`flex gap-4 w-full`}>
                        <div className={`w-[642px] h-[548px] animate-pulse rounded-lg ${classes.background}`}></div>
                        <div className='grid gap-y-3 w-[642px]'>
                            <div className={`w-full h-[172px] animate-pulse rounded-lg ${classes.background}`}></div>
                            <div className={`w-full h-[172px] animate-pulse rounded-lg ${classes.background}`}></div>
                            <div className={`w-full h-[172px] animate-pulse rounded-lg ${classes.background}`}></div>
                        </div>
                    </div>
                ) : (
                    <div className={`flex gap-4`}>
                        {news.slice(0, 1).map((item: any) => (
                            <a href={`https://news.steelbazaar.com/news/${item.slug}`} target="_blank" rel="noopener noreferrer" className=' h-[551px] w-1/2'>
                                <div className={`${classes.mainContainer} flex flex-col gap-y-6 p-3 rounded-xl`}>
                                    <img src={item.banner} alt="" className={`${classes.largeImage} h-[290px] w-full`} />
                                    <div className='grid gap-y-4'>
                                        <div className={`${classes.chip} py-1 px-2 text-xs w-fit`}>Notification</div>
                                        <div className='grid gap-y-2'>
                                            <div className={`${classes.newsHeading} text-2xl font-semibold`}>{item.title.substring(0, 30)}...</div>
                                            <div className={`${classes.newsDescription} text-lg`}>{item.content.replace(/<[^>]+>/g, '').substring(0, 280)}...</div>
                                        </div>
                                        <a href={`https://news.steelbazaar.com/news/${item.slug}`} target="_blank" rel="noopener noreferrer" className='w-fit'>
                                            <Button variant="secondaryText" label="Read More" size='small' />
                                        </a>
                                    </div>
                                </div>
                            </a>
                        ))}

                        <div className='w-1/2 flex flex-col gap-4'>
                            {news.slice(1, 4).map((item: any) => (
                                <div className={`${classes.mainContainer}  rounded-xl `}>
                                    <a href={`https://news.steelbazaar.com/news/${item.slug}`} target="_blank" rel="noopener noreferrer" className='flex gap-x-4 p-3'>
                                        <img className={`${classes.smallImage}`} src={item.banner} alt="" />
                                        <div className={`grid gap-y-2`}>
                                            <div className='flex gap-x-2'>
                                                <div className={`${classes.newsHeading} text-2xl font-semibold`}>{item.title.substring(0, 45)}...</div>
                                                <div className={`${classes.chip} py-1 px-2 text-xs h-fit`}>Notification</div>
                                            </div>
                                            <div className={`${classes.samllNewsDescription} text-sm`}>{item.content.replace(/<[^>]+>/g, '').substring(0, 120)}...</div>
                                            <a href={`https://news.steelbazaar.com/news/${item.slug}`} target="_blank" rel="noopener noreferrer" className='w-fit'>
                                                <Button variant="secondaryText" label="Read More" size='small' />
                                            </a>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </div>


            <div className={`${classes.mobileContainer}`}>
                {loading ? (
                    <div className={`grid gap-4 w-full`}>
                        <div className={`w-[336px] h-[406px] animate-pulse rounded-lg ${classes.background}`}></div>
                        <div className='grid w-[336px]'>
                            <div className={`w-full h-[156px] animate-pulse rounded-lg ${classes.background}`}></div>
                        </div>
                    </div>
                ) : (
                    <div className={`grid gap-4`}>
                        {news.slice(0, 1).map((item: any) => (
                            <div className={`${classes.mainContainer}  rounded-xl`}>
                                <a href={`https://news.steelbazaar.com/news/${item.slug}`} target="_blank" rel="noopener noreferrer" className='flex flex-col gap-y-4 p-2 w-full'>
                                    <img src={item.banner} alt="" className={`${classes.largeImage} h-[156px] w-full`} />
                                    <div className='grid gap-y-1'>
                                        <div className={`${classes.chip} py-1 px-2 text-xs w-fit`}>Notification</div>
                                        <div className='grid gap-y-1'>
                                            <div className={`${classes.newsHeading} text-2xl font-semibold`}>{item.title.substring(0, 60)}...</div>
                                            <div className={`${classes.newsDescription} text-lg`}>{item.content.replace(/<[^>]+>/g, '').substring(0, 225)}...</div>
                                        </div>
                                        <a href={`https://news.steelbazaar.com/news/${item.slug}`} target="_blank" rel="noopener noreferrer" className='w-fit'>
                                            <IconButton className='!px-0' variant="secondaryText" label="Read More" size='small' />
                                        </a>
                                    </div>
                                </a>
                            </div>
                        ))}

                        <div className='w-full'>
                            {news.slice(1, 2).map((item: any) => (
                                <div className={`${classes.mainContainer} rounded-xl`}>
                                    <a href={`https://news.steelbazaar.com/news/${item.slug}`} target="_blank" rel="noopener noreferrer" className='flex gap-x-2.5 p-2'>

                                        <img className={`${classes.smallImage}`} src={item.banner} alt="" />
                                        <div className={`grid gap-y-1`}>
                                            <div className='flex justify-between'>
                                                <div className={`${classes.newsHeading} font-semibold`}>{item.title.substring(0, 45)}...</div>
                                                <div className={`${classes.chip} h-fit`}>Notification</div>
                                            </div>
                                            <div className={`${classes.samllNewsDescription}`}>{item.content.replace(/<[^>]+>/g, '').substring(0, 100)}...</div>
                                            <a href={`https://news.steelbazaar.com/news/${item.slug}`} target="_blank" rel="noopener noreferrer" className='w-fit'>
                                                <IconButton className='!px-0' variant="secondaryText" label="Read More" size='small' />
                                            </a>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </div>
        </div>

    );
};

export default NewsTemplate;