import React from "react";
import { Grid } from "@mui/material";
import { createUseStyles } from 'react-jss';
import Button from "../Button/Button";
import { Cards } from "./StatusCard";
import { formatInIndianNumberSystem } from "../../../utils/helper";


interface CardListProps {
    cards: Cards[];
}

const useStyles = createUseStyles((theme: any) => ({
    title: {
        color: theme.palette.text.neutral.neutral970
    },
    statusHeader: {
        padding: "12px 10px"
    },
    stats: {
        padding: "12px 0px 16px 0px",
    },
    count: {
        borderTop: `1px solid ${theme.palette.border.neutral.neutral960}`,
        padding: "16px 0px 12px 0px"
    },
    totalQuanity: {
        color: theme.palette.text.neutral.neutral600,
        fontSize: "12px"
    },
    quantityValue: {
        color: theme.palette.text.neutral.neutral900,
    },
    qty: {
        borderRight: `1px solid ${theme.palette.border.neutral.neutral960}`,
    }
}));

const ProductStatusCard: React.FC<CardListProps> = ({ cards }) => {

    const classes = useStyles();

    return (
        <Grid container spacing={3}>
            {cards.map((customerCard, index) => (
                <Grid item xs={12} sm={6} md={customerCard?.itemspace} key={index}>
                    <div className="w-full rounded-2xl border">
                        <div className={`rounded-t-2xl ${classes.statusHeader}`} style={{ backgroundColor: customerCard.backgroundColor, backgroundSize: "cover" }}>
                            <div className={`${classes.title} flex justify-center text-base font-medium `} style={{ color: customerCard.textColor }}>{customerCard.title}</div>
                        </div>
                        <div className={`${classes.stats}`}>
                            <div className={` flex justify-between ml-4 mr-4`}>
                            <div className={`flex items-center flex-col gap-1 `}>
                                <span className={classes.totalQuanity}>Total Qty (MT)</span>
                                <span className={`${classes.quantityValue} font-semibold`}>{formatInIndianNumberSystem(customerCard?.productQuantity ?? 0)}</span>
                            </div>
                            <span className={`${classes.qty} `}></span>
                            <div className={`flex items-center flex-col gap-1 `}>
                                <span className={classes.totalQuanity}>No of SKU’s</span>
                                <span className={`${classes.quantityValue} font-semibold`}>{customerCard?.productSKU}</span>
                            </div>
                            </div>    
                        </div>
                        <div className={`flex justify-center ${classes.count}`}>
                            <Button className='!rounded-none' variant="tertiaryText" label={"View Details"} size="small" onClick={customerCard?.onclick} />
                        </div>
                    </div>
                </Grid>
            ))}
        </Grid>
    );
};
export default ProductStatusCard;