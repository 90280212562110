import { createUseStyles } from "react-jss";
import { Button } from "@mui/joy";
import RequestDemoTemplate from "../../template/Home/RequestDemo/RequestDemo.template";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward"; 
//bykamal
import WebRecycleBanner from "../../../assets/images/RecycleBanner.jpg";
import MobileRecycleBanner from "../../../assets/images/SellerBannerMobile.jpg";
import DotImg from "../../../assets/images/dot-shape.png"; 
import BulletImg from "../../../assets/images/bulletIcon.svg";
import Section1 from "../../../assets/images/Recycling-Section-0.jpg"; 
import Section3 from "../../../assets/images/Recycling-Section-1.jpg";
import Section4 from "../../../assets/images/Recycling-Section-2.jpg";
import Section5 from "../../../assets/images/Recycling-Section-3.jpg";
import Section6 from "../../../assets/images/Recycling-Section-4.jpg"; 
import Section8 from "../../../assets/images/Recycling-Section-5.jpg";
import RealTimeIcon1 from "../../../assets/images/Recycling_Real-Time-Icon_1.svg";
import RealTimeIcon2 from "../../../assets/images/Recycling_Real-Time-Icon_2.svg";
import RealTimeIcon3 from "../../../assets/images/Recycling_Real-Time-Icon_3.svg";
import RealTimeIcon4 from "../../../assets/images/Recycling_Real-Time-Icon_4.svg";
import RecyclingScrap1 from "../../../assets/images/Recycling_Scrap_1.jpg";
import RecyclingScrap2 from "../../../assets/images/Recycling_Scrap_2.jpg";
import RecyclingScrap3 from "../../../assets/images/Recycling_Scrap_3.jpg";

const useStyles = createUseStyles((theme: any) => ({
  bannerContainer: {
    background: `url(${WebRecycleBanner})`,
    backgroundSize: "cover",
  }, 
  "@media screen and (max-width: 480px)": {
    bannerContainer: {
      backgroundImage: `url(${MobileRecycleBanner})`,
      backgroundSize: "cover",
    },
  },
}));

const Recycling: React.FC = () => {
  const classes = useStyles();  
  return (
    <>
      <div className={`${classes.bannerContainer}`}>
        <div className="container mx-auto px-4">
          <div className="insideBannerBx">
            <div className="insideBannerInfo">
              <b>Reduce,<br />Recycle, Reinvent</b>
              <h1>The Steel Industry Reimagined</h1>
              <p>Join us in transforming scrap into high-value steel, reducing waste, and <br />enhancing efficiency</p>
            </div>
          </div>
        </div>
      </div>
      <div className="neutral20Bg lg:py-14 py-8 w-full">
        <div className="container lg:px-4 px-2 mx-auto flex lg:columns-2 columns-1 lg:flex-row flex-col lg:gap-10 gap-2 PageInfoBoxGry">
          <div className="lg:w-2/5 w-full grid content-center ">
            <h2>Steel Recycling: Transforming Waste into Wealth</h2>
          </div>
          <div className={`lg:w-3/5 w-full`}>
            <p className="!mb-0">At Steelbazaar, we’re committed to creating a sustainable steel industry through our flagship recycling initiative, SB Green. By bridging the gap between scrap buyers and sellers, we ensure efficient recycling that reduces waste and promotes environmental stewardship. Our streamlined platform connects stakeholders in the steel supply chain, offering transparency, secure transactions, and advanced digital tools to facilitate seamless recycling operations. Whether you're looking to source or supply recyclable scrap, Steelbazaar is your trusted partner in creating a greener future.</p>
          </div>
        </div>
      </div>
     
      <div
        className={`container mx-auto lg:px-4 px-2 lg:pt-24 py-8`}
      >
        <div className={`headingContainer`}>
          <div className="heading">How the Steelbazaar <span>Recycling Process Works</span></div>
        </div>
        {
          // Section One Start Here
        }
        <div className="flex lg:columns-2 columns-1 lg:flex-row flex-col items-center ZigZagSection">
          <div className="2xl:w-4/12 lg:w-5/12 w-full flex content-center justify-center">
            <div className="ZigZagImgBox">
              <div className="ImgItem">
                <img src={DotImg} alt="" />
              </div>
              <img src={Section1} className="w-full" alt="" />
            </div>
          </div>
          <div className="2xl:w-8/12 lg:w-7/12 w-full ZigZagText">
            <div className="grayText">Step 01:</div>
            <div className="heading48">
            Scrap Listing: <span>Present 
            Your Scrap with Precision</span>
            </div>
            <div className="PointBox">
              <ul>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span>
                    <b>Detailed Listings</b>
                    Sellers upload scrap details, including type, grade, weight, photos, and pricing.
                  </span>
                </li>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span>
                    <b>Broad Reach</b>
                    Make your scrap visible to a vast network of buyers, increasing the chances of a quick sale.
                  </span>
                </li>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span>
                    <b>Enhanced Visibility</b>
                    Opt for auction-based listings for better exposure through SB BidHub.
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        </div>
        {
          // Section Two Start Here
        }
        <div className="container mx-auto ZigZagText text-center lg:pb-10">
                  <div className="grayText">Step 02:</div>
            <div className="heading48">
            Competitive Auctions via SB BidHub <span className="lg:block inline">Buy and Sell Transparently</span>
            </div>
            </div>
            <div className='w-full flex blueOddEvenBox  lg:py-10 py-4'>
                <div className='container mx-auto flex lg:flex-row flex-col lg:px-4 px-0'>
                    <div className='lg:w-6/12 w-full leftBx'>
                    <div className='OddTextBox'>
                        <div className='OddHeading'><span>For </span>Buyers</div>
                        {/* <b className='lg:pt-4 pt-2'>Connect with Reliable Buyers</b> */}
                        <div className='OddContent'>
                        <ul>
                            <li>
                            <img src={BulletImg} className="bulletBrd" alt="" />
                            <span>
                            Open Auction Transparency
                              <p>Participate in competitive bidding to secure scrap materials at market-driven prices.</p>
                            </span>
                            </li>
                            <li>
                            <img src={BulletImg} className="bulletBrd" alt="" />
                            <span>
                            Detailed Offers
                            <p>View complete specifications and terms for every auctioned scrap lot.</p></span>
                            </li>
                            <li>
                            <img src={BulletImg} className="bulletBrd" alt="" />
                            <span>
                            Regular Sale Offers
                            <p>Access and purchase directly listed scraps without bidding hassles.</p></span>
                            </li>
                        </ul>
                        </div>
                    </div>
                    </div>
                    <div className='lg:w-6/12 w-full RightBx'>
                    <div className='EvenTextBox'>
                        <div className='EvenHeading'>For <span>Sellers</span></div>
                        {/* <b className='lg:pt-4 pt-2'>Payments When You Need Them</b> */}
                        <div className='EvenContent'>
                        <ul>
                            <li>
                            <img src={BulletImg} className="bulletBrd" alt="" />
                            <span>
                            Instant Sales
                              <p>List scrap on SB BidHub for fast, competitive, and transparent transactions.</p>
                            </span>
                            </li>
                            <li>
                            <img src={BulletImg} className="bulletBrd" alt="" />
                            <span>
                            Prompt Cash Flow
                              <p>Sell quickly and realize immediate payments upon deal completion.</p>
                            </span>
                            </li>
                            <li>
                            <img src={BulletImg} className="bulletBrd" alt="" />
                            <span>
                            Maximised Value
                              <p>Achieve fair market pricing with active buyer participation in auctions.</p>
                            </span>
                            </li>
                        </ul>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
<div  className={`container mx-auto lg:px-4 px-2 lg:pt-24 py-8`}>
        {
          // Section Three Start Here
        }
        <div className="flex lg:columns-2 columns-1 lg:flex-row flex-col items-center ZigZagSection">
          <div className="2xl:w-4/12 lg:w-5/12 w-full flex order-1 lg:order-2 justify-center">
            <div className="ZigZagImgBox">
              <div className="ImgItemLeft">
                <img src={DotImg} alt="" />
              </div>
              <img src={Section3} className="w-full" alt="" />
            </div>
          </div>
          <div className="2xl:w-8/12 lg:w-7/12 w-full ZigZagText order-2 lg:order-1">
            <div className="grayText">Step 03:</div>
            <div className="heading48">
            Buyer Search & Match: <span>Find Your Ideal Trade Partner</span>
            </div>
            <div className="PointBox">
              <ul>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span>
                    <b>Intelligent Filters</b>
                    Use advanced search tools to locate scrap by type, grade, and quantity. 
                  </span>
                </li>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span>
                    <b>AI-Powered Matching</b>
                    Connect with the most relevant buyers or sellers automatically.
                  </span>
                </li>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span>
                    <b>Efficiency Boost</b>
                    Save time by finding precisely what you need in just a few clicks.
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {
          // Section Four Start Here
        }
        <div className="flex lg:columns-2 columns-1 lg:flex-row flex-col items-center ZigZagSection">
          <div className="2xl:w-4/12 lg:w-5/12 w-full flex content-center justify-center">
            <div className="ZigZagImgBox">
              <div className="ImgItem">
                <img src={DotImg} alt="" />
              </div>
              <img src={Section4} className="w-full" alt="" />
            </div>
          </div>
          <div className="2xl:w-8/12 lg:w-7/12 w-full ZigZagText">
            <div className="grayText">Step 04:</div>
            <div className="heading48">
            Quality Inspection:  <span>Trust Built on Assurance</span>
            </div> 
            <div className="PointBox">
              <ul>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span>
                    <b>Verified Quality</b>
                    Arrange third-party inspections or certifications to confirm scrap grade and composition.
                  </span>
                </li>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span>
                    <b>Standardised Grading</b>
                    Ensure consistent quality with industry-standard grading systems.
                  </span>
                </li>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span>
                    <b>Build Confidence</b>
                    Foster trust with transparent quality assurance processes.
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {
          // Section Five Start Here
        }
        <div
          className="flex lg:columns-2 columns-1 lg:flex-row flex-col items-center ZigZagSection">
          <div className="2xl:w-4/12 lg:w-5/12 w-full flex order-1 lg:order-2 justify-center">
            <div className="ZigZagImgBox">
              <div className="ImgItemLeft">
                <img src={DotImg} alt="" />
              </div>
              <img src={Section5} className="w-full" alt="" />
            </div>
          </div>
          <div className="2xl:w-8/12 lg:w-7/12 w-full ZigZagText order-2 lg:order-1">
            <div className="grayText">Step 05:</div>
            <div className="heading48">
            Negotiation & Agreement: <span>Smooth Communication for Better Deals</span>
            </div>
            <div className="PointBox">
              <ul>
                <li className="items-center">
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span>
                    <b>Integrated Chat Tools</b>
                    Negotiate directly on the platform for quick and efficient deal-making.
                  </span>
                </li>
                <li className="items-center">
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span>
                    <b>Clear Terms</b>
                    Finalize pricing, delivery schedules, and payment terms seamlessly.
                  </span>
                </li>
                <li className="items-center">
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span>
                    <b>Faster Closures</b>
                    Streamlined communication leads to quicker agreement and order finalisation.
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {
          // Section Six Start Here
        }
        <div className="flex lg:columns-2 columns-1 lg:flex-row flex-col items-center ZigZagSection">
          <div className="2xl:w-4/12 lg:w-5/12 w-full flex content-center justify-center">
            <div className="ZigZagImgBox">
              <div className="ImgItem">
                <img src={DotImg} alt="" />
              </div>
              <img src={Section6} className="w-full" alt="" />
            </div>
          </div>
          <div className="2xl:w-8/12 lg:w-7/12 w-full ZigZagText">
            <div className="grayText">Step 06:</div>
            <div className="heading48">
            Logistics Arrangement: <span>Hassle-Free Scrap Transportation</span>
            </div>
            <div className="PointBox">
              <ul>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span>
                    <b>Trusted Partners</b>
                    Leverage Steelbazaar’s logistics network for reliable pick-up and delivery.
                  </span>
                </li>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span>
                    <b>Real-Time Tracking</b>
                    Monitor transportation progress and ensure timely delivery.Monitor transportation progress and ensure timely delivery.
                  </span>
                </li>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span>
                    <b>Cost Efficiency</b>
                    Optimize transport costs with competitive logistics options.
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
</div>
<div className="container mx-auto ZigZagText text-center lg:pb-10">
                  <div className="grayText">Step 07:</div>
            <div className="heading48">Financial Support with SB Instafin Services <span className="lg:block inline">Empower Your Cash Flow</span>
            </div>
            </div>
        <div className='w-full flex blueOddEvenBox  lg:py-10 py-4'>
                <div className='container mx-auto flex lg:flex-row flex-col lg:px-4 px-0'>
                    <div className='lg:w-6/12 w-full leftBx'>
                    <div className='OddTextBox'>
                        <div className='OddHeading'><span>For </span>Buyers</div>
                        {/* <b className='lg:pt-4 pt-2'>Connect with Reliable Buyers</b> */}
                        <div className='OddContent'>
                        <ul>
                            <li>
                            <img src={BulletImg} className="bulletBrd" alt="" />
                            <span>
                              Flexible Payments 
                              <p>Manage large purchases with customisable payment terms.</p>
                            </span>
                            </li>
                            <li>
                            <img src={BulletImg} className="bulletBrd" alt="" />
                            <span>
                            Reduced Financial Strain
                            <p>Spread costs effectively without compromising on purchase timelines.</p></span>
                            </li>
                            <li>
                            <img src={BulletImg} className="bulletBrd" alt="" />
                            <span>
                            Innovative Financing
                            <p>Access to pre-vetted leads from genuine buyers.</p></span>
                            </li>
                        </ul>
                        </div>
                    </div>
                    </div>
                    <div className='lg:w-6/12 w-full RightBx'>
                    <div className='EvenTextBox'>
                        <div className='EvenHeading'>For <span>Sellers</span></div>
                        {/* <b className='lg:pt-4 pt-2'>Payments When You Need Them</b> */}
                        <div className='EvenContent'>
                        <ul>
                            <li>
                            <img src={BulletImg} className="bulletBrd" alt="" />
                            <span>
                            Instant Cash Payments
                              <p>Receive funds immediately upon transaction completion.</p>
                            </span>
                            </li>
                            <li>
                            <img src={BulletImg} className="bulletBrd" alt="" />
                            <span>
                            Improved Cash Flow
                              <p>Eliminate payment delays to keep operations running smoothly.</p>
                            </span>
                            </li>
                            <li>
                            <img src={BulletImg} className="bulletBrd" alt="" />
                            <span>
                            Secure Transactions
                              <p>Rely on SB Instafin for guaranteed and hassle-free payments.</p>
                            </span>
                            </li>
                        </ul>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
<div  className={`container mx-auto lg:px-4 px-2 lg:py-24 py-8`}>
        {
          // Section Eight Start Here
        }
        <div
          className="flex lg:columns-2 columns-1 lg:flex-row flex-col items-center ZigZagSection">
          <div className="2xl:w-4/12 lg:w-5/12 w-full flex order-1 lg:order-2 justify-center">
            <div className="ZigZagImgBox">
              <div className="ImgItemLeft">
                <img src={DotImg} alt="" />
              </div>
              <img src={Section8} className="w-full" alt="" />
            </div>
          </div>
          <div className="2xl:w-8/12 lg:w-7/12 w-full ZigZagText order-2 lg:order-1">
            <div className="grayText">Step 08:</div>
            <div className="heading48">
            Recycling & Reporting:<span> Complete the Cycle Sustainably</span>
            </div>
            <div className="PointBox">
              <ul>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span>
                    <b>Sustainable Practices</b>
                    Buyers process the scrap for recycling, reducing environmental impact.
                  </span>
                </li>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span>
                    <b>Service Offerings</b>
                    Highlight any additional services like custom manufacturing
                    or packaging.
                  </span>
                </li>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span>
                    <b>Performance Tracking</b>
                    Use analytics to measure transaction efficiency and material reuse.
                  </span>
                </li>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span>
                    <b>Data-Driven Decisions</b>
                    Monitor sustainability metrics and operational improvements over time.
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>

      </div>
      
      <div className="neutral20Bg lg:py-28 py-8 w-full">
        <div className={`headingContainer`}>
          <div className="heading"><span className="lg:block inline-block">Real-Time Tools </span>
          for Enhanced Decision-Making</div> 
        </div>
        <div className="container mx-auto lg:px-4 px-3 w-full flex flex-wrap gap-4 ">
          <div className="whiteGrayBox colWidth-4">
            <div className="iconBox orangeBg50 orangeBrd300">
              <img src={RealTimeIcon1} alt="" />
            </div>
            <div className="TextBox">
              <b>Live Pricing Tracker</b>
              Monitor scrap market rates in real-time to capitalise on opportunities.
            </div>
          </div>

          <div className="whiteGrayBox colWidth-4">
            <div className="iconBox primaryBg50 primaryBrd300">
              <img src={RealTimeIcon2} alt="" />
            </div>
            <div className="TextBox">
              <b>Supply Chain Dashboard</b>
              A centralised hub for tracking orders, logistics, and communications.
            </div>
          </div> 

          <div className="whiteGrayBox colWidth-4">
            <div className="iconBox successBg50 successBrd300">
              <img src={RealTimeIcon3} alt="" />
            </div>
            <div className="TextBox">
              <b>Analytics & Reporting</b>
              Gain insights into market trends, sales performance, and sustainability metrics.
            </div>
          </div>

          <div className="whiteGrayBox colWidth-4">
            <div className="iconBox secondaryBg50 secondaryBrd300">
              <img src={RealTimeIcon4} alt="" />
            </div>
            <div className="TextBox">
              <b>Smart Alerts</b>
              Notifications for new listings, pricing updates, and deal closures.
            </div>
          </div>

        </div>
      </div>

      <div  className={`container mx-auto lg:px-4 px-2 lg:py-24 py-8`}>
      <div className="headingContainer">
        <div className="heading !text-left">Steelbazaar’s Scrap Aggregation Process</div>
        <div className="Subheading !text-left">Steelbazaar ensures efficient aggregation & delivery of 
        bulk scraps to ElectricArc Furnace (EAF) operators by</div>
        </div>

        <div className="container mx-auto lg:px-4 px-3 w-full flex flex-wrap gap-4 ">
          <div className="GrayCard colWidth-3">
          <img src={RecyclingScrap1} alt="" />
              <span>
              Collaborating with small scrap traders to source ferrous and non-ferrous materials.
              </span>
          </div>
          <div className="GrayCard colWidth-3">
          <img src={RecyclingScrap2} alt="" />
              <span>Partnering with manufacturing units to collect scraps generated during production.</span>
          </div>
          <div className="GrayCard colWidth-3">
          <img src={RecyclingScrap3} alt="" />
              <span>Consolidating and streamlining logistics ensures large-scale, uninterrupted supplies for Green Steel production.</span>
          </div>


          </div>

      </div>

      <div className="neutral20Bg lg:pt-28 lg:pb-0   py-8 w-full">
        <div className={`headingContainer`}>
          <div className="heading">Benefits of Using Steelbazaar for <span className="lg:block inline-block">Scrap Supply Chain</span></div> 
        </div> </div>
        <div className='w-full flex blueOddEvenBox  lg:py-10 py-4'>
                <div className='container mx-auto flex lg:flex-row flex-col lg:px-4 px-0'>
                    <div className='lg:w-6/12 w-full leftBx'>
                    <div className='OddTextBox'>
                        <div className='OddHeading'><span>For </span>Buyers</div> 
                        <div className='OddContent'>
                        <ul>
                            <li>
                            <img src={BulletImg} className="bulletBrd" alt="" />
                            <span>
                            Verified Sellers
                              <p>Source high-quality scrap from trusted suppliers.</p>
                            </span>
                            </li>
                            <li>
                            <img src={BulletImg} className="bulletBrd" alt="" />
                            <span>
                            Time Savings
                            <p>Quickly find the material you need using advanced filters.</p></span>
                            </li>
                            <li>
                            <img src={BulletImg} className="bulletBrd" alt="" />
                            <span>
                            Cost Efficiency
                            <p>Negotiate competitive rates with transparent pricing tools.</p></span>
                            </li>
                            <li>
                            <img src={BulletImg} className="bulletBrd" alt="" />
                            <span>
                            Streamlined Logistics
                            <p>Ensure timely delivery with integrated services.</p></span>
                            </li>
                            <li>
                            <img src={BulletImg} className="bulletBrd" alt="" />
                            <span>
                            Sustainability Focus
                            <p>Contribute to eco-friendly steel production.</p></span>
                            </li>
                        </ul>
                        </div>
                    </div>
                    </div>
                    <div className='lg:w-6/12 w-full RightBx'>
                    <div className='EvenTextBox'>
                        <div className='EvenHeading'>For <span>Sellers</span></div>
                        <div className='EvenContent'>
                        <ul>
                            <li>
                            <img src={BulletImg} className="bulletBrd" alt="" />
                            <span>
                            Market Reach
                              <p>Access a vast network of potential buyers.</p>
                            </span>
                            </li>
                            <li>
                            <img src={BulletImg} className="bulletBrd" alt="" />
                            <span>
                            Faster Sales
                              <p>Reduce inventory downtime with quick matches.</p>
                            </span>
                            </li>
                            <li>
                            <img src={BulletImg} className="bulletBrd" alt="" />
                            <span>
                            Fair Pricing
                              <p>Set competitive prices backed by market data.</p>
                            </span>
                            </li>
                            <li>
                            <img src={BulletImg} className="bulletBrd" alt="" />
                            <span>
                            Reliable Payment
                              <p>Secure your revenue with escrow services.</p>
                            </span>
                            </li>
                            <li>
                            <img src={BulletImg} className="bulletBrd" alt="" />
                            <span>Simplified Operations
                              <p>Manage everything from listing to delivery on one platform.</p>
                            </span>
                            </li>
                        </ul>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
            <div className='w-full flex blueOddEvenBoxRevse  lg:py-10 py-4'>
                <div className='container mx-auto flex lg:flex-row flex-col lg:px-4 px-0'>
                    <div className='lg:w-6/12 w-full leftBx'>
                    <div className='text-center'>
                    <div className='txt32 FW500 Txtwhite FW500 mb-2'>Buyers' RFQ Form for Scrap</div>
                    <Button className="Btn sm whiteBg Txtprimary500 mt-2">Apply Now</Button>
                    </div>
                    </div>
                    <div className='lg:w-6/12 w-full RightBx'>
                    <div className='text-center'>
                    <div className='txt32 FW500 Txtprimary500 FW500 mb-2'>Instant Buying Form for Scrap Sellers</div>
                    <Button className="Btn sm primary500Bg Txtwhite mt-2" >Apply Now</Button>
                    </div>
                    </div>
                </div>
            </div>
    
        
        <div className="container mx-auto lg:py-24 py-8 lg:px-4 px-2">
        <div className={`headingContainer`}>
          <div className="heading">Why Choose <span>SB Green Recycling?</span></div> 
          <div className="Subheading">Experience a workplace where innovation meets collaboration. At SteelBazaar, we prioritise</div>
        </div>

        <div className="container mx-auto lg:px-4 lg:py24 lg:py-8 px-3 w-full flex flex-wrap gap-4 ">
          <div className="warningBg50 warningBrd300 SmallCard colWidth-3">
              <b className="txt22 FW500">Expertise in Scrap Recycling</b>
              Decades of experience in transforming waste into value.
          </div>
          <div className="primaryBg50 primaryBrd300  SmallCard colWidth-3">
          <b className="txt22 FW500">Reliable Aggregation Network</b>
          Efficient partnerships for uninterrupted supply.
          </div>
          <div className="successBg50 successBrd300  SmallCard colWidth-3">
          <b className="txt22 FW500">Sustainability Commitment</b>
          Aligning industrial practices with environmental goals.
          </div>
          <div className="complementary50Bg complementaryBrd300 SmallCard colWidth-3">
          <b className="txt22 FW500">Advanced Digital Tools</b>
          Streamlining transactions and logistics through technology.
          </div>
          <div className="orangeBg50 orangeBrd300 SmallCard colWidth-3">
          <b className="txt22 FW500">Fast & Transparent Transactions</b>
          Ensuring fair evaluations and timely payouts.
          </div>
          <div className="secondaryBg50 secondaryBrd300 SmallCard colWidth-3">
          <b className="txt22 FW500">Supporting Green Steel Production</b>
          Enabling eco-friendly manufacturing processes.
          </div> 
          </div>
        </div> 

      <div  className={`CTABox lg:py-24 py-8 w-full textBoxfg`}>
      <div className="container mx-auto CallToAction lg:px-4 px-3 ">
        <div className="2xl:w-5/12 lg:w-5/12 flex flex-col gap-y-2.5 items-start">
       <h2>
       Closing the Loop on Sustainability
       </h2>
       <p className="mb-5">Steelbazaar’s SB Green Recycling initiative is a step towards a sustainable future by promoting innovative recycling strategies. By choosing SB Green Recycling, you’re embracing eco-friendly practices and unlocking new business opportunities.</p>
       <Button className="Btn whiteBg Txtprimary500">Contact Now <ArrowForwardIcon className="primary500Bg text-white" />
                </Button>
      </div>
      </div>
      </div>

      <RequestDemoTemplate />
    </>
  );
};
export default Recycling;

