import { useAuthenticatedUser } from '../hooks/useAuthenticatedUser';
import { API_METHOD } from '../utils/constant';
import { request } from '.';
import { replaceUrlParams } from '../utils/helper';

const API_BASE_URL_V2 = process.env.REACT_APP_API_V2_URL;

const VENDOR_CATALOGUE_URLS = {
    CREATE_CATALOGUE: "/vendors/:id/catalogue/create",
    UPDATE_CATALOGUE: "/vendors/:id/catalogue/:catalogueId",
    GET_CATALOGUE_BY_ID: "/vendors/:id/catalogue/:catalogueId",
    GET_USN_DETAILS: "/vendors/:vendorId/products/:vendorProductId",
    GET_ALL_CATALOGUES: "vendors/:vendorId/catalogues",
    GET_VAS_SERVICE: "vas/services",
    GET_VAS_CUSTOMISATION: "vendors/:vendorId/machines/:id",
    UPDATE_STATUS: '/seller/:vendorId/catalogues',
    GET_CATALOGUE_FEEDBACK: '/approval/history'
}

export interface IProductChargesDetails {
    locationId: number;
    chargeType: string;
    rate: number;
    rateType: string;
}

export interface IProductDiscountDetails {
    locationId: number;
    minQuantity: number;
    maxQuantity: number;
    discountType: string;
    minDiscount: number;
    maxDiscount: number;
}

export interface IProductServicesDetails {
    locationId: number;
    machineId: number | null;
}

export interface ICatalogueProductDetails {
    vendorProductId: number;
    warehouseId: number;
    marketRetailPrice: number;
    offerSalePrice: number;
    deliveryTerms: string;
    packagingTypeEnum: string;
    productChargesDetailsRequestBodyDTOs: IProductChargesDetails[];
    productDiscountDetailsRequestBodyDTOs: IProductDiscountDetails[];
    productServicesDetailsRequestBodyDTOs: IProductServicesDetails[];
}

export interface IVendorCatalogueAddServiceForm {
    name: string;
    description: string;
    catalogueType: string;
    productType: string;
    paymentTerms: string[];
    status: string;
    termsAndConditions: string;
    catalogueProductDetails: ICatalogueProductDetails[];
    isVerifiedTrusted: boolean;
}

export interface IVendorCatalogueUpdateStatus {
    catalogueIds: string[],
    status: string;
    resourceType: string
}

export interface ICatalogueVasService {
    superServiceIds: string | null;
    mainServiceIds: string | null;
    serviceCategoryIds: string | null;
}

export interface ICatalogueVasServiceParams{
    level: number;
}

export interface IGetCatalogueParams {
    catalogueId?: string;
    status?: string;
    search?: string;
    page: number;
    size: number;
    sort: string;
    productCategory?: number|null;
    classType: string;
}
export interface IGetCatalogueFeedbackParams {
    vendorId: number;
    catalogueId: number;
    resourceType: string;
    approvalStatus: string;
}

export const useVendorCatalogueService = () => {
    const { user: authenticatedUser } = useAuthenticatedUser();

    const createVendorCatalogue = (id: number, data: IVendorCatalogueAddServiceForm) => {
        return request(API_METHOD.POST, replaceUrlParams(VENDOR_CATALOGUE_URLS.CREATE_CATALOGUE, {id}), authenticatedUser, data, null, null, API_BASE_URL_V2);
    };

    const updateVendorCatalogue = (id: number, catalogueId: number,  data: IVendorCatalogueAddServiceForm) => {
        return request(API_METHOD.PUT, replaceUrlParams(VENDOR_CATALOGUE_URLS.UPDATE_CATALOGUE, {id, catalogueId}), authenticatedUser, data, null, null, API_BASE_URL_V2);
    };

    const GetVendorCatalogue = (id: number, catalogueId: number) => {
        return request(API_METHOD.GET, replaceUrlParams(VENDOR_CATALOGUE_URLS.GET_CATALOGUE_BY_ID, {id, catalogueId}), authenticatedUser, null, null, null, API_BASE_URL_V2);
    }

    const getVendorUsnDetails = (vendorId: number, vendorProductId: number) => {
        return request(API_METHOD.GET, replaceUrlParams(VENDOR_CATALOGUE_URLS.GET_USN_DETAILS, {vendorId, vendorProductId}), authenticatedUser, null, null, null, API_BASE_URL_V2);
    }

    const getAllVendorCatalogue = (vendorId: number , params: IGetCatalogueParams) => {
        return request(API_METHOD.GET, replaceUrlParams(VENDOR_CATALOGUE_URLS.GET_ALL_CATALOGUES, {vendorId}), authenticatedUser, null, { params }, null, API_BASE_URL_V2);
    }

    const getCatalogueVasService = (params:ICatalogueVasServiceParams ,data: ICatalogueVasService) => {
        return request(API_METHOD.POST, VENDOR_CATALOGUE_URLS.GET_VAS_SERVICE, authenticatedUser, data, { params }, null);
    };

    const getVasCustomisation = (vendorId: number | undefined, id: number) => {
        return request(API_METHOD.GET, replaceUrlParams(VENDOR_CATALOGUE_URLS.GET_VAS_CUSTOMISATION, {vendorId, id}), authenticatedUser, null, null, null, API_BASE_URL_V2);
    };

    const updateVendorCatalogueStatus = (vendorId: number , data: IVendorCatalogueUpdateStatus) => {
        return request(API_METHOD.PUT, replaceUrlParams(VENDOR_CATALOGUE_URLS.UPDATE_STATUS, {vendorId}) , authenticatedUser, data, null, null, API_BASE_URL_V2);
    };

    const getCatalogueFeedback = (params: IGetCatalogueFeedbackParams) => {
        return request(API_METHOD.GET, VENDOR_CATALOGUE_URLS.GET_CATALOGUE_FEEDBACK, authenticatedUser, null, { params }, null);
    };

    return {
        createVendorCatalogue,
        updateVendorCatalogue,
        GetVendorCatalogue,
        getVendorUsnDetails,
        getAllVendorCatalogue,
        getCatalogueVasService,
        getVasCustomisation,
        updateVendorCatalogueStatus,
        getCatalogueFeedback
    };
};
