import React, { useEffect, useMemo, useState } from 'react'
import { IValueAddedServices } from '../../../pages/Cart/CartDetails.page';
import ValueAddedServicesDetailsTemplate from './ValueAddedServicesDetails.template';
import { ICustomization } from '../../Customization/SelectCustomization.template';
import { VAS_SECTIONS } from '../../../../utils/constant';
import { ICartItem } from '../CartItemDetail.template';
import AddAnotherServiceTemplate from './AddAnotherService.template';
import { useConfigurationService } from '../../../../services/useConfigurationService';
import { useCatalogueService } from '../../../../services/useCatalogueService';
import { ICatalogue, ICurrentCartItem } from '../../../pages/CatalogueDetail/CatalogueDetails.page';
import { HTTP_STATUS } from '../../../../utils/types';

interface ValueAddedServicesProps {
	valueAddedServices: IValueAddedServices | null;
	shearingTotalWeight: number;
	slittingTotalWeight: number;
	shearingTotalCharges: number;
	slittingTotalCharges: number;
	setVasPopupTo: (isOpen: boolean) => void;
	isVasPopup: boolean;
	cartData: ICartItem;
	setValueAddedServicesTo: (value: (IValueAddedServices | null)) => void;
	setCartItemsTo?: (items: (ICartItem[] | [])) => void;
	cartItems?: ICartItem[] | [];
}

export interface CustomizationItem {
	text: string;
	description: string;
	label: string;
}

const ValueAddedServicesDetailsPage: React.FC<ValueAddedServicesProps> = ({ valueAddedServices, shearingTotalWeight, slittingTotalWeight, shearingTotalCharges, slittingTotalCharges, setVasPopupTo, isVasPopup, cartData, setValueAddedServicesTo, setCartItemsTo, cartItems}) => {
	const [currentSection, setCurrentSectionTo] = useState<string>(VAS_SECTIONS.VAS_DETAILS);
	const catalogueService = useCatalogueService();
	const [catalogue, setCatalogueTo] = useState<ICatalogue | null>(null);
	const [currentCartItem, setCurrentCartItemTo] = useState<ICurrentCartItem>({
		attributes: {},
		upc: null,
		warehouse: null,
		packaging: "WITHOUT_PACKAGING",
		quantity: "",
		secondarySkuId: null,
		paymentType: null
	});
	const [customization, setCustomizationTo] = useState<ICustomization[]>([]);
	const configurationService = useConfigurationService();
	const [anotherServiceDialogOpen, setAnotherServiceDialogOpenTo] = useState<boolean>(false);
	const [balanceWeight, setBalanceWeightTo] = useState<number>(cartData?.quantity - (shearingTotalWeight + slittingTotalWeight));
	const [availableCustomizations, setAvailableCustomizationsTo] = useState<ICustomization[]>([]);

	const handleDialogClose = () => {
		setVasPopupTo(false);
	};

	const handleAnotherServiceDialogClose = () => {
		setAnotherServiceDialogOpenTo(false);
	}

	useEffect(() => {
		if (cartData?.catalogueId) {
			catalogueService.getCatalogueById(cartData?.catalogueId)
				.then((response) => {
					if (response.status === HTTP_STATUS.OK) {
						setCatalogueTo(response.data.data ? response.data.data : null);
					}
				})
				.catch((error) => {
					console.error('Error', error);
					setCatalogueTo(null);
				});
		}
	}, [cartData?.catalogueId]);

	useEffect(() => {
		if (cartData?.catalogueId) {
			catalogueService.getAllUpcsByCatalogueIdV2(cartData?.catalogueId)
				.then((response) => {
					setCurrentCartItemTo((prevCartItem: any) => ({
						...prevCartItem,
						upc: response?.data?.data?.[0]
					}));
				},)
				.catch((error) => {
					console.error('Error', error);
				})
		}
	}, [cartData?.catalogueId]);

	const loadConfiguration = (customizationArray: any) => {
		configurationService.getConfiguration1({ contexts: "CUSTOMIZATION" })
			.then(res => {
				if (res?.status === HTTP_STATUS.OK) {
					const filteredCustomizations = res?.data?.data[0]?.data.filter((option: any) => {
						return customizationArray.includes(option.value);
					});
					setCustomizationTo(filteredCustomizations);
				}
			})
			.catch((error) => {
				console.error('Error', error);
				setCustomizationTo([]);
			});
	}

	useEffect(() => {
		if (catalogue?.customization) {
			loadConfiguration(catalogue.customization);
		}
	}, [catalogue?.customization]);

	const getAvailableCustomizations = (valueAddedServices: any, customizations: ICustomization[]) => {
		const availableValues = new Set(customizations.map(c => c.value.toLowerCase()));
	
		return customizations.filter(customization => {
			const addAnotherService = valueAddedServices[customization.value.toLowerCase()];
			return (!addAnotherService || addAnotherService.length === 0) && availableValues.has(customization.value.toLowerCase());
		});
	};

	useEffect(() => {
		if (valueAddedServices && customization) {
			setAvailableCustomizationsTo(getAvailableCustomizations(valueAddedServices, customization));
		}
	}, [valueAddedServices, customization])

	const vasSectionView = useMemo(() => {
		if (!currentCartItem?.upc) {
			return null;
		}
		switch (currentSection) {
			case VAS_SECTIONS.VAS_DETAILS: {
				return (
					<div>
						{(valueAddedServices && cartData && availableCustomizations) && (
							<ValueAddedServicesDetailsTemplate
								valueAddedServices={valueAddedServices}
								setDialogOpen={handleDialogClose}
								dialogOpen={isVasPopup}
								shearingTotalWeight={shearingTotalWeight}
								slittingTotalWeight={slittingTotalWeight}
								shearingTotalCharges={shearingTotalCharges}
								slittingTotalCharges={slittingTotalCharges}
								cartData={cartData}
								setValueAddedServicesTo={setValueAddedServicesTo}
								setCurrentSectionTo={setCurrentSectionTo}
								selectedUpc={currentCartItem?.upc}
								customization={customization}
								setAnotherServiceDialogOpenTo={setAnotherServiceDialogOpenTo}
								availableCustomizations={availableCustomizations}
								balanceWeight = {balanceWeight}
								setBalanceWeight = {setBalanceWeightTo}
								setCartItemsTo = {setCartItemsTo}
								cartItems = {cartItems}
							/>
						)}
					</div>
				);
			}
			case VAS_SECTIONS.ADD_ANOTHER_SERVICE: {
				return (
					<div>
						{anotherServiceDialogOpen && (<AddAnotherServiceTemplate
							anotherServiceDialogOpen={anotherServiceDialogOpen}
							setAnotherServiceDialogOpenTo={handleAnotherServiceDialogClose}
							valueAddedServices={valueAddedServices}
							setValueAddedServicesTo={setValueAddedServicesTo}
							setCurrentSectionTo={setCurrentSectionTo}
							selectedUpc={currentCartItem?.upc}
							quantity={cartData?.quantity}
							balanceWeight={balanceWeight}
							setBalanceWeight={setBalanceWeightTo}
							customization={customization}
							availableCustomizations={availableCustomizations}
						/>
						)}
					</div>
				);
			}
			default:
				return null;
		}

	}, [currentSection, valueAddedServices, currentCartItem?.upc, customization, balanceWeight, availableCustomizations]);

	return (
		<div className=" grid gap-y-6">
			{vasSectionView}
		</div>
	)

}

export default ValueAddedServicesDetailsPage