interface GeneralProps {
    contentStyle?: string;
    header?: string;
    boxStyle?: string;
    subHeading?: string;
}

const General: React.FC<GeneralProps> = ({ contentStyle, header, boxStyle, subHeading }) => {

    return (
        <>
            <div className={boxStyle}>
                <span className={subHeading}>{header}</span>
            </div>
            <div className={contentStyle}>
                <h4 className="text-xl font-semibold mt-3 mb-1">Introduction</h4>
                <p>
                    These Terms of Use ("Terms") define the contractual relationship between SteelBazaar ("We", "Our", "Us") and you ("Buyer", "You", "Your"), governing your use of our website/platform (collectively, the "Platform") to purchase products and associated services ("Products and Services")
                </p>
                <p>
                    The Platform includes our website, mobile applications, and their associated domains.
                </p>
                <p>
                    The following terms should be read carefully in conjunction with our Privacy Policy and other policies periodically updated and available on our Platform.
                </p>
                <p>
                    Your registration or first-time access to the Platform indicates Your acceptance of these Terms.
                </p>
                <h4 className="text-xl font-semibold mt-3 mb-1">Platform Usage</h4>
                <p>The Platform is exclusively intended for commercial and business use. Upon successful registration or initial login, You will be able to transact and procure Products and Services subject to these Terms. We reserve the exclusive right to restrict, suspend, or terminate access to the Platform without prior notice if required.</p>
                <p>You are responsible for safeguarding the confidentiality of your account credentials and for accepting full responsibility for activities undertaken through your account. If you suspect unauthorised account access, immediately inform us.</p>

                <h4 className="text-xl font-semibold mt-3 mb-1">Account Creation and Registration</h4>
                <p>To avail of Our Products and Services, You must register an account by providing accurate, current, and complete business-related details along with mandatory KYC documents. You must promptly update Us in case of any change in the provided details. Our representatives can assist you in your registration process if required.</p>

                <h4 className="text-xl font-semibold mt-3 mb-1">Your Obligations and Conduct</h4>
                <p>You agree to conduct Yourself lawfully on the Platform and solely for commercial transactions. You must not post, transmit, or engage in activities that are illegal, harmful, threatening, offensive, obscene, defamatory, discriminatory, or violate applicable laws.</p>

                <h4 className="text-xl font-semibold mt-3 mb-1">Limited License</h4>
                <p>We grant You a restricted, revocable, non-exclusive license to access and utilise the Platform solely for permitted purposes. Any unauthorised reproduction, modification, distribution, or commercial exploitation of Platform content or software is prohibited. Violation will result in immediate license revocation.</p>

                <h4 className="text-xl font-semibold mt-3 mb-1">Privacy</h4>
                <p>These Terms complement our Privacy Policy available on our Platform. Information shared with Us shall be processed confidentially and in accordance with applicable data protection laws. If You disagree with the Privacy Policy, You must discontinue Platform use immediately.</p>


                {/* <h4 className="text-xl font-semibold">Inforamtion</h4>
                <p> 1.1 The Platform is owned and operated by SteelBazaar (“SteelBazaar”), which has its  registered office at A-88, MIDC Taloja, Taloja Industrial Area, Distt. Raigad , Navi Mumbai -410208.
                </p>
                <br />
                <br />
                <p>1.2 For the purpose of these Terms of Use, wherever the context so requires:</p>
                <br />
                <p>
                    (a) “Affiliate” shall mean, with respect to each party, any person or entity which, directly or indirectly through one or more intermediary Controls, is Controlled by, or is under direct or indirect common Control with a party. “Control”, “Controlled” or “Controlling” shall mean to include any circumstance in which such person or entity is controlled by another person or entity by virtue of the latter person or entity’s power, directly or indirectly, to direct or cause the direction of the management and policies of the former, whether through the ownership of voting securities or holding of office in former, by contract or otherwise;
                </p>
                <br />
                <p>
                    (b) “Platform” shall mean this internet-based electronic e-commerce platform (https://steelbazaar.in/) and/or an application, website and/or web page (including but not limited to any web app, marketing landing page(s) and/or iOS/Android app or any other app that a User may be using to access/communicate with SteelBazaar), owned and/or operated, directly or indirectly, by SteelBazaar;
                </p>
                <br />
                <p>
                    (c) “GST” means the Goods and Service Tax is levied under the Central Goods and Services Tax Act and Rules (CGST), Integrated Goods and Services Tax Act and Rules (IGST), State Goods and Services Tax Acts and Rules (SGST) levied by the States, Union Territory Goods and Services Tax Act and Rules (UTGST) levied by the Union Territories, and all allied cesses, duties and taxes;
                </p>
                <br />
                <p>
                    (d) “KYC Documents” means such ‘know your customer’ documents as may be required to be submitted to SteelBazaar from time to time;
                </p>
                <br />
                <p>
                    (e) “Profile” means the individually identifiable profile of User created at the time of registering on the Platform;
                </p>
                <br />
                <p>
                    (f) “Transaction” means the process of placing an order for the Products (defined hereinafter) and/or availing the Services (defined hereinafter);
                </p>
                <br />
                <p>
                    (g) “User Content” means content including data or information of You, including Your Product rating, comments, reviews in relation to Products or the Platform, suggestions, questions, or other information and includes any material that is generated, as a whole or in part, on the Platform, including communication between Users, analytics and reports, trends, patterns, notes, messages, emails, posts and other communication that Users provide or receive, through Platform;
                </p>
                <br />
                <p>
                    (h) “We”, “Us”, “Our”, or “SteelBazaar” means SteelBazaar or any of its subsidiaries or Affiliates; and
                </p>
                <br />
                <p>
                    (i) “Buyer”, “You” or “User” means a legal entity which accesses or uses the Platform for the purpose of placing an order for the Products, and/or availing the Services, including any legal entity that may avail of the Service on behalf of another legal entity, or uploading information or views, and includes other persons jointly participating in using the Platform
                </p>
                <br />
                <p>
                    1.3 SteelBazaar owns and operates the Platform to facilitate the display and sale of various products including customized and cost effective flat rolled steel raw materials (“Products”) and provides incidental and ancillary services, including Slitting and Shearing of the Products  (“Services”).
                </p>
                <br />
                <p>
                    1.4 Upon your compliance with these Terms of Use and other applicable Agreements, SteelBazaar grants you a personal, non-exclusive, non-transferable, revocable, non-commercial, limited privilege to access and use the Platform, unless otherwise agreed upon. Such access excludes downloading (other than page caching) or modifying the contents of the Platform. This license does not allow resale or commercial use of the Platform or its contents, collection and use of product listings or prices, derivative use of the Platform or its contents, downloading or copying account information for another person's benefit, or use of data mining, robots, or similar data gathering tools.
                </p>
                <br />
                <p>
                    1.5 The Platform or any part of it, including copyrighted material, trademarks, or other proprietary information, shall not be reproduced, duplicated, uploaded, copied, sold, resold, visited, distributed, transmitted, or exploited for any commercial purpose, without the explicit approval of the company i.e., SteelBazaar.
                </p>
                <br />
                <p>
                    1.6 Please note that the Platform is intended for use within India only. You agree that in the event You avail or access the Platform and its Services form a legal jurisdiction other than the territory of India, You will be deemed to have accepted the terms and conditions of the Company as applicable to that jurisdiction
                </p>
                <br />
                <p>
                    1.7 While all efforts have been made to verify and ensure correctness of all information contained herein, You undertake to take your own assessment of the information before proceeding with any Transaction(s). You release and indemnify SteelBazaar and/or its directors, officers, representatives or any other such person from any cost, damage, liability, or consequence, if any,  arising from the actions of Platform Users. You waive any claims under applicable law. While SteelBazaar makes reasonable efforts, it cannot control or be held responsible for the information provided by third parties on the Platform, which may be offensive, harmful, inconsistent, inaccurate, or deceptive. Users are advised to exercise caution and practice restraint.
                </p>
                <br />
                <p>
                    1.8 Product prices are displayed in Indian Rupees and, unless specified otherwise, are inclusive of the packaging charges. All applicable taxes, including GST, customs duty, or other taxes or levies associated with the Transaction shall be calculated and applicable separately, at the time of checkout. Any additional charges and costs, wherever applicable, such as cost of transportation, warehousing and the like shall be determined and be applicable separately.
                </p>
                <br />
                <p>
                    1.9 Buyers are responsible for providing correct GSTIN details for tax benefits. SteelBazaar shall not be held responsible for mismatched or incorrect GST invoices.
                </p>
                <br />
                <p>
                    1.10 Prices are provided on an 'as is' basis, and SteelBazaar reserves the right to correct errors and update prices. SteelBazaar reserves the right to  cancel transactions based on faulty selling prices and communicate such cancellations to Users.
                </p>
                <br />
                <p>
                    1.11 SteelBazaar is not liable for damages to Products, out-of-stock situations, unavailability, or backorders, and You/the User waive any claims arising out of such a situation.
                </p>
                <br />
                <p>
                    1.12 The facilitation of display and sale of the Products and Services on the Platform by SteelBazaar are not absolute or unconditional and may be repudiated by SteelBazaar before Product or Service delivery, without assigning any reason or obtaining consent from the Buyer. SteelBazaar may cancel Transactions, listings, or acceptances without assigning any reason or under orders from statutory, quasi-judicial, or judicial authorities.
                </p>
                <br />
                <p>
                    1.13 Buyers acknowledge that the actual quantity delivered may vary by +/- 15%  compared to the ordered quantity. Invoices provided at the time of delivery/pickup will detail the exact quantity and price charged. Refunds or additional payments may be processed for negative or positive variations in the quantity supplied.
                </p>
                <br />
                <p>
                    1.14 The availability of Products and Services on the Platform is subject to change without notice prior to the purchase by the Buyer. Circumstances may arise where the Product becomes unavailable even as the  Product stays listed on the Platform. In such cases, SteelBazaar reserves the right to cancel the Transaction without recourse to the Buyer and without any liability to SteelBazaar. If the order is canceled after processing some payments, the relevant amounts will be reversed/remitted to the Buyer.
                </p> */}
            </div>
        </>
    )

}

export default General;