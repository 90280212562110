import { Button } from '@mui/joy';
import React from 'react';
import { createUseStyles } from 'react-jss';
import RequestDemoTemplate from '../../template/Home/RequestDemo/RequestDemo.template';
import platform1 from '../../../assets/images/platform1.jpg';
import platform2 from '../../../assets/images/platform2.jpg';
import platform3 from '../../../assets/images/platform3.jpg';
import platform4 from '../../../assets/images/platform4.jpg';
import platform5 from '../../../assets/images/platform5.jpg';
import financing from '../../../assets/icons/financing.jpg';
import bulk from '../../../assets/icons/bulk.svg';
import visibililty from '../../../assets/icons/visibililty.svg';
import programs from '../../../assets/icons/programs.svg';
import longPartnership from '../../../assets/icons/longPartnership.svg';
import stategy from '../../../assets/icons/stategy.svg';
import logistics from '../../../assets/icons/logistics.svg';
import Vendorinventory from '../../../assets/icons/Vendorinventory.svg';
import reach from '../../../assets/icons/reach.svg';
import vender from '../../../assets/images/vendorCentralBanner.jpg';
import seller from '../../../assets/images/SellerSecondaryBanner.jpg';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import vendorSeller from '../../../assets/images/vendorSeller.svg';
import vendorOrders from '../../../assets/images/vendorOrders.svg';
import vendorWearhouse from '../../../assets/images/vendorWearhouse.svg';
import vendorQuality from '../../../assets/images/vendorQuality.svg';
import vendorPayment from '../../../assets/images/vendorPayment.svg';
import vendorRetailing from '../../../assets/images/vendorRetailing.svg';
import vendorRelationship from '../../../assets/images/vendorRelationship.svg';
import OrderRetailingTemplate from './OrderRetailing.template';
import { useMediaQuery } from 'react-responsive';
import vendorArrow1 from '../../../assets/images/vendorArrow1.svg';
import vendorArrow2 from '../../../assets/images/vendorArrow2.svg';
import vendorArrow3 from '../../../assets/images/vendorArrow3.svg';
import vendorArrow4 from '../../../assets/images/vendorArrow4.svg';
import vendorArrow5 from '../../../assets/images/vendorArrow5.svg';
import showcaseProducts from '../../../assets/images/showcaseProducts.jpg';
import DotImg from "../../../assets/images/dot-shape.png";
import BulletImg from "../../../assets/images/bulletIcon.svg";
import invitationRegistration from '../../../assets/images/invitationRegistration.jpg';

interface CardData {
    img: string;
    title: string;
    description: string;
}

const useStyles = createUseStyles((theme: any) => ({
    container: {
    },
    bannerContainer: {
        backgroundImage: `url(${vender})`,
        backgroundSize: 'cover',
    },
    platformImg: {
        position: "relative"
    },
    platformText: {
        position: 'absolute',
        top: "50%",
        left: '55%',
        transform: 'translate(-50%, -50%)',
        color: '#fff',
        lineHeight: '28px',
    },
    borderDiv: {
        background: '#000',
        width: '2px',
        height: '25px',
    },
    platformSecText: {
        color: '#000',
        fontSize: '14px',
    },
    secondaryPlatform: {
        position: 'relative',
        top: '-87px',
    },

    vendorCTA: {
        background: `url(${seller})`,
        backgroundSize: 'cover',
    },
    vendorCount: {
        height: '120px',
        width: '120px',
        border: `10px solid ${theme.palette.border.complementary.complementary800}`,
        background: theme.palette.background.complementary.complementary400,
        borderRadius: '60px',
        fontSize: '54px',
        color: '#fff',

    },
    vendorfeatures: {
        position: 'relative',
        right: '-118px',
    },
    vendorfeatures2: {
        position: 'relative',
        left: '-100px',
    },
    venderFeatureText: {
        "& span": {
            color: theme.palette.text.neutral.neutral900,
        },
        "& p": {
            color: theme.palette.text.neutral.neutral900,
            lineHeight: '24px',
        },
    },
    mehDiagonal: {
        '&:after': {
            content: '""',
            borderBottom: `16px solid ${theme.palette.border.complementary.complementary800}`,
            width: '100px',
            position: 'absolute',
            top: '123px',
            left: '34%',
            transform: 'rotate(48deg)',
            transformOrigin: 'top left',
        },
    },
    mehDiagonal2: {
        '&:after': {
            content: '""',
            borderBottom: `16px solid ${theme.palette.border.complementary.complementary800}`,
            width: '101px',
            position: 'absolute',
            top: '120px',
            left: '54%',
            transform: 'rotate(-48deg)',
            transformOrigin: 'top right',
        },
    },

    accessText: {},
    '@media screen and (max-width: 768px)': {
        accessText: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '81%',
            "& h4": {

            },
            "& p": {

            },
        },
        sellerCTA: { backgroundPosition: 'bottom', }
    },
}));

const cardData = [
    {
        img: reach,
        title: "Increased Reach",
        description:
            "Access new markets and buyers through our extensive sales channels.",
    },
    {
        img: financing,
        title: "Guaranteed Payments",
        description:
            "Reliable, timely payments for all transactions",
    },
    {
        img: Vendorinventory,
        title: "Inventory Liquidation",
        description:
            "Move slow-moving or excess inventory with ease.",
    },
    {
        img: logistics,
        title: "Streamlined Logistics",
        description:
            "Leverage SteelBazaar's extensive logistics network, potentially reducing shipping complexities and costs.",
    },
    {
        img: bulk,
        title: "Potential for Bulk Orders",
        description:
            "SteelBazaar may place large purchase orders, providing opportunities for significant sales volumes.",
    },
    {
        img: stategy,
        title: "Simplified Pricing Strategy",
        description:
            "Set wholesale prices while SteelBazaar determines the retail pricing, simplifying your pricing strategies.",
    },
    {
        img: longPartnership,
        title: "Long-term Partnership",
        description:
            "Build a stable, long-term business with Steelbazaar as your key buyer.",
    },
    {
        img: programs,
        title: "Access to Marketing Programs",
        description:
            "To enhance product visibility, you can gain access to exclusive marketing & promotional opportunities, such as featured listings & digital marketing services.",
    },
    {
        img: visibililty,
        title: "Enhanced Product Visibility",
        description:
            "Products sold through Vendor Central are listed as Ships from and sold by SteelBazaar, increasing customer trust and potentially boosting sales.",
    },
];

const VendorCentralTemplate: React.FC = () => {
    const classes = useStyles();
    const isSmallScreen = useMediaQuery({ query: '(max-width: 1100px)' });

    return (
        <>
            <div className={`${classes.bannerContainer}`}>
                <div className="container mx-auto px-4">
                    <div className="insideBannerBx">
                        <div className="insideBannerInfo">
                            <h1>
                                Your Gateway to <br /> <span>Smarter, Faster</span> & <br /> <span>Profitable</span> Sales
                            </h1>
                        </div>
                    </div>
                </div>
            </div>

            <div className="neutral20Bg lg:py-14 py-8 w-full">
                <div className="container lg:px-4 px-2 mx-auto flex lg:columns-2 columns-1 lg:flex-row flex-col lg:gap-10 gap-2 PageInfoBoxGry">
                    <div className="lg:w-2/5 w-full grid content-center ">
                        <small>Welcome to SB Vendor Central</small>
                        <h2 className='!font-semibold'>About Steelbazaar<br />Vendor Central</h2>
                    </div>
                    <div className={`lg:w-3/5 w-full`}>
                        <p>
                            SteelBazaar Vendor Central is your gateway to a seamless steel trading experience. This platform enables manufacturers, dealers, and distributors to sell their steel materials directly to SteelBazaar, simplifying operations and boosting visibility.
                        </p>
                        <p>
                            With a secure, digital-first approach, vendors can easily manage inventory, orders, and payments. While you focus on delivering quality products, SteelBazaar handles logistics, sales, and customer interactions, ensuring effortless access to a wider market.
                        </p>
                        <p>
                            By partnering with SteelBazaar, you benefit from a trusted marketplace that drives growth, streamlines processes, and takes the hassle out of reaching your customers.
                        </p>
                    </div>
                </div>
            </div>
            <div className={`${classes.container} container mx-auto lg:px-4 px-2 lg:pt-24 py-8`}>
                <div className={`headingContainer`}>
                    <div className="heading">Start Selling on SB Vendor Central</div>
                </div>
                <div className="flex lg:columns-2 columns-1 lg:flex-row flex-col items-center lg:pt-10 lg:pb-[44px]">
                    <div className="2xl:w-4/12 lg:w-5/12 w-full flex order-1 lg:order-2 justify-center">
                        <div className="ZigZagImgBox">
                            <div className="ImgItemLeft">
                                <img src={DotImg} alt="" />
                            </div>
                            <img src={invitationRegistration} className="w-full" alt="" />
                        </div>
                    </div>
                    <div className="2xl:w-8/12 lg:w-7/12 w-full ZigZagText order-2 lg:order-1 ">
                        <div className="grayText">Step 01:</div>
                        <div className="heading48">
                            Invitation and <span>Registration</span>
                        </div>
                        <div className="Subhead">
                            <b>Join Our Exclusive Vendor Community</b>
                            <p>Seamless Integration into the SteelBazaar Ecosystem</p>
                        </div>

                        <div className="PointBox">
                            <ul>
                                <li>
                                    <img src={BulletImg} className="bulletBrd" alt="" />
                                    <span>
                                        <b>Invitation-Only Access</b>
                                        SteelBazaar Vendor Central is an invitation-only program. Once invited, register to gain access to the platform.
                                    </span>
                                </li>
                                <li>
                                    <img src={BulletImg} className="bulletBrd" alt="" />
                                    <span>
                                        <b>Simple Registration Process</b>
                                        To get started, complete the registration form with your business details.
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className='ZigZagSection'>
                    <div className={`headingContainer`}>
                        <div className="heading">Secure Platform Access</div>
                        <div className="Subheading">Access our secure vendor portal immediately upon registration.</div>
                    </div>
                    {isSmallScreen ?
                        <div className='flex flex-col items-center'>
                            <div className='relative w-max mb-[-27px]'>
                                <img src={vendorArrow1} alt="" />
                                <div className={`${classes.accessText}`}>
                                    <h4 className='text-base font-semibold leading-5 mb-2 text-center'>Register on Vendor Central</h4>
                                    <p className='text-[12px] leading-[14px] text-center'>Create an account and submit the necessary business documents.</p>
                                </div>
                            </div>
                            <div className='relative w-max z-1 mb-[-27px]'>
                                <img src={vendorArrow2} alt="" />
                                <div className={`${classes.accessText}`}>
                                    <h4 className='text-base font-semibold leading-5 mb-2 text-center text-white'>Business Verification</h4>
                                    <p className='text-[12px] leading-[14px] text-center text-white'>Upload relevant business certifications and bank details for verification</p>
                                </div>
                            </div>
                            <div className='relative w-max mb-[-27px]'>
                                <img src={vendorArrow3} alt="" />
                                <div className={`${classes.accessText}`}>
                                    <h4 className='text-base font-semibold leading-5 mb-2 text-center'>Inventory Submission</h4>
                                    <p className='text-[12px] leading-[14px] text-center'>Our team reviews your products to ensure quality standards.</p>
                                </div>
                            </div>
                            <div className='relative w-max mb-[-27px]'>
                                <img src={vendorArrow4} alt="" />
                                <div className={`${classes.accessText}`}>
                                    <h4 className='text-base font-semibold leading-5 mb-2 text-center text-white'>Vendor Approval</h4>
                                    <p className='text-[12px] leading-[14px] text-center text-white'>Our team reviews your products to ensure quality standards.</p>
                                </div>
                            </div>
                            <div className='relative w-max'>
                                <img src={vendorArrow5} alt="" />
                                <div className={`${classes.accessText}`}>
                                    <h4 className='text-base font-semibold leading-5 mb-2 text-center'>Onboarding</h4>
                                    <p className='text-[12px] leading-[14px] text-center'>Once verified, you will be officially onboarded and can start receiving orders from Steelbazaar.</p>
                                </div>
                            </div>
                        </div>
                        :
                        <div className={`${classes.container}`}>
                            <div className='flex pt-20'>
                                <div className='flex flex-col items-center'>
                                    <div className={`${classes.platformImg}`}>
                                        <img src={platform1} alt="" />
                                        <div className={`${classes.platformText} text-2xl font-bold w-max`}>
                                            Register on <br />Vendor Central
                                        </div>
                                    </div>
                                    <div className={classes.borderDiv}></div>
                                    <p className={`${classes.platformSecText} font-medium text-center`}>Create an account and submit the <br /> necessary business documents.</p>
                                </div>
                                <div className={`${classes.secondaryPlatform} flex flex-col items-center`}>
                                    <p className={`${classes.platformSecText} font-medium text-center`}>Upload relevant business <br /> certifications and bank details for <br /> verification</p>
                                    <div className={classes.borderDiv}></div>
                                    <div className={`${classes.platformImg}`}>
                                        <img src={platform2} alt="" />
                                        <div className={`${classes.platformText} text-2xl font-bold w-max`}>
                                            Business <br />Verification
                                        </div>
                                    </div>
                                </div>
                                <div className='flex flex-col items-center'>
                                    <div className={`${classes.platformImg}`}>
                                        <img src={platform3} alt="" />
                                        <div className={`${classes.platformText} text-2xl font-bold w-max`}>
                                            Inventory <br />Submission
                                        </div>
                                    </div>
                                    <div className={classes.borderDiv}></div>
                                    <p className={`${classes.platformSecText} font-medium  text-center`}>Upload your product catalogue to <br /> Steelbazaar, including <br /> specifications, prices & stock levels.</p>
                                </div>
                                <div className={`${classes.secondaryPlatform} flex flex-col items-center`}>
                                    <p className={`${classes.platformSecText} font-medium text-center`}>Our team reviews <br /> your products to <br /> ensure quality standards.</p>
                                    <div className={classes.borderDiv}></div>
                                    <div className={`${classes.platformImg}`}>
                                        <img src={platform4} alt="" />
                                        <div className={`${classes.platformText} text-2xl font-bold w-max`}>
                                            Vendor
                                            <br />Approval
                                        </div>
                                    </div>
                                </div>


                                <div className='flex flex-col items-center'>
                                    <div className={`${classes.platformImg}`}>
                                        <img src={platform5} alt="" />
                                        <div className={`${classes.platformText} text-2xl font-bold w-max`}>
                                            Onboarding
                                        </div>
                                    </div>
                                    <div className={classes.borderDiv}></div>
                                    <p className={`${classes.platformSecText} font-medium  text-center`}>Once verified, you will be officially <br /> onboarded and can start receiving <br /> orders from Steelbazaar.</p>
                                </div>
                            </div>
                        </div>
                    }
                </div>
                <div>
                    <div>
                        <div className="flex lg:columns-2 columns-1 lg:flex-row flex-col items-center ZigZagSection">
                            <div className="2xl:w-4/12 lg:w-5/12 w-full flex order-1 lg:order-2 justify-center">
                                <div className="ZigZagImgBox">
                                    <div className="ImgItemLeft">
                                        <img src={DotImg} alt="" />
                                    </div>
                                    <img src={showcaseProducts} className="w-full" alt="" />
                                </div>
                            </div>
                            <div className="2xl:w-8/12 lg:w-7/12 w-full ZigZagText order-2 lg:order-1">
                                <div className="grayText">Step 02:</div>
                                <div className="heading48">
                                    Showcase Your <span>Products</span>
                                </div>
                                <div className="PointBox">
                                    <ul>
                                        <li>
                                            <img src={BulletImg} className="bulletBrd" alt="" />
                                            <span>
                                                <b>Digital Product Catalogs</b>
                                                Submit your product listings and wholesale prices through the Vendor Central portal.
                                            </span>
                                        </li>
                                        <li>
                                            <img src={BulletImg} className="bulletBrd" alt="" />
                                            <span>
                                                <b>Customisable Storefront</b>
                                                Build a branded storefront for product listings
                                            </span>
                                        </li>
                                        <li>
                                            <img src={BulletImg} className="bulletBrd" alt="" />
                                            <span>
                                                <b>Advanced Analytics</b>
                                                Access real-time data to monitor performance and inform strategic decisions.
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {isSmallScreen ? <OrderRetailingTemplate /> :
                <div className='pb-[110px] container ZigZagText mx-auto my-0 '>
                    <div className="grayText">Step 03:</div>
                    <div className="heading48">
                        Purchase Orders to <span>Retailing</span>
                    </div>
                    <div className="Subhead !pt-0">
                        <p>Streamlined Process from Orders to Customer Delivery</p>
                    </div>
                    <div className={`${classes.container} flex flex-col items-center pt-[60px]`}>
                        <div className={`${classes.vendorfeatures} flex items-center `}>
                            <img src={vendorSeller} alt="" />
                            <div className={`${classes.vendorCount} font-medium flex items-center justify-center`}>01</div>
                            <div className={`${classes.venderFeatureText} w-[550px] pl-5`}>
                                <span className='text-2xl font-medium'>Start Selling</span>
                                <p className='text-lg mt-[10px]'>Once approved, you will receive purchase orders from <br /> Steelbazaar based on demand.</p>
                            </div>
                            <div
                                className={`${classes.mehDiagonal}`}
                            ></div>
                        </div>
                        <div className={`${classes.vendorfeatures2} flex items-center`}>
                            <div className={`${classes.venderFeatureText} w-[550px] pr-5 text-right`}>
                                <span className='text-2xl font-medium'>Purchase Orders</span>
                                <p className='text-lg mt-[10px]'>SteelBazaar sends you purchase orders based on demand <br /> forecasts.</p>
                            </div>
                            <div className={`${classes.vendorCount} font-medium flex items-center justify-center`}>02</div>
                            <img src={vendorOrders} alt="" className='ml-5' />
                            <div
                                className={`${classes.mehDiagonal2}`}
                            ></div>
                        </div>
                        <div className={`${classes.vendorfeatures} flex items-center `}>
                            <img src={vendorWearhouse} alt="" className='pr-5' />
                            <div className={`${classes.vendorCount} font-medium flex items-center justify-center`}>03</div>
                            <div className={`${classes.venderFeatureText} w-[550px] pl-5`}>
                                <span className='text-2xl font-medium'>Fulfilment</span>
                                <p className='text-lg mt-[10px]'>Ship the ordered products to SteelBazaar's fulfilment centres.</p>
                            </div>
                            <div
                                className={`${classes.mehDiagonal}`}
                            ></div>
                        </div>
                        <div className={`${classes.vendorfeatures2} flex items-center`}>
                            <div className={`${classes.venderFeatureText} w-[550px] pr-5 text-right`}>
                                <span className='text-2xl font-medium'>Quality Assurance</span>
                                <p className='text-lg mt-[10px]'>Steelbazaar inspects and verifies the quality of received<br />products.</p>
                            </div>
                            <div className={`${classes.vendorCount} font-medium flex items-center justify-center`}>04</div>
                            <img src={vendorQuality} alt="" />
                            <div
                                className={`${classes.mehDiagonal2}`}
                            ></div>
                        </div>
                        <div className={`${classes.vendorfeatures} flex items-center `}>
                            <img src={vendorPayment} alt="" />
                            <div className={`${classes.vendorCount} font-medium flex items-center justify-center`}>05</div>
                            <div className={`${classes.venderFeatureText} w-[550px] pl-5`}>
                                <span className='text-2xl font-medium'>Payment</span>
                                <p className='text-lg mt-[10px]'>Upon successful delivery and quality check, payments are<br />released to your account as per the agreed terms..</p>
                            </div>
                            <div
                                className={`${classes.mehDiagonal}`}
                            ></div>
                        </div>
                        <div className={`${classes.vendorfeatures2} flex items-center`}>
                            <div className={`${classes.venderFeatureText} w-[550px] pr-5 text-right`}>
                                <span className='text-2xl font-medium'>Retailing</span>
                                <p className='text-lg mt-[10px]'>SteelBazaar manages the retailing process, including customer<br />service and returns.</p>
                            </div>
                            <div className={`${classes.vendorCount} font-medium flex items-center justify-center`}>06</div>
                            <img src={vendorRetailing} alt="" />
                            <div
                                className={`${classes.mehDiagonal2}`}
                            ></div>
                        </div>
                        <div className={`${classes.vendorfeatures} flex items-center `}>
                            <img src={vendorRelationship} alt="" />
                            <div className={`${classes.vendorCount} font-medium flex items-center justify-center`}>07</div>
                            <div className={`${classes.venderFeatureText} w-[550px] pl-5`}>
                                <span className='text-2xl font-medium'>Long-Term Relationship</span>
                                <p className='text-lg mt-[10px]'>Continue to receive regular orders from Steelbazaar based on<br />performance, product demand, and supply consistency</p>
                            </div>
                        </div>
                    </div>
                </div>
            }

            <div className='neutral20Bg'>
                <div className='container mx-auto lg:px-4 px-2 lg:py-24 py-8'>
                    <div className={`headingContainer`}>
                        <div className="heading">Unlock Opportunities with SB Vendor Central</div>
                    </div>
                    <div className="mx-auto w-full flex flex-wrap gap-4 ">
                        {cardData.map((card, index) => (
                            <div key={index} className='whiteGrayBox colWidth-3'>
                                <div className='iconBox'>
                                    <img src={card.img} alt={card.title} />
                                </div>
                                <div className="TextBox">
                                    <b className='!font-semibold'>{card.title}</b>
                                    {card.description}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <div className={`${classes.vendorCTA} lg:py-14 py-8 w-full `}>
                <div className="container mx-auto CallToAction lg:px-4 px-3 ">
                    <div className="2xl:w-2/5 lg:w-3/5 flex flex-col gap-y-2.5 items-start">
                        <h2>Start Selling with Steelbazaar Today</h2>
                        <p className="mb-5">
                            Ready to expand your business with Steelbazaar? Join Vendor Central today and take the first step toward a successful partnership. Click below to register and start selling to Steelbazaar now
                        </p>
                        <Button className="Btn whiteBg Txtprimary500">
                            Register Now <ArrowForwardIcon className="primary500Bg text-white" />
                        </Button>
                    </div>
                </div></div>
            <RequestDemoTemplate />
        </>
    );
};

export default VendorCentralTemplate;
