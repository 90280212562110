import React, { useState } from 'react';
import { ArrowDropUp, ArrowDropDown } from '@mui/icons-material';
import Button from '../../atoms/Button/Button';
import { createUseStyles } from 'react-jss';
import { DELIVERY_INFORMATION } from '../../../../src/utils/constant';
import { toInr } from '../../../utils/helper';

export interface WarehouseCatalogue {
    vendorId: number;
    companyName: string;
    warehouseId: number;
    warehouseName: string;
    offerSalePrice: number;
    minimumOrderQuantity: number;
    deliveryTerm: string;
    chargeType: string;
    rate: number;
    rateType: string;
    isVerifiedTrusted: boolean;
    packagingType: string;
}

interface CatalogueVendorsTemplateProps {
    catalogueVendors: WarehouseCatalogue[];
    handleVendorCatalogue: (warehouse: WarehouseCatalogue) => void;
}

const useStyles = createUseStyles((theme: any) => ({
    catalogueVendors: {
        border: `1px solid ${theme.palette.border.primary.primary200}`,
        boxShadow: "0px 0px 16px 0px rgba(0, 0, 0, 0.09)"
    },
    catalogueVendorHeading: {
        color: theme.palette.text.neutral.neutral700,
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "20px",
        "& span": {
            color: "white",
            fontSize: "12px",
            fontWeight: 600,
            borderRadius: "100px",
            background: theme.palette.text.primary.primary500,
        }
    },
    card: {
        border: `1px solid ${theme.palette.border.primary.primary100}`,
        background: "linear-gradient(93deg, #F7F8FD 0.32%, #FFF 100%)"
    },
    arrow: {
        display: "flex"
    },
    companyName: {
        color: theme.palette.text.primary.primary500,
    },
    sellerPrice: {
        color: theme.palette.text.neutral.neutral700,
    },
    deliveryTerm: {
        color: theme.palette.text.neutral.neutral900,
    },
    hiddenScroll: {
        maxHeight: '452px',
        overflow: 'auto',
        scrollbarWidth: 'none',
        '-ms-overflow-style': 'none',
        '&::-webkit-scrollbar': {
            display: 'none'
        }
    }
}));

const CatalogueVendorsTemplate: React.FC<CatalogueVendorsTemplateProps> = ({ catalogueVendors, handleVendorCatalogue }) => {
    const classes = useStyles();

    const [showAll, setShowAll] = useState<boolean>(false);

    const handleClick = () => () => {
        setShowAll(!showAll);
    };

    const getDeliveryLabel = (value: string) => {
        const info = DELIVERY_INFORMATION.find(item => item.value === value);
        return info ? info.label : value;
    };

    if (catalogueVendors.length <= 1) return null;

    return (
        <>
            {catalogueVendors.length > 0 && (
                <div className={`${classes.catalogueVendors} mt-4 p-3 rounded-xl`}>
                    <div className="flex justify-between">
                        <div className={`${classes.catalogueVendorHeading} my-auto`}>
                            Other Seller Info <span className="ml-3 p-1">+{catalogueVendors.length}</span>
                        </div>
                        <div className="cursor-pointer" onClick={handleClick()}>
                            {showAll ? <ArrowDropUp /> : <ArrowDropDown />}
                        </div>
                    </div>

                    {showAll && (
                        <div className={`${classes.hiddenScroll} mt-3 grid gap-y-3`}>
                            {catalogueVendors.map((warehouse) => (
                                <div className={`${classes.card} px-[14px] py-5 rounded-xl grid gap-y-2`} key={`${warehouse.vendorId}-${warehouse.warehouseId}`}>
                                    <div className="flex justify-between">
                                        <div className={`${classes.companyName} text-sm font-semibold leading-4`}>{warehouse.companyName}</div>
                                        <div className={`${classes.sellerPrice} text-sm font-medium leading-4`}>{toInr(warehouse.offerSalePrice)} /MT</div>
                                    </div>
                                    <div className="flex justify-between">
                                        <div className="grid gap-y-0.5">
                                            <div className={`${classes.sellerPrice} text-xs leading-[14px]`}>Delivery Terms:</div>
                                            <div className={`${classes.deliveryTerm} text-sm font-medium leading-4`}>{getDeliveryLabel(warehouse.deliveryTerm)}</div>
                                        </div>
                                        <div className="flex justify-center self-center">
                                            <Button variant="secondaryContained" label="Get Best Quote" size="medium" onClick={() => handleVendorCatalogue(warehouse)} />
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            )}
        </>
    );
};

export default CatalogueVendorsTemplate;
