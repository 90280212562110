import React, { useEffect, useRef } from 'react';
import { createUseStyles } from 'react-jss';
import careerBanner from '../../../assets/images/careerBanner.png';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Button } from '@mui/joy';
import careerSecondaryBanner from '../../../assets/images/careerSecondaryBanner.png';
import companyGroup1 from '../../../assets/images/companyGroup1.png';
import companyGroup2 from '../../../assets/images/companyGroup2.png';
import CarouselRef from 'react-multi-carousel';
import Carousel from 'react-multi-carousel';
import globaIcon1 from '../../../assets/images/globaIcon1.svg';
import techIcon from '../../../assets/images/techIcon.svg';
import expansiveIcon from '../../../assets/images/expansiveIcon.svg';
import developmentIcon from '../../../assets/images/developnmentIcon.svg';
import networkIcon from '../../../assets/images/networkIcon.svg';
import oppIcon from '../../../assets/images/oppIcon.svg';
import { useLocation } from 'react-router-dom';

const useStyles = createUseStyles((theme: any) => ({
    bannerContainer: {
        backgroundImage: `url(${careerBanner})`,
        backgroundSize: 'cover',
    },
    oppCard: {
        border: `1px solid ${theme.palette.border.neutral.neutral200}`,
        background: '#F8F8F8',
    },
    oppText: {
        '& span': {
            color: theme.palette.text.neutral.neutral800,
            lineHeight: '22px',
        },
        '& p': {
            color: '#000',
            lineHeight: '22px',
        },
    },
    featuresCard1: {
        border: `1px solid ${theme.palette.border.warning.warning400}`,
        background: '#FDF9E8',
    },
    featuresCard2: {
        border: `1px solid ${theme.palette.border.primary.primary400}`,
        background: '#EAEEFA',
    },
    featuresCard3: {
        border: `1px solid ${theme.palette.border.success.success400}`,
        background: '#EFF7EE',
    },
    featuresCard4: {
        border: `1px solid ${theme.palette.border.complementary.complementary400}`,
        background: '#E7F6FE',
    },
    featuresCard5: {
        border: '1px solid #FEC09A',
        background: '#FFEFE6',
    },
    featuresCard6: {
        border: `1px solid ${theme.palette.border.secondary.secondary200}`,
        background: '#FCE9EA',
    },
    features: {
        "& h4": {
            color: '#000',
            fontSize: '22px',
        },
        "& p": {

        },
    },
    main: {
        width: '100% !important',
    },
    carouselContainer: {
        "& .react-multiple-carousel__arrow": {
            display: "none",
        },
        "& .react-multi-carousel-list": {
            width: "1300px",
        },
        "& .react-multi-carousel-list ul": {
            display: "flex",
            alignItems: "center",
            columnGap: "44px"
        },
        "& .react-multi-carousel-list li": {
            width: "fit-content !important",
        },
    },
    "@media (max-width: 767px)": {
        container: {
            margin: "0px auto",
            rowGap: "15px",
            width: '100%',
        },
        heading: {
            fontSize: "28px",
            color: theme.palette.text.neutral.neutral900,
            lineHeight: '34px',

        },
        carouselContainer: {
            "& .react-multiple-carousel__arrow": {
                display: "none",
            },
            "& .react-multi-carousel-list": {
                width: "100% !important",

            },
            "& .react-multi-carousel-list ul": {
                display: "flex",
                alignItems: "center",
                columnGap: "26px"
            },
            "& .react-multi-carousel-list li": {
                width: "fit-content !important",
            },
        },
    },
    "@media (max-width: 480px)": {
        oppCard: {
            "& h4": {
                fontSize: '18px',
                marginBottom: '0px',
            },
        },
        oppText: {
            '& span': {
                fontSize: '14px',
            },
            '& p': {
                fontSize: '14px',
            },
        },
        features: {
            "& h4": {
                fontSize: '18px',
                marginBottom: '4px',
            },
            "& p": {
                fontSize: '14px',
            },
        },
    },
}));

const cardData = [
    {
        img: globaIcon1,
        title: "Global Industry Exposure",
        description:
            "Engage with international markets and broaden your professional horizons.",
    },
    {
        img: techIcon,
        title: "Technological Innovation",
        description:
            "Be at the forefront of adopting & implementing cutting-edge technologies in steel.",
    },
    {
        img: expansiveIcon,
        title: "Expansive Business Opportunities",
        description:
            "Contribute to significant projects that drive industry growth.",
    },
    {
        img: developmentIcon,
        title: "Professional Development",
        description:
            "Access continuous learning and growth opportunities to enhance your career.",
    },
    {
        img: networkIcon,
        title: "Collaborative Work Environment",
        description:
            "Thrive in a culture that values teamwork, creativity, and initiative.",
    },
    {
        img: oppIcon,
        title: "Networking Opportunities",
        description:
            "Collaborate with industry leaders, innovators, & experts from around the globe.",
    },
];


const CareerTemplate: React.FC = () => {
    const classes = useStyles();
    const carouselRef = useRef<CarouselRef>(null);
    const location = useLocation();

    useEffect(() => {
        if (carouselRef.current) {
            carouselRef.current.goToSlide(0, true);
        }
    }, []);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const component = params.get('component');

        if (component) {
            const element = document.getElementById(component);
            if (element) {
                element.scrollIntoView();
            }
        }
    }, [location.search]);

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1280 },
            items: 6,
            partialVisibilityGutter: 15
        },
        tablet: {
            breakpoint: { max: 1280, min: 980 },
            items: 3,
            partialVisibilityGutter: 10
        },
        mobile: {
            breakpoint: { max: 480, min: 320 },
            items: 2,
            partialVisibilityGutter: 0
        },
        "sm-mobile": {
            breakpoint: { max: 464, min: 0 },
            items: 2,
            partialVisibilityGutter: 10
        },
    };

    return (
        <>
            <div className={`${classes.bannerContainer}`}>
                <div className="container mx-auto px-4">
                    <div className="insideBannerBx">
                        <div className="insideBannerInfo">
                            <h1>
                                <span>Redefining Careers</span><br />in The Steel Industry
                            </h1>
                            <p>
                                At SteelBazaar, we're shaping the future of the <br />
                                steel industry.  We invite talented professionals <br />
                                to join our dynamic team and embark on a journey <br />
                                of innovation, growth, and excellence.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className='lg:py-[90px] py-8 neutral20Bg'>
                <div className='container mx-auto'>
                    <div className={`headingContainer`}>
                        <div className="heading">Why Choose<span> SteelBazaar?</span></div>
                    </div>
                    <div className="container mx-auto lg:px-4 px-3 w-full flex flex-wrap gap-4 ">
                        {cardData.map((card, index) => (
                            <div key={index} className='whiteGrayBox colWidth-3'>
                                <div className='iconBox'>
                                    <img src={card.img} alt={card.title} />
                                </div>
                                <div className="TextBox">
                                    <b>{card.title}</b>
                                    {card.description}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className='lg:py-[90px] py-8 px-4 container mx-auto' id='job-opening'>
                <div className={`headingContainer`}>
                    <div className="heading text-left">Current <span>Opportunities </span></div>
                    <div className="Subheading text-left">We are seeking dedicated professionals for the following roles</div>
                </div>

                <div className={`${classes.oppCard} grayWhiteBox lg:px-4 mb-4`}>
                    <h4 className='font-semibold text-[28px] leading-[38px] mb-1'>Research Associate</h4>
                    <div className='flex justify-between items-center lg:flex-row flex-col'>
                        <div className={`${classes.oppText} w-full`}>
                            <div className='flex  lg:flex-row flex-wrap'>
                                <div className='flex lg:flex-row flex-col'> <div className='flex'><span>Location: </span> <p className='font-semibold mr-4 ml-1'> Raipur</p></div>
                                    <div className='flex'>
                                        <span>Qualifications: </span> <p className='font-semibold mr-4 ml-1'> MBA/PGDM in Operations, Marketing, or Finance</p>
                                    </div>
                                    <div className='flex'>
                                        <span>Experience: </span> <p className='font-semibold mr-4 ml-1'> 1-3 years</p>
                                    </div>
                                </div>
                                <div className='flex '><span>Skills:  </span> <p className='font-semibold mr-4 ml-1'> Strong analytical abilities, excellent communication, and proficiency in data analysis.</p>
                                </div>
                            </div>
                        </div>
                        <div className='flex lg:items-end lg:mt-0 mt-3 items-start w-full lg:w-max'>
                            <Button className="Btn sm primary500Bg w-max">Apply Now <ArrowForwardIcon /></Button>
                        </div>
                    </div>
                </div>
                <div className={`${classes.oppCard} p-4 mb-4`}>
                    <h4 className="font-semibold text-[28px] leading-[38px] mb-1">Sales Director</h4>
                    <div className="flex justify-between items-center lg:flex-row flex-col">
                        <div className={`${classes.oppText} w-full`}>
                            <div className="flex lg:flex-row flex-wrap">
                                <div className="flex lg:flex-row flex-col">
                                    <div className="flex">
                                        <span>Location: </span>
                                        <p className="font-semibold mr-4 ml-1">Delhi</p>
                                    </div>
                                    <div className="flex">
                                        <span>Qualifications: </span>
                                        <p className="font-semibold mr-4 ml-1">MBA with a focus on Sales/Marketing</p>
                                    </div>
                                    <div className="flex">
                                        <span>Experience: </span>
                                        <p className="font-semibold mr-4 ml-1">18+ years in the steel industry</p>
                                    </div>
                                </div>
                                <div className="flex">
                                    <span>Skills: </span>
                                    <p className="font-semibold mr-4 ml-1">
                                        Proven leadership, strategic planning, and extensive industry knowledge.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="flex lg:items-end lg:mt-0 mt-3 items-start w-full lg:w-max">
                            <Button className="Btn sm primary500Bg w-max">Apply Now <ArrowForwardIcon /></Button>
                        </div>
                    </div>
                </div>

                <div className={`${classes.oppCard} p-4 mb-4`}>
                    <h4 className="font-semibold text-[28px] leading-[38px] mb-1">Procurement Lead</h4>
                    <div className="flex justify-between items-center lg:flex-row flex-col">
                        <div className={`${classes.oppText} w-full`}>
                            <div className="flex lg:flex-row flex-wrap">
                                <div className="flex lg:flex-row flex-col">
                                    <div className="flex">
                                        <span>Location: </span>
                                        <p className="font-semibold mr-4 ml-1">Gurgaon</p>
                                    </div>
                                    <div className="flex">
                                        <span>Qualifications: </span>
                                        <p className="font-semibold mr-4 ml-1">Degree in Supply Chain Management or related field</p>
                                    </div>
                                    <div className="flex">
                                        <span>Experience: </span>
                                        <p className="font-semibold mr-4 ml-1">18+ years in the steel industry</p>
                                    </div>
                                </div>
                                <div className="flex">
                                    <span>Skills: </span>
                                    <p className="font-semibold mr-4 ml-1">
                                        Proven leadership, strategic planning, and extensive industry knowledge.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="flex lg:items-end lg:mt-0 mt-3 items-start w-full lg:w-max">
                            <Button className="Btn sm primary500Bg w-max">Apply Now <ArrowForwardIcon /></Button>
                        </div>
                    </div>
                </div>
            </div>
            <div className='neutral20Bg lg:pt-[90px] pt-8'>
                <div className='container mx-auto ' id='life-at-steelbazaar'>
                    <div>
                        <div className={`headingContainer lg:px-0 px-2`}>
                            <div className="heading">Life at <span>SteelBazaar</span></div>
                            <div className="Subheading">
                                Experience a workplace where innovation meets collaboration. At SteelBazaar, we prioritise
                            </div>
                        </div>
                        <div className=' container flex flex-wrap gap-4 lg:px-0 px-3'>
                            <div className={`${classes.features} ${classes.featuresCard1} py-5 px-[15px] rounded-lg colWidth-3`}>
                                <h4 className='font-medium leading-[26px] mb-3'>Work-Life Balance</h4>
                                <p className='text-base leading-5'>Flexible schedules to support your personal and professional life.</p>
                            </div>
                            <div className={`${classes.features} ${classes.featuresCard2} py-5 px-[15px] rounded-lg colWidth-3`}>
                                <h4 className='font-medium leading-[26px] mb-3'>Diversity and Inclusion</h4>
                                <p className='text-base leading-5'>A culture that celebrates diverse perspectives and backgrounds.</p>
                            </div>
                            <div className={`${classes.features} ${classes.featuresCard3} py-5 px-[15px] rounded-lg colWidth-3`}>
                                <h4 className='font-medium leading-[26px] mb-3'>Career Growth</h4>
                                <p className='text-base leading-5'>Opportunities for advancement and professional development.</p>
                            </div>
                            <div className={`${classes.features} ${classes.featuresCard4} py-5 px-[15px] rounded-lg colWidth-3`}>
                                <h4 className='font-medium leading-[26px] mb-3'>Rewarding Performance</h4>
                                <p className='text-base leading-5'>Attractive incentives and recognition programs to celebrate your achievements.</p>
                            </div>
                            <div className={`${classes.features} ${classes.featuresCard5} py-5 px-[15px] rounded-lg colWidth-3`}>
                                <h4 className='font-medium leading-[26px] mb-3'>Team Building and Fun</h4>
                                <p className='text-base leading-5'>Participate in team outings, celebrations, and engagement activities to foster camaraderie.</p>
                            </div>
                            <div className={`${classes.features} ${classes.featuresCard6} py-5 px-[15px] rounded-lg colWidth-3`}>
                                <h4 className='font-medium leading-[26px] mb-3'>State-of-the-Art Facilities</h4>
                                <p className='text-base leading-5'>Work in modern offices with the latest technologies to support creativity and productivity.</p>
                            </div>
                        </div>
                    </div>
                    <img src={careerSecondaryBanner} alt="" className='w-full px-5 mt-10' />
                </div>
                <div className={`${classes.carouselContainer} overflow-hidden`}>
                    <Carousel
                        className={`${classes.main}`}
                        responsive={responsive}
                        showDots={false}
                        infinite={true}
                        partialVisible={true}
                        ref={carouselRef}
                        autoPlay={true}
                        autoPlaySpeed={1000}
                        removeArrowOnDeviceType={["sm-mobile"]}
                    >
                        <div className=""><img src={companyGroup1} alt="" /></div>
                        <div className="w-max"><img src={companyGroup2} alt="" /></div>
                        <div className="w-max"><img src={companyGroup1} alt="" /></div>
                        <div className="w-max"><img src={companyGroup2} alt="" /></div>
                        <div className="w-max"><img src={companyGroup1} alt="" /></div>
                        <div className="w-max"><img src={companyGroup2} alt="" /></div>
                        <div className="w-max"><img src={companyGroup1} alt="" /></div>
                        <div className="w-max"><img src={companyGroup2} alt="" /></div>
                    </Carousel>
                </div>
            </div>
        </>
    );
};

export default CareerTemplate;
