import React from 'react';
import { createUseStyles } from 'react-jss';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import digiStockBanner from '../../../assets/images/digiStockBanner.png';
import digiSku from '../../../assets/images/digiSku.png';
import RequestDemoTemplate from '../../template/Home/RequestDemo/RequestDemo.template';
import primaryBlueTick from '../../../assets/icons/primaryBlueTick.jpg';
import roudTickIcon from '../../../assets/icons/roudTickIcon.jpg';
import register from '../../../assets/images/register.jpg';
import verifiedTick2 from '../../../assets/icons/verifiedTick2.svg';
import DigitalSecondaryBanner from '../../../assets/images/digitalSecondaryBanner.png';
import { Button } from '@mui/joy';
import roudTickIcon1 from '../../../assets/icons/roudTickIcon1.svg';
import roudTickIcon2 from '../../../assets/icons/roudTickIcon2.svg';
import logistics from '../../../assets/icons/logistics.svg';
import cardrupee from '../../../assets/images/cardrupee.png';
import creatingSku from '../../../assets/images/creatingSku.png';
import buildInventory from '../../../assets/images/buildInventory.png';
import infoUsn from '../../../assets/images/infoUsn.png';
import visualImages from '../../../assets/images/visualImages.png';
import rupee from '../../../assets/icons/currency_rupee.jpg';
import verified from '../../../assets/icons/verifiedTick2.svg';
import catalogIcon2 from '../../../assets/icons/catalogIcon2.svg';
import catalogIcon4 from '../../../assets/icons/catalogIcon4.svg';
import catalogIcon6 from '../../../assets/icons/catalogIcon6.svg';
import timeIcon from '../../../assets/icons/timeIcon.jpg';
//bykamal
import digiStockMobileBanner from '../../../assets/images/digiStockMobileBanner.jpg';
import DotImg from "../../../assets/images/dot-shape.png";
import BulletImg from "../../../assets/images/bulletIcon.svg";
import Section1 from '../../../assets/images/DigiStock-Selecting-Creating-SKUs.jpg';
import Section2 from '../../../assets/images/DigiStock-Building-Inventory-Selected-SKUs.jpg';
import Section3 from '../../../assets/images/DigiStock-Entering-Detailed-Information-Each-USN.jpg';
import Section4 from '../../../assets/images/DigiStock-Add-Visual-Images.jpg';
import Section5 from '../../../assets/images/DigiStock-Viewing-and-Managing-Inventory.jpg';
const useStyles = createUseStyles((theme: any) => ({
    container: {
      
    },
    bannerContainer: { 
        backgroundImage: `url(${digiStockBanner})`,
        backgroundSize: 'cover',
    },
    bannerSellContainer: {
    },
    heading: {
        fontSize: '56px',
        lineHeight: '66px',
        color: '#fff',
        "& span": {
            color: theme.palette.text.warning.warning400,
        },
    },
    bannerDescription: {
        color: theme.palette.text.primary.primary50,
        lineHeight: '28px',
        "& span": {
            color: theme.palette.text.warning.warning400,
        },
    },
    intoContainer: {
        backgroundColor: theme.palette.background.neutral.neutral50,
    },
    primaryText: {
        fontSize: '54px',
        color: '#000',
        lineHeight: '66px',
    },
    decContainer: {
        width: '750px',
        "& p": {
            color: '#000',
            lineHeight: '26px',
            fontWeight: '400',
        },
    },
    sectionHeading: {
        fontSize: '54px !important',
        lineHeight: '66px',
        color: '#000',
        "& span": {
            color: theme.palette.text.primary.primary500,
        },
    },
    inventoryContainer: {
        "& h4": {
            color: theme.palette.text.neutral.neutral800,
        },
    },
    inventoryCard1: {
        border: `1px solid ${theme.palette.border.complementary.complementary400}`,
        background: theme.palette.background.complementary.complementary50,
        color: theme.palette.text.complementary.complementary700,
        lineHeight:'20px',
        padding:'40px 20px',
        borderRadius:'8px',
        "& h6": {
            paddingBottom:'8px',
            fontSize:'22px',
            color: theme.palette.text.complementary.complementary600,
        },
    },
    inventoryCard2: {
        border: `1px solid ${theme.palette.border.warning.warning400}`,
        background: theme.palette.background.warning.warning50,
        color: theme.palette.text.warning.warning700,
        lineHeight:'20px',
        padding:'40px 20px',
        borderRadius:'8px',
        "& h6": {
            paddingBottom:'8px',
            fontSize:'22px',
            color: theme.palette.text.warning.warning600,
        },
    },
    inventoryCard3: {
        border: `1px solid ${theme.palette.border.orange.orange400}`,
        background: theme.palette.background.orange.orange50,
        color: theme.palette.text.orange.orange700,
        lineHeight:'20px',
        padding:'40px 20px',
        borderRadius:'8px',
        "& h6": {
            paddingBottom:'8px',
            fontSize:'22px',
            color: theme.palette.text.orange.orange600,
        },
    },
    inventoryCard4: {
        border: `1px solid ${theme.palette.border.secondary.secondary400}`,
        background: theme.palette.background.secondary.secondary50,
        color: theme.palette.text.secondary.secondary700,
        lineHeight:'20px',
        padding:'40px 20px',
        borderRadius:'8px',
        "& h6": {
            paddingBottom:'8px',
            fontSize:'22px',
            color: theme.palette.text.secondary.secondary600,
        },
    },
    designContainer: {
        "& > h2": {
            color: theme.palette.text.neutral.neutral800,
        },
        "& > h3": {
            color: theme.palette.text.neutral.neutral600,
        },
    },
    designCard: {
        border: `1px solid ${theme.palette.border.neutral.neutral200}`,
        background: '#fff',
        width: '350px',
    },
    sellerContainer: {
        background: theme.palette.background.primary.primary50,
        position: "relative",
    },
    buyerContainer: {
        background: theme.palette.background.primary.primary500,
    },
    sellerHeading: {
        fontSize: '40px',
        lineHeight: '48px',
        color: theme.palette.text.neutral.neutral800,
        borderBottom: `1px solid ${theme.palette.border.primary.primary500}`,
        "& span": {
            fontSize: '40px',
            lineHeight: '48px',
            color: theme.palette.text.primary.primary500,
        },
    },
    benifitContent: {
        "& h5": {
            fontSize: '28px',
            lineHeight: '34px',
            color: '#000',
        },
        "& p": {
            color: theme.palette.text.neutral.neutral800,
            fontSize: '22px',
            lineHeight: '26px',
        },
    },
    buyerHeading: {
        color: '#fff',
        fontSize: '40px',
        lineHeight: '48px',
        borderBottom: '1px solid #fff',
        "& span": {
            fontSize: '40px',
            lineHeight: '48px',
            color: theme.palette.text.warning.warning500,
        },
    },
    benifitContentBuyer: {
        "& h5": {
            fontSize: '28px',
            lineHeight: '34px',
            color: '#fff',
        },
        "& p": {
            color: theme.palette.text.neutral.neutral50,
            fontSize: '22px',
            lineHeight: '26px',
        },
    },
    benifitExtra: {
        position: 'absolute',
        top: '154px',
        right: '-25px',
    },
    extra2: {
        background: theme.palette.background.primary.primary200,
        width: '24px',
        height: '24px',
    },
    extra1: {
        background: theme.palette.background.primary.primary500,
        width: '24px',
        height: '24px',
    },
    tradeHeader: {
        background: theme.palette.background.success.success400,
        fontSize: '32px',
        color: '#fff',
        textAlign: 'right',
        width: '580px',
    },
    traderContent: {
        background: theme.palette.background.success.success50,
        borderRight: `2px solid ${theme.palette.border.success.success400}`,
        borderLeft: `2px solid ${theme.palette.border.success.success400}`,
        color: '#000',
        lineHeight: '26px',
        fontSize: '22px',
        textAlign: 'right',
        width: '580px',

    },
    aspectHeader: {
        background: '#000',
        fontSize: '28px',
        lineHeight: '38px',
        color: '#fff',
        width: '120px',
        paddingTop: '20px',
    },
    aspectContent: {
        width: '120px',
        fontSize: '18px',
        fontWeight: '600',
        lineHeight: '22px',
        borderTop: '1px solid #000',
        borderBottom: '1px solid #000',
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inventoryContent: {
        background: theme.palette.background.complementary.complementary50,
        borderRight: `2px solid ${theme.palette.border.complementary.complementary400}`,
        borderLeft: `2px solid ${theme.palette.border.complementary.complementary400}`,
        color: '#000',
        lineHeight: '26px',
        fontSize: '22px',
        textAlign: 'left',
        width: '580px',
    },
    inventoryHeader: {
        background: theme.palette.background.complementary.complementary400,
        fontSize: '32px',
        color: '#fff',
        textAlign: 'left',
        width: '580px',
        paddingTop: '17px',
    },
    methods: {
        borderRadius: '32px',
        background: theme.palette.background.warning.warning400,
        color: '#000',
        fontSize: '40px',
        lineHeight: '42px',
        width: '64px',
        height: '64px',
        padding: '8px',
        margin: '0 auto',
    },
    featuresContainer: {
        "& > p": {
            color: theme.palette.text.neutral.neutral600,
        },
        "& span": {
            fontSize: '40px',
            lineHeight: '48px',
            color: theme.palette.text.primary.primary700,
        },
        "& h4": {
            color: theme.palette.text.neutral.neutral800,
            "& span": {
                color: theme.palette.text.primary.primary500,
                fontWeight: '600',
            },
        },
        "& h6": {
            color: theme.palette.text.neutral.neutral900,
            lineHeight: '28px'
        },
    },
    featureInfo: {
        "& h4": {
            color: theme.palette.text.neutral.neutral900,
        },
        "& p": {
            color: theme.palette.text.neutral.neutral800,
            lineHeight: '20px',
        },
    },
    featuresText: {
        width: '651px',
        "& h5": {
            color: '#000',
            fontSize: '22px',
            lineHeight: '26px',
        },
        "& p": {
            color: theme.palette.text.neutral.neutral800,
            lineHeight: '26px',
        },
    },
    headingText: {
        fontSize: '54px !important',
        "& span": {
            color: `${theme.palette.text.primary.primary500} !important`,
        },
    },
    subHeading: {
        color: theme.palette.text.neutral.neutral800,
        lineHeight: '28px',
    },

    digiCard: {
        width: '405px',
        borderRadius: '16px',
        border: `1px solid ${theme.palette.border.neutral.neutral200}`,
        background: '#F8F8F8',
        overflow: 'hidden',
    },
    digiCardHeader: {
        background: '#fff',
        borderBottom: `1px solid ${theme.palette.border.neutral.neutral200}`,
    },
    cardIcon: {
        borderRadius: '16px',
        border: `1px solid ${theme.palette.border.neutral.neutral200}`,
        background: '#fff !important',
 
    },
    cardText: {
        "& h6": {
            color: '#000',
            lineHeight: '26px',
        },
        "& p": {
            color: '#000',
            lineHeight: '20px',
        },
    },
    secondaryBanner: {
        width: '100%',
        height: '480px',
        backgroundImage: `url(${DigitalSecondaryBanner})`,
        backgroundSize: 'cover',
        position: 'relative',
    },
    secondaryContainer: {
        '& h2': {
            fontSize: '40px',
            color: theme.palette.text.warning.warning400,
            lineHeight: '48px',
        },
        "& p": {
            color: theme.palette.text.primary.primary50,
            lineHeight: '28px',
        },
    },
    btn3: {
        background: '#fff',
        color: '#fff',
        fontSize: '18px',
        fontWeight: '500',
        padding: '10px 20px',
        lineHeight: '22px',
    },
    btnArrow2: {
        border: '1px solid transparent',
        background: theme.palette.background.primary.primary500,
        borderRadius: '50%',
        height: '32px',
        width: '32px',
    },
    btnText: {
        color: theme.palette.text.primary.primary500,
        fontSize: '20px',
    },
    cardsContainer: {
        background: '#fff',
        "& p": {
            fontSize: '28px',
        },
    },
    card: {
        border: `1px solid ${theme.palette.border.neutral.neutral200}`,
        background: '#F8F8F8',
        padding: '20px 15px',
        width: '24%',
        "& h4": {
            color: '#000',
            fontSize: '22px',
            lineHeight: '26px',
        },
        "& p": {
            color: theme.palette.text.neutral.neutral800,
            fontSize: '16px',
            lineHeight: '20px',
        },
    },
    cardIconContainer: {
        border: `1px solid ${theme.palette.border.neutral.neutral200}`,
        background: '#Fff',
    },
    "@media screen and (max-width: 480px)": {
    bannerContainer: { 
        backgroundImage: `url(${digiStockMobileBanner})`,
        backgroundSize: 'cover',
    },
    methods: {fontSize:'30px',lineHeight: '42px',
        width: '48px',textAlign:'center', padding:'0px',
        height: '48px',},
    inventoryCard1: {
        border: `1px solid ${theme.palette.border.complementary.complementary400}`,
        background: theme.palette.background.complementary.complementary50,
        color: theme.palette.text.complementary.complementary700,
        fontSize:'12px',
        padding:'20px 12px',lineHeight:'normal',
        borderRadius:'8px',
        "& h6": {lineHeight:'22px',
            paddingBottom:'4px',
            fontSize:'18px',
            color: theme.palette.text.complementary.complementary600,
        },
    },
    inventoryCard2: {
        border: `1px solid ${theme.palette.border.warning.warning400}`,
        background: theme.palette.background.warning.warning50,
        color: theme.palette.text.warning.warning700,
        fontSize:'12px',
        padding:'20px 12px',lineHeight:'normal',
        borderRadius:'8px',
        "& h6": {lineHeight:'22px',
            paddingBottom:'4px',
            fontSize:'18px',
            color: theme.palette.text.warning.warning600,
        },
    },
    inventoryCard3: {
        border: `1px solid ${theme.palette.border.orange.orange400}`,
        background: theme.palette.background.orange.orange50,
        color: theme.palette.text.orange.orange700,
        fontSize:'12px',lineHeight:'normal',
        padding:'20px 12px',
        borderRadius:'8px',
        "& h6": {lineHeight:'22px',
            paddingBottom:'4px',
            fontSize:'18px',
            color: theme.palette.text.orange.orange600,
        },
    },
    inventoryCard4: {
        border: `1px solid ${theme.palette.border.secondary.secondary400}`,
        background: theme.palette.background.secondary.secondary50,
        color: theme.palette.text.secondary.secondary700,
        fontSize:'12px',lineHeight:'normal',
        padding:'20px 12px',
        borderRadius:'8px',
        "& h6": {lineHeight:'22px',
            paddingBottom:'4px',
            fontSize:'18px',
            color: theme.palette.text.secondary.secondary600,
        },
    },
},
}));
const cardData = [
    {
        img: rupee,
        title: "Improved Quality Assurance",
        description:
            "Detailed digital records help buyers verify material specifications, certifications, and supplier performance before placing orders.",
    },
    {
        img: verified,
        title: `Real-Time Visibility`,
        title2: '',
        description:
            "Instant stock level updates enable informed decision-making and quick response to demand fluctuations.",
    },
    {
        img: timeIcon,
        title: "Improved Supplier Coordination",
        title2: '',
        description:
            "Real-time inventory data fosters transparent communication with suppliers, ensuring timely restocks and stable supply relationships.",
    },
    {
        img: catalogIcon2,
        title: "Faster Replenishment Cycles",
        title2: '',
        description:
            "Automated reordering triggers ensure materials are delivered just in time, shortening lead times and improving production flow.",
    },
    {
        img: catalogIcon6,
        title: "Optimising Stock Levels",
        title2: '',
        description:
            "Ensuring the right amount of inventory is maintained to meet demand without excess.",
    },
    {
        img: catalogIcon4,
        title: "Improving Cash Flow",
        title2: '',
        description:
            "Reducing capital tied up in unsold stock, allowing for better financial flexibility.",
    },
    {
        img: catalogIcon4,
        title: "Enhancing Customer Satisfaction",
        title2: '',
        description:
            "Enabling timely order fulfillment through accurate stock tracking.",
    },
    {
        img: catalogIcon4,
        title: "Enhanced Forecast Accuracy",
        title2: '',
        description:
            "Historical data & advanced analytics improve demand forecasting, allowing more accurate & strategic procurement planning.",
    },
];

const DigiStockTemplate: React.FC = () => {
    const classes = useStyles();

    return (

        <>
        <div className={`${classes.bannerContainer}`}>
        <div className="container mx-auto px-4">
          <div className="insideBannerBx">
            <div className="insideBannerInfo">
                <b>SB DigiStock</b>
              <h1>Transparent Quality, <br />Real-Time Updates,<br />No Overstocks & <br />Shortages</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="neutral20Bg lg:py-14 py-8 w-full">
        <div className="container lg:px-4 px-2 mx-auto flex lg:columns-2 columns-1 lg:flex-row flex-col lg:gap-10 gap-2 PageInfoBoxGry">
          <div className="lg:w-2/5 w-full flex items-center">
            <h2>Digitise Your Steel Stocks for Operational Success</h2>
          </div>
          <div className={`lg:w-3/5 w-full`}>
            <p className=''>Digitising inventory on Steelbazaar offers transformative benefits to both buyers and sellers. For buyers, real-time updates, predictive analytics, and detailed material records enable informed decision-making, transparent quality assurance, and cost optimisation by maintaining ideal stock levels. Sellers gain enhanced visibility into market demand, efficient stock management, and the ability to showcase verified products to a broader audience, boosting credibility and sales.</p>
            <p className='!mb-0'>Building inventory on Steelbazaar is seamless, with user-friendly tools that simplify listing, tracking, & updating stock. The platform fosters collaboration, reduces inefficiencies, & ensures a smooth, data-driven procurement process for all stakeholders in the steel ecosystem.</p>
          </div>
        </div>
      </div>
      <div className={`${classes.container} container mx-auto py-90 lg:px-4 px-2 `} id='features'>
        <div className={`headingContainer`}>
          <div className="heading"><span>Digital Inventory </span>Management is Essential</div> 
        </div>

        <div className="container mx-auto w-full flex flex-wrap gap-4 ">
          {cardData.map((card, index) => (
            <div key={index} className="grayWhiteBox colWidth-4">
              <div className="iconBox">
                <img src={card.img} alt={card.title} />
              </div>
              <div className="TextBox">
                <b>{card.title}</b>{card.description}
              </div>
            </div>
          ))}
        </div>
        </div>
        <div className="neutral20Bg w-full">
            <div className={`${classes.container} container mx-auto  pt-90 lg:px-4 px-2 `} id='features'>
                <div className={`headingContainer`}>
                    <div className="heading">How to <span>Digitize Your Steel Stocks </span>on Steelbazaar?</div> 
                </div>
                <div className='txt40 w-full lg:py-10 pb-8 pt-0 font-semibold lg:text-left text-center Txtneutral800'>Digitizing All Type of Steel Inventories</div>
                <div className='gap-4 xl:columns-4 lg:columns-4 md:columns-2 columns-1'>
                            <div className={`${classes.inventoryCard1} w-full lg:mb-0 mb-4`}>
                                <h6>Standard Inventory</h6>
                                Regular steel products adhering to industry standards.
                            </div>
                            <div className={`${classes.inventoryCard2} w-full lg:mb-0 mb-4`}>
                                <h6>Non-Standard Inventory</h6>
                                Custom or unique steel products with specific specifications.
                            </div>
                            <div className={`${classes.inventoryCard3} w-full lg:mb-0 mb-4`}>
                                <h6>Commercial Scraps</h6>
                                Usable scrap materials generated during production.
                            </div>
                            <div className={`${classes.inventoryCard4} w-full lg:mb-0 mb-4`}>
                                <h6>Melting Scraps</h6>
                                Scrap materials designated for re-melting and recycling.
                            </div>
                        </div>

                        
             </div>
             <div className={`${classes.container} container mx-auto  py-90 lg:px-4 px-2 `}>
             <div className='txt40 w-full font-semibold Txtneutral800 pb-[4px]'>Specially Designed SKUs for The Steel Industry</div>
             <div className='txt24 w-full font-medium Txtneutral600 pb-10'>Specific attributes to ensure precise identification and standardisation</div>

             <div className='flex lg:flex-row flex-col gap-10'>
            <div className='lg:w-3/12 w-full flex lg:flex-col lg:flex-nowrap flex-wrap lg:gap-y-4 gap-2'>
                <div className="whiteGrayBox colWidthD1M2">
                    <div className="TextBox">
                        <b className='lg:!pb-3'>Product Category</b>Classification of the steel product (e.g., coil, sheet, rod).
                     </div>
                 </div>
                 <div className="whiteGrayBox colWidthD1M2">
                    <div className="TextBox">
                        <b className='lg:!pb-3'>Standard</b>Applicable industry standards (e.g., BIS, ASTM, ISO).
                     </div>
                 </div>
                 <div className="whiteGrayBox  colWidthD1M2">
                    <div className="TextBox">
                        <b className='lg:!pb-3'>Grades</b>Material grade indicating quality and composition.
                     </div>
                 </div>
                 <div className="whiteGrayBox colWidthD1M2">
                    <div className="TextBox">
                        <b className='lg:!pb-3'>Size Specifications</b>Dimensions such as thickness, width, and length.
                     </div>
                 </div>
            </div>
<div className='lg:w-6/12 w-full flex justify-center flex-nowrap items-center'><img src={digiSku} className='2xl:w-4/6 lg:w-full w-72' alt="" /></div>
<div className='lg:w-3/12 w-full flex lg:flex-col lg:flex-nowrap flex-wrap lg:gap-y-4 gap-2'>
                <div className="whiteGrayBox colWidthD1M2">
                    <div className="TextBox">
                        <b className='lg:!pb-3'>Coatings</b>Type of surface treatment or coating applied.
                     </div>
                 </div>
                 <div className="whiteGrayBox colWidthD1M2">
                    <div className="TextBox">
                        <b className='lg:!pb-3'>Chemical Composition</b>Detailed breakdown of elemental constituents.
                     </div>
                 </div>
                 <div className="whiteGrayBox colWidthD1M2">
                    <div className="TextBox">
                        <b className='lg:!pb-3'>Mechanical Properties</b>Attributes like tensile strength, yield strength, and elongation.
                     </div>
                 </div>
                 <div className="whiteGrayBox colWidthD1M2">
                    <div className="TextBox">
                        <b className='lg:!pb-3'>Other Properties</b>Additional characteristics relevant to the product's application.
                     </div>
                 </div>
            </div>
             </div>
             </div>
        </div>

             <div className={`${classes.container} container mx-auto py-90 lg:px-4 px-2 `} id='Registration'>
                <div className={`headingContainer`}>
                    <div className="heading">Steps for Creating Digital Inventory on Steelbazaar</div> 
                    <div className="Subheading">A Standard Inventory</div> 
                </div>
                <div className='txt40 w-full py-10 font-semibold Txtneutral800 lg:block hidden'>A) Standard Inventory</div>
                {
          // Section One Start Here
        }
        <div className="flex lg:columns-2 columns-1 lg:flex-row flex-col items-center ZigZagSection lg:pt-11 pt-0">
          <div className="2xl:w-4/12 lg:w-5/12 w-full flex order-1 lg:order-2 justify-center">
            <div className="ZigZagImgBox">
              <div className="ImgItemLeft">
                <img src={DotImg} alt="" />
              </div>
              <img src={Section1} className="w-full" alt="" />
            </div>
          </div>
          <div className="2xl:w-8/12 lg:w-7/12 w-full ZigZagText order-2 lg:order-1">
            <div className="grayText">Step 01:</div>
            <div className="heading48">
            Selecting or <span className='lg:block inline-block'>
            Creating SKUs</span>
            </div> 
            <div className="PointBox">
              <ul>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span>
                    <b>Pre-defined SKUs</b>
                    Choose from existing SKUs of renowned brands like TATA, JSW, SAIL, AMNS, & JSPL, which come with standardized specifications.
                  </span>
                </li>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span>
                    <b>Custom SKUs</b>
                    Create unique SKUs tailored to specific products, with the option to associate them with particular brands.
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {
          // Section Two Start Here
        }
        <div className="flex lg:columns-2 columns-1 lg:flex-row flex-col items-center ZigZagSection">
          <div className="2xl:w-4/12 lg:w-5/12 w-full flex justify-center">
            <div className="ZigZagImgBox">
              <div className="ImgItem">
                <img src={DotImg} alt="" />
              </div>
              <img src={Section2} className="w-full" alt="" />
            </div>
          </div>
          <div className="2xl:w-8/12 lg:w-7/12 w-full ZigZagText">
            <div className="grayText">Step 02:</div>
            <div className="heading48">
            Building Inventory for
            <span className='lg:block inline-block'>Selected SKUs</span>
            </div>
            <div className="PointBox">
              <ul>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span>
                    <b>Specify Quantity</b>
                    Indicate the number of coils or bundles for the chosen SKU.</span>
                </li>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span>
                    <b>Assign Unique Serial Numbers (USNs)</b>
                    Each unit is given a USN for individual tracking.
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {
          // Section Three Start Here
        }
        <div className="flex lg:columns-2 columns-1 lg:flex-row flex-col items-center ZigZagSection">
          <div className="2xl:w-4/12 lg:w-5/12 w-full flex order-1 lg:order-2 justify-center">
            <div className="ZigZagImgBox">
              <div className="ImgItemLeft">
                <img src={DotImg} alt="" />
              </div>
              <img src={Section3} className="w-full" alt="" />
            </div>
          </div>
          <div className="2xl:w-8/12 lg:w-7/12 w-full ZigZagText order-2 lg:order-1">
            <div className="grayText">Step 03:</div>
            <div className="heading48">
            Entering Detailed <span className='lg:block inline-block'>Information for Each USN</span>
            </div> 
            <div className="PointBox">
              <ul>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span>
                    <b>Weight Details</b>
                    Record both gross and net weight.
                  </span>
                </li>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span>
                    <b>Location</b>
                    Specify the storage or warehouse location.
                  </span>
                </li>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span>
                    <b>Packaging Information</b>
                    Describe packaging type and condition.
                  </span>
                </li>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span>
                    <b>Buyer Information</b>
                    Include any buyer-specific details or requirements.
                  </span>
                </li>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span>
                    <b>Mill Test Certificate Availability</b>
                    Indicate if a mill test certificate is available for the unit.
                  </span>
                </li>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span>
                    <b>Visual Documentation</b>
                    Upload images of each USN to provide a visual reference.
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {
          // Section Four Start Here
        }
        <div className="flex lg:columns-2 columns-1 lg:flex-row flex-col items-center ZigZagSection">
          <div className="2xl:w-4/12 lg:w-5/12 w-full flex justify-center">
            <div className="ZigZagImgBox">
              <div className="ImgItem">
                <img src={DotImg} alt="" />
              </div>
              <img src={Section4} className="w-full" alt="" />
            </div>
          </div>
          <div className="2xl:w-8/12 lg:w-7/12 w-full ZigZagText">
            <div className="grayText">Step 04:</div>
            <div className="heading48">
            Add
            <span className='lg:block inline-block'>Visual Images</span>
            </div>
            <div className="Subhead">
            Set up your account to begin your journey with SteelBazaar. Provide basic information about your business and create a secure profile.
            </div>
          </div>
        </div>
        {
          // Section Five Start Here
        }
        <div className="flex lg:columns-2 columns-1 lg:flex-row flex-col items-center ZigZagSection">
          <div className="2xl:w-4/12 lg:w-5/12 w-full flex order-1 lg:order-2 justify-center">
            <div className="ZigZagImgBox">
              <div className="ImgItemLeft">
                <img src={DotImg} alt="" />
              </div>
              <img src={Section5} className="w-full" alt="" />
            </div>
          </div>
          <div className="2xl:w-8/12 lg:w-7/12 w-full ZigZagText order-2 lg:order-1">
            <div className="grayText">Step 05:</div>
            <div className="heading48">
            Viewing and <span className='lg:block inline-block'>Managing Inventory</span>
            </div> 
            <div className="PointBox">
              <ul>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span>
                    <b>Tabular Display</b>
                    Access a comprehensive table listing all SKUs and their associated USNs, complete with details like ageing and other pertinent inventory information
                    </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="container mx-auto w-full flex flex-wrap lg:gap-8 gap-4 lg:p-0 pt-8">
            <div className="grayWhiteBoxV1 colWidth-3m1V1">
            <b>B) Non-Standard Inventory</b>
            <div className='TextBox'>
            <div className="iconBox">
            <img src={verifiedTick2} alt="" />
              </div>
              <div className="textItem">
                <b>Detailed Entry</b>
                <p>Convert invoices into immediate cash.</p>
              </div>
              </div>
            </div>

            <div className="grayWhiteBoxV1 colWidth-3m1V1">
            <b>C) Commercial Scraps</b>
            <div className='TextBox'>
            <div className="iconBox">
            <img src={verifiedTick2} alt="" />
              </div>
              <div className="textItem">
                <b>Comprehensive Documentation</b>
                <p>Sell to pre-approved buyers for risk-free deals.</p>
              </div>
              </div>
            </div>

            <div className="grayWhiteBoxV1 colWidth-3m1V1">
            <b>D) Melting Scraps</b>
            <div className='TextBox'>
            <div className="iconBox">
            <img src={verifiedTick2} alt="" />
              </div>
              <div className="textItem">
                <b>Specific Details</b>
                <p>Provide relevant data such as material composition, weight, and intended use for recycling purposes.</p>
              </div>
              </div>
            </div>
        </div>
        
            </div>
            <div className={`${classes.container} container mx-auto lg:pt-10 lg:pb-0 pt-4 pb-5 lg:px-4 px-2 `}>
                <div className={`headingContainer`}>
                    <div className="heading">Benefits of Digital Inventory Management</div> 
                </div>
            </div>
            <div className='w-full flex blueOddEvenBox  lg:py-10 py-4'>
                <div className='container mx-auto flex lg:flex-row flex-col lg:px-4 px-0'>
                    <div className='lg:w-6/12 w-full leftBx'>
                    <div className='OddTextBox'>
                        <div className='OddHeading'>For <span>Sellers</span></div>
                        <div className='OddContent'>
                        <ul>
                            <li>
                            <img src={BulletImg} className="bulletBrd" alt="" />
                            <span>
                                <b>Enhanced Visibility</b>
                                Real-time tracking of inventory levels and locations.
                                </span>
                            </li>
                            <li>
                            <img src={BulletImg} className="bulletBrd" alt="" />
                            <span>
                                <b>Improved Accuracy</b>
                                Minimization of errors associated with manual data entry.
                                </span>
                            </li>
                            <li>
                            <img src={BulletImg} className="bulletBrd" alt="" />
                            <span>
                                <b>Operational Efficiency</b>
                                Streamlined processes leading to faster order fulfilment.
                                </span>
                            </li>
                        </ul>
                        </div>
                    </div>
                    </div>
                    <div className='lg:w-6/12 w-full RightBx'>
                    <div className='EvenTextBox'>
                        <div className='EvenHeading'>For <span>Buyers</span></div>
                        <div className='EvenContent'>
                        <ul>
                            <li>
                            <img src={BulletImg} className="bulletBrd" alt="" />
                            <span>
                                <b>Transparency</b>
                                Access to detailed product information, specifications & certifications.
                                </span>
                            </li>
                            <li>
                            <img src={BulletImg} className="bulletBrd" alt="" />
                            <span>
                                <b>Informed Purchasing Decisions</b>
                                Ability to view product images & documentation before purchase.
                                </span>
                            </li>
                            <li>
                            <img src={BulletImg} className="bulletBrd" alt="" />
                            <span>
                                <b>Reliable Supply</b>
                                Assurance of product availability and timely delivery.
                                </span>
                            </li>
                        </ul>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
 

            <div className={`${classes.container} container mx-auto py-90 lg:px-4 px-2 `}>
                <div className={`headingContainer !pb-0`}>
                    <div className="heading">Traditional <span>Methods</span></div> 
                </div>
                <div className={`${classes.methods}`}>v/s</div>
                <div className={`headingContainer !pt-0`}>
                    <div className="heading"><span>Digital</span> Inventory Management</div>
                </div>

                <div className='container mx-auto flex pb-3 TraditionalMethodsBox'>
                    <div className='w-5/12 headingGreen'>Traditional Methods</div>
                    <div className='w-2/12 headingBlack'>Aspect</div>
                    <div className='w-5/12 headingComplementary'>Digital Inventory Management</div>
                </div>
                <div className='container mx-auto flex pb-2 TraditionalMethodsBox'>
                    <div className='w-5/12 SubheadingGreen'>Prone to Errors Due to Manual Entry</div>
                    <div className='w-2/12 SubheadingBlack'>Data Accuracy</div>
                    <div className='w-5/12 SubheadingComplementary'>High Precision with Automated Systems</div>
                </div>
                <div className='container mx-auto flex pb-2 TraditionalMethodsBox'>
                    <div className='w-5/12 SubheadingGreen'>Cumbersome Access to Physical Records</div>
                    <div className='w-2/12 SubheadingBlack'>Accessibility</div>
                    <div className='w-5/12 SubheadingComplementary'>Instant Retrieval Through Digital Platforms</div>
                </div>
                <div className='container mx-auto flex pb-2 TraditionalMethodsBox'>
                    <div className='w-5/12 SubheadingGreen'>Time-Consuming Tracking Processes</div>
                    <div className='w-2/12 SubheadingBlack'>Efficiency</div>
                    <div className='w-5/12 SubheadingComplementary'>Automated, Saving Time and Resources</div>
                </div>
                <div className='container mx-auto flex pb-2 TraditionalMethodsBox'>
                    <div className='w-5/12 SubheadingGreen'>Based on Outdated or Inaccurate Data</div>
                    <div className='w-2/12 SubheadingBlack'>Decision Making</div>
                    <div className='w-5/12 SubheadingComplementary'>Real-Time Data Supports Strategic Planning</div>
                </div>
                <div className='container mx-auto flex pb-2 TraditionalMethodsBox'>
                    <div className='w-5/12 SubheadingGreen'>Delays Due to Lack of Real-Time Information</div>
                    <div className='w-2/12 SubheadingBlack'>Customer Service</div>
                    <div className='w-5/12 SubheadingComplementary'>Prompt Responses Enabled by Up-to-Date Inventory Data</div>
                </div>
            </div>

            <div  className={`CTABox lg:py-24 py-8 w-full textBoxfg`}>
      <div className="container mx-auto CallToAction lg:px-4 px-3 ">
        <div className="2xl:w-6/12 lg:w-5/12 flex flex-col gap-y-2.5 items-start">
       <h2>
       SB DigiStock <br />Efficient & Transparent Inventory Management
       </h2>
       <p className="mb-5">By adopting SB DigiStock, steel industry stakeholders can transition from traditional, error-prone methods to a streamlined, efficient, and transparent digital inventory  management system, fostering growth and competitiveness in the market</p>
       <Button className="Btn whiteBg Txtprimary500">Contact Now <ArrowForwardIcon className="primary500Bg text-white" />
                </Button>
      </div>
      </div>
      </div>
      
            <RequestDemoTemplate />
        </>
    );
};
export default DigiStockTemplate;