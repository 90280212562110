import React, { useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import RequestDemoTemplate from '../../template/Home/RequestDemo/RequestDemo.template';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Button } from '@mui/joy';
import auctionBanner from '../../../assets/images/auctionBanner.jpg';
import auctionSecondaryBanner from '../../../assets/images/auctionSecondaryBanner.png';
import roudTickIcon from '../../../assets/icons/roudTickIcon.jpg';
import register from '../../../assets/images/register.jpg';
import auction1 from '../../../assets/images/auction1.png';
import auction2 from '../../../assets/images/auction2.png';
import auction3 from '../../../assets/images/auction3.png';
import auction4 from '../../../assets/images/auction4.png';
import reach from '../../../assets/icons/reach.svg';
import forwardAuction from '../../../assets/images/forwardAuction.png';
import reverseAcution from '../../../assets/images/reverseAcution.png';
import participate from '../../../assets/images/participate.png';
import bulkProcurement from '../../../assets/images/bulkProcurement.png';
import priceTool from '../../../assets/images/priceTool.png';
import source_card_icon1 from '../../../assets/icons/source_card_icon1.jpg';
import source_card_icon2 from '../../../assets/icons/source_card_icon2.jpg';
import source_card_icon3 from '../../../assets/icons/source_card_icon3.jpg';
import source_card_icon4 from '../../../assets/icons/source_card_icon4.jpg';
import { useLocation } from 'react-router-dom';
//bykamal
import AuctionBannerMobile from '../../../assets/images/AuctionBannerMobile.jpg';
import DotImg from "../../../assets/images/dot-shape.png";
import BulletImg from "../../../assets/images/bulletIcon.svg";
import Step1Img from '../../../assets/images/Auction-Forward-Sales-Auctions.jpg';
import Step2Img from '../../../assets/images/Auction-Reverse-Procurement-Auctions.jpg';
import Step3Img from '../../../assets/images/Auction-Participate-Forward-Sales-Auctions.jpg';
import Step4Img from '../../../assets/images/Auction-Create-Reverse-Procurement-Auctions.jpg';
import Step5Img from '../../../assets/images/Auction-Participate-Reverse-Procurement-Auctions.jpg';
import Step6Img from '../../../assets/images/Auction-Create-Forward-Sales-Auction.jpg';
import YellowArrow from '../../../assets/images/YellowBigArrow.svg';
import YellowArrowMobile from '../../../assets/images/YellowBigArrowMobile.svg';
import RedArrow from '../../../assets/images/RedBigArrow.svg';
import RedArrowMobile from '../../../assets/images/RedBigArrowMobile.svg';
import BlueArrow from '../../../assets/images/BlueBigArrow.svg';
import BlueArrowMobile from '../../../assets/images/BlueBigArrowMobile.svg';
import GreenArrow from '../../../assets/images/GreenBigArrow.svg';
import GreenArrowMobile from '../../../assets/images/GreenBigArrowMobile.svg';
import IconSBBidHub1 from '../../../assets/images/Auction-Icon-Competitive-Pricing.svg';
import IconSBBidHub2 from '../../../assets/images/Auction-Icon-Real-Time-Transparency.svg';
import IconSBBidHub3 from '../../../assets/images/Auction-Icon-Large-Network.svg';
import IconSBBidHub4 from '../../../assets/images/Auction-Icon-Efficient-Transactions.svg';

const useStyles = createUseStyles((theme: any) => ({
    container: {
       
    },
    bannerContainer: {
      
        backgroundImage: `url(${auctionBanner})`,
        backgroundSize: 'cover',
    },
    bannerSellContainer: {  
        width: '1280px',
        margin: '0 auto',      
        "& h1": {
            fontSize: '56px',
            lineHeight: '66px',
            color: theme.palette.text.warning.warning400,
            
        },
        "& p": {
            color: '#fff',
            lineHeight: '66px',
            fontSize: '56px',
        },
    },
    intoContainer: {
        backgroundColor: theme.palette.background.neutral.neutral50,
    },
    text: {
        fontSize: '54px',
        color: "#000",
        lineHeight: '66px',
    },
    decContainer: {
        width: '672px',
        "& p": {
            color: "#000",
            lineHeight: '26px',
        },
    },
    headingText: {
        fontSize: '48px !important',
        "& span": {
            color: `${theme.palette.text.primary.primary500} !important`,
        },
    },
    featuresContainer: {
        "& > p": {
            color: theme.palette.text.neutral.neutral600,
        },
        // "& span": {
        //     fontSize: '40px',
        //     lineHeight: '48px',
        //     color: theme.palette.text.primary.primary700,
        // },
        "& h4": {
            color: theme.palette.text.neutral.neutral800,
            fontSize: '48px',
            "& span": {
                fontWeight: '600',
            color: theme.palette.text.primary.primary500,
            fontSize: '48px',

            },
        },
        "& h6": {
            color: theme.palette.text.neutral.neutral900,
            lineHeight: '28px'
        },
    },
    featureInfo: {
        "& h4": {
            color: theme.palette.text.neutral.neutral900,
        },
        "& > p": {
            color: theme.palette.text.neutral.neutral800,
            lineHeight: '38px',
            fontSize: '32px',
        },
    },
    featuresText: {
        width: '651px',
        "& h5": {
            color: '#000',
            fontSize: '22px',
            lineHeight: '26px',
        },
        "& p": {
            color: theme.palette.text.neutral.neutral800,
            lineHeight: '26px',
        },
    },
    platformHeading: {
        fontSize: '54px',
        color: '#000',
        lineHeight: '66px',
    },
    platformSubHeading: {
        color: theme.palette.text.neutral.neutral600,
        fontSize: '28px',
        lineHeight: '34px',
    },
    platformImg: {
        position: "relative"
    },
    platformText: {
        position: 'absolute',
        top: "50%",
        left: '52%',
        width: '100%',
        transform: 'translate(-50%, -50%)',
        color: '#000',
        lineHeight: '34px',
        textAlign: 'center',
        fontSize: '28px',
    },
    platfomrContainer: {
        background: '#F8F8F8',
    },
    borderDiv: {
        background: '#000',
        width: '2px',
        height: '25px',
    },
    platformSecText: {
        color: '#000',
    },
    secondaryPlatform: {
        position: 'relative',
        top: '-105px',
    },
    cardsContainer: {
        background: '#fff',
        "& p": {
            lineHeight: '26px',
            color: theme.palette.text.neutral.neutral600,
            fontSize: '28px',
        },
    },
    bannerBtn: {
        background: theme.palette.background.primary.primary500,
        padding: '10px 20px',
        width: '228px',
        color: '#fff',
        margin: '0 auto',
    },
    btnArrow: {
        border: '1px solid #fff',
        borderRadius: '100%',
    },
    sectionHeading: {
        color: "#000",
        fontSize: '54px',
        lineHeight: '66px',
        "& span": {
            color: "#000",
            fontSize: '54px',
            lineHeight: '66px',
        },
        "& > h4": {
            fontSize: '54px',
            lineHeight: '66px',
            color: theme.palette.text.primary.primary500,

        }
    },
    card: {
        border: `1px solid ${theme.palette.border.neutral.neutral200}`,
        background: '#F8F8F8',
        padding: '20px 15px',
        width: '23%',
        "& h4": {
            color: '#000',
            fontSize: '22px',
            lineHeight: '26px',
        },
        "& p": {
            color: theme.palette.text.neutral.neutral800,
            fontSize: '16px',
            lineHeight: '26px',
        },
    },
    cardIconContainer: {
        border: `1px solid ${theme.palette.border.neutral.neutral200}`,
        background: '#fff',
    },
    secondaryBanner: {
        width: '100%',
        height: '480px',
        backgroundImage: `url(${auctionSecondaryBanner})`,
        backgroundSize: 'cover',
        position: 'relative',
    },
    secondaryContainer: {
        '& h2': {
            color: theme.palette.text.warning.warning400,
            lineHeight: '58px',
        },
        "& p": {
            color: theme.palette.text.primary.primary50,
            lineHeight: '38px',
            fontSize: '32px',
        },  
    },
    btn3: {
        background: '#fff',
        color: '#fff',
        fontSize: '18px',
        fontWeight: '500',
        padding: '10px 20px',
        lineHeight: '22px',
    },
    btnArrow2: {
        border: '1px solid transparent',
        background: theme.palette.background.primary.primary500,
        borderRadius: '50%',
        height: '32px',
        width: '32px',
    },
    btnText: {
        color: theme.palette.text.primary.primary500,
        fontSize: '20px',
    },
    "@media screen and (max-width: 480px)": {
        bannerContainer: {
          backgroundImage: `url(${AuctionBannerMobile})`,
          backgroundSize: "100% 100%",
        },},
}));

const cardData = [
    {
        img: source_card_icon1,
        title: "Competitive Pricing",
        description:
            "Auctions help secure the best possible pricing for both buyers and sellers.",
    },
    {
        img: source_card_icon2,
        title: "Real-Time Transparency",
        description:
            "Get live updates on bids and pricing fluctuations to make informed decisions.",
    },
    {
        img: source_card_icon3,
        title: "Large Network ",
        description:
            "Access a vast network of steel buyers and sellers for optimal market exposure.",
    },
    {
        img: source_card_icon4,
        title: "Efficient Transactions",
        description:
            "Complete transactions securely and efficiently through our platform.",
    },
    
];

const AcutionTemplate: React.FC = () => {

    const classes = useStyles();
    const location = useLocation();

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const component = params.get('component');

        if (component) {
            const element = document.getElementById(component);
            if (element) {
                element.scrollIntoView();
            }
        }
    }, [location.search]);
    
    return (
        <>
        <div className={`${classes.bannerContainer}`}>
        <div className="container mx-auto px-4">
          <div className="insideBannerBx">
            <div className="insideBannerInfo">
                <b>SB BidHub</b>
              <h1>
              Buy Smarter,<br />Sell Faster<br />Your Auction Marketplace
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="neutral20Bg lg:py-14 py-8 w-full">
        <div className="container lg:px-4 px-2 mx-auto flex lg:columns-2 columns-1 lg:flex-row flex-col lg:gap-10 gap-2 PageInfoBoxGry">
          <div className="lg:w-2/5 w-full">
            <h2>Real-Time Transparent Biddings</h2>
          </div>
          <div className={`lg:w-3/5 w-full`}>
            <p className='!mb-0'>SteelBazaar introduces a cutting-edge auction service designed for both buyers and sellers of steel raw materials and finished products. Whether you're looking to procure steel at competitive prices through reverse procurement or sell your inventory through forward auctions, SteelBazaar provides a streamlined platform for real-time, transparent bidding.</p>
          </div>
        </div>
      </div> 
      <div className={`${classes.container} container mx-auto lg:px-4 px-2 lg:py-24 py-8`} id='Registration'>
        <div className={`headingContainer`}>
          <div className="heading"><span>SB BidHub</span> Auction Services</div> </div>
        
          {
          // Section One Start Here
        }
          <div className="flex lg:columns-2 columns-1 lg:flex-row flex-col items-center ZigZagSection" id='forward-sales-auction'>
          <div className="2xl:w-4/12 lg:w-5/12 w-full flex content-center justify-center">
            <div className="ZigZagImgBox">
              <div className="ImgItem">
                <img src={DotImg} alt="" />
              </div>
              <img src={Step1Img} className="w-full" alt="" />
            </div>
          </div>
          <div className="2xl:w-8/12 lg:w-7/12 w-full ZigZagText"> 
            <div className="heading48">
              Forward Sales <span>Auctions</span>
            </div>
            <div className="Subhead">
              <b>Sell Your Steel Products Through Forward Auctions</b>
            </div>

            <div className="PointBox">
              <ul>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span>
                    <b>Wider Market Access</b>
                    Tap into a broad buyer network.
                  </span>
                </li>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span>
                    <b>Real-Time Bidding</b>Get live updates on bids and price movements.
                  </span>
                </li>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span>
                    <b>Maximize Revenue</b>
                    Competitive pricing that ensures optimal returns.
                  </span>
                </li>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span>
                    <b>Seamless Listing</b>
                    Easily list your products with detailed specifications.
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {
          // Section Two Start Here
        }
          <div className="flex lg:columns-2 columns-1 lg:flex-row flex-col items-center ZigZagSection border-0" id='reverse-buy-auction'>
          <div className="2xl:w-4/12 lg:w-5/12 w-full flex content-center order-1 lg:order-2 justify-center">
            <div className="ZigZagImgBox">
              <div className="ImgItemLeft">
                <img src={DotImg} alt="" />
              </div>
              <img src={Step2Img} className="w-full" alt="" />
            </div>
          </div>
          <div className="2xl:w-8/12 lg:w-7/12 w-full ZigZagText order-2 lg:order-1"> 
            <div className="heading48">
              <span>Reverse Procurement</span> Auctions
            </div>
            <div className="Subhead">
              <b>Procure Steel Materials at Competitive Prices</b> 
            </div>
            <div className="PointBox">
              <ul>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span>
                    <b>Competitive Bidding</b>
                   Vendors compete to offer the best prices.
                  </span>
                </li>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span>
                    <b>Cost Reduction</b>
                    Drive down procurement costs through reverse bidding.
                  </span>
                </li>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span>
                    <b>Transparency</b>
                    Monitor bids in real-time and select the best offers.
                  </span>
                </li>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span>
                    <b>Customizable Requirements</b>
                    Define your steel needs and receive bids that match your criteria.
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>

  </div> 
      <div className={`${classes.container} container mx-auto lg:px-4 px-2 lg:py-0 py-8`}>
        <div className={`headingContainer`}>
          <div className="heading">For Buyers, <span>Revolutionizing Steel Procurement</span></div>
          <div className="Subheading">SB Auctions Built to Meet Every Buyer’s Needs</div>
          </div>
        {
          // Section Three Start Here
        }
          <div className="flex lg:columns-2 columns-1 lg:flex-row flex-col items-center ZigZagSection">
          <div className="2xl:w-4/12 lg:w-5/12 w-full flex content-center justify-center">
            <div className="ZigZagImgBox">
              <div className="ImgItem">
                <img src={DotImg} alt="" />
              </div>
              <img src={Step3Img} className="w-full" alt="" />
            </div>
          </div>
          <div className="2xl:w-8/12 lg:w-7/12 w-full ZigZagText">
            <div className="heading48">
            Participate in Forward Sales Auctions and Get Bargains
            </div>
            <div className="Subhead">
              <b>Sell Your Steel Products Through Forward Auctions</b>
            </div>

            <div className="PointBox">
              <ul>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span>
                    <b>Wider Market Access</b>
                    Tap into a broad buyer network.
                  </span>
                </li>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span>
                    <b>Real-Time Bidding</b>
                    Get live updates on bids and price movements.
                  </span>
                </li>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span>
                    <b>Maximize Revenue</b>
                    Competitive pricing that ensures optimal returns.
                  </span>
                </li>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span>
                    <b>Seamless Listing</b>
                    Easily list your products with detailed specifications.
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {
          // Section Four Start Here
        }
          <div className="flex lg:columns-2 columns-1 lg:flex-row flex-col items-center ZigZagSection border-0">
          <div className="2xl:w-4/12 lg:w-5/12 w-full flex content-center order-1 lg:order-2 justify-center">
            <div className="ZigZagImgBox">
              <div className="ImgItemLeft">
                <img src={DotImg} alt="" />
              </div>
              <img src={Step4Img} className="w-full" alt="" />
            </div>
          </div>
          <div className="2xl:w-8/12 lg:w-7/12 w-full ZigZagText order-2 lg:order-1">
            <div className="heading48">
            Create Reverse Procurement Auctions & Optimise Bulk Procurement
            </div> 
            <div className="PointBox">
              <ul>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span>
                    <b>Create Reverse Procurement Auctions</b>
                    Invite sellers to compete for supply orders.
                  </span>
                </li>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span>
                    <b>Bulk Buying Made Easy</b>
                    Streamline large-volume purchases effortlessly.
                  </span>
                </li>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span>
                    <b>Cost Optimization</b>
                    Leverage competition to secure the lowest rates.
                  </span>
                </li>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span>
                    <b>Customized Procurement</b>
                    Tailor auctions to meet specific material needs.
                  </span>
                </li>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span>
                    <b>Regular Supply</b>
                    Ensure consistent materials for operational efficiency.
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>


  </div> 
      <div className={`${classes.container} container mx-auto lg:px-4 px-2 lg:py-24 py-8`} id='Registration'>
        <div className={`headingContainer`}>
          <div className="heading">For Sellers, <span>Maximize Market Opportunities</span></div>
          <div className="Subheading">Sell Smarter and Secure Supply Orders</div>
          </div>

        {
          // Section Five Start Here
        }
          <div className="flex lg:columns-2 columns-1 lg:flex-row flex-col items-center ZigZagSection">
          <div className="2xl:w-4/12 lg:w-5/12 w-full flex content-center justify-center">
            <div className="ZigZagImgBox">
              <div className="ImgItem">
                <img src={DotImg} alt="" />
              </div>
              <img src={Step5Img} className="w-full" alt="" />
            </div>
          </div>
          <div className="2xl:w-8/12 lg:w-7/12 w-full ZigZagText"> 
            <div className="heading48">
            Create <span>Forward Sales Auction</span> for Surplus, Defective, Idle, or Scrap Materials
            </div>
            <div className="PointBox">
              <ul>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span>
                    <b>Find Active Buyers</b>
                    Connect with interested buyers effortlessly.
                  </span>
                </li>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span>
                    <b>Achieve Fair Pricing</b>
                    Secure competitive rates through open bidding.
                  </span>
                </li>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span>
                    <b>Quick Liquidity</b>
                    Convert unused materials into cash promptly.
                  </span>
                </li>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span>
                    <b>Enhanced Market Visibility</b>
                    Showcase offerings to a broad audience.
                  </span>
                </li>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span>
                    <b>Streamlined Selling</b>
                    Simplify sales with transparent auction processes.
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {
          // Section Six Start Here
        }
          <div className="flex lg:columns-2 columns-1 lg:flex-row flex-col items-center ZigZagSection border-0">
          <div className="2xl:w-4/12 lg:w-5/12 w-full flex content-center order-1 lg:order-2 justify-center">
            <div className="ZigZagImgBox">
              <div className="ImgItemLeft">
                <img src={DotImg} alt="" />
              </div>
              <img src={Step6Img} className="w-full" alt="" />
            </div>
          </div>
          <div className="2xl:w-8/12 lg:w-7/12 w-full ZigZagText order-2 lg:order-1">
            <div className="heading48">
            Participate in <span>Reverse Procurement Auctions</span> to get secure supply orders
            </div>
            <div className="PointBox">
              <ul>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span>
                    <b>Secure Supply Orders</b>
                    Compete for enterprise buyer requirements.
                  </span>
                </li>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span>
                    <b>Build Buyer Relationships</b>
                    Collaborate with verified, repeat clients.
                  </span>
                </li>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span>
                    <b>Improve Inventory Turnover</b>
                    Align production with buyer needs.
                  </span>
                </li>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span>
                    <b>Tailored Offers</b>
                    Customize bids to match specific procurement demands.
                  </span>
                </li>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span>
                    <b>Expand Market Reach</b>
                    Tap into a diverse pool of enterprise buyers.
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>



      </div>
    
      <div className="neutral20Bg lg:py-14 py-8 w-full">
      <div className={`${classes.container} container mx-auto lg:px-4 px-2 lg:py-24 py-8`}>
        <div className={`headingContainer`}>
          <div className="heading">How does the Auction Process Work ?</div>
          <div className="Subheading">Achieve better pricing and broader market reach</div>
          </div>
<div className='ArrowBox'>
  <div className='ArrowItem'>
<div className='ArrowImg'>
  <img src={YellowArrow} className='hidden lg:block md:block sm:hidden' alt="" />
  <img src={YellowArrowMobile} className='block sm:block lg:hidden md:hidden' alt="" />
  </div>
<div className='ArerowHeading'>Create Your Auction Listing</div>
<div className='ArerowText bottom'>Whether selling or buying, you can quickly create an auction listing by entering product specifications or your procurement needs.</div>
  </div>
  <div className='ArrowItem'>
<div className='ArrowImg'>
  <img src={RedArrow} className='hidden lg:block md:block sm:hidden' alt="" />
  <img src={RedArrowMobile} className='block sm:block lg:hidden md:hidden' alt="" />
  </div>
<div className='ArerowHeading'>Start Bidding</div>
<div className='ArerowText top'>Sellers will receive bids from buyers, and buyers will get offers from vendors in real time.</div>
  </div>
  <div className='ArrowItem'>
<div className='ArrowImg'>
  <img src={BlueArrow} className='hidden lg:block md:block sm:hidden' alt="" />
  <img src={BlueArrowMobile} className='block sm:block lg:hidden md:hidden' alt="" />
  </div>
<div className='ArerowHeading'>Monitor and Manage</div>
<div className='ArerowText bottom'>Track all bidding activity through your SteelBazaar dashboard, allowing you to adjust bids or pricing in real-time.</div>
  </div>
  <div className='ArrowItem'>
<div className='ArrowImg'>
  <img src={GreenArrow} className='hidden lg:block md:block sm:hidden' alt="" />
  <img src={GreenArrowMobile} className='block sm:block lg:hidden md:hidden' alt="" />
  </div>
<div className='ArerowHeading'>Finalize the Deal</div>
<div className='ArerowText top'>Once the auction ends, the winning bid is finalized, & you can securely complete the transaction through the platform.</div>
  </div>
</div>
          </div>
      </div>

      
      <div className={`${classes.container} container mx-auto lg:px-4 px-2 lg:py-24 py-8`} id='features'>
        <div className={`headingContainer`}>
          <div className="heading">Why Choose SB BidHub Auctions?</div>
          <div className="Subheading">Achieve better pricing and broader market reach</div>
          </div>
          <div className="w-full flex flex-wrap lg:gap-4 gap-2 ">
            {cardData.map((card, index) => (
              <div  key={index} className="grayWhiteBox colWidth-4">
              <div className="iconBox">
                <img src={card.img} alt={card.title} />
              </div>
              <div className="TextBox">
                <b>{card.title}</b>
                {card.description}
              </div>
            </div>  
            ))}
          </div>
          </div> 

           
          <div  className={`CTABox lg:py-24 py-8 w-full `}>
      <div className="container mx-auto CallToAction lg:px-4 px-3 ">
        <div className="2xl:w-5/12 lg:w-5/12 flex flex-col gap-y-2.5 items-start">
       <h2>
       Start Your Auction Journey Today
       </h2>
       <p className="mb-5">
       Join SteelBazaar and revolutionise your <br /> procurement and sales
       </p>
       <Button className="Btn whiteBg Txtprimary500">Contact Now <ArrowForwardIcon className="primary500Bg text-white" />
                </Button>
      </div>
      </div>
      </div>
 
            <RequestDemoTemplate />
        </>
    );
};
export default AcutionTemplate;
