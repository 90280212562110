import React, { useEffect, useState, useContext, useRef } from 'react'
import { useCatalogueService } from '../../../services/useCatalogueService';
import { IWarehouse } from '../../template/CatalogueDetail/WarehouseLocation.template';
import { CATALOGUE_TYPE_STATES, CLASS_STATES, HTTP_STATUS } from '../../../utils/types';
import Select from '../../atoms/Select/Select';
import ProductUpcTemplate from '../../template/CatalogueDetail/ProductUpcAttribute.template';
import { createUseStyles } from 'react-jss';
import CatalogueImageTemplate, { IImage } from '../../template/CatalogueDetail/CatalogueImage.template';
import { makeRoute, snakeCaseToTitleCase } from '../../../utils/helper';
import { useLocation, useNavigate } from 'react-router-dom';
import { ICartRequestBody, useCartService } from '../../../services/useCartService';
import Breadcrumbs from '../../atoms/BreadCrumbs/BreadCrumbs';
import { CartContext } from '../../../contexts/CartContext';
import AttributeSelectionTemplate from '../../template/CatalogueDetail/AttributeSelection.template';
import PriceDetailsTemplate from '../../template/CatalogueDetail/PriceDetails.template';
import { useAuthenticatedUser } from '../../../hooks/useAuthenticatedUser';
import CarouselRef from 'react-multi-carousel';
import { CART_LIFE_CYCLE, CUSTOMER_ROUTES, PAYMENT_METHODS, REGEX, UNIT } from '../../../utils/constant';
import TermsAndConditionsCarousel from '../../template/CatalogueDetail/TermsAndConditionsCarousel.template';
import Disclaimer from '../../template/CatalogueDetail/Disclaimer.template';
import FAQTemplate from '../../template/CatalogueDetail/FAQ.template';
import ContactUs from '../../molecules/ContactUs/ContactUs.page';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TermsConditions from '../../template/CatalogueDetail/TermsConditions.template';
import AddToFavouriteTemplate from '../../template/CatalogueDetail/AddToFavourite.template';
import PaymentDetailsTemplate from '../../template/CatalogueDetail/PaymentDetails.template';
import DeliveryDetailsTemplate from '../../template/CatalogueDetail/DeliveryDetails.template';
import SelectCustomizationTemplate, { IValueAddedService } from '../../template/Customization/SelectCustomization.template';
import TermsConditionsTemplate from '../../template/CatalogueDetail/TermsConditions.template';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import TextFieldV2 from '../../atoms/Input/TextFieldV2';
import SimilarBrands, { ISimilarBrands } from '../../template/CatalogueDetail/SimilarBrands.template';
import Button from '../../atoms/Button/Button';
import RequestForQuotationModalTemplate from '../../template/Rfq/RequestForQuotationModal.template';
import SuccessFailurePopup from '../../molecules/SuccessFailurePopup/SuccessFailurePopup';
import { useProductUpcBrandMappingService } from '../../../services/useProductUpcBrandMappingService';
import sellerTrusted from '../../../assets/icons/sellerTrusted.jpg';
import sbTrusted from '../../../assets/icons/sbTrusted.svg';
import { HiOutlineInformationCircle } from "react-icons/hi2";
import Tooltip from '../../atoms/ToolTip/Tooltip';
import CatalogueVendorsTemplate, { WarehouseCatalogue } from '../../template/CatalogueDetail/CatalogueVendors.template';

export interface IAttribute {
    id: number;
    name: string;
    uom?: string;
    fieldType?: string;
    attributeOptions: number | string | string[];
}

export interface ICatalogue {
    id: number;
    name: string;
    description: string;
    classType: string;
    standard: string;
    grade: string;
    brand: string;
    manufacturer: string;
    minimumPrice: number;
    maximumPrice: number;
    catalogueAttributes: {
        SPECIFICATION: IAttribute[];
        CLASSIFICATION: IAttribute[];
        GENERALIZATION: IAttribute[];
    };
    catalogueImages: IImage[];
    packagingType: String[];
    packagingTypes?: string[];
    customization: String[];
    isRepresentationImage: boolean;
    userType?: string;
    isVerifiedTrusted?: boolean;
}

export interface IUpcProperty {
    name: string;
    minimum?: number;
    maximum?: number;
    roomTemperature?: string;
    otherTemperature?: string;
    uom?: string;
}

export interface ICatalogueUpc {
    id: number;
    upcPrice: number;
    superCategory: string;
    mainCategory: string;
    productCategory: string;
    attributes: {
        SPECIFICATION: IAttribute[];
        CLASSIFICATION: IAttribute[];
        GENERALIZATION: IAttribute[];
    };
    hsnCodes: number[];
    tensileProperties: IUpcProperty[];
    chemicalComposition: IUpcProperty[];
    mechanicalProperties: IUpcProperty[];
    warehouseDetails: IWarehouse[];
    minimumOrderQuantity?: number;
}

export interface ICurrentCartItem {
    attributes: Record<string, any>;
    upc: ICatalogueUpc | null;
    warehouse: any | null;
    packaging: string;
    quantity: string;
    secondarySkuId: number | null;
    paymentType: string | null;
}

const useStyles = createUseStyles((theme: any) => ({
    title: {
        color: theme.palette.text.secondaryDark
    },
    brand: {
        color: theme.palette.text.primary.primary500,
        lineHeight: "14px"
    },
    leftContainer: {
    },
    middleChild: {
        width: "61.18%"
    },
    rightContainer: {
        width: "29.15%",
    },
    catalogueName: {
        color: theme.palette.text.primary.primary900,
        lineHeight: "28px"
    },
    value: {
        color: theme.palette.text.neutral.neutral700,
        fontSize: "16px",
        lineHeight: "20px"
    },
    valueInput: {
        color: theme.palette.text.neutral.neutral600,
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "20px"
    },
    select: {
        border: "1px solid",
        borderColor: theme.palette.border.neutral.neutral200,
        fontSize: "16px",
        fontWeight: 400,
        borderRadius: "12px",
        color: theme.palette.text.neutral.neutral800,
        "&:hover": {
            borderColor: theme.palette.border.primary.primary300,
            borderWidth: "1px",
            outline: "none",
        },
        "& .MuiOutlinedInput-notchedOutline": {
            outline: "none",
            borderWidth: 0,
            borderColor: theme.palette.border.primary.primary300,
        },
        "&:focus-within": {
            outline: "none",
            borderColor: `${theme.palette.border.primary.primary300} !important`,
            borderWidth: 2,
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            outline: "none",
            borderWidth: 0,
            borderColor: `${theme.palette.border.primary.primary300} !important`,
        },
        "& .MuiInputBase-input": {
            padding: "13px 12px !important",
            "&::placeholder": {
                color: `${theme.palette.text.neutral.neutral400} !important`,
                fontWeight: 400,
            },
        },
    },
    selectContainer: {
        marginTop: "-26px"
    },
    inStock: {
        background: theme.palette.background.success.success50,
        border: `1px solid ${theme.palette.border.success.success100}`,
        color: theme.palette.text.success.success600,
    },
    webContainer: {
        display: "grid",
        padding: "0px 70px 108px 70px"
    },
    mobileContainer: {
        display: "none",
    },
    termsAndConditionsWidth: {
        width: "90%"
    },
    inputContainer: {
        display: "flex",
        columnGap: "24px"
    },
    warehouse: {
        border: `1px solid ${theme.palette.border.neutral.neutral200}`,
        background: "white",
    },
    inputField: {
        // marginTop: "-10px"
    },
    location: {
        border: `1px solid ${theme.palette.border.neutral.neutral200}`,
        background: "none !important",
    },
    carouselContainer: {
        "& .react-multiple-carousel__arrow--right": {
            right: "0 !important"
        },
        "& .react-multiple-carousel__arrow--left": {
            left: "0 !important"
        }
    },
    selectionWrapper: {
        borderRadius: "10px",
        border: `1px solid ${theme.palette.border.primary.primary800}`,
        color: theme.palette.text.primary.primary800,
    },
    incrementDecrement: {
        background: "white",
        color: theme.palette.text.primary.primary700,
    },
    minimumQuantity: {
        color: theme.palette.text.neutral.neutral500,
    },
    count: {
        background: 'white',
        color: theme.palette.text.neutral.neutral800,
        border: `1px solid ${theme.palette.border.neutral.neutral200}`,
        fontSize: "16px",
        fontWeight: 500,
    },
    selectedUpc: {
        color: theme.palette.text.neutral.neutral800,
        border: `1px solid ${theme.palette.border.neutral.neutral200}`,
        background: "white",
    },
    input: {
        border: "1px solid",
        borderColor: theme.palette.border.neutral.neutral200,
        fontSize: "16px",
        fontWeight: 500,
        borderRadius: 8,
        color: theme.palette.text.primary.primary900,
        "&:hover": {
            borderColor: theme.palette.border.primary.primary500,
            borderWidth: 2,
            outline: "none",
        },
        "& .MuiOutlinedInput-notchedOutline": {
            outline: "none",
            borderWidth: 0,
            borderColor: theme.palette.border.neutral.neutral200,
        },
        "&:focus-within": {
            outline: "none",
            borderColor: `${theme.palette.border.primary.primary500} !important`,
            borderWidth: "2px !important",
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            outline: "none",
            borderWidth: 0,
            borderColor: `${theme.palette.border.primary.primary500} !important`,
        },
        "& .MuiInputBase-input": {
            padding: "16px !important"
        },
    },
    label: {
        color: theme.palette.text.neutral.neutral600,
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "20px"
    },
    borderBottom: {
        borderBottom: `1px solid ${theme.palette.border.neutral.neutral100}`,
    },
    "@media (max-width: 767px)": {
        webContainer: {
            display: "none"
        },
        mobileContainer: {
            display: "grid"
        },
        catalogueName: {
            color: theme.palette.text.primary.primary900,
        },
        termsAndConditionsWidth: {
            width: "auto"
        },
        inputContainer: {
            display: "grid",
            rowGap: "12px"
        },
        carouselContainer: {
            "& .react-multiple-carousel__arrow--right": {
                right: "0 !important"
            },
            "& .react-multiple-carousel__arrow--left": {
                left: "0 !important"
            },
            "& .react-multi-carousel-dot-list": {
                top: "225px"
            },
            maxWidth: "287px",
            boxShadow: `rgba(0, 0, 0, 0.35) 0px 5px 20px`,
        },
        value: {
            fontSize: "16px",
        },
        selectContainer: {
            marginTop: "-10px"
        },
        label: {
            color: theme.palette.text.neutral.neutral600,
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "16.1px"
        },
    },
}));

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1280 },
        items: 4,
        partialVisibilityGutter: 10
    },
    tablet: {
        breakpoint: { max: 1280, min: 980 },
        items: 3,
        partialVisibilityGutter: 10
    },
    mobile: {
        breakpoint: { max: 980, min: 670 },
        items: 2,
        partialVisibilityGutter: 10
    },
    "sm-mobile": {
        breakpoint: { max: 670, min: 0 },
        items: 1
    }
};

const MultipleUpcCatalogueDetails: React.FC = () => {

    const classes = useStyles();
    const navigate = useNavigate();
    const cartContextData = useContext(CartContext);

    const { user, syncAuthDialogActive } = useAuthenticatedUser();

    const cartService = useCartService();
    const catalogueService = useCatalogueService();
    const productUpcBrandMappingService = useProductUpcBrandMappingService();

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const [catalogueId, setCatalogueIdTo] = useState<number | null>(null);
    const [catalogue, setCatalogueTo] = useState<ICatalogue | null>(null);
    const [catalogueUpc, setCatalogueUpcTo] = useState<ICatalogueUpc[] | null>(null);
    const [packagingTypes, setPackagingTypesTo] = useState<Record<string, string>>({});
    const [currentCartItem, setCurrentCartItemTo] = useState<ICurrentCartItem>({
        attributes: {},
        upc: null,
        warehouse: null,
        packaging: "WITHOUT_PACKAGING",
        quantity: "",
        secondarySkuId: null,
        paymentType: PAYMENT_METHODS.CASH_PAYMENT
    });
    const [moq, setMOQ] = useState<string>('');
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const [similarBrands, setSimilarBrands] = useState<ISimilarBrands[]>([]);
    const [catalogueVendors, setCatalogueVendors] = useState<any[]>([]);
    const [selectedVendors, setSelectedVendors] = useState<{ vendorId: number, companyName: string, isTrusted: boolean, offerSalePrice: number }>({ vendorId: catalogueVendors[0]?.vendorId, companyName: catalogueVendors[0]?.companyName, isTrusted: catalogueVendors[0]?.isVerifiedTrusted, offerSalePrice: catalogueVendors[0]?.offerSalePrice })
    const [price, setPrice] = useState<{ minPrice: number | null, maxPrice: number | null }>({ minPrice: null, maxPrice: null });
    const [showPopUp, setShowPopUp] = useState(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    
    const getSimilarBrands = async () => {
        let res = await productUpcBrandMappingService.fetchSimilarCataloguesByBrandUpcId(currentCartItem.upc?.id as number);
        if (res.status === HTTP_STATUS.OK) {
            setSimilarBrands(res.data.data)
        }
    }

    const [valueAddedService, setValueAddedServiceTo] = useState<IValueAddedService>({
        shearing: [],
        slitting: [],
    })
    const [paymentType, setPaymentType] = useState<string | null>(PAYMENT_METHODS.CASH_PAYMENT);
    const [productCombination, setProductCombination] = useState<boolean | null>(null);

    const handleWarehouseChange = (e: any) => {
        setCurrentCartItemTo({
            ...currentCartItem,
            warehouse: e.target.value,
        });
    };

    const handleAddToCart = async () => {
        if (!user) {
            syncAuthDialogActive()
            return;
        }
        const requiredQuantity = parseFloat(currentCartItem.quantity);
        if (!REGEX.FLOAT.test(currentCartItem.quantity?.toString())) {
            return;
        }
        if (catalogue && currentCartItem && currentCartItem.upc && currentCartItem.warehouse && currentCartItem.paymentType) {
            let cartRequestBody: ICartRequestBody = {
                catalogueName: catalogue.name,
                upcId: currentCartItem.upc.id,
                warehouseId: currentCartItem.warehouse?.id,
                quantity: requiredQuantity,
                uom: currentCartItem.warehouse?.uom || "MT",
                packagingType: currentCartItem.packaging,
                secondarySkuId: null,
                valueAddedServices: valueAddedService,
                paymentType: currentCartItem.paymentType
            }
            await cartService.saveToCart(cartRequestBody);
            await cartContextData.syncCart(CART_LIFE_CYCLE.ADD_PRODUCT);
            setShowPopUp(true);
        }
    }

    const handleGoToCart = () => {
        setShowPopUp(false);
        navigate(CUSTOMER_ROUTES.CART)
    }

    const handleContinueShopping = () => {
        setShowPopUp(false);
    }

    const handlePaymentTypeChange = (paymentTypeMethod: string) => {
        setCurrentCartItemTo({
            ...currentCartItem,
            paymentType: paymentTypeMethod
        });
        setPaymentType(paymentTypeMethod);
    };

    const handleQuantityChange = (quantity: string) => {
        if (REGEX.QUANTITY.test(quantity) || quantity === '') {
            setCurrentCartItemTo({
                ...currentCartItem,
                quantity
            });
        }
    };

    const convertWarehousesToOptions = (warehouses: any): any => {
        return warehouses.map((warehouse: any) => ({
            value: warehouse,
            label: warehouse.name,
        }));
    };

    const findMOQ = (attributes: IAttribute[]) => {
        return attributes?.find((attribute: IAttribute) => attribute.name === "MOQ")?.attributeOptions ?? 1;
    };

    const handleRfqForm = () => {
        setDialogOpen(true);
    }

    const HandleVendorStoreFront = (id: number) => () => {
        navigate(`${CUSTOMER_ROUTES.STORE_FRONT}/${id}?tab=2`)
    }

    const handleVendorCatalogue = (warehouse: WarehouseCatalogue) => {
        setSelectedVendors({ vendorId: warehouse?.vendorId, companyName: warehouse?.companyName, isTrusted: warehouse.isVerifiedTrusted, offerSalePrice: warehouse.offerSalePrice })
        document.getElementById("main")?.scroll(0, 0);
        setCurrentCartItemTo(prevCartItem => ({
            ...prevCartItem,
            packaging: warehouse?.packagingType,
            warehouse: { id: warehouse?.warehouseId, uom: "", warehouseId: warehouse?.warehouseId, name: warehouse?.warehouseName, warehouseAddress: warehouse?.warehouseName },
            deliveryTerm: warehouse?.deliveryTerm,

        }));
        setPrice({ minPrice: warehouse?.offerSalePrice, maxPrice: warehouse?.offerSalePrice })
        setMOQ(warehouse?.minimumOrderQuantity.toString())

    };

    const scrollToLabel = () => {
        const label = document.getElementById('scrollToLabel');
        if (label) {
            label.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
        const label2 = document.getElementById('scroll-mobile');
        if (label2) {
            label2.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    const alterQuantity = (alterQuantityUnit: number) => () => {
        const currentQuantity = (parseFloat(currentCartItem.quantity) || 0).toString();
        handleQuantityChange(String(parseFloat(Math.max(parseFloat(currentQuantity) + alterQuantityUnit, parseFloat(moq)).toFixed(2))));
    };

    const increaseQuantity = () => {
        if (currentCartItem.warehouse) {
            alterQuantity(1)();
        }
    };

    const decreaseQuantity = () => {
        if (currentCartItem.warehouse) {
            alterQuantity(-1)();
        }
    };

    useEffect(() => {
        const catalogueIdString = queryParams.get('catalogueId');
        if (catalogueIdString !== null) {
            const parsedCatalogueId = parseInt(catalogueIdString, 10);
            if (!isNaN(parsedCatalogueId)) {
                setCatalogueIdTo(parsedCatalogueId);
            }
        }
    }, [location.search])

    useEffect(() => {
        if (catalogueId) {
            const vendorCatalogueRequestBodyDTO = {
                catalogueType: CATALOGUE_TYPE_STATES.MULTIPLE_PRODUCT,
                category: CLASS_STATES.STANDARD
            }
            catalogueService.getVendorCatalogueByIdV2(catalogueId, vendorCatalogueRequestBodyDTO)
                .then((response) => {
                    if (response.status === HTTP_STATUS.OK) {
                        const catalogueResponse = response.data.data
                            if (catalogueResponse) {
                                catalogueResponse.catalogueImages = catalogueResponse.catalogueImages ? catalogueResponse.catalogueImages : [];
                            }
                            setCatalogueTo(catalogueResponse);
                            setPrice({ minPrice: catalogueResponse?.minimumPrice, maxPrice: catalogueResponse?.maximumPrice })          
                                      }
                })
                .catch((error) => {
                    console.error('Error', error);
                    setCatalogueTo(null);
                });
                setIsLoading(false);
        }
    }, [catalogueId]);

    useEffect(() => {
        setCatalogueTo(null);
        setPackagingTypesTo({});
        setCurrentCartItemTo({
            packaging: "",
            quantity: "",
            attributes: {},
            upc: null,
            warehouse: null,
            secondarySkuId: null,
            paymentType: PAYMENT_METHODS.CASH_PAYMENT
        });
    }, [catalogueId]);

    useEffect(() => {
        if (catalogueId) {
            catalogueService.getAllUpcsByCatalogueIdV2(catalogueId)
                .then((response) => {
                    // setMOQ(response?.data?.data[0].minimumOrderQuantity)
                    setCatalogueUpcTo(response.status === HTTP_STATUS.OK && response.data.data ? response.data.data : null);
                    // setCurrentCartItemTo(prevCartItem => ({
                    //     ...prevCartItem,
                    // }));
                },)
                .catch((error) => {
                    console.error('Error', error);
                    setCatalogueUpcTo(null);
                })
        }
    }, [catalogueId]);

    useEffect(() => {
        if (currentCartItem.upc?.id) {
            catalogueService.getVendorsByProductId(currentCartItem.upc?.id)
                .then((response) => {
                    setCatalogueVendors(response.status === HTTP_STATUS.OK && response.data.data.content ? response.data.data.content : null);
                },)
                .catch((error) => {
                    console.error('Error', error);
                    setCatalogueVendors([]);
                })
        }
    }, [currentCartItem.upc?.id]);

    useEffect(() => {
            if (catalogueVendors.length > 0) {
                const firstVendor = catalogueVendors[0];
                setSelectedVendors({ vendorId: firstVendor?.vendorId, companyName: firstVendor?.companyName, isTrusted: firstVendor?.isVerifiedTrusted, offerSalePrice: firstVendor?.offerSalePrice })
                // setCurrentCartItemTo(prevCartItem => ({
                //     ...prevCartItem,
                //     packaging: firstVendor?.packagingType,
                //     warehouse: { id: firstVendor?.warehouseId, uom: "MT", warehouseId: firstVendor?.warehouseId, name: firstVendor?.warehouseName, warehouseAddress: firstVendor?.warehouseName },
                //     deliveryTerm: firstVendor?.deliveryTerm,
                // }));
                // setMOQ(firstVendor?.minimumOrderQuantity?.toString())
            }
        }, [catalogueVendors])

    useEffect(() => {
        const minimumOrderQuantity = currentCartItem?.upc?.minimumOrderQuantity ?? "";
        setMOQ(minimumOrderQuantity.toString());
    }, [currentCartItem]);

    useEffect(() => {
        if(currentCartItem.upc?.id)
        getSimilarBrands();
    }, [currentCartItem.upc?.id]);

    return (
        <div>
            <div className={`${classes.webContainer} max-container m-auto`}>
                <div className="flex justify-between mt-6">
                    <Breadcrumbs crumbs={[
                        {
                            link: makeRoute(CUSTOMER_ROUTES.CATALOGUE_LISTING, { query: { classes: catalogue?.classType ?? CLASS_STATES.STANDARD, catalogueTypes: CATALOGUE_TYPE_STATES.MAKE_TO_ORDER } }),
                            label: "Product Listing Page"

                        },
                        {
                            link: "",
                            label: "Product Description Page"
                        }
                    ]} />
                    <div className="flex justify-end items-end">
                        <ContactUs />
                    </div>
                </div>
                <div className='flex gap-x-6'>
                    <div className={`grid mt-6 flex-1 ${classes.leftContainer}`}>
                        <div className="w-full flex gap-x-6">
                            <div className={`grid gap-y-4 h-fit`}>
                                <CatalogueImageTemplate isSharable={false} catalogueImages={catalogue?.catalogueImages} isRepresentationImage={catalogue?.isRepresentationImage} isLoading={isLoading}/>
                                <TermsAndConditionsCarousel />
                                <AddToFavouriteTemplate id={catalogue?.id} catalogueType={CLASS_STATES.STANDARD} />
                            </div>
                            <div className={`${classes.middleChild} grid gap-y-6 h-fit mt-0 m-auto`}>
                                <div>
                                <div className={`${classes.catalogueName} text-2xl font-semibold`}>{catalogue?.name}</div>
                                {/* TODO: Will uncomment after getting data from backend */}
                                {/* {DEALS_OF_THE_DAY_DATA.length && <DealsOfTheDayTemplate />} */}
                                    {
                                        catalogueVendors.length > 0 &&
                                        <div className='flex justify-between mt-3'>
                                            <div className={`${classes.brand} my-auto text-xs font-semibold cursor-pointer`} onClick={HandleVendorStoreFront(selectedVendors?.vendorId)}>Visit {selectedVendors?.companyName} Store</div>

                                        </div>
                                    }
                                {catalogue?.userType == 'ADMIN' && catalogue?.isVerifiedTrusted== true ? (
                                    <div className='flex gap-1 justify-end'>
                                        <img src={sbTrusted} alt="Trusted" className='w-16' />
                                        <Tooltip title={
                                            <div className='w-60'>
                                                Source from Manufacturer & Distributors Network
                                                <br />
                                                Mill Test Certification
                                            </div>}>
                                            <span><HiOutlineInformationCircle className="text-xl cursor-pointer" /></span>
                                        </Tooltip>
                                    </div>
                                )
                                    : catalogue?.userType === 'VENDOR' && catalogue?.isVerifiedTrusted === true ?(
                                        <div className='flex gap-1 justify-end'>
                                            <img src={sellerTrusted} alt="Trusted" className='w-16' />
                                            <Tooltip title={
                                                <div className='w-60'>
                                                    Authorized dealers and distributors of reputed brands
                                                    <br />
                                                    Legal Compliance entity
                                                </div>}>
                                                <span><HiOutlineInformationCircle className="text-xl cursor-pointer" /></span>
                                            </Tooltip>
                                        </div>
                                ): null}
                                </div>
                                <div>
                                    <AttributeSelectionTemplate
                                        catalogue={catalogue}
                                        catalogueUpc={catalogueUpc}
                                        currentCartItem={currentCartItem}
                                        currentAttributes={currentCartItem.attributes}
                                        setCurrentCartItemTo={setCurrentCartItemTo}
                                        setProductCombination={setProductCombination}
                                    />
                                </div>
                                {catalogue && currentCartItem?.upc && Object.keys(currentCartItem?.upc).length > 0 && (

                                    <div className="flex gap-x-3">
                                        <div className="flex gap-x-3">
                                            <div className="grid gap-y-1" style={{ minWidth: "165px" }}>
                                                <TextFieldV2
                                                    label="Packaging"
                                                    value={snakeCaseToTitleCase(catalogue?.packagingTypes?.[0] || '')}
                                                    onChange={(event) => {
                                                        setCurrentCartItemTo({
                                                            ...currentCartItem,
                                                            packaging: event.target.value,
                                                        });
                                                    }}
                                                    fullWidth
                                                    InputProps={{ readOnly: true }}
                                                    disabled
                                                />
                                            </div>
                                            <TextFieldV2
                                                label="Location"
                                                value={snakeCaseToTitleCase(currentCartItem?.warehouse?.name)}
                                                onChange={(event) => {
                                                    setCurrentCartItemTo({
                                                        ...currentCartItem,
                                                        warehouse: event.target.value,
                                                    });
                                                }}
                                                fullWidth
                                                InputProps={{ readOnly: true }}
                                                disabled
                                            />
                                            {currentCartItem?.upc?.warehouseDetails?.length > 0 && (<div className="grid gap-y-1" style={{ minWidth: "130px" }}>
                                                <TextFieldV2
                                                    label="Delivery Terms"
                                                    value={"Within 3 Days"}
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        readOnly: true,
                                                    }}
                                                    disabled
                                                />
                                            </div>)}
                                        </div>

                                        {currentCartItem?.upc?.warehouseDetails?.length > 0 && <div className={`${classes.inStock} flex justify-end self-end py-1 px-2 text-xs font-medium h-fit whitespace-nowrap rounded`}>In Stock</div>}
                                    </div>
                                )}
                                {currentCartItem?.upc && <div className="flex gap-3">
                                    <Button variant="tertiaryContained" label="Tailor Requirements" onClick={handleRfqForm} />
                                    {/* <RequestForQuotationModalTemplate dialogOpen={dialogOpen} setDialogOpen={setDialogOpen} /> */}
                                </div>}
                                {currentCartItem?.upc && similarBrands.length > 1 && <div className="flex gap-3">
                                    <Button variant="tertiaryContained" label="Explore other Brands" onClick={scrollToLabel} />
                                </div>}

                            </div>
                        </div>
                        <div className={`py-10 ${classes.borderBottom}`}>
                            <SelectCustomizationTemplate
                                catalogue={catalogue}
                                secondaryCatalogue={null}
                                quantity={parseFloat(currentCartItem.quantity)}
                                selectedUpc={currentCartItem?.upc}
                                valueAddedService={valueAddedService}
                                setValueAddedServiceTo={setValueAddedServiceTo}
                            />
                        </div>
                        <div className={`py-10 ${classes.borderBottom}`}><PaymentDetailsTemplate paymentType={paymentType} handlePaymentTypeChange={handlePaymentTypeChange} /></div>
                        <div className={`py-10 ${classes.borderBottom}`}><DeliveryDetailsTemplate /></div>
                        {currentCartItem && currentCartItem?.upc && Object.keys(currentCartItem?.upc).length > 0 && <div className={`py-10 ${classes.borderBottom}`}><ProductUpcTemplate upc={currentCartItem?.upc} /></div>}
                        {currentCartItem.upc !== null && <div className={`py-10 ${classes.borderBottom}`}><SimilarBrands currentCartItem={currentCartItem} catalogue={catalogue} catalogueType={CATALOGUE_TYPE_STATES.MAKE_TO_ORDER} similarBrands={similarBrands} /></div>}
                        <div className={`py-10 ${classes.borderBottom}`}><FAQTemplate /></div>
                        <div className={`py-10 ${classes.borderBottom}`}><TermsConditions /></div>
                        <div className={`pt-10`} id="scrollToDisclaimer"><Disclaimer /></div>
                    </div>
                    <div className={`${classes.rightContainer}`}>
                        <div className={`pt-6 sticky top-[150px] grid`}>
                            {catalogue && <PriceDetailsTemplate vendorName={selectedVendors?.companyName} handleAddToCart={handleAddToCart} paymentType={currentCartItem.paymentType} moq={moq} handleQuantityChange={handleQuantityChange} minimumPrice={catalogue.minimumPrice} maximumPrice={catalogue.maximumPrice} quantity={currentCartItem.quantity} gst={18} selectedUpc={currentCartItem?.upc} warehouse={currentCartItem.warehouse} uom={(currentCartItem?.warehouse?.uom)?.toString() || ""} catalogueType={CATALOGUE_TYPE_STATES.MAKE_TO_ORDER} catalogue={catalogue} productCombination={productCombination} />}
                        </div>
                        {catalogueVendors.length > 0 &&
                            <CatalogueVendorsTemplate catalogueVendors={catalogueVendors} handleVendorCatalogue={handleVendorCatalogue} />
                        }
                        {showPopUp &&
                            <SuccessFailurePopup
                                setShowPopup={setShowPopUp}
                                variant={"Add"}
                                heading="Your Product has Been Added!"
                                description="Your product has been added to the cart!"
                                button1={{
                                    text: "Go to Cart",
                                    variant: "tertiaryContained",
                                    size: "large",
                                    onClick: handleGoToCart,
                                }}
                                button2={{
                                    text: "Continue Shopping",
                                    variant: "secondaryContained",
                                    size: "large",
                                    onClick: handleContinueShopping,
                                }}
                            />
                        }
                    </div>
                </div>
            </div>
            <RequestForQuotationModalTemplate dialogOpen={dialogOpen} setDialogOpen={setDialogOpen} />
            {/* Mobile PDP Page */}
            <div className={`gap-y-4 ${classes.mobileContainer}`}>
                <div className={`grid gap-y-4 px-3`}>
                    <div className={`grid gap-y-3`}>
                        <div className="">
                            <Breadcrumbs crumbs={[
                                {
                                    link: makeRoute(CUSTOMER_ROUTES.CATALOGUE_LISTING, { query: { classes: catalogue?.classType ?? CLASS_STATES.STANDARD, catalogueTypes: CATALOGUE_TYPE_STATES.MULTIPLE_PRODUCT } }),
                                    label: catalogue?.classType === CLASS_STATES.STANDARD ? snakeCaseToTitleCase(CLASS_STATES.STANDARD) : snakeCaseToTitleCase(CLASS_STATES.NONSTANDARD)
                                },
                                {
                                    link: "",
                                    label: "View"
                                }
                            ]} />
                        </div>
                        <div className="w-full grid gap-y-3">
                            <div className={`${classes.catalogueName} text-lg font-semibold`}>{catalogue?.name}</div>
                            <CatalogueImageTemplate isSharable={false} catalogueImages={catalogue?.catalogueImages} isRepresentationImage={catalogue?.isRepresentationImage} isLoading={isLoading}/>
                        </div>
                    </div>
                    <AttributeSelectionTemplate
                        catalogue={catalogue}
                        catalogueUpc={catalogueUpc}
                        currentCartItem={currentCartItem}
                        currentAttributes={currentCartItem.attributes}
                        setCurrentCartItemTo={setCurrentCartItemTo}
                        setProductCombination={setProductCombination}
                    />
                    {currentCartItem?.upc && Object.keys(currentCartItem?.upc).length > 0 && (
                        <>
                            <div className='border-t'> </div>
                            <div className={`${classes.inputContainer}`}>
                                <div className='flex justify-between gap-x-3'>
                                    <div className='w-full'>
                                        <TextFieldV2
                                            label="Packaging"
                                            value={snakeCaseToTitleCase(currentCartItem.packaging)}
                                            onChange={(event) => {
                                                setCurrentCartItemTo({
                                                    ...currentCartItem,
                                                    packaging: event.target.value,
                                                });
                                            }}
                                            fullWidth
                                            disabled={true}
                                        />
                                    </div>

                                    {currentCartItem?.upc?.warehouseDetails?.length > 0 && (<div className="grid gap-y-1 w-full">
                                        <div className={classes.label}>
                                            Location
                                        </div>
                                        <Select
                                            label=""
                                            value={currentCartItem.warehouse}
                                            fullWidth
                                            onChange={(e: any) => handleWarehouseChange(e)}
                                            name="warehouse"
                                            id="warehouse"
                                            options={convertWarehousesToOptions(currentCartItem?.upc?.warehouseDetails || [])}
                                        />
                                    </div>)}
                                </div>

                                {currentCartItem?.upc?.warehouseDetails?.length > 0 && (
                                    <TextFieldV2
                                        fullWidth
                                        label="Delivery Terms"
                                        value={"Within 3 Days"}
                                        disabled={true}
                                    />
                                )}

                                {currentCartItem?.upc?.warehouseDetails?.length > 0 && <div className={`${classes.inStock} py-1 px-2 text-xs font-medium w-fit rounded`}>
                                    In Stock
                                </div>}

                                {currentCartItem?.upc && <div className="grid gap-y-3">
                                    <div className={`${classes.selectionWrapper} text-sm p-2.5 px-auto font-medium cursor-pointer w-full text-center`} onClick={handleRfqForm}>
                                        Need Custom Specs.?Click Here
                                    </div>
                                    <div className={`${classes.selectionWrapper} text-sm py-2.5 px-auto font-medium cursor-pointer w-full text-center`} onClick={scrollToLabel}>
                                        Explore other Brands
                                    </div>
                                </div>}
                            </div>
                        </>
                    )}

                    <div className='border-t'> </div>
                    <div className="grid gap-y-3">
                        <div className={`${classes.value} font-medium`}>Enter Quantity (MT)</div>
                        <div className='flex gap-x-4'>
                            <div className={`w-8 h-8 my-auto flex rounded justify-center items-center  ${classes.incrementDecrement}`} onClick={decreaseQuantity} style={{ cursor: (currentCartItem.warehouse && (parseFloat(currentCartItem.quantity) > parseFloat(moq))) ? 'pointer' : 'not-allowed' }}><RemoveIcon /></div>
                            <input type="text" className={`w-24 h-9 rounded-lg flex text-center justify-center items-center gap-2  ${moq === "" ? classes.selectedUpc : classes.count}`} value={moq === "" ? "" : currentCartItem.quantity} disabled={moq === "" || !currentCartItem.warehouse} onChange={event => handleQuantityChange(event.target.value)} />
                            <div className={`w-8 h-8 my-auto flex rounded justify-center items-center  ${classes.incrementDecrement}`} onClick={increaseQuantity} style={{ cursor: currentCartItem.warehouse ? 'pointer' : 'not-allowed' }}><AddIcon /></div>
                        </div>
                        {!(moq === "") && <div className={`text-xs font-semibold ${classes.minimumQuantity}`}>Minimum Order Quantity : {moq}{UNIT.UOM} </div>}
                    </div>
                    <div className='border-t'> </div>
                    <div className={``}>
                        <SelectCustomizationTemplate
                            catalogue={catalogue}
                            secondaryCatalogue={null}
                            quantity={parseFloat(currentCartItem.quantity)}
                            selectedUpc={currentCartItem?.upc}
                            valueAddedService={valueAddedService}
                            setValueAddedServiceTo={setValueAddedServiceTo}
                        />
                    </div>
                    <div className='border-t'> </div>
                    <div className={``}><PaymentDetailsTemplate paymentType={paymentType} handlePaymentTypeChange={handlePaymentTypeChange} /></div>
                    <div className='border-t'> </div>
                    <div className={``}><DeliveryDetailsTemplate /></div>
                    <div className='border-t'> </div>
                    <div className={``}>{currentCartItem?.upc && currentCartItem && Object.keys(currentCartItem?.upc).length > 0 && <ProductUpcTemplate upc={currentCartItem?.upc} />}</div>
                    <div className='border-t'> </div>
                    {currentCartItem.upc !== null && <div className={``}><SimilarBrands currentCartItem={currentCartItem} catalogue={catalogue} catalogueType={CATALOGUE_TYPE_STATES.MULTIPLE_PRODUCT} similarBrands={similarBrands} /></div>}
                    <div className='border-t'> </div>
                    <div className={``}><FAQTemplate /></div>
                    <div className='border-t'> </div>
                    <div className={``}><TermsConditionsTemplate /></div>
                    <div className='border-t'> </div>
                    <div className={``} id="scrollToDisclaimer"><Disclaimer /></div>
                </div>

                <div className="w-full sticky bottom-24 z-50">
                    {catalogue && <PriceDetailsTemplate handleAddToCart={handleAddToCart} paymentType={currentCartItem.paymentType} moq={moq} handleQuantityChange={handleQuantityChange} minimumPrice={catalogue.minimumPrice} maximumPrice={catalogue.maximumPrice} quantity={currentCartItem.quantity} gst={18} selectedUpc={currentCartItem?.upc} warehouse={currentCartItem.warehouse} uom={(currentCartItem?.warehouse?.uom)?.toString() || ""} catalogueType={CATALOGUE_TYPE_STATES.MULTIPLE_PRODUCT} catalogue={catalogue} productCombination={productCombination} />}
                </div>

            </div>
        </div>
    );
}

export default MultipleUpcCatalogueDetails;