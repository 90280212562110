import React from 'react';
import { createUseStyles } from 'react-jss';
import RequestDemoTemplate from '../../template/Home/RequestDemo/RequestDemo.template';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Button } from '@mui/joy';
import source_card_icon1 from '../../../assets/icons/source_card_icon1.jpg';
import source_card_icon2 from '../../../assets/icons/source_card_icon2.jpg';
import source_card_icon3 from '../../../assets/icons/source_card_icon3.jpg';
import source_card_icon4 from '../../../assets/icons/source_card_icon4.jpg';
import auctionSecondaryBanner from '../../../assets/images/auctionSecondaryBanner.png';
import digiBanner from '../../../assets/images/digiBanner.png';
import roudTickIcon from '../../../assets/icons/roudTickIcon.jpg';
import digiCategories from '../../../assets/images/digiCategories.png';
import digiBidding from '../../../assets/images/digiBidding.png';
import digiExpert from '../../../assets/images/digiExpert.png';
import DigitalSecondaryBanner from '../../../assets/images/digitalSecondaryBanner.png';
//Bykamal
// import DigiCatMobileBanner from '../../../assets/images/DigiCatMobileBanner.jpg';
import DotImg from "../../../assets/images/dot-shape.png";
import BulletImg from "../../../assets/images/bulletIcon.svg";
import DigiStoreMobileBanner from "../../../assets/images/DigiStoreMobileBanner.jpg";
import Section1 from '../../../assets/images/DigiStore-All-Steel-Categories-in-One-Place.jpg';
import Section2 from '../../../assets/images/DigiStore-Sell-Through-Competitive-Bidding.jpg';
import Section3 from '../../../assets/images/DigiStore-Highlight-Your-Expertise.jpg';
import Section4 from '../../../assets/images/DigiStore-Your-Identity-Amplified.jpg';
import Section5 from '../../../assets/images/DigiStore-Stay-Connected-with-Buyers.jpg';
import Section6 from '../../../assets/images/DigiStore-Expand-Reach-Instantly.jpg';
const useStyles = createUseStyles((theme: any) => ({
    container: {
       
    },
    bannerContainer: {
       
        backgroundImage: `url(${digiBanner})`,
        backgroundSize: 'cover', 
    },
    bannerSellContainer: {
        "& h1": {
            fontSize: '56px',
            lineHeight: '66px',
            color: theme.palette.text.warning.warning400,
        },
        "& span": {
            color: '#fff',
            fontSize: '24px',
            lineHeight: '28px',
        },
        "& p": {
            color: '#fff',
            lineHeight: '66px',
            fontSize: '56px',
        },
    },
    intoContainer: {
        backgroundColor: theme.palette.background.neutral.neutral50,
    },
    text: {
        fontSize: '54px',
        color: "#000",
        lineHeight: '66px',
    },
    decContainer: {
        width: '790px',
        "& p": {
            color: "#000",
            lineHeight: '26px',
        },
    },
    headingText: {
        fontSize: '48px !important',
        "& span": {
            color: `${theme.palette.text.primary.primary500} !important`,
        },
    },
    featuresContainer: {
        "& > p": {
            color: theme.palette.text.neutral.neutral600,
        },
        // "& span": {
        //     fontSize: '40px',
        //     lineHeight: '48px',
        //     color: theme.palette.text.primary.primary700,
        // },
        "& h4": {
            color: theme.palette.text.primary.primary500,
            fontSize: '48px',
            "& span": {
                fontWeight: '600',
                color: theme.palette.text.neutral.neutral800,
            },
        },
        "& h6": {
            color: theme.palette.text.neutral.neutral900,
            lineHeight: '28px'
        },
    },
    featureInfo: {
        "& h4": {
            color: theme.palette.text.neutral.neutral900,
        },
        "& > p": {
            color: theme.palette.text.neutral.neutral800,
            lineHeight: '34px',
            fontSize: '28px',
        },
    },
    featuresText: {
        width: '651px',
        "& h5": {
            color: '#000',
            fontSize: '22px',
            lineHeight: '26px',
        },
        "& p": {
            color: theme.palette.text.neutral.neutral800,
            lineHeight: '26px',
        },
    },

    bannerBtn: {
        background: theme.palette.background.primary.primary500,
        padding: '10px 20px',
        width: '228px',
        color: '#fff',
        margin: '0 auto',
    },
    btnArrow: {
        border: '1px solid #fff',
        borderRadius: '100%',
    },
    sectionHeading: {
        color: "#000",
        fontSize: '54px !important',
        lineHeight: '66px',
        "& span": {
            color: theme.palette.text.primary.primary500,
        },

    },
    sectionSubHeading: {
        color: theme.palette.text.neutral.neutral600,
    },
    card: {
        border: `1px solid ${theme.palette.border.neutral.neutral200}`,
        background: '#F8F8F8',
        padding: '20px 15px',
        width: '23%',
        "& h4": {
            color: '#000',
            fontSize: '22px',
            lineHeight: '26px',
        },
        "& p": {
            color: theme.palette.text.neutral.neutral800,
            fontSize: '16px',
            lineHeight: '26px',
        },
    },
    cardIconContainer: {
        border: `1px solid ${theme.palette.border.neutral.neutral200}`,
        background: '#fff',
    },
    secondaryBanner: {
        width: '100%',
        height: '480px',
        backgroundImage: `url(${DigitalSecondaryBanner})`,
        backgroundSize: 'cover',
        position: 'relative',
    },
    secondaryContainer: {
        '& h2': {
            color: theme.palette.text.warning.warning400,
            lineHeight: '48px',
            fontSize: '40px',

        },
        "& p": {
            color: theme.palette.text.primary.primary50,
            lineHeight: '28px',
            fontSize: '24px',
        },
    },
    btn3: {
        background: '#fff',
        color: '#fff',
        fontSize: '18px',
        fontWeight: '500',
        padding: '10px 20px',
        lineHeight: '22px',
    },
    btnArrow2: {
        border: '1px solid transparent',
        background: theme.palette.background.primary.primary500,
        borderRadius: '50%',
        height: '32px',
        width: '32px',
    },
    btnText: {
        color: theme.palette.text.primary.primary500,
        fontSize: '20px',
    },
    discover1: {
        border: `1px solid ${theme.palette.border.secondary.secondary200}`,
        background: theme.palette.background.secondary.secondary50,
        width: '580px',
        height: '493px',
    },
    discover2: {
        background: theme.palette.background.primary.primary50,
        border: `1px solid ${theme.palette.border.primary.primary200}`,
        width: '580px',
        marginTop: '130px',
        height: '493px',
    },
    discover3: {
        background: theme.palette.background.warning.warning50,
        border: `1px solid ${theme.palette.border.warning.warning200}`,
        width: '580px',
        marginTop: '-70px',
        height: '527px',
    },
    discover4: {
        background: theme.palette.background.success.success50,
        border: `1px solid ${theme.palette.border.success.success200}`,
        width: '580px',
        marginTop: '70px',
        height: '516px',
    },
    discover5: {
        background: theme.palette.background.complementary.complementary50,
        border: `1px solid ${theme.palette.border.complementary.complementary200}`,
        width: '580px',
        marginTop: '-70px',
        height: '515px',
    },
    discover6: {
        backgroundColor: '#FFEFE6',
        border: '1px solid #FEC09A',
        width: '580px',
        marginTop: '70px',
        height: '526px',
    },
    discoverCard01: {
        border: `1px solid ${theme.palette.border.secondary.secondary200}`,
    },
    discoverCard02: {
        border: `1px solid ${theme.palette.border.primary.primary200}`,
    },
    discoverCard03: {
        border: `1px solid ${theme.palette.border.warning.warning400}`,
    },
    discoverCard04: {
        border: `1px solid ${theme.palette.border.success.success200}`,
    },
    discoverCard05: {
        border: `1px solid ${theme.palette.border.complementary.complementary400}`,
    },
    discoverCard06: {
        border: '1px solid #FEC09A',
    },
    discoverCard1: {
        width: '500px',
        background: '#fff',
        position: 'relative',
        right: '-40px',
        "& h4": {
            color: '#000',
            fontSize: '22px',
        },
        "& p": {
            color: '#000',
            lineHeight: '26px',
            fontSize: '20px',
            fontWeight: '600',
        },
    },
    discoverText: {
        fontSize: '48px',
        lineHeight: '58px',
    },
    discoverContent: {
        position: 'relative',
    },
    discoverCard2: {
        width: '500px',
        marginRight: '0px',
        background: '#fff',
        "& h4": {
            color: '#000',
            fontSize: '22px',
        },
        "& p": {
            color: '#000',
            lineHeight: '26px',
            fontSize: '20px',
            fontWeight: '600',
        },
    },
    outlineCommon: {
        color: 'transparent',
        fontSize: '150px',
        fontWeight: '800',
        lineHeight: '120px',
    },
    outline: {
        color: theme.palette.text.secondary.secondary400,
    },
    outline2: {
        color: theme.palette.text.primary.primary400,
    },
    outline3: {
        color: theme.palette.text.warning.warning400,
    },
    outline4: {
        color: theme.palette.text.success.success400,
    },
    outline5: {
        color: theme.palette.text.complementary.complementary400,
    },
    outline6: {
        color: ' #FD8235',
    },
    digiCard: {
        width: '32%',
    },
    digiCard1: {
        border: `1px solid ${theme.palette.border.primary.primary200}`,
        overflow: 'hidden',
    },
    digiCard2: {
        border: '1px solid #FEC09A',
        overflow: 'hidden',
    },
    digiMainHeader1: {
        background: theme.palette.background.primary.primary50,
    },
    digiHeader1: {
        border: `1px solid ${theme.palette.border.primary.primary500}`,
        color: theme.palette.text.primary.primary600,
    },
    headerText1: {
        color: theme.palette.text.primary.primary600,
        fontSize: '28px',
    },
    digiMainHeader2: {
        background: "#FEC09A",
    },
    digiHeader2: {
        border: '1px solid #FD6202',
        color: '#CA4F02',
    },
    headerText2: {
        color: '#CA4F02',
        fontSize: '28px',
    },

    digiCard3: {
        border: `1px solid ${theme.palette.border.complementary.complementary200}`,
        overflow: 'hidden',
    },
    digiMainHeader3: {
        background: theme.palette.background.complementary.complementary50,

    },
    digiHeader3: {
        border: `1px solid ${theme.palette.border.complementary.complementary500}`,
        color: theme.palette.text.complementary.complementary600,
    },
    headerText3: {
        color: theme.palette.text.complementary.complementary600,
        fontSize: '28px',
    },
    digiCard4: {
        border: `1px solid ${theme.palette.border.warning.warning200}`,
        overflow: 'hidden',
    },
    digiMainHeader4: {
        background: theme.palette.background.warning.warning50,
    },
    digiHeader4: {
        border: `1px solid ${theme.palette.border.warning.warning500}`,
        color: theme.palette.text.warning.warning600,
    },
    headerText4: {
        color: theme.palette.text.warning.warning600,
        fontSize: '28px',
    },
    digiCard5: {
        border: `1px solid ${theme.palette.border.secondary.secondary200}`,
        overflow: 'hidden',
    },
    digiMainHeader5: {
        background: theme.palette.background.secondary.secondary50,
    },
    digiHeader5: {
        border: `1px solid ${theme.palette.border.secondary.secondary500}`,
        color: theme.palette.text.secondary.secondary600,
    },
    headerText5: {
        color: theme.palette.text.secondary.secondary600,
        fontSize: '28px',
    },
    digiCard6: {
        border: `1px solid ${theme.palette.border.success.success200}`,
        overflow: 'hidden',

    },
    digiMainHeader6: {
        background: theme.palette.background.success.success50,
    },
    digiHeader6: {
        border: `1px solid ${theme.palette.border.success.success500}`,
        color: theme.palette.text.success.success600,
    },
    headerText6: {
        color: theme.palette.text.success.success600,
        fontSize: '28px',
    },
    digiContent: {
        "& p": {
            color: "#000",
            fontSize: "22px",
            lineHeight: '26px',
        },
        "& span": {
            lineHeight: '20px',
        },
    },
    "@media screen and (max-width: 480px)": {
    bannerContainer: {
        backgroundImage: `url(${DigiStoreMobileBanner})`,
        backgroundSize: 'cover', 
    },
},
}));

const DigiStoreTemplate: React.FC = () => {

    const classes = useStyles();
    return (
        <>
         <div className={`${classes.bannerContainer}`}>
        <div className="container mx-auto px-4">
          <div className="insideBannerBx">
            <div className="insideBannerInfo">
                <b>SB DigiStore</b>
              <h1>Turn Your Steel <br />Inventory into<br />A Virtual Digital<br />Showroom</h1>
              <p className='w-2/3'>Showcase products, streamline orders, and grow your business seamlessly.</p>
            </div>
          </div>
        </div>
      </div>
      <div className="neutral20Bg lg:py-14 py-8 w-full">
        <div className="container lg:px-4 px-2 mx-auto flex lg:columns-2 columns-1 lg:flex-row flex-col lg:gap-10 gap-2 PageInfoBoxGry">
          <div className="lg:w-2/5 w-full flex items-center">
            <h2>Your Digital <span className='lg:block inline'>Steel Hub</span></h2>
          </div>
          <div className={`lg:w-3/5 w-full`}>
            <p className=''>Steelbazaar’s SB DigiStore is a cutting-edge platform that empowers steel sellers with a personalised digital storefront. Showcase your standard and non-standard products, scrap inventory, and services in one centralised space. With tools for dynamic pricing, catalogue sharing, and seamless communication, SB DigiStore ensures an efficient buyer connection.</p>
            <p className='!mb-0'>Boost your visibility, streamline your processes, and unlock new sales opportunities in the competitive steel market. SB DigiStore is designed to simplify digital trade while enhancing seller credibility and customer trust.</p>
          </div>
        </div>
      </div>
      <div className={`${classes.container} container mx-auto pt-90 lg:px-4 px-2 `}>
        <div className={`headingContainer`}>
          <div className="heading">Why Steel Sellers Need <span>SB DigiStore ?</span></div>
          <div className="Subheading">Benefits of SB DigiStore</div>
        </div>

        <div className='container mx-auto w-full flex flex-wrap gap-4'>
        <div className='AccelerateSales primary colWidth-3m1'>
        <b className='Txtprimary600 !text-left'><span>01</span> Enhanced Visibility</b>
              <div className='textBox !min-h-fit'>
                  <span>Expand Your Reach to Global Buyers Instantly</span>
                  Showcase your products to a worldwide audience for maximum exposure.
            </div>
        </div>
        <div className='AccelerateSales orange colWidth-3m1'>
        <b className='Txtorange600 !text-left'><span>02</span> Streamlined Operations</b>
        <div className='textBox !min-h-fit'>
                  <span>Efficiently Manage Inventory, Pricing, and Sales Offers Digitally</span>
                  Simplify complex tasks with an all-in-one management dashboard.
            </div>
        </div>
        <div className='AccelerateSales complementary  colWidth-3m1'>
        <b className='Txtcomply600 !text-left'><span>03</span> Boosted Credibility</b>
              
              <div className='textBox !min-h-fit'>
                  <span>Build Trust Through Certifications, Reviews, & Professional Presentation</span>
                  Enhance buyer confidence with authentic storefront details and testimonials.
            </div>
             
        </div>
        <div className='AccelerateSales warning colWidth-3m1'>
        <b className='Txtwarning600 !text-left'><span>04</span> Faster Sales Cycle</b>
        <div className='textBox !min-h-fit'>
                  <span>Close Deals Quickly with Real-Time Tools and Features</span>
                  Leverage instant pricing updates and seamless purchasing processes.
            </div>
        </div>
        <div className='AccelerateSales secondary colWidth-3m1'>
        <b className='Txtsecondary600 !text-left'><span>05</span> Cost-Effective Marketing</b>
        <div className='textBox !min-h-fit'>
                  <span>Promote Products Without Incurring High Advertising & Distribution Costs</span>
                  Utilise built-in marketing tools to increase visibility affordably.
            </div>
        </div>
        <div className='AccelerateSales success colWidth-3m1'>
        <b className='Txtsuccess600 !text-left'><span>06</span> Scalability</b>
        <div className='textBox !min-h-fit'>
                  <span>Easily Adapt to Growth and Changing Market Demands</span>
                  Expand your product range and operations with minimal additional investment.
            </div>
        </div>
        
    </div>
    </div>


    <div className={`${classes.container} container mx-auto lg:px-4 px-2 py-90`} id='Registration'>
        <div className={`headingContainer`}>
          <div className="heading">Your DigiStore for <span>Unlimited Trade</span></div>
          <div className="Subheading">Connect with trusted suppliers across the globe</div>
        </div>
        {
          // Section One Start Here
        }
        <div
          className="flex lg:columns-2 columns-1 lg:flex-row flex-col items-center ZigZagSection">
          <div className="2xl:w-4/12 lg:w-5/12 w-full flex content-center justify-center">
            <div className="ZigZagImgBox">
              <div className="ImgItem">
                <img src={DotImg} alt="" />
              </div>
              <img src={Section1} className="w-full" alt="" />
            </div>
          </div>
          <div className="2xl:w-8/12 lg:w-7/12 w-full ZigZagText"> 
            <div className="heading48">
            All Steel Categories in <span className='lg:block inline-block'>One Place</span>
            </div>
            <div className="Subhead">
              <b>IShowcase Diverse Listings</b>
            </div>

            <div className="PointBox">
              <ul>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span className='txt22'> 
                  List standard, non-standard, commercial, and melting scraps with detailed specifications.
                  </span>
                </li>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span className='txt22'>
                  Provide product visuals, mill test certificates, and comprehensive descriptions for transparency.
                  </span>
                </li>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span className='txt22'>
                  Organise inventory for streamlined browsing and better buyer engagement.
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {
          // Section Two Start Here
        }
        <div className="flex lg:columns-2 columns-1 lg:flex-row flex-col items-center ZigZagSection">
          <div className="2xl:w-4/12 lg:w-5/12 w-full flex order-1 lg:order-2 justify-center">
            <div className="ZigZagImgBox">
              <div className="ImgItemLeft">
                <img src={DotImg} alt="" />
              </div>
              <img src={Section2} className="w-full" alt="" />
            </div>
          </div>
          <div className="2xl:w-8/12 lg:w-7/12 w-full ZigZagText order-2 lg:order-1"> 
            <div className="heading48">
            Sell Through <span className='lg:block inline-block'>Competitive Bidding</span>
            </div>
            <div className="Subhead">
              <b>Auction Listings</b>
            
            </div>

            <div className="PointBox">
              <ul>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span className='txt22'>
                  Host real-time auctions for surplus or scrap materials to
attract competitive buyers.
                  </span>
                </li>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span className='txt22'>
                  Enable dynamic pricing for maximum revenue generation.
                  </span>
                </li>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span className='txt22'>
                  Track bids in real-time with automatic winner notifications.
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {
          // Section Three Start Here
        }
        <div className="flex lg:columns-2 columns-1 lg:flex-row flex-col items-center ZigZagSection">
          <div className="2xl:w-4/12 lg:w-5/12 w-full flex content-center justify-center">
            <div className="ZigZagImgBox">
              <div className="ImgItem">
                <img src={DotImg} alt="" />
              </div>
              <img src={Section3} className="w-full" alt="" />
            </div>
          </div>
          <div className="2xl:w-8/12 lg:w-7/12 w-full ZigZagText"> 
            <div className="heading48">
            Highlight Your <span className='lg:block inline-block'>Expertise</span>
            </div>
            <div className="Subhead">
              <b>Seller’s Value Added Services</b>
            </div>

            <div className="PointBox">
              <ul>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                 <span className='txt22'>
                 Showcase value-added services such as customisation, processing, and manufacturing.
                  </span>
                </li>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                 <span className='txt22'>
                 Detail service capabilities and unique seller offerings.
                  </span>
                </li>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                 <span className='txt22'>
                 Build credibility with service reviews and ratings from previous customers.
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {
          // Section Four Start Here
        }
        <div
          className="flex lg:columns-2 columns-1 lg:flex-row flex-col items-center ZigZagSection">
          <div className="2xl:w-4/12 lg:w-5/12 w-full flex order-1 lg:order-2 justify-center">
            <div className="ZigZagImgBox">
              <div className="ImgItemLeft">
                <img src={DotImg} alt="" />
              </div>
              <img src={Section4} className="w-full" alt="" />
            </div>
          </div>
          <div className="2xl:w-8/12 lg:w-7/12 w-full ZigZagText order-2 lg:order-1">
            <div className="heading48">
            Your Identity, <span className='lg:block inline-block'>Amplified</span>
            </div>
            <div className="Subhead">
              <b>About the Sellers</b>
            </div>

            <div className="PointBox">
              <ul>
                <li className="items-center">
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span className='txt22'>
                  Share your business story, certifications, and key  achievements to build trust.
                  </span>
                </li>
                <li className="items-center">
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span className='txt22'>
                  Add professional credentials and a brief history of  your operations.
                  </span>
                </li>
                <li className="items-center">
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span className='txt22'>
                  Highlight sustainability or ethical practices to appeal to conscious buyers.
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {
          // Section Five Start Here
        }
        <div className="flex lg:columns-2 columns-1 lg:flex-row flex-col items-center ZigZagSection">
          <div className="2xl:w-4/12 lg:w-5/12 w-full flex content-center justify-center">
            <div className="ZigZagImgBox">
              <div className="ImgItem">
                <img src={DotImg} alt="" />
              </div>
              <img src={Section5} className="w-full" alt="" />
            </div>
          </div>
          <div className="2xl:w-8/12 lg:w-7/12 w-full ZigZagText">
            <div className="heading48">
            Stay Connected with <span className='lg:block inline-block'>Buyers</span>
            </div>
            <div className="Subhead">
              <b>Contact Page</b>
            </div>

            <div className="PointBox">
              <ul>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span className='txt22'>
                  Provide multiple contact options like phone, email, & integrated WhatsApp messaging.
                  </span>
                </li>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span className='txt22'>
                  Include a form for buyer inquiries to ensure prompt responses.
                  </span>
                </li>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span className='txt22'>
                  Add maps or location details for enhanced accessibility.
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {
          // Section Six Start Here
        }
        <div
          className="flex lg:columns-2 columns-1 lg:flex-row flex-col items-center ZigZagSection">
          <div className="2xl:w-4/12 lg:w-5/12 w-full flex order-1 lg:order-2 justify-center">
            <div className="ZigZagImgBox">
              <div className="ImgItemLeft">
                <img src={DotImg} alt="" />
              </div>
              <img src={Section6} className="w-full" alt="" />
            </div>
          </div>
          <div className="2xl:w-8/12 lg:w-7/12 w-full ZigZagText order-2 lg:order-1"> 
            <div className="heading48">
            Expand Reach <span>Instantly</span>
            </div>
            <div className="Subhead">
              <b>Catalog Sharing on Marketplace</b>
            </div>

            <div className="PointBox">
              <ul>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span className='txt22'>
                  Share your digital catalogues directly on Steelbazaar’s marketplace to attract more buyers.
                  </span>
                </li>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span className='txt22'>
                  Enable real-time updates to showcase the latest offers & inventory.
                  </span>
                </li>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span className='txt22'>
                  Gain visibility among a large buyer base searching for steel products.
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        

      </div>
      <div className="neutral20Bg w-full" id='features'>
      <div className={`${classes.container} container mx-auto lg:py-24 py-8 lg:px-4 px-2`}>
        <div className={`headingContainer`}>
          <div className="heading"><span>Smart Tools</span> for <span>Smarter Sales</span></div>
          <div className='Subheading'>Exclusive Tools for Sellers</div>
        </div>

        <div className="flex mx-auto lg:px-4 px-2 lg:columns-2 lg:flex-row flex-col columns-1 BuyerExclusiveFeatures gap-10">
        <div className="lg:w-6/12 w-full pr-4 pt-4 pb-4">
            <div className="DiscoverFeaturesV1 secondary">
                <div className="HeadingBox">
                    <h4>Live<br /> Price<br /> Updates</h4>
                    <h5>01</h5>
                </div>
                <div className="PointsBox">
                    <div className="pointOne">
                        <b>Dynamically update prices based on market conditions.</b>
                    </div>
                    <div className="pointTwo">
                        <b>Offer competitive rates that adjust in real time.</b> 
                    </div>
                    <div className="pointThree">
                        <b>Ensure transparency and trust in pricing.</b>
                    </div>
                </div>
            </div>
            
            </div>
            <div className="lg:w-6/12 w-full pr-4 pt-4 pb-4 lg:pt-[110px]">
            <div className="DiscoverFeaturesV1 primary">
                <div className="HeadingBox">
                    <h4>Inventory<br />
                    Management<br />Tools</h4>
                    <h5>02</h5>
                </div>
                <div className="PointsBox">
                    <div className="pointOne">
                        <b>Update inventory levels seamlessly after every sale.</b>
                    </div>
                    <div className="pointTwo">
                        <b>Automate stock alerts for low inventory.</b>
                    </div>
                    <div className="pointThree">
                        <b>Maintain accurate stock records for smooth operations.</b>
                    </div>
                </div>
            </div> 
            </div> 
        </div>
        <div className="flex mx-auto lg:px-4 px-2 lg:columns-2 lg:flex-row  flex-col columns-1 BuyerExclusiveFeatures gap-10">
        <div className="lg:w-6/12 w-full pr-4 pt-4 pb-4">
            <div className="DiscoverFeaturesV1 warning">
                <div className="HeadingBox">
                    <h4>Integrated<br />Digital<br />Cart</h4>
                    <h5>03</h5>
                </div>
                <div className="PointsBox">
                    <div className="pointOne">
                        <b>Allow buyers to add multiple products for one-click checkout.</b>
                    </div>
                    <div className="pointTwo">
                        <b>Provide an intuitive interface for a hassle-free purchasing journey.</b>
                    </div>
                    <div className="pointThree">
                        <b>Simplify order management with automated confirmations.</b>
                    </div>
                </div>
            </div>
            
            </div>
            <div className="lg:w-6/12 w-full  pr-4 pt-4 pb-4 lg:pt-[110px]">
            <div className="DiscoverFeaturesV1 success">
                <div className="HeadingBox">
                    <h4>Enquiry &<br />
                    Quotation<br />Integration</h4>
                    <h5>04</h5>
                </div>
                <div className="PointsBox">
                    <div className="pointOne">
                        <b>Receive and respond to inquiries directly from the storefront.</b>
                    </div>
                    <div className="pointTwo">
                        <b>Generate and send instant quotations to interested buyers.</b>
                    </div>
                    <div className="pointThree">
                        <b>Integrate communication with WhatsApp for seamless updates and support.</b>
                    </div>
                </div>
            </div> 
            </div> 
        </div>
        <div className="flex mx-auto lg:px-4 px-2 lg:columns-2 lg:flex-row  flex-col columns-1 BuyerExclusiveFeatures gap-10">
        <div className="lg:w-6/12 w-full pr-4 pt-4 pb-4">
            <div className="DiscoverFeaturesV1 complementary">
                <div className="HeadingBox">
                    <h4>Advanced <br />Analytics<br />Tools</h4>
                    <h5>05</h5>
                </div>
                <div className="PointsBox">
                    <div className="pointOne">
                        <b>Gain insights into buyer behaviour and storefront performance.</b>
                    </div>
                    <div className="pointTwo">
                        <b>Identify popular products to optimise marketing strategies.</b>
                    </div>
                    <div className="pointThree">
                        <b>Track sales data for informed business decisions.</b>
                    </div>
                </div>
            </div>
            
            </div>
            <div className="lg:w-6/12 w-full pr-4 pt-4 pb-4 lg:pt-[110px]">
            <div className="DiscoverFeaturesV1 orange">
                <div className="HeadingBox">
                    <h4>Marketing<br />Add<br />Ons</h4>
                    <h5>06</h5>
                </div>
                <div className="PointsBox">
                    <div className="pointOne">
                        <b>Promote your storefront via Steelbazaar’s digital marketing campaigns.</b>
                    </div>
                    <div className="pointTwo">
                        <b>Highlight featured products for increased visibility.</b>
                    </div>
                    <div className="pointThree">
                        <b>Leverage email and SMS marketing tools to reach targeted buyers.</b>
                    </div>
                </div>
            </div> 
            </div> 
        </div>
      </div>
      </div>
            
      <div  className={`CTABox lg:py-24 py-8 w-full textBoxfg`}>
      <div className="container mx-auto CallToAction lg:px-4 px-3 ">
        <div className="2xl:w-5/12 lg:w-5/12 flex flex-col gap-y-2.5 items-start">
       <h2>
       SB DigiStore <br />Efficient & Transparent Inventory Management
       </h2>
       <p className="mb-5">By adopting SB DigiStock, steel industry stakeholders can transition from traditional, error-prone methods to a streamlined, efficient, and transparent digital inventory management system, fostering growth and competitiveness in the market</p>
       <Button className="Btn whiteBg Txtprimary500">Contact Now <ArrowForwardIcon className="primary500Bg text-white" />
                </Button>
      </div>
      </div>
      </div>
            <RequestDemoTemplate />
        </>
    );
};
export default DigiStoreTemplate;
