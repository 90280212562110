import React, { useEffect, useRef, useState } from 'react';
import { createUseStyles } from 'react-jss';
import Button from '../../../atoms/Button/Button';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import CarouselRef from 'react-multi-carousel';
import rightArrow from "../../../../assets/icons/rightArrow.svg";
import leftArrow from "../../../../assets/icons/leftArrow.svg"
import { useCategoryService } from '../../../../services/useCategoryService';
import { makeRoute, useDebounce } from '../../../../utils/helper';
import CatalogueGridItemSkeleton from '../../CatalogueList/CatalogueGridItemSkeleton.template';
import NoProduct from '../../../molecules/NoProduct/NoProduct';
import { useNavigate } from 'react-router-dom';
import { CUSTOMER_ROUTES, DEBOUNCE_TIME, categoryImage } from '../../../../utils/constant';
import { CATALOGUE_TYPE_STATES, CLASS_STATES, IPagination } from '../../../../utils/types';
import { useCatalogueService } from '../../../../services/useCatalogueService';
import { Catalogue } from '../../CatalogueList/CatalogueList.template';
import CatalogueGridItemV2 from '../CatalogueGridItemV2.template';
import { useMediaQuery } from 'react-responsive';

const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 6,
        partialVisibilityGutter: 0
    },
    desktop: {
        breakpoint: { max: 3000, min: 1280 },
        items: 6,
        partialVisibilityGutter: 40
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 3,
        partialVisibilityGutter: 10
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 3,
        partialVisibilityGutter: 0
    },
};

const useStyles = createUseStyles((theme: any) => ({
    mainContainer: {
        padding: "50px 0px 70px 0px"
    },
    container: {
       
    },
    headingContainer: {
        paddingBottom: "10px",
        borderBottom: `1px solid ${theme.palette.border.neutral.neutral200}`
    },
    heading: {
        color: theme.palette.background.primary.primary900,
        lineHeight: "34px",
        fontSize: "28px",
        fontWeight: 600
    },
    slide: {
        width: '156px',
        rowGap: "15px",
        color: theme.palette.background.primary.primary900,
        "&:hover": {
            color: theme.palette.text.primary.primary500,
        },
    },
    tab: {
        marginRight: "24px"
    },
    image: {
        width: "100%",
        height: "158px",
        borderRadius: "100px",
        backgroundColor: "white",
        border: `1px solid ${theme.palette.border.neutral.neutral100}`
    },
    categoryName: {
        lineHeight: "20px",
        fontSize: "16px"
    },
    carouselContainer: {
        "& .react-multiple-carousel__arrow--right": {
            right: "0 !important"
        },
        "& .react-multiple-carousel__arrow--left": {
            left: "0 !important"
        },
        "& .react-multi-carousel-item": {
        },
        "& .react-multi-carousel-list": {
            width: "100%",
            margin: "0 auto",
            padding: '0px',
        },
        // "& .react-multi-carousel-list li": {
        //     width: "fit-content !important",
        // },
    },
    customButtonContainer: {
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)' 
    },
    customArrow: {
        borderRadius: "50%",
        width: "48px",
        height: "48px",
        cursor: "pointer",
        transition: "box-shadow 0.3s ease-in-out",
        boxShadow: `0px 6px 12px 2px rgba(10, 18, 41, 0.16), 0px 2px 4px 1px rgba(10, 18, 41, 0.04)`,
    },
    tabContainer: {
        borderTop: `1px solid ${theme.palette.border.neutral.neutral200}`,
        columnGap: "30px",
        paddingTop: "20px"
    },
    selectedValue: {
        color: theme.palette.text.primary.primary800,
        background: theme.palette.background.primary.primary50,
        transition: "color 0.3s ease-in-out, background 0.3s ease-in-out",
        padding: "8px 12px",
        border: `1px solid ${theme.palette.border.primary.primary200}`,
        fontSize: "14px"
    },
    unselectedValue: {
        color: theme.palette.text.primary.primary800,
        border: `1px solid ${theme.palette.border.primary.primary200}`,
        padding: "8px 12px",
        fontSize: "14px",
        "&:hover": {
            background: theme.palette.background.primary.primary50,
        },
    },
    catalogueGrid: {
        display: "grid",
        gridGap: "16px",
        gridTemplateColumns: "repeat(5, 1fr)",
        "@media (max-width: 1300px)": {
            gridTemplateColumns: "repeat(4, 1fr)",
        },
        "@media (max-width: 767px)": {
            gridGap: "10px",
            gridTemplateColumns: "repeat(3, 1fr)",
        },
        "@media (max-width: 510px)": {
            gridGap: "10px",
            gridTemplateColumns: "repeat(2, 1fr)",
        },
    },
    catalogueCard: {
        backgroundColor: theme.palette.background.neutral.neutral50
    },
    categoryMobileContainer: {
        display: "none"
    },
    button: {
        paddingRight: "12px !important"
    },
    "@media (max-width: 767px)": {
        mainContainer: {
            padding: "20px 12px 20px 12px"
        },
        container: {
            width: "100%",
            display: "grid",
            rowGap: "10px"
        },
        heading: {
            lineHeight: "22px",
            fontSize: "18px",
            fontWeight: 500
        },
        slide: {
            width: 'fit-content',
            rowGap: "12px",
            color: theme.palette.background.primary.primary900,
            "&:hover": {
                color: theme.palette.background.primary.primary500,
            },
        },
        tab: {
            marginRight: "0px"
        },
        image: {
            width: "92px",
            height: "92px",
        },
        carouselContainer: {
            display: "none",
        },
        categoryMobileContainer: {
            display: "block"
        },
        categoryName: {
            color: theme.palette.background.primary.primary900,
            lineHeight: "14px",
            fontSize: "12px"
        },
        tabContainer: {
            borderTop: `1px solid ${theme.palette.border.neutral.neutral200}`,
            justifyContent: "space-between",
        },
        selectedValue: {
            padding: "4px 7px",
            fontSize: "12px",
            lineHeight: "14px"
        },
        unselectedValue: {
            padding: "4px 7px",
            fontSize: "12px",
            lineHeight: "14px"
        },
        button: {
            paddingRight: "0px !important",
            fontSize: "14px !important",
            fontWeight: "400 !important"
        },
    }
}));

export interface ICasourselItem {
    path: string;
    id: number | string;
    label: string;
}

const FinishedTemplate: React.FC = () => {

    const classes = useStyles();
    const navigate = useNavigate();
    const carouselRef = useRef<CarouselRef>(null);
    const catagoryService = useCategoryService();
    const catalogueService = useCatalogueService();
    const [loading, setLoading] = useState<boolean>(true);
    const [categories, setCategoriesTo] = useState<ICasourselItem[]>([]);
    const [selectedCategory, setSelectedCategory] = useState<ICasourselItem | null>(null);
    const [catalogues, setCataloguesTo] = useState<Catalogue[]>([]);
    const [pagination, setPaginationTo] = useState<IPagination>({
        size: 10,
        totalRecords: 0,
        page: 0,
    });


    const ButtonGroup = ({ next, previous, carouselState }: any) => {
        const { currentSlide, totalItems, slidesToShow } = carouselState;
        const isFirstSlide = currentSlide === 0;
        const isLastSlide = totalItems === 0 || currentSlide === totalItems - slidesToShow;

        return (
            <div className={`${classes.customButtonContainer} h-0 flex justify-between items-center w-full`}>
                {!isFirstSlide && (
                    <div className={`${classes.customArrow} bg-white flex justify-center items-center`} onClick={previous}>
                        <img src={leftArrow} alt="" />
                    </div>
                )}
                {!isLastSlide && (
                    <div className={`${classes.customArrow} bg-white flex justify-center items-center ml-auto`} onClick={next}>
                        <img src={rightArrow} alt="" />
                    </div>
                )}
            </div>
        );
    };

    const CATEGORY_LEVEL = {
        PRODUCT_CATEGORY: 2,
    };

    const loadCategories = async () => {
        try {
            const params = {
                level: CATEGORY_LEVEL.PRODUCT_CATEGORY,
                limit: 100,
                superCategoryId: 2
            };
            const { data: categoriesResponse } = await catagoryService.getAllCategories(params);
            if (categoriesResponse?.data?.length) {
                setCategoriesTo(categoriesResponse.data);
                setSelectedCategory(categoriesResponse.data[0]);
            } else {
                throw new Error("Categories fetch failed");
            }
        } catch (error) {
            setCategoriesTo([]);
            setSelectedCategory(null);
        }
    };

    useEffect(() => {
        loadCategories();
    }, []);

    const fetchCatalogues = async () => {
        try {
            setLoading(true);
            const payload: any = {
                classes: [CLASS_STATES.STANDARD, CLASS_STATES.NONSTANDARD],
                catalogueTypes: [CATALOGUE_TYPE_STATES.MULTIPLE_PRODUCT],
                // categoryId: selectedCategory?.id ?? null
                superCategoryId: 2
            };

            const cataloguesResponse = await catalogueService.getAllCatalogues(
                {
                    page: pagination.page,
                    size: pagination.size,
                    sort: "createdAt,desc",
                },
                payload
            );
            if (cataloguesResponse?.data?.data?.content?.length) {
                const { content } = cataloguesResponse.data.data;
                setCataloguesTo(content);
                setLoading(false);
            } else {
                throw new Error("Catalogue fetch failed");
            }
        } catch (error) {
            setCataloguesTo([]);
            setLoading(false);
        }
    };

    useEffect(() => {
        if (selectedCategory?.id) fetchCatalogues();
        else setLoading(false);
    }, [selectedCategory, pagination.page, pagination.size]);

    const data = [
        {
            name: "Most Popular",
            value: 0,
        },
        {
            name: "Hot Selling",
            value: 1,
        },
        {
            name: "Best Reviewed",
            value: 2,
        }
    ]
    const [selectedTab, setSelectedTab] = useState<string>('Most Popular');

    const handleClick = (item: any) => {
        setSelectedTab(item.name);
        setPaginationTo({
            size: 10,
            totalRecords: 0,
            page: item.value,
        });
    };

    const handleViewAllClick = () => {
        navigate(makeRoute(CUSTOMER_ROUTES.CATALOGUE_LISTING, { query: { "catalogueTypes": CATALOGUE_TYPE_STATES.MAKE_TO_ORDER, "categoryId": selectedCategory?.id } }));
    };

    const handleCategoryClick = (item: any) => () => {
        setSelectedCategory(item);
    }

    const debouncedHandleCategoryClick = useDebounce((item) => {
        handleCategoryClick(item)();
        navigate(makeRoute(CUSTOMER_ROUTES.CATALOGUE_LISTING, { query: { "catalogueTypes": CATALOGUE_TYPE_STATES.MAKE_TO_ORDER, "categoryId": item?.id } }));
    }, DEBOUNCE_TIME.PLP_CATEGORIES);

    const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
    const displayedCatalogues = isMobile ? catalogues.slice(0, 6) : catalogues;
    const nullData = isMobile ? [null, null, null, null] : [null, null, null, null, null]

    const [imgErrors, setImgErrors] = useState<{ [key: number]: boolean }>({});

    const handleImageError = (id: number) => {
        setImgErrors((prev) => ({ ...prev, [id]: true }));
    };

    return (
        <div id='finished' className={`${classes.mainContainer} overflow-x-auto overflow-hidden `}>
             <div className={`container mx-auto lg:px-4  `}>
                <div className={`${classes.headingContainer} flex justify-between`}> 
                    <div className={`${classes.heading} my-auto`}>Long Products</div>
                    <Button className={`${classes.button}`} variant="tertiaryText" label={"View All"} onClick={handleViewAllClick} />
                </div>
                <div className={`${classes.carouselContainer} relative py-8 px-16`}>
                    <Carousel
                        responsive={responsive}
                        showDots={false}
                        infinite={false}
                        partialVisible={false}
                        ref={carouselRef}
                        arrows={false}
                        removeArrowOnDeviceType={["sm-mobile"]}
                        renderButtonGroupOutside={true}
                        customButtonGroup={<ButtonGroup />}
                    >
                        {categories.map((item: any, index: any) => (
                            <div className={` ${classes.slide} h-full grid mr-6 cursor-pointer ${index !== categories.length - 1 ? classes.tab : ''}`} key={item.id} onClick={() => debouncedHandleCategoryClick(item)}>
                                <img
                                    className={`${classes.image}`}
                                    src={imgErrors[item?.id] ? categoryImage[item?.label] : item?.path}
                                    alt={item.label}
                                    onError={() => handleImageError(item?.id)}
                                />
                                <div className={`${classes.categoryName} text-center`}>{item.label}</div>
                            </div>
                        ))}
                    </Carousel>
                </div>

                {categories.length > 0 && (
                    <div className={`overflow-x-auto overflow-hidden w-full ${classes.categoryMobileContainer}`}>
                        <div className={`flex gap-x-3 w-max`}>
                            {categories.map((item: any, index: any) => (
                                <div className={` ${classes.slide} h-full grid cursor-pointer ${index !== categories.length - 1 ? classes.tab : ''}`} key={item.id} onClick={() => debouncedHandleCategoryClick(item)} >
                                    <img className={`${classes.image} mx-auto`} src={item.path} alt="categoryImage" />
                                    <div className={`${classes.categoryName} text-center`}>{item.label}</div>
                                </div>
                            ))}
                        </div>
                    </div>
                )}

                <div className={`${classes.tabContainer} flex pb-4`}>
                    {data.map((item: any, index: any) => (
                        <div
                            key={item.name}
                            className={`justify-center items-center rounded-lg ${selectedTab === item.name ? `${classes.selectedValue} font-medium cursor-pointer text-center` : `${classes.unselectedValue} font-medium cursor-pointer`}`}
                            onClick={() => handleClick(item)}
                        >
                            {item.name}
                        </div>

                    ))}
                </div>

                <div className={loading ? "" : ""}>
                    {loading ? (
                        <div className='grid xl:grid-cols-5 gap-4 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2'>
                            {nullData.map((item: any, index) => (
                                <div key={index}>
                                    <CatalogueGridItemSkeleton />
                                </div>
                            ))}
                        </div>
                        ) : catalogues.length > 0 ? (
                            <div className='grid xl:grid-cols-5 lg:gap-4 gap-2 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2  grid-cols-2'>
                                {displayedCatalogues.map((item: any, index: number) => (
                                    <div key={item?.id || index}>
                                        <CatalogueGridItemV2 
                                            data={item} 
                                            styles={classes.catalogueCard} 
                                        />
                                    </div>
                                ))}
                            </div>
                        ) : (
                        <div className="mt-4 w-1/2 mx-auto">
                            <NoProduct text="No Catalogs" variant="Product List" />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default FinishedTemplate;