import React, { useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss';
import { ITabsSchema } from '../../molecules/Tabs/Tabs';
import { useNavigate } from 'react-router-dom';
import { HTTP_STATUS, INVENTORY_MANAGEMENT_LISTING, VENDOR_PRODUCTS_STATES } from '../../../utils/types';
import Button from '../../atoms/Button/Button';
import ComingSoon from '../../atoms/ComingSoon/ComingSoon';
import TabsV2 from '../../molecules/TabsV2/TabsV2';
import { CUSTOMER_ROUTES, PRODUCT_CLASS_TYPES } from '../../../utils/constant';
import VendorProductPriceListTemplate from '../../template/VendorManagement/VendorProductPriceList.template';
import { initialStatsValue, IVendorStats } from '../PostProduct/PostProductList.page';
import { usePostProductService } from '../../../services/usePostProductService';
import { useAuthenticatedUser } from '../../../hooks/useAuthenticatedUser';

const useStyles = createUseStyles((theme: any) => ({
  section: {
    color: theme.palette.text.primary.primary900,
  }
}));

export interface IPriceManagementProps {
  activeTab: string;
  stats: number;
}

const VendorProductPriceListPage: React.FC = () => {

  const classes = useStyles();
  const navigate = useNavigate();
  const { user } = useAuthenticatedUser();
  const productService = usePostProductService();

  const [activeTab, setActiveTab] = useState(INVENTORY_MANAGEMENT_LISTING.STANDARD);
  const [productStats, setProductStats] = useState<IVendorStats>(initialStatsValue);

  const loadVendorProductStats = () => {
    if (user?.businessId) {
        productService.getVendorProductStats(user?.businessId, "PRICE")
            .then(res => {
                if (res?.status === HTTP_STATUS.OK) {
                    setProductStats(res?.data?.data);
                }
            }).catch((error) => {
                console.error("Error Fetching Stats: ", error);
            });
    }
}

  const schema: ITabsSchema[] = [
    { label: `Standard (${productStats.STANDARD ?? 0})`, component: <VendorProductPriceListTemplate activeTab={PRODUCT_CLASS_TYPES.STANDARD} stats={productStats.STANDARD ?? 0}/> },
    { label: `Non Standard (${productStats.NONSTANDARD ?? 0})`,  component: <VendorProductPriceListTemplate activeTab={PRODUCT_CLASS_TYPES.NONSTANDARD} stats={productStats.NONSTANDARD ?? 0}/>  },
    { label: 'Commercial Scrap', component: <ComingSoon /> },
    { label: 'Melting Scrap', component: <ComingSoon /> }
  ]

  const redirectToVendorProductPriceCreate = () => {
    navigate(CUSTOMER_ROUTES.VENDOR_PRODUCT_PRICE_CREATE);
  }

  useEffect(() => {
    loadVendorProductStats();
}, []);

  return (
    <div className={"grid gap-y-6 w-full "}>
      <div className='flex '>
        <div className={`${classes.section} text-lg font-medium w-full`}>Price Management</div>
        <Button
          className={`w-1/6`}
          variant="primaryContained"
          label="Add Price"
          size = {"medium"}
          onClick={redirectToVendorProductPriceCreate}
        />
      </div>
      <div className='w-full'>
        <TabsV2 schema={schema} value={(activeTab).toString()} setValue={setActiveTab} />
      </div>
    </div>
  )
}

export default VendorProductPriceListPage