import { FormikProps, useFormik } from 'formik';
import React, { Dispatch, useEffect, useState } from 'react'
import * as Yup from "yup";
import { VENDOR_CATALOGUE_FORM_STATES, HTTP_STATUS } from '../../../utils/types';
import VendorCatalogueFormTemplate from '../../template/VendorCatalogue/VendorCatalogueForm.template';
import { IVendorCatalogueAddServiceForm, useVendorCatalogueService } from '../../../services/useVendorCatalogueService';
import { useAuthenticatedUser } from '../../../hooks/useAuthenticatedUser';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from '../../../hooks/useSnackBar';
import { Discount } from '@mui/icons-material';
import { MODE, VENDOR_CATALOGUE_ROUTES } from '../../../utils/constant';
import { IVendorCatalogueAddForm } from './VendorCatalogueAdd.page';
import AlertBar from '../../molecules/AlertBar/AlertBar';
import crossIcon from "../../../assets/icons/crossIcon.svg";
import { createUseStyles } from 'react-jss';
import { Enum } from '../../../utils/helper';

const useStyles = createUseStyles((theme: any) => ({
  container: {
    backgroundColor: theme.palette.background.secondary.secondary50
  }
}));

interface IProductServicesDetails{
    locationId: number| null;
    machineId: number|null;
}

export interface IVendorCatalogueAddProps{
    formik: FormikProps<IVendorCatalogueAddForm>;
    mode?: string;
}
const initialValues: IVendorCatalogueAddForm = {
   name: '',
   description: '',
   catalogueType: '',
   productType: '',
   productId: null,
   paymentTerms: '',
   status: '',
   termsConditions:'',
   catalogueProductDetails:[{
   productId: null,
   warehouseId: null,
   maximumRetailPrice: null,
   offerSalePrice: null,
    deliveryTerms: '',
    packagingType: '',
    productDiscountDetails: [{
        locationId: null,
        minQuantity: null,
        maxQuantity: null,
        minDiscount: null
    }],
    productServicesDetails: [{
        locationId: null,
        machineId: null,
        serviceCategoryId: null,
    }],
    cGst: null,
    sGst: null,
    iGst: null,
    tcs: null,
    tax3: null,
   }],
   isVerifiedTrusted: false,
};

const VendorCatalogueViewPage = () => {
    const classes = useStyles();  
    const [currentSection, setCurrentSection] = useState<VENDOR_CATALOGUE_FORM_STATES>(VENDOR_CATALOGUE_FORM_STATES.CATALOGUE_TYPE);
    const vendorCatalogueService = useVendorCatalogueService();
    const [vendorCatalgueData, setVendorCatalogueDataTo] = useState<IVendorCatalogueAddForm>();
    const [catalogueFeedback, setCatalogueFeedback] = useState<string>();
    const params = useParams();
    const vendorCatalgueId = Number(params.catalogueId);
    const { showSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const { user } = useAuthenticatedUser();
    
    
    const formik = useFormik<IVendorCatalogueAddForm>({
        initialValues,
        onSubmit: async () => {
            const body : IVendorCatalogueAddServiceForm = {
                name: formik.values.name,
                description: formik.values.description,
                catalogueType: formik.values.catalogueType,
                productType: formik.values.productType,
                paymentTerms: formik.values.paymentTerms,
                status: formik.values.status,
                termsAndConditions: formik.values.termsConditions,
                catalogueProductDetails: formik.values.catalogueProductDetails.map((productDetails) => ({
                    vendorProductId: productDetails.productId ?? 0,
                    warehouseId: productDetails.warehouseId ?? 0,
                    marketRetailPrice: productDetails.maximumRetailPrice ?? 0,  
                    offerSalePrice: productDetails.offerSalePrice ?? 0,
                    paymentType: formik.values.paymentTerms,
                    deliveryTerms: productDetails.deliveryTerms,
                    packagingTypeEnum: productDetails.packagingType,
                    productChargesDetailsRequestBodyDTOs: [
                        {
                            locationId: productDetails.warehouseId ?? 0,
                            chargeType: "TCS",
                            rate: productDetails.tcs ?? 0,
                            rateType: "PERCENTAGE"
                          },
                          {
                            locationId: productDetails.warehouseId ?? 0,
                            chargeType: "CGST",
                            rate: productDetails.cGst ?? 0,
                            rateType: "PERCENTAGE"
                          },
                          {
                            locationId: productDetails.warehouseId ?? 0,
                            chargeType: "SGST",
                            rate: productDetails.sGst ?? 0,
                            rateType: "PERCENTAGE"
                          },
                          {
                            locationId: productDetails.warehouseId ?? 0,
                            chargeType: "IGST",
                            rate: productDetails.iGst ?? 0,
                            rateType: "PERCENTAGE"
                          },
                          {
                            locationId: productDetails.warehouseId ?? 0,
                            chargeType: "TAX_3",
                            rate: productDetails.tax3 ?? 0,
                            rateType: "PERCENTAGE"
                          }
                    ],
                    productDiscountDetailsRequestBodyDTOs: productDetails.productDiscountDetails.map((discount)=>({
                        locationId: discount.locationId ?? 0,
                        minQuantity: discount.minQuantity ?? 0,
                        maxQuantity: discount.maxQuantity ?? 0,
                        discountType: "PERCENTAGE",
                        minDiscount: discount.minDiscount ?? 0,
                        maxDiscount: discount.minDiscount ?? 0,
                    })),
                    productServicesDetailsRequestBodyDTOs: productDetails.productServicesDetails.map((machine)=>({
                        locationId: machine.locationId ?? 0,
                        machineId: machine.machineId ?? 0,
                        serviceCategoryId: machine.serviceCategoryId ?? 0
                    }))
                })),
                isVerifiedTrusted: formik.values.isVerifiedTrusted,
            }
            if(user?.businessId){
            try {
                const vendorCatalogueUpdateResponse = await vendorCatalogueService.updateVendorCatalogue(user?.businessId, vendorCatalgueId, body);
                if (vendorCatalogueUpdateResponse.status === HTTP_STATUS.OK) {
                    navigate(VENDOR_CATALOGUE_ROUTES.MAKE_CATALOGUE);
                }
            } catch (error) {
                showSnackbar("error", `Failed to update Catalogue, Please try again`)
            }
          }
        },
    });

    const getCatalogue = async () => {
        if (user?.businessId) {
          try {
            const vendorCatalogueResponse = await vendorCatalogueService.GetVendorCatalogue(user?.businessId, vendorCatalgueId);
      
            if (vendorCatalogueResponse.status === HTTP_STATUS.OK) {
              const vendorCatalogueResponseBody = vendorCatalogueResponse.data.data;
      
              const vendorCatalogue: IVendorCatalogueAddForm = {
                name: vendorCatalogueResponseBody.name,
                description: vendorCatalogueResponseBody.description,
                catalogueType: vendorCatalogueResponseBody.catalogueType,
                productType: vendorCatalogueResponseBody.productType,
                productId: vendorCatalogueResponseBody.catalogueProductDetails[0]?.vendorProductId || "",
                paymentTerms: vendorCatalogueResponseBody.paymentTerms,
                status: vendorCatalogueResponseBody.status,
                termsConditions: vendorCatalogueResponseBody.termsAndConditions,
                catalogueProductDetails: vendorCatalogueResponseBody.catalogueProductDetails.map((productDetail: any) => {
                  let cGst = null;
                  let sGst = null;
                  let iGst = null;
                  let tcs = null;
                  let tax3 = null;
                  productDetail.productChargesDetailsRequestBodyDTOs.map((chargeDetail: any) => {
                    switch (chargeDetail.chargeType) {
                      case "CGST":
                        cGst = chargeDetail.rate;
                        break;
                      case "SGST":
                        sGst = chargeDetail.rate;
                        break;
                      case "IGST":
                        iGst = chargeDetail.rate;
                        break;
                      case "TCS":
                        tcs = chargeDetail.rate;
                        break;
                      case "TAX_3":
                        tax3 = chargeDetail.rate;
                        break;
                      default:
                        break;
                    }
                  });
      
                  return {
                    productId: productDetail.vendorProductId,
                    warehouseId: productDetail.warehouseId,
                    maximumRetailPrice: productDetail.marketRetailPrice,
                    offerSalePrice: productDetail.offerSalePrice,
                    deliveryTerms: productDetail.deliveryTerms,
                    packagingType: productDetail.packagingTypeEnum,
      
                    productDiscountDetails: productDetail.productDiscountDetailsRequestBodyDTOs.map((discount: any) => ({
                      locationId: discount.locationId,
                      minQuantity: discount.minQuantity,
                      maxQuantity: discount.maxQuantity,
                      minDiscount: discount.minDiscount,
                    })),
      
                    productServicesDetails: productDetail.productServicesDetailsRequestBodyDTOs,
                    cGst,
                    sGst,
                    iGst,
                    tcs,
                    tax3,
                  };
                }),
                isVerifiedTrusted: vendorCatalogueResponseBody?.isVerifiedTrusted
              };
              setVendorCatalogueDataTo(vendorCatalogue);
            }
          } catch (error) {
            showSnackbar("error", `Failed to update Catalogue, Please try again`);
          }
        }
      };

  const getCatalogueFeedback = async () => {
    if (user?.businessId) {
      try {
        const vendorFeedback = await vendorCatalogueService.getCatalogueFeedback({
          vendorId: user.businessId,
          catalogueId: vendorCatalgueId,
          resourceType: "CATALOGUE",
          approvalStatus: formik.values.status == Enum.UNPUBLISHED ? Enum.UNPUBLISHED:Enum.REJECTED
        });

        if (vendorFeedback.status === HTTP_STATUS.OK) {
          const vendorCatalogueResponseBody = vendorFeedback.data.data;
          setCatalogueFeedback(vendorCatalogueResponseBody.vendorComment)
        }
      } catch (error) {
        showSnackbar("error", `Failed to get feedback`);
      }
    }
  };
      

    useEffect(()=>{
      getCatalogue();
    },[user?.businessId])

    useEffect(()=>{
      getCatalogueFeedback();
    },[user?.businessId, formik.values.status] )

    useEffect(() => {
        formik.setValues({
          ...formik.values,
          ...vendorCatalgueData
        })
      }, [vendorCatalgueData])

  return (
    <>
     {catalogueFeedback && (formik.values.status === Enum.REJECTED || formik.values.status === Enum.UNPUBLISHED )&&
        <div className='w-1/2 justify-end flex ml-auto'>
          <AlertBar
            icon={crossIcon}
            text={catalogueFeedback}
            textColor='#D31F2B'
            className={classes.container}
          />
        </div>
      }
      <VendorCatalogueFormTemplate formik={formik} mode={MODE.VIEW} />
    </>
  )
}

export default VendorCatalogueViewPage