import { request } from ".";
import { useAuthenticatedUser } from "../hooks/useAuthenticatedUser";
import { API_METHOD } from "../utils/constant";
import { replaceUrlParams } from "../utils/helper";

interface ICatalogueParams {
  page: number;
  size: number;
  sort?: string;
  name?: string;
  standardIds?: string;
  gradeIds?: string;
  brandIds?: string;
  shapes?: string;
  catalogueIds?: string;
  secondaryCatalogueIds?: string;
  manufacturerIds?: string;
  classes?: string;
  catalogueTypes?: string;
  categoryId?: number;
  superCategoryId?: number;
  attributes?: string;
  isCacheControl?: boolean;
  productType?: string;
}

export interface ICatalogueAttributes {
  minValue: string | null;
  maxValue: string | null;
  values: string[] | number[] | null;
}

export interface ICatalogueRequestBody {
  name?: string;
  standardIds?: number[] | null;
  brandIds?: number[] | null;
  gradeIds?: number[] | null;
  manufacturerIds?: number[] | null;
  classes?: string[] | null;
  catalogueTypes?: string[] | null;
  shapes?: string[] | null;
  categoryId?: number | string | null;
  superCategoryId?: number | string | null;
  catalogueIds?: number[] | null;
  secondaryCatalogueIds?: number[] | null;
  attributes?: { [key: string]: ICatalogueAttributes; }
}

export const CATALOGUE_URLS = {
  GET_ALL_UPCS_BY_CATALOGUE_ID: "/catalogue/:catalogueId/upcs",
  GET_CATALOGUE_BY_ID: "/catalogue/:catalogueId",
  GET_ALL_CATALOGUE: "/catalogues",
  GET_VENDOR_CATALOGUE: "/vendor/:vendorId/catalogues",
  GET_VENDOR_CATALOGUE_BY_ID: "/vendors/:vendorId/catalogue/:catalogueId",
  GET_SECONDARY_CATALOGUE_BY_ID: "/secondary-catalogue/:secondaryCatalogueId",
  GET_SECONDARY_LOTS_CATALOUGE: "/secondary-products/:catalogueId",
  GET_SECONDARY_LOTS_CATALOUGE_BY_ID: "/secondarylots-catalogue/:secondaryCatalogueId",
  GET_VENDOR_CATALOGUE_BY_ID_V2: "/catalogue/:catalogueId",
  GET_CATALOGUE_VENDORS: "catalogue/:catalogueId/vendors",
  GET_VENDORS_BY_PRODUCT: "product/:productId/vendors"
};

export const useCatalogueService = () => {
  const { user: authenticatedUser } = useAuthenticatedUser();
  const v3BaseUrl=process.env.REACT_APP_API_V3_URL;
  const API_BASE_URL_V2 = process.env.REACT_APP_API_V2_URL;

  const removeNullValues = <T extends object>(obj: T): Partial<T> => {
    if (obj === null || typeof obj !== 'object') {
      return obj;
    }

    return Object.fromEntries(
      Object.entries(obj)
        .map(([key, value]) => [
          key,
          value === null ? undefined : 
            Array.isArray(value) ? value : 
            typeof value === 'object' ? removeNullValues(value) : value
        ])
        .filter(([_, value]) => value !== undefined)
    ) as Partial<T>;
  };

  const encodeAttributes = (attributes: { [key: string]: ICatalogueAttributes } | undefined) => {
    if (!attributes || Object.keys(attributes).length === 0) {
      return undefined;
    }
    const cleanedAttributes = removeNullValues(attributes);
    return encodeURIComponent(JSON.stringify(cleanedAttributes));
  };

  const getAllUpcsByCatalogueId = (catalogueId: number) => {
    return request(API_METHOD.GET, replaceUrlParams(CATALOGUE_URLS.GET_ALL_UPCS_BY_CATALOGUE_ID, { catalogueId } ), authenticatedUser );
  };

  const getCatalogueById = (catalogueId: number) => {
    return request(API_METHOD.GET, replaceUrlParams(CATALOGUE_URLS.GET_CATALOGUE_BY_ID, { catalogueId } ), authenticatedUser );
  };

  const getAllCatalogues = (params: ICatalogueParams, data: ICatalogueRequestBody) => {
    const queryParams: ICatalogueParams = {
      ...params,
      name: data.name,
      standardIds: data.standardIds?.length ? data.standardIds.join(',') : undefined,
      brandIds: data.brandIds?.length ? data.brandIds.join(',') : undefined,
      gradeIds: data.gradeIds?.length ? data.gradeIds.join(',') : undefined,
      manufacturerIds: data.manufacturerIds?.length ? data.manufacturerIds.join(',') : undefined,
      classes: data.classes?.length ? data.classes.join(',') : undefined,  
      catalogueTypes: data.catalogueTypes?.length ? data.catalogueTypes.join(',') : undefined,
      shapes: data.shapes?.length ? data.shapes.join(',') : undefined,
      categoryId: data.categoryId ? Number(data.categoryId) : undefined,
      superCategoryId: data.superCategoryId ? Number(data.superCategoryId) : undefined,
      catalogueIds: data.catalogueIds?.length ? data.catalogueIds.join(',') : undefined,
      secondaryCatalogueIds: data.secondaryCatalogueIds?.length ? data.secondaryCatalogueIds.join(',') : undefined,
      attributes: encodeAttributes(data.attributes)
    };

    return request(API_METHOD.GET, CATALOGUE_URLS.GET_ALL_CATALOGUE, authenticatedUser, null, { params: queryParams });
};

  const getVendorCatalogues = (vendorId:number|undefined, params: ICatalogueParams, data: ICatalogueRequestBody) => {
    return request(API_METHOD.POST, replaceUrlParams(CATALOGUE_URLS.GET_VENDOR_CATALOGUE, {vendorId} ), authenticatedUser, data, { params } );
  };

  const getVendorCatalogueById = (vendorId:number|undefined, catalogueId: number) => {
    return request(API_METHOD.GET, replaceUrlParams(CATALOGUE_URLS.GET_VENDOR_CATALOGUE_BY_ID, {vendorId, catalogueId} ), authenticatedUser, null, null, null, v3BaseUrl );
  };

  const getSecondaryCatalogueById = (secondaryCatalogueId: number) => {
    return request(API_METHOD.GET, replaceUrlParams(CATALOGUE_URLS.GET_SECONDARY_CATALOGUE_BY_ID, { secondaryCatalogueId }), authenticatedUser);
  }

  const getSecondaryProductsByCatalogueId = (catalogueId: number) => {
    return request(API_METHOD.GET, replaceUrlParams(CATALOGUE_URLS.GET_SECONDARY_LOTS_CATALOUGE, { catalogueId }), authenticatedUser);
  }

  const getSecondaryLotsCatalogueById = (secondaryCatalogueId: number) => {
    return request(API_METHOD.GET, replaceUrlParams(CATALOGUE_URLS.GET_SECONDARY_LOTS_CATALOUGE_BY_ID, { secondaryCatalogueId }), authenticatedUser);
  }

  const getVendorCatalogueByIdV2 = (catalogueId: number, data: any) => {
    return request(API_METHOD.POST, replaceUrlParams(CATALOGUE_URLS.GET_CATALOGUE_BY_ID, { catalogueId }), authenticatedUser, data, null, null, API_BASE_URL_V2);
  };

  const getAllUpcsByCatalogueIdV2 = (catalogueId: number) => {
    return request(API_METHOD.GET, replaceUrlParams(CATALOGUE_URLS.GET_ALL_UPCS_BY_CATALOGUE_ID, { catalogueId }), authenticatedUser, null, null, null, API_BASE_URL_V2);
  };

  const getCatalogueVendors = (catalogueId: number, params: any = {}) => {
    return request(API_METHOD.GET, replaceUrlParams(CATALOGUE_URLS.GET_CATALOGUE_VENDORS, { catalogueId }), authenticatedUser, null, { params }, null, API_BASE_URL_V2);
  }

  const getVendorsByProductId = (productId: number) => {
    return request(API_METHOD.GET, replaceUrlParams(CATALOGUE_URLS.GET_VENDORS_BY_PRODUCT, { productId }), authenticatedUser, null, null, null, API_BASE_URL_V2);
  }

  return {
    getAllUpcsByCatalogueId,
    getCatalogueById,
    getAllCatalogues,
    getSecondaryCatalogueById,
    getSecondaryProductsByCatalogueId,
    getSecondaryLotsCatalogueById,
    getVendorCatalogues,
    getVendorCatalogueById,
    getVendorCatalogueByIdV2,
    getAllUpcsByCatalogueIdV2,
    getCatalogueVendors,
    getVendorsByProductId
  };
};
