import { POLICY_SECTION } from "../../../utils/constant";
import { createUseStyles } from "react-jss";
import React from "react";
import AccessToThePlatform from "../../template/PrivacyPolicy/PlatformAndIntellectualProperty.template";
import General from "../../template/PrivacyPolicy/General.template";
import LogisticAndFulfillmentServicesTemplate from "../../template/PrivacyPolicy/LegalTermsAndResponsibilities.template";
import PaymentsTemplate from "../../template/PrivacyPolicy/CommunicationAndAvailability.template";
import TermsAndConditionsIntroduction from "../../template/PrivacyPolicy/TermsAndConditionsIntoduction.template";
import UseOfThePlatformTemplate from "../../template/PrivacyPolicy/ProductAndServiceInformation.template";
import YourProfileAndYourInformation from "../../template/PrivacyPolicy/PricingAndPayments.template";
import ContentPreviewer, { IContentComponent } from "../../organisms/ContentPreviewer/ContentPreviewer";
import SupportAndFeedbackTemplate from "../../template/PrivacyPolicy/SupportAndFeedback.template";
import MiscellaneousTemplate from "../../template/PrivacyPolicy/Miscellaneous.template";
import PricingAndPayments from "../../template/PrivacyPolicy/PricingAndPayments.template";
import LegalTermsAndResponsibilitiesTemplate from "../../template/PrivacyPolicy/LegalTermsAndResponsibilities.template";
import PlatformAndIntellectualProperty from "../../template/PrivacyPolicy/PlatformAndIntellectualProperty.template";
import ProductAndServiceInformationTemplate from "../../template/PrivacyPolicy/ProductAndServiceInformation.template";
import CommunicationAndAvailabilityTemplate from "../../template/PrivacyPolicy/CommunicationAndAvailability.template";


const useStyles = createUseStyles((theme: any) => ({
    contentStyle: {
        // color: theme.palette.text._primaryDarkAccent,
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '28px',
    },
    boxStyle: {
        padding: '14px 10px',
        borderRadius: '8px',
        background: theme.palette.background.complementary.complementary960,
        marginTop: '16px',
        marginBottom: '24px',
    },
    subHeading: {
        color: theme.palette.text.complementary.complementary800,
        fontSize: '18px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '120%',
    },
}));

const TermsAndConditions: React.FC = () => {
    const classes = useStyles();
    const TermsAndConditionsSchem: IContentComponent[] = [
        {
            Key: POLICY_SECTION.TERMS_INTRODUCTION,
            component: <TermsAndConditionsIntroduction {...classes}/>
        },
        {
            Key: POLICY_SECTION.GENERAL,
            component: <General  header={POLICY_SECTION.GENERAL} {...classes} />
        },
        {
            Key: POLICY_SECTION.PLATFORM_INTELLECTUAL_PROPERTY,
            component: <PlatformAndIntellectualProperty header={POLICY_SECTION.PLATFORM_INTELLECTUAL_PROPERTY} {...classes} />
        },
        {
            Key: POLICY_SECTION.PRICING_AND_PAYMENTS, 
            component: <PricingAndPayments {...classes} header={POLICY_SECTION.PRICING_AND_PAYMENTS} />
        },
        {
            Key: POLICY_SECTION.PRODUCT_AND_SERVICE_INFORMATION,
            component: <ProductAndServiceInformationTemplate {...classes} header={POLICY_SECTION.PRODUCT_AND_SERVICE_INFORMATION} />
        },
        {
            Key: POLICY_SECTION.COMMUNICATION_AND_AVAILABILITY,
            component: <CommunicationAndAvailabilityTemplate {...classes} header={POLICY_SECTION.COMMUNICATION_AND_AVAILABILITY} />
        },
        {   
            Key: POLICY_SECTION.LEGAL_TERMS_AND_RESPONSIBILITIES,
            component: <LegalTermsAndResponsibilitiesTemplate {...classes} header={POLICY_SECTION.LEGAL_TERMS_AND_RESPONSIBILITIES}/>
        },
        {   
            Key: POLICY_SECTION.SUPPORT_AND_FEEDBACK,
            component: <SupportAndFeedbackTemplate {...classes} header={POLICY_SECTION.SUPPORT_AND_FEEDBACK}/>
        },
        {   
            Key: POLICY_SECTION.MISCELLANEOUS,
            component: <MiscellaneousTemplate {...classes} header={POLICY_SECTION.MISCELLANEOUS}/>
        },
       
    ]

    return <ContentPreviewer policySchema={TermsAndConditionsSchem}/>;
}

export default TermsAndConditions;