import { Button } from "@mui/joy";
import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import procurmentBanner from "../../../assets/images/procurmentBanner.jpg";
import pCardIcon1 from "../../../assets/images/Procurement-Benefits-Icon-1.svg";
import pCardIcon2 from "../../../assets/images/Procurement-Benefits-Icon-2.svg";
import pCardIcon3 from "../../../assets/images/Procurement-Benefits-Icon-3.svg";
import pCardIcon4 from "../../../assets/images/Procurement-Benefits-Icon-4.svg";
import pCardIcon5 from "../../../assets/images/Procurement-Benefits-Icon-5.svg";
import pCardIcon6 from "../../../assets/images/Procurement-Benefits-Icon-6.svg";
import pCardIcon7 from "../../../assets/images/Procurement-Benefits-Icon-7.svg";
import pCardIcon8 from "../../../assets/images/Procurement-Benefits-Icon-8.svg";
import RequestDemoTemplate from "../../template/Home/RequestDemo/RequestDemo.template";
import meter from "../../../assets/images/meter.svg";
import iProcure from "../../../assets/images/iProcure.jpg";
import sbMaxCard from "../../../assets/images/sbMaxCard.jpg";
import procurePlus from "../../../assets/images/procurePlus.jpg";
import primaryBlueTick from "../../../assets/icons/primaryBlueTick.jpg";
import orangeTick from "../../../assets/icons/orangeTick.jpg";
import blueTick from "../../../assets/icons/blueTick.jpg";
import AccordionExpandDefault from "./accordian";
import accordianImg1 from "../../../assets/images/Procurement_Comprehensive-Material-Sourcing-Solutions.jpg";
import accordianImg2 from "../../../assets/images/Procurement_Global-Sourcing.jpg";
import accordianImg3 from "../../../assets/images/Procurement_End-2-End.jpg";
import accordianImg4 from "../../../assets/images/Procurement_Optimal-Result.jpg";
import accordianImg5 from "../../../assets/images/Procurement_Drive-Down-Cost.jpg";
import accordianImg6 from "../../../assets/images/Procurement_Get-Product.jpg";
import accordianImg7 from "../../../assets/images/Procurement_Financial-Flexibility.jpg";
import accordianImg8 from "../../../assets/images/Procurement_Assured-Quality.jpg";
import accordianImg9 from "../../../assets/images/Procurement_On-time-Deliveries.jpg";

//bykamal
import DotImg from "../../../assets/images/dot-shape.png";
import BulletImg from "../../../assets/images/bulletIcon.svg";
import ProcurementIcon1 from "../../../assets/images/Procurement_Unpredictable-Costs-Icon.svg";
import ProcurementIcon2 from "../../../assets/images/Procurement_Quality-Inconsistencies-Icon.svg";
import ProcurementIcon3 from "../../../assets/images/Procurement_Delayed-Deliveries-Icon.svg";
import ProcurementIcon4 from "../../../assets/images/Procurement_Vendor-Management-Complexity-Icon.svg";
import ProcurementIcon6 from "../../../assets/images/Procurement_Cash-Flow-Strains-Icon.svg";
import ProcurementIcon5 from "../../../assets/images/Procurement_Inefficient-Processes-Icon.svg";
import SellerCTABg from "../../../assets/images/Seller_CTABg.jpg";
import { Link } from "react-router-dom";

const useStyles = createUseStyles((theme: any) => ({
  container: {},
  bannerContainer: {
    backgroundImage: `url(${procurmentBanner})`,
    backgroundSize: "cover",
  },
  bannerBtn: {
    background: theme.palette.background.primary.primary500,
    padding: "10px 20px",
    width: "228px",
    color: "#fff",
  },
  btnArrow: {
    border: "1px solid #fff",
    borderRadius: "100%",
  },
  text: {
    fontSize: "54px",
    color: "#000",
    lineHeight: "66px",
  },
  cardTop1: {
    background: "#FD8235",
    color: "#fff",
    borderRadius: "15px 15px 0px 0px",
    margin: "0 auto",
  },
  cardTop2: {
    background: theme.palette.background.complementary.complementary400,
    color: "#fff",
    borderRadius: "15px 15px 0px 0px",
    margin: "0 auto",
  },
  cardTop3: {
    background: theme.palette.background.primary.primary500,
    color: "#fff",
    borderRadius: "15px 15px 0px 0px",
    margin: "0 auto",
  },
  bussinessCard: {
    boxShadow: "2px 2px 15px 0px rgba(0, 0, 0, 0.12)",
    overflow: "hidden",
    "& h5": {
      fontSize: "40px",
    },
    "& p": {},
  },
  cardBottom1: {
    borderRadius: "0px !important",
  },
  procureCardHeaidng1: {
    "& span": {
      color: theme.palette.text.complementary.complementary400,
    },
  },
  procureCardHeaidng2: {
    "& span": {
      color: theme.palette.text.primary.primary500,
    },
  },
  procureCardHeaidng3: {
    "& span": {
      color: "#FD8235",
    },
  },

  // <------------------------------------------->
  accordion: {
    borderRadius: "0.5rem",
    overflow: "hidden",
    margin: "1rem 0",
  },
  tab: {
    position: "relative",
    borderBottom: "1px solid #CACCCE",
    "& input": {
      position: "absolute",
      opacity: 0,
      zIndex: -1,
    },
  },
  accodText: {
    fontSize: "24px",
    color: "#000",
    paddingLeft: "10px",
  },
  tabLabel: {
    display: "flex",
    alignItems: "center",
    padding: "8px 5px",
    color: "black",
    cursor: "pointer",
    "&::before": {
      content: '"+"',
      fontSize: "30px",
      fontWeight: "500",
      transition: "transform 0.5s, content 0.5s",
      color: theme.palette.text.primary.primary500,
    },
    "& input:checked + &::after": {
      content: '"−"',
    },
  },
  tabLabelExpanded: {
    "&::before": {
      content: '"−"',
    },
  },
  tabContent: {
    maxHeight: 0,
    overflow: "hidden",
    transition: "max-height 0.35s ease-in-out",
  },
  tabContentExpanded: {
    maxHeight: "500px",
  },
  contentInner: {
    padding: "10px 0px",
    fontSize: "18px",
  },
  "@media screen and (max-width: 1199px)": {
  accodText: {
    fontSize: "20px",
    color: "#000",
    paddingLeft: "10px",
  },
  },
  "@media screen and (max-width: 768px)": {
    accodText: {
      fontSize: "18px",
      color: "#000",
      paddingLeft: "10px",
    },
    },
    "@media screen and (max-width: 480px)": {
      accodText: {
        fontSize: "16px",
        color: "#000",
        paddingLeft: "10px",
      },
      },
}));

const cardData = [
  {
    icon: pCardIcon1,
    title: "Cost Savings",
    description:
      "Lower costs and boost profits by analysing spending, consolidating suppliers & negotiating effectively.",
  },
  {
    icon: pCardIcon2,
    title: "Improved Quality",
    description:
      "Choose suppliers that provide the best overall value, including quality, ensuring high-quality goods & services for organisations.",
  },
  {
    icon: pCardIcon3,
    title: "Risk Mitigation",
    description:
      "Identifying and mitigating supply chain risks, like supplier instability or disruptions, through supplier evaluation and risk assessment.",
  },
  {
    icon: pCardIcon4,
    title: "Increased Efficiency",
    description:
      "Streamlined procurement, automation, and strategic sourcing can enhance efficiency and reduce lead times.",
  },
  {
    icon: pCardIcon5,
    title: "Enhanced Supplier Relationships",
    description:
      "Building long-term, collaborative relationships with key suppliers to foster trust and communication for better outcomes.",
  },
  {
    icon: pCardIcon6,
    title: "Innovation & Continuous Improvement",
    description:
      "Partnering with strategic suppliers leverages innovation and process improvement expertise.",
  },
  {
    icon: pCardIcon7,
    title: "Better Alignment with Business Objectives",
    description:
      "Strategic sourcing aligns procurement with organisational goals, fostering long-term success and competitiveness.",
  },
  {
    icon: pCardIcon8,
    title: "Enhanced Visibility and Control",
    description:
      "Strategic sourcing aligns procurement with organisational goals, fostering long-term success and competitiveness.",
  },
];

const faqs = [
  {
    id: 1,
    header: "What is Lorem Ipsum?",
    text: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.`,
  },
  {
    id: 2,
    header: "Where does it come from?",
    text: `It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.`,
  },
  {
    id: 3,
    header: "Why do we use it?",
    text: `Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in classical Latin literature from 45 BC.`,
  },
  {
    id: 4,
    header: "Where can I get some?",
    text: `There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.`,
  },
];

const ProcurementTemplate: React.FC = () => {
  const classes = useStyles();

  type TabKeys =
    | "tab1"
    | "tab2"
    | "tab3"
    | "tab4"
    | "tab5"
    | "tab6"
    | "tab7"
    | "tab8"
    | "tab9"
    | "tab10"
    | "tab11"
    | "tab12"
    | "tab13"
    | "tab14"
    | "tab15"
    | "tab16"
    | "tab17"
    | "tab18"
    | "tab19"
    | "tab20"
    | "tab21"
    | "tab22"
    | "tab23"
    | "tab24"
    | "tab25"
    | "tab26"
    | "tab27"
    | "tab28"
    | "tab29"
    | "tab30"
    | "tab31"
    | "tab32"
    | "tab33"
    | "tab34"
    | "tab35"
    | "tab36";

  const [expanded, setExpanded] = useState<TabKeys | null>(null);

  const toggleAccordion = (tab: TabKeys) => {
    setExpanded((prev) => (prev === tab ? null : tab));
  };

  return (
    <>
      <div className={`${classes.bannerContainer}`}>
        <div className="container mx-auto px-4">
          <div className="insideBannerBx">
            <div className="insideBannerInfo">
              <h1>
                Unlock Every
                <br />
                Hidden Savings
                <br />
                with Our Smart Steel
                <br />
                <span>Procurement Solutions</span>
              </h1>
              <p className="lg:w-2/5">
                Our procurement process delivers precision & cost efficiency,
                providing businesses with exactly what they need.
                <span>Here's how it works</span>
              </p>
              <Button className="HeaderBtn">
              Start Selling Today <ArrowForwardIcon />{" "}
              </Button>
            </div>
          </div>
        </div>
      </div>

      <div className="neutral20Bg lg:py-14 py-8 w-full">
        <div className="container lg:px-4 px-2 mx-auto flex lg:columns-2 columns-1 lg:flex-row flex-col lg:gap-10 gap-2 PageInfoBoxGry">
          <div className="lg:w-2/5 w-full grid content-center ">
            <h2>Optimize Costs Maximize Efficiency</h2>
          </div>
          <div className={`lg:w-3/5 w-full`}>
            <p>
              SteelBazaar transforms steel procurement through a digital
              platform that integrates strategic sourcing. Our approach
              continually enhances purchasing activities for efficient &
              cost-effective acquisition of quality steel products. By analyzing
              spending patterns & market conditions while fostering supplier
              relationships, we elevate procurement from a transactional
              function to a strategic business activity.
            </p>
            <p>
              This allows businesses to optimize supply chains, reduce costs, &
              improve quality. Our commitment to transparency, sustainability,
              and excellence helps organizations of all sizes achieve their
              procurement goals.{" "}
            </p>
          </div>
        </div>
      </div>

      <div
        className= 'container mx-auto lg:px-4 px-2 lg:py-[90px] py-8 overflow-hidden'
      >
        <div className={`headingContainer`}>
          <div className="heading">
            Are Steel Procurement Issues{" "}
            <span className="secondary600">Blocking</span> Your Enterprise{" "}
            <span>Growth?</span>
          </div>
          <div className="Subheading">
            Discover Solutions to Overcome These Common Obstacles
          </div>
        </div>
        <div className="container mx-auto lg:px-4 lg:pt-6 px-3 w-full flex flex-wrap gap-4 ">
          <div className="grayWhiteBox colWidth-3">
            <div className="iconBox">
              <img src={ProcurementIcon1} alt="" />
            </div>
            <div className="TextBox">
              <b>Unpredictable Costs</b>
              Rising material prices and lack of transparency in sourcing.
            </div>
          </div>

          <div className="grayWhiteBox colWidth-3">
            <div className="iconBox">
              <img src={ProcurementIcon2} alt="" />
            </div>
            <div className="TextBox">
              <b>Quality Inconsistencies</b>
              Difficulty ensuring reliable and consistent material quality.
            </div>
          </div>

          <div className="grayWhiteBox colWidth-3">
            <div className="iconBox">
              <img src={ProcurementIcon3} alt="" />
            </div>
            <div className="TextBox">
              <b>Delayed Deliveries</b>
              Supply chain disruptions impacting timely availability.
            </div>
          </div>

          <div className="grayWhiteBox colWidth-3">
            <div className="iconBox">
              <img src={ProcurementIcon4} alt="" />
            </div>
            <div className="TextBox">
              <b>Vendor Management Complexity</b>
              Managing multiple vendors with varying performance levels.
            </div>
          </div>

          <div className="grayWhiteBox colWidth-3">
            <div className="iconBox">
              <img src={ProcurementIcon5} alt="" />
            </div>
            <div className="TextBox">
              <b>Inefficient Processes</b>
              Manual procurement workflows causing delays and errors.
            </div>
          </div>

          <div className="grayWhiteBox colWidth-3">
            <div className="iconBox">
              <img src={ProcurementIcon6} alt="" />
            </div>
            <div className="TextBox">
              <b>Cash Flow Strains</b>
              Lack of flexible financing options for large-scale procurement.
            </div>
          </div>
        </div>
      </div>

      <div className="primary500Bg w-full">
        <div
          className= 'container mx-auto lg:px-4 px-2 lg:py-24 py-8 overflow-hidden'
        >
          <div className={`headingContainer`}>
            <div className="heading">
              <span className="white">
                Smart Sourcing for Smarter Steel Supply
              </span>
            </div>
            <div className="Subheading white">
              Assured Quality, Huge Cost{" "}
              <span className="success400">Savings</span>
            </div>
          </div>

          <AccordionExpandDefault />
          <div className="w-full lg:px-4 lg:pt-[60px] px-2 mx-auto flex lg:columns-2 columns-1 lg:flex-row flex-col lg:gap-10 gap-2 ScoreBox justify-between">
            <div className={`lg:w-3/5 w-full`}>
              <h3 className="block pb-4">
                <span>Check Instantre</span> Are You Buying Costly Steel?
              </h3>
              <p>
                Know how much more Efficient , Cost-Savings and Customer-Centric
                ,Your Existing Supply Chain can be? Drive Profitability with
                Steelbazaar Performance Scores. Steel Bazaar helps in optimising
                and bringing huge real potential value to you.
              </p>
              <b className="py-4 block">
                Discover the Power of Performance Metrics.
              </b>

              <div className="flex gap-x-2">
                <Link to="." className="LearnMore">
                  Learn More
                </Link>
                <Link to="." className="ContactUs">
                  Contact us now
                </Link>
              </div>
            </div>
            <div className="lg:w-1/4 w-full grid content-center text-center">
              <div className="ScoreStatusBox">
                <h4>Evaluate Your Supply Chain Score Now</h4>
                <div className="ScoreItem">
                  <img src={meter} alt="" />
                  <span>8/10</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className= 'container mx-auto lg:px-4 px-2 lg:py-[90px] py-8 overflow-hidden'
      >
        <div className={`headingContainer`}>
          <div className="heading">
            Our Streamlined Procurement Process from{" "}
            <span>Requisition to Delivery</span>
          </div>
          <div className="Subheading">
            Designed for precision and cost-efficiency procurement, Here's how
            it works.
          </div>
        </div>
        <div className="flex flex-col lg:pt-6">
          <div className="roundZigZag lg:pr-16 pr-0">
            <div className="flex justify-end">
              <div className="flex columns-2 lg:w-7/12 sm:w-full w-full items-center">
                <div className="w-1/4 flex justify-center">
                  <div className="NumberBoxRight">
                    <span>01</span>
                  </div>
                </div>
                <div className="w-3/4">
                  <div className="TextBox flex flex-col">
                    <b>Identify Steel Requirements</b>
                    Determine specific steel needs, including type, quantity,
                    quality standards, delivery timelines, and other attributes
                    to meet project specifications.
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="roundZigZag lg:pl-16 pl-0">
            <div className="flex justify-start">
              <div className="flex columns-2 lg:w-7/12 sm:w-full w-full items-center">
                <div className="w-3/4">
                  <div className="TextBox flex flex-col text-right">
                    <b>Supplier Selection</b>
                    Evaluate and choose from a global & domestic network of
                    vetted suppliers offering competitive pricing, quality,
                    compliance & reliable delivery.
                  </div>
                </div>

                <div className="w-1/4 flex justify-center">
                  <div className="NumberBoxLeft">
                    <span>02</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="roundZigZag lg:pr-16 pr-0">
            <div className="flex justify-end">
              <div className="flex columns-2 lg:w-7/12 sm:w-full w-full items-center">
                <div className="w-1/4 flex justify-center">
                  <div className="NumberBoxRight">
                    <span>03</span>
                  </div>
                </div>
                <div className="w-3/4">
                  <div className="TextBox flex flex-col">
                    <b>Request for Quotation (RFQ)</b>
                    Submit detailed RFQs to selected suppliers to obtain
                    accurate pricing and delivery timelines.
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="roundZigZag lg:pl-16 pl-0">
            <div className="flex justify-start">
              <div className="flex columns-2 lg:w-7/12 sm:w-full w-full items-center">
                <div className="w-3/4">
                  <div className="TextBox flex flex-col text-right">
                    <b>Quotation Evaluation</b>
                    Assess received quotations based on cost, quality, supplier
                    reliability & alignment with project timelines.
                  </div>
                </div>

                <div className="w-1/4 flex justify-center">
                  <div className="NumberBoxLeft">
                    <span>04</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="roundZigZag lg:pr-16 pr-0">
            <div className="flex justify-end">
              <div className="flex columns-2 lg:w-7/12 sm:w-full w-full items-center">
                <div className="w-1/4 flex justify-center">
                  <div className="NumberBoxRight">
                    <span>05</span>
                  </div>
                </div>
                <div className="w-3/4">
                  <div className="TextBox flex flex-col">
                    <b>Negotiation and Contracting</b>
                    Negotiate to finalize terms, ensuring favourable conditions
                    before formalizing agreements.
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="roundZigZag lg:pl-16 pl-0">
            <div className="flex justify-start">
              <div className="flex columns-2 lg:w-7/12 sm:w-full w-full  items-center">
                <div className="w-3/4">
                  <div className="TextBox flex flex-col text-right">
                    <b>Order Placement</b>
                    Issue purchase orders to chosen suppliers detailing product
                    specifications, quantities and delivery schedules.
                  </div>
                </div>

                <div className="w-1/4 flex justify-center">
                  <div className="NumberBoxLeft">
                    <span>06</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="roundZigZag lg:pr-16 pr-0">
            <div className="flex justify-end">
              <div className="flex columns-2 lg:w-7/12 sm:w-full w-full items-center">
                <div className="w-1/4 flex justify-center">
                  <div className="NumberBoxRight">
                    <span>07</span>
                  </div>
                </div>
                <div className="w-3/4">
                  <div className="TextBox flex flex-col">
                    <b>Production Monitoring</b>
                    Oversee manufacturing progress to ensure adherence to
                    quality standards and agreed timelines.
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="roundZigZag lg:pl-16 pl-0">
            <div className="flex justify-start">
              <div className="flex columns-2 lg:w-7/12 sm:w-full w-full items-center">
                <div className="w-3/4">
                  <div className="TextBox flex flex-col text-right">
                    <b>Quality Inspection</b>
                    Conduct thorough inspections upon delivery to verify
                    compliance with specified quality and standards.
                  </div>
                </div>

                <div className="w-1/4 flex justify-center">
                  <div className="NumberBoxLeft">
                    <span>08</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="roundZigZag lg:pr-16 pr-0">
            <div className="flex justify-end">
              <div className="flex columns-2 lg:w-7/12 sm:w-full w-full items-center">
                <div className="w-1/4 flex justify-center">
                  <div className="NumberBoxRight">
                    <span>09</span>
                  </div>
                </div>
                <div className="w-3/4">
                  <div className="TextBox flex flex-col">
                    <b>Logistics and Delivery</b>
                    Coordinate transportation and logistics to ensure timely and
                    safe delivery of steel products.
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="roundZigZag lg:pl-16 pl-0">
            <div className="flex justify-start">
              <div className="flex columns-2 lg:w-7/12 sm:w-full w-full items-center">
                <div className="w-3/4">
                  <div className="TextBox flex flex-col text-right">
                    <b>Payment and Documentation</b>
                    Process payments as per agreed terms and maintain
                    comprehensive records for future reference
                  </div>
                </div>

                <div className="w-1/4 flex justify-center">
                  <div className="NumberBoxLeft">
                    <span>10</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className= 'container mx-auto lg:px-4 lg:pt-[90px] px-2 lg:pb-24 py-8'
      >
        <div className={`headingContainer lg:!mb-10`}>
          <div className="heading">
            Our Unique Features of <span>Procurement Solutions</span>
          </div>
        </div>
        {
          // Section One Start Here
        }
        <div className="flex lg:columns-2 columns-1 lg:flex-row flex-col items-center pt-0 lg:gap-20 ZigZagSection">
          <div className="2xl:w-4/12 lg:w-5/12 w-full flex content-center justify-center">
            <div className="ZigZagImgBox">
              <div className="ImgItem">
                <img src={DotImg} alt="" />
              </div>
              <img src={accordianImg1} className="w-full" alt="" />
            </div>
          </div>
          <div className="2xl:w-8/12 lg:w-7/12 w-full ZigZagText">
            <div className="heading48">
              Comprehensive Material{" "}
              <span className="underLine">Sourcing Solutions</span>
            </div>
            <div className="Subhead">
              <b>Optimize Costs with Divers e Sourcing Strategies</b>
            </div>

            <div className="PointBox">
              <div className={classes.accordion}>
                <div className={classes.tab}>
                  <input
                    type="checkbox"
                    id="cb1"
                    checked={expanded === "tab1"}
                    onChange={() => toggleAccordion("tab1")}
                  />
                  <label
                    htmlFor="cb1"
                    className={`${classes.tabLabel} ${
                      expanded === "tab1" ? classes.tabLabelExpanded : ""
                    }`}
                  >
                    <span className={`${classes.accodText} font-medium`}>
                      Real-Time Priced Catalogs
                    </span>
                  </label>
                  <div
                    className={`${classes.tabContent} ${
                      expanded === "tab1" ? classes.tabContentExpanded : ""
                    }`}
                  >
                    <div className={classes.contentInner}>
                      <p>
                        Access up-to-date pricing to make informed purchasing
                        decisions.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className={classes.accordion}>
                <div className={classes.tab}>
                  <input
                    type="checkbox"
                    id="cb2"
                    checked={expanded === "tab2"}
                    onChange={() => toggleAccordion("tab2")}
                  />
                  <label
                    htmlFor="cb2"
                    className={`${classes.tabLabel} ${
                      expanded === "tab2" ? classes.tabLabelExpanded : ""
                    }`}
                  >
                    <span className={`${classes.accodText} font-medium`}>
                      Brand Comparison Tools
                    </span>
                  </label>
                  <div
                    className={`${classes.tabContent} ${
                      expanded === "tab2" ? classes.tabContentExpanded : ""
                    }`}
                  >
                    <div className={classes.contentInner}>
                      <p>
                        Evaluate products based on real-time market prices and
                        quality to select the best fit.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className={classes.accordion}>
                <div className={classes.tab}>
                  <input
                    type="checkbox"
                    id="cb3"
                    checked={expanded === "tab3"}
                    onChange={() => toggleAccordion("tab3")}
                  />
                  <label
                    htmlFor="cb3"
                    className={`${classes.tabLabel} ${
                      expanded === "tab3" ? classes.tabLabelExpanded : ""
                    }`}
                  >
                    <span className={`${classes.accodText} font-medium`}>
                      Pool and Save Programs
                    </span>
                  </label>
                  <div
                    className={`${classes.tabContent} ${
                      expanded === "tab3" ? classes.tabContentExpanded : ""
                    }`}
                  >
                    <div className={classes.contentInner}>
                      <p>
                        Collaborate with other buyers to leverage collective
                        purchasing power for discounts.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className={classes.accordion}>
                <div className={classes.tab}>
                  <input
                    type="checkbox"
                    id="cb4"
                    checked={expanded === "tab4"}
                    onChange={() => toggleAccordion("tab4")}
                  />
                  <label
                    htmlFor="cb4"
                    className={`${classes.tabLabel} ${
                      expanded === "tab4" ? classes.tabLabelExpanded : ""
                    }`}
                  >
                    <span className={`${classes.accodText} font-medium`}>
                      Bulk Purchasing Opportunities
                    </span>
                  </label>
                  <div
                    className={`${classes.tabContent} ${
                      expanded === "tab4" ? classes.tabContentExpanded : ""
                    }`}
                  >
                    <div className={classes.contentInner}>
                      <p>
                        Benefit from reduced prices by ordering larger
                        quantities.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {
          // Section Two Start Here
        }
        <div className="flex lg:columns-2 columns-1 lg:flex-row flex-col items-center lg:gap-20 ZigZagSection">
          <div className="2xl:w-4/12 lg:w-5/12 w-full flex order-1 lg:order-2 justify-center">
            <div className="ZigZagImgBox">
              <div className="ImgItemLeft">
                <img src={DotImg} alt="" />
              </div>
              <img src={accordianImg2} className="w-full" alt="" />
            </div>
          </div>
          <div className="2xl:w-8/12 lg:w-7/12 w-full ZigZagText order-2 lg:order-1">
            <div className="heading48">
              <span className="underLine">Global Sourcing</span> for Superior
              Quality & Pricing
            </div>
            <div className="PointBox">
              <div className={classes.accordion}>
                <div className={classes.tab}>
                  <input
                    type="checkbox"
                    id="cb5"
                    checked={expanded === "tab5"}
                    onChange={() => toggleAccordion("tab5")}
                  />
                  <label
                    htmlFor="cb5"
                    className={`${classes.tabLabel} ${
                      expanded === "tab5" ? classes.tabLabelExpanded : ""
                    }`}
                  >
                    <span className={`${classes.accodText} font-medium`}>
                      Partnerships with Suppliers
                    </span>
                  </label>
                  <div
                    className={`${classes.tabContent} ${
                      expanded === "tab5" ? classes.tabContentExpanded : ""
                    }`}
                  >
                    <div className={classes.contentInner}>
                      <p>
                        Forming alliances with reputed international & Domestic
                        Suppliers to secure cost-effective materials.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className={classes.accordion}>
                <div className={classes.tab}>
                  <input
                    type="checkbox"
                    id="cb6"
                    checked={expanded === "tab6"}
                    onChange={() => toggleAccordion("tab6")}
                  />
                  <label
                    htmlFor="cb6"
                    className={`${classes.tabLabel} ${
                      expanded === "tab6" ? classes.tabLabelExpanded : ""
                    }`}
                  >
                    <span className={`${classes.accodText} font-medium`}>
                      Cross-Border Solutions
                    </span>
                  </label>
                  <div
                    className={`${classes.tabContent} ${
                      expanded === "tab6" ? classes.tabContentExpanded : ""
                    }`}
                  >
                    <div className={classes.contentInner}>
                      <p>
                        Implementing cross-border procurement solutions to
                        leverage global supply chains.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className={classes.accordion}>
                <div className={classes.tab}>
                  <input
                    type="checkbox"
                    id="cb7"
                    checked={expanded === "tab7"}
                    onChange={() => toggleAccordion("tab7")}
                  />
                  <label
                    htmlFor="cb7"
                    className={`${classes.tabLabel} ${
                      expanded === "tab7" ? classes.tabLabelExpanded : ""
                    }`}
                  >
                    <span className={`${classes.accodText} font-medium`}>
                      Compliance and Quality Control
                    </span>
                  </label>
                  <div
                    className={`${classes.tabContent} ${
                      expanded === "tab7" ? classes.tabContentExpanded : ""
                    }`}
                  >
                    <div className={classes.contentInner}>
                      <p>
                        Ensuring compliance and quality control to meet
                        international product standards
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className={classes.accordion}>
                <div className={classes.tab}>
                  <input
                    type="checkbox"
                    id="cb8"
                    checked={expanded === "tab8"}
                    onChange={() => toggleAccordion("tab8")}
                  />
                  <label
                    htmlFor="cb8"
                    className={`${classes.tabLabel} ${
                      expanded === "tab8" ? classes.tabLabelExpanded : ""
                    }`}
                  >
                    <span className={`${classes.accodText} font-medium`}>
                      Access to Diverse Steel Grades
                    </span>
                  </label>
                  <div
                    className={`${classes.tabContent} ${
                      expanded === "tab8" ? classes.tabContentExpanded : ""
                    }`}
                  >
                    <div className={classes.contentInner}>
                      <p>
                        Providing access to a variety of steel grades and
                        products that are not available locally.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {
          // Section Three Start Here
        }
        <div className="flex lg:columns-2 columns-1 lg:flex-row flex-col items-center lg:gap-20 ZigZagSection">
          <div className="2xl:w-4/12 lg:w-5/12 w-full flex content-center justify-center">
            <div className="ZigZagImgBox">
              <div className="ImgItem">
                <img src={DotImg} alt="" />
              </div>
              <img src={accordianImg3} className="w-full" alt="" />
            </div>
          </div>
          <div className="2xl:w-8/12 lg:w-7/12 w-full ZigZagText">
            <div className="heading48">
              <span className="underLine">End-to-end</span> Digital Procurement
            </div>
            <div className="PointBox">
              <div className={classes.accordion}>
                <div className={classes.tab}>
                  <input
                    type="checkbox"
                    id="cb9"
                    checked={expanded === "tab9"}
                    onChange={() => toggleAccordion("tab9")}
                  />
                  <label
                    htmlFor="cb9"
                    className={`${classes.tabLabel} ${
                      expanded === "tab9" ? classes.tabLabelExpanded : ""
                    }`}
                  >
                    <span className={`${classes.accodText} font-medium`}>
                      Advanced Digital Platform
                    </span>
                  </label>
                  <div
                    className={`${classes.tabContent} ${
                      expanded === "tab9" ? classes.tabContentExpanded : ""
                    }`}
                  >
                    <div className={classes.contentInner}>
                      <p>
                        Utilize a user-friendly digital interface to streamline
                        procurement processes and enhance operational
                        efficiency.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className={classes.accordion}>
                <div className={classes.tab}>
                  <input
                    type="checkbox"
                    id="cb10"
                    checked={expanded === "tab10"}
                    onChange={() => toggleAccordion("tab10")}
                  />
                  <label
                    htmlFor="cb10"
                    className={`${classes.tabLabel} ${
                      expanded === "tab10" ? classes.tabLabelExpanded : ""
                    }`}
                  >
                    <span className={`${classes.accodText} font-medium`}>
                      Real-Time Market Insights
                    </span>
                  </label>
                  <div
                    className={`${classes.tabContent} ${
                      expanded === "tab10" ? classes.tabContentExpanded : ""
                    }`}
                  >
                    <div className={classes.contentInner}>
                      <p>
                        Stay informed with up-to-date market data, enabling
                        strategic decision-making and timely procurement.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className={classes.accordion}>
                <div className={classes.tab}>
                  <input
                    type="checkbox"
                    id="cb11"
                    checked={expanded === "tab11"}
                    onChange={() => toggleAccordion("tab11")}
                  />
                  <label
                    htmlFor="cb11"
                    className={`${classes.tabLabel} ${
                      expanded === "tab11" ? classes.tabLabelExpanded : ""
                    }`}
                  >
                    <span className={`${classes.accodText} font-medium`}>
                      Transparent Transactions
                    </span>
                  </label>
                  <div
                    className={`${classes.tabContent} ${
                      expanded === "tab11" ? classes.tabContentExpanded : ""
                    }`}
                  >
                    <div className={classes.contentInner}>
                      <p>
                        Ensure clarity and trust in all dealings, minimising
                        risks and fostering strong supplier relationships
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className={classes.accordion}>
                <div className={classes.tab}>
                  <input
                    type="checkbox"
                    id="cb12"
                    checked={expanded === "tab12"}
                    onChange={() => toggleAccordion("tab12")}
                  />
                  <label
                    htmlFor="cb12"
                    className={`${classes.tabLabel} ${
                      expanded === "tab12" ? classes.tabLabelExpanded : ""
                    }`}
                  >
                    <span className={`${classes.accodText} font-medium`}>
                      Dedicated Customer Support
                    </span>
                  </label>
                  <div
                    className={`${classes.tabContent} ${
                      expanded === "tab12" ? classes.tabContentExpanded : ""
                    }`}
                  >
                    <div className={classes.contentInner}>
                      <p>
                        Receive continuous assistance from our expert team,
                        ensuring a seamless procurement experience.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {
          // Section Four Start Here
        }
        <div className="flex lg:columns-2 columns-1 lg:flex-row flex-col items-center lg:gap-20 ZigZagSection">
          <div className="2xl:w-4/12 lg:w-5/12 w-full flex order-1 lg:order-2 justify-center">
            <div className="ZigZagImgBox">
              <div className="ImgItemLeft">
                <img src={DotImg} alt="" />
              </div>
              <img src={accordianImg4} className="w-full" alt="" />
            </div>
          </div>
          <div className="2xl:w-8/12 lg:w-7/12 w-full ZigZagText order-2 lg:order-1">
            <div className="heading48">
              Enhanced <span className="underLine">Vendor Management</span> for
              Optimal Results
            </div>
            <div className="PointBox">
              <div className={classes.accordion}>
                <div className={classes.tab}>
                  <input
                    type="checkbox"
                    id="cb13"
                    checked={expanded === "tab13"}
                    onChange={() => toggleAccordion("tab13")}
                  />
                  <label
                    htmlFor="cb13"
                    className={`${classes.tabLabel} ${
                      expanded === "tab13" ? classes.tabLabelExpanded : ""
                    }`}
                  >
                    <span className={`${classes.accodText} font-medium`}>
                      Vendor Selection
                    </span>
                  </label>
                  <div
                    className={`${classes.tabContent} ${
                      expanded === "tab13" ? classes.tabContentExpanded : ""
                    }`}
                  >
                    <div className={classes.contentInner}>
                      <p>
                        Evaluate vendors based on performance metrics to ensure
                        quality partnerships.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className={classes.accordion}>
                <div className={classes.tab}>
                  <input
                    type="checkbox"
                    id="cb14"
                    checked={expanded === "tab14"}
                    onChange={() => toggleAccordion("tab14")}
                  />
                  <label
                    htmlFor="cb14"
                    className={`${classes.tabLabel} ${
                      expanded === "tab14" ? classes.tabLabelExpanded : ""
                    }`}
                  >
                    <span className={`${classes.accodText} font-medium`}>
                      Regular Audits
                    </span>
                  </label>
                  <div
                    className={`${classes.tabContent} ${
                      expanded === "tab14" ? classes.tabContentExpanded : ""
                    }`}
                  >
                    <div className={classes.contentInner}>
                      <p>
                        Conduct vendor audits consistently to maintain quality
                        and compliance standards.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className={classes.accordion}>
                <div className={classes.tab}>
                  <input
                    type="checkbox"
                    id="cb15"
                    checked={expanded === "tab15"}
                    onChange={() => toggleAccordion("tab15")}
                  />
                  <label
                    htmlFor="cb15"
                    className={`${classes.tabLabel} ${
                      expanded === "tab15" ? classes.tabLabelExpanded : ""
                    }`}
                  >
                    <span className={`${classes.accodText} font-medium`}>
                      Performance
                    </span>
                  </label>
                  <div
                    className={`${classes.tabContent} ${
                      expanded === "tab15" ? classes.tabContentExpanded : ""
                    }`}
                  >
                    <div className={classes.contentInner}>
                      <p>
                        Tracking: Utilize real-time analytics for effective
                        supplier performance tracking.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className={classes.accordion}>
                <div className={classes.tab}>
                  <input
                    type="checkbox"
                    id="cb16"
                    checked={expanded === "tab16"}
                    onChange={() => toggleAccordion("tab16")}
                  />
                  <label
                    htmlFor="cb16"
                    className={`${classes.tabLabel} ${
                      expanded === "tab16" ? classes.tabLabelExpanded : ""
                    }`}
                  >
                    <span className={`${classes.accodText} font-medium`}>
                      Vendor
                    </span>
                  </label>
                  <div
                    className={`${classes.tabContent} ${
                      expanded === "tab16" ? classes.tabContentExpanded : ""
                    }`}
                  >
                    <div className={classes.contentInner}>
                      <p>
                        Development Implement vendor development programs aimed
                        at enhancing supplier capabilities.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {
          // Section Five Start Here
        }
        <div className="flex lg:columns-2 columns-1 lg:flex-row flex-col items-center lg:gap-20 ZigZagSection">
          <div className="2xl:w-4/12 lg:w-5/12 w-full flex content-center justify-center">
            <div className="ZigZagImgBox">
              <div className="ImgItem">
                <img src={DotImg} alt="" />
              </div>
              <img src={accordianImg5} className="w-full" alt="" />
            </div>
          </div>
          <div className="2xl:w-8/12 lg:w-7/12 w-full ZigZagText">
            <div className="heading48">
              Innovative <span className="underLine">Reverse Auction</span>{" "}
              Procurement Drive Down Costs
            </div>
            <div className="Subhead">
              <b>Optimize Costs with Divers e Sourcing Strategies</b>
            </div>

            <div className="PointBox">
              <div className={classes.accordion}>
                <div className={classes.tab}>
                  <input
                    type="checkbox"
                    id="cb17"
                    checked={expanded === "tab17"}
                    onChange={() => toggleAccordion("tab17")}
                  />
                  <label
                    htmlFor="cb17"
                    className={`${classes.tabLabel} ${
                      expanded === "tab17" ? classes.tabLabelExpanded : ""
                    }`}
                  >
                    <span className={`${classes.accodText} font-medium`}>
                      Competitive Bids
                    </span>
                  </label>
                  <div
                    className={`${classes.tabContent} ${
                      expanded === "tab17" ? classes.tabContentExpanded : ""
                    }`}
                  >
                    <div className={classes.contentInner}>
                      <p>
                        Reverse auction platform enabling buyers to receive
                        competitive bids from suppliers.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className={classes.accordion}>
                <div className={classes.tab}>
                  <input
                    type="checkbox"
                    id="cb18"
                    checked={expanded === "tab18"}
                    onChange={() => toggleAccordion("tab18")}
                  />
                  <label
                    htmlFor="cb18"
                    className={`${classes.tabLabel} ${
                      expanded === "tab18" ? classes.tabLabelExpanded : ""
                    }`}
                  >
                    <span className={`${classes.accodText} font-medium`}>
                      Cost Reduction
                    </span>
                  </label>
                  <div
                    className={`${classes.tabContent} ${
                      expanded === "tab18" ? classes.tabContentExpanded : ""
                    }`}
                  >
                    <div className={classes.contentInner}>
                      <p>
                        Cost reduction through dynamic bidding from
                        pre-qualified vendors.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className={classes.accordion}>
                <div className={classes.tab}>
                  <input
                    type="checkbox"
                    id="cb19"
                    checked={expanded === "tab19"}
                    onChange={() => toggleAccordion("tab19")}
                  />
                  <label
                    htmlFor="cb19"
                    className={`${classes.tabLabel} ${
                      expanded === "tab19" ? classes.tabLabelExpanded : ""
                    }`}
                  >
                    <span className={`${classes.accodText} font-medium`}>
                      Transparency and Competition
                    </span>
                  </label>
                  <div
                    className={`${classes.tabContent} ${
                      expanded === "tab19" ? classes.tabContentExpanded : ""
                    }`}
                  >
                    <div className={classes.contentInner}>
                      <p>
                        Enhanced transparency and competition among suppliers.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className={classes.accordion}>
                <div className={classes.tab}>
                  <input
                    type="checkbox"
                    id="cb20"
                    checked={expanded === "tab20"}
                    onChange={() => toggleAccordion("tab20")}
                  />
                  <label
                    htmlFor="cb20"
                    className={`${classes.tabLabel} ${
                      expanded === "tab20" ? classes.tabLabelExpanded : ""
                    }`}
                  >
                    <span className={`${classes.accodText} font-medium`}>
                      Streamlined Procurement
                    </span>
                  </label>
                  <div
                    className={`${classes.tabContent} ${
                      expanded === "tab20" ? classes.tabContentExpanded : ""
                    }`}
                  >
                    <div className={classes.contentInner}>
                      <p>
                        Streamlined procurement process with real-time pricing
                        during auctions.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {
          // Section Six Start Here
        }
        <div className="flex lg:columns-2 columns-1 lg:flex-row flex-col items-center lg:gap-20 ZigZagSection">
          <div className="2xl:w-4/12 lg:w-5/12 w-full flex order-1 lg:order-2 justify-center">
            <div className="ZigZagImgBox">
              <div className="ImgItemLeft">
                <img src={DotImg} alt="" />
              </div>
              <img src={accordianImg6} className="w-full" alt="" />
            </div>
          </div>
          <div className="2xl:w-8/12 lg:w-7/12 w-full ZigZagText order-2 lg:order-1">
            <div className="heading48">
              Get Products <span className="underLine">Customized</span> to Your
              Specifications
            </div>
            <div className="PointBox">
              <div className={classes.accordion}>
                <div className={classes.tab}>
                  <input
                    type="checkbox"
                    id="cb21"
                    checked={expanded === "tab21"}
                    onChange={() => toggleAccordion("tab21")}
                  />
                  <label
                    htmlFor="cb21"
                    className={`${classes.tabLabel} ${
                      expanded === "tab21" ? classes.tabLabelExpanded : ""
                    }`}
                  >
                    <span className={`${classes.accodText} font-medium`}>
                      Customised Steel Products
                    </span>
                  </label>
                  <div
                    className={`${classes.tabContent} ${
                      expanded === "tab21" ? classes.tabContentExpanded : ""
                    }`}
                  >
                    <div className={classes.contentInner}>
                      <p>
                        Steel products are tailored to meet industry-specific
                        needs.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className={classes.accordion}>
                <div className={classes.tab}>
                  <input
                    type="checkbox"
                    id="cb22"
                    checked={expanded === "tab22"}
                    onChange={() => toggleAccordion("tab22")}
                  />
                  <label
                    htmlFor="cb22"
                    className={`${classes.tabLabel} ${
                      expanded === "tab22" ? classes.tabLabelExpanded : ""
                    }`}
                  >
                    <span className={`${classes.accodText} font-medium`}>
                      Tailor-Made Specifications
                    </span>
                  </label>
                  <div
                    className={`${classes.tabContent} ${
                      expanded === "tab22" ? classes.tabContentExpanded : ""
                    }`}
                  >
                    <div className={classes.contentInner}>
                      <p>
                        Specifications designed for specialised manufacturing
                        requirements.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className={classes.accordion}>
                <div className={classes.tab}>
                  <input
                    type="checkbox"
                    id="cb23"
                    checked={expanded === "tab23"}
                    onChange={() => toggleAccordion("tab23")}
                  />
                  <label
                    htmlFor="cb23"
                    className={`${classes.tabLabel} ${
                      expanded === "tab23" ? classes.tabLabelExpanded : ""
                    }`}
                  >
                    <span className={`${classes.accodText} font-medium`}>
                      Advanced Manufacturing
                    </span>
                  </label>
                  <div
                    className={`${classes.tabContent} ${
                      expanded === "tab23" ? classes.tabContentExpanded : ""
                    }`}
                  >
                    <div className={classes.contentInner}>
                      <p>
                        Services Access to cutting-edge manufacturing services
                        through Steelbazaar’s network.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className={classes.accordion}>
                <div className={classes.tab}>
                  <input
                    type="checkbox"
                    id="cb24"
                    checked={expanded === "tab24"}
                    onChange={() => toggleAccordion("tab24")}
                  />
                  <label
                    htmlFor="cb24"
                    className={`${classes.tabLabel} ${
                      expanded === "tab24" ? classes.tabLabelExpanded : ""
                    }`}
                  >
                    <span className={`${classes.accodText} font-medium`}>
                      Prototype and Trial
                    </span>
                  </label>
                  <div
                    className={`${classes.tabContent} ${
                      expanded === "tab24" ? classes.tabContentExpanded : ""
                    }`}
                  >
                    <div className={classes.contentInner}>
                      <p>
                        Production Opportunity for prototype and trial
                        production before placing a final bulk order.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {
          // Section Seven Start Here
        }
        <div className="flex lg:columns-2 columns-1 lg:flex-row flex-col items-center lg:gap-20 ZigZagSection">
          <div className="2xl:w-4/12 lg:w-5/12 w-full flex content-center justify-center">
            <div className="ZigZagImgBox">
              <div className="ImgItem">
                <img src={DotImg} alt="" />
              </div>
              <img src={accordianImg7} className="w-full" alt="" />
            </div>
          </div>
          <div className="2xl:w-8/12 lg:w-7/12 w-full ZigZagText">
            <div className="heading48">
              Unlocking <span className="underLine">Financial Flexibility</span>{" "}
              For Your Enterprises
            </div>
            <div className="PointBox">
              <div className={classes.accordion}>
                <div className={classes.tab}>
                  <input
                    type="checkbox"
                    id="cb25"
                    checked={expanded === "tab25"}
                    onChange={() => toggleAccordion("tab25")}
                  />
                  <label
                    htmlFor="cb25"
                    className={`${classes.tabLabel} ${
                      expanded === "tab25" ? classes.tabLabelExpanded : ""
                    }`}
                  >
                    <span className={`${classes.accodText} font-medium`}>
                      Tailored Credit
                    </span>
                  </label>
                  <div
                    className={`${classes.tabContent} ${
                      expanded === "tab25" ? classes.tabContentExpanded : ""
                    }`}
                  >
                    <div className={classes.contentInner}>
                      <p>
                        Credit solutions are specifically designed for corporate
                        clients engaged in large procurement activities.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className={classes.accordion}>
                <div className={classes.tab}>
                  <input
                    type="checkbox"
                    id="cb26"
                    checked={expanded === "tab26"}
                    onChange={() => toggleAccordion("tab26")}
                  />
                  <label
                    htmlFor="cb26"
                    className={`${classes.tabLabel} ${
                      expanded === "tab26" ? classes.tabLabelExpanded : ""
                    }`}
                  >
                    <span className={`${classes.accodText} font-medium`}>
                      Deferred Payment
                    </span>
                  </label>
                  <div
                    className={`${classes.tabContent} ${
                      expanded === "tab26" ? classes.tabContentExpanded : ""
                    }`}
                  >
                    <div className={classes.contentInner}>
                      <p>
                        Terms Flexible payment terms that allow clients to
                        manage their cash flow more effectively.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className={classes.accordion}>
                <div className={classes.tab}>
                  <input
                    type="checkbox"
                    id="cb27"
                    checked={expanded === "tab27"}
                    onChange={() => toggleAccordion("tab27")}
                  />
                  <label
                    htmlFor="cb27"
                    className={`${classes.tabLabel} ${
                      expanded === "tab27" ? classes.tabLabelExpanded : ""
                    }`}
                  >
                    <span className={`${classes.accodText} font-medium`}>
                      Supply Chain Finance
                    </span>
                  </label>
                  <div
                    className={`${classes.tabContent} ${
                      expanded === "tab27" ? classes.tabContentExpanded : ""
                    }`}
                  >
                    <div className={classes.contentInner}>
                      <p>
                        Options Financial solutions aimed at optimising working
                        capital within the supply chain.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className={classes.accordion}>
                <div className={classes.tab}>
                  <input
                    type="checkbox"
                    id="cb28"
                    checked={expanded === "tab28"}
                    onChange={() => toggleAccordion("tab28")}
                  />
                  <label
                    htmlFor="cb28"
                    className={`${classes.tabLabel} ${
                      expanded === "tab28" ? classes.tabLabelExpanded : ""
                    }`}
                  >
                    <span className={`${classes.accodText} font-medium`}>
                      Access to Trade
                    </span>
                  </label>
                  <div
                    className={`${classes.tabContent} ${
                      expanded === "tab28" ? classes.tabContentExpanded : ""
                    }`}
                  >
                    <div className={classes.contentInner}>
                      <p>
                        Financing Opportunities for trade financing to support
                        international procurement needs.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {
          // Section Eight Start Here
        }
        <div className="flex lg:columns-2 columns-1 lg:flex-row flex-col items-center lg:gap-20 ZigZagSection">
          <div className="2xl:w-4/12 lg:w-5/12 w-full flex order-1 lg:order-2 justify-center">
            <div className="ZigZagImgBox">
              <div className="ImgItemLeft">
                <img src={DotImg} alt="" />
              </div>
              <img src={accordianImg8} className="w-full" alt="" />
            </div>
          </div>
          <div className="2xl:w-8/12 lg:w-7/12 w-full ZigZagText order-2 lg:order-1">
            <div className="heading48">
              <span className="underLine">Assured Quality</span> in Every
              Procurement
            </div>
            <div className="PointBox">
              <div className={classes.accordion}>
                <div className={classes.tab}>
                  <input
                    type="checkbox"
                    id="cb29"
                    checked={expanded === "tab29"}
                    onChange={() => toggleAccordion("tab29")}
                  />
                  <label
                    htmlFor="cb29"
                    className={`${classes.tabLabel} ${
                      expanded === "tab29" ? classes.tabLabelExpanded : ""
                    }`}
                  >
                    <span className={`${classes.accodText} font-medium`}>
                      Strict Quality Control
                    </span>
                  </label>
                  <div
                    className={`${classes.tabContent} ${
                      expanded === "tab29" ? classes.tabContentExpanded : ""
                    }`}
                  >
                    <div className={classes.contentInner}>
                      <p>
                        Implementing stringent quality control measures at every
                        stage of the procurement process.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className={classes.accordion}>
                <div className={classes.tab}>
                  <input
                    type="checkbox"
                    id="cb30"
                    checked={expanded === "tab30"}
                    onChange={() => toggleAccordion("tab30")}
                  />
                  <label
                    htmlFor="cb30"
                    className={`${classes.tabLabel} ${
                      expanded === "tab30" ? classes.tabLabelExpanded : ""
                    }`}
                  >
                    <span className={`${classes.accodText} font-medium`}>
                      Third-Party Audits
                    </span>
                  </label>
                  <div
                    className={`${classes.tabContent} ${
                      expanded === "tab30" ? classes.tabContentExpanded : ""
                    }`}
                  >
                    <div className={classes.contentInner}>
                      <p>
                        Utilising third-party quality audits for thorough
                        material verification.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className={classes.accordion}>
                <div className={classes.tab}>
                  <input
                    type="checkbox"
                    id="cb27"
                    checked={expanded === "tab27"}
                    onChange={() => toggleAccordion("tab27")}
                  />
                  <label
                    htmlFor="cb27"
                    className={`${classes.tabLabel} ${
                      expanded === "tab27" ? classes.tabLabelExpanded : ""
                    }`}
                  >
                    <span className={`${classes.accodText} font-medium`}>
                      Real-Time Reporting
                    </span>
                  </label>
                  <div
                    className={`${classes.tabContent} ${
                      expanded === "tab27" ? classes.tabContentExpanded : ""
                    }`}
                  >
                    <div className={classes.contentInner}>
                      <p>
                        Providing real-time quality reports accessible through
                        the SB Maxx portal.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className={classes.accordion}>
                <div className={classes.tab}>
                  <input
                    type="checkbox"
                    id="cb28"
                    checked={expanded === "tab28"}
                    onChange={() => toggleAccordion("tab28")}
                  />
                  <label
                    htmlFor="cb28"
                    className={`${classes.tabLabel} ${
                      expanded === "tab28" ? classes.tabLabelExpanded : ""
                    }`}
                  >
                    <span className={`${classes.accodText} font-medium`}>
                      Adherence to Standards
                    </span>
                  </label>
                  <div
                    className={`${classes.tabContent} ${
                      expanded === "tab28" ? classes.tabContentExpanded : ""
                    }`}
                  >
                    <div className={classes.contentInner}>
                      <p>
                        Consistently adhering to international standards and
                        specifications.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {
          // Section Three Start Here
        }
        <div className="flex lg:columns-2 columns-1 lg:flex-row flex-col items-center lg:gap-20 ZigZagSection">
          <div className="2xl:w-4/12 lg:w-5/12 w-full flex content-center justify-center">
            <div className="ZigZagImgBox">
              <div className="ImgItem">
                <img src={DotImg} alt="" />
              </div>
              <img src={accordianImg9} className="w-full" alt="" />
            </div>
          </div>
          <div className="2xl:w-8/12 lg:w-7/12 w-full ZigZagText">
            <div className="heading48">
              <span className="underLine">On-Time Deliveries</span> Keeping Your
              Business Moving
            </div>

            <div className="PointBox">
              <div className={classes.accordion}>
                <div className={classes.tab}>
                  <input
                    type="checkbox"
                    id="cb29"
                    checked={expanded === "tab29"}
                    onChange={() => toggleAccordion("tab29")}
                  />
                  <label
                    htmlFor="cb29"
                    className={`${classes.tabLabel} ${
                      expanded === "tab29" ? classes.tabLabelExpanded : ""
                    }`}
                  >
                    <span className={`${classes.accodText} font-medium`}>
                      Real-time Order Tracking
                    </span>
                  </label>
                  <div
                    className={`${classes.tabContent} ${
                      expanded === "tab29" ? classes.tabContentExpanded : ""
                    }`}
                  >
                    <div className={classes.contentInner}>
                      <p>
                        Provides customers with real-time order tracking and
                        status updates.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className={classes.accordion}>
                <div className={classes.tab}>
                  <input
                    type="checkbox"
                    id="cb30"
                    checked={expanded === "tab30"}
                    onChange={() => toggleAccordion("tab30")}
                  />
                  <label
                    htmlFor="cb30"
                    className={`${classes.tabLabel} ${
                      expanded === "tab30" ? classes.tabLabelExpanded : ""
                    }`}
                  >
                    <span className={`${classes.accodText} font-medium`}>
                      Guaranteed Delivery Timelines
                    </span>
                  </label>
                  <div
                    className={`${classes.tabContent} ${
                      expanded === "tab30" ? classes.tabContentExpanded : ""
                    }`}
                  >
                    <div className={classes.contentInner}>
                      <p>
                        Offers guaranteed delivery timelines with penalty
                        clauses for late delivery.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className={classes.accordion}>
                <div className={classes.tab}>
                  <input
                    type="checkbox"
                    id="cb31"
                    checked={expanded === "tab31"}
                    onChange={() => toggleAccordion("tab31")}
                  />
                  <label
                    htmlFor="cb31"
                    className={`${classes.tabLabel} ${
                      expanded === "tab31" ? classes.tabLabelExpanded : ""
                    }`}
                  >
                    <span className={`${classes.accodText} font-medium`}>
                      Efficient Supply Chain Management
                    </span>
                  </label>
                  <div
                    className={`${classes.tabContent} ${
                      expanded === "tab31" ? classes.tabContentExpanded : ""
                    }`}
                  >
                    <div className={classes.contentInner}>
                      <p>
                        Leverages Steelbazaar’s logistics network for efficient
                        supply chain management.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className={classes.accordion}>
                <div className={classes.tab}>
                  <input
                    type="checkbox"
                    id="cb32"
                    checked={expanded === "tab32"}
                    onChange={() => toggleAccordion("tab32")}
                  />
                  <label
                    htmlFor="cb32"
                    className={`${classes.tabLabel} ${
                      expanded === "tab32" ? classes.tabLabelExpanded : ""
                    }`}
                  >
                    <span className={`${classes.accodText} font-medium`}>
                      Reduced Lead Times
                    </span>
                  </label>
                  <div
                    className={`${classes.tabContent} ${
                      expanded === "tab32" ? classes.tabContentExpanded : ""
                    }`}
                  >
                    <div className={classes.contentInner}>
                      <p>
                        Achieves reduced lead times through strategic
                        warehousing and local sourcing.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`${classes.container} container mx-auto lg:px-4 px-2 lg:py-[90px] py-8`}
      >
        <div className={`headingContainer`}>
          <div className="heading">
          Tailored Procurement Solutions <br /> for <span>Every Business</span>
          </div>
          <div className="Subheading">Smarter, Faster, Seamless</div>
        </div>

        <div className="w-full flex flex-col lg:flex-row lg:columns-3 md:columns-3 sm:columns-3 columns-1 gap-7 justify-center">
        <div>
            <div
              className={`${classes.cardTop1} py-[10px] lg:w-[320px] w-[280px] text-center font-semibold text-2xl `}
            >
              SMEs & Micro
            </div>
            <div
              className={`${classes.bussinessCard} h-[682px] rounded-2xl relative`}
            >
              <img src={iProcure} alt="" />
              <div className="px-[15px] ">
                <h5
                  className={`${classes.procureCardHeaidng3} my-3 font-semibold`}
                >
                  <span>i</span>Procure
                </h5>
                <p className="flex items-center gap-3 mb-5">
                  <img src={orangeTick} alt="" /> RM Sourcings: eBidding Based
                </p>
                <p className="flex items-center gap-3">
                  <img src={orangeTick} alt="" /> Inventory Management
                </p>
              </div>
              <div
                className={`${classes.cardTop1} ${classes.cardBottom1} py-[10px] w-full text-center font-semibold text-lg absolute bottom-0 flex gap-[10px] items-center justify-center`}
              >
                Explore More{" "}
                <div className={classes.btnArrow}>
                  <ArrowForwardIcon />
                </div>
              </div>
            </div>
          </div>
          <div>
            <div
              className={`${classes.cardTop2} py-[10px] w-[325px] text-center font-semibold text-2xl `}
            >
              Mid Corporate & MSMEs
            </div>
            <div
              className={`${classes.bussinessCard} h-[682px] rounded-2xl relative`}
            >
              <img src={procurePlus} alt="" />
              <div className="px-[15px] ">
                <h5
                  className={`${classes.procureCardHeaidng1} my-3 font-semibold`}
                >
                  iProcure <span>Plus</span>
                </h5>
                <div className="flex flex-col gap-5">
                  <p className="flex items-center gap-3">
                    <img src={blueTick} alt="" />
                    RM Sourcings: eBidding Based
                  </p>
                  <p className="flex items-center gap-3">
                    <img src={blueTick} alt="" /> Inventory Management
                  </p>
                  <p className="flex items-center gap-3">
                    <img src={blueTick} alt="" /> Supply Chain Finance
                  </p>
                  <p className="flex items-center gap-3">
                    <img src={blueTick} alt="" /> Logistics
                  </p>
                  <p className="flex items-center gap-3">
                    <img src={blueTick} alt="" /> Performance Metrics based
                    Dashboard
                  </p>
                </div>
              </div>
              <div
                className={`${classes.cardTop2} ${classes.cardBottom1} py-[10px] w-full text-center font-semibold text-lg absolute bottom-0 flex gap-[10px] items-center justify-center`}
              >
                Explore More{" "}
                <div className={classes.btnArrow}>
                  <ArrowForwardIcon />
                </div>
              </div>
            </div>
          </div>
          <div>
            <div
              className={`${classes.cardTop3} py-[10px] w-[325px] text-center font-semibold text-2xl `}
            >
              Enterprises
            </div>
            <div
              className={`${classes.bussinessCard} h-[682px] rounded-2xl relative`}
            >
              <img src={sbMaxCard} alt="" />
              <div className="px-[15px] ">
                <h5
                  className={`${classes.procureCardHeaidng2} my-3 font-semibold`}
                >
                  SB<span> Maxx</span>{" "}
                </h5>
                <div className="flex flex-col gap-5">
                  <p className="flex items-center gap-3">
                    <img src={primaryBlueTick} alt="" /> Supply Chain Planning
                  </p>
                  <p className="flex items-center gap-3">
                    <img src={primaryBlueTick} alt="" /> Demand and Forecasting
                  </p>
                  <p className="flex items-center gap-3">
                    <img src={primaryBlueTick} alt="" /> RM Sourcings: eBidding
                    Based
                  </p>
                  <p className="flex items-center gap-3">
                    <img src={primaryBlueTick} alt="" /> Supply Chain Finance
                  </p>
                  <p className="flex items-center gap-3">
                    <img src={primaryBlueTick} alt="" /> Inventory Management
                  </p>
                  <p className="flex items-center gap-3">
                    <img src={primaryBlueTick} alt="" /> Logistics
                  </p>
                  <p className="flex items-center gap-3">
                    <img src={primaryBlueTick} alt="" /> Performance Metrics
                    based Dashboard
                  </p>
                </div>
              </div>
              <div
                className={`${classes.cardTop3} ${classes.cardBottom1} py-[10px] w-full text-center font-semibold text-lg absolute bottom-0 flex gap-[10px] items-center justify-center`}
              >
                Explore More{" "}
                <div className={classes.btnArrow}>
                  <ArrowForwardIcon />
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      <div className="neutral20Bg lg:py-[90px] py-8 w-full">
        <div
          className={`${classes.container} container mx-auto lg:px-4 px-2`}
        >
          <div className={`headingContainer`}>
            <div className="heading">
              Benefits of Steelbazaar Procurement
            </div>
          </div>

          <div className="w-full flex flex-wrap lg:gap-4 gap-2 ">
            {cardData.map((card, index) => (
              <div key={index} className="whiteGrayBox colWidth-4 items-center">
                <div className="iconBox">
                  <img src={card.icon} alt={card.title} />
                </div>
                <div className="TextBox text-center">
                  <b>{card.title}</b>
                  {card.description}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
 
      <RequestDemoTemplate />
    </>
  );
};

export default ProcurementTemplate;
