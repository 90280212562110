import React, { useRef, useEffect } from "react";
import { createUseStyles } from "react-jss";
import { useLocation } from "react-router-dom";
import { Button } from "@mui/joy";
import RequestDemoTemplate from "../../template/Home/RequestDemo/RequestDemo.template";
import website from "../../../assets/images/website.jpg";
import moneyGrowth from "../../../assets/images/moneyGrowth.jpg";
import onlineMoney from "../../../assets/images/onlineMoney.jpg";
import onlineStore from "../../../assets/images/onlineStore.jpg";
import moneyCurrency from "../../../assets/images/moneyCurrency.jpg";
import analyst from "../../../assets/images/analyst.jpg";
import action from "../../../assets/images/action.jpg";
import rupees from "../../../assets/images/rupees.jpg";
import card1 from "../../../assets/images/card1.svg";
import pricing from "../../../assets/images/pricing.svg";
import SBDigiStore from "../../../assets/images/SBDigiStore.svg";
import Lead from "../../../assets/images/Lead.svg";
import bid from "../../../assets/images/bid.svg";
import management from "../../../assets/images/management.svg";
import sbpool from "../../../assets/images/sbpool.svg";
import instafin from "../../../assets/images/instafin.svg";
import insights from "../../../assets/images/insights.jpg";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import sellerBackground from "../../../assets/images/sellerBackground.jpg";
import sellerMobileBanner from "../../../assets/images/SellerBannerMobile.jpg";
//bykamal
import DotImg from "../../../assets/images/dot-shape.png";
import BlueArrowImg from "../../../assets/images/BlueArrowIcon.svg";
import YellowArrowImg from "../../../assets/images/YellowArrowIcon.svg";
import BulletImg from "../../../assets/images/bulletIcon.svg";
import Section1 from "../../../assets/images/Register_on_SteelBazaar.jpg";
import Section2 from "../../../assets/images/Create_Your_Seller_Profile.jpg";
import Section3 from "../../../assets/images/Access_the_Comprehensive_Dashboard.jpg";
import Section4 from "../../../assets/images/Manage_Inventory_Orders_Digitally.jpg";
import Section5 from "../../../assets/images/Create_Digital_Product_Catalogs_with_Predefined_SKUs.jpg";
import Section6 from "../../../assets/images/Build_Your_Personalized_Storefront.jpg";
import Section7 from "../../../assets/images/Publish_Your_Catalog_on_SteelBazaar.jpg";
import UnlockIcon1 from "../../../assets/images/Seller_ExploreIcon.svg";
import UnlockIcon2 from "../../../assets/images/Seller_MaximizeSalesIcon.svg";
import UnlockIcon3 from "../../../assets/images/Seller_CostIcon.svg";
import UnlockIcon4 from "../../../assets/images/Seller_EnhancedVisibilityIcon.svg";
import UnlockIcon5 from "../../../assets/images/Seller_EaseTransactionsIcon.svg";
import UnlockIcon6 from "../../../assets/images/Seller_AccessFinancingIcon.svg";
import SellerCTABg from "../../../assets/images/Seller_CTABg.jpg";

const useStyles = createUseStyles((theme: any) => ({
  bannerContainer: {
    background: `url(${sellerBackground}) #08102d`,
    backgroundSize: "cover",
  },
  container: {},
  cardHeadingSecondary: {
    color: '#000',
    fontSize: '28px',
    lineHeight: '34px',
  },
  featureCard: {
    borderRadius: "20px",
    overflow: "hidden",
  },

  //AddBykamal
  stepContainer: {
    borderBottom: `1px solid ${theme.palette.border.neutral.neutral200}`,
    textAlign: 'center', marginBottom: '8px',
    "& h6": {
      color: '#ffffff',
      fontSize: "24px",
      lineHeight: "28px",
      padding: '10px 5px',
      fontWeight: '590',
      position: 'relative', marginRight: '22px',
      "&.blue": {
        background: theme.palette.text.primary.primary400,
      },
      "&.yellow": {
        background: theme.palette.text.warning.warning400,
      },
      "&.green": {
        background: theme.palette.text.success.success400,
        borderTopRightRadius: '20px',
        borderBottomRightRadius: '20px', marginRight: '0px',
      },
      "& img": {
        position: 'absolute',
        top: '0px',
        right: '-22px',
      },
    },
    "& p": {
      fontSize: "16px",
      lineHeight: "20px",
      fontWeight: '400',
      color: theme.palette.text.neutral.neutral800,

    },
  },
  card01: {
    background: theme.palette.text.orange.orange200,
    width: '39%',
    "& img": {
      height: '298px',
    },
  },
  card02: {
    background: theme.palette.text.warning.warning100,
    width: '27%',
    "& img": {
      height: '298px',
    },

  },
  card03: {
    background: theme.palette.text.complementary.complementary100,
    width: '31%',
    "& img": {
      height: '258px', marginBottom: '40px',
    },
  },
  card04: {
    background: theme.palette.text.success.success100,
    width: '27%',
    "& img": {
      height: '258px',
    },
  },
  card05: {
    background: theme.palette.text.primary.primary100,
    width: '35%',
    "& img": {
      height: '442px',
    },
  },
  card06: {
    background: theme.palette.text.warning.warning500,
    width: '35%',
    "& img": {
      height: '270px',
    },
  },
  card07: {
    background: theme.palette.text.secondary.secondary100,
    width: '39%',
    "& img": {
      height: '342px',
    },
  },
  card08: {
    background: theme.palette.text.orange.orange100,
    width: '27%',
    "& img": {
      height: '240px',
      marginBottom: '40px',
    },
  },
  card09: {
    background: theme.palette.text.warning.warning100,
    width: '31%',
    "& img": {
      height: '365px',

    },
  },
  sellerCTA: {
    background: `url(${SellerCTABg})`,
    backgroundSize: 'cover',
  },
  '@media screen and (max-width: 1279px)': {

    card01: {
      width: '48%', marginBottom: '16px',

    },
    card02: {
      width: '48%', marginBottom: '16px',

    },
    card03: {
      width: '48%', marginBottom: '16px',

    },
    card04: {
      width: '48%', marginBottom: '16px',

    },
    card05: {
      width: '48%', marginBottom: '16px',

    },
    card06: {
      width: '48%', marginBottom: '16px',

    },
    card07: {
      width: '48%', marginBottom: '16px',

    },
    card08: {
      width: '48%', marginBottom: '16px',

    },
    card09: {
      width: '48%', marginBottom: '16px',

    },
  },
  '@media screen and (max-width: 1024px)': {

    card01: {
      width: '48%', marginBottom: '16px', "& img": {
        height: '140px',
      },
    },
    card02: {
      width: '48%', marginBottom: '16px', "& img": {
        height: '140px',
      },
    },
    card03: {
      width: '48%', marginBottom: '16px', "& img": {
        height: '140px', marginBottom: '40px',
      },
    },
    card04: {
      width: '48%', marginBottom: '16px', "& img": {
        height: '140px',
      },
    },
    card05: {
      width: '48%', marginBottom: '16px', "& img": {
        height: '180px',
      },
    },
    card06: {
      width: '48%', marginBottom: '16px', "& img": {
        height: '180px',
      },
    },
    card07: {
      width: '48%', marginBottom: '16px', "& img": {
        height: '140px',
      },
    },
    card08: {
      width: '48%', marginBottom: '16px', "& img": {
        height: '140px',
        marginBottom: '40px',
      },
    },
    card09: {
      width: '48%', marginBottom: '16px', "& img": {
        height: '180px',

      },
    },

  },
  "@media screen and (max-width: 480px)": {
    bannerContainer: {
      backgroundImage: `url(${sellerMobileBanner})`,
      backgroundSize: "cover",
    },
    stepContainer: {
      borderBottom: `0px solid ${theme.palette.border.neutral.neutral200}`,
      textAlign: 'center', marginBottom: '8px',
      "& h6": {
        color: '#ffffff',
        fontSize: "16px",
        lineHeight: "20px",
        padding: '10px 5px',
        fontWeight: '590', width: '150px', margin: '0 auto', height: '40px', borderRadius: '20px',
        position: 'relative', marginRight: '0px',
        "&.blue": {
          background: theme.palette.text.primary.primary400, margin: '0 auto',
        },
        "&.yellow": {
          background: theme.palette.text.warning.warning400, margin: '0 auto',
        },
        "&.green": {
          background: theme.palette.text.success.success400,
          borderTopRightRadius: '20px',
          borderBottomRightRadius: '20px',
          margin: '0 auto',
        },
        "& img": {
          position: 'absolute',
          top: '0px', display: 'none',
          right: '-22px',
        },
      },
      "& p": {
        fontSize: "16px",
        lineHeight: "20px",
        fontWeight: '400',
        color: theme.palette.text.neutral.neutral800,

      },
    },
    card01: {
      width: '100%', marginBottom: '16px',
    },
    card02: {
      width: '100%', marginBottom: '16px',
    },
    card03: {
      width: '100%', marginBottom: '16px',
    },
    card04: {
      width: '100%', marginBottom: '16px',
    },
    card05: {
      width: '100%', marginBottom: '16px',
    },
    card06: {
      width: '100%', marginBottom: '16px',
    },
    card07: {
      width: '100%', marginBottom: '16px',
    },
    card08: {
      width: '100%', marginBottom: '16px',
    },
    card09: {
      width: '100%', marginBottom: '16px',
    },
    sellerCTA: { backgroundPosition: 'bottom', }
  },
}));

const futureData = [
  {
    imgSrc: website,
    title: "Digital Inventory ",
    title2: "Management",
    description:
      "Sell to domestic and international buyers on a trusted platform.",
  },
  {
    imgSrc: onlineMoney,
    title: "Digital",
    title2: " Catalogs",
    description:
      "Catalogues with comprehensive product details, pricing & offers.",
  },
  {
    imgSrc: onlineStore,
    title: "Digital ",
    title2: " Storefronts",
    description:
      "Create your personalized store to showcase steel products online.",
  },
  {
    imgSrc: moneyGrowth,
    title: "Real-Time",
    title2: " Pricing",
    description:
      "Stay competitive with instant updates on market price changes.",
  },
  {
    imgSrc: moneyCurrency,
    title: "Seamless Digital ",
    title2: " Transactions",
    description:
      "Enjoy smooth order management, secure payments, and logistics.",
  },
  {
    imgSrc: analyst,
    title: "Analytics ",
    title2: " Insights",
    description:
      "Gain actionable data to optimize sales and boost performance.",
  },
  {
    imgSrc: action,
    title: "E-Auction",
    title2: " Digital Tools",
    description:
      "Participate in forward and reverse auctions for better deals.",
  },
  {
    imgSrc: rupees,
    title: "Digital",
    title2: " Financing Options",
    description: "Unlock flexible financing to grow your business with ease.",
  },
];

const SellerCentral: React.FC = () => {
  const classes = useStyles();
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const component = params.get("component");

    if (component) {
      const element = document.getElementById(component);
      if (element) {
        element.scrollIntoView();
      }
    }
  }, [location.search]);

  return (
    <>
      <div className={`${classes.bannerContainer}`}>
        <div className="container mx-auto px-4">
          <div className="insideBannerBx">
            <div className="insideBannerInfo">
              <h1>
                Transformation <br /> from
                <span className="orange400">Traditional</span> to <br />
                <span>NextGen Digital Trade</span>
              </h1>
              <p>
                Join our global Seller network and unlock new growth
                opportunities.
              </p>
              <Button className="HeaderBtn">
                Start Selling Today <ArrowForwardIcon />
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="neutral20Bg lg:py-14 py-8 w-full">
        <div className="container lg:px-4 px-2 mx-auto flex lg:columns-2 columns-1 lg:flex-row flex-col lg:gap-10 gap-2 PageInfoBoxGry">
          <div className="lg:w-2/5 w-full grid content-center ">
            <small>Welcome to SB Seller Central</small>
            <h2>Sell Smarter, Grow Faster</h2>
          </div>
          <div className={`lg:w-3/5 w-full`}>
            <p>
              Seller Central by Steelbazaar is a comprehensive platform tailored
              for steel sellers, enabling them to seamlessly list, manage, &
              sell their products. From small-scale distributors to large-scale
              manufacturers, Seller Central equips Sellers with innovative tools
              to maximize their reach, streamline their operations, & boost
              sales. Whether you're selling raw materials, semi-finished, or
              finished steel products, Seller Central ensures a transparent,
              efficient, & profitable experience.
            </p>
            <p>
              SteelBazaar is the Go-To Platform for the steel industry. Let us
              help you grow your business while maximising sales potential.
            </p>
          </div>
        </div>
      </div>
      <div
        className={`${classes.container} container mx-auto lg:px-4 px-2 lg:py-[90px] py-8`}
        id="benifits-features"
      >
        <div className={`headingContainer`}>
          <div className="heading">The future is Digital. Be Ready Today</div>
          <div className="Subheading">
            Transforming Steel Selling with Advanced Digital Solutions
          </div>
        </div>

        <div className="flex gap-4 flex-wrap">
          {futureData.map((item, index) => (
            <div className={`whiteSmallCard`} key={index}>
              <img src={item.imgSrc} alt={item.title} />
              <div className="flex gap-y-2.5 flex-col justify-items-center">
                <b className="flex 2xl:flex-row flex-col gap-x-2  justify-center">
                  <div className="">{item.title}</div> <div>{item.title2}</div>
                </b>
                <p>{item.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div
        className={`${classes.container} container mx-auto lg:px-4 px-2 lg:py-[90px] py-8`}
      >
        <div className={`headingContainer`}>
          <div className="heading">Start selling on Steelbazaar</div>
          <div className="Subheading">Your Journey Starts Here...</div>
        </div>
        {
          // Section One Start Here
        }
        <div
          className="flex lg:columns-2 columns-1 lg:flex-row flex-col items-center ZigZagSection lg:gap-20"
          id="registration"
        >
          <div className="2xl:w-4/12 lg:w-5/12 w-full flex content-center justify-center">
            <div className="ZigZagImgBox">
              <div className="ImgItem">
                <img src={DotImg} alt="" />
              </div>
              <img src={Section1} className="w-full" alt="" />
            </div>
          </div>
          <div className="2xl:w-8/12 lg:w-7/12 w-full ZigZagText">
            <div className="grayText">Step 01:</div>
            <div className="heading48">
              Register on <span>SteelBazaar</span>
            </div>
            <div className="Subhead">
              <b>Join our network of trusted vendors in just a few steps.</b>
            </div>
            <div className="PointBox">
              <div
                className={`${classes.stepContainer} flex lg:columns-3 md:columns-3 sm:columns-3 columns-1 lg:flex-row md:flex-row sm:flex-row flex-col gap-7 md:gap-4 pb-2`}
              >
                <div className="lg:w-1/3 md:w-1/3 sm:w-1/3 w-full gap-2.5 flex flex-col">
                  <h6 className="blue">
                    Register <img src={BlueArrowImg} alt="" />
                  </h6>
                  <p>
                    Sign up as a vendor and provide basic business information.
                  </p>
                </div>
                <div className="lg:w-1/3 md:w-1/3 sm:w-1/3 w-full gap-2.5 flex flex-col">
                  <h6 className="yellow">
                    Verify <img src={YellowArrowImg} alt="" />
                  </h6>
                  <p>
                    Upload necessary documents to Complete the verification
                    process<sup>*</sup> to ensure a secure marketplace.
                  </p>
                </div>
                <div className="lg:w-1/3 md:w-1/3 sm:w-1/3 w-full gap-2.5 flex flex-col">
                  <h6 className="green">Launch</h6>
                  <p>
                    Once verified, you’re ready to list your products and start
                    selling!
                  </p>
                </div>
              </div>

              <div className="flex lg:columns-2 columns-1 lg:flex-row mobileGray flex-col items-center justify-between">
                <p className="2xl:w-9/12 lg:w-9/12 md:w-8/12 w-full">
                  <sup>*</sup>SteelBazaar’s business verification process is
                  specially designed to build buyer confidence by confirming
                  your business's legitimacy and authenticity. Verified sellers
                  gain buyer’s valuable
                </p>
                <Button className="Btn sm h-[45px] primary500Bg ">
                  Register Now <ArrowForwardIcon />
                </Button>
              </div>
            </div>
          </div>
        </div>

        {
          // Section Two Start Here
        }
        <div className="flex lg:columns-2 columns-1 lg:flex-row flex-col items-center ZigZagSection lg:gap-20">
          <div className="2xl:w-4/12 lg:w-5/12 w-full flex order-1 lg:order-2 justify-center">
            <div className="ZigZagImgBox">
              <div className="ImgItemLeft">
                <img src={DotImg} alt="" />
              </div>
              <img src={Section2} className="w-full" alt="" />
            </div>
          </div>
          <div className="2xl:w-8/12 lg:w-7/12 w-full ZigZagText order-2 lg:order-1">
            <div className="grayText">Step 02:</div>
            <div className="heading48">
              Create Your<span> Seller Profile</span>
            </div>
            <div className="Subhead">
              <b>Build Your Brand and Business Values</b>
              <p>
                Build a comprehensive seller profile that showcases your brand,
                values, and offerings to buyers, helping establish trust and
                credibility.
              </p>
            </div>

            <div className="PointBox">
              <ul>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span>
                    <b>Business Information</b>
                    Highlight key details like business History Type, location,
                    Product details, contact information and specialities.
                  </span>
                </li>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span>
                    <b>Brand Story</b>
                    Share your brand story to connect with potential buyers.
                  </span>
                </li>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span>
                    <b>Certification & Quality Standards</b>
                    Upload certifications to build trust with buyers.
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {
          // Section Three Start Here
        }
        <div className="flex lg:columns-2 columns-1 lg:flex-row flex-col items-center ZigZagSection lg:gap-20">
          <div className="2xl:w-4/12 lg:w-5/12 w-full flex content-center justify-center">
            <div className="ZigZagImgBox">
              <div className="ImgItem">
                <img src={DotImg} alt="" />
              </div>
              <img src={Section3} className="w-full" alt="" />
            </div>
          </div>
          <div className="2xl:w-8/12 lg:w-7/12 w-full ZigZagText">
            <div className="grayText">Step 03:</div>
            <div className="heading48">
              Access the Comprehensive <span>Dashboard</span>
            </div>
            <div className="Subhead">
              <b>All Your Business Operations in One Place</b>
              <p>
                The SteelBazaar dashboard gives you complete control over your
                store, orders, inventory, and performance metrics, helping you
                manage your business effortlessly.
              </p>
            </div>

            <div className="PointBox">
              <ul>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span>
                    <b>Real-Time Analytics</b>
                    Track sales, inventory levels, and buyer trends.
                  </span>
                </li>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span>
                    <b>Order Management</b>
                    View and manage all orders in one location.
                  </span>
                </li>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span>
                    <b>Notifications and Alerts</b>
                    Get notified of important updates, orders, and stock levels.
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {
          // Section Four Start Here
        }
        <div
          className="flex lg:columns-2 columns-1 lg:flex-row flex-col items-center ZigZagSection lg:gap-20"
          id="inventory"
        >
          <div className="2xl:w-4/12 lg:w-5/12 w-full flex order-1 lg:order-2 justify-center">
            <div className="ZigZagImgBox">
              <div className="ImgItemLeft">
                <img src={DotImg} alt="" />
              </div>
              <img src={Section4} className="w-full" alt="" />
            </div>
          </div>
          <div className="2xl:w-8/12 lg:w-7/12 w-full ZigZagText order-2 lg:order-1">
            <div className="grayText">Step 04:</div>
            <div className="heading48">
              Manage Inventory & <span> Orders Digitally</span>
            </div>
            <div className="Subhead">
              <b>Stay in Control of Your Sales</b>
              <p>
                Our platform allows you to manage your inventory seamlessly.
                Keep your stock up-to-date, handle orders efficiently, and
                ensure on-time customer deliveries.
              </p>
            </div>

            <div className="PointBox">
              <ul>
                <li className="items-center">
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span>
                    <b>Inventory management SKU and USN-wise</b>
                  </span>
                </li>
                <li className="items-center">
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span>
                    <b>Real-time Inventory and Order tracking</b>
                  </span>
                </li>
                <li className="items-center">
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span>
                    <b>Automated stock updates</b>
                  </span>
                </li>
                <li className="items-center">
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span>
                    <b>Bar Coding Options</b>
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {
          // Section Five Start Here
        }
        <div className="flex lg:columns-2 columns-1 lg:flex-row flex-col items-center ZigZagSection lg:gap-20">
          <div className="2xl:w-4/12 lg:w-5/12 w-full flex content-center justify-center">
            <div className="ZigZagImgBox">
              <div className="ImgItem">
                <img src={DotImg} alt="" />
              </div>
              <img src={Section5} className="w-full" alt="" />
            </div>
          </div>
          <div className="2xl:w-8/12 lg:w-7/12 w-full ZigZagText">
            <div className="grayText">Step 05:</div>
            <div className="heading48">
              Create Digital Product Catalogs with <span>Predefined SKUs</span>
            </div>
            <div className="Subhead">
              <b>Access Predefined SKUs to Simplify Your Listings</b>
              <p>
                Use SteelBazaar's catalogue builder to create digital catalogues
                efficiently. Access a library of predefined SKUs to get started
                quickly and list products accurately.
              </p>
            </div>

            <div className="PointBox">
              <ul>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span>
                    <b>Predefined SKUs</b>
                    Save time by choosing from an extensive list of SKUs
                    tailored to steel products.
                  </span>
                </li>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span>
                    <b>Bulk Upload Options</b>
                    Upload multiple products at once for faster catalogue
                    creation.
                  </span>
                </li>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span>
                    <b>Detailed Descriptions</b>
                    Add details, images, and specifications to showcase each
                    product.
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {
          // Section Six Start Here
        }
        <div
          className="flex lg:columns-2 columns-1 lg:flex-row flex-col items-center ZigZagSection lg:gap-20"
          id="storeFront"
        >
          <div className="2xl:w-4/12 lg:w-5/12 w-full flex order-1 lg:order-2 justify-center">
            <div className="ZigZagImgBox">
              <div className="ImgItemLeft">
                <img src={DotImg} alt="" />
              </div>
              <img src={Section6} className="w-full" alt="" />
            </div>
          </div>
          <div className="2xl:w-8/12 lg:w-7/12 w-full ZigZagText order-2 lg:order-1">
            <div className="grayText">Step 06:</div>
            <div className="heading48">
              Build Your <span>Personalized Storefront</span>
            </div>
            <div className="Subhead">
              <b>Attract Buyers with a Professional Online Store</b>
              <p>
                Build your storefront with a detailed Profile, Listings of your
                Products and services, and Auction listings with your brand and
                a professional look that attracts buyers and encourages repeat
                visits. SteelBazaar’s intuitive tools make setup simple.
              </p>
            </div>

            <div className="PointBox">
              <ul>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span>
                    <b>Product Listings</b>
                    Add detailed descriptions, specifications, and pricing for
                    each product.
                  </span>
                </li>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span>
                    <b>Service Offerings</b>
                    Highlight any additional services like custom manufacturing
                    or packaging.
                  </span>
                </li>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span>
                    <b>Featured Products</b>
                    Promote best-sellers and discounted items.
                  </span>
                </li>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span>
                    <b>Sales Offers</b>
                    Promote best-sellers and discounted items.
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {
          // Section Seven Start Here
        }
        <div
          className="flex lg:columns-2 columns-1 lg:flex-row flex-col items-center ZigZagSection pb-0 lg:gap-20"
          id="publish"
        >
          <div className="2xl:w-4/12 lg:w-5/12 w-full flex content-center justify-center">
            <div className="ZigZagImgBox">
              <div className="ImgItem">
                <img src={DotImg} alt="" />
              </div>
              <img src={Section7} className="w-full" alt="" />
            </div>
          </div>
          <div className="2xl:w-8/12 lg:w-7/12 w-full ZigZagText">
            <div className="grayText">Step 07:</div>
            <div className="heading48">
              Publish Your <span>Catalog on SteelBazaar</span>
            </div>
            <div className="Subhead">
              <b>Showcase Your Products to a Global Audience</b>
              <p>
                Once your catalogue and storefront are ready, publish them on
                the SteelBazaar marketplace to reach buyers worldwide and start
                accepting orders.
              </p>
            </div>

            <div className="PointBox">
              <ul>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span>
                    <b>Instant Publishing</b>
                    Go live with just one click once everything is ready.
                  </span>
                </li>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span>
                    <b>Market-Wide Visibility</b>
                    Your products become visible to a broad audience of steel
                    buyers.
                  </span>
                </li>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span>
                    <b>Ongoing Support</b>
                    SteelBazaar’s team provides support as you begin selling on
                    the platform
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`${classes.container}  container mx-auto lg:py-[90px] lg:px-4 my-8 px-4 w-full lg:my-0`}
        id="sellSmart"
      >
        <div className={`headingContainer`}>
          <div className="heading">
            Sell Smarter, Faster with Advanced Tools
          </div>
        </div>

        <div className="flex gap-4 gap-y-4 flex-wrap justify-center">
          <div
            className={`${classes.card01} ${classes.featureCard} flex flex-col justify-between h-[240px] lg:h-[450px] overflow-hidden`}
          >
            <div className="p-3 lg:p-8 md:p-4 sm:p-4">
              <h4 className={`${classes.cardHeadingSecondary}`}>
                Feature Listings
              </h4>
              <p className= 'text-base'>
                Highlight your brand and sales offers to increase visibility and
                attract global buyers
              </p>
            </div>
            <img src={card1} alt="" className="mx-auto my-0" />
          </div>

          <div
            className={`${classes.card02} ${classes.featureCard} flex flex-col justify-between h-[240px] lg:h-[450px] overflow-hidden`}
          >
            <div className="p-3 lg:p-8 md:p-4 sm:p-4">
              <h4 className={`${classes.cardHeadingSecondary}`}>
                Digital Catalogue with Real Time Pricing
              </h4>
              <p className='text-base'>
                Access Verified Lead to Drive Sales and Business Growth
              </p>
            </div>
            <img src={pricing} alt="" className="mx-auto my-0" />
          </div>

          <div
            className={`${classes.card03} ${classes.featureCard} flex flex-col justify-between h-[240px] lg:h-[450px] overflow-hidden`}
          >
            <div className="p-3 lg:p-8 md:p-4 sm:p-4">
              <h4 className={`${classes.cardHeadingSecondary}`}>
                SB DigiStore
              </h4>
              <p className='text-base'>
                Real-Time Live auctions for buying raw materials
              </p>
            </div>
            <img src={SBDigiStore} alt="" className="mx-auto my-0" />
          </div>

          <div
            className={`${classes.card04} ${classes.featureCard} flex flex-col justify-between h-[240px] lg:h-[450px] overflow-hidden`}
          >
            <div className="p-3 lg:p-8 md:p-4 sm:p-4">
              <h4 className={`${classes.cardHeadingSecondary}`}>
                SB Connect Leads Management
              </h4>
              <p className='text-base'>
                Access exclusive curated buyer leads to generate strong sales
                opportunities.
              </p>
            </div>
            <img src={Lead} alt="" className="mx-auto my-0" />
          </div>

          <div
            className={`${classes.card05} ${classes.featureCard} flex flex-col justify-between h-[240px] lg:h-[450px] overflow-hidden`}
          >
            <div className="p-3 lg:p-8 md:p-4 sm:p-4">
              <h4 className={`${classes.cardHeadingSecondary}`}>SB BidHub</h4>
              <p className='text-base'>
                Quickly sell old, aged, or excess stock through competitive
                auctions.
              </p>
            </div>
            <img src={bid} alt="" className="my-0" />
          </div>

          <div
            className={`${classes.card06} ${classes.featureCard} flex flex-col justify-between h-[240px] lg:h-[450px] overflow-hidden`}
          >
            <div className="p-3 lg:p-8 md:p-4 sm:p-4">
              <h4 className={`${classes.cardHeadingSecondary}`}>
                SB Inventim{" "}
              </h4>
              <p className='text-base'>
                Track, Organize, and Manage Inventory for accurate, real-time
                stock tracking.
              </p>
            </div>
            <img src={management} alt="" className="my-0" />
          </div>
          <div
            className={`${classes.card07} ${classes.featureCard} flex flex-col justify-between h-[240px] lg:h-[450px] overflow-hidden`}
          >
            <div className="p-3 lg:p-8 md:p-4 sm:p-4">
              <h4 className={`${classes.cardHeadingSecondary}`}>
                AI-Driven Market Analytics and Insights
              </h4>
              <p className='text-base'>
                Gain Actionable Insights into market trends and buyer behaviors.
              </p>
            </div>
            <img src={insights} alt="" className="mx-auto my-0" />
          </div>

          <div
            className={`${classes.card08} ${classes.featureCard} flex flex-col justify-between h-[240px] lg:h-[450px] overflow-hidden`}
          >
            <div className="p-3 lg:p-8 md:p-4 sm:p-4">
              <h4 className={`${classes.cardHeadingSecondary}`}>SB InstaFin</h4>
              <p className='text-base'>
                Help buyers purchase more with convenient and easy financing
                solutions facilitated by SteelBazaar
              </p>
            </div>
            <img src={instafin} alt="" className="mx-auto my-0" />
          </div>

          <div
            className={`${classes.card09} ${classes.featureCard} flex flex-col justify-between h-[240px] lg:h-[450px] overflow-hidden`}
          >
            <div className="p-3 lg:p-8 md:p-4 sm:p-4">
              <h4 className={`${classes.cardHeadingSecondary}`}>SB Branding</h4>
              <p className='text-base'>
                Access digital marketing tools to enhance brand reach and
                engagement.
              </p>
            </div>
            <img src={sbpool} alt="" className="mx-auto my-0" />
          </div>
        </div>
      </div>
      <div className="neutral20Bg lg:py-[90px] py-8 w-full">
        <div className={`headingContainer`}>
          <div className="heading">Unlock Limitless Opportunities</div>
          <div className="Subheading">
            Joining Seller Central offers unparalleled advantages
          </div>
        </div>
        <div className="container mx-auto lg:px-4 px-3 w-full flex flex-wrap gap-4 ">
          <div className="whiteGrayBox colWidth-3">
            <div className="iconBox">
              <img src={UnlockIcon1} alt="" />
            </div>
            <div className="TextBox">
              <b>Explore New Markets & Customers</b>
              Connect with a vast network of buyers across domestic &
              international markets.
            </div>
          </div>

          <div className="whiteGrayBox colWidth-3">
            <div className="iconBox">
              <img src={UnlockIcon2} alt="" />
            </div>
            <div className="TextBox">
              <b>Maximize Sales with Lead Generation</b>
              Get regular customer inquiries through our integrated lead management.
            </div>
          </div>

          <div className="whiteGrayBox colWidth-3">
            <div className="iconBox">
              <img src={UnlockIcon3} alt="" />
            </div>
            <div className="TextBox">
              <b>Cost-Effective Purchases</b>
              Negotiate best deals and explore long-term contracts and partnerships
            </div>
          </div>

          <div className="whiteGrayBox colWidth-3">
            <div className="iconBox">
              <img src={UnlockIcon4} alt="" />
            </div>
            <div className="TextBox">
              <b>Enhanced Visibility</b>
              Showcase your products to a targeted audience with marketing support.
            </div>
          </div>

          <div className="whiteGrayBox colWidth-3">
            <div className="iconBox">
              <img src={UnlockIcon5} alt="" />
            </div>
            <div className="TextBox">
              <b>Ease of Transactions</b>
              Enjoy smooth order management, secure payments, and on-time delivery services.
            </div>
          </div>

          <div className="whiteGrayBox colWidth-3">
            <div className="iconBox">
              <img src={UnlockIcon6} alt="" />
            </div>
            <div className="TextBox">
              <b>Access to Financing</b>
              Approved sellers can avail of financing solutions for business growth.
            </div>
          </div>
        </div>
      </div>
      <div className={`${classes.sellerCTA} lg:py-[50px] py-8 w-full `}>
        <div className="container mx-auto CallToAction lg:px-4 px-3 ">
          <div className="2xl:w-1/3 lg:w-2/5 flex flex-col gap-y-2.5 items-start">
            <h2>
              Your Trusted Partner in Steel Trading Built for Sellers, Powered by Innovation
            </h2>
            <p className="mb-5">
              Steelbazaar’s Seller Central combines industry expertise with technological innovation, ensuring that steel sellers thrive in an ever-evolving market. With unmatched features, dedicated support, & access to a thriving ecosystem of buyers, Seller Central is your ultimate partner for sustainable growth in steel trading.
            </p>
            <Button className="Btn whiteBg Txtprimary500">
              Register Now <ArrowForwardIcon className="primary500Bg text-white" />
            </Button>
          </div>
        </div></div>
      <RequestDemoTemplate />
    </>
  );
};
export default SellerCentral;

