import React, { useRef, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { createUseStyles } from "react-jss";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import customerBuyerBanner from "../../../assets/images/customerBuyerBanner.jpg";
import iProcure from "../../../assets/images/iProcure.jpg";
import sbMaxCard from "../../../assets/images/sbMaxCard.jpg";
import procurePlus from "../../../assets/images/procurePlus.jpg";
import primaryBlueTick from "../../../assets/icons/primaryBlueTick.jpg";
import orangeTick from "../../../assets/icons/orangeTick.jpg";
import blueTick from "../../../assets/icons/blueTick.jpg";
import { Button } from "@mui/joy";
import RequestDemoTemplate from "../../template/Home/RequestDemo/RequestDemo.template";
//bykamal
import customerMobileBanner from "../../../assets/images/BuyerMobileBanner.jpg";
import OneStopIcon1 from "../../../assets/images/customer-OneStop-Icon-1.svg";
import OneStopIcon2 from "../../../assets/images/customer-OneStop-Icon-2.svg";
import OneStopIcon3 from "../../../assets/images/customer-OneStop-Icon-3.svg";
import OneStopIcon4 from "../../../assets/images/customer-OneStop-Icon-4.svg";
import OneStopIcon5 from "../../../assets/images/customer-OneStop-Icon-5.svg";
import OneStopIcon6 from "../../../assets/images/customer-OneStop-Icon-6.svg";
import OneStopIcon7 from "../../../assets/images/customer-OneStop-Icon-7.svg";
import OneStopIcon8 from "../../../assets/images/customer-OneStop-Icon-8.svg";
import OneStopIcon9 from "../../../assets/images/customer-OneStop-Icon-9.svg";
import OneStopIcon10 from "../../../assets/images/customer-OneStop-Icon-10.svg";
import OneStopIcon11 from "../../../assets/images/customer-OneStop-Icon-11.svg";
import OneStopIcon12 from "../../../assets/images/customer-OneStop-Icon-12.svg";
import DotImg from "../../../assets/images/dot-shape.png";
import Step1Img from "../../../assets/images/customer-step-1.jpg";
import Step3Img from "../../../assets/images/customer-step-3.jpg";
import Step4Img from "../../../assets/images/customer-step-4.jpg";
import Step5Img from "../../../assets/images/customer-step-5.jpg";
import Step6Img from "../../../assets/images/customer-step-6.jpg";
import Step7Img from "../../../assets/images/customer-step-7.jpg";
import Step8Img from "../../../assets/images/customer-step-8.jpg";
import Step9Img from "../../../assets/images/customer-step-9.jpg";
import BulletImg from "../../../assets/images/bulletIcon.svg";

const useStyles = createUseStyles((theme: any) => ({
  bannerContainer: {
    backgroundImage: `url(${customerBuyerBanner})`,
    backgroundSize: "cover",
  },

  btnArrow: {
    border: "1px solid #fff",
    borderRadius: "100%",
  },

  profileCard1: {
    background: theme.palette.background.complementary.complementary50,
    border: `1px solid ${theme.palette.border.complementary.complementary400}`,
    overflow: "hidden",
    "& p": {
      color: theme.palette.text.complementary.complementary800,
      lineHeight: "22px",
    },
  },
  profileCardHeader1: {
    background: theme.palette.background.complementary.complementary400,
    fontSize: "22px",
    color: "#fff",
  },
  profileCard2: {
    background: theme.palette.background.warning.warning50,
    border: `1px solid ${theme.palette.border.warning.warning400}`,
    overflow: "hidden",
    "& p": {
      color: theme.palette.text.warning.warning800,
      lineHeight: "22px",
    },
  },
  profileCardHeader2: {
    background: theme.palette.background.warning.warning400,
    fontSize: "22px",
    color: "#fff",
  },
  profileCard3: {
    background: "#FFEFE6",
    overflow: "hidden",
    border: "1px solid #FD8235",
    "& p": {
      color: "#652701",
      lineHeight: "22px",
    },
  },
  profileCardHeader3: {
    background: "#FD8235",
    fontSize: "22px",
    color: "#fff",
  },
  profileCard4: {
    background: theme.palette.background.primary.primary50,
    border: `1px solid ${theme.palette.border.primary.primary400}`,
    overflow: "hidden",
    "& p": {
      color: theme.palette.text.primary.primary800,
      lineHeight: "22px",
    },
  },
  profileCardHeader4: {
    background: theme.palette.background.primary.primary400,
    fontSize: "22px",
    color: "#fff",
  },
  profileCard5: {
    background: theme.palette.background.success.success50,
    border: `1px solid ${theme.palette.border.success.success400}`,
    overflow: "hidden",
    "& p": {
      color: theme.palette.text.success.success800,
      lineHeight: "22px",
    },
  },
  profileCardHeader5: {
    background: theme.palette.background.success.success400,
    fontSize: "22px",
    color: "#fff",
  },

  catalogsContainer: {
    border: `1px solid ${theme.palette.border.neutral.neutral200}`,
    background: "#fff",
    width: "48%",
    "& h5": {
      fontSize: "22px",
      color: "#000",
      lineHeight: "26px",
    },
    "& p": {
      color: "#000",
      lineHeight: "20px",
    },
  },
  discover1: {
    border: `1px solid ${theme.palette.border.secondary.secondary200}`,
    background: theme.palette.background.secondary.secondary50,
    borderRadius: '8px',
    padding: '20px',
    display: 'flex',
    "& .TextItem": {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      width: '260px',
      fontWeight: '500',
      "& .h4": {},
      "& .h5": {},
    },
  },
  discover2: {
    background: theme.palette.background.primary.primary50,
    border: `1px solid ${theme.palette.border.primary.primary200}`,
  },
  discover3: {
    background: theme.palette.background.warning.warning50,
    border: `1px solid ${theme.palette.border.warning.warning200}`,
    width: "580px",
    marginTop: "-70px",
    height: "493px",
  },
  discover4: {
    background: theme.palette.background.neutral.neutral50,
    border: `1px solid ${theme.palette.border.neutral.neutral200}`,
    width: "580px",
    marginTop: "70px",
    height: "493px",
  },
  discover5: {
    background: theme.palette.background.success.success50,
    border: `1px solid ${theme.palette.border.success.success200}`,
    width: "580px",
    marginTop: "-70px",
    height: "493px",
  },
  discover6: {
    backgroundColor: "#FFEFE6",
    border: "1px solid #FEC09A",
    width: "580px",
    marginTop: "70px",
    height: "493px",
  },
  discoverCard01: {
    border: `1px solid ${theme.palette.border.secondary.secondary200}`,
  },
  discoverCard02: {
    border: `1px solid ${theme.palette.border.primary.primary200}`,
  },
  discoverCard03: {
    border: `1px solid ${theme.palette.border.warning.warning400}`,
  },
  discoverCard04: {
    border: `1px solid ${theme.palette.border.neutral.neutral200}`,
  },
  discoverCard05: {
    border: `1px solid ${theme.palette.border.success.success400}`,
  },
  discoverCard06: {
    border: "1px solid #FEC09A",
  },
  discoverCard1: {
    width: "367px",
    minHeight: "139px",
    background: "#fff",
    position: "relative",
    right: "-40px",
    "& h4": {
      color: "#000",
      fontSize: "22px",
    },
    "& p": {
      color: "#000",
      lineHeight: "26px",
    },
  },
  discoverText: {
    fontSize: "32px",
    lineHeight: "38px",
  },
  discoverContent: {
    position: "relative",
    left: "-40px",
  },
  discoverCard2: {
    marginRight: "0px",
    background: "#fff",
    "& h4": {
      color: "#000",
      fontSize: "22px",
    },
    "& p": {
      color: "#000",
      lineHeight: "26px",
    },
  },
  outlineCommon: {
    color: "transparent",
    WebkitTextStroke: "2px black",
    fontSize: "150px",
    fontWeight: "800",
    lineHeight: "120px",
  },
  outline: {
    textStroke: `4px ${theme.palette.text.secondary.secondary400}`,
  },
  outline2: {
    textStroke: `1px ${theme.palette.text.primary.primary400}`,
  },
  outline3: {
    textStroke: `1px ${theme.palette.text.warning.warning400}`,
  },
  outline4: {
    textStroke: `1px ${theme.palette.text.neutral.neutral400}`,
  },
  outline5: {
    textStroke: `1px ${theme.palette.text.success.success400}`,
  },
  outline6: {
    textStroke: "1px #FD8235",
  },
  intoContainer: {
    backgroundColor: theme.palette.background.neutral.neutral50,
  },
  primaryText: {
    fontSize: "54px",
    color: "#000",
    lineHeight: "66px",
  },
  decContainer: {
    width: "750px",
    "& p": {
      color: "#000",
      lineHeight: "26px",
      fontWeight: "400",
    },
  },
  featuresText: {
    width: "651px",
    "& h5": {
      color: "#000",
      fontSize: "22px",
      lineHeight: "26px",
    },
    "& p": {
      color: theme.palette.text.neutral.neutral800,
      lineHeight: "26px",
    },
  },
  cardTop1: {
    background: "#FD8235",
    color: "#fff",
    borderRadius: "15px 15px 0px 0px",
    margin: "0 auto",
  },
  cardTop2: {
    background: theme.palette.background.complementary.complementary400,
    color: "#fff",
    borderRadius: "15px 15px 0px 0px",
    margin: "0 auto",
  },
  cardTop3: {
    background: theme.palette.background.primary.primary500,
    color: "#fff",
    borderRadius: "15px 15px 0px 0px",
    margin: "0 auto",
  },
  bussinessCard: {
    boxShadow: "2px 2px 15px 0px rgba(0, 0, 0, 0.12)",
    overflow: "hidden",
    "& h5": {
      fontSize: "40px",
    },
    "& p": {},
  },
  cardBottom1: {
    borderRadius: "0px !important",
  },
  procureCardHeaidng1: {
    "& span": {
      color: theme.palette.text.complementary.complementary400,
    },
  },
  procureCardHeaidng2: {
    "& span": {
      color: theme.palette.text.primary.primary500,
    },
  },
  procureCardHeaidng3: {
    "& span": {
      color: "#FD8235",
    },
  },
  ProcurementSectionHeading: {
    fontSize: "54px",
    lineHeight: "66px",
    color: "#000",
    "& span": {
      color: theme.palette.text.primary.primary500,
    },
  },
  procurementSubHeading: {
    color: theme.palette.text.neutral.neutral600,
    lineHeight: "28px",
  },
  buyersContainer: {
    background: "#351FFF",
  },
  btn3: {
    background: "#fff",
    color: "#fff",
    fontSize: "18px",
    fontWeight: "500",
    padding: "10px 20px",
    lineHeight: "22px",
    width: "292px !important",
  },
  btnArrow2: {
    border: "1px solid transparent",
    background: theme.palette.background.primary.primary500,
    borderRadius: "50%",
    height: "32px",
    width: "32px",
  },
  btnText: {
    color: theme.palette.text.primary.primary500,
  },
  buyerContainer: {
    "& h2": {
      color: theme.palette.text.warning.warning400,
      lineHeight: "58px",
    },
    "& p": {
      color: theme.palette.text.primary.primary50,
      fontSize: "32px",
      lineHeight: "38px",
    },
  },
  sellerCTA: {
  },
  "@media screen and (max-width: 480px)": {
    bannerContainer: {
      backgroundImage: `url(${customerMobileBanner})`,
      backgroundSize: "cover",
    },
  },
}));

const cardData = [
  {
    img: OneStopIcon1,
    title: "Wide Range of Products",
    description:
      "Access a comprehensive catalogue of steel products from reputed manufacturers, tailored to diverse industry needs.",
  },
  {
    img: OneStopIcon2,
    title: "Competitive and Real-time Pricing",
    description:
      "Benefit from transparent real-time pricing & auction options to secure the best deals in the market..",
  },
  {
    img: OneStopIcon3,
    title: "Verified Quality Assurance",
    description:
      "Purchase from trusted suppliers with certifications and quality guarantees, ensuring project reliability.",
  },
  {
    img: OneStopIcon4,
    title: "Global Sourcing Opportunities",
    description:
      "Expand your options by sourcing steel from international markets, ensuring access to the best products worldwide.",
  },
  {
    img: OneStopIcon5,
    title: "Customization Options",
    description:
      "Get steel products tailored to your specifications with customization services for grades, sizes, & finishes.",
  },
  {
    img: OneStopIcon6,
    title: "End-to-End Digital Features",
    description:
      "Experience a seamless buying process with features like digital storefronts, real-time tracking, instant quotations, and integrated order management.",
  },
  {
    img: OneStopIcon7,
    title: "Integrated Financing  Solutions",
    description:
      "Unlock flexible financing options like trade credit and working capital support to simplify procurement, even with tight budgets.",
  },
  {
    img: OneStopIcon8,
    title: "Streamlined Logistics & Timely Deliveries",
    description:
      "Enjoy hassle-free logistics solutions with reliable delivery timelines to keep your projects on schedule.",
  },
  {
    img: OneStopIcon9,
    title: "Market Insights with SB SteelIntel",
    description:
      "Leverage real-time analytics and market trends to make informed purchasing decisions and optimise your costs.",
  },
  {
    img: OneStopIcon10,
    title: "Access to Exclusive Mill Offers & Discounts",
    description:
      "Save more with direct access to mill promotions, bulk discounts, and seasonal offers available only on Steelbazaar",
  },
  {
    img: OneStopIcon11,
    title: "Simplified Bulk Order Management",
    description:
      "Manage bulk steel requirements effortlessly with our centralized procurement system, designed to handle bulk orders for infrastructure, construction, and manufacturing projects.",
  },
  {
    img: OneStopIcon12,
    title: "Direct Buyer-Seller Connections",
    description:
      "Eliminate intermediaries and build long-term relationships with verified suppliers, ensuring better pricing, transparency, and trust in every transaction",
  },
];

const CustomerBuyer: React.FC = () => {
  const classes = useStyles();
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const component = params.get("component");

    if (component) {
      const element = document.getElementById(component);
      if (element) {
        element.scrollIntoView();
      }
    }
  }, [location.search]);

  return (
    <>
      <div className={`${classes.bannerContainer}`}>
        <div className="container mx-auto px-4">
          <div className="insideBannerBx">
            <div className="insideBannerInfo">
              <h1>
                Transforming Your <span className="block">Steel Sourcing</span>
                Experience.
              </h1>
              <p className="w-full">
                Explore our digital platform for customized
                <br />
                steel solutions, flexible finance options,
                <br />
                and real-time tracking.
              </p>
              <Button className="HeaderBtn">
                Start Buying Now <ArrowForwardIcon />
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="neutral20Bg lg:py-14 py-8 w-full" id="features">
        <div className="container lg:px-4 px-2 mx-auto flex lg:columns-2 columns-1 lg:flex-row flex-col lg:gap-10 gap-2 PageInfoBoxGry">
          <div className="lg:w-2/5 w-full grid content-center ">
            <h2>Smart buying, Maximum Savings</h2>
          </div>
          <div className={`lg:w-3/5 w-full`}>
            <p className="m-0">
              Steelbazaar is India's premier digital platform designed to
              simplify and streamline steel procurement for buyers of all
              scales. Whether you need raw materials, semi-finished or finished
              steel products, Steelbazaar connects you with verified suppliers,
              delivers competitive pricing, and provides unmatched convenience
              through our digital solutions.
            </p>
          </div>
        </div>
      </div>
      <div
        className='container mx-auto lg:px-4 px-2 lg:py-[90px] py-8 overflow-hidden' id='features'>
        <div className={`headingContainer`}>
          <div className="heading">Why Buy on SteelBazaar?</div>
          <div className="Subheading">
            "One-Stop Solution for All Your Steel Needs"
          </div>
        </div>
        <div className="container mx-auto lg:px-4 px-3 w-full flex flex-wrap gap-4 ">
          {cardData.map((card, index) => (
            <div key={index} className="grayWhiteBox colWidth-4">
              <div className="iconBox">
                <img src={card.img} alt={card.title} />
              </div>
              <div className="TextBox">
                <b>{card.title}</b>
                {card.description}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div
        className='container mx-auto lg:px-4 px-2 lg:py-[90px] py-8' id="Registration">
        <div className={`headingContainer`}>
          <div className="heading">How to Buy on SteelBazaar?</div>
          <div className="Subheading">"Step-by-Step Buying Process"</div>
        </div>
        <div className="flex lg:columns-2 columns-1 lg:flex-row flex-col items-center ZigZagSection border-0">
          <div className="2xl:w-8/12 lg:w-7/12 w-full ZigZagText">
            <div className="grayText">Step 01:</div>
            <div className="heading48">
              Easy Registration <span>Create Your Account</span>
            </div>
            <div className="Subhead">
              <p>
                ThSet up your account to begin your journey with SteelBazaar.
                Provide basic information about your business and create a
                secure profile.
              </p>
            </div>
            <div className="PointBox">
              <ul>
                <li className="items-center">
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span>
                    <b>
                      Visit
                      <Link
                        to="https://steelbazaar.com/"
                        className="Txtprimary500"
                      >
                        Steelbazaar.com
                      </Link>
                      and click on Sign Up.
                    </b>
                  </span>
                </li>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span>
                    <b>
                      Fill out basic details such as Name, Company, GST No.,
                      Mobile No., Email ID and Create a password.
                    </b>
                  </span>
                </li>
                <li className="items-center">
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span>
                    <b>
                      Once registered, you’ll have access to Steelbazaar’s
                      dashboard.
                    </b>
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div className="2xl:w-4/12 lg:w-5/12 w-full flex content-center justify-center">
            <div className="ZigZagImgBox">
              <div className="ImgItemLeft">
                <img src={DotImg} alt="" />
              </div>
              <img src={Step1Img} className="w-full" alt="" />
            </div>
          </div>
        </div>
      </div>
      {
        // Section Two Start Here
      }
      <div className="neutral20Bg lg:py-24 py-8 w-full">
        <div className='container mx-auto lg:px-4 px-2'>
          <div className="flex ZigZagSection flex-col lg:p-0 border-0">
            <div className="2xl:w-7/12 lg:w-7/12 w-full ZigZagText lg:p-0 pb-4">
              <div className="grayText">Step 02:</div>
              <div className="heading48">
                Complete your Customer <span>Profile</span>
              </div>
              <div className="Subhead">
                <b className="Txtneutral600">
                  Add your business details to optimise purchasing and Specify
                  sourcing preferences for tailored offers.
                </b>
              </div>
            </div>

            <div className="PointBox flex flex-wrap w-full justify-center  gap-4">
              <div
                className={`${classes.profileCard1} colWidth-5 pb-5 rounded-lg`}
              >
                <div
                  className={`${classes.profileCardHeader1} mb-5 px-[15px] py-2 font-semibold`}
                >
                  Section 1
                </div>
                <p className="px-[15px] font-medium text-lg">
                  Company Details,
                  <br />
                  Business Type
                </p>
              </div>
              <div
                className={`${classes.profileCard2} colWidth-5 pb-5 rounded-lg`}
              >
                <div
                  className={`${classes.profileCardHeader2} mb-5 px-[15px] py-2 font-semibold`}
                >
                  Section 2
                </div>
                <p className="px-[15px] font-medium text-lg">
                  Key Person Details & Upload Relevant Documents.
                </p>
              </div>
              <div
                className={`${classes.profileCard3} colWidth-5 pb-5 rounded-lg`}
              >
                <div
                  className={`${classes.profileCardHeader3} mb-5 px-[15px] py-2 font-semibold`}
                >
                  Section 3
                </div>
                <p className="px-[15px] font-medium text-lg">
                  Statutory Details of the Company
                </p>
              </div>
              <div
                className={`${classes.profileCard4} colWidth-5 pb-5 rounded-lg`}
              >
                <div
                  className={`${classes.profileCardHeader4} mb-5 px-[15px] py-2 font-semibold`}
                >
                  Section 4
                </div>
                <p className="px-[15px] font-medium text-lg">
                  Provide your Steel Product Requirements
                </p>
              </div>
              <div
                className={`${classes.profileCard5} colWidth-5 pb-5 rounded-lg`}
              >
                <div
                  className={`${classes.profileCardHeader5} mb-5 px-[15px] py-2 font-semibold`}
                >
                  Section 5
                </div>
                <p className="px-[15px] font-medium text-lg">
                  Set your Detailed Preferences.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className='container mx-auto lg:px-4 px-2 lg:py-0 py-8'
      >
        {
          // Section Three Start Here
        }
        <div className="flex lg:columns-2 columns-1 lg:flex-row flex-col items-center ZigZagSection lg:py-[90px] lg:gap-20 ">
          <div className="2xl:w-4/12 lg:w-5/12 w-full flex content-center justify-center">
            <div className="ZigZagImgBox">
              <div className="ImgItem">
                <img src={DotImg} alt="" />
              </div>
              <img src={Step3Img} className="w-full" alt="" />
            </div>
          </div>
          <div className="2xl:w-8/12 lg:w-7/12 w-full ZigZagText">
            <div className="grayText">Step 03:</div>
            <div className="heading48">
              Access the <span className="block">Comprehensive Dashboard</span>
            </div>
            <div className="Subhead">
              <b>Your Control Center for Efficient Purchasing</b>
              <p>
                The SteelBazaar dashboard offers a centralised hub for managing
                your profile, tracking quotations and orders, viewing
                catalogues, communicating with sellers via the message centre,
                analysing data, participating in auctions, and accessing
                support.
              </p>
            </div>

            <div className="PointBox">
              <ul>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span>
                    <b>Order Tracking</b>
                    Monitor orders from placement to delivery.
                  </span>
                </li>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span>
                    <b>Catalog Access</b>Browse available products and
                    suppliers.
                  </span>
                </li>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span>
                    <b>Real-Time Notifications</b>Stay updated with alerts and
                    status updates.
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {
          // Section Four Start Here
        }
        <div className="flex lg:columns-2 columns-1 lg:flex-row flex-col items-center ZigZagSection border-0">
          <div className="2xl:w-4/12 lg:w-5/12 w-full flex order-1 lg:order-2 justify-center">
            <div className="ZigZagImgBox">
              <div className="ImgItemLeft">
                <img src={DotImg} alt="" />
              </div>
              <img src={Step4Img} className="w-full" alt="" />
            </div>
          </div>
          <div className="2xl:w-8/12 lg:w-7/12 w-full ZigZagText">
            <div className="grayText">Step 04:</div>
            <div className="heading48">
              Advanced Search & <span>Filter Options</span>
            </div>
            <div className="Subhead">
              <b>Find Exactly What You Need Quickly</b>
              <p>
                Use SteelBazaar’s advanced search and filter tools to find
                products based on specific criteria, such as size, grade, price,
                and location.
              </p>
            </div>

            <div className="PointBox">
              <ul>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span>
                    <b>Detailed Filters</b>
                    Narrow results by specifications, grade, and more.
                  </span>
                </li>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span>
                    <b>Quick Results</b>Get accurate results with minimal
                    effort.
                  </span>
                </li>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span>
                    <b>Save Time</b>Efficiently find the exact products you
                    need.
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      {
        // Section Five Start Here
      }
      <div className="neutral20Bg lg:py-24 py-8 w-full" id="catalogue">
        <div className='container mx-auto lg:px-4 px-2'>
          <div className="flex ZigZagSection p-0 border-0">
            <div className="w-full ZigZagText pb-0">
              <div className="grayText">Step 05:</div>
              <div className="heading48">
                Explore Digital Catalogs for Smart Steel Buying
              </div>
            </div>
          </div>
          <div className="flex lg:columns-2 columns-1 lg:flex-row flex-col items-center lg:pt-[64px] ZigZagSection pb-0 lg:px-4 px-2 gap-8">
            <div className="2xl:w-1/3 lg:w-1/3 w-full flex justify-center pr-5">
              <div className="ZigZagImgBox">
                <img src={Step5Img} className="w-full" alt="" />
              </div>
            </div>
            <div className="2xl:w-2/3 lg:w-2/3 w-full lg:columns-2 columns-1 ZigZagText flex gap-2 flex-wrap">

              <div className="whiteGrayBox colWidth-2">
                <div className="TextBox">
                  <b className="pb-2.5">Comprehensive Product Listings</b>Showcase a wide range of steel products with detailed specifications.
                </div>
              </div>

              <div className="whiteGrayBox colWidth-2">
                <div className="TextBox">
                  <b className="pb-2.5">Customization Services</b>Offer customizable steel products (size, grade, specifications) from trusted manufacturers.
                </div>
              </div>

              <div className="whiteGrayBox colWidth-2">
                <div className="TextBox">
                  <b className="pb-2.5">Real-Time Pricing Updates</b>Display Current prices as per market trends, and helping buyers make informed decisions.
                </div>
              </div>

              <div className="whiteGrayBox colWidth-2">
                <div className="TextBox">
                  <b className="pb-2.5">Latest Mill Offers and Discounts</b>Highlight mill offers, bulk discounts, and seasonal promotions to help buyers save on costs.
                </div>
              </div>

              <div className="whiteGrayBox colWidth-2">
                <div className="TextBox">
                  <b className="pb-2.5">Customizable Filter</b>Users can search and filter products by grade, size, type, supplier, and location for quick access.
                </div>
              </div>

              <div className="whiteGrayBox colWidth-2">
                <div className="TextBox">
                  <b className="pb-2.5">Integrated Finance Services</b>Finance options like trade credit and SB Instafin for easy purchasing with limited cash flow.
                </div>
              </div>

              <div className="whiteGrayBox colWidth-2">
                <div className="TextBox">
                  <b className="pb-2.5">Verified Supplier Information</b>Ensure transparency with supplier profiles, including certifications and ratings.
                </div>
              </div>

              <div className="whiteGrayBox colWidth-2">
                <div className="TextBox">
                  <b className="pb-2.5">Logistics Services</b>Allow buyers to select logistics solutions directly for easy procurement and delivery.
                </div>
              </div>

              <div className="whiteGrayBox colWidth-2">
                <div className="TextBox">
                  <b className="pb-2.5">High-Quality Visuals</b>Include product images, datasheets, and certificates for buyer assurance.
                </div>
              </div>

              <div className="whiteGrayBox colWidth-2">
                <div className="TextBox">
                  <b className="pb-2.5">Comparison with Other Brands</b>Comparisons of products, prices, & features from multiple suppliers to help buyers find the best fit.
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

      <div className='container mx-auto lg:px-4 px-2 lg:pb-24 lg:pt-[44px] py-8'>
        {
          // Section Six Start Here
        }
        <div className="flex lg:columns-2 columns-1 lg:flex-row flex-col items-center ZigZagSection ">
          <div className="2xl:w-4/12 lg:w-5/12 w-full flex content-center justify-center lg:order-2 order-1">
            <div className="ZigZagImgBox">
              <div className="ImgItemLeft">
                <img src={DotImg} alt="" />
              </div>
              <img src={Step6Img} className="w-full" alt="" />
            </div>
          </div>
          <div className="2xl:w-8/12 lg:w-7/12 w-full ZigZagText lg:order-1 order-2">
            <div className="grayText">Step 06:</div>
            <div className="heading48">
              Compare Top Brands for <span>The Best Choice</span>
            </div>
            <div className="Subhead">
              <b>Make Informed Decisions with Product Comparisons</b>
              <p>
                Description: SteelBazaar’s comparison tool helps you evaluate brands and products side by side, ensuring you choose the best options for your needs.
              </p>
            </div>

            <div className="PointBox">
              <ul>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span>
                    <b>Side-by-Side Comparison</b>
                    See product specs and pricing in one view.
                  </span>
                </li>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span>
                    <b>Quality and Value</b>Compare brands to find the best quality and price.
                  </span>
                </li>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span>
                    <b>Quick Decision-Making</b>Save time by assessing options at a glance.
                    status updates.
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {
          // Section Seven Start Here
        }
        <div className="flex lg:columns-2 columns-1 lg:flex-row flex-col items-center ZigZagSection" id='instant-quotation'>
          <div className="2xl:w-4/12 lg:w-5/12 w-full flex justify-center">
            <div className="ZigZagImgBox">
              <div className="ImgItem">
                <img src={DotImg} alt="" />
              </div>
              <img src={Step4Img} className="w-full" alt="" />
            </div>
          </div>
          <div className="2xl:w-8/12 lg:w-7/12 w-full ZigZagText">
            <div className="grayText">Step 07:</div>
            <div className="heading48">
              Get Instant Quotations for <span>Quick Pricing</span>
            </div>
            <div className="Subhead">
              <p>
                Request and receive instant product quotations to make timely purchasing decisions based on current market rates.
              </p>
            </div>

            <div className="PointBox">
              <ul>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span>
                    <b>Fast Quotes</b>
                    Get real-time pricing with just a click.
                  </span>
                </li>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span>
                    <b>Accurate Market Rates</b>Always know the current market pricing.
                  </span>
                </li>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span>
                    <b>Convenient Access</b>View quotations directly on your dashboard.
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {
          // Section Eight Start Here
        }
        <div className="flex lg:columns-2 columns-1 lg:flex-row flex-col items-center ZigZagSection">
          <div className="2xl:w-4/12 lg:w-5/12 w-full flex content-center justify-center lg:order-2 order-1">
            <div className="ZigZagImgBox">
              <div className="ImgItemLeft">
                <img src={DotImg} alt="" />
              </div>
              <img src={Step8Img} className="w-full" alt="" />
            </div>
          </div>
          <div className="2xl:w-8/12 lg:w-7/12 w-full ZigZagText lg:order-1 order-2">
            <div className="grayText">Step 8:</div>
            <div className="heading48">
              Place an Order with <span>Ease</span>
            </div>
            <div className="Subhead">
              <b>Make Informed Decisions with Product Comparisons</b>
              <p>Description: SteelBazaar’s comparison tool helps you evaluate brands and products side by side, ensuring you choose the best options for your needs.</p>
            </div>

            <div className="PointBox">
              <ul>
                <li className="2xl:items-center">
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span>
                    <b>Once you receive quotes, review them and choose the best option.</b>
                  </span>
                </li>
                <li className="items-center">
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span>
                    <b>Add selected products to your cart and proceed to Checkout.</b>
                  </span>
                </li>
                <li className="2xl:items-center">
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span>
                    <b>Review the shipping info for the order details and confirm your purchase.</b>
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {
          // Section Nine Start Here
        }
        <div className="flex lg:columns-2 columns-1 lg:flex-row flex-col items-center ZigZagSection pb-0">
          <div className="2xl:w-4/12 lg:w-5/12 w-full flex justify-center">
            <div className="ZigZagImgBox">
              <div className="ImgItem">
                <img src={DotImg} alt="" />
              </div>
              <img src={Step9Img} className="w-full" alt="" />
            </div>
          </div>
          <div className="2xl:w-8/12 lg:w-7/12 w-full ZigZagText">
            <div className="grayText">Step 09:</div>
            <div className="heading48">
              Digital Order <span>Tracking for Transparency</span>
            </div>
            <div className="Subhead">
              <b>Track Your Orders from Placement to Delivery</b>
              <p>
                Use SteelBazaar’s digital order tracking to monitor each purchase step, from processing to shipping and delivery.
              </p>
            </div>

            <div className="PointBox">
              <ul>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span>
                    <b>After placing the order, go to your Dashboard to track your orders.</b>
                  </span>
                </li>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span>
                    <b>Receive real-time updates on order status: processing, shipping, and delivery.</b>Always know the current market pricing.
                  </span>
                </li>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span>
                    <b>Access invoices, delivery schedules, and tracking numbers in one place.</b>
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>


      </div>

      <div className='container mx-auto lg:px-4 px-2 lg:py-[90px] py-8' id="discover-features">
        <div className={`headingContainer`}>
          <div className="heading">Discover SteelBazaar’s Buyer Exclusive Features</div>
        </div>

        <div className="flex mx-auto lg:px-4 px-2 lg:columns-2 lg:flex-row flex-col columns-1 BuyerExclusiveFeatures gap-10 lg:pt-5">
          <div className="lg:w-6/12 w-full pr-4">
            <div className="DiscoverFeatures secondary">
              <div className="HeadingBox">
                <h4>Participate in Auctions & Get Best Deals</h4>
                <h5>01</h5>
              </div>
              <div className="PointsBox">
                <div className="pointOne">
                  <b>Save on Procurement Costs</b>
                  Benefit from reduced procurement costs by winning auctions for your specific steel requirements.
                </div>
                <div className="pointTwo">
                  <b>Exclusive Bulk Deals</b>
                  Access inventory from vendors looking to sell large volumes at competitive rates.
                </div>
                <div className="pointThree">
                  <b>Real-Time Bidding</b>
                  Participate in live auctions Place competitive bids to get high-quality materials at bargain prices.
                </div>
              </div>
            </div>

          </div>
          <div className="lg:w-6/12 w-full pr-4 lg:pt-[150px]">
            <div className="DiscoverFeatures primary">
              <div className="HeadingBox">
                <h4>Flexible Finance Terms for Purchases</h4>
                <h5>02</h5>
              </div>
              <div className="PointsBox">
                <div className="pointOne">
                  <b>Financing Solutions</b>
                  Access funding options that help you spread the cost of large purchases over time.
                </div>
                <div className="pointTwo">
                  <b>Pay Later Options</b>
                  Defer payments while maintaining smooth operations and production schedules.
                </div>
                <div className="pointThree">
                  <b>Credit Facilities</b>
                  Secure credit terms to buy steel products without upfront payments, helping you optimise working capital
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex mx-auto mt80Minus lg:px-4 px-2 lg:columns-2 lg:flex-row  flex-col columns-1 BuyerExclusiveFeatures gap-10">
          <div className="lg:w-6/12 w-full pr-4">
            <div className="DiscoverFeatures warning">
              <div className="HeadingBox">
                <h4>Connect Directly with Top Manufacturers</h4>
                <h5>03</h5>
              </div>
              <div className="PointsBox">
                <div className="pointOne">
                  <b>Browse verified manufacturers for raw materials and finished products</b>
                </div>
                <div className="pointTwo">
                  <b>Secure competitive rates through direct negotiation</b>
                </div>
                <div className="pointThree">
                  <b>Gain access to exclusive, high-quality steel products from around the world</b>
                </div>
              </div>
            </div>

          </div>
          <div className="lg:w-6/12 w-full pr-4 lg:pt-[150px]">
            <div className="DiscoverFeatures neutral">
              <div className="HeadingBox">
                <h4>Stay Competitive with Targeted Buy Leads</h4>
                <h5>04</h5>
              </div>
              <div className="PointsBox">
                <div className="pointOne">
                  <b>Personalised Lead Matching</b>
                  Leads tailored to your sourcing needs.
                </div>
                <div className="pointTwo">
                  <b>Direct Access to Suppliers</b>
                  Connect with verified suppliers directly.
                </div>
                <div className="pointThree">
                  <b>Increased Purchase Opportunities</b>
                  Access new buying options regularly.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex mx-auto lg:px-4 px-2 lg:columns-2 lg:flex-row  flex-col columns-1 BuyerExclusiveFeatures mt80Minus gap-10">
          <div className="lg:w-6/12 w-full pr-4">
            <div className="DiscoverFeatures success">
              <div className="HeadingBox">
                <h4>Make Smart Choices with Up-to-Date Market Information</h4>
                <h5>05</h5>
              </div>
              <div className="PointsBox">
                <div className="pointOne">
                  <b>Real-Time Market News</b>
                  Stay on top of industry changes.
                </div>
                <div className="pointTwo">
                  <b>Comprehensive Reports</b>
                  Access in-depth reports for better planning.
                </div>
                <div className="pointThree">
                  <b>Actionable Insights</b>
                  Gain insights into trends for strategic decisions
                </div>
              </div>
            </div>

          </div>
          <div className="lg:w-6/12 w-full pr-4 lg:pt-[150px]">
            <div className="DiscoverFeatures orange">
              <div className="HeadingBox">
                <h4>Dedicated Customer Support & After-Sales Assistance</h4>
                <h5>06</h5>
              </div>
              <div className="PointsBox">
                <div className="pointOne">
                  <b>24/7 customer support</b>
                </div>
                <div className="pointTwo">
                  <b>Detailed FAQs and video tutorials for self-service</b>
                </div>
                <div className="pointThree">
                  <b>Personalised assistance to ensure you have a seamless experience</b>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div
        className='container mx-auto lg:px-4 px-2 lg:py-[90px] py-8'
      >
        <div className={`headingContainer`}>
          <div className="heading">
            Tailored Procurement Solutions for <span>Every Business</span>
          </div>
          <div className="Subheading">Smarter, Faster, Seamless</div>
        </div>

        <div className="w-full flex flex-col lg:flex-row lg:columns-3 md:columns-3 sm:columns-3 columns-1 gap-7 justify-center">
          <div>
            <div
              className={`${classes.cardTop1} py-[10px] lg:w-[320px] w-[280px] text-center font-semibold text-2xl `}
            >
              SMEs & Micro
            </div>
            <div
              className={`${classes.bussinessCard} h-[682px] rounded-2xl relative`}
            >
              <img src={iProcure} alt="" />
              <div className="px-[15px] ">
                <h5
                  className={`${classes.procureCardHeaidng3} my-3 font-semibold`}
                >
                  <span>i</span>Procure
                </h5>
                <p className="flex items-center gap-3 mb-5">
                  <img src={orangeTick} alt="" /> RM Sourcings: eBidding Based
                </p>
                <p className="flex items-center gap-3">
                  <img src={orangeTick} alt="" /> Inventory Management
                </p>
              </div>
              <div
                className={`${classes.cardTop1} ${classes.cardBottom1} py-[10px] w-full text-center font-semibold text-lg absolute bottom-0 flex gap-[10px] items-center justify-center`}
              >
                Explore More{" "}
                <div className={classes.btnArrow}>
                  <ArrowForwardIcon />
                </div>
              </div>
            </div>
          </div>
          <div>
            <div
              className={`${classes.cardTop2} py-[10px] w-[325px] text-center font-semibold text-2xl `}
            >
              Mid Corporate & MSMEs
            </div>
            <div
              className={`${classes.bussinessCard} h-[682px] rounded-2xl relative`}
            >
              <img src={procurePlus} alt="" />
              <div className="px-[15px] ">
                <h5
                  className={`${classes.procureCardHeaidng1} my-3 font-semibold`}
                >
                  iProcure <span>Plus</span>
                </h5>
                <div className="flex flex-col gap-5">
                  <p className="flex items-center gap-3">
                    <img src={blueTick} alt="" />
                    RM Sourcings: eBidding Based
                  </p>
                  <p className="flex items-center gap-3">
                    <img src={blueTick} alt="" /> Inventory Management
                  </p>
                  <p className="flex items-center gap-3">
                    <img src={blueTick} alt="" /> Supply Chain Finance
                  </p>
                  <p className="flex items-center gap-3">
                    <img src={blueTick} alt="" /> Logistics
                  </p>
                  <p className="flex items-center gap-3">
                    <img src={blueTick} alt="" /> Performance Metrics based
                    Dashboard
                  </p>
                </div>
              </div>
              <div
                className={`${classes.cardTop2} ${classes.cardBottom1} py-[10px] w-full text-center font-semibold text-lg absolute bottom-0 flex gap-[10px] items-center justify-center`}
              >
                Explore More{" "}
                <div className={classes.btnArrow}>
                  <ArrowForwardIcon />
                </div>
              </div>
            </div>
          </div>
          <div>
            <div
              className={`${classes.cardTop3} py-[10px] w-[325px] text-center font-semibold text-2xl `}
            >
              Enterprises
            </div>
            <div
              className={`${classes.bussinessCard} h-[682px] rounded-2xl relative`}
            >
              <img src={sbMaxCard} alt="" />
              <div className="px-[15px] ">
                <h5
                  className={`${classes.procureCardHeaidng2} my-3 font-semibold`}
                >
                  SB<span> Maxx</span>{" "}
                </h5>
                <div className="flex flex-col gap-5">
                  <p className="flex items-center gap-3">
                    <img src={primaryBlueTick} alt="" /> Supply Chain Planning
                  </p>
                  <p className="flex items-center gap-3">
                    <img src={primaryBlueTick} alt="" /> Demand and Forecasting
                  </p>
                  <p className="flex items-center gap-3">
                    <img src={primaryBlueTick} alt="" /> RM Sourcings: eBidding
                    Based
                  </p>
                  <p className="flex items-center gap-3">
                    <img src={primaryBlueTick} alt="" /> Supply Chain Finance
                  </p>
                  <p className="flex items-center gap-3">
                    <img src={primaryBlueTick} alt="" /> Inventory Management
                  </p>
                  <p className="flex items-center gap-3">
                    <img src={primaryBlueTick} alt="" /> Logistics
                  </p>
                  <p className="flex items-center gap-3">
                    <img src={primaryBlueTick} alt="" /> Performance Metrics
                    based Dashboard
                  </p>
                </div>
              </div>
              <div
                className={`${classes.cardTop3} ${classes.cardBottom1} py-[10px] w-full text-center font-semibold text-lg absolute bottom-0 flex gap-[10px] items-center justify-center`}
              >
                Explore More{" "}
                <div className={classes.btnArrow}>
                  <ArrowForwardIcon />
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

      <div className={`${classes.sellerCTA} CTABox lg:py-24 py-8 w-full `}>
        <div className="container mx-auto CallToAction lg:px-4 px-3 ">
          <div className="2xl:w-5/12 lg:w-5/12 flex flex-col gap-y-2.5 items-start">
            <h2>
              Join Thousands of Satisfied Buyers on Steelbazaar
            </h2>
            <p className="mb-5">
              Ready to transform your steel procurement? Sign up now and experience the benefits of digital trade.
            </p>
            <Button className="Btn whiteBg Txtprimary500">Register as a Buyer Now <ArrowForwardIcon className="primary500Bg text-white" />
            </Button>
          </div>
        </div>
      </div>

      <RequestDemoTemplate />
    </>
  );
};

export default CustomerBuyer;
