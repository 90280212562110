import { FormHelperText } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss'
import Button from '../../atoms/Button/Button';
import { HTTP_STATUS, ONBOARDING_STATES } from '../../../utils/types';
import { IOnBoardingPayload } from '../../pages/OnBoarding/OnBoarding.page';
import { useBusinessService } from '../../../services/useBusinessService';
import { useAuthenticatedUser } from '../../../hooks/useAuthenticatedUser';
import { useSnackbar } from '../../../hooks/useSnackBar';
import { FormikProps } from 'formik';
import { ENTITY_TYPES, INDUSTRY_TYPES } from '../../../utils/constant';
import { useNavigate } from 'react-router-dom';
import { ICategory } from '../Rfq/ProductRequirementForm.template';
import { useAdminMasterService } from '../../../services/useAdminMasterService';
import { useMediaQuery } from 'react-responsive';

interface EntityTypeTemplateProps {
    formik: FormikProps<IOnBoardingPayload>;
    setFormState: (value: ONBOARDING_STATES) => void;
};

const useStyles = createUseStyles((theme: any) => ({
    container: {
        rowGap: "40px"
    },
    headingContainer: {
        rowGap: "4px"
    },
    heading: {
        color: theme.palette.text.neutral.neutral800,
        fontSize: "28px",
        lineHeight: "34px"
    },
    description: {
        color: theme.palette.text.neutral.neutral700,
        lineHeight: "20px"
    },
    formContainer: {
        rowGap: "40px"
    },
    formSubContainer: {
        rowGap: "12px"
    },
    label: {
        color: theme.palette.text.neutral.neutral700,
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "16px"
    },
    unSelected: {
        color: theme.palette.text.primary.primary800,
        border: `1px solid ${theme.palette.border.primary.primary200}`,
        "&:hover": {
            background: theme.palette.background.primary.primary50,
        },
        padding: "10px 16px"
    },
    selected: {
        border: `1px solid ${theme.palette.border.primary.primary200}`,
        background: theme.palette.background.primary.primary50,
        padding: "10px 16px"
    },
    skipButton: {
        width: "179px"
    },
    nextButton:{
        width: "179px"
    },
    "@media (max-width: 767px)": {
        container: {
            rowGap: "10px",
            padding: "0px 10px 10px 10px"
        },
        headingContainer: {
            rowGap: "0px",
            paddingBottom: "4px",
            borderBottom: `1px dashed ${theme.palette.border.neutral.neutral300}`
        },
        heading: {
            color: theme.palette.text.neutral.neutral800,
            fontSize: "22px",
            lineHeight: "26px"
        },
        description: {
            color: theme.palette.text.neutral.neutral700,
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "16px"
        },  
        formContainer: {
            rowGap: "10px"
        },  
        formSubContainer: {
            rowGap: "10px"
        },  
        unSelected: {
            padding: "4px 8px"
        },
        selected: {
            padding: "4px 8px"
        },
        skipButton: {
            width: "auto",
        },
        nextButton:{
            width: "auto",
        },
    }
}))

export interface IEntityType {
    business: {
        entityType: string,
        industry: string,
    }
}

const EntityTypeTemplate: React.FC<EntityTypeTemplateProps> = ({ formik, setFormState }) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const businessService = useBusinessService();
    const adminMasterService = useAdminMasterService();
    const { user } = useAuthenticatedUser();
    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const [error, setError] = useState(false);
    const [errors, setErrors] = useState({ industry: false});
    const [categories, setCategoriesTo] = useState<ICategory[]>([]);

    const handleSelected = (option: string) => {
        setError(false)
        formik.setFieldValue('entityType', option)
    }

    const handleSelectIndustry = (option: any, existingSelection: string[], formik: any, section: string, field: string) => {
        const selection = new Set(existingSelection);
        if (selection.has(option)) {
            selection.delete(option);
        } else {
            if (field !== 'categories') {
                selection.clear();
            }
            selection.add(option);
        }
        formik.setFieldValue(field, Array.from(selection));
        setErrors(prev => ({ ...prev, [section]: false }));
    };

    const handleSubmit = () => {
        const entityTypeFieldValue = formik.getFieldProps("entityType").value;
        if (!entityTypeFieldValue.length) {
            setError(true);
            return;
        }
        const selectedIndustry = formik.getFieldProps("industry").value;
        if (selectedIndustry.length === 0 ) {
            setErrors({ industry: !!!selectedIndustry.length })
            return;
        }
        user && businessService.updateBusiness({ business: { entityType: entityTypeFieldValue, industry: selectedIndustry.join(",") } }, user.businessId)
            .then(res => {
                if (res?.status === HTTP_STATUS.OK) {
                    formik.setFieldValue('entityType', entityTypeFieldValue);
                    formik.setFieldValue('industry', selectedIndustry);
                    setFormState(ONBOARDING_STATES.REQUIREMENTS)
                }
                else showSnackbar('error', 'Something went wrong');
            }).catch(error => {
                console.error(error)
                showSnackbar('error', 'Something went wrong')
            }).finally();
    }

    const onBack = () => {
        setFormState(ONBOARDING_STATES.BUSINESS_DETAILS)
    }

    const handleSkip = () => {
        navigate('/');
    }

    const loadCategories = async () => {
        try {
            adminMasterService.getAllCategories({ level: 2, page: 0, size: 10 })
                .then(res => {
                    if (res?.status === HTTP_STATUS.OK)
                        setCategoriesTo(res?.data?.data?.content)
                }).catch((error) => {
                    showSnackbar('error', "Error Fetching Category: ");
                })
        } catch (error) {
            setCategoriesTo([]);
        }
    };

    useEffect(() => {
        loadCategories();
    }, [])

    const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

    return (
        <div className={`${classes.container} grid`}>
            {SnackBarComponent}
            <div className={`grid ${classes.headingContainer}`}>
                <div className={`${classes.heading} font-medium`} >Define Your Business</div>
                <div className={`${classes.description} text-base`}>Select Entity & Industry</div>
            </div>

            <div className={`grid ${classes.formContainer}`}>
            <div className={`grid ${classes.formSubContainer}`}>
                    <div className='grid gap-y-2'>
                        <div className={classes.label}>Entity Type<sup>*</sup></div>
                        <div className=''>
                            <div className='flex gap-2 flex-wrap'>
                                {
                                    ENTITY_TYPES.map((option, index) => (
                                        <div className={`text-base font-medium rounded-md w-fit cursor-pointer ${formik.getFieldProps("entityType").value === option?.name ? classes.selected : classes.unSelected}`}
                                            onClick={() => handleSelected(option?.name)} key={index}>{option?.name}</div>
                                    ))
                                }
                            </div>
                            {error &&
                                <FormHelperText error className='top-half '>Entity Type is required</FormHelperText>
                            }
                        </div>
                    </div>

                    <div className='grid gap-y-2'>
                        <div className={classes.label}>Industry<sup>*</sup></div>
                        <div className=''>
                            <div className='flex gap-2 flex-wrap'>
                                {
                                    INDUSTRY_TYPES.map((option, index) => (
                                        <div className={`text-base font-medium rounded-md w-fit cursor-pointer ${formik.getFieldProps("industry").value.includes((option.value)) ? classes.selected : classes.unSelected}`}
                                            onClick={() => handleSelectIndustry(option.value, formik.getFieldProps("industry").value, formik, 'industry', 'industry')} key={index}>{option.label}</div>
                                    ))
                                }
                            </div>
                            {errors.industry &&
                                <FormHelperText error className='top-half '>Industry is required</FormHelperText>
                            }
                        </div>
                    </div>

                </div>

                <div className='flex justify-between'>
                    <Button size={isMobile ? "small" : "medium"} variant='tertiaryContained' label={"Back"} className='' onClick={onBack} />
                    <div className='flex justify-end gap-x-3'>
                        <Button size={isMobile ? "small" : "medium"} variant='tertiaryContained' label={"Skip for now"} className={classes.skipButton} onClick={handleSkip} />
                        <Button size={isMobile ? "small" : "medium"} variant="primaryContained" label="Next" className={classes.nextButton} onClick={handleSubmit} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EntityTypeTemplate