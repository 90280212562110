import { request } from ".";
import { useAuthenticatedUser } from "../hooks/useAuthenticatedUser";
import { API_METHOD } from "../utils/constant";
import { replaceUrlParams } from "../utils/helper";

export interface ISellerAccountCreationRequestBody {
    vendorType: string
}

export const BUSINESS_PROFILE_URLS = {
    GET: "/business-profile",
    UPDATE: "/business-profile/section/:section",
    UPDATE_SELLER: "/business-profile/vendor/:id",
    GET_CIN: "/cin-details",
    GET_BUSINESS_PROFILE_BY_VENDOR_ID: "/vendor/:businessProfileId",
}

export interface IBusinessProfile {
    id: number;
    name: string;
    userId: number;
    description: string;
    gstin: string;
    pan: string;
    cin: Cin;
    udhyam: Udhyam;
    yearOfEstablishment: number;
    numberOfEmployees: number;
    websiteUrl: string;
    industryType: string;
    businessType: string;
    brand: string;
    locations: Location[];
    finance: Finance;
    pocs: Poc[];
    communicationChannel: string;
    language: string;
    contactMode: string;
    paymentMode: string;
    whatsappUpdates: boolean;
    whatsappCommunity: boolean;
    emailUpdates: boolean;
    docsAccess: boolean;
    createdAt: string;
    updatedAt: string;
    user: User;
    address: Address;
    cinRegisteredAddress: Address;
    docs: any[];
    entityType: string;
    industry: string;
    whatsappNo:any;
    vendorType:string;
    dateOfEstablishment: string;
}

interface Cin {
    cin: number;
    entityType: string;
    registeredOffice: string;
    dateOfIncorporation: string;
}

interface Udhyam {
    businessType: string;
    udhyamNumber: number;
}

interface Finance {
    turnOvers: TurnOver[];
    bankAccounts: BankAccount[];
}

interface TurnOver {
    year: string;
    amount: number;
}

interface BankAccount {
    ifsc: string;
    branch: string;
    bankName: string;
    isDefault: boolean;
    accountName: string;
    accountNumber: string;
}

interface Poc {
    name: string;
    email: string;
    mobile: string;
    prefix: string;
    department: string;
    profilePic: string;
    countryCode: string;
    designation: string;
    socialLinks: SocialLinks;
    isAuthorized: boolean;
    whatsAppLink: string;
    alternateEmail: string;
    alternateMobile: string;
    modeOfCommunication: string;
}

interface SocialLinks {
    linkedin: string;
}

interface User {
    id: number;
    email: string | null;
    password: string | null;
    mobileNumber: string;
    fullName: string;
    countryCode: string;
    whatsappNumber: string;
    userType: string;
    gstin: string;
    department: string | null;
    designation: string | null;
    alternativeEmail: string | null;
    socialLinks: SocialLinks | null;
    isAuthorized: boolean;
    prefix: string | null;
    whatsAppLink: string | null;
    alternateMobile: string | null;
    createdAt: string;
    updatedAt: string;
    enabled: boolean;
    phoneNumberVerified: boolean;
    authorities: Authority[];
    accountNonExpired: boolean;
    accountNonLocked: boolean;
    credentialsNonExpired: boolean;
    emailVerified: boolean;
    username: string | null;
}

interface Authority {
    authority: string;
}

export interface Address {
    id: number;
    type: string;
    email: string;
    city: string;
    state: string;
    country: string;
    pincode: string;
    gmapUrl: string;
    line1: string;
    line2: string;
    countryCode: string;
    mobileNumber: string;
    stdCode: string;
    landline: string;
    resourceType: string;
    createdBy: string;
    updatedBy: string;
    resourceId: number;
    createdAt: string;
    updatedAt: string;
}

export const useBusinessProfileService = () => {
    const { user } = useAuthenticatedUser();

    const getBusinessProfile = () => {
        return request(API_METHOD.GET, BUSINESS_PROFILE_URLS.GET, user, null);
    }

    const updateBusinessProfile = (data: any, section: string) => {
        return request(API_METHOD.PUT, replaceUrlParams(BUSINESS_PROFILE_URLS.UPDATE, { section }), user, data);
    }

    const updateBusinessSellerProfile = (data: ISellerAccountCreationRequestBody, id: number) => {
        return request(API_METHOD.PUT, replaceUrlParams(BUSINESS_PROFILE_URLS.UPDATE_SELLER, { id }), user, data);
    }

    const getCinDetails = (data: any) => {
        return request(API_METHOD.POST, BUSINESS_PROFILE_URLS.GET_CIN, user, data);
    }

    const getBusinessProfileByVendorId = (businessProfileId: number) => {
        return request(API_METHOD.GET, replaceUrlParams(BUSINESS_PROFILE_URLS.GET_BUSINESS_PROFILE_BY_VENDOR_ID, { businessProfileId }), user, null);
    }
    
    return {
        getBusinessProfile, updateBusinessProfile, getCinDetails, updateBusinessSellerProfile, getBusinessProfileByVendorId
    }
}