import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useNavigate } from 'react-router-dom';
import AboutUsDropdown from './AboutUsDropdown';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PlateformDropdown from './PlateformDropdown';
import ServiceDropDown from './ServiceDropDown';
import TradeDropdown from './TradeDropdown';
import MembershipDropdown from './MembershipDropdown';
import SupplierDropdown from './SupplierDropdown';
import BuyerDropdown from './BuyerDropdown.template';
import ContactUsDropdown from './ContactUsDropdown.template';
import HelpCenterDropdown from './HelpCenterDropdown.template';

const useStyles = createUseStyles((theme: any) => ({
    container: {},
    homeSection: {
        color: theme.palette.text.primary.primary700,
        fontWeight: '500',
        fontSize: '14px',
        "&:hover": {
            color: '#000',
        },
    },
    dropdown: {
        color: theme.palette.text.primary.primary700,
        transition: 'max-height 4s ease-in-out',
    },
    navbarOptions: {
        color: theme.palette.text.primary.primary700,
    },

    dropdownContainer: {
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        gap: '0px',
        padding: '14px 4px',
        borderRadius: '4px',
        transition: 'background-color 0.3s ease-in-out',
        '&:hover': {
            backgroundColor: `${theme.palette.background.neutral.neutral50} !important`,
        },
        '&:hover $homeSection, &:hover svg': {
            color: '#000 !important',
        },
    },

    rightDropdown: {
        color: theme.palette.text.primary.primary700,
        transition: 'max-height 4s ease-in-out',
        "&:hover": {
            background: theme.palette.background.primary.primary50,
        },
        zIndex: "1000",
    },
    rightDropDownOption: {
        border: `1px solid ${theme.palette.border.primary.primary50}`,
        boxShadow: "0px 4px 24px 0px rgba(29, 54, 121, 0.16)",
    },
    rightDropDownOptionValue: {
        color: theme.palette.text.neutral.neutral400,
    },
    dropdownIcon: {
        fontSize: '26px !important', 
        transition: 'color 0.3s ease-in-out',
    },
}));

const NavbarDropdownTemplate: React.FC = () => {
    const [hoveredDropdown, setHoveredDropdown] = useState<string | null>(null);
    const navigate = useNavigate();

    const classes = useStyles();

    const handleItemClick = (path: string) => {
        navigate(path);
    };

    const renderDropdown = (
        label: string,
        hoveredDropdown: string | null,
        setHoveredDropdown: (label: string | null) => void,
        DropdownComponent: React.FC,
    ) => {
        const isOpen = hoveredDropdown === label;

        const handleMouseEnter = () => {
            if (hoveredDropdown !== label) {
                setHoveredDropdown(label);
            }
        };

        const handleMouseLeave = () => {
            if (hoveredDropdown === label) {
                setHoveredDropdown(null);
            }
        };

        const handleClick = (event: React.MouseEvent) => {
            event.stopPropagation(); // Prevents unintended state reset
            setHoveredDropdown(isOpen ? null : label); // Toggle dropdown state
        };

        return (
            <div
                className={classes.dropdownContainer}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onClick={handleClick}
                style={{ backgroundColor: isOpen ? '#D6DDF5' : 'transparent' }}
            >
                <div className={`font-medium xl:text-base lg:text-xs ${classes.homeSection}`}>{label}</div>
                {isOpen ? (
                    <ExpandLessIcon className={`${classes.homeSection} ${classes.dropdownIcon}`} />
                ) : (
                    <ExpandMoreIcon className={`${classes.homeSection} ${classes.dropdownIcon}`} />
                )}
                <div className={`${classes.dropdown}`}>
                    <div className="m-auto max-container">
                        {isOpen && <DropdownComponent />}
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className="flex justify-between w-full items-center">
            <div className={`${classes.navbarOptions} flex items-center`}>
                <ul className="flex flex-row gap-[16px] xl:gap-[4px] lg:gap-[0px]">
                    <li className='relative inline-block'>{renderDropdown("About Us", hoveredDropdown, setHoveredDropdown, AboutUsDropdown)}</li>
                    <li>{renderDropdown("Platform", hoveredDropdown, setHoveredDropdown, PlateformDropdown)}</li>
                    <li>{renderDropdown("Services", hoveredDropdown, setHoveredDropdown, ServiceDropDown)}</li>
                    <li>{renderDropdown("Membership", hoveredDropdown, setHoveredDropdown, MembershipDropdown)}</li>
                    <li>{renderDropdown("Trade Assurance", hoveredDropdown, setHoveredDropdown, TradeDropdown)}</li>
                </ul> 
            </div>

            <div className={`${classes.navbarOptions} flex items-center`}>
                <ul className="flex flex-row gap-[10px] xl:gap-[4px] lg:gap-[2px]">
                    <li>{renderDropdown("Suppliers", hoveredDropdown, setHoveredDropdown, SupplierDropdown)}</li>
                    <li>{renderDropdown("Buyer", hoveredDropdown, setHoveredDropdown, BuyerDropdown)}</li>
                    <li>{renderDropdown("Contact Us", hoveredDropdown, setHoveredDropdown, ContactUsDropdown)}</li>
                    <li>{renderDropdown("Help Center", hoveredDropdown, setHoveredDropdown, HelpCenterDropdown)}</li>
                </ul>
            </div>
        </div>
    );
};

export default NavbarDropdownTemplate;
