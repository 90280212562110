import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { createUseStyles } from 'react-jss';
import rawMaterial from '../../../assets/images/rawMaterial.jpg';
import score from '../../../assets/images/score.jpg';
import rightTimeIcon from '../../../assets/images/rightTime.jpg';
import cost from '../../../assets/images/cost.jpg';
import upArrowIcon from '../../../assets/icons/upArrowIcon.jpg'; 
import downArrowIcon from '../../../assets/icons/downArrowIcon.jpg'; 
import rightSource from '../../../assets/images/rightSource.png';
import rightTime from '../../../assets/images/rightTime.png';
import rightMaterial from '../../../assets/images/rightMaterial.png';
import rightCost from '../../../assets/images/rightCost.png';

interface AccordionData {
  title: string;
  content: React.ReactNode;
  icon: any;
  rightImage: any;
}

const useStyles = createUseStyles((theme: any) => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    
    border: '1px solid green',
    background: '#F8F8F8',
    borderRadius: '24px',
    padding: '16px 48px',
  },
  accordionContainer: {
    
  },
  accordion: {
    background: 'transparent',
    boxShadow: 'none',
    padding: '32px 0px',
    borderBottom: `2px solid ${theme.palette.border.primary.primary500}`,
    marginBottom: '0px !important',
    '& .MuiAccordionSummary-gutters': {
      height: '40px !important',
      padding: '0px',
    },
    '&:nth-last-child(1)': {
      borderBottom: 'none',
    },
  },
  title: {
    padding: '0px',
    fontSize: '32px',
    color: '#000',
    letterSpacing: '0.32px',
    marginLeft: '16px',
    fontWeight: '700',
  },
  accordionDetails: {
    padding: '0px',
    marginTop: '32px',
  },
  rightImage: {
    width: '500px',
    height: 'auto',
    borderRadius: '16px',
  },
  description: {
    '& ul': {
      display: 'flex',
      flexDirection: 'column',
      gap: '24px',
      '& li': {
        color: theme.palette.text.neutral.neutral800,
        fontSize: '20px',
        lineHeight: '23px',
        position: 'relative',
        paddingLeft: '16px',
        '&::before': {
          content: '""',
          position: 'absolute',
          left: 0,
          top: 0,
          bottom: 0,
          width: '4px',
          backgroundColor: theme.palette.background.primary.primary500,
          borderRadius: '2px',
        },
      },
    },
  },

  "@media screen and (max-width: 1024px)": {
        rightImage: {
        display:'none',
        },},
  "@media screen and (max-width: 768px)": {
        rightImage: {
        display:'none',
        },
        title: {
          padding: '0px',
          fontSize: '28px',
          color: '#000', 
          marginLeft: '16px',lineHeight:'48px',
          fontWeight: '700',
        },

      },
  "@media screen and (max-width: 480px)": {
        rightImage: {
        display:'none',
        },
        title: {
          padding: '0px',
          fontSize: '24px',
          color: '#000', 
          marginLeft: '16px',lineHeight:'48px',
          fontWeight: '700',
        },

      },
}));

// Custom component for up and down arrow
interface ExpandIconProps {
  isExpanded: boolean;
}

const CustomExpandIcon: React.FC<ExpandIconProps> = ({ isExpanded }) => (
  <img
    src={isExpanded ? upArrowIcon : downArrowIcon}
    alt={isExpanded ? 'Collapse' : 'Expand'}
    style={{  transform: isExpanded ? 'rotate(180deg)' : 'rotate(0deg)',
  }}
  />
);

const data: AccordionData[] = [
  {
    icon: rawMaterial,
    rightImage: rightMaterial,
    title: 'Right Material',
    content: (
      <ul>
        <li>Search Digital Product Catalogs / Auctions / RFQ Listings with Detailed Product Descriptions</li>
        <li>Generate Digital Purchase Requisitions</li>
        <li>Digital Quality Checks what You Ordered and Received</li>
        <li>Instant Online Quality Reports and Claims</li>
      </ul>
    ),
  },
  {
    icon: score,
    rightImage: rightSource,
    title: 'Right Source',
    content: (
      <ul>
        <li>Multiple Buying Sources Primary or Secondary or Distributors or Importers</li>
        <li>Automated Best Vendor Matching Algorithms</li>
        <li>Automated RFQs to receive Quotations from Multiple Sources</li>
        <li>Vendor Performance Analytics</li>
      </ul>
    ),
  },
  {
    icon: cost,
    rightImage: rightCost,
    title: 'Right Cost',
    content: (
      <ul>
        <li>Get Latest Real Time Market Prices of Reputed Brands</li>
        <li>Compare Multiple Suppliers to choose best </li>
        <li>Create Your Own Digital eAuction to discover Competitive Prices</li>
        <li>Offer Instant Payment for Best Supplier Negotiations</li>
        <li>Real Time Market Insights and Prices for Informed Decisions</li>
      </ul>
    ),
  },
  {
    icon: rightTimeIcon,
    rightImage: rightTime,
    title: 'Right Time',
    content: (
      <ul>
        <li>Choose Convenient and Flexible Delivery Schedules </li>
        <li>Engage Trusted & Reliable Logistics Partner </li>
        <li>Track Everything through Digital Orders </li>
        <li>Just in Time Inventory Management</li>
      </ul>
    ),
  },
];

export default function AccordionExpandDefault() {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState<string | false>('panel0');

  const handleChange =
    (panel: string) =>
    (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <div className={classes.container}>
      {/* Accordion Section */}
      <div className={classes.accordionContainer}>
        {data.map((item, index) => (
          <Accordion
            key={index}
            expanded={expanded === `panel${index}`}
            onChange={handleChange(`panel${index}`)}
            className={classes.accordion}
          >
            <AccordionSummary
              expandIcon={<CustomExpandIcon isExpanded={expanded === `panel${index}`} />}
              aria-controls={`panel${index}-content`}
              id={`panel${index}-header`}
            >
              <img src={item.icon} alt={item.title} />
              <div className={classes.title}>{item.title}</div>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              <div className={classes.description}>{item.content}</div>
            </AccordionDetails>
          </Accordion>
        ))}
      </div>

      {/* Right-side Image Section */}
      {data.map((item, index) =>
        expanded === `panel${index}` ? (
          <img
            key={`image-${index}`}
            src={item.rightImage}
            alt={item.title}
            className={classes.rightImage}
          />
        ) : null
      )}
    </div>
  );
}

