import React from 'react';
import { createUseStyles } from 'react-jss';
import vendorSeller from '../../../assets/images/vendorSeller.svg';
import vendorWearhouse from '../../../assets/images/vendorWearhouse.svg';
import vendorQuality from '../../../assets/images/vendorQuality.svg';
import vendorPayment from '../../../assets/images/vendorPayment.svg';
import vendorRetailing from '../../../assets/images/vendorRetailing.svg';
import vendorOrders from '../../../assets/images/vendorOrders.svg';
import vendorRelationship from '../../../assets/images/vendorRelationship.svg';

const useStyles = createUseStyles((theme: any) => ({
    container: {
        width: '100%',
        padding: '20px 12px 32px 12px',
    },
    serialNumber: {
        width: '20px',
        height: '20px',
        borderRadius: '10px',
        border: `1px solid ${theme.palette.border.complementary.complementary800}`,
        background: theme.palette.background.complementary.complementary400,
        color: '#fff',
    },
    cardHeading: {
        color: theme.palette.text.neutral.neutral900,
    },
    retailingDescription: {
        color: theme.palette.text.neutral.neutral900,
    },
}));


const OrderRetailingTemplate: React.FC = () => {
    const classes = useStyles();

    return (
        <>
        <div className=''>
            <div className={`${classes.container} ZigZagText`}>
                <div className="grayText">Step 03:</div>
                <div className="heading48">
                    Purchase Orders to <span>Retailing</span>
                </div>
                <div className="Subhead">
                    <p>Streamlined Process from Orders to Customer Delivery</p>
                </div>
                <div className='flex flex-col gap-3 mt-3 ZigZagSection'>
                    <div className='flex gap-2 items-center'>
                        <img src={vendorSeller} alt="" />
                        <div className='w-3/4'>
                            <div className={`${classes.cardHeading} flex items-center gap-1 mb-1 font-semibold text-base`}><div className={`${classes.serialNumber} text-[12px] leading-[14px] font-semibold flex justify-center items-center`}>1</div>Start Selling</div>
                            <div>
                                <p className={`${classes.retailingDescription} leading-[16px]`}>Once approved, you will receive purchase orders from Steelbazaar based on demand.</p>
                            </div>
                        </div>
                    </div>
                    <div className='flex gap-2 items-center'>
                        <div>
                            <div className={`${classes.cardHeading} flex items-center gap-1 mb-1 font-semibold text-base`}><div className={`${classes.serialNumber} text-[12px] leading-[14px] font-semibold flex justify-center items-center`}>2</div>Purchase Orders</div>
                            <div>
                                <p className={`${classes.retailingDescription} leading-[16px]`}>SteelBazaar sends you purchase orders based on demand forecasts.</p>
                            </div>
                        </div>
                        <img src={vendorOrders} alt="" />
                    </div>
                    <div className='flex gap-2 items-center'>
                        <img src={vendorWearhouse} alt="" />
                        <div>
                            <div className={`${classes.cardHeading} flex items-center gap-1 mb-1 font-semibold text-base`}><div className={`${classes.serialNumber} text-[12px] leading-[14px] font-semibold flex justify-center items-center`}>3</div>Fulfilment</div>
                            <div>
                                <p className={`${classes.retailingDescription} leading-[16px]`}>Ship the ordered products to SteelBazaar's fulfilment centres.</p>
                            </div>
                        </div>
                    </div>
                    <div className='flex gap-2 items-center'>
                        <div>
                            <div className={`${classes.cardHeading} flex items-center gap-1 mb-1 font-semibold text-base`}><div className={`${classes.serialNumber} text-[12px] leading-[14px] font-semibold flex justify-center items-center`}>4</div>Quality Assurance</div>
                            <div>
                                <p className={`${classes.retailingDescription} leading-[16px]`}>Steelbazaar inspects and verifies the quality of received products.</p>
                            </div>
                        </div>
                        <img src={vendorQuality} alt="" />
                    </div>
                    <div className='flex gap-2 items-center'>
                        <img src={vendorPayment} alt="" />
                        <div>
                            <div className={`${classes.cardHeading} flex items-center gap-1 mb-1 font-semibold text-base`}><div className={`${classes.serialNumber} text-[12px] leading-[14px] font-semibold flex justify-center items-center`}>5</div>Payment</div>
                            <div>
                                <p className={`${classes.retailingDescription} leading-[16px]`}>Upon successful delivery and quality check, payments are released to your account as per the agreed terms.</p>
                            </div>
                        </div>
                    </div>
                    <div className='flex gap-2 items-center'>
                        <div>
                            <div className={`${classes.cardHeading} flex items-center gap-1 mb-1 font-semibold text-base`}><div className={`${classes.serialNumber} text-[12px] leading-[14px] font-semibold flex justify-center items-center`}>6</div>Retailing</div>
                            <div>
                                <p className={`${classes.retailingDescription} leading-[16px]`}>SteelBazaar manages the retailing process, including customer service and returns.</p>
                            </div>
                        </div>
                        <img src={vendorRetailing} alt="" />
                    </div>
                    <div className='flex gap-2 items-center'>
                        <img src={vendorRelationship} alt="" />
                        <div>
                            <div className={`${classes.cardHeading} flex items-center gap-1 mb-1 font-semibold text-base`}><div className={`${classes.serialNumber} text-[12px] leading-[14px] font-semibold flex justify-center items-center`}>7</div>Long-Term Relationship</div>
                            <div>
                                <p className={`${classes.retailingDescription} leading-[16px]`}>Continue to receive regular orders from Steelbazaar based on performance, product demand, and supply consistency</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </>
    );
};

export default OrderRetailingTemplate;
