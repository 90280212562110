import React, { useEffect, useMemo, useState } from 'react';
import { createUseStyles } from 'react-jss';
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import StoreFrontAboutUsPage from '../../pages/DashBoard/StoreFrrontAboutUs.page';
import { makeRoute } from '../../../utils/helper';
import { CUSTOMER_ROUTES } from '../../../utils/constant';
import TabsV3, { ITabsSchema } from '../../molecules/TabsV2/TabsV3';
import StoreFrontProduct from '../../pages/StoreFront/StoreFrontProduct.page';
import StoreFrontContactUs from '../../pages/StoreFront/StoreFrontContactUs.page';
import { useSnackbar } from '../../../hooks/useSnackBar';
import { IBusinessProfile, useBusinessProfileService } from '../../../services/useBusinessProfileService';
import { HTTP_STATUS } from '../../../utils/types';

interface StoreFrontNavProps {
    localIsStorefront?: boolean
}

const useStyles = createUseStyles((theme: any) => ({
    container: {
        borderTop: `1px solid ${theme.palette.border.neutral.neutral200}`,
    },
}));

const StoreFrontNavBarOptions: React.FC<StoreFrontNavProps> = ({ localIsStorefront }) => {

    const classes = useStyles();
    const [searchParams, setSearchParams] = useSearchParams();

    const params = useParams();

    const [activeTab, setActiveTab] = useState<string>('1');
    const navigate = useNavigate()

    const { showSnackbar, SnackBarComponent } = useSnackbar();

    const bussinessProfileService = useBusinessProfileService();
    const [businessProfile, setBusinessProfileTo] = useState<IBusinessProfile | null>(null);

    const getBusinessProfile = async () => {
        try {
            const businessProfileResponse = await bussinessProfileService.getBusinessProfile();
            if (businessProfileResponse.status === HTTP_STATUS.OK) {
                const profileData = businessProfileResponse?.data?.data;
                setBusinessProfileTo(profileData);
            }
        } catch (error) {
            showSnackbar('error', 'Business Profile fetch failed');
        }
    };

    // useEffect(() => {
    //     getBusinessProfile();  
    // }, []);

    const getBusinessProfileByVendorID = async () => {
        if (Number(params.id)) {
            try {
                const businessProfileResponse = await bussinessProfileService.getBusinessProfileByVendorId(Number(params.id));
                if (businessProfileResponse.status === HTTP_STATUS.OK) {
                    const profileData = businessProfileResponse?.data?.data;
                    setBusinessProfileTo(profileData);
                }
            } catch (error) {
                showSnackbar('error', 'Business Profile fetch failed');
            }
        }
    };

    useEffect(() => {
        if (Number(params.id)) {
            getBusinessProfileByVendorID();
        } else {
            getBusinessProfile();
        }
    }, [params.id]);

    // useEffect(() => {
    //     getBusinessProfileByVendorID();
    // }, [Number(params.id)]);

    const schema: ITabsSchema[] = [
        { label: 'About Us', component: <StoreFrontAboutUsPage businessProfile={businessProfile} /> },
        { label: 'Products', component: <StoreFrontProduct businessProfile={businessProfile} vendorId={Number(params.id)}/>, hasIcon: <MenuIcon /> },
        { label: 'Services', component: <div></div>, disabled: true },
        { label: 'Auctions', component: <div></div>, disabled: true },
        { label: 'Sales Offer', component: <div></div>, disabled: true },
        { label: 'Contact Us', component: <StoreFrontContactUs businessProfile={businessProfile} /> }
    ]

    // const handleTabChange = (tab: string | number) => {
    //     setActiveTab(tab.toString());
    //     const route = makeRoute(CUSTOMER_ROUTES.STORE_FRONT, { query: { tab: tab.toString() } });
    //     navigate(`${route}`);
    // };

    // useEffect(()=>{
    //     if(searchParams.get('tab'))
    //     setActiveTab(searchParams.get('tab') ?? "1")
    //   },[searchParams.get('tab')])

    const handleTabChange = (tab: string | number) => {
        setActiveTab(tab.toString());
        const route = makeRoute(`${CUSTOMER_ROUTES.STORE_FRONT}/:id`, {
            params: { id: Number(params.id) },
            query: { tab: tab.toString() }
        });
        navigate(route);
    };

    useEffect(() => {
        if(searchParams.get('tab')){
        const tabFromQuery = searchParams.get('tab') || '1';
        setActiveTab(tabFromQuery);
        }
    }, [searchParams]);

    return (
        <div className={`${classes.container} w-full`}>
            {SnackBarComponent}
            {localIsStorefront &&
                <div className={`flex items-center w-[1300px] gap-x-[20px] mx-auto`}>
                    <TabsV3 schema={schema} value={activeTab.toString()} setValue={handleTabChange} />
                </div>}
        </div>
    );
};

export default StoreFrontNavBarOptions;