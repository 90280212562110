import * as React from 'react';
import * as yup from "yup";
import { createUseStyles } from 'react-jss';
import Button from '../../atoms/Button/Button';
import { useFormik } from 'formik';
import { useSnackbar } from '../../../hooks/useSnackBar';
import { useState } from 'react';
import { useBusinessProfileService } from '../../../services/useBusinessProfileService';
import { REGEX } from '../../../utils/constant';
import TextFieldV2 from '../../atoms/Input/TextFieldV2';
import SelectV2 from '../../atoms/Select/SelectV2';

const useStyles = createUseStyles((theme: any) => ({
    container: {
        border: `1px solid ${theme.palette.border.neutral.neutral100}`,
        borderRadius: "8px",
    },
    primary_container: {
        border: `1px solid ${theme.palette.border.neutral.neutral100}`,
    },
    title: {
        color: theme.palette.text.primary.primary900,
    },
    borderBottom: {
        borderBottom: `1px solid ${theme.palette.border.neutral.neutral100}`,
    }
}));

export interface udhyam {
    businessType: string;
    udhyamNumber: number;
}

interface BussinessUdhyamEditTemplateProps {
    updateBusinessProfileSection: any;
    businessProfile: any;
    onSuccess: (updatedProfile: any) => void;
    onBack: () => void;
}

const BussinessUdhyamEditTemplate: React.FC<BussinessUdhyamEditTemplateProps> = ({ updateBusinessProfileSection, businessProfile, onSuccess, onBack }) => {
    const classes = useStyles();
    const bussinessProfileService = useBusinessProfileService();
    const { showSnackbar } = useSnackbar();
    const [isEditing, setIsEditing] = useState(false);

    const udhyamSchema = yup.object().shape({
        businessType: yup.string().required('Business Classification is required'),
        udhyamNumber: yup
            .string()
            .matches(/^UDYAM-[A-Z]{2}-\d{2}-\d{7}$/, 'Enter a valid Udyam Number (e.g., UDYAM-AB-12-1234567)')
            .required('Udyam Number is required'),
    });
    

    const handleBack = () => {
        onBack();
    };

    const handleSubmit = () => {
        udhyamformik.handleSubmit();
    };

    const udhyamformik = useFormik<udhyam>({
        initialValues: {
            businessType: businessProfile?.udhyam?.businessType || '',
            udhyamNumber: businessProfile?.udhyam?.udhyamNumber || '',
        },
        validationSchema: udhyamSchema,
        onSubmit: async (values: any) => {
            try {
                udhyamformik.setSubmitting(true);
                const requestBody = {
                    businessType: values?.businessType,
                    udhyamNumber: values?.udhyamNumber,
                };
                await bussinessProfileService.updateBusinessProfile(requestBody, "UDHYAM");
                showSnackbar("success", "Business profile updated successfully");
                onSuccess({ ...businessProfile, udhyam: requestBody });
            } catch (error) {
                showSnackbar("error", `Business profile update failed: ${error}`);
            } finally {
                udhyamformik.setSubmitting(false);
            }
        },
    });

    const handleUpdateDetails = (field: string, value: any) => {
        udhyamformik.setFieldValue(field, value);
    };

    const businessTypes = [
        { id: 'SME', name: 'SME' },
        { id: 'MSME', name: 'MSME' },
    ];

    return (
        <form onSubmit={udhyamformik.handleSubmit}>
            <div className={`${classes.container} p-4 grid gap-y-6 rounded-xl w-full`}>
                <div className={`flex justify-between items-center pb-6 ${classes.borderBottom}`}>
                    <h2 className={`${classes.title} text-lg font-medium`}>Udyam Details</h2>
                    <div className='flex gap-4'>
                        <Button variant={'tertiaryContained'} label={'Back'} type="button" onClick={handleBack} />
                        <Button variant={'primaryContained'} label={'Save'} type="submit" onClick={handleSubmit} />
                    </div>
                </div>
                <div className='flex flex-col gap-y-6  w-1/2'>
                    <SelectV2
                        label='Business Classification'
                        fullWidth
                        {...udhyamformik.getFieldProps("businessType")}
                        error={
                            udhyamformik.touched?.businessType &&
                            Boolean(udhyamformik.errors?.businessType)
                        }
                        disableCapitalization={true}
                        options={
                            businessTypes?.map((businessType) => ({
                                value: businessType.id,
                                label: businessType.name,
                            })) || []
                        }
                    />
                        <TextFieldV2
                            label="Enter the udyam number to fill out the details below"
                            fullWidth
                            {...udhyamformik.getFieldProps("udhyamNumber")}
                            error={
                                udhyamformik.touched?.udhyamNumber &&
                                Boolean(udhyamformik.errors?.udhyamNumber)
                            }
                            required
                            helperText={(udhyamformik.touched?.udhyamNumber &&
                                Boolean(udhyamformik.errors?.udhyamNumber)) && udhyamformik.errors?.udhyamNumber}
                            inputProps={{ readOnly: false }}
                        />
                </div>
            </div>
        </form>
    );
};

export default BussinessUdhyamEditTemplate;
