import React, { useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import supplyChainFinance from '../../../assets/images/supplyChainFinance.jpg';
import RequestDemoTemplate from '../../template/Home/RequestDemo/RequestDemo.template';
import PrimaryTick from '../../../assets/images/PrimaryBullet.svg';
import orangeTick from '../../../assets/images/OrangeBullet.svg';
import ComplementaryTick from '../../../assets/images/ComplementaryBullet.svg';
import card1 from '../../../assets/images/card1.svg';
import pricing from '../../../assets/images/pricing.svg';
import invoiceCard from '../../../assets/images/invoiceCard.png';
import { useLocation } from 'react-router-dom';
//bykamal
import supplyChainMobileBanner from '../../../assets/images/supplyChainFinanceMobileBanner.jpg';
import DotImg from "../../../assets/images/dot-shape.png";
import BulletImg from "../../../assets/images/bulletIcon.svg";
import Step1Img from '../../../assets/images/supplychain-Save-on-Overall.jpg';
import Step2Img from '../../../assets/images/supplychain-Reliable-Cash-Flow.jpg';
import Step3Img from '../../../assets/images/supplychain-Sales-Invoice-Discounting.jpg';
import Step4Img from '../../../assets/images/supplychain-Purchase-Invoice-Financing.jpg';
import Step5Img from '../../../assets/images/supplychain-Dynamic-Discounting.jpg';

const useStyles = createUseStyles((theme: any) => ({
  bannerContainer: {
    backgroundImage: `url(${supplyChainFinance})`,
    backgroundSize: 'cover',
  },
  card1: {

    padding: '24px', borderRadius: '16px',
    "& .textBx": {
      color: '#000', fontSize: '18px',
      display: 'flex', flexDirection: 'column',
      "& .title": {
        fontSize: '32px',
        lineHeight: '38px', paddingBottom: '10px'
      },
    },
    "& .imgBx": {
      paddingTop: '20px',
      display: 'flex', justifyContent: 'space-between', alignItems: 'end',
      "& img": { height: '150px', },
      "& .button": {
        borderRadius: '10px', height: '40px',
        background: '#fff',
        border: `1px solid ${theme.palette.border.primary.primary800}`,
        color: theme.palette.text.primary.primary800,
        padding: '0px 20px', lineHeight: '40px',
      },
    },
    "&.warning200": { background: theme.palette.background.warning.warning200, },
    "&.primary100": { background: theme.palette.background.primary.primary100, },
    "&.secondary100": { background: theme.palette.background.secondary.secondary100, },
  },


  profileCard1: {
    background: theme.palette.background.complementary.complementary50,
    border: `1px solid ${theme.palette.border.complementary.complementary400}`,
    overflow: 'hidden',
    "& p": {
      color: theme.palette.text.complementary.complementary800,
      lineHeight: '22px',
    },
    "& h4": {
      color: theme.palette.text.complementary.complementary800,
      lineHeight: '22px',
    },
  },
  profileCardHeader1: {
    background: theme.palette.background.complementary.complementary400,
    fontSize: '22px',
    color: '#fff',
  },
  profileCard2: {
    background: theme.palette.background.warning.warning50,
    border: `1px solid ${theme.palette.border.warning.warning400}`,
    overflow: 'hidden',
    "& p": {
      color: theme.palette.text.warning.warning800,
      lineHeight: '22px',
    },
    "& h4": {
      color: theme.palette.text.warning.warning800,
      lineHeight: '22px',
    },
  },
  profileCardHeader2: {
    background: theme.palette.background.warning.warning400,
    fontSize: '22px',
    color: '#fff',
  },
  profileCard3: {
    background: '#FFEFE6',
    overflow: 'hidden',
    border: '1px solid #FD8235',
    "& p": {
      color: '#652701',
      lineHeight: '22px',
    },
    "& h4": {
      color: '#652701',
      lineHeight: '22px',
    },
  },
  profileCardHeader3: {
    background: '#FD8235',
    fontSize: '22px',
    color: '#fff',

  },
  profileCard4: {
    background: theme.palette.background.primary.primary50,
    border: `1px solid ${theme.palette.border.primary.primary400}`,
    overflow: 'hidden',
    "& p": {
      color: theme.palette.text.primary.primary800,
      lineHeight: '22px',
    },
    "& h4": {
      color: theme.palette.text.primary.primary800,
      lineHeight: '22px',
    },
  },
  profileCardHeader4: {
    background: theme.palette.background.primary.primary400,
    fontSize: '22px',
    color: '#fff',
  },
  profileCard5: {
    background: theme.palette.background.success.success50,
    border: `1px solid ${theme.palette.border.success.success400}`,
    overflow: 'hidden',
    "& p": {
      color: theme.palette.text.success.success800,
      lineHeight: '22px',
    },
    "& h4": {
      color: theme.palette.text.success.success800,
      lineHeight: '22px',
    },
  },
  profileCardHeader5: {
    background: theme.palette.background.success.success400,
    fontSize: '22px',
    color: '#fff',
  },
  solutionCard: {
    border: `1px solid ${theme.palette.border.neutral.neutral200}`,
    background: '#F8F8F8',
    width: '49.2%',
    '& h4': {
      color: '#000',
      fontSize: "22px",
      lineHeight: '26px',
    },
    '& p': {
      color: '#000',
      lineHeight: '20px',
    },
  },
  buyerCardHeading: {
    color: theme.palette.text.primary.primary500,
    fontSize: '32px',
  },
  financeText: {
    color: theme.palette.text.neutral.neutral600,
    lineHeight: '28px',
  },
  "@media screen and (max-width: 480px)": {
    bannerContainer: {
      backgroundImage: `url(${supplyChainMobileBanner})`,
      backgroundSize: "100% 100%",
    },

    card1: {
      padding: '16px', borderRadius: '16px',
      "& .textBx": {
        color: '#000', fontSize: '16px',
        display: 'flex', flexDirection: 'column',
        "& .title": {
          fontWeight: '500',
          fontSize: '18px',
          lineHeight: '22px', paddingBottom: '4px'
        },
      },
      "& .imgBx": {
        paddingTop: '20px',
        display: 'flex', justifyContent: 'space-between', alignItems: 'end',
        "& img": { height: '150px', },
        "& .button": {
          borderRadius: '10px', height: '40px',
          background: '#fff',
          border: `1px solid ${theme.palette.border.primary.primary800}`,
          color: theme.palette.text.primary.primary800,
          padding: '0px 20px', lineHeight: '40px',
        },
      },
      "&.warning200": { background: theme.palette.background.warning.warning200, },
      "&.primary100": { background: theme.palette.background.primary.primary100, },
      "&.secondary100": { background: theme.palette.background.secondary.secondary100, },
    },
  },
}));

const SupplyChainFinance: React.FC = () => {
  const classes = useStyles();
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const component = params.get('component');

    if (component) {
      const element = document.getElementById(component);
      if (element) {
        element.scrollIntoView();
      }
    }
  }, [location.search]);

  return (
    <>
      <div className={`${classes.bannerContainer}`}>
        <div className="container mx-auto px-4">
          <div className="insideBannerBx">
            <div className="insideBannerInfo">
              <b>SB InstaFin</b>
              <h1>
                Unlock Growth with<br /> Flexible Financing Solutions
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="neutral20Bg lg:py-14 py-8 w-full" id="features">
        <div className="container lg:px-4 px-2 mx-auto flex lg:columns-2 columns-1 lg:flex-row flex-col lg:gap-10 gap-2 PageInfoBoxGry">
          <div className="lg:w-2/5 w-full">
            <h2>Smart Supply Chain Financing</h2>
          </div>
          <div className={`lg:w-3/5 w-full`}>
            <p>
              At Steelbazaar, we recognise the financial challenges in steel trading, & that’s why we present SB Instafin a cutting-edge supply chain finance service tailored to your needs. With features like Sales Invoice Discounting, Purchase Invoice Financing, & Dynamic Discounting, SB Instafin enhances cash flow, strengthens supplier relationships, & reduces procurement costs.
            </p>
            <p>Flexible, collateral-free solutions unlock growth opportunities, align repayments with cash flow, & empower your business to excel in a competitive market.</p>
          </div>
        </div>
      </div>

      <div className= 'container mx-auto lg:px-4 px-2 lg:py-24 py-8'>
        <div className={`headingContainer`}>
          <div className="heading">Flexible Finance Solutions for All</div> </div>

        {
          // Section One Start Here
        }
        <div className="flex lg:columns-2 columns-1 lg:flex-row flex-col items-center ZigZagSection lg:gap-10">
          <div className="2xl:w-4/12 lg:w-5/12 w-full flex content-center justify-center">
            <div className="ZigZagImgBox">
              <div className="ImgItem">
                <img src={DotImg} alt="" />
              </div>
              <img src={Step1Img} className="w-full" alt="" />
            </div>
          </div>
          <div className="2xl:w-8/12 lg:w-7/12 w-full ZigZagText">
            <div className="BlueText">For Buyers</div>
            <div className="heading48">
              Save on Overall Steel Procurement Costs
            </div>
            <div className="PointBox w-full flex flex-wrap gap-3">
              <div className="grayWhiteBox colWidth-2">
                <div className="TextBox">
                  <b>Competitive Prices</b>
                  Buy steel at marketplace-best rates.
                </div>
              </div>
              <div className="grayWhiteBox colWidth-2">
                <div className="TextBox">
                  <b>Flexible Credit Terms</b>
                  Choose repayments aligned to business cycles.
                </div>
              </div>
              <div className="grayWhiteBox colWidth-2">
                <div className="TextBox">
                  <b>Pre-Approved Credit</b>
                  Instant financing for seamless procurement.
                </div>
              </div>
              <div className="grayWhiteBox colWidth-2">
                <div className="TextBox">
                  <b>Quick Approvals</b>
                  Get financing faster than traditional channels.
                </div>
              </div>
            </div>
          </div>
        </div>
        {
          // Section Two Start Here
        }
        <div className="flex lg:columns-2 columns-1 lg:flex-row flex-col items-center ZigZagSection border-0 lg:gap-10">
          <div className="2xl:w-4/12 lg:w-5/12 w-full flex content-center order-1 lg:order-2 justify-center">
            <div className="ZigZagImgBox">
              <div className="ImgItemLeft">
                <img src={DotImg} alt="" />
              </div>
              <img src={Step2Img} className="w-full" alt="" />
            </div>
          </div>
          <div className="2xl:w-8/12 lg:w-7/12 w-full ZigZagText order-2 lg:order-1">
            <div className="BlueText">For Sellers</div>
            <div className="heading48">
              Reliable Cash Flow for Consistent Business Growth
            </div>
            <div className="PointBox w-full flex flex-wrap gap-3">
              <div className="grayWhiteBox colWidth-2">
                <div className="TextBox">
                  <b>Instant Payments</b>
                  Convert invoices into immediate cash.
                </div>
              </div>
              <div className="grayWhiteBox colWidth-2">
                <div className="TextBox">
                  <b>Secure Transactions</b>
                  Sell to pre-approved buyers for risk-free deals.
                </div>
              </div>
              <div className="grayWhiteBox colWidth-2">
                <div className="TextBox">
                  <b>Enhanced Liquidity</b>
                  Strengthen cash flow for operations.
                </div>
              </div>
              <div className="grayWhiteBox colWidth-2">
                <div className="TextBox">
                  <b>Effortless Financing</b>
                  Simplified, fast, and transparent process.
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

      <div className="neutral20Bg lg:py-24 py-8 w-full !z-[-1] relative" id='sellSmart' >
        <div className= 'container mx-auto lg:px-4 px-2'>
          <div className={`headingContainer`}>
            <div className="heading">Empowering Your Steel Supply Chain <span className='lg:block !text-black'>with Smarter Financing</span></div>
            <div className="Subheading">Funds Never Short</div>
          </div>

          <div className={`grid xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 md:gap-4 gap-4`}>
            <div className={`${classes.card1} warning200`}>
              <div className='textBx'>
                <div className={`title`}>Sales Invoice Discounting</div>
                Turn Pending Payments into Working Capital Instantly
              </div>
              <div className='imgBx'>
                <a href="" className={`button`}>Contact Now</a>
                <img src={card1} alt="" />
              </div>
            </div>

            <div className={`${classes.card1} primary100`}>
              <div className='textBx'>
                <div className={`title`}>Purchase Invoice Finance</div>
                Maximise your Revenues, Liquidate in shortest time
              </div>
              <div className='imgBx'>
                <a href="" className={`button`}>Contact Now</a>
                <img src={invoiceCard} alt="" />
              </div>
            </div>

            <div className={`${classes.card1} secondary100`}>
              <div className='textBx'>
                <div className={`title`}>Dynamic Discounting</div>
                Accelerate Liquidity while Maximizing Savings
              </div>
              <div className='imgBx'>
                <a href="" className={`button`}>Contact Now</a>
                <img src={pricing} alt="" />
              </div>
            </div>
          </div>
          <div className= 'container mx-auto lg:px-4 px-2 lg:py-[90px] py-8'>
            {
              // Section Three Start Here
            }
            <div className="flex lg:columns-2 columns-1 lg:flex-row flex-col items-center ZigZagSection lg:gap-20" id='sales-invoice-discounting'>
              <div className="2xl:w-4/12 lg:w-5/12 w-full flex content-center justify-center">
                <div className="ZigZagImgBox">
                  <div className="ImgItem">
                    <img src={DotImg} alt="" />
                  </div>
                  <img src={Step3Img} className="w-full" alt="" />
                </div>
              </div>
              <div className="2xl:w-8/12 lg:w-7/12 w-full ZigZagText">
                <div className="heading48">
                  Sales Invoice <span className='block'>Discounting</span>
                </div>
                <div className="Subhead"><b>Turn Sales Into Cash Instantly</b></div>
                <div className="PointBox">
                  <ul>
                    <li className="items-center">
                      <img src={BulletImg} className="bulletBrd" alt="" />
                      <span>
                        <b>Discount invoices up to 90% value.</b>
                      </span>
                    </li>
                    <li className="items-center">
                      <img src={BulletImg} className="bulletBrd" alt="" />
                      <span>
                        <b>Get funds within 24 hours.
                        </b>
                      </span>
                    </li>
                    <li className="items-center">
                      <img src={BulletImg} className="bulletBrd" alt="" />
                      <span>
                        <b>No collateral required. </b>
                      </span>
                    </li>
                    <li className="items-center">
                      <img src={BulletImg} className="bulletBrd" alt="" />
                      <span>
                        <b>Transparent and competitive terms.</b>
                      </span>
                    </li>
                    <li className="items-center">
                      <img src={BulletImg} className="bulletBrd" alt="" />
                      <span>
                        <b>Strengthen operational cash flow.</b>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {
              // Section Four Start Here
            }
            <div className="flex lg:columns-2 columns-1 lg:flex-row flex-col items-center ZigZagSection lg:gap-20" id='purchase-invoice-finance'>
              <div className="2xl:w-4/12 lg:w-5/12 w-full flex content-center order-1 lg:order-2 justify-center">
                <div className="ZigZagImgBox">
                  <div className="ImgItemLeft">
                    <img src={DotImg} alt="" />
                  </div>
                  <img src={Step4Img} className="w-full" alt="" />
                </div>
              </div>
              <div className="2xl:w-8/12 lg:w-7/12 w-full ZigZagText order-2 lg:order-1">

                <div className="heading48">
                  Purchase Invoice <span className='block'>Financing</span>
                </div>
                <div className="Subhead"><b>Procure with Confidence</b></div>
                <div className="PointBox">
                  <ul>
                    <li className="items-center">
                      <img src={BulletImg} className="bulletBrd" alt="" />
                      <span>
                        <b>Financing up to 100% of invoice value.</b>
                      </span>
                    </li>
                    <li className="items-center">
                      <img src={BulletImg} className="bulletBrd" alt="" />
                      <span>
                        <b>Flexible repayment aligned with business cycles.</b>
                      </span>
                    </li>
                    <li className="items-center">
                      <img src={BulletImg} className="bulletBrd" alt="" />
                      <span>
                        <b>Pre-approved credit limits for hassle-free transactions. </b>
                      </span>
                    </li>
                    <li className="items-center">
                      <img src={BulletImg} className="bulletBrd" alt="" />
                      <span>
                        <b>Reduce reliance on supplier credit.</b>
                      </span>
                    </li>
                    <li className="items-center">
                      <img src={BulletImg} className="bulletBrd" alt="" />
                      <span>
                        <b>Strengthen supplier relationships with timely payments.</b>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {
              // Section Five Start Here
            }
            <div className="flex lg:columns-2 columns-1 lg:flex-row flex-col items-center ZigZagSection pb-0 border-0 lg:gap-20" id='dynamic-discounting'>
              <div className="2xl:w-4/12 lg:w-5/12 w-full flex content-center justify-center">
                <div className="ZigZagImgBox">
                  <div className="ImgItem">
                    <img src={DotImg} alt="" />
                  </div>
                  <img src={Step5Img} className="w-full" alt="" />
                </div>
              </div>
              <div className="2xl:w-8/12 lg:w-7/12 w-full ZigZagText">

                <div className="heading48">
                  Dynamic <span>Discounting</span>
                </div>
                <div className="Subhead"><b>Optimize Costs Dynamically</b></div>
                <div className="PointBox">
                  <ul>
                    <li className="items-center">
                      <img src={BulletImg} className="bulletBrd" alt="" />
                      <span>
                        <b>Early payments secure discounts up to 5%.</b>
                      </span>
                    </li>
                    <li className="items-center">
                      <img src={BulletImg} className="bulletBrd" alt="" />
                      <span>
                        <b>Tailored terms for buyer-seller flexibility.</b>
                      </span>
                    </li>
                    <li className="items-center">
                      <img src={BulletImg} className="bulletBrd" alt="" />
                      <span>
                        <b>Improve procurement cost efficiency. </b>
                      </span>
                    </li>
                    <li className="items-center">
                      <img src={BulletImg} className="bulletBrd" alt="" />
                      <span>
                        <b>Strengthen seller liquidity with faster funds.</b>
                      </span>
                    </li>
                    <li className="items-center">
                      <img src={BulletImg} className="bulletBrd" alt="" />
                      <span>
                        <b>Reduce overall financing and trading costs.</b>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className= 'container mx-auto lg:px-4 px-2 lg:py-24 py-8'>
        <div className={`headingContainer`}>
          <div className="heading">SB Instafin Journey From Eligibility to Financing</div> </div>
        <div className="PointBox flex flex-wrap w-full justify-center  gap-4">
          <div
            className={`${classes.profileCard1} colWidth-5 pb-5 rounded-lg`}
          >
            <div
              className={`${classes.profileCardHeader1} mb-5 px-[15px] py-2 font-semibold`}
            >
              Step 1
            </div>
            <h4 className='font-semibold text-lg px-[15px] mb-[10px]'>Check Eligibility & Credit Assessment</h4>
            <p className='px-[15px] font-medium text-lg'>Submit business details to confirm eligibility.</p>
          </div>
          <div
            className={`${classes.profileCard2} colWidth-5 pb-5 rounded-lg`}
          >
            <div
              className={`${classes.profileCardHeader2} mb-5 px-[15px] py-2 font-semibold`}
            >
              Step 2
            </div>
            <h4 className='font-semibold text-lg px-[15px] mb-[10px]'>Credit Facility<br />Sanction</h4>
            <p className='px-[15px] font-medium text-lg'>Receive custom financing offers from NBFC partners.</p>
          </div>
          <div
            className={`${classes.profileCard3} colWidth-5 pb-5 rounded-lg`}
          >
            <div
              className={`${classes.profileCardHeader3} mb-5 px-[15px] py-2 font-semibold`}
            >
              Step 3
            </div>
            <h4 className='font-semibold text-lg px-[15px] mb-[10px]'>Buy/Sell on<br />Steelbazaar</h4>
            <p className='px-[15px] font-medium text-lg'>Trade seamlessly on the marketplace with confidence.</p>
          </div>
          <div
            className={`${classes.profileCard4} colWidth-5 pb-5 rounded-lg`}
          >
            <div
              className={`${classes.profileCardHeader4} mb-5 px-[15px] py-2 font-semibold`}
            >
              Step 4
            </div>
            <h4 className='font-semibold text-lg px-[15px] mb-[10px]'>Apply for SB Instafin<br />Services</h4>
            <p className='px-[15px] font-medium text-lg'>Choose suitable financing options and submit invoices.</p>
          </div>
          <div
            className={`${classes.profileCard5} colWidth-5 pb-5 rounded-lg`}
          >
            <div
              className={`${classes.profileCardHeader5} mb-5 px-[15px] py-2 font-semibold`}
            >
              Step 5
            </div>
            <h4 className='font-semibold text-lg px-[15px] mb-[10px]'>Set your Detailed<br />Preferences.</h4>
            <p className='px-[15px] font-medium text-lg'>Funds are transferred directly to your account or supplier. </p>
          </div>
        </div>
      </div>

      <div className= 'container mx-auto lg:px-4 px-2 lg:py-24 py-8'>
        <div className={`headingContainer`}>
          <div className="heading">Flexible Finance Solution for All</div>
        </div>
        <div className={`grid xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 md:gap-4 lg:gap-8 gap-4`}>
          <div className='SolutionBox orange400'>
            <b>SMEs & Micro</b>
            <div className='textBox'>
              <ul>
                <li>
                  <img src={orangeTick} alt="" /> <span>Minimum 3 years of trading experience.</span>
                </li>
                <li className='items-start'>
                  <img src={orangeTick} alt="" /> <span>Minimum Turnover of 5 Crores in the last 3 Years.</span>
                </li>
                <li>
                  <img src={orangeTick} alt="" /> <span>Verified GST registration.</span>
                </li>
                <li className='items-start'>
                  <img src={orangeTick} alt="" /> <span>Good credit history & transaction records.</span>
                </li>
              </ul>
            </div>
          </div>

          <div className='SolutionBox complementary400'>
            <b>Mid Corporate & MSMEs</b>
            <div className='textBox'>
              <ul>
                <li className='items-start'>
                  <img src={ComplementaryTick} alt="" /> <span>Established manufacturing setup with verified credentials.</span>
                </li>
                <li className='items-start'>
                  <img src={ComplementaryTick} alt="" /> <span>Minimum Turnover of 3 Crores in Last 3 Years.</span>
                </li>
                <li className='items-start'>
                  <img src={ComplementaryTick} alt="" /> <span>Bulk procurement requirements for custom financing solutions.</span>
                </li>
                <li className='items-start'>
                  <img src={ComplementaryTick} alt="" /> <span>Stable financial health with audited records.</span>
                </li>
              </ul>
            </div>
          </div>

          <div className='SolutionBox primary500'>
            <b>Enterprises</b>
            <div className='textBox'>
              <ul>
                <li className='items-start'>
                  <img src={PrimaryTick} alt="" /> <span>A registered corporate entity with valid documentation.</span>
                </li>
                <li className='items-start'>
                  <img src={PrimaryTick} alt="" /> <span>Minimum Turnover of 25 Crores in the last 3 years.</span>
                </li>
                <li className='items-start'>
                  <img src={PrimaryTick} alt="" /> <span>Consistent financial and trading performance.</span>
                </li>
                <li className='items-start'>
                  <img src={PrimaryTick} alt="" /> <span>Active presence in the steel trading ecosystem.</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className='neutral20Bg lg:py-[90px] py-8 w-full'>
        <div className= 'container mx-auto lg:px-4 px-2'>
          <div className={`headingContainer`}>
            <div className="heading">Basic <span>Documents Required</span></div>
          </div>

          <div className="flex flex-wrap w-full justify-center gap-0">
            <div className=' lg:w-1/3 md:w-2/4 sm:w-2/4 w-full p-2'>
              <div className="whiteGrayBox w-full items-center pl-0 flex-row">
                <div className="iconBox !border-s-0 !rounded-s-none">
                  01
                </div>
                <div className="TextBox text-left">
                  <b className='!pb-0'>Valid business registration (GST and PAN mandatory).</b>
                </div>
              </div>
            </div><div className=' lg:w-1/3 md:w-2/4 sm:w-2/4 w-full  p-2'>
              <div className="whiteGrayBox w-full  items-center pl-0 flex-row">
                <div className="iconBox !border-s-0 !rounded-s-none">
                  02
                </div>
                <div className="TextBox text-left">
                  <b className='!pb-0'>Demonstrated trading or manufacturing activity in steel.</b>
                </div>
              </div>
            </div><div className=' lg:w-1/3 md:w-2/4 sm:w-2/4 w-full p-2 '>
              <div className="whiteGrayBox w-full items-center pl-0 flex-row">
                <div className="iconBox !border-s-0 !rounded-s-none">
                  03
                </div>
                <div className="TextBox text-left">
                  <b className='!pb-0'>Minimum 2-year <span className='xl:block inline'>operational history.</span></b>
                </div>
              </div>
            </div><div className=' lg:w-1/3 md:w-2/4 sm:w-2/4 w-full p-2 '>
              <div className="whiteGrayBox w-full items-center pl-0 flex-row">
                <div className="iconBox !border-s-0 !rounded-s-none">
                  04
                </div>
                <div className="TextBox text-left">
                  <b className='!pb-0'>Stable financial health with supporting documents.</b>
                </div>
              </div>
            </div><div className=' lg:w-1/3 md:w-2/4 sm:w-2/4 w-full p-2 '>
              <div className="whiteGrayBox w-full items-center pl-0 flex-row">
                <div className="iconBox !border-s-0 !rounded-s-none">
                  05
                </div>
                <div className="TextBox text-left">
                  <b className='!pb-0'>Positive credit assessment <span className='xl:block inline'>by NBFCs.</span></b>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <RequestDemoTemplate />
    </>
  );
};

export default SupplyChainFinance;