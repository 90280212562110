import React, { useEffect, useRef } from "react";
import brandLogo1 from '../../../../assets/images/brandLogo1.svg';
import brandLogo2 from '../../../../assets/images/brandLogo2.svg';
import brandLogo3 from '../../../../assets/images/brandLogo3.svg';
import brandLogo4 from '../../../../assets/images/brandLogo4.svg';
import brandLogo5 from '../../../../assets/images/brandLogo5.svg';
import brandLogo6 from '../../../../assets/images/brandLogo6.svg';
import { createUseStyles } from "react-jss";
import Carousel from "react-multi-carousel";
import CarouselRef from 'react-multi-carousel';

const useStyles = createUseStyles((theme: any) => ({
    mainContainer: {
        backgroundColor: "#FAFAFA",
        borderTop: `1px dashed #AEB0B6`,
        borderBottom: `1px dashed #AEB0B6`,
        paddingTop: "40px",
        paddingBottom: "40px",
    },
    container: { 
        rowGap: "32px", 
    }, 
    carouselContainer: {
       
    },
    "@media (max-width: 767px)": {
        container: {
            margin: "0px auto",
            rowGap: "15px",
            width: '100%',
        },
        heading: {
            fontSize: "28px",
            color: theme.palette.text.neutral.neutral900,
            lineHeight: '34px'
        },
        carouselContainer: {
            
        },
    },
}));

const BrandSlider: React.FC = () => {
    const classes = useStyles();
    const carouselRef = useRef<CarouselRef>(null);

    useEffect(() => {
        if (carouselRef.current) {
            carouselRef.current.goToSlide(0, true);
        }
    }, []);

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1280 },
            items: 6,
            partialVisibilityGutter: 15,slidesToSlide: 1
        },
        tablet: {
            breakpoint: { max: 1024, min: 768 },
            items: 3,
            partialVisibilityGutter: 10
        },
        mobile: {
            breakpoint: { max: 767, min: 464 },
            items: 2,
            partialVisibilityGutter: 0
        },
        "sm-mobile": {
            breakpoint: { max: 464, min: 0 },
            items: 2,
            partialVisibilityGutter: 10
        }
    };

    return (
        <div className={`${classes.mainContainer} overflow-x-auto overflow-hidden`}>
             <div className={`container mx-auto lg:px-4 `}>
            <div className={`headingContainer`}>
          <div className="heading">
             <b>Brand/Suppliers</b>
             </div>
            </div> 
                <div className={`${classes.carouselContainer} relative`}>
                    <Carousel
                       responsive={responsive}
                       autoPlay={true}
                       swipeable={true}
                       draggable={true}
                       showDots={false}
                       infinite={true}
                       arrows={false}
                       partialVisible={false}
                        removeArrowOnDeviceType={["sm-mobile"]}
                    >
                        <div className="sliders"><img src={brandLogo1} alt="" /></div>
                        <div className="sliders"><img src={brandLogo2} alt="" /></div>
                        <div className="sliders"><img src={brandLogo3} alt="" /></div>
                        <div className="sliders"><img src={brandLogo4} alt="" /></div>
                        <div className="sliders"><img src={brandLogo5} alt="" /></div>
                        <div className="sliders"><img src={brandLogo6} alt="" /></div>
                    </Carousel>
                </div>
            </div>
        </div>
    );
};

export default BrandSlider;

