import React from 'react'
import { IBusinessProfile } from '../../../services/useBusinessProfileService';
import ProfileOverviewTemplate from '../../template/StoreFront/ProfileOverview.template';

interface StoreFrontAboutUsPageProps{
    businessProfile: IBusinessProfile | null;
}

const StoreFrontAboutUsPage: React.FC<StoreFrontAboutUsPageProps> = ({businessProfile}) => {
    return (
        <div>
            {businessProfile && <ProfileOverviewTemplate businessProfile={businessProfile} />}
        </div>
    )
}

export default StoreFrontAboutUsPage;