import { request } from ".";
import { useAuthenticatedUser } from "../hooks/useAuthenticatedUser";
import { API_METHOD } from "../utils/constant";
import { ICatalogueAttributes } from "./useCatalogueService";

export const BRAND_COMPARISON_URLS = {
    GET_BRAND_COMPARISON: "/brands-compare",
}

interface IBrandComparisonQueryParams {
    page?: number;
    size?: number;
    sort?: string;
}

export interface IBrandComparisonRequestBody {
    standardIds?: number[] | null;
    gradeIds?: number[] | null;
    brandIds?: number[] | null;
    shapes?: string[] | null;
    locationIds?: number[] | null;
    stocksAvailablity?: string[] | null;
    customisations?: string[] | null;
    milltest?: boolean | null;
    deliveryTime?: string[] | null;
    packagingTypes?: string[] | null;
    superCategoryId?: number | null;
    mainCategoryIds?: number[] | null;
    productCategoryIds?: number[] | null;
    priceRange?: any;
    minimumOrderQuantity?: any;
    attributes?: { [key: string]: ICatalogueAttributes;}
    sortBy? : string;
}

interface Attribute {
    [key: string]: [string, string, string];
}

export interface ISeller {
    id: number;
    name: string;
    businessType: string;
    isTrusted: boolean;
    minimumPrice: number;
    maximumPrice: number;
    moq: number;
    stockAvaliable: boolean;
    catalogueOwnerType: string;
    locationId: number;
    locationName: string;
    uom: string;
}

export interface IBrandComparison {
    id: number;
    name: string;
    classType: string;
    catalogueType: string;
    attributes: Attribute;
    catalogueImagePath: string[];
    rank: number;
    seller: ISeller[];
}

export const useBrandComparisonService = () => {
    const { user: authenticatedUser } = useAuthenticatedUser();

    const getBrandComparison = (params: IBrandComparisonQueryParams, data: IBrandComparisonRequestBody) => {
        return request(API_METHOD.POST, BRAND_COMPARISON_URLS.GET_BRAND_COMPARISON, authenticatedUser, data, { params }, null);
    };

    return {
        getBrandComparison,
    };
}