interface CommunicationAndAvailabilityProps {
    contentStyle?: string;
    boxStyle?: string;
    subHeading?: string;
    header?: string;
}
const CommunicationAndAvailabilityTemplate: React.FC<CommunicationAndAvailabilityProps> = ({ contentStyle,boxStyle,subHeading, header }) => {
    return (
        <div className={contentStyle}>
        <div className={boxStyle}>
            <span className={subHeading}>{header}</span>
        </div>
        <h4 className="mt-3 mb-1 text-xl font-semibold">Communication</h4>
                <p>Your interaction with the Platform constitutes your consent to electronic communications via emails, SMS, phone calls, or other methods we deem suitable. You may unsubscribe from promotional communications by informing our customer service team.</p>

                <h4 className="mt-3 mb-1 text-xl font-semibold">Real-time Tracking</h4>
                <p>We provide real-time tracking for orders, including dispatch details, delivery progress, and transporter information, ensuring transparency throughout the supply chain.</p>

                <h4 className="mt-3 mb-1 text-xl font-semibold">Platform Availability</h4>
                <p>Our goal is continuous and error-free Platform availability; however, temporary interruptions for maintenance, updates, or technical issues may occur without prior notice.</p>
           
        {/* <p>5.1 You agree that the Transactions, transaction price, and all commercial terms such as dispatch of Products and / or Services are bipartite contractual obligations between the User and SteelBazaar.
            </p><br />
            <p> 5.2 You may be required to pay additional charges/fees for the Products and/or Services available on the Platform. The Platform will not facilitate Transactions with respect to any other form of currency. If and when the Platform shall commence facilitation of payment directly on it, it shall facilitate payments through one or more of: (a) net banking; (b) NEFT; (c) RTGS; (d) credit / debit card; (e) cheque; and (f) e-wallets or any other facilities as made available by SteelBazaar from time to time. A portion (full or in part) of the price of the Products may be required to be paid by the Buyers as an advance as per options made available on the Platform from time to time, and the balance amount of the Price will be required to be paid prior to the delivery of the Products.
            </p><br />
            <p>  5.3 You hereby specifically authorize SteelBazaar to collect, process, facilitate, and remit payments and / or the transaction price electronically or by cheque, as the case may be, to and from You in respect of Transactions through the payment facility.
            </p><br />
            <p> 5.4 Until the time the payment is facilitated on or through the Platform directly, if at all, Transactions shall be concluded or confirmed telephonically or over email or through any other written form. It shall be followed by issuance of proforma-invoice (if applicable), purchase order and invoice. A portion (full or in part) of the price of the Products may be required to be paid by the Buyers as an advance as per options made available on the Platform from time to time, and the balance amount of the Price will be required to be paid prior to the delivery of the Products.
            </p><br />
            <p> 5.5 Setoff: You hereby specifically agree and acknowledge that in the event of any amount becoming recoverable pursuant to any Services availed or Products purchased by You, from and payable by You to SteelBazaar, SteelBazaar shall be entitled to recover such amount by deducting in part or in whole from any sum payable or thereafter becoming payable to You by SteelBazaar for Services availed or Products purchased by You, in furtherance of a Transaction, including any past or future Transactions, on the Platform or otherwise. In the event the amount so deducted by SteelBazaar is not sufficient to cover the full amount recoverable by SteelBazaar, You shall, on demand by SteelBazaar, make immediate payment of such remaining amount to SteelBazaar.
            </p><br />
            <p>5.6 Bank Instruments and Transfers:
            </p><br />
            <p> All online bank transfers (National Electronic Funds Transfer (NEFT) and real-time gross settlement (RTGS)) from valid bank accounts and cheque payments are processed using the payment gateway provided by the respective issuing bank which supports the payment facility to provide these Services to the Users. All such online bank transfers and cheque payments on the payment facility are also governed by the terms and conditions agreed to between the Users and the respective issuing bank.
            </p><br />
            <p> 5.7 Credit / Debit cards:
            </p><br />
            <p> All valid credit / debit / cash card / and other payment instruments are processed using a credit card payment gateway or appropriate payment system infrastructure, and the same will also be governed by the terms and conditions agreed to between Users and the respective issuing bank and payment instrument issuing company. The information provided by a User will not be utilized or shared with any third party unless required in relation to fraud verifications or by law, regulation, or court order. The User will be solely responsible for the security and confidentiality of their credit / debit card details. We expressly disclaim all liabilities that may arise as a consequence of any unauthorized use of the User’s credit / debit card. You must use Your own credit / debit card for a Transaction.
            </p><br />
            <p> 5.8 E-wallets:
            </p><br />
            <p> Payments through e-wallets are processed through third-party service providers. The same will also be governed by the terms and conditions agreed to between Users and the respective payment instrument issuing company.
            </p><br />
            <p> 5.9 Buyers may also avail of financing facilities from financial partners on the Platform, if any, and as and when made available by the Platform, in which case the payment for the Products will directly be received from such financial partners. In case a financial partner fails to disburse monies when required by the Platform on an order placed by You, You shall be liable to make such payment as and when demanded by SteelBazaar. You undertake to honor all terms of such financing and promptly make payments when due to the financiers depending on the terms and conditions of your agreement with such financiers. In case of any breach of terms by You with respect to the financing availed on the Platform, We reserve the right to suspend your account on the Platform and disable your access to the Platform. Such access may be restored at the sole discretion of SteelBazaar.
            </p><br />
            <p> 5.10 SteelBazaar, at its sole discretion, may from time to time, and pursuant to applicable laws, provide credit support in the form of first-loss default guarantee or such other means as deemed appropriate to facilitate third-party financing. In case SteelBazaar has provided such credit support on the financing availed by You, and You fail to make required payments, SteelBazaar shall have the right to recover such defaulted amount to the extent of the guarantee and also recover legal, out-of-pocket, and incidental costs and expenses from You.
            </p><br />
            <p> 5.11 Where no credit support is provided by SteelBazaar with respect to financing availed by You, SteelBazaar will not be responsible in any manner for the obligations of the Buyers under such financial arrangements between the Buyers and the financial partners.
            </p><br />
            <p>5.12 Subject to the clauses above, SteelBazaar, at their sole discretion at such terms and cost as determined by them, may offer You, subject to You being held eligible to enroll or participate as per the terms and conditions prescribed from time to time, credit facilities under a seller credit program on the Platform. Should You choose to be considered to avail, register, or enroll for such seller credit program, You agree and accept the following conditions:
            </p><br />
            <p> (a) You agree and consent to be bound by the terms and conditions of the seller credit program as may be notified by SteelBazaar and/or the seller, from time to time;
            </p><br />
            <p> (b) You consent to provide Your credit report (and the credit report of Your promoter, partner, proprietor, as applicable) from any and/or all of the recognized credit bureaus, as required by SteelBazaar at their sole discretion;
            </p><br />
            <p> (c) You shall, promptly at the request of SteelBazaar, submit all details and documents required for Your registration and continued participation in the seller credit program, including but not limited to Your KYC documents, GST details, bank statement, and original post-dated cheques and such other documents required for availing the seller credit program;
            </p><br />
            <p> (d) You authorize SteelBazaar to collect, use, share, process and store Your information on a need-to-know basis;
            </p><br />
            <p> (e) You authorize SteelBazaar and its representatives to visit Your business premises for the purpose of a physical audit, and click photographs to be stored internally on a need-to-know basis; and
            </p><br />
            <p> (f) You understand that the terms and conditions regarding applicability for such a seller credit program may be revised from time to time, and You consent to submit all requisite information at regular intervals at the request of SteelBazaar for determination of Your eligibility for the seller credit program.
            </p><br />
            <p>5.13 You shall comply with all applicable laws (including without limitation Foreign Exchange Management Act, 1999 and the rules made and notifications issued thereunder and the Exchange Control Manual as may be issued by Reserve Bank of India from time to time, Customs Act, 1962, Information and Technology Act, 2000 as amended by the Information Technology (Amendment) Act, 2008, Prevention of Money Laundering Act, 2002 and the rules made thereunder, Foreign Contribution Regulation Act, 1976 and the rules made thereunder, Income Tax Act, 1961 and the rules made thereunder, Export Import Policy of the government of India) applicable to them respectively for using the payment facility and Platform.
            </p> */}
        </div>);
}
export default CommunicationAndAvailabilityTemplate;