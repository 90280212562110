import React from "react";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: any) => ({
    container: {
        border: `1px solid ${theme.palette.border.neutral.neutral100}`,
        transition: "box-shadow 0.3s ease-in-out",
        "&:hover": {
            boxShadow: `0px 5px 16px 0px rgba(8, 15, 52, 0.08)`,
        },
    },
    background: {
        background: "#d3d3d3",
    }
}));

interface CatalogueGridCardSkeletonProps {
    isImageVisible?: boolean;
}

const CatalogueGridCardSkeleton: React.FC<CatalogueGridCardSkeletonProps> = ({ isImageVisible = true }) => {

    const classes = useStyles();

    return (
        <div className={`${classes.container} rounded-lg overflow-hidden w-full h-full`}>
            {isImageVisible && <div className={`h-40  ${classes.background}`}></div>}
            <div className="p-4">
                <div className={`h-4 w-1/4 mb-2  animate-pulse ${classes.background}`}></div>
                <div className={`w-1/2 mb-4 h-6  animate-pulse ${classes.background}`}></div>
                <div className={`mb-3 w-full h-3  animate-pulse ${classes.background}`}></div>
                <div className={`mb-3 w-2/3 h-3  animate-pulse ${classes.background}`}></div>
                <div className={`mb-3 w-1/2 h-3  animate-pulse ${classes.background}`}></div>
            </div>
        </div>
    );
};

export default CatalogueGridCardSkeleton;