import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { createUseStyles } from 'react-jss';
import { IconButton, Badge } from "@mui/material";
import profileIcon from '../../../assets/icons/profileIcon.svg';
import mobileProfileIcon from '../../../assets/icons/mobileProfileIcon.svg';
import cartIcon from '../../../assets/icons/cartIcon.svg';
import mobileCartIcon from '../../../assets/icons/mobileCartIcon.svg';
import wishlistIcon from '../../../assets/icons/wishlistIcon.svg';
import wishlistmobile from '../../../assets/icons/wishlistMobile.svg';
import footerLogo from '../../../assets/images/footerLogo.svg';
import NavbarOptions from './NavbarOptions';
import HeaderSearch from './HeaderSearch.template';
import Button from '../../atoms/Button/Button';
import RequestForQuotationModalTemplate from '../Rfq/RequestForQuotationModal.template';
import { CART_LIFE_CYCLE, CUSTOMER_ROUTES } from '../../../utils/constant';
import { CartContext } from '../../../contexts/CartContext';
import { WishlistContext } from '../../../contexts/WishlistContext';
import { useAuthenticatedUser } from '../../../hooks/useAuthenticatedUser';
import { addToQueue } from '../../../utils/helper';
import AuthenticationTemplate from '../Authentication/Authentication.template';
import AuthenticationCustomerTemplate from '../Authentication/AuthenticationCustomer.template';
import { AuthPopupContext } from '../../../contexts/AuthenticationLoginTypeContext';
import categoryMenu from '../../../assets/images/categoryMenu.svg';
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from '@mui/icons-material/Search';
import useMediaQuery from '@mui/material/useMediaQuery';
import MenuDialogTemplate from './MenuDialog.template';
import IconButtonLocal from "../../atoms/Button/IconButton"
import CategoryDialogTemplate from './CategoryDialog.template';

const useStyles = createUseStyles((theme: any) => ({
    searchContainer: {
        borderRadius: '0px 12px 12px 0px',
        background: theme.palette.background.primary.primary500,
    },
    searchContainerMain: {
        border: `1px solid ${theme.palette.border.primary.primary200}`,
        borderRadius: ' 12px',
        overflow: 'hidden',

        "& .MuiInputBase-root": {
            background: 'white',
            height: '48px',
            border: 'none',
            width: '371px',
        }
    },
    badge: {
        backgroundColor: theme.palette.background.primary.primary400,
        color: "white",
    },
    arrow: {
        borderBottom: `10px solid ${theme.palette.background.neutral.neutral100}`,
        borderRight: "10px solid transparent",
        borderLeft: "10px solid transparent",
    },
    toolTipMessage: {
        backgroundColor: theme.palette.background.primary.primary400,
    },
    tabContainer: {
        background: '#fff',
        zIndex: '99',
        width: '100%',
        boxShadow: "0px 4px 24px 0px rgba(29, 54, 121, 0.16)",
        position: 'sticky',
        top: '0',
    },
    tabMobileContainer: {
        display: "none",
    },
    imageLogo: {},
    menu: {},
    iconColor: {},
    button:{
    },
    "@media (max-width: 1023px)": {
        tabMobileContainer: {
            display: "grid",
            zIndex: '999',
            width: '100%',
            boxShadow: "0px 4px 24px 0px rgba(29, 54, 121, 0.16)",
            position: 'sticky',
            top: '0',
        },
        tabContainer: {
            display: "none",
        },
        imageLogo: {
            height: "35px"
        },
        menu: {
            background: theme.palette.background.primary.primary500,
            height: '32px',
            "& span": {
                width: '18px',
            },
        },
        iconColor: { color: "white" },
        button:{
            padding: "0px"
        }
    },
}));

const NavbarTemplate: React.FC = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const cartContextData = useContext(CartContext);
    const wishlistContextData = useContext(WishlistContext);
    const { user, syncAuthDialogActive } = useAuthenticatedUser();
    const location = useLocation();

    const [localIsStorefront, setLocalIsStorefront] = useState(false);
    const { isAuthTemplateActive, toggleAuthTemplate } = React.useContext(AuthPopupContext);
    const [menuDialogOpen, setMenuDialogOpen] = useState<boolean>(false);
    const [categoryDialogOpen, setCategoryDialogOpen] = useState<boolean>(false);

    const isMobile = useMediaQuery("(max-width:768px)");

    const handleMenuDialogClose = () => {
        setMenuDialogOpen(false);
    };

    const handleCategoryDialogClose = () => {
        setCategoryDialogOpen(false);
    };

    const navigateToHomePage = () => {
        navigate("/");
    };

    useEffect(() => {
        if (location.pathname === CUSTOMER_ROUTES.STORE_FRONT) {
            setLocalIsStorefront(true);
        }
        else {
            setLocalIsStorefront(false);
        }
    }, [location.pathname]);

    useEffect(() => {
        if (new URLSearchParams(location.search).get('token')?.length) {
            syncAuthDialogActive(true)
        }
        else
            user && !user.businessId && navigate(CUSTOMER_ROUTES.ONBOARDING)
    }, [])

    useEffect(() => {
        (async () => {
            await cartContextData.syncCart(CART_LIFE_CYCLE.LOGIN);
        })();
    }, [user]);

    useEffect(() => {
        (async () => {
            await wishlistContextData.syncWishlist(CART_LIFE_CYCLE.LOGIN);
        })();
    }, [user]);

    // not in use for now 
    // useEffect(() => {
    //     if (sessionStorage.getItem('loginPopupState') !== 'true') {
    //         const timer = setTimeout(() => {
    //             syncAuthDialogActive();
    //             sessionStorage.setItem('loginPopupState', 'true');
    //         }, 10000);
    //         return () => clearTimeout(timer);
    //     }
    // }, [user]);

    const WishlistTooltip = useMemo(() => {
        if (wishlistContextData.tooltip.visible) {
            return (
                <div className="cursor-pointer grid relative">
                    <IconButton className={`${classes.button}`} onClick={() => { navigate(CUSTOMER_ROUTES.WISHLIST) }} >
                        <Badge badgeContent={wishlistContextData.wishlistItemsCount} classes={{ badge: classes.badge }}>
                            {isMobile ? (
                                <img src={wishlistmobile} alt="wishlistIcon" className="" />
                            ) : (
                                <img src={wishlistIcon} alt="wishlistIcon" className="" />
                            )}
                        </Badge>
                    </IconButton>
                    <div className="absolute top-full z-20 left-1/2 transform -translate-x-1/2">
                        <div className={`h-px w-0.5 m-auto ${classes.arrow}`}></div>
                        <div className={`p-2 rounded w-max text-white ${classes.toolTipMessage}`}>{wishlistContextData.tooltip.message}</div>
                    </div>
                </div>
            );
        } else {
            return (
                <IconButton className={`${classes.button}`} onClick={() => { navigate(CUSTOMER_ROUTES.WISHLIST) }}>
                    <Badge badgeContent={wishlistContextData.wishlistItemsCount} classes={{ badge: classes.badge }}>
                        {isMobile ? (
                            <img src={wishlistmobile} alt="wishlistIcon" className="" />
                        ) : (
                            <img src={wishlistIcon} alt="wishlistIcon" className="" />
                        )}
                    </Badge>
                </IconButton>
            );
        }
    }, [wishlistContextData.tooltip.visible, wishlistContextData.tooltip.message, wishlistContextData.wishlistItemsCount, isMobile]);

    const Tooltip = useMemo(() => {
        if (cartContextData.tooltip.visible) {
            return (
                <div className="cursor-pointer grid relative">
                    <IconButton className={`${classes.button}`} onClick={() => { navigate(CUSTOMER_ROUTES.CART) }}>
                        <Badge badgeContent={cartContextData.cartItemsCount} classes={{ badge: classes.badge }}>
                            {isMobile ? (
                                <img src={mobileCartIcon} alt="cartIcon" className="" />
                            ) : (
                                <img src={cartIcon} alt="cartIcon" className="" />
                            )}
                        </Badge>
                    </IconButton>
                    <div className="absolute top-full z-20 left-1/2 transform -translate-x-1/2">
                        <div className={`h-px w-0.5 m-auto ${classes.arrow}`}></div>
                        <div className={`p-2 rounded w-max text-white ${classes.toolTipMessage}`}>{cartContextData.tooltip.message}</div>
                    </div>
                </div>
            );
        } else {
            return (
                <IconButton className={`${classes.button}`} onClick={() => { navigate(CUSTOMER_ROUTES.CART) }}>
                    <Badge badgeContent={cartContextData.cartItemsCount} classes={{ badge: classes.badge }} >
                        {isMobile ? (
                            <img src={mobileCartIcon} alt="cartIcon" className="" />
                        ) : (
                            <img src={cartIcon} alt="cartIcon" className="" />
                        )}
                    </Badge>
                </IconButton>
            );
        }
    }, [cartContextData.tooltip.visible, cartContextData.tooltip.message, cartContextData.cartItemsCount, isMobile]);

    const handleDashboardActive = () => {
        if (user) {
            sessionStorage.setItem('current-profile-type', JSON.stringify(0));
            addToQueue(JSON.stringify({ "key": "dashboard", "expandedMenu": { "Dashboard": true } }));
            navigate(`${CUSTOMER_ROUTES.DASHBOARD}/buyer-dashboard`)
        }
        else {
            toggleAuthTemplate(!isAuthTemplateActive);
            syncAuthDialogActive();
        }
    }

    const [dialogOpen, setDialogOpen] = useState<boolean>(false);

    const handleRfqForm = () => {
        setDialogOpen(true);
    }

    // useEffect(() => {
    //     const currentPath = window.location.pathname;
    //     if (currentPath.endsWith('/rfq')) {
    //         setDialogOpen(true);
    //     }
    // }, []);

    useEffect(() => {
        setMenuDialogOpen(false);
    }, [location.pathname]);

    useEffect(() => {
        setCategoryDialogOpen(false);
    }, [location.pathname]);

    return (
        <>
            <AuthenticationTemplate />
            <AuthenticationCustomerTemplate />
            
            <div className={`${classes.tabContainer}`}>
            <div className='container mx-auto lg:px-4'>
                <div className='flex items-center relative flex-wrap lg:flex-nowrap justify-between py-4'>
                    <img className='cursor-pointer' src={footerLogo} alt="" onClick={navigateToHomePage} />
                    <div className='xl:max-w-[600px] lg:max-w-[400px] md:max-w-[200px] w-full'>
                        <HeaderSearch />
                    </div>
                    <div className='flex items-center gap-x-3'>
                        <div className='p-1'>
                            {WishlistTooltip}
                        </div>
                        <div className='p-1'>
                            {Tooltip}
                        </div>
                        <div className='p-1'>
                            <IconButton onClick={() => { handleDashboardActive() }} >
                                <img className="cursor-pointer" src={profileIcon} alt="cartIcon" />
                            </IconButton>
                        </div>
                        <Button variant={'primaryContained'} label='Get Quote' iconPosition="10px" onClick={handleRfqForm} />
                    </div>
                </div></div>
                {!localIsStorefront ? <NavbarOptions /> : null}
            </div>
            <RequestForQuotationModalTemplate dialogOpen={dialogOpen} setDialogOpen={setDialogOpen} />
            <div className={`m-auto ${classes.tabMobileContainer}`}>
                {menuDialogOpen && <MenuDialogTemplate handleDialogClose={handleMenuDialogClose} />}
                {categoryDialogOpen && <CategoryDialogTemplate handleDialogClose={handleCategoryDialogClose} />}
                <div className='flex justify-between items-center px-3 py-2.5 w-full bg-white'>
                    <img className={`${classes.imageLogo} cursor-pointer`} src={footerLogo} alt="" onClick={navigateToHomePage} />
                    <IconButtonLocal className='!py-1.5 !px-2 !rounded !text-base' variant={'primaryContained'} label='Get Quotes!' onClick={handleRfqForm} />
                </div>
                <div className={`flex justify-between items-center px-3 py-1 w-full ${classes.menu}`}>
                    <img className='cursor-pointer bg-white lg:h-full my-auto !h-[23px]' src={categoryMenu} alt="" onClick={() => { setCategoryDialogOpen(true) }} />
                    <div className='flex items-center  justify-center gap-x-5'>
                        <div>
                            <SearchIcon className={`${classes.iconColor}`} style={{ fontSize: '22px' }} />
                        </div>
                        <div>
                            {WishlistTooltip}
                        </div>
                        <div>
                            {Tooltip}
                        </div>
                        <div>
                            <IconButton className={`${classes.button}`} onClick={() => { handleDashboardActive() }} >
                                <img className="cursor-pointer w-[18px]" src={mobileProfileIcon} alt="cartIcon" />
                            </IconButton>
                        </div>
                        <div className=''>
                            <MenuIcon style={{ fontSize: '28px' }}className={`${classes.iconColor}`} onClick={() => { setMenuDialogOpen(true) }} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default NavbarTemplate;