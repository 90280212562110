import React, { useEffect, useState } from 'react'
import { IMaterialRequirementFormik, IMaterialAttribute, IMaterialRequirement } from '../../pages/DashBoard/MyMaterialProduct.page';
import { FormikErrors, FormikProps } from 'formik';
import { createUseStyles } from 'react-jss';
import { useCategoryService } from '../../../services/useCategoryService';
import { useAdminMasterService } from '../../../services/useAdminMasterService';
import { HTTP_STATUS, Attribute, ADD_OWN_PRODUCT, DELIVERY_INFORMATION, CATEGORY_TYPE } from '../../../utils/types';
import { useSnackbar } from '../../../hooks/useSnackBar';
import Button from '../../atoms/Button/Button';
import { useConfigurationService } from '../../../services/useConfigurationService';
import { StringSchema } from 'yup';
import { MODE } from '../../../utils/constant';
import { IStandards } from '../Rfq/ProductRequirementForm.template';
import { CustomAccordion, IStatus } from '../../molecules/Accordion/CustomAccordion';
import OwnProductFormTemplate from './OwnProductForm.template';
import { AddVendorOwnProductSectionProps, IAddOwnProduct, IAddOwnProductFormik } from './AddOwnProduct.template';

const useStyles = createUseStyles((theme: any) => ({
    highlightedText: {
        color: theme.palette.text.primary.primary400
    },
    tertiary100Border: {
        border: `1px solid ${theme.palette.border.neutral.neutral100}`
    },
    darkHeading: {
        color: theme.palette.text.primary.primary400
    },
    borderTop:{
        borderTop: `1px solid ${theme.palette.border.neutral.neutral100}`
    },
    activatedProgressBar: {
        backgroundColor: theme.palette.background.primary.primary500
    },
    activeProgressBar: {
        backgroundColor: theme.palette.background.primary.primary400
    },
    UnTouchProgressBar: {
        backgroundColor: theme.palette.border.neutral.neutral100
    },
    barHeading: {
        color: theme.palette.text.primary.primary500
    },
    unTouchedBarHeading:  {       
         color: theme.palette.text.neutral.neutral200,
    },
    previousActiveHeading: {
        color: theme.palette.background.primary.primary400
    },
    mainHeading: {
        color: theme.palette.text.primary.primary900,
    },
    subHeading: {
        color: theme.palette.text.neutral.neutral700
    },
}));

export interface IConfigurationShape {
    name: string,
    label: string,
    prefix: string
}

const OwnProductDetailsTemplate: React.FC<AddVendorOwnProductSectionProps> = ({ warehouses, formik, setCurrentSectionTo, handleCancel, classType}) => {
    const classes = useStyles();
    const adminMasterService = useAdminMasterService();
    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const [attributesOriginal, setAttributesOrigianl] = useState<IMaterialAttribute[]>([]);
    const [activeAccordion, setActiveAccordion] = useState<number>(0);
    const adminService = useAdminMasterService();
    const [standardList, setStandardList] = useState<number[]>([]);

    const loadProductCategory = () => {
        adminMasterService.getAllCategories({ id:null, level: 2, page: 0, size: 1 })
            .then(res => {
                if (res?.status === HTTP_STATUS.OK) {
                    setStandardList(res.data.data.content?.[0].standards)
                    parseAttribute(res?.data?.data?.content?.[0].attributeDetails?.SPECIFICATION ?? []);
                }
            }).catch((error) => {
                showSnackbar('error', "Error Fetching Category");
            })
    }


    const parseAttribute = (attributes: IMaterialAttribute[]) => {
        attributes.forEach((attribute: IMaterialAttribute) => {
            if (typeof attribute.attributeOptions === 'string')
                attribute.attributeOptions = JSON.parse(attribute.attributeOptions);
        })
        const attribute = JSON.parse(JSON.stringify(attributes));
        const attributeOriginal = JSON.parse(JSON.stringify(attributes));

        setAttributesOrigianl(attributeOriginal)
        const materialRequirementData: IAddOwnProduct[] = formik?.values?.noOfRows ? Array.from({ length: parseInt(formik?.values?.noOfRows.toString()) }, (_, i) => (
            {
                id: i,
                brand: null,
                brandId:"",
                shape:"",
                superCategory:null,
                mainCategory:null,
                productCategory:null,
                superCategoryId:null,
                mainCategoryId:null,
                productCategoryId:null,
                standardId: null,
                gradeId: null,
                attributes: [],
                otherSpecificRequirement: "",
                standard: null,
                grade: null,
                isDuplicate: false,
                warehouseWiseProductDetails: warehouses?.map((warehouse: { id: number, name: string }) => ({
                    minimumOrderQuanity: 0,
                    warehouseId: warehouse.id,
                    deliveryTimeFrame: DELIVERY_INFORMATION.IMMEDIATE
                })) || [],
                keywords: []
            }
        )) : [];
        if(formik.values.productDetails.length != formik.values.noOfRows ){
            formik.setFieldValue('productDetails', materialRequirementData);
        }  
    }

    const handleBack = () => {
        setCurrentSectionTo(ADD_OWN_PRODUCT.NO_OF_PRODUCTS);
    }
    const handleNext = async () => {
        const errors = await formik.validateForm();
        const isWarehouseValid = formik.values.productDetails.every((product) =>
            product.warehouseWiseProductDetails.some((warehouse) => 
               warehouse.minimumOrderQuanity !== null && warehouse.minimumOrderQuanity > 0
            )
        );
        if (!isWarehouseValid) {
            showSnackbar('error', "Each product must have at least one warehouse with a minimum order quantity greater than 0.");
            return;
        }
        const setTouchedRecursive = (values: any): any => {
            if (Array.isArray(values)) {
                return values.map(setTouchedRecursive);
            } else if (typeof values === 'object' && values !== null) {
                return Object.keys(values).reduce((acc, key) => {
                    acc[key] = setTouchedRecursive(values[key]);
                    return acc;
                }, {} as Record<string, any>);
            }
            return true; 
        };
    
        formik.setTouched(setTouchedRecursive(formik.values));
    
        if (Object.keys(errors).length === 0) {
            setCurrentSectionTo(ADD_OWN_PRODUCT.PRODUCT_REVIEW);
        }
    };
    
    const handleCurrentActiveAccordion = (index: number) => {
        setActiveAccordion(activeAccordion === index ? -1 : index);
    }

    const handleOnDelete = (id: number) => {
        const filteredMaterialRequirements = formik.values.productDetails.filter((productDetail, i) => productDetail.id != id)
        formik.setFieldValue('productDetails', filteredMaterialRequirements);
        formik.setFieldValue('noOfRows', filteredMaterialRequirements.length);
        formik.setFieldValue(`deletedRowIndex`, [...formik.values.deletedRowIndex as number[], id]);
        if(filteredMaterialRequirements.length ===0){ handleCancel()} 
    }

    useEffect(() => {
        loadProductCategory();
    }, [])

    const getFormStatus = (formik: FormikProps<IAddOwnProductFormik>, index: number): IStatus => {
        if (formik.values?.productDetails?.[index]?.isDuplicate) {
            return { message: "Duplicate combination, Please fill the unique combination", type: "error" }
        } else if (formik?.touched?.productDetails?.[index])
            if (formik.errors?.productDetails?.[index] === undefined)
                return { message: "Filled", type: "success" }
            else
                return { message: "Please fill the data", type: "error" }
        else {
            return { message: "", type: "error" }
        }
    }
    return (
        <div className={`grid gap-y-3`}>
            {SnackBarComponent}
            <div className={`w-full m-auto flex justify-between gap-x-2 text-var(--black, #4D4D4D) text-center text-sm`}>
                    <div className="grid gap-y-3 w-1/2">
                        <div className='flex gap-x-2 mx-auto'>
                            <div className={`${classes.activeProgressBar} w-2 h-2 my-auto rounded-full`}></div>
                            <div className={`${classes.previousActiveHeading} my-auto`}>Select Product Type </div>
                        </div>
                        <div className={`${classes.activatedProgressBar} h-2.5 rounded `}></div>
                    </div>
                    <div className="grid gap-y-3 w-1/2">
                        <div className='flex gap-x-2 mx-auto'>
                            <div className={`${classes.activatedProgressBar} w-2 h-2 my-auto rounded-full`}></div>
                            <div className={`${classes.barHeading} my-auto font-semibold`}>Add Product Details</div>
                        </div>
                        <div className={`${classes.activatedProgressBar} h-2.5 rounded `}></div>
                    </div>
                    <div className="grid gap-y-3 w-1/2">
                        <div className='flex gap-x-2 mx-auto'>
                            <div className={`${classes.UnTouchProgressBar} w-2 h-2 my-auto rounded-full`}></div>
                            <div className={`${classes.unTouchedBarHeading} my-auto`}>Review</div>
                        </div>
                        <div className={`${classes.UnTouchProgressBar} h-2.5 rounded `}></div>
                    </div>
            </div>
            <div className='flex flex-col gap-2'>
                <div className='flex justify-between mt-5'>
                    <div className={`${classes.mainHeading} text-base font-medium`}>Add the Number of Non Standard Product</div>
                    <div className='flex justify-between'>
                        <div className={`text-2xl font-medium ${classes.highlightedText}`}>{''}</div>
                        <div className={`text-case font-normal my-auto ${classes.darkHeading}`}>No of product added: <span>{formik.values.noOfRows}</span></div>
                    </div>
                </div>
                <div className={`${classes.subHeading} text-xs mb-3`}>Please select the number of products you want to add (max 10)</div>
            </div>
            <form onSubmit={formik.handleSubmit} className={`grid gap-y-6`}>
                <div className={`flex flex-col gap-y-8`}>
                    {(formik.values.productDetails as IAddOwnProduct[]).map((materialRequirement, index) => {
                        const isAttributesComplete = materialRequirement.attributes.every(
                            (attribute) =>
                                attribute.attributeOptions !== null &&
                                (Array.isArray(attribute.attributeOptions)
                                    ? attribute.attributeOptions.length > 0
                                    : attribute.attributeOptions !== "")
                        );
                        const isFilled =
                        !!(
                            materialRequirement.productCategoryId &&
                            materialRequirement.gradeId &&
                            materialRequirement.brandId &&
                            materialRequirement.shape &&
                            materialRequirement.keywords.length > 0 &&
                            isAttributesComplete
                        );
                        return (
                            <CustomAccordion
                                key={index}
                                index={materialRequirement.id as number}
                                expanded={activeAccordion}
                                component={
                                    <div className={`${classes.borderTop} flex flex-col gap-6 mx-6 mb-6 pt-3 -mt-6`}>
                                        <OwnProductFormTemplate
                                            formik={formik}
                                            warehouses={warehouses}
                                            setCurrentSectionTo={setCurrentSectionTo}
                                            handleCancel={handleCancel}
                                            index={index}
                                            attributesOriginal={attributesOriginal ?? []}
                                            mode={MODE.ADD}
                                            classType={classType}
                                        />
                                    </div>
                                }
                                heading={`Product Detail ${index + 1}`}
                                onChange={handleCurrentActiveAccordion}
                                onDelete={handleOnDelete}
                                isDeletable
                                isFilled={isFilled}
                                showStatus={true}
                                status={getFormStatus(formik, materialRequirement.id as number)}
                            />
                        );
                    })}
                </div>
            </form>
            <div className='flex justify-between pt-6'>
                <Button
                    variant="tertiaryContained"
                    label="Cancel"
                    onClick={handleCancel}
                />
                <div className='flex gap-6 '>
                    <Button
                        variant="tertiaryContained"
                        label="Back"
                        onClick={handleBack}
                    />
                    {/* <Button
                        variant="secondaryContained"
                        label="Save As Draft"
                        // onClick={handleBack}
                    /> */}
                    <Button
                        variant="primaryContained"
                        label="Review"
                        // disabled={!warehouses?.some((warehouse) => warehouse.id)}
                        onClick={handleNext}
                    />
                </div>
            </div>
        </div>
    )
}

export default OwnProductDetailsTemplate