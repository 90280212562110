import { Button } from '@mui/joy';
import React from 'react';
import { createUseStyles } from 'react-jss';
import contactBanner from '../../../assets/images/contactUsBanner.png';
import TextFieldV2 from '../../atoms/Input/TextFieldV2';
import locationPin from '../../../assets/icons/location pin.svg';
import mobile from '../../../assets/icons/mobile.svg';
import mailIcon from '../../../assets/icons/mailIcon.svg';
import instaIcon from '../../../assets/icons/instaIcon1.svg';
import fbIcon from '../../../assets/icons/fbIcon1.svg';
import linkedInIcon from '../../../assets/icons/linkedInIcon1.svg';
import xIcon from '../../../assets/icons/xIcon1.svg';
import whatsappIcon from '../../../assets/icons/whatsappIcon1.svg';
import youtubeIcon from '../../../assets/icons/youtubeIcon1.svg';
import contactUsBackground from '../../../assets/images/contactUsBackground.png';
import contactUsMobileBanner from "../../../assets/images/SellerBannerMobile.jpg";

const useStyles = createUseStyles((theme: any) => ({
    bannerContainer: {
        backgroundImage: `url(${contactBanner})`,
        backgroundSize: 'cover',
    },
    bannerSellContainer: {
        "& h2": {
            fontSize: '56px',
            lineHeight: '66px',
            color: '#fff',
        },
    },
    heading: {
        fontSize: '56px',
        lineHeight: '66px',
        color: theme.palette.text.warning.warning400,
    },
    subHeading: {
        color: theme.palette.text.neutral.neutral600,
    },
    bannerDescription: {
        color: theme.palette.text.primary.primary50,
        lineHeight: '28px',
    },
    sectionHeading: {
        fontSize: '54px',
    },
    inquiryContainer: {
        border: `1px solid ${theme.palette.border.neutral.neutral200}`,
        width: '33.33%',
        "& h4": {
            lineHeight: '58px',
            "& span": {
            },
        },
    },
    btn: {
        color: "#fff",
        background: theme.palette.background.primary.primary500,
        padding: '10px 20px',
        width: '166px',
        marginTop: '16px',
        fontSize: '16px',
        fontWeight: '700',
        lineHeight: '20px',
        position: 'relative !important',
        left: '171px',
        right: '0px',
    },
    btnArrow: {
        border: '1px solid #fff',
        borderRadius: '100%',
        marginLeft: '10px',
    },
    mapSecondaryContiner: {
        width: '639px',
        height: '204px',
        background: '#FFB7B7',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        "& p": {
            fontSize: '28px',
            lineHeight: '34px',
        },
    },
    primaryinqContainer: {
        backgroundImage: `url(${contactUsBackground})`,
        backgroundSize: 'cover',
        width: '100%',
        marginTop: '28px',
        "& h5": {
            color: '#fff',
        },
    },
    primaryinContainer: {
        background: theme.palette.background.primary.primary500,
        "& h5": {
            color: '#fff',
        },
    },

    textFeildlabel: {
        "& span": {
            color: theme.palette.text.secondary.secondary400,
            fontSize: '18px',
        }
    },
    text: {
        color: '#fff',
    },
    input: {
        height: "200px !important",
    },
    secondaryHeading: {
        "& span": {
            color: theme.palette.text.primary.primary500,

        },
    },
    mapOuter: {
        position: "relative",
        textAlign: "right",
        width: "100%",
        height: "384px",
    },
    gmapCanvas: {
        overflow: "hidden",
        background: "none !important",
        width: "100%",
        height: "384px",
    },
    gmapIframe: {
        width: "100% !important",
        height: "384px !important",
        border: 0,
    },
    containerMain: {},
    "@media screen and (max-width: 1024px)": {
        inquiryContainer: {
            width: '80%',
        },
    },
    "@media screen and (max-width: 768px)": {
        containerMain: {
        },
        text: {
            fontSize: '14px !important',
        },
        inquiryContainer: {
            width: '100%',
        },
        primaryinqContainer: {
            marginTop: '10px',
        },
        primaryinContainer: {
            marginTop: '20px',
        },
    },
    "@media screen and (max-width: 480px)": {
        bannerContainer: {
            backgroundImage: `url(${contactUsMobileBanner})`,
            backgroundSize: "cover",
        },
        containerMain: {
            display: 'flex',
            flexDirection: 'column',
        },
        primaryinqContainer: {
            width: '100%',
            marginTop: '14px',
            "& h5": {
                fontSize: '18px',
            },
        },
        text: {
            fontSize: '14px !important',
        },
        inquiryContainer: {
            width: '100%',
        },
        primaryinContainer: {
            "& h5": {
                fontSize: '18px',
            },
        },
    },
}));


const ContactUsTemplate: React.FC = () => {
    const classes = useStyles();

    return (
        <>
            <div className={`${classes.bannerContainer}`}>
                <div className="container mx-auto px-4">
                    <div className="insideBannerBx">
                        <div className="insideBannerInfo">
                            <h1>
                                <span>Connect, Collaborate,<br />Succeed</span> <br />
                                Reach Out to <br />Steelbazaar Today
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className='ZigZagSection lg:pt-[90px] lg:px-4'>
                <div className={`headingContainer`}>
                    <div className="heading">Let's keep in touch</div>
                    <div className="Subheading px-4">Feel free to reach out to us using the options below, <br />
                        and our dedicated team will respond to your inquiries promptly.</div>
                </div>
                <div className={`${classes.containerMain} container mx-auto lg:px-0 px-3 flex gap-10 items-center w-full`}>
                    <div className={`${classes.inquiryContainer} py-8 px-4 rounded-lg`}>
                        <div className='ZigZagText'>
                            <div className="heading48">Let’s <span>talk!</span>
                            </div>
                        </div>
                        <p>Please use a business email to connect with us.</p>
                        <div className='flex flex-col items-left'>
                            <div className={`${classes.textFeildlabel} font-semibold mt-4 mb-1`}>Name<span>*</span><TextFieldV2 className='w-full' /></div>
                            <div className={`${classes.textFeildlabel} font-semibold mt-4 mb-1`}>Mobile<span>*</span><TextFieldV2 placeholder='+91' /></div>
                            <div className={`${classes.textFeildlabel} font-semibold mt-4 mb-1`}>Email<span>*</span><TextFieldV2 /></div>
                            <div className={`${classes.textFeildlabel} font-semibold mt-4 mb-1`}>Message<span>*</span><TextFieldV2 className={classes.input} /></div>
                            <div className='w-full flex justify-end mt-4'>
                                <Button className="Btn sm primary500Bg w-max">Send a Message</Button>
                            </div>
                        </div>
                    </div>
                    <div className='lg:w-[58.7%] w-full'>
                        <div className='ZigZagText'>
                            <div className="heading48">We are <span>here!</span>
                            </div>
                        </div>
                        <div className={`${classes.primaryinqContainer} p-4 rounded-xl`}>
                            <h5 className='text-2xl font-semibold leading-7 mb-4'>Corporate Office</h5>
                            <div className='flex gap-2 mb-4'>
                                <img src={locationPin} alt="" />
                                <p className={`${classes.text} text-lg font-semibold leading-[22px] `}>C-196, 2nd Floor, Sec-63, Noida, <br />Uttar Pradesh (India)</p>
                            </div>
                            <div className='flex gap-2 mb-4'>
                                <img src={mobile} alt="" />
                                <p className={`${classes.text} text-lg font-semibold leading-[22px] `}>+91 9654 5555 40,</p>
                            </div>
                            <div className='flex gap-2 mb-4'>
                                <img src={mailIcon} alt="" />
                                <p className={`${classes.text} text-lg font-semibold leading-[22px] `}>info@steelbazaar.com</p>
                            </div>
                            <div className='flex gap-5 mt-7'>
                                <a href=""><img src={instaIcon} alt="" /></a>
                                <a href=""><img src={fbIcon} alt="" /></a>
                                <a href=""><img src={linkedInIcon} alt="" /></a>
                                <a href=""><img src={xIcon} alt="" /></a>
                                <a href=""><img src={whatsappIcon} alt="" /></a>
                                <a href=""><img src={youtubeIcon} alt="" /></a>
                            </div>
                        </div>
                        <div className={`${classes.primaryinContainer} p-4 rounded-xl mt-10`}>
                            <h5 className='text-2xl font-semibold leading-7 mb-4'>Regional Offices</h5>
                            <div className='flex gap-2 mb-4'>
                                <img src={locationPin} alt="" />
                                <p className={`${classes.text} text-lg font-semibold leading-[22px] `}>A-88, MIDC Taloja Industrial Area, Dist. Raigad, Maharashtra -410208</p>
                            </div>
                            <div className='flex justify-between lg:flex-row flex-col'>
                                <div className='flex gap-2 mb-4 flex '>
                                    <img src={mobile} alt="" />
                                    <p className={`${classes.text} text-lg font-semibold leading-[22px] `}>+91 9654 5555 10</p>
                                </div>
                                <div className='flex gap-2 mb-4 flex'>
                                    <img src={mailIcon} alt="" />
                                    <p className={`${classes.text} text-lg font-semibold leading-[22px] `}>info@steelbazaar.com</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div className={classes.mapOuter}>
                <div className={classes.gmapCanvas}>
                    <iframe
                        className={classes.gmapIframe}
                        scrolling="no"
                        marginHeight={0}
                        marginWidth={0}
                        src="https://maps.google.com/maps?width=1300&amp;height=384&amp;hl=en&amp;q=svamwork%20co&amp;t=&amp;z=19&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                        title="Google Map - Svamwork"
                    ></iframe>
                </div>
            </div>
        </>
    );
};

export default ContactUsTemplate;
