import React, { useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import partnershipBanner from '.././../../assets/images/partnersipBanner.png';
import roudTickIcon from '../../../assets/icons/roudTickIcon.jpg';
import boostReach from '../../../assets/images/boostReach.png';
import partnersipBanner from '../../../assets/images/partnersipBanner.png';
import collaboration from '../../../assets/images/collaboration.png';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Button } from '@mui/joy';
import DigitalSecondaryBanner from '../../../assets/images/digitalSecondaryBanner.png';
import buyerProtection from '../../../assets/images/buyerProtection.png';
import financialExpertise from '../../../assets/images/financialExpertise.png';
import steelTransportation from '../../../assets/images/steelTransportation.png';
import { useLocation } from 'react-router-dom';
//bykamal
import PratnnershipMobileBanner from '../../../assets/images/PartnershipsMobileBanner.jpg'; 
import BulletImg from "../../../assets/images/bulletIcon.svg"; 
const useStyles = createUseStyles((theme: any) => ({
    container: {
     
    },
    bannerContainer: { 
        backgroundImage: `url(${partnershipBanner})`,
        backgroundSize: 'cover',
    },
    bannerSellContainer: {
    },
    heading: {
        fontSize: '56px',
        lineHeight: '66px',
        color: theme.palette.text.primary.primary50,
        "& span": {
            color: theme.palette.text.warning.warning400,
        },
    },
    bannerDescription: {
        color: theme.palette.text.primary.primary50,
        lineHeight: '28px',
    },
    intoContainer: {
        backgroundColor: theme.palette.background.neutral.neutral50,
    },
    primaryText: {
        fontSize: '40px',
        color: '#000',
        lineHeight: '48px',
    },
    decContainer: {
        width: '750px',
        "& p": {
            color: '#000',
            lineHeight: '26px',
            fontWeight: '400',
        },
    },
    featuresContainer: {
        "& > p": {
            color: theme.palette.text.warning.warning300,
            background: theme.palette.background.primary.primary500,
            padding: '10px 16px',
            width: 'max-content',
        },
        "& span": {
            fontSize: '40px',
            lineHeight: '48px',
            color: theme.palette.text.primary.primary700,
        },
        "& h4": {
            color: theme.palette.text.neutral.neutral800,
            "& span": {
                color: theme.palette.text.primary.primary500,
                fontWeight: '600',
            },
        },
        "& h6": {
            color: theme.palette.text.neutral.neutral900,
            lineHeight: '28px'
        },
    },
    featureInfo: {
        "& h4": {
            color: theme.palette.text.neutral.neutral900,
        },
        "& p": {
            color: theme.palette.text.neutral.neutral800,
            lineHeight: '20px',
        },
    },
    featuresText: {
        width: '651px',
        "& h5": {
            color: '#000',
            fontSize: '22px',
            lineHeight: '26px',
        },
        "& p": {
            color: theme.palette.text.neutral.neutral800,
            lineHeight: '26px',
        },
    },
    headingText: {
        fontSize: '48px !important',
        "& span": {
            color: `${theme.palette.text.primary.primary500} !important`,
        },
    },
    oppSubHeading: {
        color: theme.palette.text.neutral.neutral900,
    },
    sectionHeading: {
        color: '#000',
        fontSize: '54px',
        "& span": {
            color: theme.palette.text.primary.primary500,
        },
    },
    collabCard: {
        border: `1px solid ${theme.palette.border.neutral.neutral200}`,
        background: '#fff',
        width: '420px',
        "& h5": {
            fontSize: '22px',
        },
        "& p": {
            lineHeight: '20px',
        },
    },
    transformCard: {
        border: `1px solid ${theme.palette.border.neutral.neutral200}`,
        background: '#F8F8F8',
        width: '24%',
        "& h5": {
            fontSize: '22px',
        },
        "& p": {
            lineHeight: '20px',
            color: theme.palette.text.neutral.neutral800,
        },
    },
    sectionSubHeading: {
        color: theme.palette.text.neutral.neutral600,
    },
    secondaryBanner: {
        width: '100%',
        height: '480px',
        backgroundImage: `url(${DigitalSecondaryBanner})`,
        backgroundSize: 'cover',
        position: 'relative',
    },
    secondaryContainer: {
        '& h2': {
            fontSize: '40px',
            color: theme.palette.text.warning.warning400,
            lineHeight: '48px',
        },
        "& p": {
            color: theme.palette.text.primary.primary50,
            lineHeight: '28px',
        },
    },
    btn3: {
        background: '#fff',
        color: '#fff',
        fontSize: '18px',
        fontWeight: '500',
        padding: '10px 20px',
        lineHeight: '22px',
    },
    btnArrow2: {
        border: '1px solid transparent',
        background: theme.palette.background.primary.primary500,
        borderRadius: '50%',
        height: '32px',
        width: '32px',
    },
    btnText: {
        color: theme.palette.text.primary.primary500,
        fontSize: '20px',
    },
    secondaryBannerHeading: {
        color: '#fff',
    },
    btn: {
        color: "#fff",
        background: theme.palette.background.primary.primary500,
        padding: '10px 20px',
        width: '166px',
        marginTop: '16px',
        fontSize: '16px',
        fontWeight: '700',
        lineHeight: '20px',
    },
    btnArrow: {
        border: '1px solid #fff',
        borderRadius: '100%',
        marginLeft: '10px',
    },
    "@media screen and (max-width: 480px)": {
    bannerContainer: { 
        backgroundImage: `url(${PratnnershipMobileBanner})`,
        backgroundSize: 'cover',
    },
},
}));

const Partnership: React.FC = () => {

    const classes = useStyles();
    const location = useLocation();
    
    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const component = params.get('component');

        if (component) {
            const element = document.getElementById(component);
            if (element) {
                element.scrollIntoView();
            }
        }
    }, [location.search]);
    return (
        <>
        <div className={`${classes.bannerContainer}`}>
        <div className="container mx-auto px-4">
          <div className="insideBannerBx">
            <div className="insideBannerInfo"> 
              <h1>Transform Your<br /> 
Business with<br /><span>Steelbazaar Partnerships</span></h1>
<p>Partner with India’s Leading Digital Steel Marketplace to <br />Redefine Trade Efficiency and Growth.</p>
            </div>
          </div>
        </div>
      </div>
      <div className="neutral20Bg lg:py-14 py-8 w-full">
        <div className="container lg:px-4 px-2 mx-auto flex lg:columns-2 columns-1 lg:flex-row flex-col lg:gap-10 gap-2 PageInfoBoxGry">
          <div className="lg:w-2/5 w-full flex items-center">
            <h2>Unlock Endless Opportunities in the Steel Ecosystem</h2>
          </div>
          <div className={`lg:w-3/5 w-full  flex items-center`}> 
            <p className='!mb-0'>Steelbazaar, the leading digital marketplace for the steel industry, invites Large Steel Retailers, Stockists, Fintechs, NBFCs, and Logistics Providers to join hands in transforming the steel trade. By leveraging our cutting-edge technology, AI-driven optimization tools, and extensive network of buyers and sellers, partners gain unparalleled access to new markets, streamlined operations, and exponential growth.</p>
          </div>
        </div>
      </div>
      <div className={`${classes.container} container mx-auto pt-90 lg:px-4 px-2 `}>
            <div className={`headingContainer`}>
            <div className="heading">Partnership <span>Opportunities</span></div>
            </div>
            {
          // Section One Start Here
        }
        <div
          className="flex lg:columns-2 columns-1 lg:flex-row flex-col items-center ZigZagSection">
          <div className="2xl:w-4/12 lg:w-5/12 w-full flex content-center order-1 lg:order-2 justify-center">
            <div className="ZigZagImgBox ">
              <img src={boostReach} className="w-full !shadow-none" alt="" />
            </div>
          </div>
          <div className="2xl:w-8/12 lg:w-7/12 w-full ZigZagText  order-2 lg:order-1">
          <div className='blueBgHeading'>For Steel Retailers and Stockists</div>
            <div className="heading48">
            Boost Your Reach,<span className='lg:block inline'>Maximize Your Sales</span>
            </div>
            <div className="Subhead">
              <b>As a Steelbazaar Retailer or Stockist Partner, your business gains:</b>
            </div>

            <div className="PointBox mb-4">
              <ul>
                <li>
                <img src={BulletImg} className="bulletBrd" alt="" />
                  <span>
                    <b>Expanded Customer Base</b>
                    Access a vast network of verified buyers across India.
                  </span>
                </li>
                <li>
                <img src={BulletImg} className="bulletBrd" alt="" />
                <span>
                    <b>Enhanced Visibility</b>
                    Showcase your inventory on Steelbazaar's digital storefronts.
                  </span>
                </li>
                <li>
                <img src={BulletImg} className="bulletBrd" alt="" />
                <span>
                    <b>Real-Time Market Insights</b>
                    Leverage data-driven tools for better pricing strategies.
                  </span>
                </li>
                <li>
                <img src={BulletImg} className="bulletBrd" alt="" />
                <span>
                    <b>Effortless Transactions</b>
                    Simplified catalog management and digital order processing.
                  </span>
                </li>
                <li>
                <img src={BulletImg} className="bulletBrd" alt="" />
                <span>
                    <b>Sustainability Integration</b>
                    Participate in SB Green initiatives to promote eco-friendly practices.
                  </span>
                </li>
              </ul>
            </div>
            <Button className="Btn sm primary500Bg">
                  Contact Us <ArrowForwardIcon />
                </Button>
          </div>
        </div>

        {
          // Section Two Start Here
        }
        <div className="flex lg:columns-2 columns-1 lg:flex-row flex-col items-center ZigZagSection" id='finance-partners'>
          <div className="2xl:w-4/12 lg:w-5/12 w-full flex justify-center">
            <div className="ZigZagImgBox"> 
              <img src={financialExpertise} className="w-full !shadow-none" alt="" />
            </div>
          </div>
          <div className="2xl:w-8/12 lg:w-7/12 w-full ZigZagText ">
          <div className='blueBgHeading'>For Finance Partners (Fintechs, NBFCs)</div>
            <div className="heading48">
            Fuel the Steel Trade with Your <span>Financial Expertise</span>
            </div>
            <div className="Subhead">
              <b>Join Steelbazaar to support buyers and sellers with tailored financing solutions:</b>
            
            </div>

            <div className="PointBox mb-4">
              <ul>
                <li>
                <img src={BulletImg} className="bulletBrd" alt="" />
                  <span>
                    <b>Diverse Lending Opportunities</b>
                    Provide trade credit, purchase order financing, and bill discounting to registered users.
                  </span>
                </li>
                <li>
                <img src={BulletImg} className="bulletBrd" alt="" />
                <span>
                    <b>AI-Based Credit Risk Assessmen</b>
                    Leverage our data-driven AI tools for accurate borrower evaluation.
                  </span>
                </li>
                <li>
                <img src={BulletImg} className="bulletBrd" alt="" />
                <span>
                    <b>Expanded Market Reach</b>
                    Offer auction financing, bulk trade financing, and export-import finance solutions.
                  </span>
                </li>
                <li>
                <img src={BulletImg} className="bulletBrd" alt="" />
                <span>
                    <b>Integrated Platform</b>
                    Seamless integration with Steelbazaar’s systems for easy financing approvals.
                  </span>
                </li>
              </ul>
            </div>
            <Button className="Btn sm primary500Bg">
                  Contact Us <ArrowForwardIcon />
                </Button>
          </div>
        </div>

        {
          // Section Three Start Here
        }
        <div
          className="flex lg:columns-2 columns-1 lg:flex-row flex-col items-center ZigZagSection border-0" id='logistic-partners'>
          <div className="2xl:w-4/12 lg:w-5/12 w-full flex content-center order-1 lg:order-2 justify-center">
            <div className="ZigZagImgBox ">
              <img src={steelTransportation} className="w-full !shadow-none" alt="" />
            </div>
          </div>
          <div className="2xl:w-8/12 lg:w-7/12 w-full ZigZagText  order-2 lg:order-1">
          <div className='blueBgHeading'>For Logistics Partners</div>
            <div className="heading48">
            Drive the Future of <span className='lg:block inline'>Steel Transportation</span>
            </div>
            <div className="Subhead">
              <b>Become Steelbazaar's Logistics Partner to streamline material transportation across India:</b>
            </div>

            <div className="PointBox mb-4">
            <ul>
                <li>
                <img src={BulletImg} className="bulletBrd" alt="" />
                  <span>
                    <b>Broadened Client Network</b>
                    Serve verified buyers and sellers through Steelbazaar.
                  </span>
                </li>
                <li>
                <img src={BulletImg} className="bulletBrd" alt="" />
                <span>
                    <b>Optimized Freight Management</b>
                    Use AI-powered route optimization for cost-effective deliveries.
                  </span>
                </li>
                <li>
                <img src={BulletImg} className="bulletBrd" alt="" />
                <span>
                    <b>Timely and Reliable Services</b>
                    Integrated logistics scheduling ensures efficiency.
                  </span>
                </li>
                <li>
                <img src={BulletImg} className="bulletBrd" alt="" />
                <span>
                    <b>End-to-End Visibility</b>
                    Real-time tracking of consignments builds customer trust.
                  </span>
                </li>
                <li>
                <img src={BulletImg} className="bulletBrd" alt="" />
                <span>
                    <b>Increased Revenue Opportunities</b>
                    Benefit from consistent demand for transportation services.
                  </span>
                </li>
              </ul>
            </div>
            <Button className="Btn sm primary500Bg">
                  Contact Us <ArrowForwardIcon />
                </Button>
          </div>
        </div>

    </div>
    
    <div className="neutral20Bg w-full py-90 "> 
        <div className={`${classes.container} container mx-auto lg:px-4 px-2 `} id='channel-trade-partner'>
            <div className={`headingContainer`}>
                <div className="heading">Benefits of Partnering with Steelbazaar</div>
            </div>
            <div className="flex lg:columns-2 columns-1 lg:flex-row flex-col items-center ZigZagSection !gap-0 lg:py-0" id='finance-partners'>
            <div className="2xl:w-3/12 lg:w-4/12 w-full flex justify-center">
                <div className="ZigZagImgBox bg-opacity-0"> 
                <img src={collaboration} className="w-full !shadow-none !bg-transparent !border-none" alt="" />
                </div>
            </div>
            <div className="2xl:w-9/12 lg:w-8/12 w-full">
            <div className='lg:text-[32px] text-[22px] lg:pt-2 lg:pb-5 pt-2 pb-4'>Benefits of Partnering with Steelbazaar</div>
            <div className="PointBox flex flex-wrap gap-4">
                <div className='whiteGrayBox colWidth-2'>
                    <div className='TextBox'>
                        <b>Technology-Driven Growth</b>
                        Steelbazaar’s digital tools and AI-driven insights enhance operational efficiency.
                    </div>
                </div>
                <div className='whiteGrayBox colWidth-2'>
                    <div className='TextBox'>
                        <b>Access to Verified Users</b>
                        Partner with a trusted ecosystem of registered buyers and sellers.
                    </div>
                </div>
                <div className='whiteGrayBox colWidth-2'>
                    <div className='TextBox'>
                        <b>Data-Driven Decisions</b>
                        Gain actionable insights into market trends, customer behavior, and trade patterns.
                    </div>
                </div>
                <div className='whiteGrayBox colWidth-2'>
                    <div className='TextBox'>
                        <b>Sustainable Business Practices</b>
                        Contribute to eco-friendly initiatives like SB Green and circular economy efforts.
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
    </div>

    <div className={`${classes.container} container mx-auto py-90 lg:px-4 px-2 `} id='channel-trade-partner'>
         <div className={`headingContainer`}>
           <div className="heading">How Technology Brings  <span>Transformation ?</span></div>
           <div className="Subheading">Redefine Business Efficiency Through Collaboration</div>
         </div>
         <div className="w-full flex flex-wrap gap-4">
                <div className='grayWhiteBox colWidth-4'>
                    <div className='TextBox'>
                        <b>AI-Powered Optimization</b>
                        Match supply with demand efficiently and predict market trends.
                    </div>
                </div>
                <div className='grayWhiteBox colWidth-4'>
                    <div className='TextBox'>
                        <b>IoT Integration</b>
                        Enable real-time tracking of consignments and inventory.
                    </div>
                </div>
                <div className='grayWhiteBox colWidth-4'>
                    <div className='TextBox'>
                        <b>Smart Analytics</b>
                        Access valuable data for strategic decision-making.
                    </div>
                </div>
                <div className='grayWhiteBox colWidth-4'>
                    <div className='TextBox'>
                        <b>Digital Tools</b>
                        Simplify processes like invoicing, order tracking, and inventory management.
                    </div>
                </div>
                </div>
    </div>
    <div  className={`CTABox lg:py-24 py-8 w-full textBoxfg`}>
      <div className="container mx-auto CallToAction lg:px-4 px-3 ">
        <div className="2xl:w-5/12 lg:w-5/12 flex flex-col gap-y-2.5 items-start">
       <h2>
       Ready to Transform Your Business? Contact Steelbazaar Today 
       </h2>
       <p className="mb-5">Let’s collaborate to build a more efficient, transparent, and sustainable steel industry. Connect with our team to explore partnership opportunities tailored to your business needs.</p>
       <Button className="Btn whiteBg Txtprimary500">Contact Now <ArrowForwardIcon className="primary500Bg text-white" />
                </Button>
      </div>
      </div>
      </div>
        </>
    );
};
export default Partnership;
