import { useAuthenticatedUser } from '../hooks/useAuthenticatedUser';
import { API_METHOD } from '../utils/constant';
import { request } from './index';

const WAREHOUSE_URLs = {
    GET_ALL_WAREHOUSE: "/warehouses"
};

export interface IWarehouseFilterRequest {
    page: number;
    size: number;
    sort?: string;
    search?: string;
}

export interface IWarehouse {
    id: number;
    vendorId: number;
    addressId: number;
    name: string;
    code: string;
    status: string;
    gmapUrl: string ;
    createdBy: string;
    updatedBy: string;
    createdAt: string;
    updatedAt: string;
  }

export const useWarehouseService = () => {
    const { user: authenticatedUser } = useAuthenticatedUser();
    const v2BaseUrl = process.env.REACT_APP_API_V2_URL

    const getWarehouses = (params: IWarehouseFilterRequest) => {
        return request(API_METHOD.GET, WAREHOUSE_URLs.GET_ALL_WAREHOUSE, authenticatedUser, null, { params }, null, v2BaseUrl);
    };

    return {
        getWarehouses
    };
};
