import React from 'react'
import { createUseStyles } from 'react-jss';
import topSeller from "../../../assets/icons/topSeller.svg"
import sbTrusted from '../../../assets/icons/sbTrusted.svg';
import factory from '../../../assets/icons/factory.svg'
import Button from '../../atoms/Button/Button';
import { formatUOM, makeRoute, toInr } from '../../../utils/helper';
import { IBrandComparison, ISeller } from '../../../services/useBrandComparisonService';
import noImage from "../../../assets/images/noImage.jpg"
import { useNavigate } from 'react-router-dom';
import { CUSTOMER_ROUTES } from '../../../utils/constant';

interface CatalogueGridCardTemplateProps {
    item: IBrandComparison;
    seller: ISeller;
}

const useStyles = createUseStyles((theme: any) => ({
    card: {
        border: `1px solid ${theme.palette.border.neutral.neutral100}`,
        borderBottom: "none"
    },
    borderBottom: {
        borderBottom: `1px solid ${theme.palette.border.neutral.neutral100}`,
    },
    border: {
        border: `1px solid ${theme.palette.border.neutral.neutral100}`,
    },
    catalogueName: {
        color: theme.palette.text.primary.primary900,
        lineHeight: "16.8px",
        letterSpacing: "0.14px"
    },
    specification: {
        color: theme.palette.text.neutral.neutral700,
        lineHeight: "13.8px",
        letterSpacing: "0.12px"
    },
    value: {
        color: "#2849A4",
        lineHeight: "20px"
    },
    seller: {
        color: theme.palette.text.neutral.neutral980,
        lineHeight: "16px"
    },
    companyName: {
        color: "#2849A4",
        lineHeight: "20px"
    },
    trader: {
        color: theme.palette.text.neutral.neutral600,
        lineHeight: "16px"
    },
    imageContainer: {
        height: "200px",
    },
    image: {
        borderTopLeftRadius: "5px",
        borderTopRightRadius: "5px"
    },
    "@media (max-width: 767px)": {

    },
}));

const CatalogueGridCardTemplate: React.FC<CatalogueGridCardTemplateProps> = ({ item, seller }) => {
    const classes = useStyles();
    const navigate = useNavigate();

    const onViewCatalogue = (event: any) => {
        navigate(makeRoute(CUSTOMER_ROUTES.CATALOGUE_DESCRIPTION, { query: { "catalogueId": item.id, "classType": item.classType } }))
    }

    return (
        <div className={`${classes.card} grid gap-x-3 bg-white rounded-xl rounded-b-none`}>
            <div className={`${classes.imageContainer} relative mt-0 my-auto z-0 bg-white flex justify-center p-1`}>
            <img className={` ${classes.image} w-full `}  src={item.catalogueImagePath[0] ?? noImage} alt='ProductImage' />
            </div>
            <div className={`p-3 grid gap-y-3`}>
                <div className='grid gap-y-2'>
                    <div className='flex justify-between'>
                        <div className={`${classes.catalogueName} text-sm font-medium H-[33.59px]`}>{item.name.length > 55 ? item.name.substring(0, 55) + "..." : item.name}</div>
                        <img className='w-[48px] h-[17px]' src={topSeller} alt='' />
                    </div>
                    <div className='flex justify-between'>
                        {Object.entries(item.attributes).splice(0, 1).map(([key, value]: any) => {
                            let [min, max, uom] = value;
                            min = min.replace(/\.0*$/, '');
                            max = max.replace(/\.0*$/, '');
                            const isRangeZero = min === max || max === '';
                            return (
                                <div key={key} className={`${classes.specification} text-xs font-medium`}>{key}: {formatUOM(min, uom, isRangeZero ? min : `${min}-${max}`)}</div>
                            );
                        })}
                    </div>
                </div>

                <div className={classes.borderBottom}></div>

                <div className='grid gap-y-2'>
                    <div className='grid gap-y-1 h-fit'>
                        <div className={`${classes.seller} text-sm`}>Seller</div>
                        <div className={`${classes.companyName} text-base font-medium underline decoration-solid decoration-auto underline-offset-auto h-10`}>{seller?.name}</div>
                    </div>
                    <div className='flex gap-x-6'>
                        {seller?.isTrusted && <img className='w-[40px] h-[27px]' src={sbTrusted} alt="" />}
                        <div className='flex self-end content-end'>
                            <img className='w-[18px] h-[18px]' src={factory} alt="" />
                            <div className={`${classes.trader} text-sm font-medium my-auto`}>{seller?.businessType}</div>
                        </div>
                    </div>
                </div>

                <div className={classes.borderBottom}></div>

                <div className={`grid gap-y-2`}>
                    <div className='grid gap-y-2'>
                    <div className={` text-base font-bold leading-[22px]`}>{seller?.minimumPrice === seller?.maximumPrice  ? `${toInr(seller?.minimumPrice)}` : `${toInr(seller?.minimumPrice)} - ${toInr(seller?.maximumPrice)}`}*/MT</div>
                        <div className={`${classes.trader} text-xs !leading-[14px]`}>Exclu. GST @18%</div>
                    </div>
                    <div className='flex justify-between'>
                        <div className='flex gap-x-1'>
                            <div className={`${classes.specification} text-xs !leading-[14px]`}>Location:</div>
                            <div className={`${classes.value} text-xs !leading-[14px]`}>{seller.locationName.length > 16 ? seller.locationName.substring(0, 16) + "..." : seller.locationName}</div>
                        </div>
                        <div className='flex gap-x-1'>
                            <div className={`${classes.specification} text-xs !leading-[14px]`}>MOQ:</div>
                            <div className={`${classes.value} text-xs !leading-[14px]`}>{seller?.moq} MT</div>
                        </div>
                    </div>
                    <Button fullWidth label={"View Details"} onClick={onViewCatalogue} variant='tertiaryContained' size='small' />
                </div>
            </div>
        </div>
    );
};

export default CatalogueGridCardTemplate;