import { FormikProps, useFormik } from "formik";
import { useSnackbar } from "../../../hooks/useSnackBar";
import { useEffect, useMemo, useState } from "react";
import { ADD_EXISTING_PRODUCT, ADD_OWN_PRODUCT, DELIVERY_INFORMATION, HTTP_STATUS } from "../../../utils/types";
import ExistingProductDetailsTemplate from "./ExistingProductDetails.template";
import ProductReviewTemplate from "./ProductReview.template";
import * as yup from 'yup';
import { IVendorProductRequestBodyDTO, usePostProductService } from "../../../services/usePostProductService";
import { useNavigate } from "react-router-dom";
import { useAuthenticatedUser } from "../../../hooks/useAuthenticatedUser";
import { CUSTOMER_ROUTES, GENERIC_EXCEPTION_CODE, STATUS, VENDOR_PRODUCT_ROUTES } from "../../../utils/constant";
import SelectProductTemplateV2 from "../DashBoard/Inventory Management/SelectProductV2.template";
import TotalOwnProductsTemplate from "./TotalOwnProducts.template";
import OwnProductDetailsTemplate from "./OwnProductDetails.template";
import { IMaterialAttribute } from "../../pages/DashBoard/MyMaterialProduct.page";
import { AutoCompleteOption } from "../../atoms/AutoCompleteInput/AutoCompleteInput";
import OwnProductReviewTemplate from "./OwnProductReview.template";
import { IVendorOwnProductRequestBodyDTO, useAddOwnProductService } from "../../../services/useAddOwnProductService";

interface IAddExistingProductTemplateProps {
    classType: string;
    warehouses: { id: number, name: string }[];
    setCurrentStep: (currentStep: string | null) => void;
    handleCancel: () => void;
}

export interface IWarehouseWiseProductDetails {
    minimumOrderQuanity: number | null;
    warehouseId: number;
    deliveryTimeFrame: string;
}

export interface IAddOwnProduct {
    id: number | null;

    superCategory: AutoCompleteOption | null;
    superCategoryId: number | null;

    mainCategory: AutoCompleteOption | null;
    mainCategoryId: number | null;

    productCategory: AutoCompleteOption | null;
    productCategoryId: number | null;

    standard: AutoCompleteOption | null;
    standardId: number | null;

    grade: AutoCompleteOption | null;
    gradeId: number | null;

    brand: AutoCompleteOption | null;
    brandId: number | string;

    shape: string;
    attributes: IMaterialAttribute[];

    keywords: string[];
    warehouseWiseProductDetails: IWarehouseWiseProductDetails[];
    otherSpecificRequirement: string;

    isDuplicate?: boolean;
}

export interface IAddOwnProductFormik {
    productDetails: IAddOwnProduct[] | [],
    noOfRows?: number | string,
    deletedRowIndex?: number[],
    images: File[]
}

export interface AddVendorOwnProductSectionProps {
    setCurrentSectionTo: (section: ADD_OWN_PRODUCT) => void;
    formik: FormikProps<IAddOwnProductFormik>;
    warehouses: { id: number, name: string }[];
    onBack: () => void;
    handleCancel: () => void;
    classType: string;
}

const validationSchema = yup.object().shape({
    noOfRows: yup.number()
        .min(1, "Products should be greater than 0")
        .max(10, `Add maximum upto 10 products`)
        .required("Required Field")
        .test('is-integer', 'Enter Valid Quantity', value => Number.isInteger(value)),
    productDetails: yup.array().of(
        yup.object().shape({
            superCategoryId: yup.number().required('Super Category is required'),
            mainCategoryId: yup.number().required('Main Category is required'),
            productCategoryId: yup.number().required('Product Category is required'),
            standardId: yup.number().required('Standard is required'),
            gradeId: yup.number().required('Grade is required'),
            brandId: yup.mixed().test('is-valid-brand-id', 'Brand is required and must be a valid number or string', (value) => { return ((typeof value === 'number' && value > 0) || (typeof value === 'string' && value.trim().length > 0)); }).required('Brand is required'),
            shape: yup.string().required('Shape is required'),
            keywords: yup.array().of(yup.string().trim().required("Each keyword must be a non-empty string")).min(1, "At least one keyword is required").max(10, "You can add a maximum of 10 keywords").required("Keywords are required"),
            warehouseWiseProductDetails: yup.array().of(
                yup.object().shape({
                    minimumOrderQuanity: yup.number().min(0, 'Minimum order quantity must be at least 0').required('Minimum order quantity is required'),
                    warehouseId: yup.number().required('Warehouse ID is required'),
                    deliveryTimeFrame: yup.mixed().oneOf(Object.values(DELIVERY_INFORMATION)).required('Delivery time frame is required'),
                })
            ),
            attributes: yup.array().of(yup.object().shape({
                attributeOptions: yup.string().required('Attribute option is required'),
            })).required('Attributes are required').test(
                'all-attribute-options-filled',
                'All attribute options must be filled',
                (attributes) => {
                    return (attributes as { attributeOptions?: string }[]).every(
                        (attr) => !!attr?.attributeOptions?.trim()
                    );
                }
            ),
        })
    ),

})

const AddOwnProductTemplate: React.FC<IAddExistingProductTemplateProps> = ({ classType, warehouses, setCurrentStep, handleCancel }) => {
    const navigate = useNavigate();
    const { showSnackbar } = useSnackbar();
    const { user } = useAuthenticatedUser();
    const addOwnProductService = useAddOwnProductService();

    const [currentSection, setCurrentSection] = useState<ADD_OWN_PRODUCT>(ADD_OWN_PRODUCT.NO_OF_PRODUCTS);

    const initialValues = {
        productDetails: [],
        noOfRows: "",
        deletedRowIndex: [],
        images: [],
        imageListToDelete: [],
    }

    const formik = useFormik<IAddOwnProductFormik>({
        initialValues,
        validationSchema,
        validateOnMount: true,
        enableReinitialize: true,
        onSubmit: (value) => {
            const payload: IVendorOwnProductRequestBodyDTO[] = (formik.values.productDetails as IAddOwnProduct[]).map(
                (item: IAddOwnProduct, index: number) => ({
                    productId: item.id,
                    brand: item.brandId,
                    gradeId: item.gradeId ?? 0,
                    categoryId: item.productCategoryId ?? 0,
                    standardId: item.standardId,
                    productStatus: STATUS.ACTIVE,
                    productUpcStatus: STATUS.ACTIVE,
                    keyword: item.keywords.join(', '),
                    classType: classType,
                    shape: item.shape,
                    attributes: {
                        SPECIFICATION : item.attributes,
                        CLASSIFICATION:[],
                        GENERALIZATION:[],
                    },
                    warehouseWiseProductDetails: item.warehouseWiseProductDetails.map(
                        (warehouse) => ({
                            warehouseId: warehouse.warehouseId,
                            minimumOrderQuantity: warehouse.minimumOrderQuanity ?? 0,
                            deliveryTimeFrame: warehouse.deliveryTimeFrame,
                        })
                    ),
                })
            );
            if (user?.businessId) {
                addOwnProductService.createOwnProduct(user?.businessId, payload)
                    .then((res: any) => {
                        if (res?.status === HTTP_STATUS.OK) {
                            const response = res.data.data
                            const duplicateCount = response.filter((item: any) => item.ERROR === GENERIC_EXCEPTION_CODE.DUPLICATE_ENTRY).length;
                            const nonDuplicateCount = response.length - duplicateCount;
                            if (duplicateCount > 0 && nonDuplicateCount === 0) {
                                showSnackbar('error', "Product Already Exists");
                            }
                            else if (nonDuplicateCount > 0 && duplicateCount === 0) {
                                showSnackbar('success', "Product Created Successfully");
                            }
                            else {
                                showSnackbar('success',`${nonDuplicateCount} products have been successfully saved. ${duplicateCount} product was rejected due to being a duplicate.`);
                            }
                            navigate(VENDOR_PRODUCT_ROUTES.POST_PRODUCT)
                        }
                        else {
                            showSnackbar('error', "Failed to Own product check attribute specifications ");
                        }
                    }).catch((error: any) => {
                        showSnackbar('error', "Failed to Own product ")
                        console.error('ERROR CREATING OWN ADD PRODUCT: ', error)
                    })
            }
        }
    });

    const onBack = () => {
        setCurrentStep(null);
    }

    const addVendorProductSectionProps: AddVendorOwnProductSectionProps = {
        setCurrentSectionTo: setCurrentSection,
        formik: formik,
        warehouses: warehouses,
        onBack: onBack,
        handleCancel: handleCancel,
        classType: classType,
    }

    const AddVendorOwnProductView = useMemo(() => {
        switch (currentSection) {
            case ADD_OWN_PRODUCT.NO_OF_PRODUCTS:
                return <TotalOwnProductsTemplate {...addVendorProductSectionProps} />
            case ADD_OWN_PRODUCT.PRODUCT_DETAILS:
                return <OwnProductDetailsTemplate {...addVendorProductSectionProps} />
            case ADD_OWN_PRODUCT.PRODUCT_REVIEW:
                return <OwnProductReviewTemplate {...addVendorProductSectionProps} />
            default:
                return <TotalOwnProductsTemplate {...addVendorProductSectionProps} />
        }
    }, [currentSection, formik]);

    return (
        <div className='grid gap-6 justify-items-stretch '>
            {AddVendorOwnProductView}
        </div>
    )
}

export default AddOwnProductTemplate;
