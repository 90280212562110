import React, { useEffect, useState } from 'react';
import { useCatalogueService } from '../../../services/useCatalogueService';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useLocation, useNavigate } from "react-router-dom";
import { CUSTOMER_ROUTES } from "../../../utils/constant";
import { createUseStyles } from 'react-jss';
import SearchIcon from "../../../assets/icons/outlinedsearch.svg";
import { CARALOGUE_OWNER_TYPE, CATALOGUE_TYPE_STATES, CLASS_STATES } from '../../../utils/types';
import { useDebounce } from '../../../utils/helper';

interface ICatalogueSearch {
    id: number | string;
    name: string;
}

const useStyles = createUseStyles((theme: any) => ({
    quantityInput: {
        "& .MuiOutlinedInput-root": {
            padding: "5px",
            border: 'none'
        },
        "& .MuiOutlinedInput-notchedOutline": {
            border: 'none'
        },
    },
    searchContainer: {
        display: 'flex',
        alignItems: 'center',
        borderRadius: '45px',
        fontSize: '14px',
        paddingLeft: '10px',
        border: `0.8px solid #1E3679`,
        width: '100%',
        backgroundColor: theme.palette.background.neutral.neutral50,
    },
    tabContainer: {
        display: "flex",
    },
}));

const HeaderSearch: React.FC = () => {

    const classes = useStyles()

    const [suggestions, setSuggestionsTo] = useState<ICatalogueSearch[]>([]);
    const [selectedCatalogue, setSelectedCatalogueTo] = useState<ICatalogueSearch | null>(null);

    const catalogueService = useCatalogueService();

    const navigate = useNavigate();
    const location = useLocation();

    const fetchCatalogues = async (searchText: string) => {
        if (!searchText.trim()) {
            setSuggestionsTo([]);
            return;
        }
        try {
            // *********************************TODO- fix api and remove the interation************************************ */
            const payload = {
                name: searchText
            };
            const cataloguesResponse = await catalogueService.getAllCatalogues(
                {
                    page: 0,
                    size: 20,
                },
                payload
            );
            if (cataloguesResponse?.data?.data?.content?.length) {
                const { content } = cataloguesResponse.data.data;
                setSuggestionsTo(content);
            } else {
                throw new Error("Catalogue fetch failed");
            }
        } catch (error) {
            setSuggestionsTo([]);
            setSelectedCatalogueTo(null);
        }
    };

    const handleInputChange = useDebounce((event: React.SyntheticEvent, value: string) => {
        fetchCatalogues(value);
    }, 1000);

    const handleSuggestionSelect = (event: React.ChangeEvent<{}>, value: any | null) => {
        if (value) {
            const { classType, catalogueType, catalogueOwnerType } = value;
            setSelectedCatalogueTo(value);
            if (classType === CLASS_STATES.STANDARD || classType === CLASS_STATES.NONSTANDARD) {
                if (catalogueType === CATALOGUE_TYPE_STATES.SINGLE_PRODUCT) {
                    navigate(`${CUSTOMER_ROUTES.CATALOGUE_DESCRIPTION}?catalogueId=${value.id}`);
                }
                else if (catalogueType === CATALOGUE_TYPE_STATES.MULTIPLE_PRODUCT) {
                    navigate(`${CUSTOMER_ROUTES.CATALOGUE_MTO_VIEW}?catalogueId=${value.id}`);
                }
            }
            else if (classType === CLASS_STATES.SECONDARY) {
                if (catalogueType === CATALOGUE_TYPE_STATES.MAKE_TO_ORDER) {
                    navigate(`${CUSTOMER_ROUTES.SECONDARY_CATALOGUE_LOT_VIEW}?secondaryCatalogueId=${value.id}`);
                }
                else {
                    navigate(`${CUSTOMER_ROUTES.SECONDARY_CATALOGUE_DESCRIPTION}?secondaryCatalogueId=${value.id}`);
                }
            }
        }
    };

    useEffect(() => {
        if (!location.pathname.startsWith("/catalogue")) {
            setSelectedCatalogueTo(null);
        }
    }, [location.pathname]);


    return (
            <div className={`${classes.tabContainer} w-full`}>
                <Autocomplete
                    options={suggestions}
                    getOptionLabel={(option) => option?.name}
                    onInputChange={handleInputChange}
                    onChange={handleSuggestionSelect}
                    value={selectedCatalogue}
                    renderInput={(params) => (
                        <div className={`${classes.searchContainer} ${classes.quantityInput} !w-full`}>
                            <img src={SearchIcon} alt="searchLogo" className="w-5" />
                            <TextField
                                {...params}
                                placeholder='Search anything in steel...'
                                variant="outlined"
                                InputLabelProps={{
                                    children: 'Search',
                                }}
                            />
                        </div>
                    )}
                /> 
            </div>
    );
};

export default HeaderSearch;