interface SupportAndFeedbackTemplateProps {
    contentStyle?: string;
    boxStyle?: string;
    subHeading?: string;
    header?: string;
}

const SupportAndFeedbackTemplate: React.FC<SupportAndFeedbackTemplateProps> = ({ contentStyle, header, boxStyle, subHeading }) => {
    return (
        <>
            <div className={boxStyle}>
                <span className={subHeading}>{header}</span>
            </div>
            <div className={contentStyle}>

                <h4 className="mt-3 mb-1 text-xl font-semibold">Grievance Redressal</h4>
                <p>If you have a grievance or complaint, you may contact the designated grievance officer using the contact details provided on the Platform grievance or support@steelbazaar.com. After a thorough investigation, complaints will be acknowledged within 48 hours and addressed within 30 days. Any abusive or false grievance may lead to appropriate legal action.</p>

                <h4 className="mt-3 mb-1 text-xl font-semibold">Feedback</h4>
                <p>Feedback regarding Platform services and experiences is welcome through customer service contacts provided on the Platform.</p>

            </div>
        </>
    )
}

export default SupportAndFeedbackTemplate;