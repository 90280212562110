import React, { useCallback, useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import { BrowserRouter, Navigate, Outlet, Route, Routes, useLocation, useNavigate, } from 'react-router-dom';
import { createUseStyles, ThemeProvider } from "react-jss";
import { defaultTheme } from './utils/theme';
import { popLastEntry } from "./utils/helper";
import { CUSTOMER_ROUTES } from './utils/constant';
import CatalogueListPage from './components/pages/CatalogueList/CatalogueList.page';
import HomePage from './components/pages/HomePage/HomePage';
import { AuthenticatedUserProvider } from './contexts/AuthenticatedUserContext';
import { MetaDataProvider } from './contexts/MetaDataContext';
import { SnackbarProvider } from './contexts/SnackBarContext';
import WishlistPage from './components/pages/Wishlist/Wishlist.page';
import { WishlistProvider } from './contexts/WishlistContext';
import { CartProvider } from './contexts/CartContext';
import MultipleUpcCatalogueDetails from './components/pages/CatalogueDetail/MultipleUpcCatalogueDetails.page';
import SecondaryCatalogueDetails from './components/pages/CatalogueDetail/SecondaryCatalogueDetails.page';
import SecondaryLotCatalogueDetails from './components/pages/CatalogueDetail/SecondaryLotCatalougeDetails.page';
import CartDetails from './components/pages/Cart/CartDetails.page';
import ScrollToTop from './components/atoms/ScrollToTop/ScrollToTop';
import OnBoardingPage from './components/pages/OnBoarding/OnBoarding.page';
import NavbarTemplate from './components/template/NavBar/Navbar.template';
import FooterTemplate from './components/template/footer/footer.template';
import DashBoard from './components/pages/DashBoard/DashBoard.page';
import ProfileOverviewPage from './components/pages/DashBoard/ProfileOverview.page';
import CatalogueDetails from './components/pages/CatalogueDetail/CatalogueDetails.page';
import StatutoryDetailPage from './components/pages/DashBoard/StatutoryDetail.page';
import BussinessProfile from './components/template/BussinessProfile/BussinessProfileMain';
import BussinessLocationPage from './components/pages/DashBoard/BussinessLocation.page';
import MyMaterialListingPage from './components/pages/DashBoard/MyMaterialListing.page';
import MyMaterialAddProductPage from './components/pages/DashBoard/MyMaterialProduct.page';
import FinishedProductCreationPage from './components/pages/DashBoard/FinishedProductCreation.page';
import ScrapProductCreationPage from './components/pages/DashBoard/ScrapProductCreation.page';
import StaticPagesRoutes from './components/pages/StaticPages/StaticPagesRoutes';
import { StoreFrontPage } from './components/pages/DashBoard/StoreFront.page';
import InventoryManagementPage from './components/pages/DashBoard/InventoryManagement.page';
import AddNewVendorInventoryTemplate from './components/template/DashBoard/Inventory Management/AddNewVendorInventory.template';
import InventoryManagementViewTemplate from './components/template/DashBoard/Inventory Management/InventoryManagementView.template';
import InventoryManagementEditTemplate from './components/template/DashBoard/Inventory Management/InventoryManagementEdit.template';
import PostProductListPage from './components/pages/PostProduct/PostProductList.page';
import PostProductAddPage from './components/pages/PostProduct/PostProductAdd.page';
import PostProductViewPage from './components/pages/PostProduct/PostProductView.page';
import PostProductUpdatePage from './components/pages/PostProduct/PostProductUpdate.page';
import VendorProductPriceListPage from './components/pages/VendorManagement/VendorProductPriceList.page';
import VendorProductPriceCreatePage from './components/pages/VendorManagement/VendorProductPriceCreate.page';
import PriceManagementViewTemplate from './components/template/VendorManagement/PriceManagementView.template';
import VendorProductPriceUpdatePage from './components/pages/VendorManagement/VendorProductPriceUpdate.page';
import ServiceListPage from './components/pages/DashBoard/ValueAddedService/ServiceList.page';
import KeyPersonDetailsPage from './components/pages/DashBoard/KeyPersonDetails.page';
import ChangePasswordPage from './components/pages/DashBoard/ChangePassword.page';
import Preferences from './components/pages/DashBoard/Preferences.page';
import USCCreatePage from './components/pages/DashBoard/ValueAddedService/USC/USCCreate.page';
import USCViewPage from './components/pages/DashBoard/ValueAddedService/USC/USCView.page';
import USCUpdatePage from './components/pages/DashBoard/ValueAddedService/USC/USCUpdate.page';
import MachineCreatePage from './components/pages/DashBoard/ValueAddedService/Machine/MachineCreate.page';
import MachineViewPage from './components/pages/DashBoard/ValueAddedService/Machine/MachineView.page';
import MachineUpdatePage from './components/pages/DashBoard/ValueAddedService/Machine/MachineUpdate.page';
import VendorCataloguePage from './components/pages/VendorCatalogue/VendorCatalogue.page';
import VendorCatalogueAddPage from './components/pages/VendorCatalogue/VendorCatalogueAdd.page';
import VendorCatalogueEditPage from './components/pages/VendorCatalogue/VendorCatalogueEdit.page';
import VendorCatalogueViewPage from './components/pages/VendorCatalogue/VendorCatalogueView.page';
import LeadListTemplate from './components/template/Lead/LeadList.template';
import QuotationListTemplate from './components/template/Lead/QuotationList.template';
import QuotationViewTemplate from './components/template/Lead/QuotationView.template';
import LeadUpcViewTemplate from './components/template/Lead/LeadUpcView.template';
import SecondaryLeadListTemplate from './components/template/Lead/SecondaryLeadList.template';
import SecondaryQuotationViewTemplate from './components/template/Lead/SecondaryQuotationView.template';
import SecondaryLeadUpcViewTemplate from './components/template/Lead/SecondaryLeadUpcView.template';
import SecondaryLeadLotListTemplate from './components/template/Lead/SecondaryLeadLotList.template';
import SecondaryLotQuotationViewTemplate from './components/template/Lead/SecondaryLotQuotationView.template';
import SecondaryLotLeadView from './components/template/Lead/SecondaryLotLeadView.template';
import OrderListTemplate from './components/template/Order/OrderList.template';
import OrderViewTemplate from './components/template/Order/OrderView.template';
import SecondaryOrderListTemplate from './components/template/Order/SecondaryOrderList.template';
import SecondaryOrderViewTemplate from './components/template/Order/SecondaryOrderView.template';
import SecondaryLotsOrderListTemplate from './components/template/Order/SecondaryLotsOrderList.template';
import SecondaryLotOrderViewTemplate from './components/template/Order/SecondaryLotOrderView.template';
import StoreFrontCatalogDetails from './components/pages/StoreFront/StoreFrontCatalogDetails.page';
import OnBoardingWelcomePage from './components/pages/OnBoarding/OnBoardingWelcome.page';
import CatalogueBundleDetails from './components/pages/CatalogueDetail/CatalogueBundleDetails.page';
import ChatBot from './components/molecules/ChatBot/ChatBot';
import { AuthPopupProvider } from './contexts/AuthenticationLoginTypeContext';
import BuyerDashBoardPage from './components/pages/DashBoard/BuyerDashBoard.page';
import SellerDashBoardPage from './components/pages/DashBoard/SellerDashBoard.page';
import BrandComparisonPage from './components/pages/BrandComparion/BrandComparion.page';

const TRACKING_ID = "G-XR6P8KD3X9";

ReactGA.initialize(TRACKING_ID);

const useStyles = createUseStyles({
    rootContainer: {
        height: "calc(100vh - 0px)",
        overflow: "auto",
    },
});

const App: React.FunctionComponent = () => {
    const classes = useStyles();
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);

    const handlePopState = useCallback(() => popLastEntry(), []);

    useEffect(() => {
        window.addEventListener('popstate', handlePopState);
        return () => {
            window.removeEventListener('popstate', handlePopState);
        };
    }, [handlePopState]);

    return (
        <ThemeProvider theme={defaultTheme}>
            <AuthPopupProvider>
                <AuthenticatedUserProvider>
                    <BrowserRouter>
                        <MetaDataProvider>
                            <SnackbarProvider>
                                <CartProvider>
                                    <WishlistProvider>
                                        <div className="w-full">
                                            <Routes>
                                                <Route path={CUSTOMER_ROUTES.ONBOARDING} element={<OnBoardingPage />} />
                                                <Route
                                                    path="/"
                                                    element={<div>
                                                        <div className={classes.rootContainer} id="main">
                                                            <ScrollToTop>
                                                                <NavbarTemplate />
                                                                <Outlet />
                                                                <ChatBot />
                                                                <FooterTemplate />
                                                            </ScrollToTop>
                                                        </div>
                                                    </div>} >
                                                    <Route path="/*" element={<StaticPagesRoutes />} />
                                                    <Route index element={<HomePage />} />
                                                    <Route path={CUSTOMER_ROUTES.CATALOGUE_LISTING} element={<CatalogueListPage />} />
                                                    <Route path={CUSTOMER_ROUTES.CATALOGUE_DESCRIPTION} element={<CatalogueDetails />} />
                                                    <Route path={CUSTOMER_ROUTES.CATALOGUE_MTO_VIEW} element={<MultipleUpcCatalogueDetails />} />
                                                    <Route path={CUSTOMER_ROUTES.SECONDARY_CATALOGUE_DESCRIPTION} element={<SecondaryCatalogueDetails />} />
                                                    <Route path={CUSTOMER_ROUTES.SECONDARY_CATALOGUE_LOT_VIEW} element={<SecondaryLotCatalogueDetails />} />
                                                    <Route path={CUSTOMER_ROUTES.CATALOGUE_BUNDLE} element={<CatalogueBundleDetails />} />
                                                    <Route path={CUSTOMER_ROUTES.CART} element={<CartDetails />} />
                                                    <Route path={CUSTOMER_ROUTES.WISHLIST} element={<WishlistPage />} />
                                                    <Route path={CUSTOMER_ROUTES.BRAND_COMPARISON} element={<BrandComparisonPage />} />
                                                    <Route path={`${CUSTOMER_ROUTES.STORE_FRONT}`} element={<StoreFrontPage />} />
                                                    <Route path={`${CUSTOMER_ROUTES.STORE_FRONT}/:id`} element={<StoreFrontPage />} />
                                                    <Route path={`${CUSTOMER_ROUTES.STORE_FRONT_CATALOGUE_VIEW}`} element={<StoreFrontCatalogDetails />} />
                                                    <Route path={CUSTOMER_ROUTES.ONBOARDING_WELCOME} element={<OnBoardingWelcomePage />} />
                                                    <Route path={`${CUSTOMER_ROUTES.DASHBOARD}`} element={<DashBoard />} >
                                                    <Route path={`${CUSTOMER_ROUTES.DASHBOARD}/${CUSTOMER_ROUTES.BUYER_DASHBOARD}`} element={<BuyerDashBoardPage />} />
                                                    <Route path={`${CUSTOMER_ROUTES.DASHBOARD}/${CUSTOMER_ROUTES.SELLER_DASHBOARD}`} element={<SellerDashBoardPage />} />
                                                        <Route path={`${CUSTOMER_ROUTES.DASHBOARD}/${CUSTOMER_ROUTES.MY_PROFILE}`} element={<ProfileOverviewPage />} />
                                                        <Route path={`${CUSTOMER_ROUTES.DASHBOARD}/${CUSTOMER_ROUTES.BUSINESS_PROFILE}`} element={<BussinessProfile />} />
                                                        <Route path={`${CUSTOMER_ROUTES.DASHBOARD}/${CUSTOMER_ROUTES.BUSINESS_LOCATION}`} element={<BussinessLocationPage />} />
                                                        <Route path={`${CUSTOMER_ROUTES.DASHBOARD}/${CUSTOMER_ROUTES.KEY_PERSON_DETAILS}`} element={<KeyPersonDetailsPage />} />
                                                        <Route path={`${CUSTOMER_ROUTES.DASHBOARD}/${CUSTOMER_ROUTES.STATUTORY_DOCUMENTS}`} element={<StatutoryDetailPage />} />
                                                        <Route path={`${CUSTOMER_ROUTES.DASHBOARD}/${CUSTOMER_ROUTES.PREFRENCES}`} element={<Preferences />} />
                                                        <Route path={`${CUSTOMER_ROUTES.DASHBOARD}/${CUSTOMER_ROUTES.CHANGE_PASSWORD}`} element={<ChangePasswordPage />} />
                                                        <Route path="my-material" element={<MyMaterialListingPage />} />
                                                        <Route path="my-material/add" element={<MyMaterialAddProductPage />} />
                                                        <Route path="finished-product" element={<Outlet />}>
                                                            <Route path="add" element={<FinishedProductCreationPage />} />
                                                            <Route path="edit" element={<FinishedProductCreationPage />} />
                                                        </Route>
                                                        <Route path="scrap-product" element={<Outlet />}>
                                                            <Route path="add" element={<ScrapProductCreationPage />} />
                                                            <Route path="edit" element={<ScrapProductCreationPage />} />
                                                        </Route>
                                                        <Route path="inventory-management" element={<Outlet />}>
                                                            <Route index element={<InventoryManagementPage />} />
                                                            <Route path="add" element={<AddNewVendorInventoryTemplate />} ></Route>
                                                            <Route path=":productId" element={<InventoryManagementViewTemplate />} ></Route>
                                                            <Route path="edit/:productId" element={<InventoryManagementEditTemplate />} ></Route>
                                                        </Route>
                                                        <Route path="vendor/product" element={<Outlet />}>
                                                            <Route index element={<PostProductListPage />} />
                                                            <Route path="add" element={<PostProductAddPage />} ></Route>
                                                            <Route path=":vendorId/view/:vendorProductId" element={<PostProductViewPage />} />
                                                            <Route path="edit/:vendorProductId" element={<PostProductUpdatePage />} />
                                                        </Route>
                                                        <Route path="vendor/price-management" element={<Outlet />}>
                                                            <Route index element={<VendorProductPriceListPage />} />
                                                            <Route path="add" element={<VendorProductPriceCreatePage />}></Route>
                                                            <Route path="list" element={<VendorProductPriceListPage />} />
                                                            <Route path="view/:id" element={<PriceManagementViewTemplate />} ></Route>
                                                            <Route path="edit/:id" element={<VendorProductPriceUpdatePage />} ></Route>
                                                        </Route>
                                                        <Route path="value-added-services" element={<Outlet />}>
                                                            <Route index element={<ServiceListPage />} />
                                                            <Route path="USCs/add" element={<USCCreatePage />} ></Route>
                                                            <Route path="USCs/:id" element={<USCViewPage />} ></Route>
                                                            <Route path="USCs/:id/update" element={<USCUpdatePage />} ></Route>
                                                            <Route path="machines/add" element={<MachineCreatePage />} ></Route>
                                                            <Route path="machines/:id" element={<MachineViewPage />} ></Route>
                                                            <Route path="machines/:id/update" element={<MachineUpdatePage />} ></Route>
                                                        </Route>

                                                        <Route path="catalogue" element={<Outlet />}>
                                                            <Route index element={<VendorCataloguePage />} />
                                                            <Route path="add" element={<VendorCatalogueAddPage />} ></Route>
                                                            <Route path="edit/:catalogueId" element={<VendorCatalogueEditPage />} ></Route>
                                                            <Route path=":catalogueId" element={<VendorCatalogueViewPage />} ></Route>
                                                        </Route>

                                                        {/* <Route path="lead" element={<Outlet />}>
                                                        <Route path="/*" element={<StaticPagesRoutes />} /> */}
                                                        <Route path="lead" element={<Outlet />}>
                                                            <Route index element={<LeadListTemplate />} />
                                                            <Route path=":leadId/quotation" element={<Outlet />} >
                                                                <Route index element={<QuotationListTemplate />} />
                                                                <Route path=":quotationId" element={<QuotationViewTemplate />} />
                                                            </Route>
                                                            <Route path=":leadUpcId" element={<LeadUpcViewTemplate />} ></Route>
                                                        </Route>
                                                        <Route path="lead-secondary" element={<Outlet />}>
                                                            <Route index element={<SecondaryLeadListTemplate />} />
                                                            <Route path=":leadId/quotation" element={<Outlet />} >
                                                                <Route index element={<QuotationListTemplate />} />
                                                                <Route path=":quotationId" element={<SecondaryQuotationViewTemplate />} />
                                                            </Route>
                                                            <Route path=":leadUpcId" element={<SecondaryLeadUpcViewTemplate />} ></Route>
                                                        </Route>
                                                        <Route path="lead-secondary-lot" element={<Outlet />}>
                                                            <Route index element={<SecondaryLeadLotListTemplate />} />
                                                            <Route path=":leadId/quotation" element={<Outlet />} >
                                                                <Route index element={<QuotationListTemplate />} />
                                                                <Route path=":quotationId" element={<SecondaryLotQuotationViewTemplate />} />
                                                            </Route>
                                                            <Route path=":leadUpcId" element={<SecondaryLotLeadView />} >
                                                            </Route>
                                                        </Route>
                                                        <Route path="order" element={<Outlet />}>
                                                            <Route index element={<OrderListTemplate />} />
                                                            <Route path=":orderId" element={<OrderViewTemplate />} ></Route>
                                                        </Route>
                                                        <Route path="order-secondary" element={<Outlet />}>
                                                            <Route index element={<SecondaryOrderListTemplate />} />
                                                            <Route path=":orderId" element={<SecondaryOrderViewTemplate />} ></Route>
                                                        </Route>
                                                        <Route path="order-secondary-lot" element={<Outlet />}>
                                                            <Route index element={<SecondaryLotsOrderListTemplate />} />
                                                            <Route path=":orderId" element={<SecondaryLotOrderViewTemplate />} ></Route>
                                                        </Route>
                                                    </Route>
                                                    <Route path="*" element={<Navigate replace={true} to="/" />} />
                                                </Route>
                                                <Route path="*" element={<Navigate replace={true} to="/" />} />
                                            </Routes>
                                        </div>
                                    </WishlistProvider>
                                </CartProvider>
                            </SnackbarProvider>
                        </MetaDataProvider>
                    </BrowserRouter>
                </AuthenticatedUserProvider>
            </AuthPopupProvider>
        </ThemeProvider>
    );
}

export default App;