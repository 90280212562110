import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from 'react-router-dom';
import brandLogo1 from '../../../assets/images/brandLogo1.svg';
import brandLogo2 from '../../../assets/images/brandLogo2.svg';
import brandLogo3 from '../../../assets/images/brandLogo3.svg';
import brandLogo4 from '../../../assets/images/brandLogo4.svg';
import brandLogo5 from '../../../assets/images/brandLogo5.svg';
import brandLogo6 from '../../../assets/images/brandLogo6.svg';
import Carousel from "react-multi-carousel";
import CarouselRef from 'react-multi-carousel';
import { createUseStyles } from 'react-jss';
import Button from '../../atoms/Button/Button';
import { makeRoute } from "../../../utils/helper";
import { CUSTOMER_ROUTES } from "../../../utils/constant";

const useStyles = createUseStyles((theme: any) => ({
    brandContainer: {
        border: `1px solid ${theme.palette.border.neutral.neutral100}`,
    },
    text: {
        color: theme.palette.text.primary.primary950
    },
    subText: {
        color: theme.palette.text.neutral.neutral600
    },
    container: {
        margin: "0px auto",
        rowGap: "30px",
    },
    heading: {
        color: "#71717A",
    },
    carouselContainer: {
        "& .react-multiple-carousel__arrow": {
            display: "none",
        },
        "& .react-multi-carousel-list": {
            width: "1300px",
        },
        "& .react-multi-carousel-list ul": {
            display: "flex",
            alignItems: "center",
            columnGap: "70px"
        },
        "& .react-multi-carousel-list li": {
            width: "fit-content !important",
        },
    },
}));

const CompareBrandsTemplate: React.FC = () => {
    const classes = useStyles();
    const carouselRef = useRef<CarouselRef>(null);
    const [comingSoon, setComingSoonTo] = useState<boolean>(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (carouselRef.current) {
            carouselRef.current.goToSlide(0, true);
        }
    }, []);

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1280 },
            items: 6,
            partialVisibilityGutter: 15
        },
        tablet: {
            breakpoint: { max: 1280, min: 980 },
            items: 3,
            partialVisibilityGutter: 10
        },
        mobile: {
            breakpoint: { max: 480, min: 320 },
            items: 2,
            partialVisibilityGutter: 0
        },
        "sm-mobile": {
            breakpoint: { max: 464, min: 0 },
            items: 2,
            partialVisibilityGutter: 10
        }
    };

    const handleButtonClick = () => {
        navigate(makeRoute(CUSTOMER_ROUTES.BRAND_COMPARISON, { query: { superCategory: JSON.stringify({label:"Carbon Flat Rolled Products",value:1}) } }))

    };

    return (
        <div className={`rounded-2xl p-7 flex w-full ${classes.brandContainer}`}>
            <div className='flex flex-col gap-6 w-1/5'>
                <div className='flex flex-col gap-2'> 
                    <span className={` font-semibold text-2xl ${classes.text}`}>Compare Brands</span>
                    <span className={`text-base ${classes.subText}`}>& get the Best Price</span>
                </div>
                <Button 
                    size='small' 
                    className="w-32" 
                    variant="primaryContained" 
                    label={"Click Here"} 
                    onClick={handleButtonClick} 
                />
            </div>
            <div className={`grid w-4/5 ${classes.container}`}>
                <div className={`text-center text-2xl ${classes.heading}`}>Reputed Brands Used By SteelBazaar</div>
                <div className={`${classes.carouselContainer} overflow-hidden`}>
                    <Carousel
                        className=""
                        responsive={responsive}
                        showDots={false}
                        infinite={true}
                        partialVisible={true}
                        ref={carouselRef}
                        autoPlay={true}
                        autoPlaySpeed={2000}
                        removeArrowOnDeviceType={["sm-mobile"]}
                    >
                        <div className=""><img src={brandLogo1} alt="" /></div>
                        <div className="w-max"><img src={brandLogo1} alt="" /></div>
                        <div className="w-max"><img src={brandLogo2} alt="" /></div>
                        <div className="w-max"><img src={brandLogo3} alt="" /></div>
                        <div className="w-max"><img src={brandLogo4} alt="" /></div>
                        <div className="w-max"><img src={brandLogo5} alt="" /></div>
                        <div className="w-max"><img src={brandLogo6} alt="" /></div>
                        <div className="w-max"><img src={brandLogo1} alt="" /></div>
                        <div className="w-max"><img src={brandLogo2} alt="" /></div>
                        <div className="w-max"><img src={brandLogo3} alt="" /></div>
                    </Carousel>
                </div>
            </div>
        </div>
    );
};

export default CompareBrandsTemplate;