import React, { useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss';
import { useNavigate } from 'react-router-dom';
import Search from "@mui/icons-material/Search";
import { InputAdornment, TextField } from "@mui/material";
import { ICataloguesListingFilter } from './ProductCataloguesTableView.template';
import { IPriceManagementProps } from '../../pages/VendorManagement/VendorProductPriceList.page';
import { CATEGORY_TYPE, HTTP_STATUS, IPagination, STATUS } from '../../../utils/types';
import { CUSTOMER_ROUTES, PRODUCT_SECTION,VendorProductSections, initialPaginationValues, statusOptions } from '../../../utils/constant';
import { useSnackbar } from '../../../hooks/useSnackBar';
import { useAuthenticatedUser } from '../../../hooks/useAuthenticatedUser';
import { useAdminMasterService } from '../../../services/useAdminMasterService';
import Button from '../../atoms/Button/Button';
import { capitalizeFirstLetter, enumToString, makeRoute } from '../../../utils/helper';
import ResourceStatusV2 from '../../atoms/ResourceStatusV2/ResourceStatusV2';
import TextFieldV2 from '../../atoms/Input/TextFieldV2';
import { IVendorInventories, useVendorInventoryService } from '../../../services/useVendorInventoryService';
import { IProductSearchParams, useGenericSearchService } from '../../../services/useGenericSearchService';
import { ISkuCodes } from '../DashBoard/Inventory Management/InventoryMangement.template';
import { usePostProductService } from '../../../services/usePostProductService';
import { ICategory } from '../Rfq/ProductRequirementForm.template';
import { IMultiSelectOption } from '../../atoms/FilterChip/FilterChip';
import { ColumnType } from '../../organisms/table';
import FilterChipV2 from '../../atoms/FilterChipV2/FilterChipV2';
import TableV2 from '../../organisms/TableV2';

export interface IVendorProductDetails {
  id: number;
  productId?: number;
  productName: string;
  skuCode: string;
  status: string;
  thickness: number;
  width: number;
  temper: number;
  length: number;
  locations: number;
}

const useStyles = createUseStyles((theme: any) => ({
  heading: {
    color: theme.palette.border.primary.primary900,
  },
  searchIcon: {
    color: theme.palette.background.neutral.neutral100
  },
  textField: {
    padding: "14px 12px"
  },
}));

const initialVendorProductPriceListingFiltersValues: ICataloguesListingFilter = {
  status: "",
  search: "",
  category: "",
  classType: "STANDARD",
  productIdString: ""
}

const VendorProductPriceListTemplate: React.FC<IPriceManagementProps> = ({ activeTab, stats }) => {

  const classes = useStyles();
  const navigate = useNavigate();
  const { user } = useAuthenticatedUser();
  const { showSnackbar, SnackBarComponent } = useSnackbar();

  const productService = usePostProductService();
  const adminService = useAdminMasterService();
  const vendorInventoryService = useVendorInventoryService();
  const genericSearchService = useGenericSearchService();

  const [pagination, setPagination] = useState<IPagination>(initialPaginationValues);
  const [vendorProductPrices, setVendorProductPricesTo] = useState<IVendorProductDetails[]>([]);
  const [vendorProductPriceFilters, setVendorProductPriceFiltersTo] = useState<ICataloguesListingFilter>(initialVendorProductPriceListingFiltersValues);
  const [productCategoryData, setProductCategoryData] = useState<ICategory[] | null>([]);
  const [skuCodesData, setSkuCodesData] = useState<ISkuCodes[] | null>([]);
  const [skuSearchTerm, setSkuSearchTerm] = useState<string>('');
  const [category, setCategory] = useState<IMultiSelectOption[]>([]);
  const [skuCode, setSkuCode] = useState<IMultiSelectOption[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [status, setStatus] = useState<IMultiSelectOption[]>([]);

  const loadProductCategory = () => {
    adminService.getAllCategories({ level: CATEGORY_TYPE.PRODUCT_CATEGORY, page: 0, size: 10, sort: 'created_at,desc', parentId: '', name: searchTerm ?? '', status: 'ACTIVE' })
      .then(res => {
        if (res?.status === HTTP_STATUS.OK)
          setProductCategoryData(res?.data?.data?.content)
      }).catch((error) => {
        console.error("Error Fetching Category: ", error);
      })
  }

  const loadSkuCodes = () => {
    let params: IProductSearchParams = {
      page: 0,
      size: 10,
      sort: 'createdAt,desc',
      sectionType: PRODUCT_SECTION.BRAND_UPC,
      skuCode: skuSearchTerm
    }
    genericSearchService.searchProduct(params, {})
      .then(res => {
        if (res?.status === HTTP_STATUS.OK) {
          setSkuCodesData(res?.data?.data?.content);
        }
      })
      .catch((error) => {
        console.error("Error Fetching SKU codes: ", error);
      });
  };

  const transformedProductCategoryData: IMultiSelectOption[] = productCategoryData
    ? productCategoryData.map(category => ({
      label: category.name,
      value: category.id
    }))
    : [];


    const transformedSkuCodeData: IMultiSelectOption[] = skuCodesData
    ? skuCodesData.map(skuCode => ({
        label: skuCode.productCode,
        value: skuCode.productId
    }))
    : [];

  const getVedorProductPrices = async () => {
    if (user?.businessId) {
      try {
        const params: IVendorInventories = {
          page: pagination.page,
          size: pagination.size,
          status: vendorProductPriceFilters.status,
          category: vendorProductPriceFilters.category,
          search: vendorProductPriceFilters.search,
          productIdString: vendorProductPriceFilters.productIdString,
          classType: activeTab ?? initialVendorProductPriceListingFiltersValues.classType,
          sort: 'createdAt,desc',
          section: VendorProductSections.VENDOR_PRODUCT_PRICING
        };

        const vedorInventoriesResponse = await vendorInventoryService.getAllProducts(user?.businessId, params);
        if (vedorInventoriesResponse.status === HTTP_STATUS.OK) {
          const vendorInventoryData = vedorInventoriesResponse?.data?.data?.content;
          setVendorProductPricesTo(vendorInventoryData);
          setPagination((prevPagination: IPagination) => ({
            ...prevPagination,
            totalRecords: vedorInventoriesResponse?.data?.data?.totalElements,
          }));
        }
      } catch (error) {
        showSnackbar('error', 'Vendor Product prices fetch failed');
      }
    }
  };

  const handleCategorySaveClick = (category: IMultiSelectOption[]) => {
    const valueString = category.map(item => item.value).join(',');
    handleFiltersChange("category", valueString);
  };

  const handleSelectionChangeCategory = (selected: IMultiSelectOption[], clearWithCrossIcon: boolean | undefined) => {
    setCategory(selected);
    clearWithCrossIcon && handleCategorySaveClick(selected);
  };

  const handleSelectionChangeCode = (selected: IMultiSelectOption[], clearWithCrossIcon: boolean | undefined) => {
    setSkuCode(selected);
    clearWithCrossIcon && handleCodeSaveClick(selected);
  };

  const handleCodeSaveClick = (skuCodes: IMultiSelectOption[]) => {
    const valueString = skuCodes.map(item => item.value).join(',');
    handleFiltersChange("productIdString", valueString);
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPagination((prevPagination: IPagination) => ({
      ...prevPagination,
      page: newPage
    }));
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPagination((prevPagination: IPagination) => ({
      ...prevPagination,
      page: 0,
      size: parseInt(event.target.value, 10)
    }));
  };
  const handleViewAction = (id: number) => {
    navigate(`${CUSTOMER_ROUTES.VENDOR_PRODUCT_PRICE_VIEW}/${id}`);
  }

  const handleUpdateAction = (id: number) => {
    navigate(`${CUSTOMER_ROUTES.VENDOR_PRODUCT_PRICE_UPDATE}/${id}`);
  }

  const handleSelectionChangeStatus = (selected: IMultiSelectOption[], clearWithCrossIcon: boolean | undefined) => {
    setStatus(selected);
    clearWithCrossIcon && handleStatusSaveClick(selected);
  };

  const handleFiltersChange = (name: string, value: any) => {
    setPagination({ ...pagination, page: 0 });
    setVendorProductPriceFiltersTo({ ...vendorProductPriceFilters, [name]: value ?? "" });
  };

  const handleStatusSaveClick = (status: IMultiSelectOption[]) => {
    const valueString = status.length > 0 ? status[0].value : '';
    handleFiltersChange("status", valueString);
  };

  const handleClearClick = (filterType: string) => {
    switch (filterType) {
      case 'status':
        setStatus([]);
        handleFiltersChange("status", '');
        break;
      case 'category':
        setCategory([]);
        handleFiltersChange("category", '');
        break;
      case 'productIdString':
        setSkuCode([]);
        handleFiltersChange("productIdString", '');
        break;
    }
  };

  const Action = (id: number, status: string) => (
    <div className='flex justify-center' title=''>
      {status !== STATUS.INACTIVE && (
        <Button
          variant="tertiaryText"
          label={"Update"}
          size='small'
          onClick={() => handleUpdateAction(id)}
        />
      )}      
      <Button
        variant="tertiaryText"
        label={"View"}
        size='small'
        onClick={() => handleViewAction(id)}
      />
    </div>
  )

  const getTableColumns = () => [
    { label: "S.No.", key: "id", type: "number" as ColumnType, props: { className: '' } },
    { label: "SKU Code", key: "skuCode", type: "string" as ColumnType, props: { className: '' } },
    { label: "Product Name", key: "upcTitle", type: "string" as ColumnType, props: { className: 'flex text-left ml-3.5' } },
    { label: "Thickness", key: "brandUpcCount", type: "number" as ColumnType, props: { className: '' } },
    { label: "Width", key: "availableStock", type: "number" as ColumnType, props: { className: '' } },
    { label: "Temper", key: "customers", type: "number" as ColumnType, props: { className: '' } },
    { label: "Length", key: "vendors", type: "number" as ColumnType, props: { className: '' } },
    { label: "Locations", key: "locations", type: "number" as ColumnType, props: { className: '' } },
    { label: "Status", key: "status", component: ({ value }: { value: string }) => <ResourceStatusV2 status={value} />, type: "custom" as ColumnType, props: {} },
    { label: "Action", key: "action", type: "custom" as ColumnType, props: { className: '' } }
  ]

  const getRecords = () => vendorProductPrices.map((vendorInventory: IVendorProductDetails, index) => [
    pagination.page * pagination.size + index + 1,
    vendorInventory.skuCode,
    vendorInventory.productName,
    vendorInventory.thickness ?? "-",
    vendorInventory.width ?? "-",
    vendorInventory.temper ?? "-",
    vendorInventory.length ?? "-",
    vendorInventory.locations ?? "-",
    enumToString(vendorInventory.status),
    Action(vendorInventory.id, vendorInventory.status)
  ]);

  const getSchema = () => ({
    id: "1",
    pagination: {
      total: pagination.totalRecords,
      currentPage: pagination.page,
      isVisible: true,
      limit: pagination.size,
      handleChangePage: handleChangePage,
      handleChangeRowsPerPage: handleChangeRowsPerPage
    },
    columns: getTableColumns() ?? []
  })

  useEffect(() => {
    getVedorProductPrices();
  }, [activeTab, pagination.size, pagination.page, vendorProductPriceFilters]);

  useEffect(() => {
    loadProductCategory();
  }, [searchTerm]);

  useEffect(() => {
    loadSkuCodes();
  }, [skuSearchTerm]);

  return (
    <div>
      <div className={`${classes.heading} text-lg pb-4 pt-6 font-medium w-full`}>{capitalizeFirstLetter(activeTab)} ({stats})</div>
      <div className='flex justify-between items-center gap-2 mb-4'>
        <FilterChipV2
          options={statusOptions}
          label=" "
          value={status}
          onchange={handleSelectionChangeStatus}
          placeholder="Status"
          ClearButtonComponent={Button}
          buttonLabel={"Clear all"}
          buttonOnClick={() => handleClearClick('status')}
          SaveButton={Button}
          saveButtonLabel='Apply'
          saveButtonOnClick={() => handleStatusSaveClick(status)}
          isSingleSelect={true}
          minWidth='180px'
        />

        <FilterChipV2
          options={transformedSkuCodeData}
          label=" "
          value={skuCode}
          onchange={handleSelectionChangeCode}
          placeholder="SKU Code"
          ClearButtonComponent={Button}
          buttonLabel={"Clear all"}
          buttonOnClick={() => handleClearClick('productIdString')}
          SaveButton={Button}
          saveButtonLabel='Apply'
          saveButtonOnClick={() => handleCodeSaveClick(skuCode)}
          textFieldPlaceholder='Search...'
          searchTerm={skuSearchTerm}
          setSearchTerm={setSkuSearchTerm}
          minWidth='200px'
        />

        <FilterChipV2
          options={transformedProductCategoryData}
          label=" "
          value={category}
          onchange={handleSelectionChangeCategory}
          placeholder="Product Category"
          ClearButtonComponent={Button}
          buttonLabel={"Clear all"}
          buttonOnClick={() => handleClearClick('category')}
          SaveButton={Button}
          saveButtonLabel='Apply'
          saveButtonOnClick={() => handleCategorySaveClick(category)}
          textFieldPlaceholder='Search...'
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          minWidth='200px'
        />

        <div className='flex justify-end ml-auto'>
          <TextFieldV2
            label=''
            variant="outlined"
            placeholder="Search..."
            name='search'
            className={classes.textField}
            onChange={(event: any) => {
              handleFiltersChange("search", event.target.value)
            }}
            InputProps={{
              className: "px-2 h-11 text-base ",
              startAdornment: (
                <InputAdornment position="start" style={{ marginLeft: '16px' }}>
                  <Search className={classes.searchIcon} />
                </InputAdornment>
              )
            }}
          />
        </div>
      </div>
      <div>
        <TableV2 schema={getSchema()} records={getRecords()} />
      </div>
    </div>
  )
}

export default VendorProductPriceListTemplate