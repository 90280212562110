import React, { useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import aboutUsMobileBanner from '../../../assets/images/AboutBannerMobile.jpg';
import mission from '../../../assets/images/mission.svg';
import vision from '../../../assets/images/vision.svg';
import AipoweredTemplate from '../../template/Home/AiPowered/Aipowered.template';
import RequestDemoTemplate from '../../template/Home/RequestDemo/RequestDemo.template';
import locationv1 from '../../../assets/icons/locationIcon.svg';
import solution from '../../../assets/icons/solutionIcon.svg';
import market from '../../../assets/icons/marketIcon.svg';
import chain from '../../../assets/icons/chainIcon.svg';
import design from '../../../assets/icons/designIcon.svg';
import doc from '../../../assets/icons/docIcon.svg';
import puzzle from '../../../assets/icons/puzzleIcon.svg';
import recycle from '../../../assets/icons/recycleIcon.svg';
import card1 from '../../../assets/images/card1.svg';
import pricing from '../../../assets/images/pricing.svg';
import SBDigiStore from '../../../assets/images/SBDigiStore.svg';
import Lead from '../../../assets/images/Lead.svg';
import bid from '../../../assets/images/bid.svg';
import management from '../../../assets/images/management.svg';
import sbpool from '../../../assets/images/sbpool.svg';
import instafin from '../../../assets/images/instafin.svg';
import insights from '../../../assets/images/insights.jpg';
import AboutHeaderImg from '../../../assets/images/AboutHeaderImg.jpg';
import aboutUsDescription from '../../../assets/images/aboutUsDescription.svg';

import { useLocation } from 'react-router-dom';

const useStyles = createUseStyles((theme: any) => ({
    bannerContainer: {
        backgroundImage: `url(${AboutHeaderImg})`,
        backgroundSize: 'cover',
    },

    aboutContainer: {

        "& h2": {
            fontSize: '54px',
            fontWeight: '590', lineHeight: '66px',
            color: theme.palette.text.neutral.neutral900, textAlign: 'left', marginBottom: '16px',
        },
        "& p": {
            fontSize: '18px', margin: '0px',
            fontWeight: '400px', lineHeight: '26px', textAlign: 'left', marginBottom: '16px',
        },
    },

    primaryText: {
        color: theme.palette.text.neutral.neutral900,
        fontSize: '40px',
        lineHeight: '15px',
    },

    visionSecondaryContainer: {
        "& h3": {
            fontSize: '40px', fontWeight: '590',
            color: theme.palette.text.neutral.neutral900,
        },
        "& p": {

        },
    },
    supplyContainer: {
        background: '#F1F6FC',
    },
    cardsHeading: {
        color: theme.palette.text.neutral.neutral900,
        fontSize: '54px',
        lineHeight: '66px',
    },
    supplyCard: {
        boxShadow: '',
        background: 'white',
        "& p": {
            fontSize: '22px',
            color: theme.palette.text.primary.primary900,
            padding: '0px 12px',
        },
        "& img": {
            width: '84px',
        }
    },
    cardContainer: {
        width: '100%',
        display: 'flex', justifyContent: 'center',
        flexFlow: 'wrap', gap: '16px',
    },
    cardHeadingSecondary: {
        color: '#000',
        fontSize: '28px',
        lineHeight: '34px',
    },
    featureCardsHeading: {
        color: '#333',
        fontSize: '54px',
        lineHeight: '66px',
    },
    featurecardDescription: {
        color: theme.palette.text.neutral.neutral800,
        lineHeight: '26px',
    },
    featureCard: {
        borderRadius: '20px',
        overflow: 'hidden',
    },

    card01: {
        background: theme.palette.text.orange.orange200,
        width: '39%',
        "& img": {
            height: '298px',
        },
    },
    card02: {
        background: theme.palette.text.warning.warning100,
        width: '27%',
        "& img": {
            height: '298px',
        },

    },
    card03: {
        background: theme.palette.text.complementary.complementary100,
        width: '31%',
        "& img": {
            height: '258px', marginBottom: '40px',
        },
    },
    card04: {
        background: theme.palette.text.success.success100,
        width: '27%',
        "& img": {
            height: '258px',
        },
    },
    card05: {
        background: theme.palette.text.primary.primary100,
        width: '35%',
        "& img": {
            height: '442px',
        },
    },
    card06: {
        background: theme.palette.text.warning.warning500,
        width: '35%',
        "& img": {
            height: '270px',
        },
    },
    card07: {
        background: theme.palette.text.secondary.secondary100,
        width: '39%',
        "& img": {
            height: '342px',
        },
    },
    card08: {
        background: theme.palette.text.orange.orange100,
        width: '27%',
        "& img": {
            height: '240px',
            marginBottom: '40px',
        },
    },
    card09: {
        background: theme.palette.text.warning.warning100,
        width: '31%',
        "& img": {
            height: '365px',

        },
    },

    secondaryText: {
        lineHeight: '26px',
        color: '#000',
    },
    '@media screen and (max-width: 1279px)': {
        aboutContainer: {
            "& h2": {
                fontSize: '44px',
                fontWeight: '590', lineHeight: '56px',
                color: theme.palette.text.neutral.neutral900, textAlign: 'left', marginBottom: '8px',
            },
            "& p": {
                fontSize: '16px', margin: '0px',
                fontWeight: '400px', lineHeight: '20px', textAlign: 'left', marginBottom: '8px',
            },
        },
        cardHeadingSecondary: {
            fontSize: '22px',
            lineHeight: '22px',
        },
        featurecardDescription: {
            fontSize: '16px',
            width: '100%',
            lineHeight: '16px',
            marginBottom: '0px',
        },
        card01: {
            width: '48%', marginBottom: '16px',

        },
        card02: {
            width: '48%', marginBottom: '16px',

        },
        card03: {
            width: '48%', marginBottom: '16px',

        },
        card04: {
            width: '48%', marginBottom: '16px',

        },
        card05: {
            width: '48%', marginBottom: '16px',

        },
        card06: {
            width: '48%', marginBottom: '16px',

        },
        card07: {
            width: '48%', marginBottom: '16px',

        },
        card08: {
            width: '48%', marginBottom: '16px',

        },
        card09: {
            width: '48%', marginBottom: '16px',

        },
    },
    '@media screen and (max-width: 1024px)': {
        aboutContainer: {
            "& h2": {
                marginTop: '16px',
                fontSize: '34px',
                fontWeight: '590', lineHeight: '46px',
                color: theme.palette.text.neutral.neutral900, textAlign: 'left', marginBottom: '8px',
            },
            "& p": {
                fontSize: '16px', margin: '0px',
                fontWeight: '400px', lineHeight: '20px', textAlign: 'left', marginBottom: '8px',
            },
        },
        cardHeadingSecondary: {
            fontSize: '18px',
            lineHeight: '22px',
        },
        featurecardDescription: {
            fontSize: '14px',
            width: '100%',
            lineHeight: '16px',
            marginBottom: '0px',
        },
        card01: {
            width: '48%', marginBottom: '16px', "& img": {
                height: '140px',
            },
        },
        card02: {
            width: '48%', marginBottom: '16px', "& img": {
                height: '140px',
            },
        },
        card03: {
            width: '48%', marginBottom: '16px', "& img": {
                height: '140px', marginBottom: '40px',
            },
        },
        card04: {
            width: '48%', marginBottom: '16px', "& img": {
                height: '140px',
            },
        },
        card05: {
            width: '48%', marginBottom: '16px', "& img": {
                height: '180px',
            },
        },
        card06: {
            width: '48%', marginBottom: '16px', "& img": {
                height: '180px',
            },
        },
        card07: {
            width: '48%', marginBottom: '16px', "& img": {
                height: '140px',
            },
        },
        card08: {
            width: '48%', marginBottom: '16px', "& img": {
                height: '140px',
                marginBottom: '40px',
            },
        },
        card09: {
            width: '48%', marginBottom: '16px', "& img": {
                height: '180px',

            },
        },


    },

    '@media screen and (max-width: 480px)': {
        bannerContainer: {
            backgroundImage: `url(${aboutUsMobileBanner})`,
            backgroundSize: 'cover',
        },

        aboutContainer: {
            "& h2": {
                marginTop: '20px',
                fontSize: '28px',
                fontWeight: '590', lineHeight: '34px',
                color: theme.palette.text.neutral.neutral900, textAlign: 'left', marginBottom: '12px',
            },
            "& p": {
                fontSize: '14px', margin: '0px',
                fontWeight: '400px', lineHeight: '16px', textAlign: 'left', marginBottom: '12px',
            },
        },
        card01: {
            width: '100%', marginBottom: '16px',
        },
        card02: {
            width: '100%', marginBottom: '16px',
        },
        card03: {
            width: '100%', marginBottom: '16px',
        },
        card04: {
            width: '100%', marginBottom: '16px',
        },
        card05: {
            width: '100%', marginBottom: '16px',
        },
        card06: {
            width: '100%', marginBottom: '16px',
        },
        card07: {
            width: '100%', marginBottom: '16px',
        },
        card08: {
            width: '100%', marginBottom: '16px',
        },
        card09: {
            width: '100%', marginBottom: '16px',
        },
        visionSecondaryContainer: {
            "& h3": {
                fontSize: '24px',
                fontWeight: '590', lineHeight: '22px',
                color: theme.palette.text.neutral.neutral900, textAlign: 'left',
            },
            "& p": {
                fontSize: '14px', margin: '0px',
                fontWeight: '400px', lineHeight: '16px', textAlign: 'left',
            },
        },
    }

}));

const AboutUsTemplate: React.FC = () => {

    const classes = useStyles();
    const location = useLocation();
    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const component = params.get('component');

        if (component) {
            const element = document.getElementById(component);
            if (element) {
                element.scrollIntoView();
            }
        }
    }, [location.search]);

    return (
        <>

            <div className={`${classes.bannerContainer}`}>
                <div className='container mx-auto px-4'>
                    <div className='insideBannerBx'>
                        <div className='insideBannerInfo'>
                            <h1>Driving <br /> Innovation & <br /> <span>Optimization</span> in <br /> Steel Supply Chains</h1>
                        </div></div>
                </div>
            </div>

            <div className={`${classes.aboutContainer} container mx-auto px-4 grid lg:py-[90px] lg:mt-0 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-1 md:gap-10  mt-8`}>
                <div className='w-max'><img src={aboutUsDescription} alt="" className='w-full' /></div>
                <div>
                    <h2>About Steel Bazaar</h2>
                    <p>SteelBazaar transforms the steel industry by optimising supply chains through global sourcing, digital procurement, and efficient manufacturing driven by sustainability. We streamline steel procurement with transparency and efficiency, leveraging a trusted global network to offer high-quality products at competitive prices. </p>
                    <p>Our digital platform enables real-time buyer-supplier communication, simplifies transactions, and provides essential market insights. Partnering with top manufacturers, we maintain high-quality standards while promoting responsible sourcing and waste reduction.</p>
                    <p>SteelBazaar also emphasises scrap aggregation, recycling, and a circular manufacturing model, offering real-time market prices to support informed decisions and drive sustainable, efficient industry transformation through advanced technologies and strategies.</p>

                </div>
            </div>

            <div className={`${classes.supplyContainer} lg:mt-0 mt-8`}>
                <div className='container mx-auto lg:px-4 lg:py-[90px]'>
                    <div className={`headingContainer`}>
                        <div className='heading'>How Can SteelBazaar's Innovations Optimize Steel Supply Chains?</div>
                    </div>
                    <div className={`${classes.cardContainer}`}>
                        <div className={`whiteSmallCard`}>
                            <img src={locationv1} alt="" />
                            <b>Global Sourcing Capabilities</b>
                        </div>

                        <div className={`whiteSmallCard`}>
                            <img src={solution} alt="" />
                            <b>End-to-End Digital Procurement Solutions</b>
                        </div>

                        <div className={`whiteSmallCard`}>
                            <img src={market} alt="" />
                            <b>Real-Time Market Insights</b>
                        </div>

                        <div className={`whiteSmallCard`}>
                            <img src={chain} alt="" />
                            <b>Flexible Supply Chain Financing</b>
                        </div>

                        <div className={`whiteSmallCard`}>
                            <img src={design} alt="" />
                            <b>Customized Lean Manufacturing Solutions</b>
                        </div>

                        <div className={`whiteSmallCard`}>
                            <img src={doc} alt="" />
                            <b>Digital Stream Lined Work Flows</b>
                        </div>

                        <div className={`whiteSmallCard`}>
                            <img src={puzzle} alt="" />
                            <b>Integration of Advanced Technologies</b>
                        </div>

                        <div className={`whiteSmallCard`}>
                            <img src={recycle} alt="" />
                            <b>Sustainability <br /> Initiatives</b>
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <AipoweredTemplate />
            </div>

            <div className='container mx-auto lg:px-4 my-8 lg:my-0 lg:pb-[90px] px-4 w-full'>
                <div className={`headingContainer`}>
                    <div className='heading'>Pioneering Future-Ready Steel Features</div>
                </div>

                <div className='flex gap-4 gap-y-10 flex-wrap justify-center'>
                    <div className={`${classes.card01} ${classes.featureCard} flex flex-col justify-between h-[240px] lg:h-[450px] overflow-hidden`}>
                        <div className='p-3 lg:p-8 md:p-4 sm:p-4'>
                            <h4 className={`${classes.cardHeadingSecondary}`}>Comprehensive Product Listings</h4>
                            <p className={`${classes.featurecardDescription} text-base`}>Guaranteed* Save 2-10% , Unlock Big Savings with Our Smart & Automated Procurement Solutions</p>
                        </div>
                        <img src={card1} alt="" className='mx-auto my-0' />
                    </div>


                    <div className={`${classes.card02} ${classes.featureCard} flex flex-col justify-between h-[240px] lg:h-[450px] overflow-hidden`}>
                        <div className='p-3 lg:p-8 md:p-4 sm:p-4'>
                            <h4 className={`${classes.cardHeadingSecondary}`}>Digital Catalogue with Real Time Pricing</h4>
                            <p className={`${classes.featurecardDescription} text-base`}>Access Verified Lead to Drive Sales and Business Growth</p>
                        </div>
                        <img src={pricing} alt="" className='mx-auto my-0' />
                    </div>

                    <div className={`${classes.card03} ${classes.featureCard} flex flex-col justify-between h-[240px] lg:h-[450px] overflow-hidden`}>
                        <div className='p-3 lg:p-8 md:p-4 sm:p-4'>
                            <h4 className={`${classes.cardHeadingSecondary}`}>SB DigiStore</h4>
                            <p className={`${classes.featurecardDescription} text-base`}>Real-Time Live auctions for buying raw materials</p>
                        </div>
                        <img src={SBDigiStore} alt="" className='mx-auto my-0' />
                    </div>

                    <div className={`${classes.card04} ${classes.featureCard} flex flex-col justify-between h-[240px] lg:h-[450px] overflow-hidden`}>
                        <div className='p-3 lg:p-8 md:p-4 sm:p-4'>
                            <h4 className={`${classes.cardHeadingSecondary}`}>SB Connect Leads Management</h4>
                            <p className={`${classes.featurecardDescription} text-base`}>Access Verified Lead to Drive Sales and Business Growth</p>
                        </div>
                        <img src={Lead} alt="" className='mx-auto my-0' />
                    </div>


                    <div className={`${classes.card05} ${classes.featureCard} flex flex-col justify-between h-[240px] lg:h-[450px] overflow-hidden`}>
                        <div className='p-3 lg:p-8 md:p-4 sm:p-4'>
                            <h4 className={`${classes.cardHeadingSecondary}`}>SB BidHub</h4>
                            <p className={`${classes.featurecardDescription} text-base`}>Access customized financial services, including invoice financing and trade credit.</p>
                        </div>
                        <img src={bid} alt="" className='my-0' />
                    </div>


                    <div className={`${classes.card06} ${classes.featureCard} flex flex-col justify-between h-[240px] lg:h-[450px] overflow-hidden`}>
                        <div className='p-3 lg:p-8 md:p-4 sm:p-4'>
                            <h4 className={`${classes.cardHeadingSecondary}`}>SB Customize Automated RFQ Management </h4>
                            <p className={`${classes.featurecardDescription} text-base`}>Bringing out Hidden Gaps In Procurement and converting them into Real Profits</p>
                        </div>
                        <img src={management} alt="" className='my-0' />
                    </div>


                    <div className={`${classes.card07} ${classes.featureCard} flex flex-col justify-between h-[240px] lg:h-[450px] overflow-hidden`}>
                        <div className='p-3 lg:p-8 md:p-4 sm:p-4'>
                            <h4 className={`${classes.cardHeadingSecondary}`}>AI-Driven Market Analytics and Insights</h4>
                            <p className={`${classes.featurecardDescription} text-base`}>Guaranteed* Save 2-10% , Unlock Big Savings with Our Smart & Automated Procurement Solutions</p>
                        </div>
                        <img src={insights} alt="" className='mx-auto my-0' />
                    </div>

                    <div className={`${classes.card08} ${classes.featureCard} flex flex-col justify-between h-[240px] lg:h-[450px] overflow-hidden`}>
                        <div className='p-3 lg:p-8 md:p-4 sm:p-4'>
                            <h4 className={`${classes.cardHeadingSecondary}`}>SB InstaFin</h4>
                            <p className={`${classes.featurecardDescription} text-base`}>Access Verified Lead to Drive Sales and Business Growth</p>
                        </div>
                        <img src={instafin} alt="" className='mx-auto my-0' />
                    </div>

                    <div className={`${classes.card09} ${classes.featureCard} flex flex-col justify-between h-[240px] lg:h-[450px] overflow-hidden`}>
                        <div className='p-3 lg:p-8 md:p-4 sm:p-4'>
                            <h4 className={`${classes.cardHeadingSecondary}`}>SB Pool&Save</h4>
                            <p className={`${classes.featurecardDescription} text-base`}>Guaranteed* Save 2-10% , Unlock Big Savings with Our Smart & Automated Procurement Solutions</p>
                        </div>
                        <img src={sbpool} alt="" className='mx-auto my-0' />
                    </div>
                </div>
            </div>

            <div id='vision' className='lg:py-[90px] py-8 bg-[#F5FAFF]'>
                <div className='container mx-auto lg:px-4  px-3'>
                    <div className={`headingContainer lg:pb-[64px]`}>
                        <div className='heading'>Inspired by Purpose, Driven by Innovation</div>
                    </div>
                    <div className={`${classes.visionSecondaryContainer}  grid xl:grid-cols-2 lg:grid-cols-2 lg:gap-10 gap-5 items-center`}>
                        <div className=''>
                            <img src={vision} alt="" className='w-full' />
                        </div>
                        <div className=''>
                            <h3 className={`${classes.primaryText}  pb-4 `}>Our Vision</h3>
                            <p className={`${classes.secondaryText} text-lg`}>To lead the Indian steel industry in shaping a robust, sustainable, and globally integrated supply chain that empowers Industry with seamless access to high-quality steel products, advanced manufacturing solutions, and innovative digital transformation.</p>
                        </div>
                    </div>
                    <div className={`${classes.visionSecondaryContainer}  grid xl:grid-cols-2 lg:grid-cols-2 lg:gap-10 gap-5 items-center lg:mt-10 mt-20`}>
                        <div className='w-full order-2 lg:order-1'>
                            <h3 className={`${classes.primaryText} pb-4 text-end`}>Our Mission</h3>
                            <p className={`${classes.secondaryText} text-lg text-end mt-4`}>To empower businesses in the steel industry by combining comprehensive trade and manufacturing solutions, enabling cost-effective sourcing, efficient production, and innovative digital services that support every step of their growth journey.</p>
                        </div>
                        <div className='w-full order-1 lg:order-2'>
                            <img src={mission} alt="" className='w-full' />
                        </div>
                    </div>
                </div>
            </div>
            <RequestDemoTemplate />
        </>
    );
};
export default AboutUsTemplate;

