import React, { useRef, useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import { useLocation } from 'react-router-dom';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import CarouselRef from 'react-multi-carousel';
import sourcing_card1 from '../../../assets/icons/sourcing_card1.svg';
import sourcing_card2 from '../../../assets/icons/sourcing_card2.svg';
import sourcing_card3 from '../../../assets/icons/sourcing_card3.svg';
import sourcing_card4 from '../../../assets/icons/sourcing_card4.svg';
import transport from '../../../assets/images/transport.jpg';
import hotRolled from '../../../assets/images/hotRolled.jpg';
import coldRolled from '../../../assets/images/coldRolled.png';
import galvanised from '../../../assets/images/galvanised.svg';
import ppgi from '../../../assets/images/ppgi.jpg';
import angle from '../../../assets/images/angle.jpg';
import channel from '../../../assets/images/channel.jpg';
import beam from '../../../assets/images/beam.jpg';
import tmt from '../../../assets/images/tmt.jpg';
import sheets from '../../../assets/images/sheets.jpg';
import parts from '../../../assets/images/parts.jpg';
import box from '../../../assets/images/box.png';
import containers from '../../../assets/images/containers.jpg';
import smart from '../../../assets/images/smart.jpg';
import Seamless from '../../../assets/images/seamless.jpg';
import Streamlined from '../../../assets/images/streamlined.jpg';
import Strategic from '../../../assets/images/strategic.jpg';
import Secure from '../../../assets/images/Secure.svg';
import Standardized from '../../../assets/images/Standardized.svg';
import Scalable from '../../../assets/images/Scalable.svg';
import Sustainability from '../../../assets/images/Sustainability.jpg';
import RequestDemoTemplate from '../../template/Home/RequestDemo/RequestDemo.template';
import rightArrow from "../../../assets/icons/rightArrow.svg";
import leftArrow from "../../../assets/icons/leftArrow.svg"
import automotiveSvg from '../../../assets/images/Automotive.jpg';
import constructionSvg from '../../../assets/images/Construction.jpg';
import transportationSvg from '../../../assets/images/Transportation.jpg';
import industrialEquipmentSvg from '../../../assets/images/IndustrialEquipment.jpg';
import energySvg from '../../../assets/images/Energy.jpg';
import agricultureSvg from '../../../assets/images/Agriculture.jpg';
import coldStorageSvg from '../../../assets/images/ColdStorage.jpg';
import foodPackagingSvg from '../../../assets/images/FoodPackaging.jpg';
import b2bBanner from '../../../assets/images/b2bBanner.jpg';
import seller from '../../../assets/images/seller.jpg';
import vendor from '../../../assets/images/vendor.jpg';
import BulletImg from '../../../assets/images/bulletIcon.svg';
import sbInstant from '../../../assets/images/sbInstant.jpg';
import sbLead from '../../../assets/images/sbLead.jpg';
import sbDigiLog from '../../../assets/images/sbDigiLog.jpg';
import sbDigitStore from '../../../assets/images/sbDigitStore.jpg';
import sbTailor from '../../../assets/images/sbTailor.jpg';
import sbCompare from '../../../assets/images/sbCompare.jpg';
import sbFinance from '../../../assets/images/sbFinance.jpg';
import sbBid from '../../../assets/images/sbBid.jpg';
import shearing2 from '../../../assets/images/shearing2.jpg';
import slitting2 from '../../../assets/images/slitting2.jpg';
import colorCoated from '../../../assets/images/colorCoated.jpg';
import profiling from '../../../assets/images/profiling.jpg';
import cnc2 from '../../../assets/images/cnc2.jpg';
import blanking from '../../../assets/images/blanking.jpg';
import dieCasting2 from '../../../assets/images/dieCasting2.jpg';
import forging2 from '../../../assets/images/forging2.jpg';
import pipesTools from '../../../assets/images/pipesTools.jpg';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import MarketPlaceTemplate from './MarketPlaceTemplate';
import { useMediaQuery } from 'react-responsive';
import { Button } from '@mui/joy';
import DotImg from "../../../assets/images/dot-shape.png";


const useStyles = createUseStyles((theme: any) => ({
    bannerContainer: {
        backgroundImage: `url(${b2bBanner})`,
        backgroundSize: "cover",
    },
    productsCard: {
        border: `1px solid #E6E6E6`,
        width: '25%',
        height: '190px',
        background: '#fff',
        "& p": {
            color: '#000',
        },
        "& h4": {
            fontSize: '28px',
            color: '#000',
            fontWeight: '500',
            lineHeight: '34px',
        },
    },
    product2: {
        background: "#D7E2FF !important",
    },
    product3: {
        background: `${theme.palette.background.success.success100} !important`,
    },
    serialNumber: {
        width: '83px',
        height: '132px',

        borderRadius: '8px 0px 0px 8px',
        "& span": {
            color: '#FFF',
        }
    },
    productCardText: {
        "& h5": {
            color: '#000',
            fontSize: '32px',
            fontWeight: '600',
        },
        "& p": {
            lineHeight: '26px',
            fontSize: '18px',
        },
    },
    serialNumber1: {
        background: theme.palette.background.secondary.secondary400,
    },
    serialNumber2: {
        background: theme.palette.background.success.success500,
    },
    serialNumber3: {
        background: theme.palette.background.complementary.complementary300,
    },
    serialNumber4: {
        background: "#FD8235",
    },
    serialNumber5: {
        background: theme.palette.background.success.success400,
    },
    serialNumber6: {
        background: theme.palette.background.secondary.secondary700,
    },
    serialNumber7: {
        background: theme.palette.background.warning.warning400,
    },
    serialNumber8: {
        background: theme.palette.background.primary.primary400,
    },
    salesContainer: {
        background: '#F8F8F8',
    },
    sellDescription: {
        "& h4": {
            color: theme.palette.text.neutral.neutral800,
        },
        "& h5": {
            fontSize: '32px',
        },
        "& p": {
            lineHeight: '26px',
            color: '#000',
        },
    },
    sellText: {
        border: `1px solid ${theme.palette.border.neutral.neutral300}`,
        background: '#fff',
        borderRadius: '40px',
        width: '40%',
        "& p": {
            lineHeight: '22px',
            color: '#000',

        },
    },
    slide: {
        width: '246px',
        borderRadius: "12px",
        "&:hover": {
            boxShadow: "0px 5px 16px 0px rgba(8, 15, 52, 0.08)"
        },
    },
    tab: {
        marginRight: "16px"
    },
    image: {
        borderTopLeftRadius: "12px",
        borderTopRightRadius: "12px"
    },
    imagePrimary: {
        borderRadius: '12px',
    },
    title: {
        border: `1px solid ${theme.palette.border.neutral.neutral100}`,
        borderTop: "none",
        color: theme.palette.text.primary.primary900,
        lineHeight: "22px",
        borderBottomLeftRadius: "12px",
        borderBottomRightRadius: "12px"
    },
    titlePrimary: {
        borderTop: "none",
        color: theme.palette.text.primary.primary50,
        lineHeight: "22px",
        borderBottomLeftRadius: "12px",
        borderBottomRightRadius: "12px"
    },
    customButtonContainer: {
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
    },
    carouselContainer: {

        "& .react-multiple-carousel__arrow--right": {
            right: "0 !important"
        },
        "& .react-multiple-carousel__arrow--left": {
            left: "0 !important"
        },
        "& .react-multi-carousel-item": {
        },
        "& .react-multi-carousel-list": {
            width: "100%",
            margin: "0 auto",
        },
        "& .react-multi-carousel-list li": {
            width: "fit-content !important",
        },
    },
    customArrow: {
        borderRadius: "50%",
        width: "48px",
        height: "48px",
        cursor: "pointer",
        transition: "box-shadow 0.3s ease-in-out",
        boxShadow: `0px 6px 12px 2px rgba(10, 18, 41, 0.16), 0px 2px 4px 1px rgba(10, 18, 41, 0.04)`,
    },
    carouselMobileContainer: {
        display: "none"
    },

    mobileContainer: {},
    mobileSecondaryContainer: {},
    sellTextContainer: {},
    mobileContainerSecondary: {},

    productsCardMain: {
        width: '23.63%',
        background: '#FFE59A',
        borderRadius: '16px 0px 0px 16px',
        "& h4": {
            fontSize: '28px',
            fontWeight: '500',
            lineHeight: '34px',
        },
    },

    marketPlcaeCard: {
        width: '48.5%',
    },
    '@media screen and (max-width: 768px)': {

        carouselMobileContainer: {
            display: 'block',
        },
        carouselContainer: {
            display: "none",
        },
        mobileContainer: {
            display: 'flex',
            flexDirection: 'column',
            gap: '0px',
            marginTop: '0px',
        },
        mobileContainerSecondary: {
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
            marginTop: '0px',
        },
        mobileSecondaryContainer: {
            display: 'flex',
            flexWrap: 'wrap',
            width: '100% !important',
        },
        productsCardMain: {
            width: '100% !important',
            background: '#FFE59A',
            borderRadius: '16px 16px 0px 0px',
            "& h4": {
                fontSize: '22px',
                fontWeight: '600',
                lineHeight: '26px',
            },
        },
        productsCard: {
            border: `1px solid #E6E6E6`,
            width: '50%',
            height: '190px',
            background: '#fff',
            "& p": {
                color: '#000',
                fontSize: '16px',
                fontWeight: '700',
                lineHeight: '20px',
            },
            "& h4": {
                fontSize: '28px',
                color: '#000',
                fontWeight: '500',
                lineHeight: '34px',
            },
        },
        sellDescription: {
            width: '100%',
            "& h4": {
                color: theme.palette.text.neutral.neutral800,
                fontSize: '24px',
                lineHeight: '28px',
                marginBottom: '5px',
                fontWeight: '500',
            },
            "& h5": {
                fontSize: '18px',
                lineHeight: '22px',
            },
            "& p": {
                fontSize: '14px',
                lineHeight: '16px',
                color: '#000',
            },
        },
        sellText: {
            borderRadius: '8px',
            width: '48.3%',
            padding: '8px',
            "& p": {
                lineHeight: '14px',
                color: '#000',
                fontWeight: '600',
                fontSize: '12px',
            },
        },
        sellTextContainer: {
            display: 'flex',
            gap: '10px',
            flexWrap: 'wrap',
        },
        salesContainer: {
            padding: '0px',
        },
    }

}));

const B2BMarketplace: React.FC = () => {

    const classes = useStyles();
    const carouselRef = useRef<CarouselRef>(null);
    const location = useLocation();
    const isSmallScreen = useMediaQuery({ query: '(max-width: 900px)' });

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const component = params.get('component');

        if (component) {
            const element = document.getElementById(component);
            if (element) {
                element.scrollIntoView();
            }
        }
    }, [location.search]);

    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 5,
            partialVisibilityGutter: 0
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 5,
            partialVisibilityGutter: -23
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 3,
            partialVisibilityGutter: 10
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 2,
            partialVisibilityGutter: 0
        },
    };

    const data = [
        {
            imgSrc: automotiveSvg,
            title: "Automotive",
        },
        {
            imgSrc: constructionSvg,
            title: "Construction",
        },
        {
            imgSrc: transportationSvg,
            title: "Transportation",
        },
        {
            imgSrc: industrialEquipmentSvg,
            title: "Industrial Equipment",
        },
        {
            imgSrc: energySvg,
            title: "Energy",
        },
        {
            imgSrc: agricultureSvg,
            title: "Agriculture",
        },
        {
            imgSrc: coldStorageSvg,
            title: "Cold Storage",
        },
        {
            imgSrc: foodPackagingSvg,
            title: "Food Packaging",
        },

    ];
    const dataSlider = [
        {
            imgSrc: shearing2,
            title: "Shearing",
        },
        {
            imgSrc: slitting2,
            title: "Slitting",
        },
        {
            imgSrc: colorCoated,
            title: "Color Coated",
        },
        {
            imgSrc: profiling,
            title: "Profiling",
        },
        {
            imgSrc: cnc2,
            title: "CNC",
        },
        {
            imgSrc: blanking,
            title: "Blanking",
        },
        {
            imgSrc: dieCasting2,
            title: "Die Casting",
        },
        {
            imgSrc: forging2,
            title: "Forging",
        },
        {
            imgSrc: pipesTools,
            title: "Pipes and Tubes",
        },
    ];

    const cardData = [
        {
            icon: sourcing_card1,
            title: "Enhancing Import Export Opportunities",
            description: "Empower Indian vendors with access to a wider international market for steel products."
        },
        {
            icon: sourcing_card2,
            title: "Streamlined Cross-Border Trade",
            description: "Provide essential services like export documentation and compliance to simplify processes."
        },
        {
            icon: sourcing_card3,
            title: "Robust Logistics Solutions",
            description: "Ensure efficient logistics support to handle the complexities of international shipping."
        },
        {
            icon: sourcing_card4,
            title: "Global Buyer-Seller Matching",
            description: "Implement a system that connects Indian vendors with international buyers effectively."
        }
    ];

    const ButtonGroup = ({ next, previous, carouselState }: any) => {
        const { currentSlide, totalItems, slidesToShow } = carouselState;
        const isFirstSlide = currentSlide === 0;
        const isLastSlide = totalItems === 0 || currentSlide === totalItems - slidesToShow;

        return (
            <div className={`${classes.customButtonContainer} flex justify-between items-center w-full`}>
                {!isFirstSlide && (
                    <div className={`${classes.customArrow} bg-white flex justify-center items-center`} onClick={previous}>
                        <img src={leftArrow} alt="" />
                    </div>
                )}
                {!isLastSlide && (
                    <div className={`${classes.customArrow} bg-white flex justify-center items-center ml-auto`} onClick={next}>
                        <img src={rightArrow} alt="" />
                    </div>
                )}
            </div>
        );
    };

    return (
        <div>
            <div className={`${classes.bannerContainer}`}>
                <div className="container mx-auto px-4">
                    <div className="insideBannerBx">
                        <div className="insideBannerInfo">
                            <h1>
                                <span>Connect,</span> <br />
                                Trade, and Grow
                            </h1>
                            <p>
                                A Global B2B Marketplace for Steel
                            </p>
                            <Button className="HeaderBtn">
                                Get Started Today <ArrowForwardIcon />
                            </Button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="neutral20Bg lg:py-14 py-8 w-full">
                <div className="container lg:px-4 px-2 mx-auto flex lg:columns-2 columns-1 lg:flex-row flex-col lg:gap-10 gap-2 PageInfoBoxGry">
                    <div className="lg:w-2/5 w-full grid content-center ">
                        <h2>Your Real-Time Steel Trading Hub</h2>
                    </div>
                    <div className={`lg:w-3/5 w-full`}>
                        <p>
                            Steelbazaar is a one-stop digital marketplace tailored for the steel industry, connecting buyers and sellers in an efficient, transparent, & scalable way. We bring innovative tools, including Seller Central & Vendor Central models, along with supply chain financing & lead management services, to ensure businesses of all sizes can thrive in the steel trade.
                        </p>
                        <p>
                            From procurement to sales & financial solutions, Steelbazaar offers an end-to-end solution for the steel market. With its user-friendly interface, robust features, and commitment to customer success, Steelbazaar.com is poised to revolutionize the way steel businesses operate and thrive in the digital age.
                        </p>
                    </div>
                </div>
            </div>

            <section id='global'>
                <div className='container mx-auto lg:px-4 px-2 lg:pt-[90px] lg:pb-10 py-8'>
                    <div className={`headingContainer`}>
                        <div className="heading">Global Sourcing Simplified</div>
                        <div className="Subheading">
                            Connect with trusted suppliers across the globe
                        </div>
                    </div>

                    <div className="container mx-auto lg:px-4 w-full flex flex-wrap gap-4 ">
                        {cardData.map((card, index) => (
                            <div key={index} className='grayWhiteBox colWidth-4'>
                                <div className='iconBox'>
                                    <img src={card.icon} alt={card.title} />
                                </div>
                                <div className="TextBox">
                                    <b>{card.title}</b>
                                    {card.description}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>

            <div className='w-full'>
                <img src={transport} alt="" className='w-full' />
            </div>
            <div className='neutral20Bg'>
                <div className='container mx-auto lg:px-4 px-2 lg:py-[90px] py-8 flex flex-col items-center justify-center'>
                    <div className={`headingContainer`}>
                        <div className="heading"><span>A Diverse Range</span> of Steel Products for Every Need</div>
                        <div className="Subheading">
                            From Raw Materials to Finished Products</div>
                    </div>
                    <div className={`${classes.mobileContainerSecondary} flex flex-col gap-10 w-full`}>
                        <div className={`${classes.mobileContainer} flex lg:flex-row flex-col mx-auto my-0 w-full`}>
                            <div className={`${classes.productsCardMain} lg:px-[15px] lg:py-[30px] p-[15px] w-1/5 flex flex-col justify-between h-auto`}>
                                <h4 className='font-[28px]'>Carbon and Stainless Flat Products</h4>
                                <Button className="Btn whiteBg Txtblack !text-[#313335] w-max">View All</Button>
                            </div>
                            <div className={`${classes.mobileSecondaryContainer} flex w-4/5 `}>
                                <div className={`${classes.productsCard} pt-[15px] pl-[15px] relative lg:!h-auto`}>
                                    <p className='text-lg font-medium'>Hot Rolled Coils </p>
                                    <img src={hotRolled} alt="" className='absolute right-0 bottom-0' />
                                </div>
                                <div className={`${classes.productsCard} pt-[15px] pl-[15px] relative lg:!h-auto`}>
                                    <p className='text-lg font-medium'>Cold Rolled</p>
                                    <img src={coldRolled} alt="" className='absolute right-0 bottom-0' />
                                </div>
                                <div className={`${classes.productsCard} pt-[15px] pl-[15px] relative lg:!h-auto`}>
                                    <p className='text-lg font-medium'>Galvanized</p>
                                    <img src={galvanised} alt="" className='absolute right-0 bottom-0' />
                                </div>
                                <div className={`${classes.productsCard} pt-[15px] pl-[15px] relative lg:!h-auto`}>
                                    <p className='text-lg font-medium'>PPGI</p>
                                    <img src={ppgi} alt="" className='absolute right-0 bottom-0' />
                                </div>
                            </div>

                        </div>
                        <div className={`${classes.mobileContainer} flex mx-auto my-0 w-full`}>
                            <div className={`${classes.productsCardMain} ${classes.product2} lg:px-[15px] lg:py-[30px] p-[15px] w-1/5 flex flex-col justify-between`}>
                                <h4 className='font-[28px]'>Carbon and Stainless Long Products</h4>
                                <Button className="Btn whiteBg Txtblack !text-[#313335] w-max">View All</Button>
                            </div>
                            <div className={`${classes.mobileSecondaryContainer} flex w-4/5`}>
                                <div className={`${classes.productsCard} pt-[15px] pl-[15px] relative lg:!h-auto`}>
                                    <p className='text-lg font-medium'>Angle</p>
                                    <img src={angle} alt="" className='absolute right-0 bottom-0' />
                                </div>
                                <div className={`${classes.productsCard} pt-[15px] pl-[15px] relative lg:!h-auto`}>
                                    <p className='text-lg font-medium'>Channel</p>
                                    <img src={channel} alt="" className='absolute right-0 bottom-0' />
                                </div>
                                <div className={`${classes.productsCard} pt-[15px] pl-[15px] relative lg:!h-auto`}>
                                    <p className='text-lg font-medium'>Beam</p>
                                    <img src={beam} alt="" className='absolute right-0 bottom-0' />
                                </div>
                                <div className={`${classes.productsCard} pt-[15px] pl-[15px] relative lg:!h-auto`}>
                                    <p className='text-lg font-medium'>TMT</p>
                                    <img src={tmt} alt="" className='absolute right-0 bottom-0' />
                                </div>
                            </div>
                        </div>
                        <div className={`${classes.mobileContainer} flex mx-auto my-0 w-full`}>
                            <div className={`${classes.productsCardMain} ${classes.product3} lg:px-[15px] lg:py-[30px] p-[15px] w-1/5 flex flex-col justify-between`}>
                                <h4 className='font-[28px]'>Finished Products at Bulk Prices</h4>
                                <Button className="Btn whiteBg Txtblack !text-[#313335] w-max">View All</Button>
                            </div>
                            <div className={`${classes.mobileSecondaryContainer} flex w-4/5 `}>
                                <div className={`${classes.productsCard} pt-[15px] pl-[15px] relative lg:!h-auto`}>
                                    <p className='text-lg font-medium'>Roofing Sheet</p>
                                    <img src={sheets} alt="" className='absolute right-0 bottom-0' />
                                </div>
                                <div className={`${classes.productsCard} pt-[15px] pl-[15px] relative lg:!h-auto`}>
                                    <p className='text-lg font-medium'>Auto Parts</p>
                                    <img src={parts} alt="" className='absolute right-0 bottom-0' />
                                </div>
                                <div className={`${classes.productsCard} pt-[15px] pl-[15px] relative lg:!h-auto`}>
                                    <p className='text-lg font-medium'>Panel Box</p>
                                    <img src={box} alt="" className='absolute right-0 bottom-0' />
                                </div>
                                <div className={`${classes.productsCard} pt-[15px] pl-[15px] relative lg:!h-auto`}>
                                    <p className='text-lg font-medium'>Cans & Containers</p>
                                    <img src={containers} alt="" className='absolute right-0 bottom-0' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`container mx-auto lg:px-4 lg:pb-[58px] overflow-x-auto overflow-hidden lg:pt-[90px] pt-8`}>
                <div className={`headingContainer`}>
                    <div className='heading'><span>Customization Services</span> for Tailored Products</div>
                </div>
                <div className={`${classes.carouselContainer} relative relative px-16`}>
                    <Carousel
                        responsive={responsive}
                        showDots={false}
                        infinite={false}
                        partialVisible={true}
                        ref={carouselRef}
                        arrows={false}
                        removeArrowOnDeviceType={["sm-mobile"]}
                        renderButtonGroupOutside={true}
                        customButtonGroup={<ButtonGroup />}
                    >
                        {dataSlider.map((item, index) => (
                            <div
                                className={`${classes.slide} grid relative ${index !== dataSlider.length - 1 ? classes.tab : ''}`}
                                key={item.title}
                            >
                                <img
                                    className={classes.imagePrimary}
                                    src={item.imgSrc}
                                    alt={`${item.title} image`}
                                />
                                <div className={`${classes.titlePrimary} absolute bottom-3 left-3 text-lg font-bold`}>
                                    {item.title}
                                </div>
                            </div>
                        ))}
                    </Carousel>
                </div>

                <div className={`overflow-x-auto overflow-hidden w-full ${classes.carouselMobileContainer}`}>
                    <div className={`flex gap-x-3 w-max`}>
                        {dataSlider.map((item: any, index: any) => (
                            <div className={`${classes.slide} grid relative ${index !== dataSlider.length - 1 ? classes.tab : ''}`}
                                key={item.title} >
                                <img
                                    className={classes.imagePrimary}
                                    src={item.imgSrc}
                                    alt={`${item.title} image`}
                                />
                                <div className={`${classes.titlePrimary} absolute bottom-3 left-3 text-lg font-bold`}>
                                    {item.title}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

            </div>
            <div className='neutral20Bg z-[-2] relative'>
                <div className='container mx-auto lg:px-4 px-2 lg:pb-[180px] lg:pt-[90px] py-8'>
                    <div className={`headingContainer lg:mb-10`}>
                        <div className="heading">Flexible Sales Solutions for All</div>
                        <div className="Subheading">Choose Seller Central or Vendor Central</div>
                    </div>
                    <div className="flex lg:columns-2 columns-1 lg:flex-row flex-col items-center !pt-0 ZigZagSection">
                        <div className="2xl:w-4/12 lg:w-5/12 w-full flex content-center justify-center">
                            <div className="ZigZagImgBox">
                                <div className="ImgItem">
                                    <img src={DotImg} alt="" />
                                </div>
                                <img src={seller} className="w-full" alt="" />
                            </div>
                        </div>
                        <div className={`${classes.sellDescription} w-2/3 mt-5 2xl:w-8/12 lg:w-7/12 w-full ZigZagText`}>
                            <h4 className='text-5xl font-semibold mb-[10px]'>Seller Central (3P) Model</h4>
                            <h5 className='font-medium mb-[10px]'>Sell Directly to Customers</h5>
                            <p className='text-lg'>Steelbazaar's Seller Central allows independent sellers to list products, manage inventory, and connect with buyers. It offers tools for creating a digital storefront, generating leads, and managing orders, making it ideal for businesses looking to reach a broad customer base while managing logistics.</p>
                            <div className={`${classes.sellTextContainer} flex flex-wrap gap-x-4 gap-y-[30px] mt-5`}>
                                <div className={`${classes.sellText} px-5 py-2`}>
                                    <p className='text-lg'>Customizable storefronts for product listings and branding.</p>
                                </div>
                                <div className={`${classes.sellText} px-5 py-2`}>
                                    <p className='text-lg'>Tools for lead generation to connect with potential buyers.</p>
                                </div>
                                <div className={`${classes.sellText} px-5 py-2`}>
                                    <p className='text-lg'>Increased visibility within Steelbazaar's buyer network.</p>
                                </div>
                                <div className={`${classes.sellText} px-5 py-2`}>
                                    <p className='text-lg'>Integrated inventory management in real-time.</p>
                                </div>
                                <div className={`${classes.sellText} px-5 py-2`}>
                                    <p className='text-lg'>Control over pricing, promotions, and special offers.</p>
                                </div>
                                <div className={`${classes.sellText} px-5 py-2`}>
                                    <p className='text-lg'>Direct access to manage listings and sales.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={`${classes.mobileContainer} flex items-center gap-10`}>
                        <div className={`${classes.sellDescription} w-2/3 mt-5`}>
                            <h4 className='text-5xl font-semibold mb-[10px]'>Vendor Central (1P) Model</h4>
                            <h5 className='font-medium mb-[10px]'>Bulk Sales Directly to Us</h5>
                            <p className='text-lg'>The Vendor Central model allows businesses to operate as first-party (1P) suppliers, selling products directly to Steelbazaar. Steelbazaar purchases inventory and manages sales and fulfilment, making this model ideal for businesses seeking stable demand and consistent orders.</p>
                            <div className={`${classes.sellTextContainer} flex flex-wrap gap-x-4 gap-y-[30px] mt-5`}>
                                <div className={`${classes.sellText} px-5 py-2`}>
                                    <p className='text-lg'>Guaranteed Bulk Purchases and Large Contract Opportunities</p>
                                </div>
                                <div className={`${classes.sellText} px-5 py-2`}>
                                    <p className='text-lg'>Steelbazaar manages sales, fulfilment, & customer interactions.</p>
                                </div>
                                <div className={`${classes.sellText} px-5 py-2`}>
                                    <p className='text-lg'>Steelbazaar handles enhanced visibility and marketing.</p>
                                </div>
                                <div className={`${classes.sellText} px-5 py-2`}>
                                    <p className='text-lg'>We offer payment integration with secure financial transactions.</p>
                                </div>
                                <div className={`${classes.sellText} px-5 py-2`}>
                                    <p className='text-lg'>Gain access to large buyers and enterprise-level sales opportunities.</p>
                                </div>
                                <div className={`${classes.sellText} px-5 py-2`}>
                                    <p className='text-lg'>Enjoy consistent, long-term sales through bulk purchasing options.</p>
                                </div>
                            </div>
                        </div>
                        <div className="2xl:w-4/12 lg:w-5/12 w-full flex content-center justify-center">
                            <div className="ZigZagImgBox">
                                <div className="ImgItem">
                                    <img src={DotImg} alt="" />
                                </div>
                                <img src={vendor} className="w-full" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='container mx-auto lg:px-4 px-2 lg:py-[90px] py-8 '>
                <div className={`headingContainer`}>
                    <div className="heading"><span>Innovative Features</span> <br /> Revolutionize the Steel Trade</div>
                </div>
                <div className={`${classes.mobileContainerSecondary} flex gap-10 items-center  ZigZagSection`}>
                    <div className="2xl:w-4/12 lg:w-5/12 w-full flex content-center justify-center">
                        <div className="ZigZagImgBox">
                            <div className="ImgItem">
                                <img src={DotImg} alt="" />
                            </div>
                            <img src={sbInstant} className="w-full" alt="" />
                        </div>
                    </div>
                    <div className="2xl:w-8/12 lg:w-7/12 w-full ZigZagText order-2 lg:order-1">
                        <div className="BlueText">SB Instant</div>

                        <div className="heading48">
                            Real-Time Pricing
                            and<span> Market Updates</span>
                        </div>
                        <div className="Subhead">
                            <b>Stay Informed with Accurate & Updated Insights</b>
                        </div>

                        <div className="PointBox">
                            <ul>
                                <li>
                                    <img src={BulletImg} className="bulletBrd" alt="" />
                                    <span>
                                        <b>Instant Price Tracking</b>
                                        Access real-time pricing data to make timely, informed decisions.
                                    </span>
                                </li>
                                <li>
                                    <img src={BulletImg} className="bulletBrd" alt="" />
                                    <span>
                                        <b>Market Trend Analysis</b>
                                        Stay ahead with insights into market fluctuations and trends.
                                    </span>
                                </li>
                                <li>
                                    <img src={BulletImg} className="bulletBrd" alt="" />
                                    <span>
                                        <b>Custom Alerts and Notifications</b>
                                        Get personalized updates on price changes and market shifts.
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="flex lg:columns-2 columns-1 lg:flex-row flex-col items-center ZigZagSection " id='compare-brands'>
                    <div className="2xl:w-4/12 lg:w-5/12 w-full flex order-1 lg:order-2 justify-center">
                        <div className="ZigZagImgBox">
                            <div className="ImgItemLeft">
                                <img src={DotImg} alt="" />
                            </div>
                            <img src={sbCompare} className="w-full" alt="" />
                        </div>
                    </div>
                    <div className="2xl:w-8/12 lg:w-7/12 w-full ZigZagText order-2 lg:order-1">
                        <div className="BlueText">SB Compare</div>

                        <div className="heading48">
                            Brand Comparison
                            Choose the <span>Best for You</span>
                        </div>
                        <div className="Subhead">
                            <b>Analyse Top Brands to Make Informed Decisions</b>
                        </div>

                        <div className="PointBox">
                            <ul>
                                <li>
                                    <img src={BulletImg} className="bulletBrd" alt="" />
                                    <span>
                                        <b>Feature-by-Feature Comparisons</b>
                                        Highlight key details like business History Type, location,
                                        Product details, contact information and specialities.
                                    </span>
                                </li>
                                <li>
                                    <img src={BulletImg} className="bulletBrd" alt="" />
                                    <span>
                                        <b>Pricing Transparency</b>
                                        detailed pricing structures with discounts/offers to identify the best sourcing
                                    </span>
                                </li>
                                <li>
                                    <img src={BulletImg} className="bulletBrd" alt="" />
                                    <span>
                                        <b>Expert Advice</b>
                                        Access professional recommendations to assist in your decision-making process.
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="flex lg:columns-2 columns-1 lg:flex-row flex-col items-center ZigZagSection " id='request-for-quotation'>
                    <div className="2xl:w-4/12 lg:w-5/12 w-full flex content-center justify-center">
                        <div className="ZigZagImgBox">
                            <div className="ImgItem">
                                <img src={DotImg} alt="" />
                            </div>
                            <img src={sbTailor} className="w-full" alt="" />
                        </div>
                    </div>
                    <div className="2xl:w-8/12 lg:w-7/12 w-full ZigZagText">
                        <div className="BlueText">SB Tailor</div>
                        <div className="heading48">
                            Customized Material
                            Solutions for <span>Every Need</span>
                        </div>
                        <div className="Subhead">
                            <b>Tailored Steel Products to Meet Your Specific Requirements</b>
                        </div>

                        <div className="PointBox">
                            <ul>
                                <li>
                                    <img src={BulletImg} className="bulletBrd" alt="" />
                                    <span>
                                        <b>Bespoke Material Specifications</b>
                                        Access steel products customized to your precise dimensions, grades, and finishes, ensuring optimal project performance.
                                    </span>
                                </li>
                                <li>
                                    <img src={BulletImg} className="bulletBrd" alt="" />
                                    <span>
                                        <b>Flexible Order Quantities</b>
                                        Whether you need small batches or large volumes, our platform accommodates orders of all sizes to suit your production demands.
                                    </span>
                                </li>
                                <li>
                                    <img src={BulletImg} className="bulletBrd" alt="" />
                                    <span>
                                        <b>Expert Consultation Services</b>
                                        Collaborate with our experienced professionals to select materials that align with your technical & budgetary needs, ensuring the best fit for your applications
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="flex lg:columns-2 columns-1 lg:flex-row flex-col items-center ZigZagSection ">
                    <div className="2xl:w-4/12 lg:w-5/12 w-full flex order-1 lg:order-2 justify-center">
                        <div className="ZigZagImgBox">
                            <div className="ImgItemLeft">
                                <img src={DotImg} alt="" />
                            </div>
                            <img src={sbDigitStore} className="w-full" alt="" />
                        </div>
                    </div>
                    <div className="2xl:w-8/12 lg:w-7/12 w-full ZigZagText order-2 lg:order-1">
                        <div className="BlueText">SB DigiStore</div>
                        <div className="heading48">Digital Storefronts &
                            <span> Inventory Management</span>
                        </div>
                        <div className="Subhead">
                            <b>Manage Your Products Digitally with Ease</b>
                        </div>

                        <div className="PointBox">
                            <ul>
                                <li>
                                    <img src={BulletImg} className="bulletBrd" alt="" />
                                    <span>
                                        <b>Dynamic Digital Storefronts</b>
                                        Set up a personalized, professional storefront with company branding, product displays, and promotional banners, ensuring maximum visibility to potential buyers.
                                    </span>
                                </li>
                                <li>
                                    <img src={BulletImg} className="bulletBrd" alt="" />
                                    <span>
                                        <b>Real-Time Inventory Tracking</b>
                                        Manage your inventory efficiently with real-time tracking and Get instant notifications for  inventory movements and stock levels
                                    </span>
                                </li>
                                <li>
                                    <img src={BulletImg} className="bulletBrd" alt="" />
                                    <span>
                                        <b>Integrated Order and Inventory Management</b>
                                        Track orders and manage stock from a unified dashboard.
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="flex lg:columns-2 columns-1 lg:flex-row flex-col items-center ZigZagSection ">
                    <div className="2xl:w-4/12 lg:w-5/12 w-full flex content-center justify-center">
                        <div className="ZigZagImgBox">
                            <div className="ImgItem">
                                <img src={DotImg} alt="" />
                            </div>
                            <img src={sbDigiLog} className="w-full" alt="" />
                        </div>
                    </div>
                    <div className="2xl:w-8/12 lg:w-7/12 w-full ZigZagText">
                        <div className="BlueText">SB DigiLog</div>
                        <div className="heading48">
                            Explore Our
                            Digital <span>Product Catalogs</span>
                        </div>
                        <div className="Subhead">
                            <b>Access Comprehensive Steel Listings Anytime, Anywhere</b>
                        </div>

                        <div className="PointBox">
                            <ul>
                                <li>
                                    <img src={BulletImg} className="bulletBrd" alt="" />
                                    <span>
                                        <b>Interactive Product Exploration</b>
                                        Engage with detailed product descriptions, high-resolution images & specifications to make informed decisions.
                                    </span>
                                </li>
                                <li>
                                    <img src={BulletImg} className="bulletBrd" alt="" />
                                    <span>
                                        <b>Real-Time Inventory Updates</b>
                                        Stay informed with the latest stock availability and pricing, ensuring timely procurement.
                                    </span>
                                </li>
                                <li>
                                    <img src={BulletImg} className="bulletBrd" alt="" />
                                    <span>
                                        <b>Seamless Navigation</b>
                                        Utilize intuitive search and filter options to locate the steel products that meet your specific requirements quickly.
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="flex lg:columns-2 columns-1 lg:flex-row flex-col items-center ZigZagSection ">
                    <div className="2xl:w-4/12 lg:w-5/12 w-full flex order-1 lg:order-2 justify-center">
                        <div className="ZigZagImgBox">
                            <div className="ImgItemLeft">
                                <img src={DotImg} alt="" />
                            </div>
                            <img src={sbLead} className="w-full" alt="" />
                        </div>
                    </div>
                    <div className="2xl:w-8/12 lg:w-7/12 w-full ZigZagText order-2 lg:order-1">
                        <div className="BlueText">SB LeadX</div>
                        <div className="heading48">Boost Sales Performance with<span> Lead Management</span>
                        </div>
                        <div className="Subhead">
                            <b>Connect Sellers with Quality Buy Leads & Close Deals Faster</b>
                        </div>

                        <div className="PointBox">
                            <ul>
                                <li>
                                    <img src={BulletImg} className="bulletBrd" alt="" />
                                    <span>
                                        <b>Verified Buyer and Seller Leads</b>
                                        Verified and qualified leads to ensure serious buyers and Increase sales opportunities
                                    </span>
                                </li>
                                <li>
                                    <img src={BulletImg} className="bulletBrd" alt="" />
                                    <span>
                                        <b>Real-Time Lead Updates</b>
                                        Monitor & manage leads efficiently with real-time updates and insights.
                                    </span>
                                </li>
                                <li>
                                    <img src={BulletImg} className="bulletBrd" alt="" />
                                    <span>
                                        <b>Tailored Lead Recommendations</b>
                                        With advanced algorithms, SB Connect recommends leads based on your business profile and product range, ensuring focussed sales efforts
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="flex lg:columns-2 columns-1 lg:flex-row flex-col items-center ZigZagSection ">
                    <div className="2xl:w-4/12 lg:w-5/12 w-full flex content-center justify-center">
                        <div className="ZigZagImgBox">
                            <div className="ImgItem">
                                <img src={DotImg} alt="" />
                            </div>
                            <img src={sbBid} className="w-full" alt="" />
                        </div>
                    </div>
                    <div className="2xl:w-8/12 lg:w-7/12 w-full ZigZagText">
                        <div className="BlueText">
                            SB BidHub</div>
                        <div className="heading48">
                            Forward and Reverse <span>Auction Services</span>
                        </div>
                        <div className="Subhead">
                            <b>Achieve Optimal Pricing Through Competitive Auctioning</b>
                        </div>

                        <div className="PointBox">
                            <ul>
                                <li>
                                    <img src={BulletImg} className="bulletBrd" alt="" />
                                    <span>
                                        <b>Dynamic Forward Auctions</b>
                                        Sell products to the highest bidder, maximizing returns on your inventory.
                                    </span>
                                </li>
                                <li>
                                    <img src={BulletImg} className="bulletBrd" alt="" />
                                    <span>
                                        <b>Efficient Reverse Auctions</b>
                                        Procure materials at the best prices by inviting competitive bids from suppliers.
                                    </span>
                                </li>
                                <li>
                                    <img src={BulletImg} className="bulletBrd" alt="" />
                                    <span>
                                        <b>Transparent Bidding Platform</b>
                                        Experience secure, real-time bidding with full transparency, ensuring fair & competitive pricing
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="flex lg:columns-2 columns-1 lg:flex-row flex-col items-center ZigZagSection ">
                    <div className="2xl:w-4/12 lg:w-5/12 w-full flex order-1 lg:order-2 justify-center">
                        <div className="ZigZagImgBox">
                            <div className="ImgItemLeft">
                                <img src={DotImg} alt="" />
                            </div>
                            <img src={sbFinance} className="w-full" alt="" />
                        </div>
                    </div>
                    <div className="2xl:w-8/12 lg:w-7/12 w-full ZigZagText order-2 lg:order-1">
                        <div className="BlueText">SB Instafin</div>
                        <div className="heading48">Supply Chain<span> Financing Solutions</span>
                        </div>
                        <div className="Subhead">
                            <b>Flexible Financing to Keep Your Business Moving</b>
                        </div>

                        <div className="PointBox">
                            <ul>
                                <li>
                                    <img src={BulletImg} className="bulletBrd" alt="" />
                                    <span>
                                        <b>Seamless Working Capital Solutions</b>
                                        Customized working capital loans to ensure uninterrupted operations and steady cash flow.
                                    </span>
                                </li>
                                <li>
                                    <img src={BulletImg} className="bulletBrd" alt="" />
                                    <span>
                                        <b>Empower Trade with Extended Payment Terms</b>
                                        Enable smoother transactions with flexible credit terms for both buyers and sellers.
                                    </span>
                                </li>
                                <li>
                                    <img src={BulletImg} className="bulletBrd" alt="" />
                                    <span>
                                        <b>Instant Approvals and Digital Processing</b>
                                        With fully digital workflows, We ensure fast approvals and minimal paperwork, giving you the financial support you need without delays or complexities.
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                {isSmallScreen ? <MarketPlaceTemplate /> :
                    <div className='py-[110px]'>
                        <div className={`headingContainer`}>
                            <div className="heading">Unlocking the 8S Power of Marketplace<br />Smart <span className='success400'>Sourcing </span>to Sustainability</div>
                        </div>
                        <div className='container mx-auto lg:px-4 px-3 w-full flex flex-wrap gap-4 '>
                            <div className={`${classes.marketPlcaeCard} flex gap-[10px] items-center`}>
                                <img src={smart} alt="" />
                                <div className={classes.productCardText}>
                                    <h5>Smart</h5>
                                    <p>Trade with AI-driven tools and insights for optimized trading and decision-making </p>
                                </div>
                                <div className={`${classes.serialNumber} ${classes.serialNumber1} flex items-center justify-center p-[15px]`}>
                                    <span className='text-5xl font-semi-bold'>01</span>
                                </div>
                            </div>
                            <div className={`${classes.marketPlcaeCard} flex gap-[10px] items-center`}>
                                <img src={Seamless} alt="" />
                                <div className={classes.productCardText}>
                                    <h5>Seamless</h5>
                                    <p>Sourcing from Multiple Global & Domestic Manufacturers with Quality Assurance</p>
                                </div>
                                <div className={`${classes.serialNumber} ${classes.serialNumber2} flex items-center justify-center p-[15px]`}>
                                    <span className='text-5xl font-semi-bold'>02</span>
                                </div>
                            </div>
                            <div className={`${classes.marketPlcaeCard} flex gap-[10px] items-center`}>
                                <img src={Streamlined} alt="" />
                                <div className={classes.productCardText}>
                                    <h5>Streamlined</h5>
                                    <p>Digital, Automated & Transparent Workflows for inventory & order management</p>
                                </div>
                                <div className={`${classes.serialNumber} ${classes.serialNumber3} flex items-center justify-center p-[15px]`}>
                                    <span className='text-5xl font-semi-bold'>03</span>
                                </div>
                            </div>
                            <div className={`${classes.marketPlcaeCard} flex gap-[10px] items-center`}>
                                <img src={Strategic} alt="" />
                                <div className={classes.productCardText}>
                                    <h5>Strategic</h5>
                                    <p>Savings through Bulk pricing, cost-effective sourcing, and financing options </p>
                                </div>
                                <div className={`${classes.serialNumber} ${classes.serialNumber4} flex items-center justify-center p-[15px]`}>
                                    <span className='text-5xl font-semi-bold'>04</span>
                                </div>
                            </div>
                            <div className={`${classes.marketPlcaeCard} flex gap-[10px] items-center justify-between`}>
                                <div className='flex items-center gap-[10px]'>
                                    <img src={Secure} alt="" />
                                    <div className={classes.productCardText}>
                                        <h5>Secure</h5>
                                        <p>Fast and Easy Transactions : Trade with Confidence     </p>
                                    </div>
                                </div>
                                <div className={`${classes.serialNumber} ${classes.serialNumber5} flex items-center justify-center p-[15px]`}>
                                    <span className='text-5xl font-semi-bold'>05</span>
                                </div>
                            </div>
                            <div className={`${classes.marketPlcaeCard} flex gap-[10px] items-center`}>
                                <img src={Standardized} alt="" />
                                <div className={classes.productCardText}>
                                    <h5>Standardized</h5>
                                    <p>and Industry Compliant processes and materials for assured, regulated quality</p>
                                </div>
                                <div className={`${classes.serialNumber} ${classes.serialNumber6} flex items-center justify-center p-[15px]`}>
                                    <span className='text-5xl font-semi-bold'>06</span>
                                </div>
                            </div>
                            <div className={`${classes.marketPlcaeCard} flex gap-[10px] items-center`}>
                                <img src={Scalable} alt="" />
                                <div className={classes.productCardText}>
                                    <h5>Scalable</h5>
                                    <p>Growth through discovering new global markets and connections</p>
                                </div>
                                <div className={`${classes.serialNumber} ${classes.serialNumber7} flex items-center justify-center p-[15px]`}>
                                    <span className='text-5xl font-semi-bold'>07</span>
                                </div>
                            </div>
                            <div className={`${classes.marketPlcaeCard} flex gap-[10px] items-center`}>
                                <img src={Sustainability} alt="" />
                                <div className={classes.productCardText}>
                                    <h5>Sustainability</h5>
                                    <p>Commitment to eco-friendly practices, including recycling, promotes environmental responsibility.</p>
                                </div>
                                <div className={`${classes.serialNumber} ${classes.serialNumber8} flex items-center justify-center p-[15px]`}>
                                    <span className='text-5xl font-semi-bold'>08</span>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>

            <div className={`container mx-auto pt-8 px-3 lg:px-4 lg:py-[90px] overflow-x-auto overflow-hidden `}>
                <div className={`headingContainer`}>
                    <div className='heading'>Optimized Supply Chains Maximum Realizations</div>
                    <div className="Subheading">Transforming diverse Industrial Supply Chains for Optimal Performance</div>
                </div>
                <div className={`${classes.carouselContainer} relative py-8 px-16`}>
                    <Carousel
                        responsive={responsive}
                        showDots={false}
                        infinite={false}
                        partialVisible={true}
                        ref={carouselRef}
                        arrows={false}
                        removeArrowOnDeviceType={["sm-mobile"]}
                        renderButtonGroupOutside={true}
                        customButtonGroup={<ButtonGroup />}
                    >
                        {data.map((item: any, index: any) => (
                            <div className={` ${classes.slide} grid ${index !== data.length - 1 ? classes.tab : ''}`} key={item.id} >
                                <img className={classes.image} src={item.imgSrc} alt="industryImage" />
                                <div className={`${classes.title} pl-4 pt-4 pb-6 text-lg font-bold`}>{item.title}</div>
                            </div>
                        ))}
                    </Carousel>
                </div>
                <div className={`overflow-x-auto overflow-hidden w-full ${classes.carouselMobileContainer}`}>
                    <div className={`flex gap-x-3 w-max`}>
                        {data.map((item: any, index: any) => (
                            <div className={` ${classes.slide} grid ${index !== data.length - 1 ? classes.tab : ''}`} key={item.id} >
                                <img className={classes.image} src={item.imgSrc} alt="industryImage" />
                                <div className={`${classes.title} pl-4 pt-4 pb-6 text-lg font-bold`}>{item.title}</div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <RequestDemoTemplate />
        </div>
    );
};
export default B2BMarketplace;
