import React, { useState, useRef, useEffect } from "react";
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import { IMultiSelectOption } from "../../../atoms/FilterChip/FilterChip";
import { createUseStyles } from "react-jss";

interface FilterDropdownProps {
    options: IMultiSelectOption[];
    label: string;
    value: IMultiSelectOption[];
    onchange: (selected: IMultiSelectOption[]) => void;
    border?: boolean;
    singleSelect?: boolean;
}

const useStyles = createUseStyles((theme: any) => ({
    container: {
        color: theme.palette.text.neutral.neutral700,
        fontSize: "14px",
        lineHeight: "16px"
    },
    selectedValue: {
        color: theme.palette.text.primary.primary500,
        backgroundColor: theme.palette.background.primary.primary50,
        lineHeight: "14px"
    },
    unselectedValue: {
        color: theme.palette.text.neutral.neutral600,
        lineHeight: "14px",
        border: `1px solid #DEDEDE`,
    },
    border: {
        border: `1px solid #CACCCE`,
    },
    more: {
        color: theme.palette.text.primary.primary500,
        backgroundColor: theme.palette.background.primary.primary50,
        lineHeight: "14px",
        fontWeight: 500,
        fontSize: "10px",
        borderRadius: "100px",
        padding: "3.5px",
        width: "20px",
        height: "20px"
    },
    "@media (max-width: 767px)": {},
}));

const FilterDropDown: React.FC<FilterDropdownProps> = ({ options, label, value, onchange, border = true, singleSelect = false }) => {
    const classes = useStyles();

    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);

    const toggleDropdown = () => setIsOpen(!isOpen);

    const handleSelect = (selectedOption: IMultiSelectOption) => {
        let newValues;
        if (singleSelect) {
            newValues = [selectedOption];
            setIsOpen(false);
        } else {
            newValues = value.some((v) => v.value === selectedOption.value)
                ? value.filter((v) => v.value !== selectedOption.value)
                : [...value, selectedOption];
        }
        onchange(newValues);
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, []);

    return (
        <div className={`${border ? classes.border : ""} relative`} ref={dropdownRef} >
            <div className={`${classes.container} px-3 py-2 rounded flex items-center justify-between cursor-pointer !text-black !font-medium !text-base`} onClick={toggleDropdown}>
                <span className="!text-base">
                    {value.length > 0 ? (
                        value.length > 1 ? (<div className="flex gap-x-1 "><div className="my-auto">{value[0].label}</div> <span className={`${classes.more} items-center`}>+{value.length - 1}</span></div>) : (value[0].label)
                    ) : (
                        label
                    )}
                </span>
                {isOpen ? <ArrowDropUp /> : <ArrowDropDown />}
            </div>
            {isOpen && (
                <div className="absolute lg:left-[-15px] left-0 mt-2 lg:w-[107%] w-full bg-white rounded-xl z-10 flex flex-wrap gap-3 p-6 text-xs font-medium">
                    {options.map((option) => (
                        <div key={option.value} className={`${value.some((v) => v.value === option.value) ? classes.selectedValue : classes.unselectedValue} py-2 px-3 flex gap-x-1 items-center cursor-pointer rounded-full font-medium`} onClick={() => handleSelect(option)} >
                            {value.some((v) => v.value === option.value) ? (<CheckIcon style={{ fontSize: 14 }} />) : (<AddIcon style={{ fontSize: 14 }} />)}
                            <span>{option.label}</span>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default FilterDropDown;