interface MiscellaneousTemplateProps {
    contentStyle?: string;
    boxStyle?: string;
    subHeading?: string;
    header?: string;
}

const MiscellaneousTemplate: React.FC<MiscellaneousTemplateProps> = ({ contentStyle, header, boxStyle, subHeading }) => {
    return (
        <>
            <div className={boxStyle}>
                <span className={subHeading}>{header}</span>
            </div>
            <div className={contentStyle}>
            
            <h4 className="mt-3 mb-1 text-xl font-semibold">General Terms</h4>
            <p>We may periodically amend these Terms without prior notice. Regularly review updated Terms posted on the Platform. Continued usage of the Platform constitutes Your acceptance of revised Terms. </p>
                <p>All communications with Us will remain confidential. Calls may be recorded for training and quality assurance purposes.</p>
                <p>These Terms are governed exclusively by Indian law, and disputes are subject to the jurisdiction of courts in Delhi, India.</p>
          </div>
        </>
    )
}

export default MiscellaneousTemplate;