import * as React from 'react';
import { useState } from 'react';
import * as yup from "yup";
import { createUseStyles } from 'react-jss';
import Button from '../../atoms/Button/Button';
import TextFieldV2 from '../../atoms/Input/TextFieldV2';
import { useFormik } from 'formik';
import { HTTP_STATUS } from '../../../utils/types';
import { useBusinessProfileService } from '../../../services/useBusinessProfileService';
import { useSnackbar } from '../../../hooks/useSnackBar';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import { ENTITY_TYPES, REGEX } from '../../../utils/constant';
import SelectV2 from '../../atoms/Select/SelectV2';
import whiteSearchIcon from "../../../assets/icons/whiteSearchIcon.svg"

const useStyles = createUseStyles((theme: any) => ({
    container: {
        border: `1px solid ${theme.palette.border.neutral.neutral100}`,
        borderRadius: "8px",
    },
    primary_container: {
        border: `1px solid ${theme.palette.border.neutral.neutral100}`,
    },
    title: {
        color: theme.palette.text.primary.primary900,
    },
    container_heading: {
        color: theme.palette.text.neutral.neutral800,
    },
    card_content: {
        color: theme.palette.text.neutral.neutral700,
        lineHeight: "20px"
    },
    search_container: {
        background: theme.palette.background.primary.primary500,
        padding: "12px",
        borderRadius: "12px",
        height: "46px",
        marginTop: "24px"
    },
    input: {
        border: "1px solid",
        borderColor: theme.palette.border.neutral.neutral200,
        fontSize: "16px",
        fontWeight: 400,
        borderRadius: "12px",
        "&:hover": {
            borderColor: theme.palette.border.primary.primary300,
            borderWidth: "1px",
            outline: "none",
        },
        "& .MuiInputBase-input": {
            padding: "13px 12px",
            "&::placeholder": {
                color: `${theme.palette.text.neutral.neutral400} !important`,
                fontWeight: 400,
            },
        },
        "& .MuiOutlinedInput-notchedOutline": {
            outline: "none",
            borderWidth: 0,
            borderColor: theme.palette.border.primary.primary300,
        },
        "&:focus-within": {
            outline: "none",
            borderColor: theme.palette.border.primary.primary300,
            borderWidth: 2,
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            outline: "none",
            borderWidth: 0,
            borderColor: theme.palette.border.primary.primary300,
        },
    },
    borderBottom: {
        borderBottom: `1px solid ${theme.palette.border.neutral.neutral100}`,
    }
}));

export interface cinDetails {
    line1: string;
    line2: string;
    pincode: number;
    city: string;
    state: string;
}
export interface cin {
    entityType: string;
    dateOfIncorportation: string;
    cin: any;
    name: string,
    line1: string;
    line2: string;
    pincode: string;
    city: string;
    state: string;
}

interface BussinessCinEditTemplateProps {
    cinformik: any;
    updateBusinessProfileSection: any,
    businessProfile: any,
    onSuccess: () => void;
    onBack: () => void;
}

const BussinessProfileCINEditTemplate: React.FC<BussinessCinEditTemplateProps> = ({ updateBusinessProfileSection, businessProfile, onSuccess, onBack }) => {
    const classes = useStyles();
    const bussinessProfileService = useBusinessProfileService();
    const [cinDetails, setcinDetailsTo] = useState<cinDetails>();
    const { showSnackbar } = useSnackbar();

    const cinSchema = yup.object().shape({
        entityType: yup.string().required(' Entity Type is required'),
        dateOfIncorportation: yup.date().typeError("Enter valid date").required('Date Of Incorporation is required'),
        cin: yup.string().matches(REGEX.CIN, 'Enter Valid CIN').required(' CIN is required'),
        name: yup.string(),
        line1: yup.string().required(''),
        line2: yup.string(),
        pincode: yup.string().required(' pincode is required'),
        city: yup.string().required(' city is required'),
        state: yup.string().required(' state is required'),
    });

    const handleSubmit = () => {
        cinformik.handleSubmit();

    };

    const cinformik = useFormik<cin>({
        initialValues: {
            entityType: businessProfile?.cin?.entityType,
            dateOfIncorportation: businessProfile?.cin?.dateOfIncorportation,
            cin: businessProfile?.cin?.cin,
            name: businessProfile?.name ?? "",
            line1: businessProfile?.cinRegisteredAddress?.line1 ?? "",
            line2: businessProfile?.cinRegisteredAddress?.line2 ?? "",
            pincode: businessProfile?.cinRegisteredAddress?.pincode ?? "",
            city: businessProfile?.cinRegisteredAddress?.city ?? "",
            state: businessProfile?.cinRegisteredAddress?.state ?? "",
        },

        validationSchema: cinSchema,
        onSubmit: async (values: any) => {
            try {
                cinformik.setSubmitting(true);
                const requestBody = {
                    cin: values?.cin,
                    entityType: values?.entityType,
                    dateOfIncorportation: values?.dateOfIncorportation,
                }
                const businessProfile = await bussinessProfileService.updateBusinessProfile(requestBody, "CIN");
                showSnackbar("success", "Business profile updated successfully");
                onBack();
            } catch (error) {
                showSnackbar("error", `Business profile update failed: ${error}`);
            }
        },
    });

    const handleUpdateDetails = (field: string, value: any) => {
        cinformik.setFieldValue(field, value);

    };

    const handleCinChange = (value: string) => {
        getCinDetails(value);

    };

    const getBusinessProfile = async () => {
        try {
            const businessProfileResponse = await bussinessProfileService.getBusinessProfile();
            if (businessProfileResponse.status === HTTP_STATUS.OK) {
                const profileData = businessProfileResponse?.data?.data;
                setcinDetailsTo(profileData);
            }
        } catch (error) {
            showSnackbar('error', 'Business Profile fetch failed');
        }
    };

    const getCinDetails = async (cin: string) => {
        try {
            const payload = {
                "cin": cin
            }
            const cinDetailsResponse = await bussinessProfileService.getCinDetails(payload);
            if (cinDetailsResponse.status === HTTP_STATUS.OK) {
                const details = cinDetailsResponse?.data?.data;
                cinformik.setFieldValue("name", details.name);
                cinformik.setFieldValue("line1", details.line1);
                cinformik.setFieldValue("line2", details.line2);
                cinformik.setFieldValue("pincode", details.pincode);
                cinformik.setFieldValue("city", details.city);
                cinformik.setFieldValue("state", details.state);
                cinformik.setFieldValue("dateOfIncorportation", details?.dateOfIncorporation);

            }
        } catch (error) {
            showSnackbar('error', 'Failed to fetch CIN details');
        }
    };
    
    return (
        <form onSubmit={cinformik.handleSubmit}>
            <div className={`${classes.container} p-4 grid gap-y-6 rounded-xl w-full`}>
                <div className={`flex justify-between items-center pb-6 ${classes.borderBottom}`}>
                    <h2 className={`${classes.title} text-2xl font-medium`}>CIN Details</h2>
                    <div className='flex gap-4'>
                        <Button variant={'tertiaryContained'} label={'Back'} type="button" onClick={onBack} />
                        <Button variant={'primaryContained'} label={'Save'} type="submit" />
                    </div>
                </div>
                <div className='flex flex-col gap-y-6 w-1/2'>
                    <SelectV2
                        label='Entity Type'
                        fullWidth
                        {...cinformik.getFieldProps("entityType")}
                        error={
                            cinformik.touched.entityType &&
                            Boolean(cinformik.errors?.entityType)
                        }
                        options={
                            ENTITY_TYPES?.map((entityType) => ({
                                value: String(entityType.id),
                                label: entityType.name,
                            })) || []
                        }
                    />
                    <div className='grid gap-y-1'>
                        <div className={`${classes.card_content} text-base`}>Date Of Incorporation</div>
                        <div className='grid'>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DatePicker
                                    value={cinformik?.values?.dateOfIncorportation ? moment(cinformik?.values?.dateOfIncorportation) : null}
                                    format="DD/MM/YYYY"
                                    onChange={(newValue) => {
                                        const formattedDate = moment(newValue).format('YYYY-MM-DD');
                                        cinformik.setFieldValue('dateOfIncorportation', formattedDate);
                                    }}
                                    disabled
                                    className={classes.input}
                                />
                            </LocalizationProvider>
                            {cinformik?.errors?.dateOfIncorportation && cinformik?.touched?.dateOfIncorportation && (
                                <div>
                                    {cinformik.errors.dateOfIncorportation}
                                </div>
                            )}
                        </div>
                    </div>

                    <div className={`flex gap-x-3 w-full`}>
                        <div className='flex-1'>
                            <TextFieldV2
                                label="Enter the CIN number to fill out the details below"
                                fullWidth
                                {...cinformik.getFieldProps("cin")}
                                error={cinformik.touched.cin && Boolean(cinformik.errors.cin)}
                                required
                                helperText={cinformik.touched.cin && cinformik.errors.cin ? String(cinformik.errors.cin) : ''}
                                inputProps={{ readOnly: false }}
                            />
                        </div>
                        <div className={`${classes.search_container}`} onClick={() => handleCinChange(cinformik?.values?.cin)}>
                            <img src={whiteSearchIcon} alt="whiteSearchIcon" />
                        </div>
                    </div>
                </div>
                <div className={`${classes.primary_container} grid gap-y-6 w-full p-4 rounded-xl`}>
                    <p className={`${classes.container_heading} font-semibold text-base`}>Registered Address (as per ROC)</p>
                    <div className='grid gap-y-6'>
                        <TextFieldV2
                            label="Comapany Name"
                            fullWidth
                            {...cinformik.getFieldProps("name")}
                            error={
                                cinformik.touched.name &&
                                Boolean(cinformik.errors?.name)
                            }
                            required
                            helperText={(cinformik.touched?.name &&
                                Boolean(cinformik.errors?.name)) && cinformik.errors?.name}
                            inputProps={{ readOnly: true }}
                        />
                        <TextFieldV2
                            label="Address Line 1"
                            fullWidth
                            {...cinformik.getFieldProps("line1")}
                            error={
                                cinformik.touched.line1 &&
                                Boolean(cinformik.errors.line1)
                            }
                            required
                            helperText={(cinformik.touched.cin &&
                                Boolean(cinformik.errors?.line1)) && cinformik.errors.line1}
                            inputProps={{ readOnly: true }}
                        />
                        <TextFieldV2
                            label="Address Line 2"
                            fullWidth
                            {...cinformik.getFieldProps("line2")}
                            error={
                                cinformik.touched.line2 &&
                                Boolean(cinformik.errors.line2)
                            }
                            required
                            helperText={(cinformik.touched.cin &&
                                Boolean(cinformik.errors.line2)) && cinformik.errors.line2}
                            inputProps={{ readOnly: true }}
                        />
                        <div className='grid grid-cols-3 gap-x-6'>
                            <TextFieldV2
                                label="Pincode"
                                fullWidth
                                {...cinformik.getFieldProps("pincode")}
                                error={
                                    cinformik.touched.pincode &&
                                    Boolean(cinformik.errors.pincode)
                                }
                                required
                                helperText={(cinformik.touched?.pincode &&
                                    Boolean(cinformik.errors?.pincode)) && cinformik.errors.pincode}
                                inputProps={{ readOnly: true }}
                            />
                            <TextFieldV2
                                label="City"
                                fullWidth
                                {...cinformik.getFieldProps("city")}
                                error={
                                    cinformik.touched?.city &&
                                    Boolean(cinformik.errors?.city)
                                }
                                required
                                helperText={(cinformik.touched?.city &&
                                    Boolean(cinformik.errors?.city)) && cinformik.errors?.city}
                                inputProps={{ readOnly: true }}
                            />
                            <TextFieldV2
                                label="State"
                                fullWidth
                                {...cinformik.getFieldProps("state")}
                                error={
                                    cinformik.touched?.state &&
                                    Boolean(cinformik.errors?.state)
                                }
                                required
                                helperText={(cinformik.touched?.state &&
                                    Boolean(cinformik.errors?.state)) && cinformik.errors?.state}
                                inputProps={{ readOnly: true }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
};

export default BussinessProfileCINEditTemplate;
