import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { createUseStyles } from 'react-jss';
import grid from "../../../assets/icons/grid.svg"
import list from '../../../assets/icons/list.svg';
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import CatalogueListCardTemplate from '../../template/BrandComparison/CatalogueListCard.template';
import Breadcrumbs from '../../atoms/BreadCrumbs/BreadCrumbs';
import CatalogueGridCardTemplate from '../../template/BrandComparison/CatalogueGridCard.template';
import Button from '../../atoms/Button/Button';
import FilterChip from '../../atoms/FilterChipV4/FilterChip';
import { CATEGORY_TYPE, HTTP_STATUS, IPagination, VAS_MASTER_CATEGORY_TYPE } from '../../../utils/types';
import { IBrand, ICategory, useAdminMasterService } from '../../../services/useAdminMasterService';
import { useFormik } from 'formik';
import { IMultiSelectOption } from '../../atoms/MultiSelectInput/MultiSelectInput2';
import { IWarehouse, useWarehouseService } from '../../../services/useWarehouseService';
import SortButton from '../../template/BrandComparison/SortButton.template';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { IBrandComparison, IBrandComparisonRequestBody, ISeller, useBrandComparisonService } from '../../../services/useBrandComparisonService';
import { useSnackbar } from '../../../hooks/useSnackBar';
import MoreFiltersTemplate from '../../template/BrandComparison/MoreFilters.template';
import { IGrade, IStandards } from '../../template/Rfq/ProductRequirementForm.template';
import { sortByRank, useDebounce } from '../../../utils/helper';
import { DELIVERY_INFORMATION, PACKAGING_TYPES, SHAPE_TYPES } from '../../../utils/constant';
import { Service, useVASMasterCategoryService } from '../../../services/useVASMasterCategoryService';
import standardIcon from '../../../assets/icons/standardIcon.svg';
import specificationIcon from '../../../assets/icons/specificationIcon.svg';
import stockAvalibilityIcon from '../../../assets/icons/stockAvalibilityIcon.svg';
import customizationIcon from '../../../assets/icons/customizationIcon.svg';
import sellerPreferenceIcon from '../../../assets/icons/sellerPreferenceIcon.svg';
import priceAndDiscountIcon from '../../../assets/icons/priceAndDiscountIcon.svg';
import logistics from '../../../assets/icons/logistics.svg';
import orderAndPackagingIIcon from '../../../assets/icons/orderAndPackagingIIcon.svg';
import NoProduct from '../../molecules/NoProduct/NoProduct';
import CatalogueGridItemSkeleton from '../../template/CatalogueList/CatalogueGridItemSkeleton.template';
import CatalogueListCardSkeleton from '../../template/BrandComparison/CatalogueListCardSkeleton';
import CatalogueGridCardSkeleton from '../../template/BrandComparison/CatalogueGridCardSkeleton';
import { TablePagination, Input } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import brandComparisionBanner from '../../../assets/images/brandComparisonBanner.jpg';
import LastPageIcon from '@mui/icons-material/LastPage';
import { TablePaginationActionsProps } from '../../template/CatalogueList/CatalogueList.template';
import { useTheme } from '@mui/material/styles';

const useStyles = createUseStyles((theme: any) => ({
    headingMain: {
        color: theme.palette.text.neutral.neutral800,
    },
    container: {
        backgroundColor: theme.palette.background.primary.primary50,
    },
    banner: {
        backgroundImage: `url(${brandComparisionBanner})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        height: '375px',
        "& h1": {
            fontSize: '52px',
            lineHeight: '38px',
            color: theme.palette.text.neutral.neutral900,
            fontFamily: 'Abril Fatface !important   ',
            fontWeight: '400',
            marginBottom: '20px',
            marginTop: '50px',
        },
        "& p": {
            fontSize: '32px',
            fontWeight:"274",
            lineHeight: '44px',
            color: theme.palette.text.neutral.neutral900,
        },
    },
    showOfferButton: {
        borderRadius: "0px 0px 12px 12px",
        backgroundColor: theme.palette.background.primary.primary50,
        color: theme.palette.text.primary.primary500,
    },
    toggleContainer: {
        backgroundColor: theme.palette.background.primary.primary50,
    },
    boxShadow: {
        boxShadow: "0px 2px 4.8px 0px rgba(77, 77, 77, 0.25)",
        backgroundColor: "#fff"
    },
    selectedValue: {
        color: theme.palette.text.neutral.neutral800,
        lineHeight: "22px"
    },
    unselectedValue: {
        color: theme.palette.text.neutral.neutral700,
        lineHeight: "22px"
    },
    border: {
        border: `2px solid ${theme.palette.border.primary.primary500}`,
    },

    paginationTable: {
        "& .MuiTablePagination-selectLabel": {
            color: "#4A4D4F",
            fontWeight: 500,
            fontSize: "14px",
            lineHeight: "20.3px",
            letterSpacing: "0.21px"
        },
        "& .MuiTablePagination-input": {
            borderRadius: '8px',
            border: `1px solid ${theme.palette.border.neutral.neutral200}`,
            marginRight: "16px",
            marginLeft: "16px",
            fontSize: "12px",
            padding: "4px 0px 3px 0px",
            color: theme.palette.text.neutral.neutral800,
        },
        "& .MuiTablePagination-displayedRows": {
            color: "#4A4D4F",
            fontWeight: 500,
            fontSize: "14px",
            lineHeight: "20.3px",
            letterSpacing: "0.21px",
        },
        "& .MuiTablePagination-spacer": {
            flex: 0
        },
        "& .MuiToolbar-root": {
            paddingLeft: "0px !important",
            paddingRight: "0px",
            width: "100%"
        },
    },
    paginationComponent: {
        color: theme.palette.text.neutral.neutral700,
        fontWeight: 500,
        fontSize: "14px",
        width: "100%",
        justifyContent: 'end',
    },
    pageButton: {
    },
 
    filterMain: {
        marginTop: '-102px',
        background: 'white',
        width: '1182px',
        margin: '0 auto',
        boxShadow: '0px 146px 41px 0px rgba(199, 199, 199, 0.00), 0px 94px 37px 0px rgba(199, 199, 199, 0.01), 0px 53px 32px 0px rgba(199, 199, 199, 0.05), 0px 23px 23px 0px rgba(199, 199, 199, 0.09), 0px 6px 13px 0px rgba(199, 199, 199, 0.10)',
        borderRadius: '24px',
    },
    "@media (max-width: 767px)": {
        paginationTable: {
            width: '100%',
            display: 'flex',
            '& .MuiTablePagination-selectLabel': {
                display: 'none',
            },
            "& .MuiTablePagination-displayedRows": {
                display: 'none',
            },
            "& .MuiTablePagination-input": {
                display: 'none',
            },
        },
        paginationComponent: {
            color: theme.palette.text.neutral.neutral700,
            fontWeight: 500,
            fontSize: "14px",
            width: "100%",
            justifyContent: 'space-between'
        },
        pageButton: {
            columnGap: "12px",
            "& .MuiButtonBase-root": {
                padding: "0px"
            }
        }
    },
    "@media (min-width: 1550px)": { 
        banner: {
            height: '420px',
        },
    },
}));

const BrandComparisonPage: React.FC = () => {
    const classes = useStyles();
    const { showSnackbar } = useSnackbar();
    const adminMasterService = useAdminMasterService();
    const warehouseService = useWarehouseService();
    const brandComparisonService = useBrandComparisonService();
    const vasMasterCategoryService = useVASMasterCategoryService();

    const [superCategoryData, setSuperCategoryData] = useState<ICategory[] | null>([]);
    const [mainCategoryData, setMainCategoryData] = useState<ICategory[] | null>([]);
    const [productCategoryData, setProductCategoryData] = useState<ICategory[] | null>([]);
    const [brandData, setBrandData] = useState<IBrand[] | null>([]);
    const [warehouseData, setWarehouseData] = useState<IWarehouse[] | null>([]);
    const [standardData, setStandardData] = useState<IStandards[] | null>([]);
    const [gradeData, setGradeData] = useState<IGrade[] | null>([]);
    const [productServiceData, setProductServiceData] = useState<Service[] | null>(null);

    const [loading, setLoading] = useState<boolean>(true);
    const navigate = useNavigate();
    const location = useLocation(); 
    const [prevParams, setPrevParams] = useState("");

    const createOptions = (data: any[] | null, keyName: string = "name") => {
        return data?.map((item: any) => ({ label: item[keyName], value: item.id })) ?? [];
    };

    const createOptions2 = (data: any[] | null, keyName: string = "name") => {
        if (!data) return [];
        const groupedMap = new Map<string, { label: string; value: string }>();
        data.forEach((item) => {
            const key = item[keyName];
            if (groupedMap.has(key)) {
                groupedMap.set(key, {
                    label: key,
                    value: groupedMap.get(key)!.value + "," + item.id,
                });
            } else {
                groupedMap.set(key, { label: key, value: String(item.id) });
            }
        });
        return Array.from(groupedMap.values());
    };

    const superCategoryOptions = useMemo(() => createOptions(superCategoryData), [superCategoryData]);
    const mainCategoryOptions = useMemo(() => createOptions(mainCategoryData), [mainCategoryData]);
    const productCategoryOptions = useMemo(() => createOptions(productCategoryData), [productCategoryData]);
    const brandOptions = useMemo(() => createOptions(brandData), [brandData]);
    const warehouseOptions = useMemo(() => createOptions2(warehouseData), [warehouseData]);
    const standardOptions = useMemo(() => createOptions(standardData), [standardData]);
    const gradeOptions = useMemo(() => createOptions(gradeData), [gradeData]);
    const shapeOptions = useMemo(() => createOptions(SHAPE_TYPES), [SHAPE_TYPES]);
    const productServiceOptions = useMemo(() => createOptions(productServiceData), [productServiceData])


    const [searchParams, setSearchParams] = useSearchParams();

    const superCategoryString = searchParams.get("superCategory");
    const mainCategoryString = searchParams.get("mainCategory");
    const productCategoryString = searchParams.get("productCategory");
    const brandString = searchParams.get("brand");

    const superCategory = superCategoryString ? JSON.parse(superCategoryString) : null;
    const mainCategory = mainCategoryString ? JSON.parse(mainCategoryString) : null;
    const productCategory = productCategoryString ? JSON.parse(productCategoryString) : null;
    const brand = brandString ? JSON.parse(brandString) : null;

    const initialFormikValue = {
        superCategory: superCategory ?? { label: "", value: "" },
        mainCategoryArray: mainCategory ?? [],
        productCategoryArray: productCategory ?? [],
        brandArray: brand ?? [],
        warehouseArray: [],
        sortBy: "",
        standardArray: [],
        gradeArray: [],
        shapeArray: [],
        customizationArray: [],
        certificationStandardArray: [],
        deliveryTimeArray: [],
        packagingTypeArray: [],
        priceRange: { minValue: "", maxValue: "" },
        minimumOrderQuantity: { minValue: "", maxValue: "" },
        thickness: { minValue: "", maxValue: "" },
        width: { minValue: "", maxValue: "" },
    }

    const [expandedCatalogues, setExpandedCatalogues] = useState<Record<number, boolean>>({});

    const [selectedNumber, setSelectedNumber] = useState<number>(0);
    const [isFilterApply, setIsFilterApply] = useState<boolean>(false);

    const handleClick = (id: number, index: number) => {
        setExpandedCatalogues(prevState => ({
            [id]: !prevState[id]
        }));
        setSelectedNumber((index % 4));
    };

    const [viewMode, setViewMode] = useState<'GRID' | 'LIST'>('LIST');

    const sortOptions = ["Ascending", "Descending"];

    const formik = useFormik<any>({
        initialValues: initialFormikValue,
        onSubmit: async (values, { setSubmitting }) => {
            setSubmitting(true);

        }
    });

    const loadSuperCategory = (inputValue?: string) => {
        adminMasterService.getAllCategories({ level: CATEGORY_TYPE.SUPER_CATEGORY, page: 0, size: 10, sort: 'created_at,desc', parentId: '', name: inputValue ?? '', status: 'ACTIVE' })
            .then(res => {
                if (res.status === HTTP_STATUS.OK)
                    setSuperCategoryData(res?.data?.data?.content)
            }).catch((error) => {
                console.error("Error Fetching Super Category: ", error);
            })
    }

    const loadMainCategory = (inputValue?: string) => {

        if (formik.values.superCategory?.value) {
            adminMasterService.getAllCategories({ level: CATEGORY_TYPE.MAIN_CATEGORY, page: 0, size: 10, sort: 'created_at,desc', parentId: formik.values.superCategory?.value, name: inputValue ?? '', status: 'ACTIVE' })
                .then(res => {
                    if (res.status === HTTP_STATUS.OK)
                        setMainCategoryData(res?.data?.data?.content)
                }).catch((error) => {
                    console.error("Error Fetching Main Category: ", error);
                })
        }
    }

    const loadProductCategory = async (inputValue?: string) => {
        let responseCategoryArray: any = [];
        for (let mainCategory of formik.values.mainCategoryArray) {
            try {
                let res = await adminMasterService.getAllCategories({ level: CATEGORY_TYPE.PRODUCT_CATEGORY, page: 0, size: 10, sort: 'created_at,desc', parentId: mainCategory?.value, name: inputValue ?? '', status: 'ACTIVE' })
                if (res.status === HTTP_STATUS.OK) {
                    let data = await res?.data?.data?.content;
                    responseCategoryArray.push(...data)
                }
            } catch (error) {
                console.error("Error Fetching Product Category: ", error);
            };
        }
        setProductCategoryData(responseCategoryArray);
        formik.setFieldValue("brandArray", []);
    }

    const loadBrands = (inputValue?: string) => {
        if(formik.values.productCategoryArray.length === 0) return
        adminMasterService.getAllBrandsByName({ page: 0, size: 10, sort: 'createdAt,desc', name: inputValue ?? '', productCategoryIds: formik.values.productCategoryArray.length > 0 ? formik.values.productCategoryArray.map((category: any) => category.value).join(",") : "" })
            .then(res => {
                if (res.status === HTTP_STATUS.OK)
                    setBrandData(res?.data?.data?.content)
                formik.setFieldValue("brandArray", []);
            }).catch((error) => {
                console.error("Error Fetching Brands: ", error);
            })
    }

    const loadWarehouses = (inputValue?: string) => {
        warehouseService.getWarehouses({ page: 0, size: 10, sort: 'createdAt,desc', search: inputValue ?? "" })
            .then(res => {
                if (res.status === HTTP_STATUS.OK)
                    setWarehouseData(res?.data?.data?.content)
            }).catch((error) => {
                console.error("Error Fetching Warehouses: ", error);
            })
    }

    const loadStandard = (inputValue?: string) => {
        adminMasterService.getAllStandards({ page: 0, size: 10, sort: 'createdAt,desc', search: inputValue ?? "" })
            .then(res => {
                if (res?.status === HTTP_STATUS.OK)
                    setStandardData(sortByRank(res?.data?.data?.content))
            }).catch((error) => {
                console.error("Error Fetching Standards: ", error);
            })
    }

    const loadGrade = (inputValue?: string) => {
        adminMasterService.getAllQualityGrades({ search: inputValue ?? '', type: 'GRADE', page: 0, size: 10, sort: 'createdAt,desc' })
            .then(res => {
                if (res?.status === HTTP_STATUS.OK)
                    setGradeData(sortByRank(res?.data?.data?.content))
            }).catch((error) => {
                console.error("Error Fetching Grades: ", error);
            })
    }

    const fetchProductServices = (inputValue?: string) => {
        vasMasterCategoryService.getAllService({ level: VAS_MASTER_CATEGORY_TYPE.SERVICE_CATEGORY, page: 0, size: 10, sort: 'createdAt,desc', name: inputValue ?? '', status: 'ACTIVE' }, {})
            .then(res => {
                if (res.status === HTTP_STATUS.OK)
                    setProductServiceData(res?.data?.data?.content)
            }).catch((error) => {
                console.error("Error Fetching Service: ", error);
                showSnackbar('error', "Error while fetching Service Category Details");
            })
    }

    useEffect(() => {
        loadSuperCategory();
        loadWarehouses();
        loadStandard();
        loadGrade();
        fetchProductServices();
    }, []);

    useEffect(() => {
        loadMainCategory();
    }, [formik.values.superCategory?.value]);

    useEffect(() => {
        loadProductCategory();
    }, [formik.values.mainCategoryArray]);

    useEffect(() => {
        loadBrands();
    }, [formik.values.productCategoryArray]);

    const handleToggleClick = (item: any) => {
        formik.setValues({
            ...formik.values,
            superCategory: item,
            mainCategoryArray: [],
            productCategoryArray: [],
            brandArray: []
        });
    };

    const handleSubmit = (event?: React.FormEvent) => {
        event?.preventDefault();
        formik.handleSubmit();
    };

    const updateSelected = (key: string) => (option: IMultiSelectOption[] | null) => {
        if (option) {
            formik.setFieldValue(`${key}Array`, option);
            handleSubmit();
        }
    };

    const handleClearClick = (key: string) => {
        formik.setFieldValue(`${key}Array`, []);
        handleSubmit();
    };

    const debouncedNavigate = useCallback(
        useDebounce((searchString: string) => {
            if (searchString !== location.search.substring(1)) {
                navigate(`?${searchString}`, { replace: true });
            }
        }, 300),
        [navigate, location]
    );

    useEffect(() => {
        const { superCategory, mainCategoryArray, productCategoryArray, brandArray } = formik.values;
        const updatedParams: Record<string, string> = {};

        if (superCategory?.value) updatedParams.superCategory = JSON.stringify(superCategory);
        if (Array.isArray(mainCategoryArray) && mainCategoryArray.length > 0)
            updatedParams.mainCategory = JSON.stringify(mainCategoryArray);
        if (Array.isArray(productCategoryArray) && productCategoryArray.length > 0)
            updatedParams.productCategory = JSON.stringify(productCategoryArray);
        if (Array.isArray(brandArray) && brandArray.length > 0)
            updatedParams.brand = JSON.stringify(brandArray);

        const searchParams = new URLSearchParams();
        Object.entries(updatedParams).forEach(([key, value]) => {
            if (value && value !== "[]" && value !== "{}") {
                searchParams.append(key, value);
            }
        });

        const searchString = searchParams.toString();

        if (searchString !== prevParams) {
            setPrevParams(searchString);
            debouncedNavigate(searchString);
        }
    }, [formik.values, navigate, debouncedNavigate, location]);

    const [brandComparison, setBrandComparison] = useState<IBrandComparison[]>([])
    const [pagination, setPaginationTo] = useState<IPagination>({
        size: 12,
        totalRecords: 0,
        page: 0,
    });

    const refreshBrandComparison = async (page: number, size: number) => {
        setLoading(true);
        const payload: IBrandComparisonRequestBody = {
            superCategoryId: formik.values?.superCategory?.value,
            mainCategoryIds: formik.values?.mainCategoryArray?.length > 0 ? formik.values.mainCategoryArray.map((category: any) => category.value) : null,
            productCategoryIds: formik.values?.productCategoryArray?.length > 0 ? formik.values.productCategoryArray.map((category: any) => category.value) : null,
            brandIds: formik.values?.brandArray?.length > 0 ? formik.values.brandArray.map((warehouse: any) => warehouse.value) : null,
            locationIds: formik.values?.warehouseArray?.length > 0 ? formik.values.warehouseArray.flatMap((warehouse: any) => warehouse.value.split(",").map(Number)) : null,
            standardIds: formik.values?.standardArray?.length > 0 ? formik.values.standardArray.map((standard: any) => standard.value) : null,
            gradeIds: formik.values?.gradeArray?.length > 0 ? formik.values.gradeArray.map((grade: any) => grade.value) : null,
            shapes: formik.values?.shapeArray?.length > 0 ? formik.values.shapeArray.map((shape: any) => shape.value) : null,
            stocksAvailablity: formik.values?.stocksAvailablityArray?.length > 0 ? formik.values.stocksAvailablityArray.map((stocksAvailablity: any) => stocksAvailablity.value) : null,
            customisations: formik.values?.customizationsArray?.length > 0 ? formik.values.customizationsArray.map((customization: any) => customization.value) : null,
            milltest: formik.values?.certificationStandardArray?.length > 0 ? true : null,
            deliveryTime: formik.values?.deliveryTimeArray?.length > 0 ? formik.values.deliveryTimeArray.map((deliveryTime: any) => deliveryTime.value) : null,
            packagingTypes: formik.values?.packagingTypeArray?.length > 0 ? formik.values.packagingTypeArray.map((packaging: any) => packaging.value) : null,
            priceRange: formik.values?.priceRange?.minValue && formik.values?.priceRange?.maxValue
                ? {
                    min: Number(formik.values.priceRange.minValue),
                    max: Number(formik.values.priceRange.maxValue)
                }
                : {},
            minimumOrderQuantity: formik.values?.minimumOrderQuantity?.minValue && formik.values?.minimumOrderQuantity?.maxValue
                ? {
                    min: Number(formik.values.minimumOrderQuantity.minValue),
                    max: Number(formik.values.minimumOrderQuantity.maxValue)
                }
                : {},
            attributes: (() => {
                const attributes: Record<string, any> = {};
                if (formik.values?.thickness?.minValue || formik.values?.thickness?.maxValue) {
                    attributes["Thickness"] = {
                        minValue: (formik.values.thickness.minValue.length > 0 ? formik.values.thickness.minValue : null),
                        maxValue: formik.values.thickness.maxValue.length > 0 ? formik.values.thickness.maxValue : null,
                        values: null
                    };
                }
                if (formik.values?.width?.minValue || formik.values?.width?.maxValue) {
                    attributes["Width"] = {
                        minValue: formik.values.width.minValue.length > 0 ? formik.values.width.minValue : null,
                        maxValue: formik.values.width.maxValue.length > 0 ? formik.values.width.maxValue : null,
                        values: null
                    };
                }
                return attributes;
            })(),
            sortBy: formik?.values?.sortBy == "Ascending" ? "ascending" : "descending"
        };
        await brandComparisonService.getBrandComparison({
            page: pagination.page,
            size: pagination.size,
        }, payload)
            .then(res => {
                if (res.status === HTTP_STATUS.OK) {
                    setBrandComparison(res?.data?.data?.content)
                    setPaginationTo((prevPagination: IPagination) => ({
                        ...prevPagination,
                        totalRecords: res?.data?.data?.totalElements,
                    }));
                    setLoading(false);
                }
            }).catch(error => {
                showSnackbar('error', error);
                setBrandComparison([]);
                setPaginationTo({
                    size: 12,
                    totalRecords: 0,
                    page: 0,
                })
                setLoading(false);
            })
    }

    useEffect(() => {
        refreshBrandComparison(pagination.page, pagination.size,)
    }, [formik.values.superCategory, formik.values.mainCategoryArray , formik.values.brandArray, formik.values.warehouseArray, formik.values.productCategoryArray, pagination.page, pagination.size, isFilterApply])

    const filterData = {
        "Standard": {
            image: standardIcon,
            options: {
                "Standard": standardOptions,
                "Grade": gradeOptions
            }
        },
        "Specification": {
            image: specificationIcon,
            options: {
                "Shape": shapeOptions,
                "Thickness": { minValue: null, maxValue: null },
                "Width": { minValue: null, maxValue: null },
            }
        },
        // "Stock Availability": {
        //     image: stockAvalibilityIcon,
        //     options: {
        //         "Stock Availability": []
        //     }
        // },
        "Customisation Options": {
            image: customizationIcon,
            options: {
                "Customisations": productServiceOptions
            }
        },
        "Seller Preference": {
            image: sellerPreferenceIcon,
            options: {
                // "Seller Rating": [],
                "Certification/Standard": [
                    { label: "MILTEST Certificate", value: 1 }
                ]
            }
        },
        "Pricing and Discount": {
            image: priceAndDiscountIcon,
            options: {
                "Price Range": { minValue: null, maxValue: null },
                // "Discount and Offers": []
            }
        },
        "Logistics": {
            image: logistics,
            options: {
                "Delivery Time": DELIVERY_INFORMATION
            }
        },
        "Order and Packaging": {
            image: orderAndPackagingIIcon,
            options: {
                "Minimum Order Quantity": { minValue: null, maxValue: null },
                "Packaging Type": PACKAGING_TYPES
            }
        }
    };


    const grandParent = document.getElementById("grandParent");
    const [childWidth, setChildWidth] = useState<number>(1280)

    useEffect(() => {
        if (grandParent)
            setChildWidth(parseFloat(window.getComputedStyle(grandParent).width))
    }, [expandedCatalogues])

    const parent = document.getElementById("parent");
    const [parentWidth, setParentWidth] = useState<number>(302)

    useEffect(() => {
        if (parent)
            setParentWidth(parseFloat(window.getComputedStyle(parent).width))
    }, [expandedCatalogues])

    const nullData = [null, null, null, null]

    function TablePaginationActions(props: TablePaginationActionsProps) {
        const theme = useTheme();
        const { count, page, rowsPerPage, onPageChange } = props;
        const [inputPage, setInputPage] = useState(page + 1);

        const handleFirstPageButtonClick = (
            event: React.MouseEvent<HTMLButtonElement>,
        ) => {
            onPageChange(event, 0);
        };

        const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
            onPageChange(event, page - 1);
        };

        const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
            onPageChange(event, page + 1);
        };

        const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
            onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
        };

        const handleInputPageChange = (event: any) => {
            setInputPage(parseInt(event.target.value, 10));

        };
        const handleInputBlur = (event: any) => {
            onPageChange(event, inputPage - 1);
        };

        return (
            <div className={`flex gap-x-6 ${classes.paginationComponent}`}  >
                <div className="flex gap-x-2.5">
                    <div className='my-auto'>Page</div>
                    <div className='my-auto'>
                        <Input
                            type="number"
                            value={inputPage}
                            onChange={handleInputPageChange}
                            onBlur={handleInputBlur}
                            disableUnderline={true}
                            inputProps={{ min: 1, max: Math.ceil(count / rowsPerPage) }}
                            style={{ width: '54px', height: "28px", borderRadius: '8px', border: '1px solid #E6E6E6', paddingLeft: '16px' }}
                        />
                    </div>
                    <div className='my-auto'>of {Math.ceil(count / rowsPerPage)}</div>
                </div>

                <div className={`${classes.pageButton} flex`}>
                    <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
                        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
                    </IconButton>
                    <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                    </IconButton>
                    <IconButton onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="next page">
                        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                    </IconButton>
                    <IconButton onClick={handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="last page">
                        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
                    </IconButton>
                </div>
            </div>
        );
    }

    const onPaginationChange = (_: any, page: number) => {
        setPaginationTo({
            ...pagination,
            page: page
        })
    }

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setPaginationTo({
            ...pagination,
            size: parseInt(event.target.value, 10)
        })
    };

    useEffect(() => {
        setPaginationTo({
            size: 12,
            totalRecords: 0,
            page: 0,
        })
    }, [formik.values])

    return (
        <div className={` mx-auto grid gap-y-6`}>
            <div className={`${classes.banner}`}>
                <div className='container mx-auto'>
                <Breadcrumbs />
                    <h1>GetMySteel</h1>
                    <p>Search, Compare and Find the Best Steel, <br />Brands & Sellers</p>
                </div>

            </div>
            <div className={`${classes.filterMain} flex flex-col gap-8 py-7 px-6`}>
            <div className={`flex gap-x-3`}>
                {superCategoryOptions?.map((item: any) => (
                    <div key={item.name} className={`grid justify-center items-center ${formik.values?.superCategory?.value === item?.value ? `${classes.selectedValue} font-semibold text-lg cursor-pointer` : `${classes.unselectedValue} font-normal text-lg cursor-pointer`}`} onClick={() => handleToggleClick(item)}>
                        <div className='pb-3'>{item.label}</div>
                        <div className={`w-4/5 rounded-[100px] mx-auto ${formik.values?.superCategory?.value === item?.value ? classes.border : ""}`}></div>
                    </div>
                ))}
            </div>

            <div className='flex gap-x-6'>
            <div className='!w-[250px]'>
                <FilterChip
                    options={mainCategoryOptions}
                    label="Main Category"
                    value={formik.values.mainCategoryArray}
                    onchange={updateSelected('mainCategory')}
                    placeholder="Main Category"
                    ButtonComponent={Button}
                    buttonLabel="Clear All"
                    buttonOnClick={() => handleClearClick('mainCategory')}
                    textFieldPlaceholder='Search...'
                    setSearchTerm={loadMainCategory}
                    optionToShow={1}
                    isSingleSelect={true}
                />
                  </div>
                  <div className='!w-[270px]'>
                <FilterChip
                    options={productCategoryOptions}
                    label="Product Category"
                    value={formik.values.productCategoryArray}
                    onchange={updateSelected('productCategory')}
                    placeholder="Product Category"
                    ButtonComponent={Button}
                    buttonLabel="Clear All"
                    buttonOnClick={() => handleClearClick('productCategory')}
                    textFieldPlaceholder='Search...'
                    setSearchTerm={loadProductCategory}
                    optionToShow={1}
                />
                  </div>
                  <div className='!w-[200px]'>
                <FilterChip
                    options={brandOptions}
                    label="Brand"
                    value={formik.values.brandArray}
                    onchange={updateSelected('brand')}
                    placeholder="Brand"
                    ButtonComponent={Button}
                    buttonLabel="Clear All"
                    buttonOnClick={() => handleClearClick('brand')}
                    textFieldPlaceholder='Search...'
                    setSearchTerm={loadBrands}
                    optionToShow={1}
                />
                </div>
                <div className='!w-[168px]'>
                <FilterChip
                    options={warehouseOptions}
                    label="Location"
                    value={formik.values.warehouseArray}
                    onchange={updateSelected('warehouse')}
                    placeholder="Location"
                    ButtonComponent={Button}
                    buttonLabel="Clear All"
                    buttonOnClick={() => handleClearClick('warehouse')}
                    textFieldPlaceholder='Search...'
                    setSearchTerm={loadWarehouses}
                    optionToShow={1}
                />
                </div>
                <div className='flex content-end self-end pb-1'>
                    <MoreFiltersTemplate filterData={filterData} formik={formik} data={brandComparison} setIsFilterApply={setIsFilterApply} isFilterApply={isFilterApply}/>
                </div>
            </div>
            </div>

            <div className='flex justify-end gap-x-6 container mx-auto'>
                <SortButton options={sortOptions} formik={formik} />
                <div className={`${classes.toggleContainer} flex justify-between gap-x-1 p-0.5 rounded-lg`}>
                    <div className={`flex justify-center content-center w-10 h-8 py-2 px-3 cursor-pointer rounded-lg ${viewMode === 'GRID' ? classes.boxShadow : ''}`} onClick={() => setViewMode('GRID')}>
                        <img src={grid} alt="" />
                    </div>
                    <div className={`flex justify-center content-center w-10 h-8 py-2 px-3 cursor-pointer rounded-lg ${viewMode === 'LIST' ? classes.boxShadow : ''}`} onClick={() => setViewMode('LIST')}>
                        <img src={list} alt="" />
                    </div>
                </div>
            </div>
        
            {loading ? (
                <>
                    {viewMode === "LIST" ?
                        (<div className='grid gap-4 w-full'>
                            {nullData.map((item: any, index) => (
                                <div key={index}>
                                    <CatalogueListCardSkeleton />
                                </div>
                            ))}
                        </div>)
                        :
                        (
                            <div className='grid gap-6 xl:grid-cols-4 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 w-full'>
                                {nullData.map((item: any, index) => (
                                    <div key={index}>
                                        <CatalogueGridCardSkeleton />
                                    </div>
                                ))}
                            </div>
                        )
                    }
                </>
            ) : brandComparison.length > 0 ? (
                <>
                <div className='container mx-auto'>

              
                    <div id="grandParent" className={`${viewMode === "LIST" ? "grid gap-y-6" : "grid gap-6 xl:grid-cols-4 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2"}`}>
                        {brandComparison?.map((item: IBrandComparison, index: number) => {
                            const isExpanded = expandedCatalogues[item.id] || false;
                            return (
                                <div id="parent" className={` ${isExpanded ? `${classes.container} ` : ""} ${viewMode === "GRID" && isExpanded && "border-[6px] border-[#EAEEFA]"}  rounded-xl`}>
                                    {viewMode === "LIST" ? <CatalogueListCardTemplate key={index} item={item} seller={item.seller[0]} /> : <CatalogueGridCardTemplate key={item.id} item={item} seller={item.seller[0]} />}
                                    <div className={`flex justify-end`}>
                                        <div className={`${classes.showOfferButton} gap-x-2 cursor-pointer ${item?.seller?.length === 1 && "hidden"}  ${viewMode === "LIST" ? "w-[258px]" : "w-full"}`} onClick={() => handleClick(item.id, index)} >
                                            {isExpanded ?
                                                <div className={`flex justify-between  py-2 px-3`}>
                                                    <div className={`text-sm font-medium leading-4 my-auto`}>Hide Offers</div>
                                                    <ArrowDropDown />
                                                </div>
                                                :
                                                <div className={`flex justify-between py-2 px-3`}>
                                                    <div className={`text-sm font-medium leading-4 my-auto`}>{item?.seller?.length - 1}+ offers from other Sellers</div>
                                                    <ArrowDropUp />
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    {isExpanded && (
                                        <div className={` ${viewMode === "LIST" ? "grid gap-y-3 p-10 pt-6 w-full" : `grid gap-6 xl:grid-cols-4 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 p-3 bg-[#EAEEFA] mt-4 relative`} `} style={viewMode === "GRID" ? { width: `${childWidth}px`, left: `-${(parentWidth + 24) * selectedNumber}px` } : undefined}>
                                            {item.seller.slice(1).map((seller: ISeller) => (
                                                <>
                                                    {viewMode === "LIST" ? <CatalogueListCardTemplate key={seller.id} item={item} seller={seller} /> : <CatalogueGridCardTemplate key={seller.id} item={item} seller={seller} />}
                                                </>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            )
                        })}
                    </div>
                    <TablePagination
                        className={`${classes.paginationTable} w-full flex`}
                        component="div"
                        rowsPerPageOptions={[12, 24, 36]}
                        count={pagination.totalRecords}
                        page={pagination.page}
                        onPageChange={onPaginationChange}
                        rowsPerPage={pagination.size}
                        ActionsComponent={TablePaginationActions}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        labelRowsPerPage="Rows per page"
                    />
                      </div>
                </>
            ) : (
                <div className="mt-14">
                    <NoProduct text="No Products to Show" subText="Select the filters to see the selected products" variant="Comparison List" />
                </div>
            )
            }
        </div>
    )
}

export default BrandComparisonPage;