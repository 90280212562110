import React, { useState } from "react";
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import { createUseStyles } from "react-jss";

interface SortButtonProps {
    options: string[];
    formik: any
}

const useStyles = createUseStyles((theme: any) => ({
    container: {
        border: `1px solid ${theme.palette.border.primary.primary500}`,
        color: theme.palette.text.primary.primary500,
    },
    optionContainer: {
        backgroundColor: "#fff",
        boxShadow: "0px 77px 22px 0px rgba(179, 179, 179, 0.00), 0px 49px 20px 0px rgba(179, 179, 179, 0.01), 0px 28px 17px 0px rgba(179, 179, 179, 0.05), 0px 12px 12px 0px rgba(179, 179, 179, 0.09), 0px 3px 7px 0px rgba(179, 179, 179, 0.10)",
    },
    option: {
        color: theme.palette.text.neutral.neutral900,
        "&:hover": {
            backgroundColor: theme.palette.background.primary.primary50,
        }
    },
    text: {
        color: theme.palette.text.primary.primary800,
        fontSize: '16px',
        fontWeight: '500',
        lineHeight: '20px',
    },
    "@media (max-width: 767px)": {

    },
}));

const SortButton: React.FC<SortButtonProps> = ({ options, formik }) => {
    const classes = useStyles();

    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className="relative z-10">
            <div className={`${classes.container} font-medium leading-5 cursor-pointer px-5 py-[6px] rounded-[100px] flex jus gap-x-2 `} onClick={() => setIsOpen(!isOpen)}>
                <div className={`${classes.text} my-auto`}>Sort: {formik.values.sortBy}</div>
                {isOpen ? <ArrowDropUp /> : <ArrowDropDown />}
            </div>

            {isOpen && (
                <div className={`${classes.optionContainer} absolute right-0 mt-2 w-max rounded-xl p-3 grid gap-y-1`}>
                    {options.map((option) => (
                        <div key={option} className={`p-2.5 cursor-pointer font-medium leading-5 rounded-xl ${classes.option} ${formik.values.sortBy === option ? "" : ""}`}
                            onClick={() => {
                                formik.setFieldValue("sortBy", option);
                                setIsOpen(false);
                            }}
                        >
                            {option}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default SortButton;