import React from "react";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: any) => ({
    container: {
        border: `1px solid ${theme.palette.border.neutral.neutral100}`,
        transition: "box-shadow 0.3s ease-in-out",
        "&:hover": {
            boxShadow: `0px 5px 16px 0px rgba(8, 15, 52, 0.08)`,
        },
    },
    background: {
        background: "#d3d3d3",
    }
}));

interface CatalogueListCardSkeletonProps {
    isImageVisible?: boolean;
}

const CatalogueListCardSkeleton: React.FC<CatalogueListCardSkeletonProps> = ({ isImageVisible = true }) => {

    const classes = useStyles();

    return (
        <div className={`${classes.container} rounded-lg overflow-hidden p-4 w-full h-full flex gap-x-3`}>
            <div className={`w-[140px] h-[140px] rounded-lg ${classes.background}`}></div>
            <div className={`h-full flex-1 rounded-lg animate-pulse ${classes.background}`}></div>
            <div className={`w-[250px]  h-full rounded-lg animate-pulse ${classes.background}`}></div>
            <div className={` w-[272px] h-full rounded-lg animate-pulse ${classes.background}`}></div>
        </div>
    );
};

export default CatalogueListCardSkeleton;