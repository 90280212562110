import React from 'react';
import { createUseStyles } from 'react-jss';
import { Button } from '@mui/joy';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import DigitalSecondaryBanner from '../../../assets/images/digitalSecondaryBanner.png';
import DigitalBanner from '../../../assets/images/digitalBanner.jpg';
import primaryBlueTick from '../../../assets/icons/primaryBlueTick.jpg';
import greenTick from '../../../assets/icons/greenTick.svg';
import redTick from '../../../assets/icons/redTick.svg';
import RequestDemoTemplate from '../../template/Home/RequestDemo/RequestDemo.template';
import catalogType from '../../../assets/images/catalogType.png';
import selectSku from '../../../assets/images/selectSku.png';
import inventoryDetails from '../../../assets/images/inventoryDetails.png';
import priceOffer from '../../../assets/images/priceOffer.png';
import customizeOption from '../../../assets/images/customizeOption.png';
import Termlogistics from '../../../assets/images/logistics.png';
import remarks from '../../../assets/images/remarks.png';
import manageCatalog from '../../../assets/images/manageCatalog.png';
import bundleCatalog from '../../../assets/images/bundleCatalog.png';
import rupee from '../../../assets/icons/currency_rupee.jpg';
import verified from '../../../assets/icons/verifiedTick2.svg';
import catalogIcon1 from '../../../assets/icons/catalogIcon1.svg';
import catalogIcon2 from '../../../assets/icons/catalogIcon2.svg';
import catalogIcon3 from '../../../assets/icons/catalogIcon3.svg';
import catalogIcon4 from '../../../assets/icons/catalogIcon4.svg';
import catalogIcon5 from '../../../assets/icons/catalogIcon5.svg';
import catalogIcon6 from '../../../assets/icons/catalogIcon6.svg';
import catalogIcon7 from '../../../assets/icons/catalogIcon7.svg';
//Bykamal
import DigiCatMobileBanner from '../../../assets/images/DigiCatMobileBanner.jpg';
import DotImg from "../../../assets/images/dot-shape.png";
import BulletImg from "../../../assets/images/bulletIcon.svg";
import SuccessBullet700 from "../../../assets/images/SuccessBullet700.svg";
import OrangeBullet700 from "../../../assets/images/OrangeBullet700.svg";
import ComplementaryBullet700 from "../../../assets/images/ComplementaryBullet700.svg";
import Icon1 from '../../../assets/images/DigiLog-Detailed-Product-Specifications.svg';
import Icon2 from '../../../assets/images/DigiLog-Real-Time-Pricing.svg';
import Icon3 from '../../../assets/images/DigiLog-High-Quality-Visual-Representation.svg';
import Icon4 from '../../../assets/images/DigiLog-Customization-Options.svg';
import Icon5 from '../../../assets/images/DigiLog-Integrated-Financing-Options.svg';
import Icon6 from '../../../assets/images/DigiLog-Mill-Discounts-Offers.svg';
import Icon7 from '../../../assets/images/DigiLog-Reviews-Ratings.svg';
import Icon8 from '../../../assets/images/DigiLog-Digital-Negotiation-Tools.svg';
import Icon9 from '../../../assets/images/DigiLog-Logistics-Delivery-Options.svg';
import Section1 from '../../../assets/images/DigiLog-Choose-Your-Catalog-Type.jpg';
import Section2 from '../../../assets/images/DigiLog-Select-Create-SKUs.jpg';
import Section3 from '../../../assets/images/DigiLog-Enter-Inventory-Details.jpg';
import Section4 from '../../../assets/images/DigiLog-Set-Pricing-Sales-Offers.jpg';
import Section5 from '../../../assets/images/DigiLog-Associate-Customization-Options.jpg';
import Section6 from '../../../assets/images/DigiLog-Define-Payment-Terms-Logistics.jpg';
import Section7 from '../../../assets/images/DigiLog-Add-Visual-Content-Remarks.jpg';
import Section8 from '../../../assets/images/DigiLog-Publish-Manage-Your-Catalog.jpg';
import Section9 from '../../../assets/images/DigiLog-Bundle-RFQ-Catalogs.jpg';




const useStyles = createUseStyles((theme: any) => ({
    container: {
        
    },
    bannerContainer: {  
        backgroundImage: `url(${DigitalBanner})`,
        backgroundSize: 'cover',
    },
    bannerSellContainer: {
        "& h1": {
            fontSize: '48px',
            lineHeight: '66px',
            color: theme.palette.text.warning.warning400,
        },
        "& p": {
            color: '#fff',
            lineHeight: '66px',
            fontSize: '48px',
        },
        "& h3": {
            color: '#fff',
        },
    },
    intoContainer: {
        backgroundColor: theme.palette.background.neutral.neutral50,
    },
    text: {
        fontSize: '54px',
        color: "#000",
        lineHeight: '66px',
    },
    decContainer: {
        width: '780px',
        "& p": {
            color: "#000",
            lineHeight: '26px',
        },
    },
  
    headingText: {
        color: theme.palette.text.neutral.neutral800,
        "& span": {
            color: `${theme.palette.text.primary.primary500} !important`,
        },
    },
    featuresContainer: {
        "& > p": {
            color: theme.palette.text.neutral.neutral600,
        },

        "& h4": {
            color: theme.palette.text.primary.primary500,
            fontSize: '48px',
            lineHeight: '58px',
        },

        "& h6": {
            color: theme.palette.text.neutral.neutral900,
            lineHeight: '28px'
        },
    },
    tradeContainer1: {
        background: theme.palette.background.primary.primary500,
        border: theme.palette.border.neutral.neutral200,
        fontSize: '32px',lineHeight:'38px',justifyContent: 'center',color: '#fff',
    },
    tradeContainer: {
    },
    tradeContainer2: {
        background: theme.palette.background.success.success500,
        border: theme.palette.border.neutral.neutral200,
        fontSize: '32px',lineHeight:'38px',justifyContent: 'center',
    },
    cardsContainer: {
        background: '#fff',
        "& p": {
            fontSize: '28px',
        },
    },
    card: {
        border: `1px solid ${theme.palette.border.neutral.neutral200}`,
        background: '#F8F8F8',
        padding: '20px 15px',
        width: '31.6%',
        "& h4": {
            color: '#000',
            fontSize: '22px',
            lineHeight: '26px',
        },
        "& p": {
            color: theme.palette.text.neutral.neutral800,
            fontSize: '16px',
            lineHeight: '20px',
        },
    },
    cardIconContainer: {
        border: `1px solid ${theme.palette.border.neutral.neutral200}`,
        background: '#Fff',
    },
    sectionHeading: {
        color: "#000 !important",
        fontSize: '54px !important',
        lineHeight: '66px',
        "& span": {
            color: theme.palette.text.primary.primary500,
            fontSize: '54px !important',
            lineHeight: '66px',
            fontWeight: '700',
        },
        "& p": {
            color: theme.palette.text.success.success500,
        },
    },
    subHeading: {
        color: theme.palette.text.neutral.neutral600,
    },
    secondaryBanner: {
        width: '100%',
        height: '480px',
        backgroundImage: `url(${DigitalSecondaryBanner})`,
        backgroundSize: 'cover',
        position: 'relative',
    },
    secondaryContainer: {
        '& h2': {
            fontSize: '40px',
            color: theme.palette.text.warning.warning400,
            lineHeight: '48px',
        },
        "& p": {
            color: theme.palette.text.primary.primary50,
            lineHeight: '28px',
        },
    },
    btn3: {
        background: '#fff',
        color: '#fff',
        fontSize: '18px',
        fontWeight: '500',
        padding: '10px 20px',
        lineHeight: '22px',
    },
    btnArrow2: {
        border: '1px solid transparent',
        background: theme.palette.background.primary.primary500,
        borderRadius: '50%',
        height: '32px',
        width: '32px',
    },
    btnText: {
        color: theme.palette.text.primary.primary500,
        fontSize: '20px',
    },
    catalog1: {
        border: `1px solid ${theme.palette.border.complementary.complementary400}`,
    },
    catalog2: {
        border: '1px solid #FD8235',
    },
    catalog3: {
        border: `1px solid ${theme.palette.border.success.success400}`,
    },
    catalogMain: {
        width: '33%',
        overflow: 'hidden',
        background: '#fff',
        "& h4": {
            fontSize: '28px',
            lineHeight: '34px',
            color: '#000',
        },
    },
    catalogHeader1: {
        background: theme.palette.background.complementary.complementary100,
    },
    catalogHeader2: {
        background: '#FFE0CC',
    },
    catalogHeader3: {
        background: theme.palette.background.success.success100,
    },
    catalogText: {
        "& p": {
            fontSize: '16px',
            lineHeight: '20px',
            "& span": {
                color: '#000',
            },
        },
    },
    featuresText: {
        width: '651px',
        "& h5": {
            color: '#000',
            fontSize: '22px',
            lineHeight: '26px',
        },
        "& p": {
            color: theme.palette.text.neutral.neutral800,
            lineHeight: '26px',
        },
    },
    featureInfo: {
        "& h4": {
            color: theme.palette.text.neutral.neutral900,
            fontSize: '32px',
            lineHeight: '38px',
        },
        "& p": {
            color: theme.palette.text.neutral.neutral800,
            lineHeight: '20px',
        },
    },
    "@media screen and (max-width: 480px)": {
      bannerContainer: {
        backgroundImage: `url(${DigiCatMobileBanner})`,
        backgroundSize: "100% 100%",
      },
      tradeContainer1: {width:'100%',textAlign:'center',fontSize:'22px'},
      tradeContainer2: {width:'100%',textAlign:'center',fontSize:'22px'},
    },
}));
const cardData = [
    {
        img: Icon1,
        title: "Detailed Product Specifications",
        description:
            "Provide all technical and commercial details, including dimensions, grades, and certifications like Mill Test Certificates, ensuring complete transparency and buyer confidence.",
    },
    {
        img: Icon2,
        title: "Real-Time Pricing ",
        description:
            "Dynamic pricing for materials helps sellers stay competitive, and buyers get accurate cost insights. Location-specific pricing enhances local relevance.",
    },
    {
        img: Icon3,
        title: "High-Quality Visual Representation",
        description:
            "Upload default or real images to give buyers clear product visualisation, building trust and improving engagement.",
    },
    {
        img: Icon4,
        title: "Customization Options",
        description:
            "Highlight options for tailored materials, sizes, grades, and packaging to meet specific buyer needs, fostering flexibility in procurement.",
    },
    {
        img: Icon5,
        title: "Integrated Financing Options",
        description:
            "Link catalogs to SB Instafin for buyers to access pre-approved credit limits and flexible payment terms, making procurement seamless.",
    },
    {
        img: Icon6,
        title: "Mill Discounts & Offers",
        description:
            "Mention special promotions, mill quantity discounts, and seasonal offers to attract more buyers and close deals quickly.",
    },
    {
        img: Icon7,
        title: "Reviews & Ratings",
        description:
            "Showcase buyer feedback to strengthen trust and improve decision-making for prospective buyers.",
    },
    {
        img: Icon8,
        title: "Digital Negotiation Tools",
        description:
            "Enable buyers to request quotes, compare offers, and negotiate online to close deals faster and more efficiently.",
    },
    {
        img: Icon9,
        title: "Logistics & Delivery Options",
        description:
            "Include transportation choices with estimated timelines and costs, ensuring end-to-end procurement ease.",
    },
];


const DigitalCatalogTemplate: React.FC = () => {

    const classes = useStyles();

    const tradeData = [
        {
            className: `${classes.tradeContainer1} ${classes.tradeContainer}`,
            content: 'Benefits of For Buyers',
        },
        {
            className: `${classes.tradeContainer}`,
            heading: 'Centralized <br /> Access',
            description: 'All product details, specifications, and pricing in one convenient location.',
        },
        {
            className: `${classes.tradeContainer}`,
            heading: 'Real-Time<br />Updates',
            description: 'Instant access to live pricing, availability, and inventory changes.',
        },
        {
            className: `${classes.tradeContainer}`,
            heading: 'Streamlined<br />Transactions',
            description: 'Faster and smoother procurement process with integrated tools for financing and logistics.',
        },
        {
            className: `${classes.tradeContainer}`,
            heading: 'Cost and Time <br /> Efficiency',
            description: 'Reduces paperwork, improves accuracy, and saves time compared to traditional methods.',
        },
        {
            className: `${classes.tradeContainer} ${classes.tradeContainer2}`,
            content: 'Benefits of For Sellers',
        },
        {
            className: `${classes.tradeContainer}`,
            heading: ' Wide <br /> Reach',
            description: 'Showcase products to a broad, global buyer base.',
        },
        {
            className: `${classes.tradeContainer}`,
            heading: 'Enhanced  <br /> Sales',
            description: 'Attract buyers with detailed catalogs, discounts, and customisation.',
        },
        {
            className: `${classes.tradeContainer}`,
            heading: 'Faster  <br /> Payments',
            description: 'Use integrated financing options for instant funds.',
        },
        {
            className: `${classes.tradeContainer}`,
            heading: 'Easy  <br /> Updates',
            description: 'Real-time catalogue management with analytics insights.',
        },
    ];


    return (
        <>
        <div className={`${classes.bannerContainer}`}>
        <div className="container mx-auto px-4">
          <div className="insideBannerBx">
            <div className="insideBannerInfo">
                <b>SB DigiLog</b>
              <h1>Everything<br />For Easy Steel Buying<br />At One Location</h1>
              <p>Streamline, Showcase, and Simplify Your Steel <div className='lg:block inline-block'>Procurement Journey</div></p>
            </div>
          </div>
        </div>
      </div>
      <div className="neutral20Bg lg:py-14 py-8 w-full">
        <div className="container lg:px-4 px-2 mx-auto flex lg:columns-2 columns-1 lg:flex-row flex-col lg:gap-10 gap-2 PageInfoBoxGry">
          <div className="lg:w-2/5 w-full">
            <h2>Elevate your Business Presence</h2>
          </div>
          <div className={`lg:w-3/5 w-full`}>
            <p className=''>SteelBazaar's Digital Catalogs provide a centralised platform for steel procurement, enabling sellers to showcase products with detailed specifications, high-quality images, and real-time pricing. This digital approach enhances visibility, streamlines transactions, and integrates customisation options, financing solutions, and logistics management.</p>
            <p className='!mb-0'>By embracing SteelBazaar's Digital Catalogs, businesses can efficiently manage inventory, reach a broader audience, respond swiftly to market demands, and reduce costs associated with traditional methods, thereby elevating their presence in the competitive steel industry.</p>
          </div>
        </div>
      </div>

      <div className={`${classes.container} container mx-auto pt-90 lg:px-4 px-2 `}>
        <div className={`headingContainer`}>
          <div className="heading">Empowering Steel Trade with <span> Digital Precision</span></div>
          <div className="Subheading">Benefits of Steelbazaar Catalogs</div>
        </div>

        <div className="container mx-auto w-full flex flex-wrap lg:gap-4 gap-2 ">
        {tradeData.map((item, index) => (
                        <div key={index} className={`${item.className} grayWhiteBox colWidth-5`}>
                            {item.heading && <b className="" dangerouslySetInnerHTML={{ __html: item.heading }} />}
                            {item.description && <p>{item.description}</p>}
                            {item.content}
                        </div>
                    ))}
         
        </div>
    </div>

      <div className={`${classes.container} container mx-auto py-90 lg:px-4 px-2 `} id='features'>
        <div className={`headingContainer`}>
          <div className="heading">Showcase Steel <span>Inventory Digitally</span></div>
          <div className="Subheading">Features of Steelbazaar Catalogs</div>
        </div>

        <div className="container mx-auto w-full flex flex-wrap gap-4 ">
          {cardData.map((card, index) => (
            <div key={index} className="grayWhiteBox colWidth-3">
              <div className="iconBox">
                <img src={card.img} alt={card.title} />
              </div>
              <div className="TextBox">
                <b>{card.title}</b>{card.description}
              </div>
            </div>
          ))}
        </div>
    </div>
    <div className="neutral20Bg py-90 w-full">
    <div className={`${classes.container} container mx-auto lg:px-4 px-2 `}>
        <div className={`headingContainer`}>
          <div className="heading"><span>Accelerate Sales</span> with Digital Catalogs</div>
          <div className="Subheading">Choose Your Catalog Types</div>
        </div>
    
    <div className='flex lg:columns-3 md:columns-2 columns-1 gap-4 flex-col lg:flex-row'>
        <div className='AccelerateSales complementary lg:w-1/3 w-full'>
        <b>Single Product Catalogs</b>
              <div className='textBox'>
                 <ul>
                  <li>
                  <img src={ComplementaryBullet700} alt="" />
                  <span>
                    <b>Standard Materials:</b> List reputed brand products like TATA, JSW, SAIL, AMNS, JSPL, etc., with detailed specifications.
                  </span>
                </li>
                  <li>
                  <img src={ComplementaryBullet700} alt="" />
                  <span>
                  <b>Non-Standard Materials:</b> Showcase customised or unique materials for niche buyers.
                  </span>
                </li>
                  <li>
                  <img src={ComplementaryBullet700} alt="" />
                  <span>
                  <b>Commercial Scraps:</b> Highlight industrial-grade reusable scrap materials.
                  </span>
                </li>
                  <li>
                  <img src={ComplementaryBullet700} alt="" />
                  <span>
                  <b>Melting Scraps:</b> Present steel scraps for recycling or melting processes.
                  </span>
                </li>
                </ul>
            </div>
        </div>
        <div className='AccelerateSales orange lg:w-1/3 w-full'>
        <b>Bundle Catalogs</b>
              <div className='textBox'>
                 <ul>
                  <li>
                  <img src={OrangeBullet700} alt="" />
                  <span>
                    <b>Hybrid Catalogs:</b> Combine various material categories in bulk for competitive offerings.
                  </span>
                </li>
                  <li>
                  <img src={OrangeBullet700} alt="" />
                  <span>
                  <b>Pre-Curated & Customizable Bundles:</b> Ready-to-order steel product combinations tailored to specific project and buyer needs.
                  </span>
                </li>
                  <li>
                  <img src={OrangeBullet700} alt="" />
                  <span>
                  <b>Segmented Sales Offers:</b> Customized discounts and offers for SMEs, large enterprises, and bulk buyers to optimize savings.
                  </span>
                </li>
                  <li>
                  <img src={OrangeBullet700} alt="" />
                  <span>
                  <b>Cost-Effective Procurement Solutions:</b> Bundled pricing with flexible options, ensuring efficient and affordable procurement for all buyer segments
                  </span>
                </li>
                </ul>
            </div>
        </div>
        <div className='AccelerateSales success lg:w-1/3 w-full'>
        <b>RFQ Catalogs</b>
              <div className='textBox !p-4'>
                 <ul>
                  <li>
                  <img src={SuccessBullet700} alt="" />
                  <span>
                    <b>Customisable Specifications:</b>  Buyers can select exact steel specifications from multiple options to meet their project needs.
                  </span>
                </li>
                  <li>
                  <img src={SuccessBullet700} alt="" />
                  <span>
                  <b>Simplified Request for Quote (RFQ):</b>  Easy-to-use interface to submit tailored RFQs directly to sellers for quick responses.
                  </span>
                </li>
                  <li>
                  <img src={SuccessBullet700} alt="" />
                  <span>
                  <b>Flexible Comparison:</b>  Compare quotes from multiple reputed brands, TATA, JSW, AMNS, SAIL, and others, based on specifications, pricing, and delivery timelines.
                  </span>
                </li>
                  <li>
                  <img src={SuccessBullet700} alt="" />
                  <span>
                  <b>Precision Procurement:</b> Ensure buyers receive steel products that match their chemical, mechanical, and quality requirements.
                  </span>
                </li>
                </ul>
            </div>
        </div>

    </div>
</div>
    </div>
    <div
        className={`${classes.container} container mx-auto lg:px-4 px-2 pt-90`}
      >
        <div className={`headingContainer`}>
          <div className="heading">Start selling on Steelbazaar</div>
          <div className="Subheading">Your Journey Starts Here...</div>
        </div>
        {
          // Section One Start Here
        }
        <div
          className="flex lg:columns-2 columns-1 lg:flex-row flex-col items-center ZigZagSection"
          id="registration"
        >
          <div className="2xl:w-4/12 lg:w-5/12 w-full flex content-center justify-center order-1 lg:order-2">
            <div className="ZigZagImgBox">
              <div className="ImgItemLeft">
                <img src={DotImg} alt="" />
              </div>
              <img src={Section1} className="w-full" alt="" />
            </div>
          </div>
          <div className="2xl:w-8/12 lg:w-7/12 w-full ZigZagText order-2 lg:order-1">
          <div className="grayText">Step 01:</div>
            <div className="heading48">
            Choose Your <span>Catalog Type</span>
            </div>
            <div className="Subhead">
              <b>Identify and Select Your Inventory Category</b>
             
            </div>

            <div className="PointBox">
              <ul>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span className='txt22'> 
                    Choose from Standard, Non-Standard, Commercial, or Scrap catalogue types.
                  </span>
                </li>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span className='txt22'>
                  Match inventory type to the appropriate category for easy management.
                  </span>
                </li>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span className='txt22'>
                  Facilitate buyer navigation with precise catalogue categorisation
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {
          // Section Two Start Here
        }
        <div className="flex lg:columns-2 columns-1 lg:flex-row flex-col items-center ZigZagSection">
          <div className="2xl:w-4/12 lg:w-5/12 w-full flex justify-center">
            <div className="ZigZagImgBox">
              <div className="ImgItem">
                <img src={DotImg} alt="" />
              </div>
              <img src={Section2} className="w-full" alt="" />
            </div>
          </div>
          <div className="2xl:w-8/12 lg:w-7/12 w-full ZigZagText">
            <div className="grayText">Step 02:</div>
            <div className="heading48">
            Select or <span>Create SKUs</span>
            </div>
            <div className="Subhead">
              <b>Build Your Product Blueprint</b>
            
            </div>

            <div className="PointBox">
              <ul>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span className='txt22'>
                  <strong>Choose Pre-defined SKUs:</strong> Access a library of standard SKUs from leading brands like TATA, JSW, SAIL, AMNS, and JSPL.
                  </span>
                </li>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span className='txt22'>
                  <strong>Customise Your SKUs:</strong> Tailor SKUs to match unique product specifications and branding requirements.
                  </span>
                </li>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span className='txt22'>
                  <strong>Define Detailed Attributes:</strong> Specify product category, standards, grades, size specifications, coatings, chemical composition, and mechanical properties.
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {
          // Section Three Start Here
        }
        <div className="flex lg:columns-2 columns-1 lg:flex-row flex-col items-center ZigZagSection">
          <div className="2xl:w-4/12 lg:w-5/12 w-full flex content-center justify-center order-1 lg:order-2">
            <div className="ZigZagImgBox">
              <div className="ImgItemLeft">
                <img src={DotImg} alt="" />
              </div>
              <img src={Section3} className="w-full" alt="" />
            </div>
          </div>
          <div className="2xl:w-8/12 lg:w-7/12 w-full ZigZagText order-2 lg:order-1">
            <div className="grayText">Step 03:</div>
            <div className="heading48">
            Enter <span>Inventory Details</span>
            </div>
            <div className="Subhead">
              <b>Organize Your Inventory with Precision</b>
            </div>

            <div className="PointBox">
              <ul>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                 <span className='txt22'>
                  <strong>Specify Quantities:</strong> Input the number of coils or bundles available for each SKU.
                  </span>
                </li>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                 <span className='txt22'>
                  <strong>Assign Unique Serial Numbers (USNs):</strong> Allocate USNs to each unit for precise tracking and identification.
                  </span>
                </li>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                 <span className='txt22'>
                  <strong>Record Location and Packaging:</strong> Document storage locations and packaging details for streamlined logistics.
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {
          // Section Four Start Here
        }
        <div
          className="flex lg:columns-2 columns-1 lg:flex-row flex-col items-center ZigZagSection"
          id="inventory"
        >
          <div className="2xl:w-4/12 lg:w-5/12 w-full flex justify-center">
            <div className="ZigZagImgBox">
              <div className="ImgItem">
                <img src={DotImg} alt="" />
              </div>
              <img src={Section4} className="w-full" alt="" />
            </div>
          </div>
          <div className="2xl:w-8/12 lg:w-7/12 w-full ZigZagText">
            <div className="grayText">Step 04:</div>
            <div className="heading48">
           <span>Set Pricing</span> and Sales Offers
            </div>
            <div className="Subhead">
              <b>Attract Buyers with Competitive , Dynamic Pricing & Discounts</b>
            </div>

            <div className="PointBox">
              <ul>
                <li className="items-center">
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span className='txt22'>
                  <strong>Define Competitive Pricing:</strong> Establish prices that reflect market conditions and business objectives.
                  </span>
                </li>
                <li className="items-center">
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span className='txt22'>
                  <strong>Create Promotional Offers:</strong> Develop sales promotions to attract and retain customers.
                  </span>
                </li>
                <li className="items-center">
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span className='txt22'>
                  <strong>Implement Dynamic Pricing:</strong> Adjust prices in real-time based on demand and inventory levels.
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {
          // Section Five Start Here
        }
        <div className="flex lg:columns-2 columns-1 lg:flex-row flex-col items-center ZigZagSection">
          <div className="2xl:w-4/12 lg:w-5/12 w-full flex content-center justify-center order-1 lg:order-2">
            <div className="ZigZagImgBox">
              <div className="ImgItemLeft">
                <img src={DotImg} alt="" />
              </div>
              <img src={Section5} className="w-full" alt="" />
            </div>
          </div>
          <div className="2xl:w-8/12 lg:w-7/12 w-full ZigZagText order-2 lg:order-1">
            <div className="grayText">Step 05:</div>
            <div className="heading48">
            Associate <span>Customization Options</span>
            </div>
            <div className="Subhead">
              <b>Tailor Solutions for Your Buyers</b>
            </div>

            <div className="PointBox">
              <ul>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span className='txt22'>
                 <strong>Offer Product Variants:</strong> Provide size, grade, and coating options to meet diverse customer needs.
                  </span>
                </li>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span className='txt22'>
                  <strong>Enable Custom Orders:</strong> Facilitate bespoke orders with specific requirements.
                  </span>
                </li>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span className='txt22'>
                  <strong>Highlight Customization Capabilities:</strong> Showcase your ability to deliver tailored solutions.
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {
          // Section Six Start Here
        }
        <div
          className="flex lg:columns-2 columns-1 lg:flex-row flex-col items-center ZigZagSection"
          id="storeFront"
        >
          <div className="2xl:w-4/12 lg:w-5/12 w-full flex justify-center">
            <div className="ZigZagImgBox">
              <div className="ImgItem">
                <img src={DotImg} alt="" />
              </div>
              <img src={Section6} className="w-full" alt="" />
            </div>
          </div>
          <div className="2xl:w-8/12 lg:w-7/12 w-full ZigZagText">
            <div className="grayText">Step 06:</div>
            <div className="heading48">
            Define <span>Payment Terms & Logistics</span>
            </div>
            <div className="Subhead">
              <b>Simplify Payments and Deliveries</b>
            </div>

            <div className="PointBox">
              <ul>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span className='txt22'>
                  <strong>Set Payment Conditions:</strong> Outline terms, including credit options and payment schedules.
                  </span>
                </li>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span className='txt22'>
                  <strong>Detail Delivery Options:</strong> Specify shipping methods, timelines, and associated costs.
                  </span>
                </li>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span className='txt22'>
                  <strong>Clarify Return Policies:</strong> Provide clear guidelines on returns and exchanges.
                  </span>
                </li>
              </ul>
              <i>Mention applicable credit terms for buyers using SB Instafin, including flexible repayment durations and competitive interest rates.</i>
            </div>
          </div>
        </div>

        {
          // Section Seven Start Here
        }
        <div
          className="flex lg:columns-2 columns-1 lg:flex-row flex-col items-center ZigZagSection"
          id="publish"
        >
          <div className="2xl:w-4/12 lg:w-5/12 w-full flex content-center justify-center order-1 lg:order-2">
            <div className="ZigZagImgBox">
              <div className="ImgItemLeft">
                <img src={DotImg} alt="" />
              </div>
              <img src={Section7} className="w-full" alt="" />
            </div>
          </div>
          <div className="2xl:w-8/12 lg:w-7/12 w-full ZigZagText order-2 lg:order-1">
            <div className="grayText">Step 07:</div>
            <div className="heading48">
            Add Visual <span>Content and Remarks</span>
            </div>
            <div className="Subhead">
              <b>Visualise Your Inventory with Comprehensive Buyer Information</b>
            </div>

            <div className="PointBox">
              <ul>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span className='txt22'>
                  <strong>Upload High-Quality Images:</strong> Include visuals for each USN to enhance buyer confidence.Either Use SB default Images or Upload Product images. 
                  </span>
                </li>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span className='txt22'>
                  <strong>Attach Certificates:</strong> Provide mill test certificates and other relevant documentation.
                  </span>
                </li>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span className='txt22'>
                  <strong>Include Specific Remarks:</strong> Add notes on product condition, special features, or handling instructions.
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {
          // Section Eight Start Here
        }
        <div
          className="flex lg:columns-2 columns-1 lg:flex-row flex-col items-center ZigZagSection"
          id="storeFront"
        >
          <div className="2xl:w-4/12 lg:w-5/12 w-full flex justify-center">
            <div className="ZigZagImgBox">
              <div className="ImgItem">
                <img src={DotImg} alt="" />
              </div>
              <img src={Section8} className="w-full" alt="" />
            </div>
          </div>
          <div className="2xl:w-8/12 lg:w-7/12 w-full ZigZagText ">
            <div className="grayText">Step 08:</div>
            <div className="heading48">
            <span>Publish and Manage</span> Your Catalog
            </div>
            <div className="Subhead">
              <b>Make Your Catalog Visible to Buyers</b>
            </div>

            <div className="PointBox">
              <ul>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span className='txt22'>
                  Review and Finalize: Ensure all information is accurate and complete before publishing.
                  </span>
                </li>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span className='txt22'>
                  Distribute Across Platforms: Share your digital catalogue via email, social media, and the Steelbazaar marketplace.
                  </span>
                </li>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span className='txt22'>
                  Monitor Performance: buyer inquiries, and sales through the intuitive Seller Dashboard.
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {
          // Section Nine Start Here
        }
        <div
          className="flex lg:columns-2 columns-1 lg:flex-row flex-col items-center ZigZagSection"
          id="publish"
        >
          <div className="2xl:w-4/12 lg:w-5/12 w-full flex content-center justify-center order-1 lg:order-2">
            <div className="ZigZagImgBox">
              <div className="ImgItemLeft">
                <img src={DotImg} alt="" />
              </div>
              <img src={Section9} className="w-full" alt="" />
            </div>
          </div>
          <div className="2xl:w-8/12 lg:w-7/12 w-full ZigZagText order-2 lg:order-1">
            <div className="grayText">Step 09:</div>
            <div className="heading48">
            <span>Bundle and RFQ </span>Catalogs
            </div>
            <div className="Subhead">
              <b>Streamline Large Inventories for Buyers</b>
            </div>

            <div className="PointBox">
              <ul>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span className='txt22'>
                  Combine multiple catalogs for easier browsing.
                  </span>
                </li>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span className='txt22'>
                  Manufacturers can create <span>RFQ Catalogs</span> with multiple SKUs for bulk orders.
                  </span>
                </li>
                <li>
                  <img src={BulletImg} className="bulletBrd" alt="" />
                  <span className='txt22'>
                  Enable smooth buyer interactions with detailed catalogs.
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>

      </div>
      <div  className={`CTABox lg:py-24 py-8 w-full textBoxfg`}>
      <div className="container mx-auto CallToAction lg:px-4 px-3 ">
        <div className="2xl:w-5/12 lg:w-5/12 flex flex-col gap-y-2.5 items-start">
       <h2>
       Steelbazaar Digital Catalogs: Fulfilling Every Buyer Need
       </h2>
       <p className="mb-5">
       Our catalogues provide 24/7 access to products with flexible financing, transparent pricing, and unmatched customisation. Backed by modern digital tools and comprehensive buyer support, Steelbazaar Digital Catalogs redefine steel procurement.
       </p>
       <Button className="Btn whiteBg Txtprimary500">Contact Now <ArrowForwardIcon className="primary500Bg text-white" />
                </Button>
      </div>
      </div>
      </div>
            <RequestDemoTemplate />

        </>
    );
};
export default DigitalCatalogTemplate;
