import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useBusinessProfileService } from '../../../services/useBusinessProfileService';
import { useSnackbar } from '../../../hooks/useSnackBar';
import Button from '../../atoms/Button/Button';
import GntnDetailModalTemplate from './GstnDetailModal.Template';
import deleteOutlinedPrimary800 from '../../../assets/icons/deleteOutlinedPrimary800.svg';
import { LocationSectionProps } from './AddBusinessLocation.template';
import GetNumberOfBusinessLocationTemplate from './GetNumberOfBusinessLocation.template';
import SuccessFailurePopup from '../../molecules/SuccessFailurePopup/SuccessFailurePopup';

const useStyles = createUseStyles((theme: any) => ({
    container: {
        border: `1px solid ${theme.palette.border.neutral.neutral100}`,
    },
    mainContainer: {
        border: `1px solid ${theme.palette.border.neutral.neutral100}`,
    },
    titleText: {
        color: theme.palette.text.neutral.neutral600,
        lineHeight: "16px"
    },
    titleTextValue: {
        color: theme.palette.text.neutral.neutral700,
        lineHeight: "20px"
    },
    button: {
        backgroundColor: theme.palette.background.primary.primary500,
        color: theme.palette.text.primary.primary50,
    },
    deleteButton: {
        border: `1px solid ${theme.palette.border.primary.primary800}`
    }
}));

const ViewBusinessLocationTemplate: React.FC<LocationSectionProps> = ({ formik, setCurrentSectionTo }) => {
    const classes = useStyles();
    const [gstinModalDialogOpen, setGstinModalDialogOpen] = useState<boolean>(false);
    const [index, setIndexTo] = useState<number>();
    const [currentGstin, setCurrentGstin] = useState<string>('');
    const bussinessProfileService = useBusinessProfileService();
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
    const [deleteIndex, setDeleteIndex] = useState<number | null>(null);
    const [buttonShow, setButtonShow] = useState<boolean>(true);
    const { showSnackbar, SnackBarComponent } = useSnackbar();

    const locationSectionProps: LocationSectionProps = {
        setCurrentSectionTo: setCurrentSectionTo,
        formik: formik,
    };

    const deleteLocation = async (index: number) => {
        setIsDeleteModalOpen(true);
        setDeleteIndex(index-1);
    };
    const onClickModal = (index: number) => {
        setGstinModalDialogOpen(true);
        setIndexTo(index)
    };
    const handleDeleteDialogClose = async (event: boolean) => {
        setIsDeleteModalOpen(false);
        if (event && deleteIndex != null) {
            let locations = [...formik.values.locations];
            const indexLocation = locations[0];
            locations.splice(0, 1);
            locations.splice(deleteIndex, 1);
            formik.setFieldValue('locations', locations);
            const business = await bussinessProfileService.getBusinessProfile();
            locations = [
                ...locations,
                {
                    gstin: business?.data?.data?.gstin,
                    state: business?.data?.data?.address?.city
                }
            ]
            try {
                await bussinessProfileService.updateBusinessProfile(locations, 'LOCATIONS');
                const filteredLocations = locations.filter(location => location.gstin !== indexLocation.gstin);
                locations = [indexLocation, ...filteredLocations];
                formik.setFieldValue('locations', locations);
            } catch (error) {
                showSnackbar("error", `Business profile update failed ${error}`)
            }
        }
    };
    
    return (
        <> <div className={`${classes.mainContainer} grid gap-y-4 p-4 rounded-xl`}>
            {formik.values?.locations?.map((location: any, index: number) => (
                <div key={index} className={`${classes.container} p-3 rounded-lg`}>
                    <div className='flex justify-between'>
                        <div className='flex gap-x-8'>
                            <div className={`boder rounded-lg ${classes.button} w-[55px] h-[49px] text-lg flex justify-center text-center items-center font-semibold`}> {index + 1} </div>
                            <div className='flex gap-x-6'>
                                <div className='grid gap-y-1'>
                                    <text className={`${classes.titleText} font-normal text-sm`}>GST Number</text>
                                    <div className={`${classes.titleTextValue} font-medium text-base`}>{location.gstin}</div>
                                </div>
                                <div className='grid gap-y-1'>
                                    <text className={`${classes.titleText} font-normal text-sm`}>Location</text>
                                    <div className={`${classes.titleTextValue} font-medium text-base`}>{location.state}</div>
                                </div>
                            </div>
                        </div>
                       <div className='flex gap-x-3 '>
                            <Button
                                variant="tertiaryContained"
                                label="View Details"
                                onClick={() => { onClickModal(index) }}
                                className='!h-12'
                            />
                            {index > 0 && <div className={`p-3 ${classes.deleteButton} rounded-xl`}>
                                <img src={deleteOutlinedPrimary800} alt="deleteOutlinedPrimary800" className="blink-on-hover blink-on-click" onClick={() => deleteLocation(index)} />
                            </div>}
                        </div>
                    </div>
                </div>
            ))}
            {
                isDeleteModalOpen &&
                <SuccessFailurePopup
                    width="464px"
                    variant='Delete'
                    heading="Delete Location?"
                    description="Are you sure you want to delete this business location?"
                    setShowPopup={setIsDeleteModalOpen}
                    button1={{
                        text: 'Yes',
                        variant: "tertiaryContained",
                        size: "large",
                        onClick: () => handleDeleteDialogClose(true),
                    }}
                    button2={{
                        text: 'No',
                        variant: "secondaryContained",
                        size: "large",
                        onClick: () => handleDeleteDialogClose(false)
                    }}
                />
            }
            {gstinModalDialogOpen && index != null && <GntnDetailModalTemplate locations={formik.values?.locations} index={index} dialogOpen={gstinModalDialogOpen} setDialogOpen={setGstinModalDialogOpen} />}
        </div>
            {!(!buttonShow || formik.values.noOfRows) &&
                <div className='flex justify-self-end'>
                    <Button
                        variant="tertiaryContained"
                        label="Add New Location"
                        onClick={() => { setButtonShow(false) }}
                    />
                </div>}
            {
                (!buttonShow || formik.values.noOfRows) && <GetNumberOfBusinessLocationTemplate {...locationSectionProps} />

            }
        </>
    );
}

export default ViewBusinessLocationTemplate;