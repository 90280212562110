import { useAuthenticatedUser } from '../hooks/useAuthenticatedUser';
import { API_METHOD } from '../utils/constant';
import { request } from '.';
import { replaceUrlParams } from '../utils/helper';

const SCRAP_PRODUCT_URLS = {
    GET_ALL_VENDOR_INVENTORY: "/vendor/:id/products/inventories",
    GET_VENDOR_INVETORY: "/inventory/vendor/:id/warehouse/:warehouseId/product/:productId/inventories",
    GET_WAREHOUSE_DETAILS: "/inventory/vendor/:id/warehouse/:warehouseId/product/:productId/usnDetailStats",
    GET_WAREHOUSE_USN_DETAILS: "/inventory/vendor/:id/warehouse/:warehouseId/product/:productId/usnDetail",
    CREATE_INVENTORY: "/inventory/inventories",
    GET_WAREHOUSE: "/warehouse/warehouses",
    GET_ALL_PRODUCT: "/vendor/:id/products",
    GET_PRODUCT_DETAIL: "/vendor/:id/product/:productId",
    GET_CATALOGUE_IMAGES: "/files/image-master/images",
    GET_VALID_WAREHOUSES_FOR_CATALOGUE: "/warehouse/catalogue/warehouses/:vendorProductId",
}

export interface IVendorInventories {
    classType: string;
    page: number;
    size: number;
    productIdString?: string;
    category?: string;
    search?: string;
    status?: string;
    sort?: string;
    section?: string;
}

interface IWarehouse {
    // key?: string;
    vendorId?: number;
    status?: string;
}

interface IInventory {
    vendorId: number;
    productId: number;
    warehouseId: number;
    vendorUsnCode: string;
    netWeight: number;
    grossWeight: number;
    packagingType: string;
    isMillTest: boolean;
    status: string;
    uom: string;
    warehousePlacement: string;
}

interface IVendorProductRequestBody {
    vendorProductDetailsType: string;
}

export interface IVendorInventoryMillTest {
    fileName: string | null;
}

export const useVendorInventoryService = () => {
    const { user: authenticatedUser } = useAuthenticatedUser();
    const v2BaseUrl = process.env.REACT_APP_API_V2_URL

    const getAllVendorInventories = (id: number, params: IVendorInventories) => {
        return request(API_METHOD.GET, replaceUrlParams(SCRAP_PRODUCT_URLS.GET_ALL_VENDOR_INVENTORY, { id }), authenticatedUser, null, { params });
    };

    const getAllInvendorMappedVenndor = (id: number, warehouseId: number, productId: number, params: IVendorInventories) => {
        return request(API_METHOD.GET, replaceUrlParams(SCRAP_PRODUCT_URLS.GET_VENDOR_INVETORY, { id, warehouseId, productId }), authenticatedUser, null, { params });
    };

    const createInventories = (fileData: FormData) => {
        return request(API_METHOD.POST, SCRAP_PRODUCT_URLS.CREATE_INVENTORY, authenticatedUser, fileData, null, null);
    };

    const getAllWarehouseMappedVendor = (params: IWarehouse) => {
        return request(API_METHOD.GET, SCRAP_PRODUCT_URLS.GET_WAREHOUSE, authenticatedUser, null, { params });
    };

    const getAllProducts = (id: number, params: IVendorInventories) => {
        return request(API_METHOD.GET, replaceUrlParams(SCRAP_PRODUCT_URLS.GET_ALL_PRODUCT, { id }), authenticatedUser, null, { params });
    };

    const getDetailedProduct = (id: number, productId: number, params?: IVendorProductRequestBody) => {
        const requestParams = params ? { params } : undefined;
        return request(API_METHOD.GET, replaceUrlParams(SCRAP_PRODUCT_URLS.GET_PRODUCT_DETAIL, { id, productId }), authenticatedUser, null, requestParams);
    };

    const getWarehouseDetails = (id: number, warehouseId: number, productId: number) => {
        return request(API_METHOD.GET, replaceUrlParams(SCRAP_PRODUCT_URLS.GET_WAREHOUSE_DETAILS, { id, warehouseId, productId }), authenticatedUser, null, null, null, v2BaseUrl);
    };

    const getWarehouseUsnDetails = (id: number, warehouseId: number, productId: number) => {
        return request(API_METHOD.GET, replaceUrlParams(SCRAP_PRODUCT_URLS.GET_WAREHOUSE_USN_DETAILS, { id, warehouseId, productId }), authenticatedUser, null, null, null, v2BaseUrl);
    };

    const getCatalogueImages = (data: any) => {
        return request(API_METHOD.POST, SCRAP_PRODUCT_URLS.GET_CATALOGUE_IMAGES, authenticatedUser, data, null);
    };

    const getValidWarehousesForCatalogues = (vendorProductId: number, params: IWarehouse) => {
        return request(API_METHOD.GET, replaceUrlParams(SCRAP_PRODUCT_URLS.GET_VALID_WAREHOUSES_FOR_CATALOGUE, { vendorProductId }), authenticatedUser, null, { params });
    };

    return {
        getAllVendorInventories,
        getAllInvendorMappedVenndor,
        createInventories,
        getAllWarehouseMappedVendor,
        getAllProducts,
        getDetailedProduct,
        getWarehouseDetails,
        getWarehouseUsnDetails,
        getCatalogueImages,
        getValidWarehousesForCatalogues
    };
};
