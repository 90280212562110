import React, { useState } from 'react';
import { createUseStyles } from "react-jss";
import CloseIcon from '@mui/icons-material/Close';
import AccordionV2 from '../../molecules/AccordionV2/AccordionV2';
import { STATIC_PAGES_ROUTES } from '../../../utils/constant';

interface MenuDialogTemplateProps {
    handleDialogClose: (value: boolean) => void;
}

const useStyles = createUseStyles((theme: any) => ({
    container: {
        position: 'fixed',
        width: '100vw',
        minHeight: '70vh',
        height: '100vh',
        overflow: 'auto',
        zIndex: 9999,
        backgroundColor: 'white',
    },
    closeIcon: {
        color: theme.palette.text.primary.primary500
    },
    "@media (max-width: 767px)": {
        container: {
            minHeight: '0',
        },
    }
}));

const MenuDialogTemplate: React.FC<MenuDialogTemplateProps> = ({ handleDialogClose }) => {
    const classes = useStyles();
    const [isDropdownOpen, setIsDropdownOpen] = useState(true);


    const handleLinkClick = () => {
        setIsDropdownOpen(false);
    };

    if (!isDropdownOpen) return null;

    const menuSections = [
        {
            title: 'About',
            items: [
                {
                    name: 'About us',
                    route: `${STATIC_PAGES_ROUTES.ABOUT_US}`,
                    onClick: handleLinkClick,
                },
                {
                    name: 'Mission and Vision',
                    route: `${STATIC_PAGES_ROUTES.ABOUT_US}/?component=vision`,
                    onClick: handleLinkClick,
                },
            ],
        },
        {
            title: 'Platform',
            items: [
                {
                    name: 'Marketplace',
                    route: '',
                    subSection: {
                        title: 'Marketplace',
                        items: [
                            {
                                name: '',
                                route: '',
                                secondarySubSection: {
                                    title: 'SB DigiTrade',
                                    items: [
                                        {
                                            name: 'B2B Marketplace',
                                            route: `${STATIC_PAGES_ROUTES.B2B_MARKETPLACE}`,
                                            onClick: handleLinkClick,
                                        },
                                    ],
                                },
                            },
                            {
                                name: '',
                                route: '',
                                secondarySubSection: {
                                    title: 'SB BidHub',
                                    items: [
                                        {
                                            name: 'Forward Sales Auction',
                                            route: `${STATIC_PAGES_ROUTES.AUCTION}/?component=forward-sales-auction`,
                                            onClick: handleLinkClick,
                                        },
                                        {
                                            name: 'Reverse Buy Auction',
                                            route: `${STATIC_PAGES_ROUTES.AUCTION}/?component=reverse-buy-auction`,
                                            onClick: handleLinkClick,
                                        },
                                    ],
                                },
                            },
                            {
                                name: '',
                                route: '',
                                secondarySubSection: {
                                    title: 'SB LeadX',
                                    items: [
                                        {
                                            name: 'SB Buy Leads',
                                            route: `${STATIC_PAGES_ROUTES.B2B_MARKETPLACE}`,
                                            onClick: handleLinkClick,
                                        },
                                        {
                                            name: 'SB Sell Leads',
                                            route: `${STATIC_PAGES_ROUTES.B2B_MARKETPLACE}`,
                                            onClick: handleLinkClick,
                                        },
                                    ],
                                },
                            },

                        ],
                    },
                },
                {
                    name: 'For Sellers/Vendors',
                    route: '',
                    subSection: {
                        title: 'For Sellers/Vendors',
                        items: [
                            {
                                name: '',
                                route: '',
                                secondarySubSection: {
                                    title: 'SB DigiSell',
                                    items: [
                                        {
                                            name: 'Seller Central',
                                            route: `${STATIC_PAGES_ROUTES.SELLER_CENTRAL}`,
                                            onClick: handleLinkClick,
                                        },
                                        {
                                            name: 'Vendor Central',
                                            route: `${STATIC_PAGES_ROUTES.VENDOR_CENTRAL}`,
                                            onClick: handleLinkClick,
                                        },
                                    ],
                                },

                            },
                            {
                                name: '',
                                route: '',
                                secondarySubSection: {
                                    title: 'SB DigiStore ',
                                    items: [
                                        {
                                            name: 'Digital Store Front',
                                            route: `${STATIC_PAGES_ROUTES.DIGISTORE}`,
                                            onClick: handleLinkClick,
                                        },
                                        {
                                            name: 'Digital Inventory',
                                            route: `${STATIC_PAGES_ROUTES.DIGI_STOCK}`,
                                            onClick: handleLinkClick,
                                        },
                                        {
                                            name: 'Digital Catalogue',
                                            route: `${STATIC_PAGES_ROUTES.DIGITAL_CATALOG}`,
                                            onClick: handleLinkClick,
                                        },
                                        {
                                            name: 'Digital Order Manegment',
                                            route: '',
                                            onClick: handleLinkClick,
                                        },
                                    ],
                                },
                            },


                        ],
                    },
                },
                {
                    name: 'For Buyers',
                    route: '',
                    subSection: {
                        title: 'For Buyers',
                        items: [
                            {
                                name: '',
                                route: '',
                                secondarySubSection: {
                                    title: 'SB Get MySteel',
                                    items: [
                                        {
                                            name: 'Compare Brands',
                                            route: `${STATIC_PAGES_ROUTES.SELLER_CENTRAL}/?component=compare-brands`,
                                            onClick: handleLinkClick,
                                        },
                                    ],
                                },

                            },
                            {
                                name: '',
                                route: '',
                                secondarySubSection: {
                                    title: 'SB Customize ',
                                    items: [
                                        {
                                            name: 'Request For Quotation',
                                            route: `${STATIC_PAGES_ROUTES.DIGISTORE}/?component=request-for-quotation`,
                                            onClick: handleLinkClick,
                                        },
                                    ],
                                },
                            },
                            {
                                name: '',
                                route: '',
                                secondarySubSection: {
                                    title: 'SB Pool&Save ',
                                    items: [
                                        {
                                            name: 'Pool & Save',
                                            route: '',
                                            onClick: handleLinkClick,
                                        },
                                    ],
                                },
                            },
                            {
                                name: '',
                                route: '',
                                secondarySubSection: {
                                    title: 'SB GlobalSource ',
                                    items: [
                                        {
                                            name: 'Global Sourcing',
                                            route: `${STATIC_PAGES_ROUTES.B2B_MARKETPLACE}/?component=global`,
                                            onClick: handleLinkClick,
                                        },
                                    ],
                                },
                            },

                        ],
                    },
                },
            ],
        },
        {
            title: 'Services',
            items: [
                {
                    name: 'Procurement Solutions', route: '',
                    subSection: {
                        title: 'Procurement Solutions',
                        items: [
                            {
                                name: 'SB Procure',
                                route: `${STATIC_PAGES_ROUTES.PROCUREMENT}`,
                                onClick: handleLinkClick,
                            },
                            {
                                name: 'SB MaXX for Enterprises',
                                route: `${STATIC_PAGES_ROUTES.PROCUREMENT}/?component=sbmax`,
                                onClick: handleLinkClick,
                            },
                            {
                                name: 'iProcure for MSMEs',
                                route: '',
                                onClick: handleLinkClick,
                            },
                            {
                                name: 'iProcure for SMEs',
                                route: '',
                                onClick: handleLinkClick,
                            },
                        ],
                    },
                },
                {
                    name: 'Manufacturing Solutions', route: '',
                    subSection: {
                        title: 'Manufacturing Solutions',
                        items: [
                            {
                                name: 'SB Makers',
                                route: '',
                                onClick: handleLinkClick,
                            },
                        ],
                    },
                },
                {
                    name: 'Supply Chain Finance', route: '',
                    subSection: {
                        title: 'Supply Chain Finance',
                        items: [
                            {
                                name: 'SB InstaFin',
                                route: `${STATIC_PAGES_ROUTES.SUPPLYCHAIN_FINANCE}/?component=sbmax`,
                                onClick: handleLinkClick,
                            },
                            {
                                name: 'Sales Invoice Discounting',
                                route: `${STATIC_PAGES_ROUTES.SUPPLYCHAIN_FINANCE}/?component=sales-invoice-discounting`,
                                onClick: handleLinkClick,
                            },
                            {
                                name: 'Purchase Invoice Finance',
                                route: `${STATIC_PAGES_ROUTES.SUPPLYCHAIN_FINANCE}/?component=purchase-invoice-finance`,
                                onClick: handleLinkClick,
                            },
                            {
                                name: 'Dynamic Discounting',
                                route: `${STATIC_PAGES_ROUTES.SUPPLYCHAIN_FINANCE}/?component=dynamic-discounting`,
                                onClick: handleLinkClick,
                            },
                        ],
                    },
                },
                {
                    name: 'Sustainability', route: '',
                    subSection: {
                        title: 'Sustainability',
                        items: [
                            {
                                name: 'SB Green',
                                route: '',
                                onClick: handleLinkClick,
                            },
                            {
                                name: 'Recycling',
                                route: `${STATIC_PAGES_ROUTES.RECYCLING}`,
                                onClick: handleLinkClick,
                            },
                            {
                                name: 'Re-Processing',
                                route: '',
                                onClick: handleLinkClick,
                            },
                        ],
                    },
                },
                {
                    name: 'Insights & Analytics', route: '',
                    subSection: {
                        title: 'Insights & Analytics',
                        items: [
                            {
                                name: 'SB SteelInteli',
                                route: '',
                                onClick: handleLinkClick,
                            },
                            {
                                name: 'SB News',
                                route: 'https://news.steelbazaar.com/',
                                onClick: handleLinkClick,
                            },
                            {
                                name: 'SB Market Insights',
                                route: '',
                                onClick: handleLinkClick,
                            },
                            {
                                name: 'Blogs/Case Studies',
                                route: '',
                                onClick: handleLinkClick,
                            },
                        ],
                    },
                },
            ],
        },
        {
            title: 'Trade Assurance',
            items: [
                {
                    name: 'Payment Security', route: '',
                    subSection: {
                        title: 'Payment Security',
                        items: [
                            {
                                name: 'Buyer Protection',
                                route: `${STATIC_PAGES_ROUTES.TRUST_ASSURANCE}/?component=buyer-protection`,
                                onClick: handleLinkClick,
                            },
                            {
                                name: 'Quality Assurance',
                                route: `${STATIC_PAGES_ROUTES.TRUST_ASSURANCE}/?component=quality-assurance`,
                                onClick: handleLinkClick,
                            },
                            {
                                name: 'Ontime Delivery',
                                route: `${STATIC_PAGES_ROUTES.TRUST_ASSURANCE}/?component=ontime-delivery`,
                                onClick: handleLinkClick,
                            },
                        ],
                    },
                },
                {
                    name: 'Seller Benefits', route: '',
                    subSection: {
                        title: 'Seller Benefits',
                        items: [
                            {
                                name: 'Verified Buyers',
                                route: `${STATIC_PAGES_ROUTES.TRUST_ASSURANCE}/?component=verified-buyers`,
                                onClick: handleLinkClick,
                            },
                            {
                                name: 'Timely Payments',
                                route: `${STATIC_PAGES_ROUTES.TRUST_ASSURANCE}/?component=verified-buyers`,
                                onClick: handleLinkClick,
                            },
                        ],
                    },
                },
                {
                    name: 'Dispute Resolution', route: '',
                    subSection: {
                        title: 'Seller Benefits',
                        items: [
                            {
                                name: 'Issue Reporting',
                                route: `${STATIC_PAGES_ROUTES.TRUST_ASSURANCE}/?component=issue-reporting`,
                                onClick: handleLinkClick,
                            },
                            {
                                name: 'Mediation Services',
                                route: `${STATIC_PAGES_ROUTES.TRUST_ASSURANCE}/?component=mediation-services`,
                                onClick: handleLinkClick,
                            },
                        ],
                    },
                },
            ],
        },
        {
            title: 'Supplier',
            items: [
                {
                    name: 'Why Sell on SteelBazaar?', route: '',
                    subSection: {
                        title: 'Why Sell on SteelBazaar?',
                        items: [
                            {
                                name: 'Benefits & Features',
                                route: `${STATIC_PAGES_ROUTES.SELLER_CENTRAL}/?component=benifits-features`,
                                onClick: handleLinkClick,
                            },
                        ],
                    },
                },
                {
                    name: 'How to Get Started?', route: '',
                    subSection: {
                        title: 'How to Get Started',
                        items: [
                            {
                                name: 'Registration Process',
                                route: `${STATIC_PAGES_ROUTES.SELLER_CENTRAL}/?component=registration`,
                                onClick: handleLinkClick,
                            },

                            {
                                name: 'Digitize your Inventory',
                                route: `${STATIC_PAGES_ROUTES.SELLER_CENTRAL}/?component=inventory`,
                                onClick: handleLinkClick,
                            },
                            {
                                name: 'Store Front Creation Guide',
                                route: `${STATIC_PAGES_ROUTES.SELLER_CENTRAL}/?component=storeFront`,
                                onClick: handleLinkClick,
                            },
                            {
                                name: 'List Your Products',
                                route: `${STATIC_PAGES_ROUTES.SELLER_CENTRAL}/?component=publish`,
                                onClick: handleLinkClick,
                            },
                        ],
                    },
                },
                {
                    name: 'Advanced Selling Tools', route: '',
                    subSection: {
                        title: 'Advanced Selling Tools',
                        items: [
                            {
                                name: 'Sell Smart & Fast',
                                route: `${STATIC_PAGES_ROUTES.SELLER_CENTRAL}/?component=sellSmart`,
                                onClick: handleLinkClick,
                            },
                        ],
                    },
                },
            ],
        },
        {
            title: 'Buyer',
            items: [
                {
                    name: 'Why Buy on SteelBazaar?', route: '',
                    subSection: {
                        title: 'Why Buy on SteelBazaar?',
                        items: [
                            {
                                name: 'Benefits & Features',
                                route: `${STATIC_PAGES_ROUTES.CUSTOMER_BUYER}/?component=features`,
                                onClick: handleLinkClick,
                            },
                        ],
                    },
                },
                {
                    name: 'How to Get Started', route: '',
                    subSection: {
                        title: 'How to Get Started',
                        items: [
                            {
                                name: 'Buyer Registration Process',
                                route: `${STATIC_PAGES_ROUTES.CUSTOMER_BUYER}/?component=Registration`,
                                onClick: handleLinkClick,
                            },
                            {
                                name: 'Catalogue Sales',
                                route: `${STATIC_PAGES_ROUTES.CUSTOMER_BUYER}/?component=catalogue`,
                                onClick: handleLinkClick,
                            },
                            {
                                name: 'Placing RFQs & Orders',
                                route: `${STATIC_PAGES_ROUTES.CUSTOMER_BUYER}/?component=instant-quotation`,
                                onClick: handleLinkClick,
                            },

                        ],
                    },
                },
                {
                    name: 'Advanced Buying Tools', route: '',
                    subSection: {
                        title: 'Advanced Buying Tools',
                        items: [
                            {
                                name: 'Buyer Exclusive Features',
                                route: `${STATIC_PAGES_ROUTES.CUSTOMER_BUYER}/?component=features`,
                                onClick: handleLinkClick,
                            },
                        ],
                    },
                },
            ],
        },
        {
            title: 'Contact Us',
            items: [
                {
                    name: 'Customer Support', route: '',
                    subSection: {
                        title: 'Customer Support',
                        items: [
                            {
                                name: 'Phone & Email Chat Support',
                                route: `${STATIC_PAGES_ROUTES.CONTACT_US}`,
                                onClick: handleLinkClick,
                            },
                        ],
                    },
                },
                {
                    name: 'Office Location', route: '',
                    subSection: {
                        title: 'Office Location',
                        items: [
                            {
                                name: 'Principal Office',
                                route: `${STATIC_PAGES_ROUTES.CONTACT_US}`,
                                onClick: handleLinkClick,
                            },
                            {
                                name: 'Regional Offices',
                                route: `${STATIC_PAGES_ROUTES.CONTACT_US}`,
                                onClick: handleLinkClick,
                            },
                        ],
                    },
                },
                {
                    name: 'Partnerships', route: '',
                    subSection: {
                        title: 'Partnerships',
                        items: [
                            {
                                name: 'Channel Trade Partners',
                                route: `${STATIC_PAGES_ROUTES.PARTNERSHIP}/?component=channel-trade-partner`,
                                onClick: handleLinkClick,
                            },
                            {
                                name: 'Finance Partners',
                                route: `${STATIC_PAGES_ROUTES.PARTNERSHIP}/?component=finance-partners`,
                                onClick: handleLinkClick,
                            },
                            {
                                name: 'Logistic Partners',
                                route: `${STATIC_PAGES_ROUTES.PARTNERSHIP}/?component=logistic-partners`,
                                onClick: handleLinkClick,
                            },
                        ],
                    },
                },
                {
                    name: 'Careers', route: '',
                    subSection: {
                        title: 'Careers',
                        items: [
                            {
                                name: 'Job Openings',
                                route: `${STATIC_PAGES_ROUTES.CAREER}/?component=job-opening`,
                                onClick: handleLinkClick,
                            },
                            {
                                name: 'Life At SteelBazaar',
                                route: `${STATIC_PAGES_ROUTES.CAREER}/?component=life-at-steelbazaar`,
                                onClick: handleLinkClick,
                            },
                        ],
                    },
                },
            ],
        },
        {
            title: 'Help Center',
            items: [
                {
                    name: 'Help Center', route: '',
                    subSection: {
                        title: 'FAQs',
                        items: [
                            {
                                name: 'Buyer',
                                route: `${STATIC_PAGES_ROUTES.HELP_CENTER}`,
                                onClick: handleLinkClick,
                            },
                            {
                                name: 'Sellers',
                                route: `${STATIC_PAGES_ROUTES.HELP_CENTER}`,
                                onClick: handleLinkClick,
                            },
                            {
                                name: 'Payment & Shipping',
                                route: `${STATIC_PAGES_ROUTES.HELP_CENTER}`,
                                onClick: handleLinkClick,
                            },
                        ],
                    },
                },
                {
                    name: 'Tutorial', route: '',
                    subSection: {
                        title: 'Tutorial',
                        items: [
                            {
                                name: 'Platform',
                                route: '',
                                onClick: handleLinkClick,
                            },
                            {
                                name: 'Walkthrough',
                                route: '',
                                onClick: handleLinkClick,
                            },
                            {
                                name: 'Feature Specifies',
                                route: '',
                                onClick: handleLinkClick,
                            },
                        ],
                    },
                },
                {
                    name: 'Troubleshooting', route: '',
                    subSection: {
                        title: 'Troubleshooting',
                        items: [
                            {
                                name: 'Common Issues',
                                route: '',
                                onClick: handleLinkClick,
                            },
                            {
                                name: 'Reporting Bugs',
                                route: '',
                                onClick: handleLinkClick,
                            },
                        ],
                    },
                },
                {
                    name: 'Troubleshooting', route: '',
                    subSection: {
                        title: 'Troubleshooting',
                        items: [
                            {
                                name: 'Common Issues',
                                route: '',
                                onClick: handleLinkClick,
                            },
                            {
                                name: 'Reporting Bugs',
                                route: '',
                                onClick: handleLinkClick,
                            },
                        ],
                    },
                },
            ],
        },
    ];

    return (
        <div className={classes.container}>
            <div className=" my-1 flex justify-end" onClick={() => handleDialogClose(false)}>
                <CloseIcon className={classes.closeIcon} />
            </div>
            <div className=''>
                <AccordionV2 sections={menuSections} />
            </div>
        </div>
    );
}

export default MenuDialogTemplate;