import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { AuthenticatedUserType } from '../contexts/AuthenticatedUserContext';

const API_BASE_URL = process.env.REACT_APP_API_V1_URL;

const setAuthHeader = (userContext: AuthenticatedUserType | null): void => {
    if (userContext?.token) {
        axios.defaults.headers.common['Authorization'] = userContext.token;
    } else {
        delete axios.defaults.headers.common['Authorization'];
    }

};

export const request = async (
    method: AxiosRequestConfig['method'],
    url: string,
    userContext: AuthenticatedUserType | null,
    data?: any,
    options?: { params: { [key: string]: any } } | null,
    onUploadProgress?: any,
    baseUrl = API_BASE_URL,
    headers?: { [key: string]: string }
): Promise<any> => {
    setAuthHeader(userContext);
    try {
        const response = await axios({
            method,
            baseURL: baseUrl,
            url,
            data,
            ...(options ? options : {}),
            onUploadProgress,
            headers
        });
        return response;
    } catch (error) {

        const status = (error as AxiosError)?.response?.status;

        if (status === 401) {
          localStorage.removeItem("user");
          window.location.reload();
        }
    
        return (error as AxiosError)?.response ?? null;
    }
}