import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import Button from '../../../atoms/Button/Button';
import { Link } from 'react-router-dom';
import { STATIC_PAGES_ROUTES } from '../../../../utils/constant';
import navbarArrowIcon from '../../../../assets/icons/navbarArrowIcon.svg';
import Navbarplatform1 from '../../../../assets/images/Navbarplatform1.jpg';
import Navbarplatform2 from '../../../../assets/images/Navbarplatform2.jpg';
import Navbarplatform3 from '../../../../assets/images/Navbarplatform3.jpg';
import Navbarplatform4 from '../../../../assets/images/Navbarplatform4.jpg';
import Navbarplatform5 from '../../../../assets/images/Navbarplatform5.png';
import Navbarplatform6 from '../../../../assets/images/Navbarplatform6.png';
import Navbarplatform7 from '../../../../assets/images/Navbarplatform7.jpg';
import Navbarplatform8 from '../../../../assets/images/Navbarplatform8.jpg';
import Navbarplatform9 from '../../../../assets/images/Navbarplatform9.jpg';


const useStyles = createUseStyles((theme: any) => ({
    container: {
        background: theme.palette.background.neutral.neutral50,
        boxShadow: `0px 20px 20px 0px rgba(0, 0, 0, 0.25)`,
        borderTop: `1px solid ${theme.palette.border.neutral.neutral200}`,
        position: 'absolute',
        width: '100%',
        top: '135px',
        left: '0',
        zIndex: '99',
    },
    primaryContainer: {
      
    },
    dropdownContent: {
        "& h3": {
            color: '#000',
            fontSize: '22px',
            lineHeight: '26px',
        },
        "& h4": {
            lineHeight: '22px',
            color: '#000',
            borderBottom: `1px solid ${theme.palette.border.neutral.neutral200}`
        },
        "& p": {
            color: '#000',
            position: 'relative',
            paddingLeft: '24px',
            "&::before": {
                content: '""',
                display: 'inline-block',
                position: 'absolute',
                left: '12px',
                top: '50%',
                transform: 'translateY(-50%)',
                width: '8px',
                height: '12px',
                backgroundImage: `url(${navbarArrowIcon})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
            },
        },
    },
    dropdownImgContainer: {
        border: '4px solid white',
        borderRadius: '8px',
        boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.25)',
        overflow: 'hidden',
        '& img': {
            width: '100%',
        },
    },
}));

const PlateformDropdown: React.FC = () => {
    const classes = useStyles();

    const [hoveredImage, setHoveredImage] = useState<string>(Navbarplatform3);
    const [isDropdownOpen, setIsDropdownOpen] = useState(true);

    const imagesMap: Record<string, string> = {
        'Navbarplatform1': Navbarplatform1,
        'Navbarplatform2': Navbarplatform2,
        'Navbarplatform3': Navbarplatform3,
        'Navbarplatform4': Navbarplatform4,
        'Navbarplatform5': Navbarplatform5,
        'Navbarplatform6': Navbarplatform6,
        'Navbarplatform7': Navbarplatform7,
        'Navbarplatform8': Navbarplatform8,
        'Navbarplatform9': Navbarplatform9,

    };
    const handleLinkClick = () => {
        setIsDropdownOpen(false);
    };

    if (!isDropdownOpen) return null;

    return (
        <div className={`${classes.container} py-10`}>
            <div className={`${classes.primaryContainer} container mx-auto lg:px-4`}>
                <div className='flex w-full gap-[15px]'>
                    <div className='w-1/4 pr-10'>
                        <div className={`${classes.dropdownContent} flex flex-col `}>
                            <h3 className='font-bold mb-[10px]'>Marketplace</h3>
                            <div className='p-[10px]'>
                                <h4 className='font-semibold text-lg py-[5px] px-[10px] mb-1'>   <Link to={`${STATIC_PAGES_ROUTES.B2B_MARKETPLACE}`} onClick={handleLinkClick}>SB DigiTrade</Link></h4>
                                <p
                                    className="text-base leading-5 py-[5px] px-[10px]"
                                    onMouseEnter={() => setHoveredImage(imagesMap['Navbarplatform1'])}
                                >
                                    <Link to={`${STATIC_PAGES_ROUTES.B2B_MARKETPLACE}`} onClick={handleLinkClick}>B2B Marketplace</Link>
                                </p>
                            </div>
                        </div>
                        <div className={`${classes.dropdownContent} flex flex-col `}>
                            <div className='p-[10px]'>
                                <h4 className='font-semibold text-lg py-[5px] px-[10px] mb-1'><Link to={`${STATIC_PAGES_ROUTES.AUCTION} `} onClick={handleLinkClick}>SB BidHub</Link></h4>
                                <p
                                    className="text-base leading-5 py-[5px] px-[10px]"
                                    onMouseEnter={() => setHoveredImage(imagesMap['Navbarplatform1'])}
                                >
                                    <Link to={`${STATIC_PAGES_ROUTES.AUCTION}/?component=forward-sales-auction`} onClick={handleLinkClick}>Forward Sales Auction</Link>
                                </p>
                                <p
                                    className="text-base leading-5 py-[5px] px-[10px]"
                                    onMouseEnter={() => setHoveredImage(imagesMap['Navbarplatform1'])}
                                >
                                    <Link to={`${STATIC_PAGES_ROUTES.AUCTION}/?component=reverse-buy-auction`} onClick={handleLinkClick}>Reverse Buy Auction</Link>
                                </p>
                            </div>
                        </div>
                        <div className={`${classes.dropdownContent} flex flex-col `}>
                            <div className='p-[10px]'>
                                <h4 className='font-semibold text-lg py-[5px] px-[10px] mb-1'><Link to={`${STATIC_PAGES_ROUTES.B2B_MARKETPLACE}`} onClick={handleLinkClick}>SB LeadX</Link></h4>

                                <p
                                    className="text-base leading-5 py-[5px] px-[10px]"
                                    onMouseEnter={() => setHoveredImage(imagesMap['Navbarplatform1'])}
                                >
                                    <Link to={`${STATIC_PAGES_ROUTES.B2B_MARKETPLACE}/?component=reverse-buy-auction`} onClick={handleLinkClick}>SB Buy Leads</Link>
                                </p>
                                <p
                                    className="text-base leading-5 py-[5px] px-[10px]"
                                    onMouseEnter={() => setHoveredImage(imagesMap['Navbarplatform1'])}
                                >
                                    <Link to={`${STATIC_PAGES_ROUTES.B2B_MARKETPLACE}/?component=reverse-buy-auction`} onClick={handleLinkClick}>SB Sell Leads</Link>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='w-1/4 pr-10'>
                        <div className={`${classes.dropdownContent} flex flex-col `}>
                            <h3 className='font-bold mb-[10px]'>For Sellers/Vendors</h3>
                            <div className='p-[10px]'>
                                <h4 className='font-semibold text-lg py-[5px] px-[10px] mb-1'>SB DigiSell</h4>
                                <p
                                    className="text-base leading-5 py-[5px] px-[10px]"
                                    onMouseEnter={() => setHoveredImage(imagesMap['Navbarplatform1'])}
                                >
                                    <Link to={`${STATIC_PAGES_ROUTES.SELLER_CENTRAL}`} onClick={handleLinkClick}>Seller Central</Link>
                                </p>
                                <p
                                    className="text-base leading-5 py-[5px] px-[10px]"
                                    onMouseEnter={() => setHoveredImage(imagesMap['Navbarplatform1'])}
                                >
                                    <Link to={`${STATIC_PAGES_ROUTES.VENDOR_CENTRAL}`} onClick={handleLinkClick}>Vendor Central</Link>
                                </p>
                            </div>
                        </div>
                        <div className={`${classes.dropdownContent} flex flex-col `}>
                            <div className='p-[10px]'>
                                <h4 className='font-semibold text-lg py-[5px] px-[10px] mb-1'><Link to={`${STATIC_PAGES_ROUTES.DIGISTORE}`} onClick={handleLinkClick}>SB DigiStore </Link></h4>
                                <p
                                    className="text-base leading-5 py-[5px] px-[10px]"
                                    onMouseEnter={() => setHoveredImage(imagesMap['Navbarplatform4'])}
                                >
                                    <Link to={`${STATIC_PAGES_ROUTES.DIGISTORE}`} onClick={handleLinkClick}>Digital Store Front</Link>
                                </p>
                                <p
                                    className="text-base leading-5 py-[5px] px-[10px]"
                                    onMouseEnter={() => setHoveredImage(imagesMap['Navbarplatform5'])}
                                >
                                    <Link to={`${STATIC_PAGES_ROUTES.DIGI_STOCK}`} onClick={handleLinkClick}>Digital Inventory</Link>
                                </p>
                                <p
                                    className="text-base leading-5 py-[5px] px-[10px]"
                                    onMouseEnter={() => setHoveredImage(imagesMap['Navbarplatform6'])}
                                >
                                    <Link to={`${STATIC_PAGES_ROUTES.DIGITAL_CATALOG}`} onClick={handleLinkClick}>Digital Catalogue</Link>
                                </p>
                                <p className='text-base leading-5 py-[5px] px-[10px]'>Digital Order Manegment</p>
                            </div>
                        </div>
                    </div>
                    <div className='w-1/4 pr-10'>
                        <div className={`${classes.dropdownContent} flex flex-col `}>
                            <h3 className='font-bold mb-[10px]'>For Buyers</h3>
                            <div className='p-[10px]'>
                                <h4 className='font-semibold text-lg py-[5px] px-[10px] mb-1'>SB Get MySteel</h4>
                                <p
                                    className="text-base leading-5 py-[5px] px-[10px]"
                                    onMouseEnter={() => setHoveredImage(imagesMap['Navbarplatform6'])}
                                >
                                    <Link to={`${STATIC_PAGES_ROUTES.B2B_MARKETPLACE}/?component=compare-brands`} onClick={handleLinkClick}>Compare Brands</Link>
                                </p>
                            </div>
                        </div>
                        <div className={`${classes.dropdownContent} flex flex-col `}>
                            <div className='p-[10px]'>
                                <h4 className='font-semibold text-lg py-[5px] px-[10px] mb-1'><Link to={`${STATIC_PAGES_ROUTES.B2B_MARKETPLACE}/?component=request-for-quotation`} onClick={handleLinkClick}>SB Customize</Link></h4>
                                <p
                                    className="text-base leading-5 py-[5px] px-[10px]"
                                    onMouseEnter={() => setHoveredImage(imagesMap['Navbarplatform6'])}
                                >
                                    <Link to={`${STATIC_PAGES_ROUTES.B2B_MARKETPLACE}/?component=request-for-quotation`} onClick={handleLinkClick}>Request For Quotation</Link>
                                </p>
                            </div>
                        </div>
                        <div className={`${classes.dropdownContent} flex flex-col `}>
                            <div className='p-[10px]'>
                                <h4 className='font-semibold text-lg py-[5px] px-[10px] mb-1'>SB Pool&Save</h4>
                                <p className='text-base leading-5 py-[5px] px-[10px]'>Pool & Save</p>
                            </div>
                        </div>
                        <div className={`${classes.dropdownContent} flex flex-col `}>
                            <div className='p-[10px]'>
                                <h4 className='font-semibold text-lg py-[5px] px-[10px] mb-1'><Link to={`${STATIC_PAGES_ROUTES.B2B_MARKETPLACE}/?component=global`} onClick={handleLinkClick}>
                                    SB GlobalSource
                                </Link></h4>
                                <p
                                    className="text-base leading-5 py-[5px] px-[10px]"
                                    onMouseEnter={() => setHoveredImage(imagesMap['Navbarplatform1'])}
                                >
                                    <Link to={`${STATIC_PAGES_ROUTES.B2B_MARKETPLACE}/?component=global`} onClick={handleLinkClick}>
                                        Global Sourcing
                                    </Link>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className={`${classes.dropdownImgContainer} rounded-lg w-1/4 h-fit`}>
                        {hoveredImage && <img src={hoveredImage} alt="Hovered Image" />}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PlateformDropdown;


