import { createUseStyles } from "react-jss";
import Dialog from '@mui/material/Dialog';
import Button from "../../atoms/Button/Button";
import CloseIcon from '@mui/icons-material/Close';
import { useGstinService } from "../../../services/useGstService";
import { useEffect, useState } from "react";
import { createGstAdditionalAddressLines, createGstAddressLines } from "../../../utils/helper";
import closeIcon from "../../../assets/icons/closeRoundIcon.svg";
import { useBusinessProfileService } from "../../../services/useBusinessProfileService";

interface IGstnDetailModalTemplateProps {
    dialogOpen: boolean;
    setDialogOpen: (open: boolean) => void;
    locations: any;
    index: number;
}

interface PrincipalPlaceSplitAddress {
    addressLine: string;
    city: string[];
    country: string[];
    pincode: string;
    state: string[][];
}

interface AdditionalAddress {
    street: string;
    locality: string;
    state: string;
    city: string;
    pincode: string;
    buildingName: string;
    buildingNo: string;
}

export interface GstinResponseDTO {
    gstin: string;
    legalNameOfBusiness: string;
    tradeNameOfBusiness: string;
    registrationDate: string;
    constitutionOfBusiness: string;
    gstStatus: string;
    city: string[];
    state: string[][];
    country: string[];
    addressLine: string;
    pincode: string;
    additionalAddress: AdditionalAddress[];
    valid: boolean;
}

const useStyles = createUseStyles((theme: any) => ({
    titleText: {
        color: theme.palette.text.neutral.neutral800
    },
    container: {
        "& .MuiDialog-paper": {
            width: "555px !important",
            maxWidth: "555px !important",
            borderRadius: "16px"
        },
    },
    content: {
        color: theme.palette.text.neutral.neutral600
    },
    value: {
        color: theme.palette.text.neutral.neutral700
    },
    button: {
        paddingLeft: "89px !important",
        paddingRight: "89px !important",
        color: theme.palette.background.primary.primary500,
    },
    closeIcon: {
        color: theme.palette.background.primary.primary500
    },
    headingContainer: {
        borderBottom: `1px solid ${theme.palette.border.neutral.neutral100}`
    },
    gstNumber: {
        color: theme.palette.text.primary.primary800,
        fontSize: "28px",
        lineHeight: "34px"
    },
    location: {
        color: theme.palette.text.primary.primary800,
        lineHeight: "20px"
    },
    border: {
        border: `1px solid ${theme.palette.border.neutral.neutral100}`
    }
}));
const GntnDetailModalTemplate: React.FC<IGstnDetailModalTemplateProps> = ({ dialogOpen, setDialogOpen, locations, index }) => {
    const classes = useStyles();
    const gstinService = useGstinService();
    const [gstinDetail, setGstinDetail] = useState<GstinResponseDTO | null>(null);
    const [principalAddress, setPrincipalAddress] = useState<any | null>(null);
    const bussinessProfileService = useBusinessProfileService();

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const validateGstin = async () => {
        if (index === 0) {
            const response = await bussinessProfileService.getBusinessProfile();
           const business =  response?.data?.data?.address
           setGstinDetail({
            gstin: locations?.[0]?.gstin || "",
            legalNameOfBusiness: "",
            tradeNameOfBusiness: "",
            registrationDate: "",
            constitutionOfBusiness: "",
            gstStatus: "",
            city: [business.city] || [],
            pincode: business.pincode || "",
            state: business.state || [],
            country: [],
            addressLine: "",
            additionalAddress: [],
            valid: true
        });
        setPrincipalAddress([
            business.line1 || "",
            business.line2 || ""
        ]);        
        }
        else {
            const response = await gstinService.searchGstin({ gstin: locations?.[index]?.gstin });
            if (response?.data?.data?.valid) {
                setGstinDetail(response.data.data);
                const details = response?.data?.data;
                const gstSplitAddress = createGstAddressLines(details);
                setPrincipalAddress(gstSplitAddress);
            } else {
                setGstinDetail(null);
            }
        }
    }
    useEffect(() => {
        validateGstin()
    }, [locations?.[index]?.gstin]);

    return (
        <Dialog fullWidth className={classes.container} PaperProps={{ style: { height: 'auto' } }}
            open={dialogOpen} onClose={handleDialogClose}>
            <div className="p-6 grid gap-y-8">
                <div className={`pb-4 flex justify-between ${classes.headingContainer}`}>
                    <div className="grid">
                        <div className={`${classes.gstNumber} font-semibold`}>{gstinDetail?.gstin}</div>
                        <div className={`${classes.location} text-base`}>Location: {gstinDetail?.city[0]}</div>
                    </div>
                    <div className={`cursor-pointer flex justify-end`} onClick={handleDialogClose} >
                        <img src={closeIcon} alt="closeIcon" />
                    </div>
                </div>
                <div className={`grid gap-y-6`}>
                    <div className={`${classes.border} grid gap-y-3 p-4 rounded-xl`}>
                        <div className={`${classes.titleText} font-semibold text-base `}>Registered Office Address</div>
                        <div className="grid gap-1">
                            <div className={`${classes.content} text-sm`}>Address Line 1</div>
                            <div className={`${classes.value} text-base font-medium text-ellipsis overflow-hidden`}>{principalAddress?.[0]}</div>
                        </div>
                        <div className="grid gap-1">
                            <div className={`${classes.content} text-sm`}>Address Line 2</div>
                            <div className={`${classes.value} text-base font-medium text-ellipsis overflow-hidden`}>{principalAddress?.[1]}</div>
                        </div>
                        <div className="flex justify-between">
                            <div className={`${classes.content} text-sm grid gap-1`}>
                                <div className="text-sm">Pincode</div>
                                <div className={`${classes.value} text-base font-medium`}>{gstinDetail?.pincode}</div>
                            </div>
                            <div className={`${classes.content} text-sm grid gap-1`}>
                                <div className="text-sm">City</div>
                                <div className={`${classes.value} text-base font-medium`}>{gstinDetail?.city}</div>
                            </div>
                            <div className={`${classes.content} text-sm grid gap-1`}>
                                <div className="text-sm">State</div>
                                <div className={`${classes.value} text-base font-medium`}>{gstinDetail?.state}</div>
                            </div>
                        </div>
                    </div>

                    <div className="grid gap-y-6">
                        {
                            gstinDetail?.additionalAddress?.map((location: AdditionalAddress, index: number) => {
                                const addressLines = createGstAdditionalAddressLines(location);
                                return (
                                    <div key={index} className={`${classes.border} grid gap-y-3 p-4 rounded-xl`}>
                                        <div className={`${classes.titleText} font-semibold text-base`}>Additional business location {index + 2}</div>
                                        <div className="grid gap-1">
                                            <div className={`${classes.content} text-sm`}>Address Line 1</div>
                                            <div className={`${classes.value} text-base font-medium text-ellipsis overflow-hidden`}>{addressLines[0]}</div>
                                        </div>
                                        <div className="grid gap-1">
                                            <div className={`${classes.content} text-sm`}>Address Line 2</div>
                                            <div className={`${classes.value} text-base font-medium text-ellipsis overflow-hidden`}>{addressLines[1]}</div>
                                        </div>
                                        <div className="flex justify-between">
                                            <div className={`${classes.content} text-sm grid gap-1 `}>
                                                <div className="text-sm">Pincode</div>
                                                <div className={`${classes.value} text-base font-medium`}>{location.pincode}</div>
                                            </div>
                                            <div className={`${classes.content} text-sm grid gap-1 `}>
                                                <div className="text-sm  justify-self-center">City</div>
                                                <div className={`${classes.value} text-base font-medium`}>{location.city}</div>
                                            </div>
                                            <div className={`${classes.content} text-sm grid gap-1 `}>
                                                <div className="text-sm">State</div>
                                                <div className={`${classes.value} text-base font-medium`}>{location.state}</div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })
                        }
                    </div>

                </div>
                <div className="flex justify-end">
                    <Button
                        variant="tertiaryContained"
                        label="Back"
                        onClick={handleDialogClose}
                    />
                </div>
            </div>
        </Dialog>
    );
};

export default GntnDetailModalTemplate;