import { useEffect, useState } from "react";
import TabsV2 from "../../molecules/TabsV2/TabsV2";
import ProductViewTemplate from "../DashBoard/Inventory Management/ProductView.template";
import { createUseStyles } from "react-jss";
import { ITabsSchema } from "../../molecules/Tabs/Tabs";
import DeliveryInformationTemplate from "./DeliveryInformation.template";
import { ADD_EXISTING_PRODUCT, ADD_OWN_PRODUCT, CLASS_STATES } from "../../../utils/types";
import Button from "../../atoms/Button/Button";
import { useSnackbar } from "../../../hooks/useSnackBar";
import { AddVendorProductSectionProps } from "./AddExistingProduct.template";
import { capitalizeFirstLetter } from "../../../utils/helper";
import { AddVendorOwnProductSectionProps } from "./AddOwnProduct.template";
import { useNavigate } from "react-router-dom";
import { PRODUCTS_TYPES, VENDOR_PRODUCT_CLASS_TYPE, VENDOR_PRODUCT_ROUTES } from "../../../utils/constant";
import TextFieldV2 from "../../atoms/Input/TextFieldV2";
import IconButton from "../../atoms/Button/IconButton";
import plusIcon from "../../../assets/icons/addIconOutlinedPrimary50.svg"
import ErrorMessage from "../../atoms/ErrorMessage/ErrorMessage";

const useStyles = createUseStyles((theme: any) => ({
    activatedProgressBar: {
        backgroundColor: theme.palette.background.primary.primary500
    },
    activeProgressBar: {
        backgroundColor: theme.palette.background.primary.primary400
    },
    UnTouchProgressBar: {
        backgroundColor: theme.palette.border.neutral.neutral100
    },
    barHeading: {
        color: theme.palette.text.primary.primary500
    },
    unTouchedBarHeading:  {       
         color: theme.palette.text.neutral.neutral200,
    },
    previousActiveHeading: {
        color: theme.palette.background.primary.primary400
            },
    textHeading:{
        color: theme.palette.text.primary.primary900
    },
    container: {
        border: `1px solid ${theme.palette.border.neutral.neutral100}`,
        borderRadius: "12px",
    },
    mainHeading: {
        color: theme.palette.text.primary.primary900,
    },
    subHeading: {
        color: theme.palette.text.neutral.neutral700
    },
    button: {
        padding: '14px !important'
    },
    errorMessage: {
        color: theme.palette.text.secondary.secondary500,
    },
}));


const TotalOwnProductsTemplate: React.FC<AddVendorOwnProductSectionProps> = ({ warehouses, formik, setCurrentSectionTo, handleCancel, onBack, classType}) => {

    const classes = useStyles();
    const { showSnackbar } = useSnackbar();
    const navigate = useNavigate()

    const handleBack = () => {
        onBack()
    }
    
    const handleNext = () => {
        if (!formik.touched.noOfRows) {
            showSnackbar("error", "Number of products required");
            return;
        }
        if(formik.touched.noOfRows && Boolean(formik.errors.noOfRows)){
            return
        }
        setCurrentSectionTo(ADD_OWN_PRODUCT.PRODUCT_DETAILS);
    }

    useEffect(() => {
        document.getElementById("main")?.scroll(0, 0); 
    }, []);

    return (
        <div>
            <div >
                <div className={`w-full m-auto flex justify-between gap-x-2 text-var(--black, #4D4D4D) text-center text-sm`}>
                    <div className="grid gap-y-3 w-1/2">
                        <div className='flex gap-x-2 mx-auto'>
                            <div className={`${classes.activeProgressBar} w-2 h-2 my-auto rounded-full`}></div>
                            <div className={`${classes.previousActiveHeading} my-auto`}>Select Product Type </div>
                        </div>
                        <div className={`${classes.activatedProgressBar} h-2.5 rounded `}></div>
                    </div>
                    <div className="grid gap-y-3 w-1/2">
                        <div className='flex gap-x-2 mx-auto'>
                            <div className={`${classes.activatedProgressBar} w-2 h-2 my-auto rounded-full`}></div>
                            <div className={`${classes.barHeading} my-auto font-semibold`}>Add Product Details</div>
                        </div>
                        <div className={`${classes.activatedProgressBar} h-2.5 rounded `}></div>
                    </div>
                    <div className="grid gap-y-3 w-1/2">
                        <div className='flex gap-x-2 mx-auto'>
                            <div className={`${classes.UnTouchProgressBar} w-2 h-2 my-auto rounded-full`}></div>
                            <div className={`${classes.unTouchedBarHeading} my-auto`}>Review</div>
                        </div>
                        <div className={`${classes.UnTouchProgressBar} h-2.5 rounded `}></div>
                    </div>
                </div>
                <div className={`${classes.mainHeading} text-base font-medium mt-8`}>Add the Number of {classType ===  CLASS_STATES.STANDARD ? PRODUCTS_TYPES.STANDARD : PRODUCTS_TYPES.NON_STANDARD } Product</div>
                <div className={`${classes.subHeading} text-xs mb-6`}>Please select the number of products you want to add (max 10)</div>
                <div className={`p-4 flex flex-col gap-2.5 ${classes.container}`}>
                    <div className={`${classes.textHeading}`}>Enter the Number of products you want ( Max. 10 nos.)</div>
                    <div className={`flex flex-col gap-0.5`}>
                        <div className={`font-normal ${classes.subHeading}`}>Enter number of products</div>
                        <div className='flex w-1/2 gap-x-3 items-end'>
                            <div className="w-full">
                                <TextFieldV2
                                    {...formik.getFieldProps("noOfRows")}
                                    id="outlined-basic"
                                    label=""
                                    placeholder='Type here'
                                    type="number"
                                    error={formik.touched.noOfRows && Boolean(formik.errors.noOfRows)}
                                    fullWidth
                                    helperText=""
                                />
                                {formik.touched.noOfRows && Boolean(formik.errors.noOfRows) && (
                                    <div className={` ${classes.errorMessage}`}>
                                        <small>{formik.errors.noOfRows}</small>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='flex justify-between pt-6'>
                <Button
                    variant="tertiaryContained"
                    label="Cancel"
                    onClick={handleCancel}
                />
                <div className='flex gap-6 '>
                    <Button
                        variant="tertiaryContained"
                        label="Back"
                        onClick={handleBack}
                    />
                    {/* <Button
                        variant="secondaryContained"
                        label="Save As Draft"
                        onClick={handleBack}
                    /> */}
                    <Button
                        variant="primaryContained"
                        label="Next"
                        // disabled={!warehouses?.some((warehouse) => warehouse.id)}
                        onClick={handleNext}
                    />
                </div>
            </div>
        </div>
    )
}

export default TotalOwnProductsTemplate;
