import React from 'react'
import { createUseStyles } from 'react-jss';
import topSeller from "../../../assets/icons/topSeller.svg"
import sbTrusted from '../../../assets/icons/sbTrusted.svg';
import factory from '../../../assets/icons/factory.svg'
import Button from '../../atoms/Button/Button';
import { formatUOM, makeRoute, toInr } from '../../../utils/helper';
import { IBrandComparison, ISeller } from '../../../services/useBrandComparisonService';
import noImage from "../../../assets/images/noImage.jpg"
import { useNavigate } from 'react-router-dom';
import { CUSTOMER_ROUTES } from '../../../utils/constant';

interface CatalogueListCardTemplateProps {
    item: IBrandComparison;
    seller: ISeller
}

const useStyles = createUseStyles((theme: any) => ({
    card: {
        border: `1px solid ${theme.palette.border.neutral.neutral960}`,
    },
    border: {
        border: `1px solid ${theme.palette.border.neutral.neutral100}`,
    },
    catalogueName: {
        color: theme.palette.text.primary.primary900,
        lineHeight: "20px"
    },
    specification: {
        color: theme.palette.text.neutral.neutral980,
        lineHeight: "16px"
    },
    value: {
        color: "#2849A4",
        lineHeight: "20px"
    },
    seller: {
        color: theme.palette.text.neutral.neutral980,
        lineHeight: "16px"
    },
    companyName: {
        color: "#2849A4",
        lineHeight: "20px"
    },
    trader: {
        color: theme.palette.text.neutral.neutral600,
        lineHeight: "16px"
    },
    "@media (max-width: 767px)": {

    },
}));

const CatalogueListCardTemplate: React.FC<CatalogueListCardTemplateProps> = ({ item, seller }) => {
    const classes = useStyles();
    const navigate = useNavigate();

    const onViewCatalogue = (event: any) => {
        navigate(makeRoute(CUSTOMER_ROUTES.CATALOGUE_DESCRIPTION, { query: { "catalogueId": item.id, "classType": item.classType } }))
    }

    const handleVendorStoreFront = (id: number) => () => {
        navigate(`${CUSTOMER_ROUTES.STORE_FRONT}/${id}?tab=2`)
    }

    return (
        <div className={`${classes.card} mx-auto w-full p-4 flex gap-x-3 bg-white rounded-xl rounded-br-none`}>
            <img className='w-[140px] h-[140px] rounded-lg' src={item.catalogueImagePath[0]?? noImage} alt='ProductImage' />
            <div className={`${classes.border} py-[18px] px-[14px] rounded-xl flex flex-col justify-between flex-1 cursor-pointer`} onClick={onViewCatalogue}>
                <div className='flex justify-between'>
                    <div className={`${classes.catalogueName} text-xl font-semibold w-4/5`}>{item.name}</div>
                    <img className='w-[48px] h-[17px]' src={topSeller} alt='' />
                </div>
                <div className='flex justify-between'>
                    {Object.entries(item.attributes).map(([key, value]: any) => {
                        let [min, max, uom] = value;
                        min = min?.replace(/\.0*$/, '');
                        max = max?.replace(/\.0*$/, '');
                        const isRangeZero = min === max || max === '';
                        return (
                            <div key={key} className='grid gap-y-1'>
                                <div className={`${classes.specification} text-sm`}>{key}</div>
                                <div className={`${classes.value} text-base font-medium`}>{formatUOM(min, uom, isRangeZero ? min : `${min}-${max}`)}</div>
                            </div>
                        );
                    })}
                </div>
            </div>
            <div className={`${classes.border} w-[250px] py-[18px] px-[14px] rounded-xl flex flex-col justify-between`}>
                <div className='grid gap-y-1 h-fit'>
                    <div className={`${classes.seller} text-sm`}>Seller</div>
                    <div className={`${classes.companyName} text-base font-medium underline decoration-solid decoration-auto underline-offset-auto cursor-pointer`} onClick={handleVendorStoreFront(seller?.id)}>{seller?.name}</div>
                </div>
                <div className='flex gap-x-6'>
                    {seller?.isTrusted && <img className='w-[33px]' src={sbTrusted} alt="" />}
                    <div className='flex'>
                        <img src={factory} alt="" />
                        <div className={`${classes.trader} text-sm font-medium my-auto`}>{seller?.businessType}</div>
                    </div>
                </div>
            </div>

            <div className={`${classes.border} w-[272px] py-[18px] px-[14px] rounded-xl flex flex-col justify-between`}>
                <div className='grid gap-y-2'>
                    <div className={` text-base font-bold leading-[22px]`}>{seller?.minimumPrice === seller?.maximumPrice  ? `${toInr(seller?.minimumPrice)}` : `${toInr(seller?.minimumPrice)} - ${toInr(seller?.maximumPrice)}`}*/MT</div>
                    <div className={`${classes.trader} text-xs !leading-[14px]`}>Exclu. GST @18%</div>
                </div>
                <div className='flex justify-between'>
                    <div className='flex gap-x-1'>
                        <div className={`${classes.specification} text-xs !leading-[14px]`}>Location:</div>
                        <div className={`${classes.value} text-xs !leading-[14px]`}>{seller?.locationName}</div>
                    </div>
                    <div className='flex gap-x-1'>
                        <div className={`${classes.specification} text-xs !leading-[14px]`}>MOQ:</div>
                        <div className={`${classes.value} text-xs !leading-[14px]`}>{seller?.moq} MT</div>
                    </div>
                </div>
                <Button fullWidth label={"View Details"} onClick={onViewCatalogue} variant='tertiaryContained' size='small' />
            </div>
        </div>
    );
};

export default CatalogueListCardTemplate;