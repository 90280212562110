import React, { useRef } from "react";
import { createUseStyles } from "react-jss";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import CarouselRef from "react-multi-carousel";
import rightArrow from "../../../../assets/icons/rightArrow.svg";
import leftArrow from "../../../../assets/icons/leftArrow.svg";
import slittingSvg from "../../../../assets/images/Slitting.jpg";
import cncSvg from "../../../../assets/images/cnc.jpg";
import metalPrintingSvg from "../../../../assets/images/MetalPrinting.jpg";
import dieCastingSvg from "../../../../assets/images/DieCasting.jpg";
import forgingSvg from "../../../../assets/images/Forging.jpg";
import fabricationSvg from "../../../../assets/images/Fabrication.jpg";
import sheetMetalSvg from "../../../../assets/images/SheetMetalStamping.jpg";
import shearingSvg from "../../../../assets/images/Shearing.jpg";

const useStyles = createUseStyles((theme: any) => ({
  mainContainer: {
    marginBottom: "100px",
  },
  heading: {
    fontSize: "48px",
    lineHeight: "58px",
    color: theme.palette.text.neutral.neutral900,
    "& span": {
      color: theme.palette.text.primary.primary500,
    },
  },
  slide: {
    width: "250px",
    borderRadius: "12px",
    "&:hover": {
      boxShadow: "0px 5px 16px 0px rgba(8, 15, 52, 0.08)",
    },
  },
  tab: {
    marginRight: "16px",
  },
  image: {
    borderTopLeftRadius: "12px",
    borderTopRightRadius: "12px",
  },
  containerItem: {
    border: `1px solid ${theme.palette.border.neutral.neutral100}`,
    borderTop: "none",
    borderBottomLeftRadius: "12px",
    borderBottomRightRadius: "12px",
  },
  title: {
    color: theme.palette.text.primary.primary900,
    lineHeight: "22px",
  },
  description: {
    color: theme.palette.text.neutral.neutral600,
    lineHeight: "20px",
  },
  customButtonContainer: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
  },
  carouselContainer: {
    "& .react-multiple-carousel__arrow--right": {
      right: "0 !important",
    },
    "& .react-multiple-carousel__arrow--left": {
      left: "0 !important",
    },
    "& .react-multi-carousel-item": {},
    "& .react-multi-carousel-list": {
      width: "100%",
      margin: "0 auto",
    },
    "& .react-multi-carousel-list li": {
      width: "fit-content !important",
    },
  },
  customArrow: {
    borderRadius: "50%",
    width: "48px",
    height: "48px",
    cursor: "pointer",
    transition: "box-shadow 0.3s ease-in-out",
    boxShadow: `0px 6px 12px 2px rgba(10, 18, 41, 0.16), 0px 2px 4px 1px rgba(10, 18, 41, 0.04)`,
  },
  carouselMobileContainer: {
    display: "none",
  },
  "@media (max-width: 767px)": {
    mainContainer: {
      width: "100%",
      rowGap: "40px",
      marginBottom: "0px",
      padding: "0px 12px",
    },
    heading: {
      fontSize: "28px",
      lineHeight: "34px",
    },
    carouselContainer: {
      display: "none",
    },
    carouselMobileContainer: {
      display: "block",
    },
    tab: {
      marginRight: "0px",
    },
  },
}));

const ServicesTemplate: React.FC = () => {
  const classes = useStyles();
  const carouselRef = useRef<CarouselRef>(null);
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
      partialVisibilityGutter: 0,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1280 },
      items: 5,
      partialVisibilityGutter: -27,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
      partialVisibilityGutter: 10,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
      partialVisibilityGutter: 0,
    },
  };

  const data = [
    {
      imgSrc: slittingSvg,
      title: "Slitting",
      description:
        "Splitting wide coiled sheet metal into narrower widths or for edge trimming of rolled sheet.",
    },
    {
      imgSrc: cncSvg,
      title: "CNC Laser Machining",
      description:
        "Cutting or shearing metal sheet  into predetermined shapes and sizes.",
    },
    {
      imgSrc: metalPrintingSvg,
      title: "Metal Printing",
      description:
        "Printing  high-quality images and text onto various types of sheet metal surfaces",
    },
    {
      imgSrc: dieCastingSvg,
      title: "Die Casting",
      description:
        "Providing safe material handling and proper storage for Steel Coil and Sheet products.",
    },
    {
      imgSrc: forgingSvg,
      title: "Logistics",
      description:
        "Integrated Transport Services for Delivering Steel Materials safe and Just in Time.",
    },
    {
      imgSrc: fabricationSvg,
      title: "Fabrication - Profiling",
      description:
        "Cutting the Coils into desired Sheet Lengths with precise tolerances.",
    },
    {
      imgSrc: sheetMetalSvg,
      title: "Sheet Metal Stamping",
      description:
        "Cutting the Coils into desired Sheet Lengths with precise tolerances.",
    },
    {
      imgSrc: shearingSvg,
      title: "Shearing",
      description:
        "Cutting the Coils into desired Sheet Lengths with precise tolerances.",
    },
  ];

  const ButtonGroup = ({ next, previous, carouselState }: any) => {
    const { currentSlide, totalItems, slidesToShow } = carouselState;
    const isFirstSlide = currentSlide === 0;
    const isLastSlide =
      totalItems === 0 || currentSlide === totalItems - slidesToShow;

    return (
      <div
        className={`${classes.customButtonContainer} flex justify-between items-center w-full`}
      >
        {!isFirstSlide && (
          <div
            className={`${classes.customArrow} bg-white flex justify-center items-center`}
            onClick={previous}
          >
            <img src={leftArrow} alt="" />
          </div>
        )}
        {!isLastSlide && (
          <div
            className={`${classes.customArrow} bg-white flex justify-center items-center ml-auto`}
            onClick={next}
          >
            <img src={rightArrow} alt="" />
          </div>
        )}
      </div>
    );
  };

  return (
    
       <div className={`container mx-auto lg:px-4 overflow-x-auto overflow-hidden `}>
        <div className={`headingContainer`}>
          <div className="heading">
            Our Value <span>Added Services</span>
          </div>
        </div>

        <div className={`${classes.carouselContainer} relative py-8 px-16`}>
          <Carousel
            responsive={responsive}
            showDots={false}
            infinite={false}
            partialVisible={true}
            ref={carouselRef}
            arrows={false}
            removeArrowOnDeviceType={["sm-mobile"]}
            renderButtonGroupOutside={true}
            customButtonGroup={<ButtonGroup />}
          >
            {data.map((item: any, index: any) => (
              <div
                className={` ${classes.slide} h-full grid ${
                  index !== data.length - 1 ? classes.tab : ""
                }`}
                key={item.id}
              >
                <img
                  className={classes.image}
                  src={item.imgSrc}
                  alt="industryImage"
                />
                <div
                  className={`${classes.containerItem} p-[15px] grid gap-y-1`}
                >
                  <div className={`${classes.title} text-lg font-bold`}>
                    {item.title}
                  </div>
                  <div className={`${classes.description} text-base h-[82px]`}>
                    {item.description}
                  </div>
                </div>
              </div>
            ))}
          </Carousel>
        </div>

        <div
          className={`overflow-x-auto overflow-hidden w-full ${classes.carouselMobileContainer}`}
        >
          <div className={`flex gap-x-3 w-max`}>
            {data.map((item: any, index: any) => (
              <div
                className={` ${classes.slide} h-full grid ${
                  index !== data.length - 1 ? classes.tab : ""
                }`}
                key={item.id}
              >
                <img
                  className={classes.image}
                  src={item.imgSrc}
                  alt="industryImage"
                />
                <div
                  className={`${classes.containerItem} p-[15px] grid gap-y-1`}
                >
                  <div className={`${classes.title} text-lg font-bold`}>
                    {item.title}
                  </div>
                  <div className={`${classes.description} text-base h-[82px]`}>
                    {item.description}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    
  );
};

export default ServicesTemplate;
