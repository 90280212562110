import React, { useState, useRef, useEffect } from "react";
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import { createUseStyles } from "react-jss";
import Button from "../../atoms/Button/Button";
import TextFieldV2 from "../../atoms/Input/TextFieldV2";

interface MoreFiltersTemplateProps {
    formik: any;
    filterData: FilterData;
    data: any;
    setIsFilterApply: (value: boolean) => void;
    isFilterApply: boolean;
}

interface FilterOption {
    label: string;
    value: number | string;
}

interface RangeOption {
    minValue: number | null;
    maxValue: number | null;
}

interface FilterCategory {
    image: string;
    // options: Record<string, FilterOption[]>;
    options: Record<string, FilterOption[] | RangeOption>;
}

interface FilterData {
    [key: string]: FilterCategory;
}

const useStyles = createUseStyles((theme: any) => ({
    container: {
        color: theme.palette.text.neutral.neutral700,
        fontSize: "14px",
        lineHeight: "16px",
        border: `1px solid #CACCCE`,
    },
    number: {
        color: "#FFF",
        backgroundColor: theme.palette.background.primary.primary500,
    },
    moreFilter: {
        color: theme.palette.text.neutral.neutral700,
    },
    selectedValue: {
        color: theme.palette.text.primary.primary500,
        backgroundColor: theme.palette.background.primary.primary50,
        lineHeight: "14px"
    },
    unselectedValue: {
        color: theme.palette.text.neutral.neutral600,
        lineHeight: "14px",
        border: `1px solid #DEDEDE`,
    },
    more: {
        color: theme.palette.text.primary.primary500,
        backgroundColor: theme.palette.background.primary.primary50,
        lineHeight: "14px",
        fontWeight: 500,
        fontSize: "10px",
        borderRadius: "100px",
        padding: "6.5px 3.5px"
    },
    leftContainer: {
        padding: "20px 12px 20px 28px",
        cursor: "pointer",
        color: theme.palette.text.neutral.neutral800,
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "20px",
        borderBottom: `1px solid ${theme.palette.border.neutral.neutral100}`,
        "&:hover": {
            backgroundColor: "#F7F8FD"
        }
    },
    dot: {
        backgroundColor: theme.palette.background.primary.primary500,
    },
    selectedleftContainer: {
        color: theme.palette.text.primary.primary500,
        backgroundColor: "#F7F8FD",
        fontWeight: 500,
    },
    subCategory: {
        color: theme.palette.text.neutral.neutral800,

    },
    heading: {
        color: theme.palette.text.neutral.neutral800,

    },
    buttonContainer: {
        boxShadow: "0px -32px 9px 0px rgba(217, 217, 217, 0.00), 0px -20px 8px 0px rgba(217, 217, 217, 0.01), 0px -11px 7px 0px rgba(217, 217, 217, 0.05), 0px -5px 5px 0px rgba(217, 217, 217, 0.09), 0px -1px 3px 0px rgba(217, 217, 217, 0.10)"
    },
    "@media (max-width: 767px)": {

    },
}));

const MoreFiltersTemplate: React.FC<MoreFiltersTemplateProps> = ({ formik, filterData, data , setIsFilterApply, isFilterApply}) => {
    const classes = useStyles();

    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);

    const toggleDropdown = () => setIsOpen(!isOpen);

    const categoryKeys = Object.keys(filterData) as (keyof typeof filterData)[];
    const [selectedCategory, setSelectedCategory] = useState<keyof typeof filterData>(categoryKeys[0]);

    const handleClickOutside = (event: MouseEvent) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, []);


    const toCamelCase = (str: string) => {
        return str.replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => index === 0 ? word.toLowerCase() : word.toUpperCase()).replace(/\s+|\/+/g, '');
    };

    const handleSelect = (option: { label: string; value: number }, subCategory: string) => {
        const formikField = `${toCamelCase(subCategory)}Array`
        if (formikField) {
            const currentValues = formik.values[formikField] || [];
            const exists = currentValues.some((v: { value: number }) => v.value === option.value);

            const newValues = exists ? currentValues.filter((v: { value: number }) => v.value !== option.value) : [...currentValues, option];
            formik.setFieldValue(formikField, newValues);
        }
    };

    const handleRangeChange = (subCategory: string, key: any, value: any) => {
        const formikField = `${toCamelCase(subCategory)}`;
        formik.setFieldValue(formikField, {
            ...formik.values[formikField],
            [key]: value,
        });
    };

    const getSelectedCount = () => {
        let count = 0;
        categoryKeys.forEach((category) => {
            const hasSelectedSubcategory = Object.keys(filterData[category].options).some((subCategory) => {
                const formikField = `${toCamelCase(subCategory)}Array`;
                return formik.values[formikField]?.length > 0;
            });
            if (hasSelectedSubcategory) {
                count++;
            }

        });

        if (formik.values?.priceRange?.minValue && formik.values?.priceRange?.maxValue) {
            count++;
        }

        if (formik.values?.minimumOrderQuantity?.minValue && formik.values?.minimumOrderQuantity?.maxValue) {
            count++;
        }

        if (formik.values?.thickness?.minValue || formik.values?.thickness?.maxValue) {
            count++;
        }

        if (formik.values?.width?.minValue || formik.values?.width?.maxValue) {
            count++;
        }
        return count;
    };

    const selectedCount = getSelectedCount();

    const handleClearAll = () => {
        formik.setValues({
            ...formik.values,
            standardArray: [],
            gradeArray: [],
            shapeArray: [],
            stocksAvailablityArray: [],
            customisationsArray: [],
            milltestArray: [],
            deliveryTimeArray: [],
            packagingTypeArray: [],
            certificationStandardArray: [],
            priceRange: { minValue: "", maxValue: "" },
            minimumOrderQuantity: { minValue: "", maxValue: "" },
            thickness: { minValue: "", maxValue: "" },
            width: { minValue: "", maxValue: "" },
        })
    }

    const handleOpenButton = () => {
        setIsOpen(false);
        setIsFilterApply(!isFilterApply);
    }

    return (
        <div id="moreFilter" className={`relative`} ref={dropdownRef} >
            <div className={`${classes.container} rounded-[100px] pl-2 pr-3 py-2 flex items-center justify-between cursor-pointer`} onClick={toggleDropdown}>
                <div className="flex justify-between gap-y-1">
                    <div className={`${classes.number} py-1 px-[7px] text-sm font-semibold leading-4 rounded-full mr-1`}>{selectedCount}</div>
                    <div className={`${classes.moreFilter} my-auto text-sm font-medium leading-4`}>More Filters</div>
                </div>
                {isOpen ? <ArrowDropUp /> : <ArrowDropDown />}
            </div>

            {isOpen && (
                <div className="absolute -left-96 mt-2 w-[920px] max-h-[601px] bg-white rounded-xl z-20 flex border">
                    <div className="flex flex-col w-[34%] border-r border-gray-200 overflow-y-auto max-h-[600px]">
                        <div className={`${classes.heading} text-base font semibold leading-5 pt-6 px-6 pb-3`}>Select Options</div>
                        <div className="w-full h-full overflow-y-auto">
                            {categoryKeys.map((category) => (
                                <div key={category} className={`flex justify-between ${classes.leftContainer} ${selectedCategory === category ? classes.selectedleftContainer : ""}`} onClick={() => setSelectedCategory(category)}>
                                    <div className="flex gap-x-2">
                                        <img src={filterData[category]?.image} alt="icon" className="w-[18px] h-[18px] my-auto" />
                                        <div>{category}</div>
                                    </div>
                                    {selectedCategory === category && <div className={`${classes.dot} my-auto w-[6px] h-[6px] rounded-full`}> </div>}
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className="w-[66%] flex flex-col justify-between">
                        <div className=" p-4 overflow-y-auto">
                            <div className="grid gap-y-8 py-[46px] px-[18px]">
                                {selectedCategory && filterData[selectedCategory] && (
                                    Object.entries(filterData[selectedCategory].options).map(([subCategory, options]) => (
                                        <div key={subCategory} className="grid gap-y-3">
                                            <div className={`${classes.subCategory} text-base font-medium leading-5`}>{subCategory}</div>
                                            {/* {options?.length > 0 &&
                                                <div className="flex flex-wrap gap-x-6 gap-y-3">
                                                    {(options as { label: string; value: number; }[])?.map((option) => (
                                                        <div key={option.value}
                                                            className={`text-xs font-medium leading-[14px] ${formik.values[`${toCamelCase(subCategory)}Array`]?.some((v: { value: number }) => v.value === option.value) ? classes.selectedValue : classes.unselectedValue} py-2 px-3 flex gap-x-1 items-center cursor-pointer rounded-full`}
                                                            onClick={() => handleSelect(option, subCategory)}
                                                        >
                                                            {formik.values[`${toCamelCase(subCategory)}Array`]?.some((v: { value: number }) => v.value === option.value) ? (<CheckIcon style={{ fontSize: 14 }} />) : (<AddIcon style={{ fontSize: 14 }} />)}
                                                            <span>{option.label}</span>
                                                        </div>
                                                    ))}
                                                </div>
                                            } */}
                                            {Array.isArray(options) ? (
                                                <div className="flex flex-wrap gap-x-6 gap-y-3">
                                                    {(options as { label: string; value: number; }[])?.map((option) => (
                                                        <div key={option.value}
                                                            className={`text-xs font-medium leading-[14px] ${formik.values[`${toCamelCase(subCategory)}Array`]?.some((v: { value: number }) => v.value === option.value) ? classes.selectedValue : classes.unselectedValue} py-2 px-3 flex gap-x-1 items-center cursor-pointer rounded-full`}
                                                            onClick={() => handleSelect(option, subCategory)}
                                                        >
                                                            {formik.values[`${toCamelCase(subCategory)}Array`]?.some((v: { value: number }) => v.value === option.value) ? (<CheckIcon style={{ fontSize: 14 }} />) : (<AddIcon style={{ fontSize: 14 }} />)}
                                                            <span>{option.label}</span>
                                                        </div>
                                                    ))}
                                                </div>
                                            ) : (
                                                <div className="flex gap-x-4">
                                                    <TextFieldV2
                                                        type="number"
                                                        label="Minimum"
                                                        placeholder="Type Here"
                                                        value={formik.values[`${toCamelCase(subCategory)}`]?.minValue || ""}
                                                        onChange={(e) => handleRangeChange(subCategory, 'minValue', e.target.value)}
                                                    />
                                                    <div className="mt-2.5 flex justify-center content-center self-center">-</div>
                                                    <TextFieldV2
                                                        type="number"
                                                        label="Maximum"
                                                        placeholder="Type Here"
                                                        value={formik.values[`${toCamelCase(subCategory)}`]?.maxValue || ""}
                                                        onChange={(e) => handleRangeChange(subCategory, 'maxValue', e.target.value)}
                                                        error={parseInt(formik.values[`${toCamelCase(subCategory)}`]?.minValue as string) > parseInt(formik.values[`${toCamelCase(subCategory)}`]?.maxValue as string)}
                                                        helperText={parseInt(formik.values[`${toCamelCase(subCategory)}`]?.minValue as string) > parseInt(formik.values[`${toCamelCase(subCategory)}`]?.maxValue as string) && 'Max must be greater than Min'}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    )
                                    )
                                )}
                            </div>
                        </div>
                        <div className={`p-4 flex justify-end gap-x-6 ${classes.buttonContainer}`}>
                            <Button size="medium" label={"Clear All"} variant="tertiaryContained" onClick={handleClearAll} />
                            <Button size="medium" label={`View Items`} variant="primaryContained" onClick={handleOpenButton} />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default MoreFiltersTemplate;