import React, { useState, useEffect, useRef } from 'react';
import { createUseStyles } from 'react-jss';
import footerLogo from '../../../../src/assets/images/footerLogo.svg';
import TextField from '../../atoms/Input/TextField';
import rightArrow from '../../../assets/icons/rightArrowButton.svg';
import { CUSTOMER_ROUTES, REGEX, STATIC_PAGES_ROUTES } from '../../../utils/constant';
import { useEmailSubscriptionService } from '../../../services/useEmailService';
import { useSnackbar } from '../../../hooks/useSnackBar';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { EMAIL_SUBSCRIPTION_CONTEXT } from '../../../utils/types';
import { Link, useNavigate } from 'react-router-dom';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/joy';
import location_icon from '../../../assets/icons/location_icon.svg';
import mail_icon from '../../../assets/icons/mail_icon.svg';
import facebook_Icon_1 from '../../../assets/icons/facebook_Icon_1.svg';
import linkedin_Icon_1 from '../../../assets/icons/linkedin_Icon_1.svg';
import x_Icon_1 from '../../../assets/icons/x_Icon_1.svg';
import youtube_Icon_1 from '../../../assets/icons/youtube_Icon_1.svg';
import whatsapp_Icon_1 from '../../../assets/icons/whatsapp_Icon_1.svg';
import useMediaQuery from '@mui/material/useMediaQuery';
import CloseIcon from '@mui/icons-material/Close';
import call_icon from '../../../assets/icons/call_icon.svg';

const useStyles = createUseStyles((theme: any) => ({
    container: {
        background: "#fff",
        padding: "50px 70px 40px 70px",
    },
    containerPopUp: {
        width: '70%',
        height: '400px',
        margin: '0 auto',
        boxShadow:
            '1px 1px 4px 0px rgba(0, 0, 0, 0.20)',
        borderRadius: '8px',
        position: 'absolute',
        top: '20%',
        right: '0%',
        left: '0%',
        bottom: '100px',
        backgroundColor: '#fff',
        zIndex: 1000,
        display: 'none',
        overflow: 'hidden',
    },
    topContainer: {
    },
    footerHeading: {
        color: "#000",
        lineHeight: '20px',
        marginBottom: '10px',
    },
    textdemo: {
        display: 'flex',
        justifyContent: 'space-between !important',
    },
    btn: {
        background: theme.palette.background.primary.primary500,
        color: "white",
        borderRadius: '50px',
        height: '48px',
        width: '152px',
    },
    inputSearch: {
        border: `0.8px solid ${theme.palette.border.primary.primary500}`,
        borderRadius: '45px',
        overflow: 'hidden',
        background: 'white',
        width: '512px',
        "& .MuiInputBase-root": {
            border: 'none',
            background: '#fff',
            width: '350px',
            color: 'black',
        },
    },
    middleContainer: {
        padding: "25px 70px",
        background: '#F9F9F9',
        borderTop: `1px solid ${theme.palette.border.neutral.neutral300}`,
        display: 'flex',
        flexDirection: 'row',

    },
    tagLine: {
        color: theme.palette.text.neutral.neutral800,
        lineHeight: '16px',
    },
    companyName: {
        color: theme.palette.text.neutral.neutral700,
        lineHeight: '22px',
    },
    address: {
        color: theme.palette.text.neutral.neutral700,
        lineHeight: '16px',
        "& div": {
            color: theme.palette.text.neutral.neutral700,
            lineHeight: '16px',
        },
    },
    webContainer: {
        display: "flex",
        flexDirection: "column"
    },
    footerContent: {
        transition: 'all 0.5s ease',
        "& ul": {
            "& li": {
                position: 'relative',
                color: theme.palette.text.neutral.neutral700,
                fontSize: '14px',
                fontWeight: '400',
                marginBottom: '8px',
                textDecoration: 'none',
                transition: 'padding-left 0.3s ease',
                "&::before": {
                    content: `''`,
                    position: 'absolute',
                    left: '-5px',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    width: '20px',
                    height: '20px',
                    background: `url(${rightArrow}) no-repeat center center`,
                    backgroundSize: 'contain',
                    opacity: 0,
                    transition: 'opacity 0.3s ease, left 0.3s ease',
                },
                "&:hover": {
                    paddingLeft: '25px',
                    color: theme.palette.text.primary.primary700,
                },
                "&:hover::before": {
                    left: '0',
                    opacity: 1,
                },
                "& a": {
                    textDecoration: 'none',
                    color: 'inherit',
                },
            },
        },
    },
    sectionTitle: {
        color: theme.palette.text.primary.primary500,
        lineHeight: "20px",
    },
    bottomContainer: {
        background: theme.palette.background.neutral.neutral900,
        padding: '10px 70px',
        display: "flex",
        justifyContent: "space-between"
    },
    copyRight: {
        color: theme.palette.text.neutral.neutral50,
        lineHeight: '16px',
    },
    text: {
        color: `${theme.palette.text.neutral.neutral50} !important `,
        lineHeight: '16px',
        '&:hover': {
            color: theme.palette.text.primary.primary900,
        },
    },
    footerChildContainer: {
        width: '190px',
    },
    mobileFooterContainer: {
        display: 'none',
    },
    primaryContainer: {

    },
    footerBottomLinks: {
        marginBottom: "-8px",
    },
    bottomMobileContainer: {

    },
    listItem: {},
    socialLinks: {
        columnGap: "28px",
    },
    mobileFooter: {},
    visible: {
        display: 'block',
        animation: '$slideDown 0.5s ease forwards',
    },
    hidden: {
        animation: '$slideUp 0.5s ease forwards',
    },
    mapOuter: {
        position: "relative",
        textAlign: "right",
        width: "100%",
        height: "384px",
    },
    gmapCanvas: {
        overflow: "hidden",
        background: "none !important",
        width: "100%",
        height: "384px",
    },
    gmapIframe: {
        width: "100% !important",
        height: "384px !important",
        border: 0,
    },
    '@keyframes slideDown': {
        from: { transform: 'translateY(20px)', opacity: 0 },
        to: { transform: 'translateY(0)', opacity: 1 },
    },
    '@keyframes slideUp': {
        from: { transform: 'translateY(0)', opacity: 1 },
        to: { transform: 'translateY(20px)', opacity: 0 },
    },
    mapContainer: {
        position: 'relative',
    },
    crossIcon: {
        position: 'absolute',
        top: '0px',
        background: '#fff',
        right: '0',
        padding: '10px',
        borderRadius: '50%',
        cursor: 'pointer',
    },
    "@media (max-width: 768px)": {
        container: {
            display: 'none'
        },
        mobileFooter: {
            padding: "50px 12px 40px 12px"
        },
        textdemo: {
            display: 'flex',
            flexDirection: 'column',
            gap: '10px'
        },
        socialLinks: {
            columnGap: "10px",
            justifyContent: "center"
        },
        bottomContainer: {
            background: theme.palette.background.neutral.neutral900,
            padding: '10px 12px',
            display: 'flex',
            flexDirection: 'column',
            gap: '10px'

        },
        webContainer: {
            display: "none",
        },
        footerHeading: {
            fontSize: '16px',
        },
        inputSearch: {
            width: '100%',
            "& .MuiInputBase-root": {
                width: '100%',
            },
        },
        primaryContainer: {
            backgroundColor: '#F8F8F8',
            marginBottom: "10px",
            borderBottom: '1px solid #B0B3B5',
        },
        sectionTitle: {
            color: theme.palette.text.neutral.neutral800,
        },
        mobileFooterContainer: {
            display: 'block',
        },
        bottomMobileContainer: {
            background: theme.palette.background.neutral.neutral900,
        },
        listItem: {
            margin: '8px 0',
            '& a': {
                textDecoration: 'none',
                color: '#007BFF',
                '&:hover': {
                    textDecoration: 'underline',
                },
            },
        },
        middleContainer: {
            padding: "25px 12px",
            background: '#F9F9F9',
            borderTop: `1px solid ${theme.palette.border.neutral.neutral300}`,
            display: 'flex',
            flexDirection: 'column',
        },
        footerContent: {
            transition: 'all 0.5s ease',
            "& ul": {
                "& li": {
                    position: 'relative',
                    color: theme.palette.text.neutral.neutral700,
                    fontSize: '14px',
                    fontWeight: '400',
                    marginBottom: '8px',
                    textDecoration: 'none',
                    transition: 'padding-left 0.3s ease',
                    "&::before": {
                        content: `''`,
                        position: 'absolute',
                        left: '-5px',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        width: '20px',
                        height: '20px',
                        backgroundSize: 'contain',
                        opacity: 0,
                        transition: 'opacity 0.3s ease, left 0.3s ease',
                    },
                    "&:hover::before": {
                        left: '0',
                        opacity: 1,
                    },
                    "& a": {
                        textDecoration: 'none',
                        color: 'inherit',
                    },
                },
            },
        },
    },
}));

const footerSections = [
    {
        title: 'Sell on SteelBazaar',
        items: [
            { name: 'Sell on SteelBazaar', route: '/seller-central' },
            { name: 'Participate in RFQs', route: '' },
            { name: 'Create Sale Auction', route: '/auction' },
            { name: 'Post Your Lead', route: '' },
            { name: 'Become Verified Seller', route: '/seller-central' },
            { name: 'Channel Partners', route: '/partnership' }
        ]
    },
    {
        title: 'Buy on SteelBazaar',
        items: [
            { name: 'Search and Find', route: '/customer-buyer' },
            { name: 'Compare the Brands', route: '/customer-buyer' },
            { name: 'Pool and Save', route: '/customer-buyer' },
            { name: 'Post Your Lead', route: '/customer-buyer' }
        ]
    },
    {
        title: 'Other Services',
        items: [
            { name: 'Manufacturing', route: '' },
            { name: 'Project Management', route: '' },
            { name: 'Customization', route: '' },
            { name: 'Fabrication', route: '' },
            { name: 'Dismantling', route: '' }
        ]
    },
    {
        title: 'Support and Care',
        items: [
            { name: 'Help Centre', route: '' },
            { name: 'Live Chat', route: '' },
            { name: 'Whatsapp Us', route: '' },
            { name: 'Order Status', route: '' },
            { name: 'Refunds', route: '' },
            { name: 'Complaints', route: '' }
        ]
    },
    {
        title: 'Useful Link',
        items: [
            { name: 'About Us', route: '/about-us' },
            { name: 'Careers with Us', route: '/career' },
            { name: 'News Centre', route: '' },
            { name: 'Lending Partners', route: '' }
        ]
    }
];

const validationSchema = Yup.object().shape({
    email: Yup.string().required('Please enter your email address').matches(REGEX.EMAIL, 'Invalid email address'),
});

const initialValues = {
    email: ''
};

const FooterTemplate: React.FC = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const emailSubscriptionService = useEmailSubscriptionService();
    const { showSnackbar, SnackBarComponent } = useSnackbar();
    const [isOpen, setIsOpen] = useState(false);
    const [isAnimating, setIsAnimating] = useState(false);
    const mapContainerRef = useRef<HTMLDivElement>(null);

    const isMobile = useMediaQuery("(max-width:768px)");


    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (mapContainerRef.current && !mapContainerRef.current.contains(event.target as Node)) {
                closeChatBox();
            }
        };

        if (isOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen]);

    const toggleChatBox = () => {
        if (isOpen) {
            setIsAnimating(true);
            setTimeout(() => {
                setIsOpen(false);
                setIsAnimating(false);
            }, 300);
        } else {
            setIsOpen(true);
        }
    };

    const closeChatBox = () => {
        setIsAnimating(true);
        setTimeout(() => {
            setIsOpen(false);
            setIsAnimating(false);
        }, 300);
    };

    const formik = useFormik({
        initialValues,
        validationSchema,
        validateOnMount: true,
        onSubmit: (values, { setSubmitting, setFieldError, resetForm }) => {
            setSubmitting(true);
            emailSubscriptionService.createEmailSubscription({ email: values.email, context: EMAIL_SUBSCRIPTION_CONTEXT.EMAIL_SUBSCRIPTION })
                .then(res => {
                    showSnackbar('success', 'Successfully subscribed to newsletter');
                }).catch(err => {
                    setFieldError('email', 'Failed to subscribe this email');
                    showSnackbar('error', 'Failed to subscribe to newsletter');
                }).finally(() => {
                    setSubmitting(false);
                });
            resetForm();
        },
    });

    const handleClick = (route: string) => {
        navigate(route);
    };

    const [openSection, setOpenSection] = useState<number | null>(null);

    const handleToggle = (index: number) => {
        setOpenSection(openSection === index ? null : index);
    };

    return (
        <>
            <div className={`${classes.container} `}>
                <div className='py-10 flex gap-x-2 justify-between'>
                    <div className='grid gap-y-5 h-fit'>
                        <div className='grid gap-y-3'>
                            <img className='w-[204px]' src={footerLogo} alt="footerLogo" />
                            <div className={`${classes.tagLine} text-sm font-semibold`}>Smart Trade With Technology</div>
                        </div>
                        <div className='grid gap-y-2 h-fit'>
                            <div className={`text-lg font-bold ${classes.companyName}`}>FutureCom Technologies Pvt. Ltd.</div>
                            <div className={`${classes.address} `}>
                                <div className={`text-sm font-medium mb-3 leading-[16px]`}>C-196, 2nd Floor, Sec-63, Noida,<br />
                                    Uttar Pradesh (India) 201301</div>
                                <div
                                    className={`${classes.containerPopUp} ${isOpen ? classes.visible : ''}`}
                                    style={{ display: isOpen || isAnimating ? 'block' : 'none' }}
                                >
                                    <div className={`${classes.mapContainer}`} ref={mapContainerRef}>
                                        <div className={classes.mapOuter}>
                                            <div className={classes.gmapCanvas}>
                                                <iframe
                                                    className={classes.gmapIframe}
                                                    scrolling="no"
                                                    marginHeight={0}
                                                    marginWidth={0}
                                                    src="https://maps.google.com/maps?width=1300&amp;height=384&amp;hl=en&amp;q=svamwork%20co&amp;t=&amp;z=19&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                                                    title="Google Map - Svamwork"
                                                ></iframe>
                                            </div>
                                        </div> 
                                        <div className={`${classes.crossIcon}`}>
                                            <CloseIcon onClick={closeChatBox} />
                                        </div>
                                    </div>
                                </div>
                                <div onClick={toggleChatBox} className="text-sm flex gap-2 cursor-pointer">
                                    <img src={location_icon} alt="" />
                                    <span>Get Direction on Map</span>
                                </div>
                                <div className={`text-sm flex gap-2 mt-[10px]`}><img src={call_icon} alt="" /> <span>+91 9654 555 540</span></div>
                                <div className={`text-sm flex gap-2 mt-[10px]`}> <a href="mailto:support@steelbazaar.com?subject=Hello&body=" className='flex gap-2 items-center'><img src={mail_icon} alt="" /> <span>support@steelbazaar.com</span></a></div>
                            </div>
                        </div>
                    </div>
                    {footerSections.map((section, index) => (
                        <div key={index} className={classes.footerChildContainer}>
                            <div className={`${classes.sectionTitle} font-semibold text-base mb-5`}>{section.title}</div>
                            <div className={`${classes.footerContent} grid gap-y-2`}>
                                <ul>
                                    {section.items.map((item, idx) => (
                                        <li key={idx} onClick={() => handleClick(item.route)}>
                                            <Link to={item.route}>{item.name}</Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className={`${classes.mobileFooterContainer}`}>
                <div className={classes.mobileFooter}>
                    <div className='grid gap-y-5 pb-5'>
                        <div className='grid gap-y-1'>
                            <img className='w-[204px] h-[36px]' src={footerLogo} alt="footerLogo" />
                            <div className={`${classes.tagLine} text-sm font-semibold`}>Smart Trade With Technology</div>
                        </div>
                        <div className='grid gap-y-1 h-fit'>
                            <div className={`text-lg font-semibold ${classes.companyName}`}>FutureCom Technologies Pvt. Ltd.</div>
                            <div className={`${classes.address} `}>
                                <div className={`text-sm font-medium mb-2.5 leading-[16px]`}>C-196, 2nd Floor, Sec-63, Noida,<br />
                                    Uttar Pradesh (India) 201301</div>
                                <div
                                    className={`${classes.containerPopUp} ${isOpen ? classes.visible : ''}`}
                                    style={{ display: isOpen || isAnimating ? 'block' : 'none' }}
                                >
                                    <div className={`${classes.mapContainer}`} ref={mapContainerRef}>
                                        <div className={classes.mapOuter}>
                                            <div className={classes.gmapCanvas}>
                                                <iframe
                                                    className={classes.gmapIframe}
                                                    scrolling="no"
                                                    marginHeight={0}
                                                    marginWidth={0}
                                                    src="https://maps.google.com/maps?width=1300&amp;height=384&amp;hl=en&amp;q=svamwork%20co&amp;t=&amp;z=19&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                                                    title="Google Map - Svamwork"
                                                ></iframe>
                                            </div>
                                        </div>
                                        <div className={`${classes.crossIcon}`}>
                                            <CloseIcon onClick={closeChatBox} />
                                        </div>
                                    </div>
                                </div>
                                <div onClick={toggleChatBox} className="text-sm flex gap-2 cursor-pointer">
                                    <img src={location_icon} alt="" />
                                    <span>Get Direction on Map</span>
                                </div>
                                <div className={`text-sm flex gap-2 mt-[10px]`}><img src={call_icon} alt="" /> <span>+91 9654 555 540</span></div>
                                <div className={`text-sm flex gap-2 mt-[10px]`}> <a href="mailto:support@steelbazaar.com?subject=Hello&body=" className='flex gap-2 items-center'><img src={mail_icon} alt="" /> <span>info@steelbazaar.com</span></a></div>
                            </div>
                        </div>
                    </div>
                    <div>
                        {footerSections.map((section, index) => (
                            <Accordion key={index}
                                expanded={openSection === index}
                                onChange={() => handleToggle(index)}>
                                <AccordionSummary className={classes.primaryContainer}
                                >
                                    <div className={`${classes.sectionTitle} font-semibold !text-base px-1 py-3`}>{section.title}</div>
                                </AccordionSummary>
                                <AccordionDetails className={`${classes.footerContent} grid gap-y-2`}>
                                    <ul className='pb-2.5'>
                                        {section.items.map((item, idx) => (
                                            <li key={idx} className={classes.listItem}>
                                                <Link to={item.route}>{item.name}</Link>
                                            </li>
                                        ))}
                                    </ul>
                                </AccordionDetails>
                            </Accordion>
                        ))}
                    </div>
                </div>
            </div>
            <div className={`${classes.middleContainer} pt-2.5`}>
                <div className={`${classes.textdemo} w-full`}>
                    <div className='flex flex-col'>
                        <div className={`${classes.footerHeading} font-medium text-base`}>Subscribe to our Newsletter</div>
                        <form onSubmit={formik.handleSubmit}>
                            <div className={`${classes.inputSearch} flex justify-between items-center h-fit w-80`}>
                                <TextField
                                    fullWidth
                                    className='!py-0 !px-0 !w-80'
                                    placeholder='Enter your Email'
                                    {...formik.getFieldProps("email")}
                                    error={formik.touched.email && Boolean(formik.errors.email)}
                                    helperText={formik.touched.email && Boolean(formik.errors.email) && formik.errors.email}
                                />
                                <button className={`${classes.btn} !text-base !mr-1 flex justify-center items-center`} type='submit'>Submit</button>
                            </div>
                        </form>
                    </div>
                    <div>
                        <p className='font-semibold text-base mb-3'>Follow Us:</p>
                        <div className={`flex ${classes.socialLinks}`}>
                            {[
                                { href: "", icon: facebook_Icon_1 },
                                { href: "", icon: x_Icon_1 },
                                { href: "https://www.linkedin.com/company/steelbazaar", icon: linkedin_Icon_1 },
                                { href: "", icon: youtube_Icon_1 },
                                { href: "", icon: whatsapp_Icon_1 },
                            ].map((link, index) =>
                                link.href ? (
                                    <a key={index} href={link.href} target="_blank" rel="noopener noreferrer">
                                        <img src={link.icon} alt={`Social Icon ${index + 1}`} />
                                    </a>
                                ) : null
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className={`${classes.bottomContainer} items-center`}>
                <div className={`${classes.copyRight} text-center text-sm font-medium`}>
                    © {new Date().getFullYear()} FutureCom Technologies Pvt. Ltd.,
                    {isMobile ? <br /> : " "} All Rights Reserved
                </div>
                <div className={`${classes.footerContent} flex gap-x-6`}>
                    <ul className={`${classes.footerBottomLinks} flex gap-3`}>
                        <li><Link to={STATIC_PAGES_ROUTES.TERMS_AND_CONDITIONS} className={`${classes.text} mb-0 cursor-pointer px-1 text-sm font-medium`}>Terms & Conditions</Link></li>
                        <li><Link to={STATIC_PAGES_ROUTES.PRIVACY_POLICY} className={`${classes.text} cursor-pointer mb-0 px-1 text-sm font-medium`}>Privacy Policy</Link></li>
                    </ul>
                </div>
            </div>
        </>
    );
};

export default FooterTemplate;