import React, { useEffect, useState } from 'react';
import { CATALOGUE_FILTER_STATUS, CATALOGUE_TYPE, HTTP_STATUS, IPagination, STATUS } from '../../../utils/types';
import TableV2, { ColumnType } from '../../organisms/TableV2';
import ResourceStatusV2 from '../../atoms/ResourceStatusV2/ResourceStatusV2';
import Button from '../../atoms/Button/Button';
import { enumToString } from '../../../utils/helper';
import { useNavigate } from 'react-router-dom';
import { ICataloguesList, ICataloguesListingFilter } from './VendorCatalogueTableView.template';
import { GENERIC_EXCEPTION_CODE, VENDOR_CATALOGUE_ROUTES } from '../../../utils/constant';
import moment from 'moment';
import editPenIcon from "./../../../assets/images/editPenIcon.svg";
import ViewEyeIcon from './../../../assets/icons/viewOutlinedPrimary500.svg'
import TableV3 from '../../organisms/TableV3';
import { useAuthenticatedUser } from '../../../hooks/useAuthenticatedUser';
import { useVendorCatalogueService } from '../../../services/useVendorCatalogueService';
import { useSnackbar } from '../../../hooks/useSnackBar';

interface IVendorCataloguesListingTemplateProps {
    cataloguesList: ICataloguesList[];
    pagination: IPagination;
    handlePaginationChange: any;
    handleRowsPerPageChange: any;
    vendorId: number;
    cataloguesFilters: ICataloguesListingFilter;
}


const VendorCataloguesListingTemplate: React.FC<IVendorCataloguesListingTemplateProps> = ({ cataloguesList, pagination, handlePaginationChange, handleRowsPerPageChange, vendorId, cataloguesFilters }) => {
    const navigate = useNavigate();
    const { user } = useAuthenticatedUser();
    const { showSnackbar } = useSnackbar();

    const vendorCatalogueService = useVendorCatalogueService();

    const [selectedIds, setSelectedIds] = useState<string[]>([]);
    
    const showCheckboxes = cataloguesFilters.status === CATALOGUE_FILTER_STATUS.PUBLISHED || cataloguesFilters.status === CATALOGUE_FILTER_STATUS.UNPUBLISHED;

    const Action = (id: number, status: string, productType: string) => (
        <div className='flex gap-x-3 items-center' title=''>
             {status !== STATUS.INACTIVE && (
            <div >
                <img className="max-w-xs m-auto cursor-pointer" src={editPenIcon} alt="pic" onClick={() => {
                    navigate(`${VENDOR_CATALOGUE_ROUTES.EDIT_CATALOGUE}/${id}?mode="EDIT"&catalogueType=${productType}`);
                }} />
            </div>)}
            <div>
                <img className="max-w-xl m-auto cursor-pointer" src={ViewEyeIcon} alt="pic" onClick={() => {
                    navigate(`${VENDOR_CATALOGUE_ROUTES.VIEW_CATALOGUE}/${id}`);
                }} />
            </div>
        </div>
    );


    const schema = {
        id: "1",
        title: "",
        pagination: {
            total: pagination.totalRecords,
            currentPage: pagination.page,
            isVisible: true,
            limit: pagination.size,
            handleChangePage: handlePaginationChange,
            handleChangeRowsPerPage: handleRowsPerPageChange,
        },
        columns: [
            { label: "S No.", key: "id", type: "number" as ColumnType, props: { className: '' } },
            { label: "Catalog ID", key: "id", type: "string" as ColumnType, props: { className: '' } },
            { label: "Catalog Name", key: "catalogueName", type: "string" as ColumnType, props: { className: 'flex text-left ml-3.5' } },
            { label: "Catalog Type", key: "catalogueType", type: "string" as ColumnType, props: { className: '' } },
            { label: "No of Products", key: "productCount", type: "number" as ColumnType, props: { className: '' } },
            { label: "Created On", key: "createdAt", type: "string" as ColumnType, props: { className: 'w-16 m-auto' } },
            { label: "Last Update", key: "updatedAt", type: "string" as ColumnType, props: { className: 'w-16 m-auto' } },
            { label: "Created By", key: "createdBy", type: "string" as ColumnType, props: { className: 'w-16 m-auto' } },
            { label: "Status", key: "status", component: ({ value }: { value: string }) => <ResourceStatusV2 status={value} />, type: "custom" as ColumnType, props: {} },
            { label: "Action", key: "action", type: "custom" as ColumnType, props: { className: '' } }
        ],
    };

    const records = cataloguesList.map((catalogueList, index) => [
        pagination.page * pagination.size + index + 1,
        catalogueList.id,
        catalogueList.catalogueName,
        CATALOGUE_TYPE[catalogueList.catalogueType as keyof typeof CATALOGUE_TYPE],
        catalogueList.productCount,
        moment(catalogueList.createdAt).format("DD-MM-YYYY HH:mm"),
        moment(catalogueList.updatedAt).format("DD-MM-YYYY HH:mm"),
        catalogueList.fullName,
        enumToString(catalogueList.status),
        Action(catalogueList.id, catalogueList.productStatus, catalogueList.productType),
    ]);
   
    const handleSubmit = async () => {
        if (!selectedIds.length) return;
        try {
            if (user?.businessId) {
                try {
                    const vendorCatalogueCreateResponse = await vendorCatalogueService.updateVendorCatalogueStatus(user?.businessId, {catalogueIds:selectedIds,status:cataloguesFilters.status,resourceType:'CATALOGUE'});
                    if (vendorCatalogueCreateResponse.status === HTTP_STATUS.OK) {
                        navigate(VENDOR_CATALOGUE_ROUTES.MAKE_CATALOGUE);
                    }
                    else if (vendorCatalogueCreateResponse?.data?.exceptionCode === GENERIC_EXCEPTION_CODE.DUPLICATE_ENTRY) {
                        showSnackbar("error", "Catalogue Updation  Status failed")
                    }
                } catch (error) {
                    showSnackbar("error", `Catalogue Updation  Status failed`)
                }
            }
        } catch (error) {
            setSelectedIds([]);
            console.error("Error sending IDs:", error);
        }
    };
    
    return (
        <div>          
            <TableV3 schema={schema} records={records} showCheckboxes={showCheckboxes}  onSendSelected={handleSubmit} selectedIds={selectedIds} setSelectedIds={setSelectedIds}/>
        </div>
    );
};

export default VendorCataloguesListingTemplate;
